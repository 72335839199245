import { ThemeProvider } from '@emotion/react'
import { Box, Grid } from '@mui/material'
import { ChangeEvent, useEffect, useState } from 'react'
import Resizer from 'react-image-file-resizer'
import { useActions } from '../../hooks/useActions'
import { useTypedSelector } from '../../hooks/useTypedSelector'
import { putAvatar } from '../../state/action-creators'
import { fetchData } from '../../utils/helpers/navigationHelper'
import { WarningPrompt } from '../../utils/notifications/WarningPrompt'
import theme from '../../utils/theme2024'
import {
	PageWrapper,
	StyledBoxComp,
	StyledButtonComp,
	UserNameWithAvatar,
} from '../components/reusableComponents'
import { TitleComponent } from '../components/Informative'
import { SubmitButton } from '../components/Buttons'
const PersonalSettingsPage = (props: {
	setLoading: any
	errorMessage: null | string
	setErrorMessage: any
	isErrorPromptOpened: boolean
	setIsErrorPromptOpened: any
	setIsWarningPromptOpened: any
}) => {
	const { login, users, avatars } = useTypedSelector((state) => state)
	const { getAvatars } = useActions()

	const headers = {
		Authorization: `Token ${login.user.token}`,
	}

	const [isWarningPromptOpened, setIsWarningPromptOpened] = useState(false)

	const [file, setFile] = useState<any>(null)
	const [resizedFile, setResizedFile] = useState<any>(null)

	const handleFileChange = (e: ChangeEvent<HTMLInputElement>) => {
		if (e.target.files && e.target.files[0].type !== 'image/jpeg') {
			props.setErrorMessage('Image should be in JPEG format only!')
			props.setIsErrorPromptOpened(true)
			setFile(null)
		}
		if (e.target.files && e.target.files[0].type === 'image/jpeg') {
			setFile(e.target.files[0])
		}
	}
	let avatarComp = (
		<UserNameWithAvatar
			avatars={
				resizedFile === null
					? avatars.avatars
					: { [login.user.name]: resizedFile }
			}
			value={login.user.name}
			withoutName
			bigPicture
		></UserNameWithAvatar>
	)
	const callback = (newFileUri: any) => {
		setResizedFile(newFileUri)
	}

	const resizeImage = (imageFile: any) => {
		try {
			Resizer.imageFileResizer(imageFile, 150, 150, 'JPEG', 100, 0, callback)
		} catch (err) {
			console.log(err)
		}
	}
	const reader = new FileReader()

	const handleUpdateClick = async () => {
		props.setLoading(true)
		if (!file) {
			return
		}
	}
	useEffect(() => {
		if (resizedFile) {
			let dataToSave = {
				file_title: login.user.name,
				file_name: file.name,
				file_open_link: resizedFile,
				file_type: file.type,
			}
			putAvatar(headers, dataToSave)
		}
	}, [resizedFile])
	useEffect(() => {
		if (file) {
			resizeImage(file)
		}
	}, [file])
	useEffect(() => {
		props.setLoading(false)
	}, [])

	const handleSubmit = async () => {
		// await handleUpdateClick()
		// props.setLoading(false)
		await fetchData(login, getAvatars, avatars.avatars)
		await fetchData(login, getAvatars, avatars.avatars)
		setFile(null)
		setResizedFile(null)
	}
	useEffect(() => {
		if (resizedFile) {
			avatarComp = (
				<UserNameWithAvatar
					avatars={{ [login.user.name]: resizedFile }}
					value={login.user.name}
					withoutName
					bigPicture
				></UserNameWithAvatar>
			)
		} else {
			avatarComp = (
				<UserNameWithAvatar
					avatars={avatars.avatars}
					value={login.user.name}
					withoutName
					bigPicture
				></UserNameWithAvatar>
			)
		}
	}, [resizedFile])

	return (
		<ThemeProvider theme={theme}>
			<PageWrapper></PageWrapper>

			<Box
				sx={{
					width: '95%',
					display: 'flex',
					flexDirection: 'column',
					alignItems: 'center',
					justifyContent: 'space-between',
					height: '90%',
					paddingBottom: '5%',
				}}
			>
				<div
					style={{
						display: 'flex',
						alignItems: 'center',
						flexDirection: 'column',
						justifyContent: 'center',
					}}
				>
					{avatarComp}
				</div>

				<SubmitButton
					onClick={() => document.getElementById('fileUploader')?.click()}
					tooltip={'.jpg only!'}
					style={{ width: 160, marginTop: 20 }}
				>
					Upload picture
				</SubmitButton>

				<input
					type='file'
					onChange={handleFileChange}
					style={{ display: 'none' }}
					id='fileUploader'
				/>
				<StyledButtonComp
					onClick={() => {
						setIsWarningPromptOpened(true)
					}}
					style={{ color: 'white', marginTop: '30px' }}
				>
					Save
				</StyledButtonComp>

				<WarningPrompt
					message={'Saving current changes?'}
					PopUpVisible={isWarningPromptOpened}
					setPopUpVisible={setIsWarningPromptOpened}
					onAgree={() => {
						handleSubmit()
						setIsWarningPromptOpened(false)
					}}
					onCancel={() => setIsWarningPromptOpened(false)}
				/>
			</Box>
		</ThemeProvider>
	)
}

export default PersonalSettingsPage
