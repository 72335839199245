import React from 'react'
import { countryContinentList } from '../components/countryContinentList'
import { StyledTextField } from '../components/reusableComponents'
import { generateNodesFromArray } from '../../utils/helpers/helperFuncs'
import { TreeCheckboxContainer } from '../components/TreeCheckBox'

//this is the main component for the capabilities. We have all of them rendered with this component.
//we use different functions that work the same way to generate the nodes.
//all the capabilities must have the structure of an array of objects which properties are value and label.
//if the nodes has children then it will work the same way: value, label and children (an array of value, label)
//this code could probably get refactored but it's not an easy task because every capability (in general) has something different.
//it might be better for us to have this crazy amount of small functions than a huge unique function with a lot of coditionals inside.
//the way it is right now is easy to mantain, scale and remove.

const PublishersMediaCapabilities = (props: {
	settings: any
	publisherCapabilitiesMap: any
}) => {
	//based on what we generate we will do some previous things before generating the nodes.
	const countryGenerateNodes = (baseNodes: any, updatedNodes: any) => {
		try {
			for (const node of updatedNodes) {
				for (const continent of baseNodes) {
					for (const country of continent.children) {
						if (node.value === country.value) {
							country.payout = node.payout
							country.volume = node.volume
							continue
						}
					}
				}
			}
		} catch (error) {
			console.error(error)
		}
		return baseNodes
	}
	const generateNodesFraudTool = () => {
		return generateNodesFromArray(props.settings.settings.fraudTools, {
			value: '',
			label: '',
		})
	}
	const generateNodesTrafficIndicators = () => {
		const indicatorsCR = generateNodesFromArray(
			['High CR', 'Average CR', 'Low CR'],
			{ value: '', label: '' },
		)
		const indicatorsQuality = generateNodesFromArray(
			['High', 'Average', 'Low'],
			{ value: '', label: '' },
		)
		return [
			{
				value: 'CR',
				label: 'CR',
				children: indicatorsCR,
			},
			{
				value: 'Quality',
				label: 'Quality',
				children: indicatorsQuality,
			},
		]
	}
	const generateNodesPublisherType = () => {
		const mediaBuyingPlatforms = generateNodesFromArray(
			[
				'Direct App/Website',
				'Social Platform',
				'DSP',
				'in App Self-Serve',
				'Pop & Push Self-Serve',
				'Incent Self-Serve',
				'Incent Offerwall',
				'Native Ads Platform',
				'Phone Operator',
			],
			{ value: '', label: '' },
		)
		const nonMediaBuyingPlatforms = generateNodesFromArray(
			['Ad Network', 'External Media Buying'],
			{ value: '', label: '' },
		)
		return [
			{
				value: 'Media Buying platforms',
				label: 'Media Buying platforms',
				children: mediaBuyingPlatforms,
			},
			{
				value: 'Non media Buying',
				label: 'Non media Buying',
				children: nonMediaBuyingPlatforms,
			},
		]
	}
	const generateNodesAdType = () => {
		return generateNodesFromArray(
			[
				'Rewarded Video',
				'Video',
				'Banner Ads',
				'Interstitials',
				'Incent',
				'Point Incent',
				'Cash Incent',
				'Content Locking Incent',
				'Pop',
				'Push',
				'Native Ads',
				'Search',
				'App Discovery',
				'Playable Ads',
				'Influencer',
				'Direct Linking',
				'Contextual',
				'Mrec',
				'Calendar',
			],
			{ value: '', label: '' },
		)
	}
	const generateAbilities = () => {
		try {
			const resultArr = props.settings.settings.capabilities
			const forcedAbilities = ['Fully Transparency', 'SKAD', 'VTA', 'Hard KPI']
			for (const ability of forcedAbilities) {
				if (resultArr.indexOf(ability) < 0) {
					resultArr.push(ability)
				}
			}
			return generateNodesFromArray(resultArr, {
				value: '',
				label: '',
			})
		} catch (error) {
			console.error(error)
			return generateNodesFromArray([], { value: '', label: '' })
		}
	}
	const generateNodesDevices = () => {
		return generateNodesFromArray(['iOS', 'Android', 'Desktop', 'SmartTV'], {
			value: '',
			label: '',
		})
	}
	const generateNodesPaymentModel = () => {
		return generateNodesFromArray(
			[
				'CPI',
				'CPA',
				'CPC',
				'CPM',
				'CPV',
				'Revenue Share',
				'Prepaid Platform',
				'Fixed Cost (Influencers)',
			],
			{ value: '', label: '' },
		)
	}
	const generateNodesRelevantCategories = () => {
		const children = generateNodesFromArray(
			['Hyper Casual Gaming', 'Casual Gaming', 'RPG Gaming'],
			{ value: '', label: '' },
		)
		const nodes = generateNodesFromArray(
			[
				'Gambling',
				'Gambling License Only',
				'Sport Betting',
				'Sport Betting License Only',
				'Social Casino',
				'Shopping',
				'Food & Drinks',
				'Fashion',
				'Auto parts',
				'Dating',
				'Finance',
				'Trading',
				'Trading License Only',
				'Streaming',
				'Entertainment',
				'Sports',
				'Social Platform',
				'Food Delivery',
				'Loans',
				'Surveys & Freebies',
				'VPN',
				'Travel App',
				'Utility',
				'Real Estate',
				'Job Search',
				'Insurance',
				'Crypto',
			],
			{ value: '', label: '' },
		)
		return [
			{
				value: 'Gaming',
				label: 'Gaming',
				children: children,
			},
			...nodes,
		]
	}
	const generateNodesAvailablePlatforms = () => {
		const children = generateNodesFromArray(
			[
				'Appsflyer',
				'Adjust',
				'Branch',
				'Kochava',
				'Singular',
				'AppMetrica',
				'Airbridge',
				'Tenjin',
				'Income Access',
				'Mytracker',
			],
			{ value: '', label: '' },
		)
		return [
			{
				value: 'Affise',
				label: 'Affise',
			},
			{
				value: 'MMP',
				label: 'MMP',
				children: children,
			},
		]
	}
	const generateNodesFormatTypes = () => {
		const logoChildren = generateNodesFromArray(props.settings.settings.logos, {
			value: '',
			label: '',
		})
		const bannersChildren = generateNodesFromArray(
			props.settings.settings.banners,
			{ value: '', label: '' },
		)
		const videosChildren = generateNodesFromArray(
			props.settings.settings.videos,
			{ value: '', label: '' },
		)
		videosChildren.push(
			{
				value: 'Max. time: X sec',
				label: 'Max. time: X sec',
				inputValue: 'X',
			},
			{
				value: 'Min. time: X sec',
				label: 'Min. time: X sec',
				inputValue: 'X',
			},
		)
		return [
			{
				value: 'Logo',
				label: 'Logo',
				children: logoChildren,
			},
			{
				value: 'Banners',
				label: 'Banners',
				children: bannersChildren,
			},
			{
				value: 'Videos',
				label: 'Videos',
				children: videosChildren,
			},
			{
				value: 'Text',
				label: 'Text',
				children: [
					{
						value: 'Up to X characters',
						label: 'Up to X characters',
						inputValue: 'X',
					},
				],
			},
		]
	}

		
	
	const generateNodesDailyCap = () => {
		return [
			{
				value: 'Minimum Daily Cap',
				label: 'Minimum Daily Cap',
				inputValue: 'X',
			},
		]
	}

	return (
		<>
			<div
				style={{
					width: '30%',
					display: 'flex',
					flexDirection: 'column',
					gap: '20px',
				}}
			>
				<TreeCheckboxContainer
					nodes={countryGenerateNodes(
						countryContinentList,
						props.publisherCapabilitiesMap.selectedCountryElements,
					)}
					label={'Country'}
					selectedElements={
						props.publisherCapabilitiesMap.selectedCountryElements
					}
					setSelectedElements={
						props.publisherCapabilitiesMap.setSelectedCountryElements
					}
					isCountry={true}
					countryData={
						props.publisherCapabilitiesMap.selectedGeneralCountryData
					}
					setCountryData={
						props.publisherCapabilitiesMap.setSelectedGeneralCountryData
					}
				/>
				<TreeCheckboxContainer
					nodes={generateNodesFraudTool()}
					label={'Fraud Tool'}
					selectedElements={props.publisherCapabilitiesMap.selectedFraudTools}
					setSelectedElements={
						props.publisherCapabilitiesMap.setSelectedFraudTools
					}
					notExpandable={true}
				/>
				<TreeCheckboxContainer
					nodes={generateNodesTrafficIndicators()}
					label={'Traffic Indicators'}
					selectedElements={
						props.publisherCapabilitiesMap.selectedTrafficIndicators
					}
					setSelectedElements={
						props.publisherCapabilitiesMap.setSelectedTrafficIndicators
					}
				/>
			</div>
			<div
				style={{
					width: '25%',
					display: 'flex',
					flexDirection: 'column',
					gap: '20px',
				}}
			>
				<TreeCheckboxContainer
					nodes={generateNodesPublisherType()}
					label={'Type of Publisher'}
					selectedElements={
						props.publisherCapabilitiesMap.selectedTypeOfPublisher
					}
					setSelectedElements={
						props.publisherCapabilitiesMap.setSelectedTypeOfPublisher
					}
				/>
				<TreeCheckboxContainer
					nodes={generateNodesAdType()}
					label={'Ad Type'}
					selectedElements={props.publisherCapabilitiesMap.selectedAdType}
					setSelectedElements={props.publisherCapabilitiesMap.setSelectedAdType}
					notExpandable={true}
				/>
				<TreeCheckboxContainer
					nodes={generateAbilities()}
					label={'Abilities'}
					selectedElements={props.publisherCapabilitiesMap.selectedAbilities}
					setSelectedElements={
						props.publisherCapabilitiesMap.setSelectedAbitilies
					}
					notExpandable={true}
				/>
			</div>
			<div
				style={{
					width: '25%',
					display: 'flex',
					flexDirection: 'column',
					gap: '20px',
				}}
			>
				<TreeCheckboxContainer
					nodes={generateNodesDevices()}
					label={'Devices'}
					selectedElements={props.publisherCapabilitiesMap.selectedDevices}
					setSelectedElements={
						props.publisherCapabilitiesMap.setSelectedDevices
					}
					notExpandable={true}
				/>
				<TreeCheckboxContainer
					nodes={generateNodesPaymentModel()}
					label={'Payment Model'}
					selectedElements={props.publisherCapabilitiesMap.selectedPaymentModel}
					setSelectedElements={
						props.publisherCapabilitiesMap.setSelectedPaymentModel
					}
					notExpandable={true}
				/>
				<TreeCheckboxContainer
					nodes={generateNodesRelevantCategories()}
					label={'Verticals'}
					selectedElements={
						props.publisherCapabilitiesMap.selectedRelevantCategories
					}
					setSelectedElements={
						props.publisherCapabilitiesMap.setSelectedRelevantCategories
					}
				/>
			</div>
			<div
				style={{
					width: '25%',
					display: 'flex',
					flexDirection: 'column',
					gap: '20px',
				}}
			>
				<TreeCheckboxContainer
					nodes={generateNodesAvailablePlatforms()}
					label={'Available Platforms'}
					selectedElements={
						props.publisherCapabilitiesMap.selectedAvailablePlatforms
					}
					setSelectedElements={
						props.publisherCapabilitiesMap.setSelectedAvailablePlatforms
					}
					notExpandable={true}
				/>
				<TreeCheckboxContainer
					nodes={generateNodesFormatTypes()}
					label={'Format Types'}
					selectedElements={props.publisherCapabilitiesMap.selectedFormatTypes}
					setSelectedElements={
						props.publisherCapabilitiesMap.setSelectedFormatTypes
					}
				/>
				<TreeCheckboxContainer
					nodes={generateNodesDailyCap()}
					label={'Daily Cap'}
					selectedElements={props.publisherCapabilitiesMap.selectedDailyCap}
					setSelectedElements={
						props.publisherCapabilitiesMap.setSelectedDailyCap
					}
					notExpandable={true}
					customInputLabel={'$'}
				/>
			</div>
		</>
	)
}

export default PublishersMediaCapabilities
