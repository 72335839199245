import { ThemeProvider } from '@emotion/react'
import MenuIcon from '@mui/icons-material/Menu'
import { DialogActions, ListItemButton, MenuItem } from '@mui/material'
import Box from '@mui/material/Box'
import Drawer from '@mui/material/Drawer'
import IconButton from '@mui/material/IconButton'
import List from '@mui/material/List'
import Toolbar from '@mui/material/Toolbar'
import Typography from '@mui/material/Typography'
import moment from 'moment'
import React, {
	Dispatch,
	SetStateAction,
	useEffect,
	useRef,
	useState,
} from 'react'
import {
	NavigateFunction,
	Outlet,
	useLocation,
	useNavigate,
} from 'react-router-dom'
import logo from '../assets/varys-logo-white.png'
import { FirstLoading, Loading } from '../assets/svg/loading'
import { CLIENT_VERSION, routes } from '../config'
import { useActions } from '../hooks/useActions'
import { useTypedSelector } from '../hooks/useTypedSelector'
import { UserInterface } from '../models/model.interface'
import {
	ActionsButton,
	AddButton,
	CancelButton,
	ChipInputList,
	Logo,
	StyledDialog,
	StyledTextField,
	// TableSearchComponent,
	UserNameWithAvatar,
} from '../pages/components/reusableComponents'
import { TableSearchComponent } from '../pages/components/Search'
import { getVersionAction } from '../state/action-creators'
import { getApplicableRules } from '../utils/helpers/helperFuncs'
import { fetchData } from '../utils/helpers/navigationHelper'
import { ErrorPrompt } from '../utils/notifications/ErrorPrompt'
import { SuccessPrompt } from '../utils/notifications/SuccessPrompt'
import { WarningPrompt } from '../utils/notifications/WarningPrompt'
import theme from '../utils/theme2024'
import {
	MenuComponent,
	MenuIsSelected,
	MenuNotSelected,
	NavbarButton,
} from './NavBarStyled'
import { UserMessagePrompt } from '../utils/notifications/UserMessagePrompt'

export function extractRootPath(pathname: string): string {
	return pathname.split('/')[1]
}

const ResponsiveDrawer = (props: {
	isErrorPromptOpened: any
	setIsErrorPromptOpened: any
	isSuccessPromptOpened: any
	setIsSuccessPromptOpened: any
	isWarningPromptOpened: any
	setIsWarningPromptOpened: any
	isUserMessagePromptOpened: any
	setIsUserMessagePromptOpened: any
	setErrorMessage: any
	setUserMessage: any
	errorMessage: any
	userMessage: any
	PopUpVisible: boolean
	setPopUpVisible: any
	forcedFilterStatus: number
	setForcedFilterStatus: any
	isAuthorized: any
	setFirstLoadingDone: any
	search: string
	setSearch: Dispatch<SetStateAction<string>>
	presetSearch: string
	setPresetSearch: Dispatch<SetStateAction<string>>
	setLoading: Dispatch<SetStateAction<boolean>>
}) => {
	const {
		users,
		login,
		advertiser,
		publisher,
		campaign,
		app,
		logs,
		passwords,
		campaign_history,
		learning,
		accounts,
		dashboard,
		avatars,
		version,
		p360aggregated,
		salesplan,
		settings,
		wishlist,
		creator,
		handshake,
	} = useTypedSelector((state) => state)
	const ruleSet = getApplicableRules(users, login, settings, 'settings')
	const navigate = useNavigate()
	const location = useLocation()
	const [mobileOpen, setMobileOpen] = useState(false)
	// const [loading, setLoading] = useState(false)
	const [firstLoading, setFirstLoading] = useState(false)
	const [progress, setProgress] = useState(0)
	const [currentpage, setCurrentpage] = useState(location.pathname)
	const [viewRecord, setViewRecord] = useState<any>(null)
	const [isPreviewOpened, setIsPreviewOpened] = useState(false)
	const [universalEdit, setUniversalEdit] = useState<any>(null)
	const [universalDelete, setUniversalDelete] = useState<any>(null)
	const [isLongDownload, setIsLongDownload] = useState(false)
	const [searchPlaceholder, setSearchPlaceholder] = useState('')
	const {
		usersAction,
		getAdvertiserAction,
		getPublisherAction,
		getCampaignAction,
		getAppsAction,
		logOutAction,
		getSettingsAction,
		getPasswordsAction,
		getAvatars,
		getDashboardAction,
		getCampaignHistoryAction,
		getLogsAction,
		getAccountsAction,
		getAppsIcons,
		getp360aggregated,
		getSalesPlanAppsAction,
		getPublisherWishList,
		getCreatorsAction,
		getHandshakeAction,
	} = useActions()

	if (!login.user.token) {
		navigate('/')
	}

	function updateProgress(completed: number, total: number) {
		const progressPercentage = (completed / total) * 100
		setProgress(progressPercentage)
	}
	const checkVersion = async () => {
		const fheaders = {
			Authorization: `Token ${login.user.token}`,
		}
		const result = await getVersionAction(CLIENT_VERSION, fheaders)
		if (result !== undefined && result.data.successful !== true) {
			sessionStorage.clear()
			window.location.reload()
		}
	}
	useEffect(() => {
		const lastFetchTime = localStorage.getItem('lastFetchTime')
		const populateData = async () => {
			//if there is no user saved then it's the first time we render the page and we want to do the "First loading"
			if (
				location &&
				(users.users.length === 0 || avatars.avatars.length === 0)
			) {
				setFirstLoading(true)
				// console.time('downloading')
				const promisesDownloadData = [
					fetchData(login, usersAction, users.users),
					fetchData(login, getAdvertiserAction, advertiser.advertiser),
					fetchData(login, getPublisherAction, publisher.publisher),
					fetchData(login, getCreatorsAction, creator.creator),
					fetchData(login, getHandshakeAction, handshake),
					fetchData(login, getCampaignAction, campaign.campaign),
					fetchData(
						login,
						getCampaignHistoryAction,
						campaign_history.campaign_history,
					),
					fetchData(login, getAppsAction, app.app),
					fetchData(login, getLogsAction, logs.logs),
					fetchData(login, getPasswordsAction, passwords),
					fetchData(login, getAccountsAction, accounts),
					fetchData(login, getAvatars, avatars),
					fetchData(login, getDashboardAction, dashboard),
					fetchData(login, getSalesPlanAppsAction, salesplan),
					fetchData(login, getPublisherWishList, wishlist),
				]
				const dateIsOneDayOld =
					moment().diff(moment(lastFetchTime), 'hours') >= 24
				const dateIsOneHourOld =
					moment().diff(moment(lastFetchTime), 'hours') >= 1

				if (!lastFetchTime || dateIsOneDayOld) {
					setIsLongDownload(true)
					promisesDownloadData.push(
						fetchData(login, getp360aggregated, p360aggregated.P360),
					)
				}
				if (!lastFetchTime || dateIsOneHourOld) {
					promisesDownloadData.push(
						fetchData(login, getAppsIcons, app.appIcons),
					)
				}
				const total = promisesDownloadData.length
				let completed = 0
				updateProgress(completed, total)
				const promises = promisesDownloadData.map((promise) =>
					promise.then(() => {
						completed++
						updateProgress(completed, total)
					}),
				)
				await Promise.all(promises)
					.then(() => {
						setProgress(100)
						setFirstLoading(false)
						localStorage.setItem('lastFetchTime', moment().toISOString())
						// console.timeEnd('downloading')
					})
					.catch((error) => {
						console.error('Error during fetching data: ', error)
					})
			}
		}

		checkVersion()

		populateData()
	}, [])

	useEffect(() => {
		if (login.user.token) {
			fetchData(login, usersAction, users.users)
		}
		const loggedUser = users?.users?.find(
			(user: UserInterface) => user.email === login.user.email,
		)
		if (loggedUser && loggedUser.blocked) {
			handleLogout()
		}
		const currentPath = '/' + window.location.pathname.split('/')[1]
		if (currentPath !== currentpage) {
			if (
				currentPath !== '/aggregated' &&
				currentPath !== '/network' &&
				currentPath !== '/rawData' &&
				currentPath !== '/invalid' &&
				currentPath !== '/adjust' &&
				currentPath !== '/logs' &&
				currentPath !== '/settings' &&
				currentPath !== '/mediaplan'
			) {
				setCurrentpage(window.location.pathname)
			}
		}
		if (
			window.location.pathname !== '/campaigns' &&
			props.forcedFilterStatus !== 1
		) {
			props.setForcedFilterStatus(1)
		}

		checkVersion()
	}, [window.location.pathname])

	useEffect(() => {
		if (props.errorMessage !== null) {
			props.setIsErrorPromptOpened(true)
		}
	}, [props.errorMessage])

	useEffect(() => {
		if (viewRecord !== null) {
			setIsPreviewOpened(true)
		}
	}, [viewRecord])

	useEffect(() => {
		return () => {
			if (isPreviewOpened) {
				setUniversalDelete(null)
				setUniversalEdit(null)
			}
		}
	}, [isPreviewOpened])

	const searchPlaceholderMap = [
		{ endpoint: '/campaigns', placeholder: 'Campaigns' },
		{ endpoint: '/advertisers', placeholder: 'Advertisers' },
		{ endpoint: '/publishers', placeholder: 'Supply' },
		{ endpoint: '/creators', placeholder: 'Creators' },
		{ endpoint: '/apps', placeholder: 'Apps' },
		{ endpoint: '/logs', placeholder: 'Logs' },
		{ endpoint: '/users', placeholder: 'Users' },
		{ endpoint: '/autoswitch', placeholder: 'Autoswitch' },
		{ endpoint: '/passwords', placeholder: 'Passwords' },
		{ endpoint: '/documents', placeholder: 'Documents' },
		{ endpoint: '/finance/control', placeholder: 'Finance Control' },
		{ endpoint: '/finance/personal', placeholder: 'Finance Personal' },
		{ endpoint: '/finance/supply', placeholder: 'Finance Supply' },
		{ endpoint: '/finance/demand', placeholder: 'Finance demand' },
		{ endpoint: '/finance/global', placeholder: 'Finance Global' },
		{ endpoint: '/finance/mediabuying', placeholder: 'Finance Media Buying' },
		{
			endpoint: '/finance/creatorsupply',
			placeholder: 'Finance Creative Supply',
		},
		{ endpoint: '/finance/creator', placeholder: 'Finance Creative' },
		{ endpoint: '/finance/balancein', placeholder: 'Finance Balance In' },
		{ endpoint: '/finance/balanceout', placeholder: 'Finance Balance Out' },
		{ endpoint: '/p360apps', placeholder: 'P360 Apps' },
	]
	const disabledSearchMap = ['-demand-']

	const prevPathname = useRef(extractRootPath(location.pathname))

	useEffect(() => {
		const loadCurrentSettingsAndUsers = async () => {
			const headers = {
				Authorization: `Token ${login.user.token}`,
			}
			await getSettingsAction(headers)
			await usersAction(headers)
		}
		loadCurrentSettingsAndUsers()
		// props.setSearch(props.search)
		let newPlaceholder = ''
		let searchDisabled = false
		for (const disabled of disabledSearchMap) {
			if (location.pathname.includes(disabled)) {
				searchDisabled = true
				break
			}
		}
		if (!searchDisabled) {
			for (const possiblePlaceholder of searchPlaceholderMap) {
				if (location.pathname.startsWith(possiblePlaceholder.endpoint)) {
					newPlaceholder = possiblePlaceholder.placeholder
					break
				}
			}
		}
		setSearchPlaceholder(newPlaceholder)
		const currentPathnameRoot = extractRootPath(location.pathname)
		if (prevPathname.current !== currentPathnameRoot) {
			// If the root route has changed, reset search to an empty string
			props.setSearch('')
		} else {
			// Otherwise, set it to the props.search value
			props.setSearch(props.search)
		}
		prevPathname.current = currentPathnameRoot
	}, [location.pathname, props.search])

	const handleLogout = () => {
		logOutAction()
		sessionStorage.clear()
		navigate('/')
	}
	const [showAvatarMenu, setShowAvatarMenu] = useState(false)
	const [avatarMenuIsSelected, setAvatarMenuIsSelected] = useState(false)
	const [showSubMenu, setShowSubMenu] = useState(false)
	const pathname = window.location.pathname

	const handleAvatarClick = () => {
		setShowAvatarMenu(!showAvatarMenu)
		setShowSubMenu(false)
	}
	const avatarRoutesMap = {
		options: [
			{ endpoint: '/logs', title: 'Logs' },
			{ endpoint: '/settings', title: 'Settings' },
			{ endpoint: '/users', title: 'Users' },
			{ endpoint: '/personalSettings', title: 'Personal' },
			{ endpoint: handleLogout, title: 'Log Out' },
		],
		name: '_Avatar',
	}

	useEffect(() => {
		let found = false
		if (showAvatarMenu) {
			found = true
		} else {
			for (const option of avatarRoutesMap.options) {
				if (option.endpoint === pathname) {
					found = true
					break
				}
			}
		}
		setAvatarMenuIsSelected(found)
	}, [pathname, showAvatarMenu])

	return (
		<ThemeProvider theme={theme}>
			<Box
				sx={{ display: 'flex' }}
				style={{ visibility: firstLoading ? 'hidden' : 'visible' }}
			>
				{/* <Box
					component='nav'
					sx={{ width: { sm: 40 }, flexShrink: { sm: 0 } }}
					aria-label='mailbox folders'
				> */}
				<div
					style={{
						display: 'flex',
						height: '15vh',
						width: '100%',
						flexDirection: 'column',
					}}
				>
					<div
						style={{
							width: '100vw',
							height: 'inherit',
							display: 'flex',
							justifyContent: 'flex-end',
							flexDirection: 'column',
							borderRadius: '0px',
							backgroundImage: theme.colors.gradientMenu,
							backgroundSize: '100% 100%',
							backgroundPosition: '0px 0px',
							zIndex: '12',
							position: 'relative',
						}}
					>
						<div
							style={{
								height: '9vh',
								display: 'flex',
								alignItems: 'center',
								padding: '0rem 10rem 0rem 4rem',
								justifyContent: 'space-between',
							}}
						>
							<img src={logo} alt={'logo'} style={{ height: '90%' }}></img>
							<div
								style={{
									display: 'flex',
									width: '100%',
								}}
							>
								<TableSearchComponent
									style={{
										width: '36%',
										boxShadow: 'none',
										border: '1px solid rgba(0,0,0,0.12)',
										borderRadius: theme.customComponents.input.borderRadius,
										marginTop: '9px',
										marginLeft: '7vw',
										opacity: !searchPlaceholder ? '0.4' : '1',
										transition: '0.6s ease',
									}}
									setSearch={props.setSearch}
									search={props.search}
									// filterHandler={filterHandler}
									searchWithTimer={false}
									presetSearch={props.presetSearch}
									placeholder={searchPlaceholder}
								/>
							</div>
							<div
								style={{
									display: 'flex',
									width: 200,
								}}
							>
								<UserNameWithAvatar
									data-tag={'_navbarButton'}
									// withoutName
									avatars={avatars.avatars}
									value={login.user.name}
									position={login.user.position}
									country={login.user.location}
									onClick={handleAvatarClick}
									selected={avatarMenuIsSelected}
								/>
							</div>
						</div>
						<DrawerComponent
							setPopUpVisible={props.setPopUpVisible}
							isAuthorized={props.isAuthorized}
							setCurrentpage={setCurrentpage}
							navigate={navigate}
							avatarMenu={avatarRoutesMap}
							showSubMenu={showSubMenu}
							setShowSubMenu={setShowSubMenu}
							setLoading={props.setLoading}
						/>
						{showAvatarMenu && (
							<SubMenu
								currentSubMenu={avatarRoutesMap}
								subtitlesMap={{}}
								navigate={navigate}
								setShowSubMenu={setShowAvatarMenu}
								style={{ bottom: '-43%', zIndex: '20' }}
								setPopUpVisible={props.setPopUpVisible}
								setLoading={props.setLoading}
							/>
						)}
						{/* <div
								style={{
									backgroundColor: 'blue',
									// backgroundColor: theme.colors.base.grey,
									height: '14vh',
									width: '100vw',
									display: 'flex',
									position: 'absolute',
									bottom: '-43%',
									flexDirection: 'column',
									gap: '0px',
									boxShadow: '0px 4px 22px -20px rgba(0,0,0,1)',
									// paddingLeft: '8rem',
								}}
							/> */}
						{/* </Drawer> */}
					</div>
				</div>
			</Box>

			<Box component='main' sx={{ width: '100%', height: '0px' }}>
				{/* <Toolbar  /> */}
				<UserMessagePrompt
					PopUpVisible={props.isUserMessagePromptOpened}
					setPopUpVisible={props.setIsUserMessagePromptOpened}
					userMessage={props.userMessage}
					setUserMessage={props.setUserMessage}
				/>
				<ErrorPrompt
					errorMessage={props.errorMessage}
					setErrorMessage={props.setErrorMessage}
					PopUpVisible={props.isErrorPromptOpened}
					setPopUpVisible={props.setIsErrorPromptOpened}
					handleLogout={handleLogout}
				/>
				<SuccessPrompt
					message={'UPDATE SUCCESSFUL'}
					PopUpVisible={props.isSuccessPromptOpened}
					setPopUpVisible={props.setIsSuccessPromptOpened}
				/>
				{/* <RowViewPopUp
						row={viewRecord}
						setRow={setViewRecord}
						popUpVisible={isPreviewOpened}
						setPopUpVisible={setIsPreviewOpened}
					/> */}
				<WarningPrompt
					message={
						'Refreshing the Data may take some time (approximately 2-15 seconds)'
					}
					PopUpVisible={props.isWarningPromptOpened}
					setPopUpVisible={props.setIsWarningPromptOpened}
				/>

				{/* <Loading loading={loading} /> */}
				<FirstLoading
					loading={firstLoading}
					progress={progress}
					style={{ visibility: firstLoading ? 'visible' : 'hidden' }}
					isLongDownload={isLongDownload}
				/>

				<Outlet />
			</Box>
			{/* </Box> */}
		</ThemeProvider>
	)
}

const DrawerComponent = (props: {
	setPopUpVisible: any
	isAuthorized: any
	setCurrentpage: any
	navigate: any
	avatarMenu: any
	showSubMenu: boolean
	setShowSubMenu: Dispatch<SetStateAction<boolean>>
	setLoading: Dispatch<SetStateAction<boolean>>
}) => {
	const navigate = useNavigate()
	const pathname = window.location.pathname
	const [currentSubMenu, setCurrentSubMenu] = useState<any>({})
	const routesMap = generateRouteMap(props.isAuthorized)

	const subtitlesMap = {
		analytics: [
			{ groupName: 'Standard', endpoints: ['/network'] },
			{
				groupName: 'Advanced',
				endpoints: ['/aggregated', '/rawdata', '/invalid'],
			},
		],
	}

	return (
		<div
			style={{
				width: '100%',
				display: 'flex',
				flexDirection: 'column',
				position: 'relative',
			}}
		>
			<MenuComponent
				theme={theme}
				style={{
					display: 'flex',
					flexDirection: 'row',
					justifyContent: 'flex-start',
					alignItems: 'center',
					height: '5.5vh',
					whiteSpace: 'nowrap',
					position: 'relative',
					background: theme.colors.base.white,
					width: '100%',
					boxShadow: '0px 4px 16px -16px rgba(0,0,0,1)',
					zIndex: '12',
					// backgroundImage: `conic-gradient(from 0deg, ${theme.new_style.primary.secondary}, ${theme.new_style.primary.secondary} 90deg, ${theme.new_style.primary.main} 180deg, ${theme.new_style.primary.main} 270deg, ${theme.new_style.primary.main} 360deg)`,
				}}
			>
				<div
					style={{
						height: 'inherit',
						width: '10rem',
						backgroundColor: 'transparent',
					}}
				></div>
				<MenuButtonsComponent
					endpointsMap={routesMap}
					showSubMenu={props.showSubMenu}
					setShowSubMenu={props.setShowSubMenu}
					currentSubMenu={currentSubMenu}
					setCurrentSubMenu={setCurrentSubMenu}
					navigate={navigate}
					pathname={pathname}
					avatarMenu={props.avatarMenu}
					setPopUpVisible={props.setPopUpVisible}
				/>
			</MenuComponent>

			{props.showSubMenu && (
				<SubMenu
					currentSubMenu={currentSubMenu}
					subtitlesMap={subtitlesMap}
					navigate={navigate}
					setShowSubMenu={props.setShowSubMenu}
					setPopUpVisible={props.setPopUpVisible}
					setLoading={props.setLoading}
				/>
			)}
		</div>
	)
}

export default ResponsiveDrawer

const MenuButtonsComponent = ({
	endpointsMap,
	showSubMenu,
	setShowSubMenu,
	currentSubMenu,
	setCurrentSubMenu,
	navigate,
	pathname,
	avatarMenu,
	setPopUpVisible,
}: {
	endpointsMap: any
	showSubMenu: boolean
	setShowSubMenu: Dispatch<SetStateAction<boolean>>
	currentSubMenu: any
	setCurrentSubMenu: Dispatch<SetStateAction<any>>
	navigate: NavigateFunction
	pathname: string
	avatarMenu: any
	setPopUpVisible: Dispatch<SetStateAction<boolean>>
}) => {
	const [indicatorStyle, setIndicatorStyle] = useState<any>({
		left: '0px',
		width: '0px',
	})

	return (
		<div
			style={{
				display: 'flex',
				width: '100%',
				height: '100%',
				justifyContent: 'space-between',
				marginRight: '10rem',
			}}
		>
			{endpointsMap.map((el: any, index: number) => {
				return (
					<SingleMenuButtonComponent
						endpoint={el}
						navigate={navigate}
						selected={pathname}
						setIndicatorStyle={setIndicatorStyle}
						key={index}
						showSubMenu={showSubMenu}
						setShowSubMenu={setShowSubMenu}
						currentSubMenu={currentSubMenu}
						setCurrentSubMenu={setCurrentSubMenu}
						avatarMenu={avatarMenu}
						setPopUpVisible={setPopUpVisible}
					/>
				)
			})}

			<div
				style={{
					position: 'absolute',
					bottom: 0,
					backgroundColor: theme.customComponents.button.green,
					width: indicatorStyle.width,
					left: 0,
					transform: `translateX(${indicatorStyle.left})`,
					transition:
						'transform 0.5s ease-in-out 0s, width 0.2s ease-in-out 0s',
					height: '18%',
					borderRadius: '20px 20px 0px 0px',
					display: 'flex',
					willChange: 'transform, width',
				}}
			/>
		</div>
	)
}
const SingleMenuButtonComponent = ({
	endpoint,
	navigate,
	selected,
	setIndicatorStyle,
	showSubMenu,
	setShowSubMenu,
	currentSubMenu,
	setCurrentSubMenu,
	avatarMenu,
	setPopUpVisible,
}: {
	endpoint: any
	navigate: NavigateFunction
	selected: string
	setIndicatorStyle: any
	showSubMenu: boolean
	setShowSubMenu: Dispatch<SetStateAction<boolean>>
	currentSubMenu: any
	setCurrentSubMenu: Dispatch<SetStateAction<any>>
	avatarMenu: any
	setPopUpVisible: Dispatch<SetStateAction<boolean>>
}) => {
	const handleNavigation = () => {
		if (endpoint.options.length === 1 && buttonRef.current) {
			setShowSubMenu(false)
			navigate(endpoint.options[0].endpoint)
			setPopUpVisible(false)
		} else {
			if (currentSubMenu.name === endpoint.title) {
				setShowSubMenu(!showSubMenu)
			} else {
				setShowSubMenu(true)
			}
			setCurrentSubMenu({ options: endpoint.options, name: endpoint.title })
		}
	}
	const buttonRef = useRef(null)

	useEffect(() => {
		if (
			endpoint.options.length === 1 &&
			selected === endpoint.options[0].endpoint &&
			buttonRef.current
		) {
			const { offsetLeft, offsetWidth } = buttonRef.current
			const newIndication = {
				left: offsetLeft + 'px',
				width: offsetWidth + 'px',
			}
			setIndicatorStyle(newIndication)
		} else if (endpoint.options.length > 1) {
			for (const options of endpoint.options) {
				if (selected === options.endpoint && buttonRef.current) {
					const { offsetLeft, offsetWidth } = buttonRef.current
					const newIndication = {
						left: offsetLeft + 'px',
						width: offsetWidth + 'px',
					}
					setIndicatorStyle(newIndication)
				}
			}
		} else {
			for (const endpoint of avatarMenu.options) {
				if (selected === endpoint.endpoint) {
					const newIndication = {
						left: '0px',
						width: '0px',
					}
					setIndicatorStyle(newIndication)
					break
				}
			}
		}
	}, [selected])

	return (
		<>
			<NavbarButton
				key={endpoint.title}
				onClick={handleNavigation}
				ref={buttonRef}
				data-tag={'_navbarButton'}
			>
				{endpoint.title}
			</NavbarButton>
		</>
	)
}

const SubMenu = ({
	currentSubMenu,
	subtitlesMap,
	navigate,
	setShowSubMenu,
	style,
	setPopUpVisible,
	setLoading,
}: {
	currentSubMenu: any
	subtitlesMap: any
	navigate: NavigateFunction
	setShowSubMenu: Dispatch<SetStateAction<boolean>>
	style?: React.CSSProperties
	setPopUpVisible: Dispatch<SetStateAction<boolean>>
	setLoading: Dispatch<SetStateAction<boolean>>
}) => {
	const [options, setOptions] = useState<any>([])
	useEffect(() => {
		if (subtitlesMap[currentSubMenu.name.toLowerCase()]) {
			const groups: any = {}
			for (const endpoint of currentSubMenu.options) {
				for (const subtitle of subtitlesMap[
					currentSubMenu.name.toLowerCase()
				]) {
					if (subtitle.endpoints.includes(endpoint.endpoint)) {
						if (groups[subtitle.groupName]) {
							groups[subtitle.groupName].push(endpoint)
						} else {
							groups[subtitle.groupName] = [endpoint]
						}
						continue
					}
				}
			}
			const result = []
			for (const key in groups) {
				const obj = {
					title: key,
					options: groups[key],
				}
				result.push(obj)
			}
			setOptions(result)
		} else {
			setOptions([{ options: currentSubMenu.options }])
		}
	}, [currentSubMenu])

	const filterRef = useRef(null)
	const handleClickOutside = (event: any) => {
		const taggedElement = event.target.closest('[data-tag="_navbarButton"]')
		if (taggedElement) {
			if (
				filterRef.current &&
				!(filterRef.current as any).contains(taggedElement) &&
				taggedElement.dataset.tag !== '_menu' &&
				taggedElement.dataset.tag !== '_navbarButton'
			) {
				setShowSubMenu(false)
			}
		} else {
			if (
				filterRef.current &&
				!(filterRef.current as any).contains(event.target) &&
				event.target.dataset.tag !== '_menu' &&
				event.target.dataset.tag !== '_navbarButton'
			) {
				setShowSubMenu(false)
			}
		}
	}
	useEffect(() => {
		document.addEventListener('click', handleClickOutside)
		return () => {
			document.removeEventListener('click', handleClickOutside)
		}
	}, [])

	return (
		<div
			data-tag={'_menu'}
			style={{
				// backgroundColor: 'blue',
				backgroundColor: theme.colors.base.grey,
				height: '12vh',
				width: '100vw',
				zIndex: '12',
				display: 'flex',
				position: 'absolute',
				flexDirection: 'column',
				gap: '0px',
				boxShadow: '0px 4px 22px -20px rgba(0,0,0,1)',
				bottom: '-240%',
				// paddingLeft: '8rem',
				...style,
			}}
			ref={filterRef}
		>
			<div
				style={{
					display: 'flex',
					padding: '1rem 8rem',
					flexDirection: 'column',
					alignItems: 'flex-start',
					marginLeft: 'auto',
					marginRight: 'auto',
				}}
			>
				{options.length > 0 &&
					options.map((el: any, indx: any) => {
						return (
							<SubMenuOptions
								options={el.options}
								label={el.title}
								key={indx}
								navigate={navigate}
								setShowSubMenu={setShowSubMenu}
								setPopUpVisible={setPopUpVisible}
								setLoading={setLoading}
							/>
						)
					})}
			</div>
		</div>
	)
}
const SubMenuOptions = ({
	options,
	label,
	navigate,
	setShowSubMenu,
	setPopUpVisible,
	setLoading,
}: {
	options: any
	label?: string
	navigate: NavigateFunction
	setShowSubMenu: Dispatch<SetStateAction<boolean>>
	setPopUpVisible: Dispatch<SetStateAction<boolean>>
	setLoading: Dispatch<SetStateAction<boolean>>
}) => {
	return (
		<div
			style={{
				display: 'flex',
				flexDirection: 'row',
				gap: '14px',
				alignItems: 'center',
				justifyContent: 'center',
			}}
		>
			{label && (
				<span
					style={{
						display: 'flex',
						color: theme.colors.text.grey,
						padding: '8px 14px',
						fontSize: theme.font.size.button,
						fontWeight: theme.font.weight.normal,
						width: '5vw',
					}}
				>
					{label}:
				</span>
			)}
			{options.map((el: any) => {
				return (
					<SubMenuButton
						endpoint={el.endpoint}
						title={el.title}
						key={el.endpoint}
						navigate={navigate}
						setShowSubMenu={setShowSubMenu}
						setPopUpVisible={setPopUpVisible}
						setLoading={setLoading}
					/>
				)
			})}
		</div>
	)
}
const SubMenuButton = ({
	endpoint,
	title,
	navigate,
	setShowSubMenu,
	setPopUpVisible,
	setLoading,
}: {
	endpoint: string | Function
	title: string
	navigate: NavigateFunction
	setShowSubMenu: Dispatch<SetStateAction<boolean>>
	setPopUpVisible: Dispatch<SetStateAction<boolean>>
	setLoading: Dispatch<SetStateAction<boolean>>
}) => {
	return (
		<button
			onClick={() => {
				if (typeof endpoint === 'string') {
					setLoading(true)
					//TODO: THIS IS TEMPORAL AND NEEDS TO BE REMOVED ONCE WE FIX THE DELAY
					setTimeout(() => navigate(endpoint), 50)
					setPopUpVisible(false)
				} else if (typeof endpoint === 'function') {
					endpoint()
				}
				setShowSubMenu(false)
			}}
			style={{
				padding: '8px 14px',
				border: 'none',
				cursor: 'pointer',
			}}
		>
			·{' '}
			<span
				style={{
					textDecoration: 'underline',
					fontSize: theme.font.size.buttons,
					fontWeight: theme.font.weight.normal,
				}}
			>
				{title}
			</span>
		</button>
	)
}

const generateRouteMap = (isAuthorized: any) => {
	const routesMap = [
		{ options: [{ endpoint: '/news' }], title: 'News' },
		{ options: [{ endpoint: '/dashboard' }], title: 'Dashboard' },
		{ options: [{ endpoint: '/campaigns' }], title: 'Campaigns' },
		{
			options: [{ endpoint: '/advertisers' }],
			title: 'Advertisers',
		},
		{
			options: [{ endpoint: '/publishers' }],
			title: 'Supply',
		},
		{
			options: [
				{ endpoint: '/network', title: 'Network' },
				{ endpoint: '/aggregated', title: 'Aggregated' },
				// { endpoint: '/rawdata', title: 'Rawdata' }, // this one's for you renny!
				{ endpoint: '/invalid', title: 'Invalid' },
			],
			title: 'Analytics',
		},
		{
			options: [
				{ endpoint: '/finance/control', title: 'Control' },
				{ endpoint: '/finance/personal', title: 'Personal' },
				{ endpoint: '/finance/mediabuying', title: 'Media Buying' },
				{ endpoint: '/finance/creator', title: 'Creative' },
				{ endpoint: '/finance/global', title: 'Global' },
				{ endpoint: '/finance/demand', title: 'Demand' },
				{ endpoint: '/finance/supply', title: 'Supply' },
				{ endpoint: '/finance/creatorsupply', title: 'Creative Supply' },
				{ endpoint: '/finance/balancein', title: 'Balance In' },
				{ endpoint: '/finance/balanceout', title: 'Balance Out' },
			],
			title: 'Finance',
		},
		{
			options: [
				{ endpoint: '/autoswitch', title: 'Autoswitch' },
				{ endpoint: '/passwords', title: 'Passwords' },
				{ endpoint: '/documents', title: 'Documents' },
				{ endpoint: '/p360apps', title: 'P360 (Apps)' },
				{ endpoint: '/adsplacement', title: 'Ads Placement' },
			],
			title: 'Tools',
		},
	]
	//We are going to remove endpoints based on permissions. If the permission has only one endpoint then we will remove the main
	//object. If the permission has mutiple options we will remove the inside option.

	for (let i = 0; i < routesMap.length; i++) {
		const mainRoute = routesMap[i]
		if (mainRoute.options.length > 1) {
			for (let k = 0; k < mainRoute.options.length; k++) {
				const insideOption = mainRoute.options[k]
				if (!isAuthorized(insideOption.endpoint)) {
					routesMap[i].options.splice(k, 1)
					//since we are modifying the length of the array we need to also turn our variable down too.
					k--
				}
			}
			if (routesMap[i].options.length === 0) {
				routesMap.splice(i, 1)
				//since we are modifying the length of the array we need to also turn our variable down too.
				i--
			}
		} else {
			if (!isAuthorized(mainRoute.options[0].endpoint)) {
				routesMap.splice(i, 1)
				//since we are modifying the length of the array we need to also turn our variable down too.
				i--
			}
		}
	}

	return routesMap
}
