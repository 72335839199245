import { DialogActions, DialogContent } from '@mui/material'
import { useState } from 'react'
import { useActions } from '../../hooks/useActions'
import { useTypedSelector } from '../../hooks/useTypedSelector'
import { fetchData } from '../../utils/helpers/navigationHelper'
import { StyledDialog, StyledNameField } from '../components/reusableComponents'
import { InputFieldText } from '../components/Inputs'
import { DropList } from '../components/SelectableInputs'
import { SubmitButton, UnborderedButton } from '../components/Buttons'

const AccountsEdit = (props: {
	PopUpVisible: boolean
	setPopUpVisible: any
	row?: any
	setLoading: any
	errorMessage: null | string
	setErrorMessage: any
	isErrorPromptOpened: boolean
	setIsErrorPromptOpened: any
	advertisers: any
	setIsSuccessPromptOpened: any
	isSuccessPromptOpened: any
	blockPasswords: any
	isAdding: any
	setIsAdding: any
	setRow: any
}): JSX.Element => {
	const { updateAccountsAction, addAccountsAction, getAccountsAction } =
		useActions()
	const { login, users, accounts } = useTypedSelector((state) => state)
	const [username, setUsername] = useState(
		!!props.row ? props.row.user_name || '' : '',
	)
	const [name, setName] = useState('')
	const [password, setPassword] = useState(
		!!props.row ? props.row.password || '' : '',
	)
	const [identifier, setIdentifier] = useState(
		!!props.row ? props.row.identifier || '' : '',
	)
	const [token, setToken] = useState(!!props.row ? props.row.token || '' : '')
	const [createdBy, setCreatedBy] = useState(
		!!props.row ? props.row.created_by || '' : '',
	)
	const [mmp, setMmp] = useState(!!props.row ? props.row.mmp || '' : '')

	// const [isValid, setIsValid] = useState(false)
	const createPayload = () => {
		if (props.row) {
			return {
				user_name: username,
				password: password,
				identifier: identifier,
				token: token,
				user_login: login.user.email,
				created_by: createdBy === '' ? login.user.email : createdBy,
				mmp: mmp,
				uuid: props.row.uuid,
				name: name,
			}
		} else {
			return {
				user_name: username,
				password: password,
				identifier: identifier,
				token: token,
				user_login: login.user.email,
				mmp: mmp,
				created_by: createdBy === '' ? login.user.email : createdBy,
				name: name,
			}
		}
	}
	const nullifyFields = () => {
		setUsername('')
		setPassword('')
		setCreatedBy('')
		setIdentifier('')
		setToken('')
		setMmp('')
		setName('')
		props.setRow(undefined)
	}

	const trySubmit = async () => {
		const headers = {
			Authorization: `Token ${login.user.token}`,
		}
		const payload = { dto: createPayload() }
		props.setLoading(true)
		if (props.row) {
			payload.dto.uuid = props.row.uuid
			await updateAccountsAction(headers, payload)
		} else {
			await addAccountsAction(headers, payload)
		}
		props.setIsAdding(false)
		props.setLoading(false)
		if (!props.errorMessage) {
			props.setPopUpVisible(false)
			if (props.row) {
				props.setIsSuccessPromptOpened(true)
			}
		}
		fetchData(login, getAccountsAction, accounts.accounts)
	}
	return (
		<StyledDialog open={props.PopUpVisible}>
			<DialogContent
				style={{
					display: 'flex',
					flexDirection: 'column',
					gap: '18px',
				}}
			>
				{createdBy && login.user.role.toLowerCase() === 'super' && (
					<InputFieldText
						label='Created by'
						type='text'
						value={createdBy}
						disabled={true}
					/>
				)}

				<InputFieldText
					label='Username'
					type='text'
					value={username}
					onChange={setUsername}
				/>
				<InputFieldText
					label='Password'
					type='text'
					value={
						(!props.isAdding &&
							!props.blockPasswords &&
							props.row?.whitelist.includes(login.user.name)) ||
						props.isAdding ||
						(!props.blockPasswords && props.row?.access === 'Open')
							? password
							: '******'
					}
					onChange={setPassword}
				/>
				<DropList
					onChange={setMmp}
					options={['appsflyer', 'adjust']}
					value={mmp}
					label={'MMP'}
				></DropList>
				<InputFieldText
					label='Identifier (used in backend)'
					type='text'
					value={identifier}
					onChange={setIdentifier}
				/>
				<InputFieldText
					label='Token (used in backend)'
					type='text'
					value={token}
					onChange={setToken}
				/>
				<InputFieldText
					label='Name (used in P360 scheduler)'
					type='text'
					value={name}
					onChange={setName}
				/>
			</DialogContent>
			<DialogActions style={{ paddingBottom: '28px' }}>
				<UnborderedButton
					style={{ margin: 'auto' }}
					onClick={() => {
						props.setPopUpVisible(false)
						nullifyFields()
					}}
					label={'Cancel'}
				></UnborderedButton>
				{(!props.row ||
					props.row?.created_by === login.user.email ||
					login.user.role.toLowerCase() === 'super') && (
					<SubmitButton
						//disabled={!isValid}
						style={{ margin: 'auto' }}
						onClick={() => {
							trySubmit()
							props.setPopUpVisible(false)
							nullifyFields()
						}}
					>
						{props.row ? 'Update' : 'Save'}
					</SubmitButton>
				)}
			</DialogActions>
		</StyledDialog>
	)
}
export default AccountsEdit
