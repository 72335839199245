import { Grid } from '@mui/material'
import { checkInputStartsWithHttp } from '../../utils/helpers/tableHelper'
import { countryList } from '../components/countryList'
import { StyledNameField } from '../components/reusableComponents'
import {
	getApplicableRules,
	stringIsValidName,
} from '../../utils/helpers/helperFuncs'
import moment from 'moment'
import { useTypedSelector } from '../../hooks/useTypedSelector'
import { InputFieldText, StyledTextAreaField } from '../components/Inputs'
import {
	OwnerSelect,
	DropList,
	CountryListSelector,
} from '../components/SelectableInputs'
import { ChipInputList } from '../components/ChipInput'
import { SubmitButton } from '../components/Buttons'
import { useState } from 'react'

interface Owner {
	owner: string
	finishDate: number
}

//this is the main component for editing an advertiser. Here is the logic that handles every input.
export const CompanyInfoComponent = (props: {
	name: string | null
	setName: any
	legalName: string
	setLegalName: any
	address: string
	setAddress: any
	geo: string[]
	setGeo: any
	linkToIO: string
	setLinkToIO: any
	setOwnerEmail: any
	ownerEmail: string | null
	setSalesRep: any
	salesRep: string
	setStatus: any
	status: boolean
	setApps: any
	apps: string[]
	setPlatforms: any
	platforms: string[] | null
	setFraudTool: any
	fraudTool: any
	linkToAdvFile: string
	setLinkToAdvFile: any
	linkToCreative: string
	setLinkToCreative: any
	team: string
	setTeam: any
	communicationChannel?: string[]
	setCommunicationChannel?: any
	advertiserType: string
	setAdvertiserType: any
	vertical?: string[]
	setVertical?: any
	isValid?: boolean
	settings: any
	loginRole: string
	createdBy: string
	loginUser: string
	appList: string[]
	setMainHeightChange: any
	pauseReason: string
	setPauseReason: any
	pauseExplanation: string
	setPauseExplanation: any
	ViewPauseBox: boolean
	setViewPauseBox: any
	setStatusChanged: any
	website: string
	setWebsite: any
	linkedin: string
	setLinkedin: any
	brief: string
	setBrief: any
	specialRequest: string
	setSpecialRequest: any
	pastOwner: Owner[]
	setPastOwner: any
	pastOwnerFinance: any
	setPastOwnerFinance: any
	parametersTemplate: string
	setParametersTemplate: any
	users: any
	mandatoryFields?: any
	fieldValidations?: any
}) => {
	const { settings, login, app, advertiser, publisher, users } =
		useTypedSelector((state) => state)
	const [nameTouched, setNameTouched] = useState(false)

	const ruleSet = getApplicableRules(users, login, settings, 'advertiser')
	const weekCheckerBetweenDates = (date1: number, date2: number) => {
		const WEEK_IN_MS = 604800000
		if (date1 + WEEK_IN_MS >= date2) {
			return false
		}
		return true
	}
	//everytime we change the owner we want to track who was the previous owner. We will only update if 1 week or more happened
	const handlePastOwner = (newOwner: string) => {
		if (newOwner !== props.ownerEmail) {
			const currentDate = Date.now()
			if (
				props.pastOwner.length === 0 ||
				weekCheckerBetweenDates(
					props.pastOwner[props.pastOwner.length - 1].finishDate,
					currentDate,
				)
			) {
				const pastOwner: Owner = {
					owner: props.ownerEmail!,
					finishDate: currentDate,
				}
				props.setPastOwner((prev: any) =>
					prev ? [...prev, pastOwner] : [pastOwner],
				)
			}
		}
	}
	const handlePastOwnerFinance = (newOwner: string) => {
		if (newOwner !== props.ownerEmail) {
			const currentFinanceDate = moment()
			currentFinanceDate.add(5, 'days')
			const currentFinancePeriod = currentFinanceDate.format('01/MM/YYYY')
			const userName = props.users.users.filter(
				(el: any) =>
					el.email.toLowerCase() === (props.ownerEmail as string).toLowerCase(),
			)[0]['name']

			const financePastOwner = {
				owner: props.ownerEmail,
				ownerName: userName,
				financePeriod: currentFinancePeriod,
			}
			const financeRuleSet = getApplicableRules(
				users,
				{ user: { email: props.ownerEmail } },
				settings,
				'finance',
			)

			const viewPersonal = financeRuleSet?.rules?.['View Personal'] || false
			const viewMediaBuying =
				financeRuleSet?.rules?.['View Media Buying'] || false

			if (!financeRuleSet || !financeRuleSet.rules) {
				console.error('Finance rule set is undefined or invalid.')
				return
			}

			if (viewPersonal || viewMediaBuying) {
				const updatedArr = []
				if (props.pastOwnerFinance.length > 0) {
					for (const oldOwner of props.pastOwnerFinance) {
						if (oldOwner.financePeriod === currentFinancePeriod) {
							oldOwner.owner = props.ownerEmail
							oldOwner.ownerName = userName
						}
						updatedArr.push(oldOwner)
					}
				} else {
					updatedArr.push(financePastOwner)
				}
				props.setPastOwnerFinance(updatedArr)
			} else {
				console.warn('No permission to view personal or media buying')
			}
		}
	}
	// advertisers of type 'Direct' can only have one platform
	const validateDirectType = (value: string) => {
		let isError = false
		// if (value === 'Direct' && props.platforms!.length > 1) {
		// 	isError = true
		// }
		return isError
	}
	return (
		<Grid
			container
			columns={12}
			columnSpacing={6}
			rowSpacing={4}
			direction='row'
			style={{ width: '98%' }}
		>
			{props.ViewPauseBox && (
				<>
					<div
						style={{
							background: 'rgba(0,0,0,0.2)',
							width: '120vw',
							height: '200vh',
							display: 'flex',
							top: '-10%',
							left: '-10%',
							position: 'absolute',
							zIndex: '1',
						}}
					></div>
					<div
						style={{
							background: 'white',
							position: 'absolute',
							display: 'flex',
							top: '40%',
							left: '50%',
							transform: 'translate(-50%, -50%)',
							border: '1px solid black',
							borderRadius: '30px',
							// left: '30%',
							flexDirection: 'column',
							zIndex: '10',
							height: '24%',
							width: '32%',
							padding: '2rem 4rem 2rem 4rem',
						}}
					>
						<p style={{ color: 'black', width: '110%', marginLeft: '-12px' }}>
							Please, note that disabling the advertiser will also disable all
							the campaigns connected to it.
						</p>
						<SubmitButton
							disabled={!ruleSet.post}
							style={{ margin: 'auto' }}
							onClick={() => {
								props.setViewPauseBox(false)
							}}
						>
							Close
						</SubmitButton>
					</div>
				</>
			)}
			<Grid item xs={3}>
				<InputFieldText
					label='Company Name'
					placeholder='Company Name'
					type='text'
					value={props.name}
					onChange={(e: string) => {
						setNameTouched(true)
						props.setName(e)
					}}
					disabled={!ruleSet.post}
					errormessage={
						nameTouched && !stringIsValidName(props.name as string)
							? "Company name is mandatory. Company name must not start or end with whitespace and must not contain '/', '%', ',' or '.'"
							: undefined
					}
					mandatory={!props.fieldValidations.name}
				/>
			</Grid>
			<Grid item xs={3}>
				<InputFieldText
					label='Legal Name'
					placeholder='Legal Name'
					type='text'
					value={props.legalName}
					onChange={props.setLegalName}
					disabled={!ruleSet.post}
					mandatory={!props.fieldValidations.legalName}
				/>
			</Grid>
			<Grid item xs={3}>
				<InputFieldText
					label='Company Address'
					type='text'
					value={props.address}
					onChange={props.setAddress}
					disabled={!ruleSet.post}
				/>
			</Grid>
			<Grid item xs={3}>
				<CountryListSelector
					label='Head Office Geo'
					options={countryList}
					value={props.geo}
					singular
					onChange={(e: any) => props.setGeo(e)}
					disabled={!ruleSet.post}
				/>
			</Grid>
			<Grid item xs={3}>
				{props.ownerEmail && (
					<OwnerSelect
						onChange={(e: any) => {
							handlePastOwner(e)
							handlePastOwnerFinance(e)
							props.setOwnerEmail(e)
						}}
						//options={[]}
						label={'Owner'}
						value={props.ownerEmail}
						disabled={!ruleSet.post}
					/>
				)}
			</Grid>
			<Grid item xs={3}>
				<ChipInputList
					onChange={props.setPlatforms}
					options={
						props.settings.settings.platforms
							? props.settings.settings.platforms.sort()
							: []
					}
					label={'Platforms'}
					errormessage={
						validateDirectType(props.advertiserType)
							? 'Direct-type advertisers must have only one platform'
							: undefined
					}
					openWithClick
					value={props.platforms || []}
					disabled={!ruleSet.post}
					onlyOptionsAllowed
				/>
			</Grid>
			<Grid item xs={3}>
				<DropList
					onChange={(e: any) => props.setSalesRep(e)}
					options={props.settings.settings.salesRepresentative}
					label={'Sales Representative'}
					value={props.salesRep}
					disabled={!ruleSet.post}
					noNull={true}
				/>
			</Grid>
			<Grid item xs={3}>
				<ChipInputList
					onChange={(e: any) => {
						props.setCommunicationChannel(e)
					}}
					options={
						props.settings.settings.communicationChannel
							? props.settings.settings.communicationChannel.sort()
							: []
					}
					label={'Communication channel'}
					openWithClick
					value={props.communicationChannel}
					disabled={!ruleSet.post}
					onlyOptionsAllowed
				/>
			</Grid>
			<Grid item xs={3}>
				<DropList
					onChange={props.setAdvertiserType}
					options={['Direct', 'Network', 'Agency']}
					label={'Type'}
					value={props.advertiserType}
					disabled={!ruleSet.post}
					noNull={true}
				/>
			</Grid>
			<Grid item xs={3}>
				<InputFieldText
					label='LinkedIn'
					type='text'
					value={props.linkedin}
					onChange={props.setLinkedin}
					color={
						props.linkedin &&
						!checkInputStartsWithHttp(props.linkedin) &&
						props.isValid === false
							? 'error'
							: 'primary'
					}
					disabled={!ruleSet.post}
					errormessage={
						props.linkedin
							? !checkInputStartsWithHttp(props.linkedin)
								? "Links must start with 'http://' or 'https://'"
								: undefined
							: undefined
					}
					showUrlIcon={true}
				/>
			</Grid>
			<Grid item xs={3}>
				<ChipInputList
					onChange={props.setFraudTool}
					options={
						props.settings.settings.fraudTools
							? props.settings.settings.fraudTools.sort()
							: []
					}
					label={'Fraud Tool'}
					openWithClick
					value={props.fraudTool}
					disabled={
						props.loginRole === 'view' || props.advertiserType === 'Network'
					}
					onlyOptionsAllowed
				/>
			</Grid>
			<Grid item xs={3}>
				<InputFieldText
					label='Link to Google Drive'
					type='text'
					value={props.linkToIO}
					onChange={props.setLinkToIO}
					color={
						props.linkToIO &&
						!checkInputStartsWithHttp(props.linkToIO) &&
						props.isValid === false
							? 'error'
							: 'primary'
					}
					disabled={!ruleSet.post}
					errormessage={
						props.linkToIO
							? !checkInputStartsWithHttp(props.linkToIO)
								? "Links must start with 'http://' or 'https://'"
								: undefined
							: undefined
					}
					showUrlIcon={true}
				/>
			</Grid>
			<Grid item xs={3}>
				<InputFieldText
					label='Website'
					type='text'
					value={props.website}
					onChange={props.setWebsite}
					color={
						props.website &&
						!checkInputStartsWithHttp(props.website) &&
						props.isValid === false
							? 'error'
							: 'primary'
					}
					disabled={!ruleSet.post}
					errormessage={
						props.website
							? !checkInputStartsWithHttp(props.website)
								? "Links must start with 'http://' or 'https://'"
								: undefined
							: undefined
					}
					showUrlIcon={true}
				/>
			</Grid>
			<Grid item xs={3}>
				<DropList
					onChange={(e: any) => {
						props.setStatus(e === 'Active' ? true : false)
						props.setStatusChanged(true)
					}}
					options={['Active', 'Disabled']}
					label={'Status'}
					value={props.status === true ? 'Active' : 'Disabled'}
					disabled={!ruleSet.post}
				/>
			</Grid>
			<Grid item xs={3}>
				<ChipInputList
					onChange={props.setVertical}
					options={
						props.settings.settings.vertical
							? props.settings.settings.vertical.sort()
							: []
					}
					label={'Vertical'}
					openWithClick
					value={props.vertical}
					disabled={!ruleSet.post}
					onlyOptionsAllowed
					errored={
						props.advertiserType === 'Direct' &&
						props.vertical &&
						props.vertical.length === 0
					}
				/>
			</Grid>
			<Grid item xs={3}>
				<InputFieldText
					label='Link to Creative'
					type='text'
					value={props.linkToCreative}
					onChange={props.setLinkToCreative}
					color={
						props.linkToCreative &&
						!checkInputStartsWithHttp(props.linkToCreative) &&
						props.isValid === false
							? 'error'
							: 'primary'
					}
					disabled={!ruleSet.post}
					errormessage={
						props.linkToCreative
							? !checkInputStartsWithHttp(props.linkToCreative)
								? "Links must start with 'http://' or 'https://'"
								: undefined
							: undefined
					}
					showUrlIcon={true}
				/>
			</Grid>
			{props.status === false && (
				<>
					<Grid item xs={3}>
						<DropList
							onChange={props.setPauseReason}
							options={
								props.settings.settings.platforms
									? props.settings.settings.pauseReason.sort()
									: []
							}
							label={'Pause Reason'}
							value={props.pauseReason}
							multiple={false}
							disabled={!ruleSet.post}
							noNull={true}
						/>
					</Grid>
					<Grid item xs={3}>
						<InputFieldText
							label='Explanation'
							type='text'
							value={props.pauseExplanation}
							onChange={props.setPauseExplanation}
							disabled={!ruleSet.post}
						/>
					</Grid>
				</>
			)}

			<Grid item xs={12}>
				<ChipInputList
					onChange={(e: any) => props.setApps(e)}
					label={'App Ids'}
					options={props.appList}
					value={props.apps}
					hint='iOS: The format for the app id should be idxxxxxxxxx &nbsp;
						Android: The format for the app id should be com.xxxx.xxxx'
					errored={props.advertiserType === 'Direct' && props.apps.length === 0}
					disabled={
						props.loginRole === 'view' ||
						(props.ownerEmail !== '' &&
							props.loginRole === 'user' &&
							props.ownerEmail !== props.loginUser) ||
						props.advertiserType === 'Network'
					}
				/>
			</Grid>
			<Grid item xs={4}>
				<StyledTextAreaField
					label='Brief'
					value={props.brief}
					onChange={props.setBrief}
				/>
			</Grid>
			<Grid item xs={4}>
				<StyledTextAreaField
					label='Special Request'
					value={props.specialRequest}
					onChange={props.setSpecialRequest}
				/>
			</Grid>
			<Grid item xs={4}>
				<StyledTextAreaField
					label='Parameters Template'
					value={props.parametersTemplate}
					onChange={props.setParametersTemplate}
				/>
			</Grid>
			{/* </Grid>
			<Grid item xs={3}={4}> */}
		</Grid>
	)
}
