import React, { Dispatch, SetStateAction, useEffect, useState } from 'react'
import { ThemeProvider } from 'styled-components'
import theme from '../../utils/theme2024'
import { PageWrapper } from '../components/reusableComponents'
import { useTypedSelector } from '../../hooks/useTypedSelector'
import { TitleComponent } from '../components/Informative'
import { Grid } from '@mui/material'
import { InputFieldText } from '../components/Inputs'
import { SwitchComponent } from '../components/ToggleComponents'
import {
	PidButton,
	SubmitButton,
	UnborderedButton,
} from '../components/Buttons'
import { fetchData } from '../../utils/helpers/navigationHelper'
import { useActions } from '../../hooks/useActions'
import HandshakeEdit from './HandshakeEdit'
import { TableComponent } from '../components/TableComponents'
import { generateRowsWithIds } from '../../utils/helpers/tableHelper'
import { GridColDef } from '@mui/x-data-grid-pro'
import { v4 as uuidv4 } from 'uuid'
import './handshake.css'
import { DropList } from '../components/SelectableInputs'

const HandshakePage = (props: {
	setLoading: any
	errorMessage: any
	setErrorMessage: any
	isErrorPromptOpened: any
	setIsErrorPromptOpened: any
	isSuccessPromptOpened: any
	setIsSuccessPromptOpened: any
	isEditorOpened?: any
	isAddEditorOpened?: any
	PopUpVisible: any
	setPopUpVisible: any
	search: string
	setSearch: Dispatch<SetStateAction<string>>
	presetSearch: string
	setPresetSearch: Dispatch<SetStateAction<string>>
}) => {
	const { settings, publisher, handshake, login } = useTypedSelector(
		(state) => state,
	)
	const { getHandshakeAction, updateHandshake } = useActions()

	const [searchValue, setSearchValue] = useState('')
	const [checkByPid, setCheckByPid] = useState(true)
	const [checkByPublisher, setCheckByPublisher] = useState(false)
	const [checkByAgency, setCheckByAgency] = useState(false)
	const [filtered, setFiltered] = useState<any>([])
	const [handShakeEditorOpen, setHandShakeEditorOpen] = useState(false)
	const [editablePid, setEditablePid] = useState<string>('')
	const [editablePublisher, setEditablePublisher] = useState<any>({})
	const [handshakeArr, setHandshakeArr] = useState([])
	const [pageSize, setPageSize] = useState(15)
	const [optionSelected, setOptionSelected] = useState('PID')
	const rowCount = 12
	const [rowWidth, setRowWidth] = useState(
		Math.floor((window.innerWidth - theme.dimentions.drawerWidth) / rowCount),
	)
	const headers: any = {
		Authorization: `Token ${login.user.token}`,
	}
	const agencyAccounts = [
		'thingortwo',
		'smartassmeil951',
		'tatanka',
		'weknowil870',
		'appsadvil761',
	]

	useEffect(() => {
		fetchData(login, getHandshakeAction, handshake)
		//we do one search the first time so we get data the first time.
		startSearch()
	}, [])
	useEffect(() => {
		if (searchValue) {
			startSearch()
		}
	}, [checkByAgency, checkByPid, checkByPublisher])

	// useEffect(() => {
	// 	window.addEventListener('keydown', handleEnterPress)

	// 	// Cleanup the event listener on component unmount
	// 	return () => {
	// 		window.removeEventListener('keydown', handleEnterPress)
	// 	}
	// }, [])

	const startSearch = () => {
		let result: any = []
		const searchFormatted = searchValue.toLowerCase().trim()
		if (checkByPid) {
			const pidFound = handshake.handshake.filter((el: any) => {
				return el.pid.toLowerCase().trim().includes(searchFormatted)
			})
			const arr = []
			for (const pub of publisher.publisher) {
				const obj = { publisher: pub.publisher_name, pid: [] as any }
				let added = false
				for (const pid of pub.media_source_pid) {
					const pidChecking = pid.toLowerCase().trim()
					for (const el of pidFound) {
						if (el.pid.toLowerCase().trim() === pidChecking) {
							obj.pid.push(el)
							added = true
						}
					}
				}
				if (added) {
					arr.push(obj)
				}
			}
			result = [...arr]
		} else if (checkByPublisher) {
			const publishersFound = publisher.publisher.filter((el: any) => {
				// console.log(el)
				return el.publisher_name.toLowerCase().trim().includes(searchFormatted)
			})
			for (const pub of publishersFound) {
				const found = { publisher: pub.publisher_name, pid: [] as any }
				const pids = pub.media_source_pid.filter(
					(el: string) => !el.endsWith(pub.external_id),
				)
				for (const el of handshake.handshake) {
					for (const pid of pids) {
						if (pid.toLowerCase().trim() === el.pid.toLowerCase().trim()) {
							found.pid.push(el)
							break
						}
					}
				}
				result.push(found)
			}
		} else if (checkByAgency) {
			const filtered = handshake.handshake.filter((el: any) => {
				for (const key in el.handshake) {
					if (key.toLowerCase().trim().includes(searchFormatted)) {
						if (el.handshake[key] === true) {
							return true
						}
					}
				}
				return false
			})
			const arr = []
			for (const pub of publisher.publisher) {
				const obj = { publisher: pub.publisher_name, pid: [] as any }
				let added = false
				for (const pid of pub.media_source_pid) {
					const pidChecking = pid.toLowerCase().trim()
					for (const el of filtered) {
						if (el.pid.toLowerCase().trim() === pidChecking) {
							obj.pid.push(el)
							added = true
						}
					}
				}
				if (added) {
					arr.push(obj)
				}
			}
			result = [...arr]
			// console.log(filtered)
		}
		const tableRows = []
		// console.log(result)
		setFiltered(generateRowsWithIds(result))
	}
	const handleEnterPress = (event: any) => {
		if (event.key === 'Enter') {
			// Call your function here
			startSearch()
		}
	}

	const handleChangeFilter = (e: string) => {
		if (e === 'PID') {
			setCheckByPublisher(false)
			setCheckByPid(true)
			setCheckByAgency(false)
			setEditablePublisher({})
		} else if (e === 'Publisher') {
			setCheckByPublisher(true)
			setCheckByPid(false)
			setCheckByAgency(false)
			setEditablePublisher({})
		} else if (e === 'Agency') {
			setCheckByPublisher(false)
			setCheckByPid(false)
			setCheckByAgency(true)
			setEditablePublisher({})
		}
		setOptionSelected(e)
	}

	const updateData = async () => {
		const dataToUpload = handshakeArr.filter((el: any) => el.pid)
		const failed = await updateHandshake(
			headers,
			dataToUpload,
			props.setErrorMessage,
		)
		if (!failed) {
			props.setIsSuccessPromptOpened(true)
		}
		setHandshakeArr([])
	}

	const columns: GridColDef[] = [
		{
			field: 'publisher',
			headerName: 'Publisher',
			width: rowWidth * 0.8,
			renderCell: (row) => {
				if (checkByPublisher) {
					const data = { pid: row.row.publisher }
					return (
						<PidButton
							el={data}
							onClick={() => {
								setHandShakeEditorOpen(true)
								setEditablePublisher(row.row)
								// setEditablePid(el.pid.toLowerCase().trim())
							}}
						/>
					)
				}
				return <>{row.value}</>
			},
		},
		{
			field: 'pid',
			headerName: 'pid',
			width: rowWidth * 11,
			renderCell: (row) => {
				return (
					<Grid
						container
						columnSpacing={1}
						rowSpacing={1}
						direction='row'
						style={{
							marginTop: 8,
							marginBottom: 12,
						}}
					>
						{row.row.pid.length > 0 &&
							row.row.pid.map((el: any, index: number) => {
								return (
									<PidHandshakeContainer
										el={el}
										index={index}
										setHandShakeEditorOpen={setHandShakeEditorOpen}
										setEditablePid={setEditablePid}
										handshakeArr={handshakeArr}
										key={uuidv4()}
										setEditablePublisher={setEditablePublisher}
									/>
								)
							})}
					</Grid>
				)
			},
		},
	]

	return (
		<ThemeProvider theme={theme}>
			<PageWrapper
				style={{
					gap: 0,
					display: 'flex',
					flexDirection: 'column',
					marginLeft: 30,
					marginRight: 30,
				}}
			>
				<div
					style={{
						display: 'flex',
						justifyContent: 'flex-start',
						alignItems: 'flex-start',
						flexDirection: 'column',
						marginBottom: 12,
					}}
				>
					<TitleComponent>PID Handshake</TitleComponent>

					{/* <p style={{ color: theme.colors.text.titles, opacity: '0.4' }}>
						Choose the filters you need and find the ideal publishers for your
						campaign
					</p> */}
				</div>
				{/* <hr
					style={{
						width: '60%',
						color: theme.colors.text.titles,
						opacity: '0.4',
					}}
				></hr> */}
				<Grid
					container
					columnSpacing={2}
					direction='row'
					style={{ marginTop: 12 }}
				>
					<Grid item xs={4} style={{ display: 'flex', flexDirection: 'row' }}>
						<DropList
							onChange={handleChangeFilter}
							options={['PID', 'Publisher', 'Agency']}
							label={''}
							value={optionSelected}
							style={{ width: '30%' }}
							noClearOption
						/>
						<InputFieldText
							label='Search'
							placeholder='Search'
							type='text'
							value={searchValue}
							onChange={(e: string, event: any) => {
								setSearchValue(e)
							}}
							onKeyDown={handleEnterPress}
							style={{
								width: '100%',
								marginLeft: -4,
								borderRadius: '0px 6px 6px 0px',
							}}
						/>
					</Grid>
					{/* <Grid item xs={1}> */}
					{/* <SwitchComponent
							style={{ marginTop: 4 }}
							onChange={(e) => {
								setCheckByPublisher(false)
								setCheckByPid(e)
								setCheckByAgency(false)
								setEditablePublisher({})
							}}
							value={checkByPid}
							label={'By PID'}
							disabled={false}
						/> */}
					{/* </Grid> */}
					{/* <Grid item xs={1}>
						<SwitchComponent
							style={{ marginTop: 4 }}
							onChange={(e) => {
								setCheckByPublisher(e)
								setCheckByPid(false)
								setCheckByAgency(false)
								setEditablePublisher({})
							}}
							value={checkByPublisher}
							label={'By Publisher'}
							disabled={false}
						/>
					</Grid>
					<Grid item xs={1}>
						<SwitchComponent
							style={{ marginTop: 4 }}
							onChange={(e) => {
								setCheckByPublisher(false)
								setCheckByPid(false)
								setCheckByAgency(e)
								setEditablePublisher({})
							}}
							value={checkByAgency}
							label={'By Agency'}
							disabled={false}
						/>
					</Grid> */}
					<Grid item xs={1}>
						{/* <SubmitButton
							style={{ marginTop: 24 }}
							onClick={() => {
								startSearch()
							}}
						>
							Start
						</SubmitButton> */}
					</Grid>
					<Grid item xs={6}></Grid>
					{handshakeArr.length > 0 && (
						<Grid item xs={1}>
							<SubmitButton
								style={{ marginTop: 24 }}
								onClick={() => {
									updateData()
								}}
							>
								Save
							</SubmitButton>
						</Grid>
					)}
				</Grid>
				{handShakeEditorOpen && (
					<div
						style={{
							background: theme.colors.base.white,
							border: '1px solid ' + theme.colors.base.grey300,
							borderRadius: '10px',
							boxShadow: '0px 0px 1000px 20px ' + theme.colors.base.grey100,
							width: '70%',
							height: '30%',
							zIndex: 15,
							position: 'fixed',
							display: 'flex',
							top: '30%',
							left: '10%',
						}}
					>
						<UnborderedButton
							onClick={() => {
								setHandShakeEditorOpen(false)
								if (checkByPublisher) {
									setEditablePublisher({})
								}
							}}
							style={{
								display: 'flex',
								position: 'absolute',
								right: '2%',
								top: '6%',
							}}
						>
							X
						</UnborderedButton>
						<HandshakeEdit
							selected={editablePid}
							handshakeArr={handshakeArr}
							setHandshakeArr={setHandshakeArr}
							editablePublisher={editablePublisher}
						/>
					</div>
				)}
				{filtered.length > 0 && (
					<TableComponent
						columns={columns}
						rows={filtered}
						setLoading={props.setLoading}
						setPageSize={setPageSize}
						pageSize={pageSize}
						rowsPerPageOptions={[15, 25, 50]}
						rowHeight={'auto'}
						handleCellOnFocus={(params: any) => {
							const target = params.nativeEvent.target
							if (target.nodeName === 'BUTTON') {
								target.click()
							}
						}}
						getRowClassName={() => 'handshake'}
					></TableComponent>
				)}
				{/* {filtered.length > 0 && (
					<Grid
						container
						columnSpacing={2}
						direction='row'
						style={{ marginTop: 12 }}
					>
						{filtered.map((el: any) => {
							return (
								<Grid item xs={4} key={el.publisher}>
									<HandshakeElement
										key={el.publisher}
										publisher={el.publisher}
										pidList={el.pid}
										setEditablePid={setEditablePid}
										setHandShakeEditorOpen={setHandShakeEditorOpen}
									/>
								</Grid>
							)
						})}
					</Grid>
				)} */}
			</PageWrapper>
		</ThemeProvider>
	)
}

export default HandshakePage

const PidHandshakeContainer = ({
	el,
	index,
	setHandShakeEditorOpen,
	setEditablePid,
	handshakeArr,
	setEditablePublisher,
}: {
	el: any
	index: number
	setHandShakeEditorOpen: any
	setEditablePid: any
	handshakeArr: any
	setEditablePublisher: any
}) => {
	const [visibleHandshake, setVisibleHandshake] = useState(el.handshake)
	useEffect(() => {
		const formattedPid = el.pid.toLowerCase().trim()
		// console.log(handshakeArr)
		for (const updated of handshakeArr) {
			if (updated.pid && updated.pid.toLowerCase().trim() === formattedPid) {
				setVisibleHandshake(updated.handshake)
				break
			}
		}
	}, [handshakeArr])

	return (
		<Grid item xs={1.5} key={uuidv4()}>
			<PidButton
				el={el}
				onClick={() => {
					setHandShakeEditorOpen(true)
					setEditablePid(el.pid.toLowerCase().trim())
					setEditablePublisher({})
				}}
			/>
			{Object.keys(el.handshake).length > 0 && (
				<ul
					style={{
						padding: 0,
					}}
				>
					{Object.keys(visibleHandshake).map((agency) => {
						return (
							<li
								key={uuidv4()}
								style={{
									color: visibleHandshake[agency]
										? theme.colors.base.green500
										: theme.colors.base.grey00,
									listStyle: 'none',
								}}
							>
								{agency}
							</li>
						)
					})}
				</ul>
			)}
		</Grid>
	)
}
