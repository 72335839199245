import { LearningInterface } from '../../models/model.interface'
import { ActionType } from '../action-types/index'
import { Action } from '../actions'

export interface GetLearningInterface {
	learning: LearningInterface | any
}

const initialState: GetLearningInterface = {
	learning: [],
}

const reducer = (
	state: GetLearningInterface = initialState,
	action: Action,
): GetLearningInterface => {
	switch (action.type) {
		case ActionType.GET_LEARNING: {
			return {
				...state,
				learning: action.payload,
			}
		}
		case ActionType.ADD_LEARNING: {
			return {
				...state,
				learning: action.payload,
			}
		}
		case ActionType.REMOVE_LEARNING: {
			const newState = state.learning.filter((el: any) => Number(el.id) !== Number(action.payload.id))
			return {
				learning: newState
			}
		}
		default: {
			return state
		}
	}
}

export default reducer
