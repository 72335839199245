import React, { useEffect, useRef, useState } from 'react'
import {
	GradientedText,
	PageWrapper,
	TableWrapper,
} from '../components/reusableComponents'
import theme from '../../utils/theme2024'
// import sendIcon from '../../assets/send.png'
import ArrowBackIcon from '@mui/icons-material/ArrowBack'
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos'

import './finance.css'
import { ReactComponent as DeleteIcon } from '../../assets/svg/delete-icon.svg'
import {
	extractPaymentLimit,
	formatDateToDdMmYyyy,
	formatFinancePaymentDate,
	getApplicableRules,
	sortDates,
} from '../../utils/helpers/helperFuncs'
import { ReactComponent as EditIcon } from '../../assets/svg/edit-icon.svg'
import { ReactComponent as SendIcon } from '../../assets/svg/send-icon.svg'

import { ThemeProvider, Typography } from '@mui/material'
import {
	GridColDef,
	GridRowModesModel,
	GridRowModes,
	GridActionsCellItem,
	GridEventListener,
	GridRowEditStopReasons,
	useGridApiRef,
	GridEditInputCell,
} from '@mui/x-data-grid-pro'
import { useActions } from '../../hooks/useActions'
import { generateRowsWithIds } from '../../utils/helpers/tableHelper'
import { useTypedSelector } from '../../hooks/useTypedSelector'
import CheckIcon from '@mui/icons-material/Check'
import ClearIcon from '@mui/icons-material/Clear'
import moment from 'moment'
import { useNavigate, useParams } from 'react-router-dom'
import { UploadFinanceImage } from './UploadFinanceImage'
import { UploadInstructionsFinance } from './UploadInstructionsFinance'
import DocViewer, { DocViewerRenderers } from '@cyntler/react-doc-viewer'
import _ from 'lodash'
import { StyledTextAreaField } from '../components/Inputs'
import { SubmitButton, UnborderedButton } from '../components/Buttons'
import { TableComponent } from '../components/TableComponents'
import { TitleComponent } from '../components/Informative'
import { useDispatch } from 'react-redux'
import { emptyFinance } from '../../state/actions'

const DemandInvoiceTable = (props: {
	setLoading: any
	errorMessage: any
	setErrorMessage: any
	isErrorPromptOpened: any
	setIsErrorPromptOpened: any
	isSuccessPromptOpened: any
	setIsSuccessPromptOpened: any
	isEditorOpened?: any
	isAddEditorOpened?: any
	presetSearch: any
	setPresetSearch: any
	PopUpVisible: any
	setPopUpVisible: any
}) => {
	const { users, login, finance, advertiser, settings } = useTypedSelector(
		(state) => state,
	)

	const ruleSet = getApplicableRules(users, login, settings, 'finance')
	const {
		saveProofFinance,
		sendEmailAdvertiserFinance,
		uploadInvoiceDemand,
		removeInvoiceDemand,
		getInternalFinanceData,
	} = useActions()
	const [pageSize, setPageSize] = useState(15)
	const params = useParams()
	const navigate = useNavigate()
	const [isEmailPreviewOpen, setIsEmailPreviewOpen] = useState(false)
	const rowCount = 14
	const [rowWidth, setRowWidth] = useState(
		Math.floor((window.innerWidth - theme.dimentions.drawerWidth) / rowCount),
	)
	// const paramsUuid = params?.id?.split('-demand-')[0]
	// const foundRow = finance.finance.filter((el: any) => {
	// 	return el.uuid === paramsUuid
	// })
	// const advertiserFound = foundRow[0].advertiser
	// const currentAdvertiser = advertiser.advertiser.find(
	// 	(el: any) => el.advertiser_name === advertiserFound,
	// )
	const [currentAdvertiser, setCurrentAdvertiser] = useState<any>({})

	const month = params?.id?.includes('-demand-')
		? params?.id?.split('-demand-')[1].replaceAll('-', '/')
		: params?.id?.split('-demandcreator-')[1].replaceAll('-', '/')
	const [mainAdvertiser, setMainAdvertiser] = useState('')
	const [mainAdvertiserPlatform, setMainAdvertiserPlatform] = useState('')
	const [filteredFinance, setFilteredFinance] = useState<any>(
		// Array.isArray(finance.finance)
		// 	? [
		// 			finance.finance.find(
		// 				(el: any) =>
		// 					el.advertiser === advertiserFound && el.month === month,
		// 			),
		// 	  ]
		// 	: [],
		[],
	)

	const headers: any = {
		Authorization: `Token ${login.user.token}`,
	}
	//we use the uuid in order to find the row. With that we can find the publisher and the period (month).

	const [tableRows, setTableRows] = useState<any>(
		// generateRowsWithIds(generateInvoiceRows(filteredFinance[0])),
		[],
	)

	const closeDetailedView = () => {
		navigate('/finance/demand')
	}

	const [proofToView, setProofToView] = useState<any>()
	const [openImagePreview, setOpenImagePreview] = useState(false)
	const [invoiceInstructions, setInvoiceInstructions] = useState<any>('')
	const [openInstructionsPreview, setOpenInstructionsPreview] = useState(false)
	const [uploadInvoiceTriggered, setUploadInvoiceTriggered] = useState(false)
	const [selectedRow, setSelectedRow] = useState<any>(null)
	// const [customFilter, setCustomFilter] = useState<any>({})
	const [sortModel, setSortModel] = useState([{ field: 'month', sort: 'desc' }])
	const [rowModesModel, setRowModesModel] = React.useState<GridRowModesModel>(
		{},
	)
	const apiRef = useGridApiRef()
	const [pinnedBottomRow, setPinnedBottomRow] = useState<any>([])
	const [financeFinalConfirmationFilter, setFinanceFinalConfirmationFilter] =
		useState(0)
	const [forceRefresh, setForceRefresh] = useState(false)

	const dispatch = useDispatch()
	const hasFetched = useRef(false)

	const fetchData = async () => {
		let i = 0
		let result
		do {
			result = await getInternalFinanceData(
				headers,
				params?.id?.includes('-demand-') ? 'demand' : 'demandcreator',
				props.errorMessage,
				params.id!,
				login.user.email,
				50,
				i,
			)
			i++
			// console.log('result', result)
		} while (
			result &&
			result.length > 0 &&
			(window.location.pathname.includes('-demand-') ||
				window.location.pathname.includes('-demandcreator-'))
		)
	}
	// const advertiserList = advertiser.advertiser.map(
	// 	(el: any) => el.advertiser_name,
	// )

	// useEffect(() => {
	// 	console.log('tablerows', tableRows)
	// }, [tableRows])

	const possibleFinanceFilter = [
		'All',
		'Missing',
		'Confirmed',
		'Overdue',
		'Non Overdue',
		'Paid',
	]
	useEffect(() => {
		dispatch(emptyFinance())
		//this needs to be added for the StrictMode. Otherwise data is duplicated.
		if (hasFetched.current) {
			return
		}
		hasFetched.current = true
		fetchData()
	}, [])

	useEffect(() => {
		setFilteredFinance(finance.finance)
		if (finance.finance.length > 0) {
			setMainAdvertiser(finance.finance[0].advertiser)
			if (finance.finance[0].is_creator_row) {
				setMainAdvertiserPlatform(finance.finance[0].platform)
			}
		}
	}, [finance.finance])

	useEffect(() => {
		if (finance.finance.length > 0) {
			let filtered = finance.finance
			let currentAdvertiser = advertiser.advertiser.find(
				(el: any) => el.advertiser_name === filtered[0].advertiser,
			)
			const invoiceRows = generateInvoiceRows(
				filtered[0],
				possibleFinanceFilter[financeFinalConfirmationFilter],
				currentAdvertiser,
			)
			setCurrentAdvertiser(currentAdvertiser)
			setTableRows(generateRowsWithIds(invoiceRows))
			setPinnedBottomRow(calculatePinnedTotalRow(invoiceRows))
		}
	}, [financeFinalConfirmationFilter, filteredFinance])
	useEffect(() => {
		// console.log('refresh')
		setFilteredFinance([
			// finance.finance.find(
			// 	(el: any) => el.advertiser === advertiserFound && el.month === month,
			// ),
			// [],
			finance.finance,
		])
	}, [forceRefresh])

	const handleUploadProof = (row: any, image: any) => {
		const formData = new FormData()
		formData.append('finance', image)
		const mainUuid = row.row.uuid.split('-invoice-')[0]
		let mainRow = {}
		for (const currentRow of tableRows) {
			// if (currentRow.mainInvoice && currentRow.uuid === mainUuid) {
			if (currentRow.mainInvoice) {
				mainRow = { ...currentRow }
				break
			}
		}
		const data = {
			row: row.row,
			email: login.user.email,
			main_row: mainRow,
			type: 'demand',
		}
		formData.append('data', JSON.stringify(data))
		saveProofFinance(
			headers,
			formData,
			() => setForceRefresh(!forceRefresh),
			props.setErrorMessage,
			props.setLoading,
		)
	}
	const disableSendingDemandEmail = (row: any) => {
		if (
			row.final_confirmation_advertiser_sent ||
			row.final_confirmation_advertiser === '' ||
			row.proof === ''
		) {
			return true
		} else {
			return false
		}
	}

	const handleNewInvoice = (mainRow: any) => {
		// console.log('mainrow', mainRow)
		const newInvoice = {
			mainInvoice: false,
			invoice_instructions: '',
			proof: '',
			final_confirmation_advertiser: '',
			final_confirmation_sent: false,
			invoice_number: '',
			invoice_number_advertiser: '',
			invoice_number_advertiser_added_time: '',
			payment_advertiser: '',
			uuid: mainRow.uuid + '-invoice-',
			revenue_converted: '',
			bank_fees_advertiser: '',
			received_amount_advertiser: '',
			payment_method_advertiser: '',
			custom_advertiser_payment_term: '',
		}
		if (mainRow.extra_invoices) {
			//we use the ms of creation as uuid.
			newInvoice.uuid += Date.now().toString()
		} else {
			newInvoice.uuid += 0
		}
		const newMainRow = _.cloneDeep(mainRow)
		if (Array.isArray(newMainRow.extra_invoices)) {
			newMainRow.extra_invoices.push(newInvoice)
		} else {
			newMainRow.extra_invoices = [newInvoice]
		}
		// console.log(newMainRow)
		const invoiceTable = generateInvoiceRows(newMainRow)
		setPinnedBottomRow(calculatePinnedTotalRow(invoiceTable))
		setTableRows(generateRowsWithIds(invoiceTable))
	}
	const handleEditClick = (id: any) => () => {
		setRowModesModel({ ...rowModesModel, [id]: { mode: GridRowModes.Edit } })
	}
	const handleSaveClick = (id: any) => () => {
		setRowModesModel({ ...rowModesModel, [id]: { mode: GridRowModes.View } })
	}
	const handleCancelClick = (id: any) => () => {
		setRowModesModel({
			...rowModesModel,
			[id]: { mode: GridRowModes.View, ignoreModifications: true },
		})
	}
	const handleDeleteClick = (id: any) => async () => {
		const deleted = window.confirm(
			'Deleting this will remove the entire invoice from the database. Are you sure?',
		)
		if (deleted) {
			let rowToRemove: any
			let mainRow: any
			const arr = tableRows.filter((element: any) => {
				if (element.id !== id) {
					return true
				} else {
					rowToRemove = element
					return false
				}
			})
			for (const row of arr) {
				if (row.mainInvoice === true) {
					const updatedInvoices = row.extra_invoices.filter(
						(el: any) => el.uuid !== rowToRemove.uuid,
					)
					row.extra_invoices = updatedInvoices
					mainRow = row
					break
				}
			}
			if (rowToRemove.mainInvoice) {
				mainRow = rowToRemove
			}
			// setPinnedBottomRow(calculatePinnedTotalRow(arr))

			const newMainRow = await removeInvoiceDemand(
				headers,
				{ to_remove: rowToRemove, main: mainRow },
				login.user.email,
				props.setErrorMessage,
			)
			const invoiceRows = generateInvoiceRows(newMainRow)
			setPinnedBottomRow(calculatePinnedTotalRow(invoiceRows))
			setTableRows(generateRowsWithIds(invoiceRows))
		}
	}
	const handleRowEditStop: GridEventListener<'rowEditStop'> = (
		params,
		event,
	) => {
		if (params.reason === GridRowEditStopReasons.rowFocusOut) {
			event.defaultMuiPrevented = true
		}
	}

	const handleSendEmail = (row: any) => async () => {
		let mainRow: any = {}
		const mainUuid = row.uuid.split('-invoice-')[0]
		for (const savedRow of tableRows) {
			if (savedRow.mainInvoice && savedRow.uuid === mainUuid) {
				mainRow = { ...savedRow }
				break
			}
		}
		const sendApproved = window.confirm('Do you want to send the email?')
		if (sendApproved) {
			const success = await sendEmailAdvertiserFinance(
				headers,
				row,
				mainRow,
				login.user,
				tableRows,
				props.setErrorMessage,
			)
			if (row.mainInvoice) {
				const newRow = { ...row }
				newRow.final_confirmation_advertiser_sent = true
				mainRow = newRow
			} else {
				const newInvoicesArr = []

				for (const invoice of mainRow.extra_invoices) {
					const newRow = { ...invoice }
					if (invoice.uuid === row.uuid) {
						// console.log(newRow)
						newRow.final_confirmation_advertiser_sent = true
					}
					newInvoicesArr.push(newRow)
				}
				const updatedMainRow = {
					...mainRow,
					extra_invoices: newInvoicesArr,
				}
				mainRow = updatedMainRow
			}
			const invoiceRows = generateInvoiceRows(mainRow)
			setTableRows(generateRowsWithIds(invoiceRows))
		}
	}

	const columns: GridColDef[] = [
		{
			field: 'invoice_instructions',
			headerName: 'Invoice Instructions',
			width: rowWidth * 1,
			align: 'center',
			headerAlign: 'center',
			editable: false,
			renderCell: (row) => {
				let mainRow = {}
				if (row.row.uuid) {
					const mainUuid = row.row.uuid.split('-invoice-')[0]
					for (const savedRow of tableRows) {
						if (savedRow.mainInvoice && savedRow.uuid === mainUuid) {
							mainRow = { ...savedRow }
							break
						}
					}
				} else {
					mainRow = row.row
				}
				if (row.id === 9999999999) {
					return <span>TOTAL</span>
				}
				return (
					<UploadInstructionsFinance
						headers={headers}
						row={row}
						mainRow={mainRow}
						setOpenInstructionsPreview={setOpenInstructionsPreview}
						setErrorMessage={props.setErrorMessage}
						setLoading={props.setLoading}
						invoiceInstructions={invoiceInstructions}
						setInvoiceInstructions={setInvoiceInstructions}
						setUploadInvoiceTriggered={setUploadInvoiceTriggered}
						uploadInvoiceTriggered={uploadInvoiceTriggered}
						selectedRow={selectedRow}
						setSelectedRow={setSelectedRow}
						refreshFunc={() => setForceRefresh(!forceRefresh)}
						type={'demand'}
					></UploadInstructionsFinance>
				)
			},
			cellClassName: (params) => {
				if (params.colDef.editable === false) {
					return 'is-not-editable'
				}
				return 'is-editable'
			},
		},
		{
			field: 'proof',
			headerName: 'Proof',
			width: rowWidth * 0.7,
			align: 'center',
			headerAlign: 'center',
			editable: false,
			renderCell: (row) => {
				let mainRow = {}
				if (row.row.uuid) {
					const mainUuid = row.row.uuid.split('-invoice-')[0]
					for (const savedRow of tableRows) {
						// if (savedRow.mainInvoice && savedRow.uuid === mainUuid) {
						// 	mainRow = { ...savedRow }
						// }
						if (savedRow.mainInvoice) {
							mainRow = { ...savedRow }
							break
						}
					}
				} else {
					mainRow = row.row
				}
				if (row.id === 9999999999) {
					return []
				}
				return (
					<UploadFinanceImage
						headers={headers}
						row={row}
						mainRow={mainRow}
						handleUploadProof={handleUploadProof}
						setProofToView={setProofToView}
						setOpenImagePreview={setOpenImagePreview}
						setErrorMessage={props.setErrorMessage}
						setLoading={props.setLoading}
						permissions={false}
						forceRefresh={forceRefresh}
						setForceRefresh={setForceRefresh}
						type={'demand'}
					></UploadFinanceImage>
				)
			},
			cellClassName: (params) => {
				if (params.colDef.editable === false) {
					return 'is-not-editable'
				}
				return 'is-editable'
			},
		},

		{
			field: 'final_confirmation_advertiser',
			headerName: 'Invoice Confirmation',
			headerAlign: 'center',
			width: rowWidth * 1.4,
			type: 'number',
			align: 'center',
			headerClassName: 'finance-final-header',
			editable: true,
			renderCell: ({ value }) => {
				return (
					<span>
						{Number(value).toLocaleString('en-US', {
							minimumFractionDigits: 2,
							maximumFractionDigits: 2,
						})}
					</span>
				)
			},
			cellClassName: (params) => {
				if (params.colDef.editable === false) {
					return 'is-not-editable'
				}
				return 'finance-final-cell is-editable'
			},
		},

		{
			field: 'currency',
			headerName: 'Currency',
			headerAlign: 'center',
			width: rowWidth * 0.8,
			align: 'center',
			editable: true,
			type: 'singleSelect',
			valueOptions(params) {
				let options = ['USB', 'EUR', 'WON', 'GBP']
				if (params && params.row && settings.settings.currency.length > 0) {
					options = settings.settings.currency
				}
				return options
			},
			cellClassName: (params) => {
				if (params.colDef.editable === false) {
					return 'is-not-editable'
				}
				return 'is-editable'
			},
		},

		{
			field: 'final_with_vat',
			headerName: 'Amount inc. VAT',
			headerAlign: 'center',
			width: rowWidth * 1.2,
			type: 'number',
			align: 'center',
			editable: false,
			renderCell: ({ value }) => {
				if (value) {
					return (
						<span>
							{Number(value).toLocaleString('en-US', {
								minimumFractionDigits: 2,
								maximumFractionDigits: 2,
							})}
						</span>
					)
				} else {
					return <></>
				}
			},
			cellClassName: (params) => {
				if (params.colDef.editable === false) {
					return 'is-not-editable'
				}
				return 'is-editable'
			},
		},
		{
			field: 'custom_advertiser_payment_term',
			headerName: 'Payment Term',
			headerAlign: 'center',
			align: 'center',
			width: rowWidth * 0.8,
			type: 'singleSelect',
			valueOptions: ['Default', ...settings.settings?.paymentTerms] || [
				'Default',
			],
			editable: true,
			cellClassName: (params) => {
				if (params.colDef.editable === false) {
					return 'is-not-editable'
				}
				return 'is-editable'
			},
			renderCell: ({ value }) => {
				if (!value || value === '' || value === 'Default') {
					if (currentAdvertiser.payment_terms) {
						return <>{currentAdvertiser.payment_terms}</>
					}
					return <>Default</>
				}
				return <>{value}</>
			},
		},

		{
			field: 'invoice_number_advertiser',
			headerName: 'Invoice Number',
			width: rowWidth,
			align: 'left',
			headerAlign: 'left',
			cellClassName: (params) => {
				if (params.colDef.editable === false) {
					return 'is-not-editable'
				}
				return 'is-editable'
			},
			editable:
				ruleSet.rules['Edit Demand Invoice'] && ruleSet.post ? true : false,
		},

		{
			field: 'invoice_number_advertiser_added_time_date',
			headerName: 'Sent date',
			width: rowWidth * 0.8,
			type: 'date',
			align: 'left',
			headerAlign: 'left',
			editable:
				ruleSet.rules['Edit Demand Invoice'] && ruleSet.post ? true : false,
			renderCell: ({ value }) => {
				if (value) {
					const date = formatFinancePaymentDate(value)
					return <Typography sx={{ fontSize: 14 }}>{date}</Typography>
				} else {
					return <></>
				}
			},
			valueGetter: (params) => {
				if (params.value) {
					return new Date(params.value)
				}
			},
			renderEditCell: (params) => {
				if (params.value) {
					const date = moment(params.value).format('YYYY-MM-DD')
					return <GridEditInputCell type={'date'} {...params} value={date} />
				}
				return <></>
			},
			sortComparator: (str1, str2) => {
				return sortDates(str1, str2)
			},
			cellClassName: (params) => {
				if (params.colDef.editable === false) {
					return 'is-not-editable'
				}
				return 'is-editable'
			},
		},

		{
			field: 'invoice_number_advertiser_added_time',
			headerName: 'Overdue Days',
			width: rowWidth,
			type: 'number',
			align: 'center',
			headerAlign: 'left',
			editable: false,
			renderCell: (row) => {
				const value = row.row.invoice_number_advertiser_added_time
				let currentDate
				if (row.row.payment_advertiser) {
					currentDate = moment(row.row.payment_advertiser).startOf('day')
				} else {
					currentDate = moment().startOf('day')
				}
				const valueDate = moment(value).startOf('day')
				const difference_days = currentDate.diff(valueDate, 'days')
				const outOfPaymentLimit = row.row.payment_limit < difference_days
				// console.log(row.row.payment_limit)
				return (
					<Typography
						sx={{ fontSize: 14, color: outOfPaymentLimit ? 'red' : 'inherit' }}
					>
						{value ? difference_days : ''}
					</Typography>
				)
			},
			cellClassName: (params) => {
				if (params.colDef.editable === false) {
					return 'is-not-editable'
				}
				return 'is-editable'
			},
		},

		{
			field: 'payment_advertiser',
			headerName: 'Payment Date',
			width: rowWidth,
			align: 'left',
			headerAlign: 'left',
			editable:
				ruleSet.rules['Edit Demand Invoice'] && ruleSet.post ? true : false,
			type: 'date',
			renderCell: ({ value }) => {
				if (value) {
					const date = formatFinancePaymentDate(value)
					return <Typography sx={{ fontSize: 14 }}>{date}</Typography>
				} else {
					return <></>
				}
			},
			sortComparator: (str1, str2) => {
				return sortDates(str1, str2)
			},
			cellClassName: (params) => {
				if (params.colDef.editable === false) {
					return 'is-not-editable'
				}
				return 'is-editable'
			},
			// renderCell: ({ value }) => {
			// 	return (
			// 		<Checkbox
			// 			checked={value}
			// 			// onChange={handleCheck}
			// 			// disabled
			// 			icon={
			// 				<CheckBoxOutlineBlankIcon
			// 					style={{ scale: '1', color: 'rgba(141, 141, 147, 0.8)' }}
			// 				/>
			// 			}
			// 			checkedIcon={
			// 				<CheckBoxIcon
			// 					style={{
			// 						scale: '1',
			// 						color: 'rgba(0, 204, 38, 1)',
			// 					}}
			// 				/>
			// 			}
			// 		/>
			// 	)
			// },
		},
		{
			field: 'actions_send',
			type: 'actions',
			headerName: 'Send to Finance',
			width: 80,
			cellClassName: 'actions_send',
			getActions: (row) => {
				if (row.row.final_confirmation_advertiser_sent) {
					return [
						<GridActionsCellItem
							icon={
								<button
									style={{
										display: 'flex',
										alignItems: 'center',
										border: 'none',
										color: theme.colors.base.green400,
										height: '28px',
										width: '28px',
										fontSize: '16px',
										cursor: 'pointer',
									}}
									disabled
									className={'finance-action-button'}
								>
									<CheckIcon fontSize={'inherit'} />
								</button>
							}
							label='Sent'
							sx={{
								color: 'primary.main',
							}}
							onClick={() => {}}
							disabled
						/>,
					]
				} else {
					return [
						<GridActionsCellItem
							icon={
								<button
									style={{
										display: 'flex',
										alignItems: 'center',
										border: 'none',
										// background: disableSendingDemandEmail(row.row)
										// 	? 'rgba(238, 29, 74, 0.06)'
										// 	: 'rgba(238, 29, 74, 0.1)',
										// borderRadius: '100px',
										height: '28px',
										width: '28px',
										fontSize: '16px',
										cursor: disableSendingDemandEmail(row.row)
											? 'not-allowed'
											: 'pointer',
									}}
									disabled={disableSendingDemandEmail(row.row)}
									className={'finance-action-button'}
								>
									<SendIcon
										style={{ color: theme.colors.text.titles, scale: '0.8' }}
										className={'action-button'}
									/>
								</button>
							}
							style={{ pointerEvents: 'auto' }}
							label='Send'
							className='textPrimary'
							onClick={handleSendEmail(row.row)}
							color='inherit'
							disabled={disableSendingDemandEmail(row.row)}
						/>,
					]
				}
			},
		},
	]
	if (ruleSet.rules['Edit Demand'] || ruleSet.rules['Edit Demand invoice']) {
		columns.splice(columns.length - 1, 0, {
			field: 'actions',
			type: 'actions',
			headerName: 'Actions',
			width: 100,
			cellClassName: 'actions',
			getActions: (row) => {
				const isInEditMode =
					rowModesModel[row.row.id]?.mode === GridRowModes.Edit
				//9999999999 this id is the hardcoded id we use for the bottom pinned row.
				if (row.row.id === 9999999999) {
					return []
				}

				//based on if the edit mode is triggered we will have the options to save, cancel or edit, remove
				if (isInEditMode) {
					return [
						<GridActionsCellItem
							icon={
								<button
									style={{
										display: 'flex',
										alignItems: 'center',
										border: 'none',

										height: '28px',
										width: '28px',
										fontSize: '16px',
									}}
									className={'finance-action-button'}
								>
									<CheckIcon fontSize={'inherit'} />
								</button>
							}
							label='Save'
							sx={{
								color: 'primary.main',
							}}
							onClick={handleSaveClick(row.row.id)}
						/>,
						<GridActionsCellItem
							icon={
								<button
									style={{
										display: 'flex',
										alignItems: 'center',
										border: 'none',

										height: '28px',
										width: '28px',
										fontSize: '16px',
									}}
									className={'finance-action-button'}
								>
									<ClearIcon fontSize={'inherit'} />
								</button>
							}
							label='Cancel'
							className='textPrimary'
							onClick={handleCancelClick(row.row.id)}
							color='inherit'
						/>,
					]
				}
				if (
					tableRows.length > 1 &&
					(ruleSet.rules['Edit Demand'] || ruleSet.rules['Edit Demand invoice'])
				) {
					return [
						<GridActionsCellItem
							icon={
								<button
									style={{
										display: 'flex',
										alignItems: 'center',
										border: 'none',

										height: '28px',
										width: '28px',
										fontSize: '16px',
									}}
									className={'finance-action-button'}
								>
									<EditIcon
										style={{ color: theme.colors.text.titles, scale: '0.8' }}
										className={'action-button'}
									/>
								</button>
							}
							label='Edit'
							className='textPrimary'
							onClick={handleEditClick(row.row.id)}
							color='inherit'
						/>,
						<GridActionsCellItem
							icon={
								<button
									style={{
										display: 'flex',
										alignItems: 'center',
										border: 'none',

										height: '28px',
										width: '28px',
										fontSize: '16px',
									}}
									className={'finance-action-button'}
								>
									<DeleteIcon
										style={{ color: theme.colors.text.titles, scale: '0.8' }}
										className={'action-button'}
									/>
								</button>
							}
							label='Edit'
							className='textPrimary'
							onClick={handleDeleteClick(row.row.id)}
							color='inherit'
						/>,
					]
				}
				return [
					<GridActionsCellItem
						icon={
							<button
								style={{
									display: 'flex',
									alignItems: 'center',
									border: 'none',
									cursor: 'pointer',
									height: '28px',
									width: '28px',
									fontSize: '16px',
								}}
								className={'finance-action-button'}
							>
								<EditIcon
									style={{ color: theme.colors.text.titles, scale: '1' }}
									className={'action-button'}
								/>
							</button>
						}
						label='Edit'
						className='textPrimary'
						onClick={handleEditClick(row.row.id)}
						color='inherit'
					/>,
				]
			},
		})
	}
	ruleSet.post &&
		ruleSet.rules['Edit Demand Invoice'] &&
		columns.splice(
			columns.length - 2,
			0,
			{
				field: 'received_amount_advertiser',
				headerName: 'Received Amount',
				width: rowWidth,
				align: 'left',
				headerAlign: 'left',
				editable: true,
				type: 'number',
				cellClassName: (params) => {
					if (params.colDef.editable === false) {
						return 'is-not-editable'
					}
					return 'is-editable'
				},
				renderCell: ({ value }) => {
					if (value) {
						return (
							<span>
								{Number(value).toLocaleString('en-US', {
									minimumFractionDigits: 2,
									maximumFractionDigits: 2,
								})}
							</span>
						)
					} else {
						return <></>
					}
				},
			},
			{
				field: 'bank_fees_advertiser',
				headerName: 'Bank Fees',
				width: rowWidth,
				align: 'left',
				headerAlign: 'left',
				editable: true,
				type: 'number',
				cellClassName: (params) => {
					if (params.colDef.editable === false) {
						return 'is-not-editable'
					}
					return 'is-editable'
				},
				renderCell: ({ value }) => {
					if (!value && value !== 0) {
						return <></>
					}
					return (
						<span>
							{Number(value).toLocaleString('en-US', {
								minimumFractionDigits: 2,
								maximumFractionDigits: 2,
							})}
						</span>
					)
				},
			},
			{
				field: 'payment_method_advertiser',
				headerName: 'Payment Method',
				width: rowWidth,
				align: 'left',
				headerAlign: 'left',
				type: 'singleSelect',
				editable: true,
				valueOptions: settings.settings.paymentMethod,
				cellClassName: (params) => {
					if (params.colDef.editable === false) {
						return 'is-not-editable'
					}
					return 'is-editable'
				},
			},
		)

	const handleRowModesModelChange = (newRowModesModel: GridRowModesModel) => {
		setRowModesModel(newRowModesModel)
	}
	const processRowUpdate = async (newRow: any) => {
		if (
			newRow.invoice_number_advertiser &&
			!newRow.invoice_number_advertiser_added_time
		) {
			const formattedDate = moment().format('YYYY-MM-DDT00:00:00.000[Z]')
			newRow.invoice_number_advertiser_added_time = formattedDate
		} else if (
			!newRow.invoice_number_advertiser &&
			newRow.invoice_number_advertiser_added_time
		) {
			newRow.invoice_number_advertiser_added_time = ''
		}
		if (
			!newRow.final_confirmation_advertiser &&
			newRow.final_confirmation_advertiser !== 0
		) {
			newRow.final_confirmation_advertiser = ''
		}
		// console.log('newrow', newRow)
		const updatedRow = { ...newRow, isNew: false }
		if (updatedRow.payment_advertiser && updatedRow.proof) {
			updatedRow.proof = ''
		}
		if (updatedRow.custom_advertiser_payment_term === 'Default') {
			updatedRow.custom_advertiser_payment_term = ''
		}
		if (
			updatedRow.final_confirmation_advertiser &&
			updatedRow.final_confirmation_advertiser_sent
		) {
			const element = tableRows.find((el: any) => el.uuid === updatedRow.uuid)
			if (element?.final_confirmation_advertiser_sent === true) {
				updatedRow.final_confirmation_advertiser_sent = false
			}
		}
		const oldRow = tableRows.find((el: any) => el.uuid === newRow.uuid)
		// console.log(oldRow)
		const data = { old_row: oldRow, new_row: updatedRow, main_row: {} }
		if (!updatedRow.mainInvoice) {
			const mainUuid = updatedRow.uuid.split('-invoice-')[0]
			const updatedArr = []
			for (const row of tableRows) {
				if (row.mainInvoice && row.uuid === mainUuid) {
					for (const invoice of row.extra_invoices) {
						if (invoice.uuid === updatedRow.uuid) {
							if (
								updatedRow.invoice_number_advertiser_added_time &&
								updatedRow.invoice_number_advertiser_added_time_date &&
								updatedRow.invoice_number_advertiser_added_time !==
									updatedRow.invoice_number_advertiser_added_time_date
							) {
								updatedRow.invoice_number_advertiser_added_time =
									updatedRow.invoice_number_advertiser_added_time_date
							}
							if (updatedRow.received_amount_advertiser) {
								updatedRow.bank_fees_advertiser = (
									Number(updatedRow.final_with_vat) -
									Number(updatedRow.received_amount_advertiser)
								).toFixed(2)
							}
							if (updatedRow.custom_advertiser_payment_term === 'Default') {
								updatedRow.custom_advertiser_payment_term = ''
							}
							updatedArr.push(updatedRow)
						} else {
							updatedArr.push(invoice)
						}
					}
					row.extra_invoices = updatedArr
				}
				if (row.mainInvoice) {
					data.main_row = row
				}
			}
		} else {
			// if (
			// 	updatedRow.invoice_number_advertiser_added_time !==
			// 	updatedRow.invoice_number_advertiser_added_time_date
			// ) {
			// 	updatedRow.invoice_number_advertiser_added_time =
			// 		updatedRow.invoice_number_advertiser_added_time_date
			// }
			if (
				updatedRow.invoice_number_advertiser_added_time &&
				updatedRow.invoice_number_advertiser_added_time_date &&
				updatedRow.invoice_number_advertiser_added_time !==
					updatedRow.invoice_number_advertiser_added_time_date
			) {
				updatedRow.invoice_number_advertiser_added_time =
					updatedRow.invoice_number_advertiser_added_time_date
			}
			if (updatedRow.received_amount_advertiser) {
				updatedRow.bank_fees_advertiser = (
					Number(updatedRow.final_with_vat) -
					Number(updatedRow.received_amount_advertiser)
				).toFixed(2)
			}
			if (updatedRow.custom_advertiser_payment_term === 'Default') {
				updatedRow.custom_advertiser_payment_term = ''
			}
			data.main_row = updatedRow
		}
		// console.log(data)
		const update: any = await uploadInvoiceDemand(
			headers,
			data,
			login.user.email,
			props.setErrorMessage,
		)
		// console.log(update)

		if (update.successful) {
			// const arr = tableRows.map((row: any) =>
			// 	row.uuid === newRow.uuid ? updatedRow : row,
			// )
			const invoiceRows = generateInvoiceRows(update.payload)
			setPinnedBottomRow(invoiceRows)
			setTableRows(generateRowsWithIds(invoiceRows))
		} else {
			const notUpdatedData = [...tableRows]
			setPinnedBottomRow(notUpdatedData)
			setTableRows(notUpdatedData)
		}
		return updatedRow
	}
	//we do this in order to prevent the double click to allow to enter the edit mode.
	const handleDoubleCellClick = React.useCallback((params, event) => {
		event.defaultMuiPrevented = true
	}, [])

	// Prevent from rolling back on escape
	const handleCellKeyDown = React.useCallback((params, event) => {
		if (
			['Escape', 'Delete', 'Backspace', 'Enter'].includes(
				(event as React.KeyboardEvent).key,
			)
		) {
			event.defaultMuiPrevented = true
		}
	}, [])

	// Prevent from committing on focus out
	const handleCellFocusOut = React.useCallback((params, event) => {
		if (params.cellMode === 'edit' && event) {
			event.defaultMuiPrevented = true
		}
	}, [])
	//basic function for style
	const handleRowStyle = (params: any) => {
		const row = params.row
		const isInEditMode = rowModesModel[row.id]?.mode === GridRowModes.Edit
		//9999999999 this id is the hardcoded id we use for the bottom pinned row.
		if (isInEditMode) {
			return 'edit-mode-on'
		}
		if (row.id === 9999999999) {
			return 'pinned-row'
		}

		return ''
	}
	return (
		<ThemeProvider theme={theme}>
			{isEmailPreviewOpen && (
				<div
					style={{
						display: 'flex',
						position: 'absolute',
						width: '100%',
						height: '100%',
						background: theme.colors.base.white,
						top: '0%',
						left: '0%',
						zIndex: '15',
					}}
				></div>
			)}
			<div
				style={{
					// borderBottom: '1px solid rgba(0,0,0,0.12',
					width: '90%',
					paddingTop: '20px',
					marginLeft: '2%',
					// position: 'absolute',
					display: 'flex',
					// justifyContent: 'flex-start',
					zIndex: '11',
					// top: 140,
					// left: 40,
				}}
			>
				<UnborderedButton
					style={{
						width: '80px',
						display: 'flex',
						justifyContent: 'flex-start',
						// margin: 'auto',
						// position: 'absolute',
						// top: 26,
					}}
					onClick={() => closeDetailedView()}
					icon={
						<ArrowBackIosIcon
							style={{ height: '16px', marginRight: '0px', marginLeft: -10 }}
						/>
					}
				>
					Back
				</UnborderedButton>
				<TitleComponent
					style={{
						marginBottom: '6px',
						marginLeft: 20,
						fontSize: theme.font.size.titles,
						color: theme.colors.text.titles,
						display: 'flex',
						gap: '8px',
					}}
				>
					{mainAdvertiser}{' '}
					{mainAdvertiserPlatform ? `- ${mainAdvertiserPlatform}` : ''} for{' '}
					{moment(month, 'DD/MM/YYYY').format('MMM-YYYY')}
				</TitleComponent>
			</div>
			<PageWrapper
				style={{
					marginTop: '-7px',
					paddingTop: '10px',
					maxHeight: '200%',
					overflow: 'visible',
				}}
			>
				{openImagePreview && (
					<div
						style={{
							width: '75%',
							height: '75%',
							background: theme.colors.base.white,
							position: 'absolute',
							zIndex: '14',
							left: '10%',
							border: '1px solid rgba(0,0,0,0.2)',
							borderRadius: '12px',
							fontSize: '16px',
							color: 'black',
							padding: '40px',
							display: 'flex',
							overflowY: 'auto',
							boxShadow: '2px 2px 2px 3px rgba(206,207,208,1)',
							justifyContent: 'center',
							flexDirection: 'column',
							alignItems: 'center',
						}}
						key={'testdocviewer'}
					>
						<button
							style={{
								display: 'flex',
								alignItems: 'center',
								border: 'none',
								borderRadius: '100px',
								height: '28px',
								width: '28px',
								fontSize: '16px',
								position: 'absolute',
								left: '94%',
								top: '2%',
								cursor: 'pointer',
							}}
							onClick={() => {
								setOpenImagePreview(false)
								setProofToView(null)
							}}
						>
							<ClearIcon fontSize={'inherit'} />
						</button>
						<DocViewer
							documents={[{ uri: proofToView }]}
							pluginRenderers={DocViewerRenderers}
						></DocViewer>
						<SubmitButton
							onClick={() => {
								setOpenImagePreview(false)
								setProofToView(null)
							}}
							style={{
								width: 120,
								height: 30,
								display: 'flex',
								alignItems: 'center',
								justifyContent: 'center',
							}}
						>
							Close
						</SubmitButton>
					</div>
				)}
				{openInstructionsPreview && (
					<div
						style={{
							width: '45%',
							height: '45%',
							background: theme.colors.base.white,
							position: 'absolute',
							zIndex: '14',
							left: '20%',
							border: '1px solid rgba(0,0,0,0.2)',
							borderRadius: '12px',
							fontSize: '16px',
							color: 'black',
							padding: '40px',
							display: 'flex',
							overflowY: 'auto',
							boxShadow: '2px 2px 2px 3px rgba(206,207,208,1)',
							justifyContent: 'space-around',
							flexDirection: 'column',
							alignItems: 'center',
						}}
					>
						<button
							style={{
								display: 'flex',
								alignItems: 'center',
								border: 'none',
								borderRadius: '100px',
								height: '28px',
								width: '28px',
								fontSize: '16px',
								position: 'absolute',
								left: '94%',
								top: '2%',
								cursor: 'pointer',
							}}
							onClick={() => {
								setOpenInstructionsPreview(false)
								setInvoiceInstructions('')
							}}
						>
							<ClearIcon fontSize={'inherit'} />
						</button>
						<div style={{ width: '90%', marginLeft: 60 }}>
							<StyledTextAreaField
								label={'Invoice Instructions'}
								value={invoiceInstructions}
								onChange={setInvoiceInstructions}
							/>
						</div>
						<div
							style={{
								display: 'flex',
								width: '50%',
								justifyContent: 'space-around',
							}}
						>
							<SubmitButton
								onClick={() => {
									setOpenInstructionsPreview(false)
									setUploadInvoiceTriggered(true)
								}}
								style={{
									width: 120,
									height: 30,
									display: 'flex',
									alignItems: 'center',
									justifyContent: 'center',
								}}
							>
								Update
							</SubmitButton>
						</div>
					</div>
				)}
				<TableWrapper
					theme={theme}
					rowCount={rowCount}
					setRowWidth={setRowWidth}
					style={{ paddingBottom: '60px', width: '90%', marginTop: 30 }}
				>
					<TableComponent
						columns={columns}
						rows={tableRows}
						rowsPerPageOptions={[15, 25, 50]}
						setLoading={props.setLoading}
						rowHeight={'auto'}
						sortModel={sortModel}
						handlePopUp={{
							disabled:
								tableRows.length > 0 &&
								tableRows[0].final_confirmation_advertiser
									? false
									: true,
							onClick: () => handleNewInvoice(tableRows[0]),
						}}
						onSortModelChange={(model: any) => setSortModel(model)}
						pinnedBottomRow={pinnedBottomRow}
						getRowClassName={handleRowStyle}
						rowModesModel={rowModesModel}
						filterStatus={financeFinalConfirmationFilter}
						setFilterStatus={setFinanceFinalConfirmationFilter}
						mainFilterOptions={possibleFinanceFilter}
						handleRowModesModelChange={handleRowModesModelChange}
						processRowUpdate={processRowUpdate}
						handleRowEditStop={handleRowEditStop}
						handleDoubleCellClick={handleDoubleCellClick}
						handleCellKeyDown={handleCellKeyDown}
						handleCellFocusOut={handleCellFocusOut}
						apiRef={apiRef}
						setPageSize={setPageSize}
						pageSize={pageSize}
						customSx={{
							'& .demand-row-no-advertiser.MuiDataGrid-row:hover': {
								backgroundColor: 'rgba(252, 234, 235)',
							},
							'& .MuiDataGrid-columnHeaderTitle': {
								lineHeight: '18px',
								whiteSpace: 'pre-line',
							},
							'& .MuiDataGrid-editInputCell': {
								fontSize: '16px',
								marginTop: '3px',
							},
							'& .MuiSelect-select': {
								fontSize: '16px',
							},
						}}
					></TableComponent>
				</TableWrapper>
			</PageWrapper>
		</ThemeProvider>
	)
}

const generateInvoiceRows = (
	selectedRow: any,
	filterStatus: string = '',
	advertiser: any = [],
) => {
	// console.log('selectedRow', selectedRow, 'advertiser', advertiser)
	const result = []
	const mainRow: any = { ...selectedRow }
	selectedRow.mainInvoice = true
	mainRow.uuid = selectedRow.uuid
	mainRow.invoice_number_advertiser_added_time_date =
		selectedRow.invoice_number_advertiser_added_time
			? formatDateToDdMmYyyy(selectedRow.invoice_number_advertiser_added_time)
			: ''
	mainRow.final_confirmation_advertiser =
		selectedRow.final_confirmation_advertiser
	mainRow.received_amount_advertiser = selectedRow.received_amount_advertiser
	mainRow.payment_method_advertiser = selectedRow.payment_method_advertiser
	mainRow.advertiser_vat = selectedRow.advertiser_vat
	if (isNaN(Number(mainRow.advertiser_vat))) {
		mainRow.advertiser_vat = '0'
	}
	mainRow.final_with_vat = (
		Number(selectedRow.final_confirmation_advertiser) +
		Number(selectedRow.final_confirmation_advertiser) *
			(Number(mainRow.advertiser_vat) / 100)
	).toFixed(2)
	if (mainRow.received_amount_advertiser) {
		mainRow.bank_fees_advertiser = (
			Number(mainRow.final_with_vat) -
			Number(mainRow.received_amount_advertiser)
		).toFixed(2)
	}
	if (mainRow.custom_advertiser_payment_term) {
		mainRow.payment_limit = extractPaymentLimit(
			mainRow.custom_advertiser_payment_term,
		)
	} else {
		mainRow.payment_limit = advertiser
			? extractPaymentLimit(advertiser.payment_terms)
			: ''
	}
	// mainRow.payment_limit = advertiser
	// 	? extractPaymentLimit(advertiser.payment_terms)
	// 	: ''
	mainRow.final_confirmation_advertiser_sent =
		selectedRow.final_confirmation_advertiser_sent
	mainRow.invoice_number_advertiser = selectedRow.invoice_number_advertiser
	mainRow.invoice_number_advertiser_added_time =
		selectedRow.invoice_number_advertiser_added_time
	if (selectedRow.payment_advertiser) {
		mainRow.payment_advertiser = new Date(selectedRow.payment_advertiser)
	} else {
		mainRow.payment_advertiser = ''
	}
	if (selectedRow.invoice_number_advertiser_added_time) {
		mainRow.invoice_number_advertiser_added_time_date = new Date(
			selectedRow.invoice_number_advertiser_added_time,
		)
	} else {
		mainRow.invoice_number_advertiser_added_time_date = ''
	}
	// console.log('mainrow pushed', mainRow)
	result.push(mainRow)

	if (selectedRow.extra_invoices) {
		for (const invoice of selectedRow.extra_invoices) {
			const extraRow: any = {
				...invoice,
				mainInvoice: false,
				revenue_converted: '',
			}
			extraRow.invoice_number_advertiser_added_time_date =
				invoice.invoice_number_advertiser_added_time
					? formatDateToDdMmYyyy(invoice.invoice_number_advertiser_added_time)
					: ''
			extraRow.final_confirmation_advertiser =
				invoice.final_confirmation_advertiser
			extraRow.invoice_instructions = invoice.invoice_instructions
			extraRow.bank_fees_advertiser = invoice.bank_fees_advertiser
			extraRow.received_amount_advertiser = invoice.received_amount_advertiser
			extraRow.payment_method_advertiser = invoice.payment_method_advertiser
			extraRow.revenue_converted = invoice.revenue_converted
			extraRow.custom_advertiser_payment_term =
				invoice.custom_advertiser_payment_term
			if (extraRow.custom_advertiser_payment_term) {
				extraRow.payment_limit = extractPaymentLimit(
					extraRow.custom_advertiser_payment_term,
				)
			} else {
				extraRow.payment_limit = advertiser
					? extractPaymentLimit(advertiser.payment_terms)
					: ''
			}

			extraRow.advertiser_vat = selectedRow.advertiser_vat
			extraRow.final_with_vat = (
				Number(invoice.final_confirmation_advertiser) +
				Number(invoice.final_confirmation_advertiser) *
					(Number(selectedRow.advertiser_vat) / 100)
			).toFixed(2)
			if (extraRow.received_amount_advertiser) {
				extraRow.bank_fees_advertiser = (
					Number(extraRow.final_with_vat) -
					Number(extraRow.received_amount_advertiser)
				).toFixed(2)
			}
			extraRow.final_confirmation_advertiser_sent =
				invoice.final_confirmation_advertiser_sent
			extraRow.invoice_number_advertiser = invoice.invoice_number_advertiser
			extraRow.invoice_number_advertiser_added_time =
				invoice.invoice_number_advertiser_added_time
			if (invoice.payment_advertiser) {
				extraRow.payment_advertiser = new Date(invoice.payment_advertiser)
			} else {
				extraRow.payment_advertiser = ''
			}
			if (invoice.invoice_number_advertiser_added_time) {
				extraRow.invoice_number_advertiser_added_time_date = new Date(
					invoice.invoice_number_advertiser_added_time,
				)
			} else {
				extraRow.invoice_number_advertiser_added_time_date = ''
			}
			result.push(extraRow)
		}
	}

	if (filterStatus) {
		let filteredArr = []
		if (filterStatus.toLowerCase() === 'all') {
			// console.log('RESULT', result)

			return result
		}
		if (filterStatus.toLowerCase() === 'missing') {
			filteredArr = result.filter(
				(el: any) => !el.final_confirmation_advertiser,
			)
		} else if (filterStatus.toLowerCase() === 'confirmed') {
			filteredArr = result.filter((el: any) => el.final_confirmation_advertiser)
		} else if (filterStatus.toLowerCase() === 'overdue') {
			filteredArr = result.filter((el: any) => {
				if (el.payment_advertiser) {
					return false
				}
				const value = el.invoice_number_advertiser_added_time
				const currentDate = new Date()
				const currentDate_ms = currentDate.getTime()
				const value_ms = new Date(value).getTime()
				const difference_ms = currentDate_ms - value_ms
				const difference_days = Math.floor(
					difference_ms / (1000 * 60 * 60 * 24),
				)

				let paymentLimit: any = ''
				if (el.custom_advertiser_payment_term) {
					paymentLimit = extractPaymentLimit(el.custom_advertiser_payment_term)
				} else {
					paymentLimit = advertiser
						? extractPaymentLimit(advertiser.payment_terms)
						: ''
				}
				// const paymentLimit: any = currentAdvertiser
				// 	? extractPaymentLimit(currentAdvertiser.payment_terms)
				// 	: ''
				return paymentLimit < difference_days
			})
		} else if (filterStatus.toLowerCase() === 'non overdue') {
			filteredArr = result.filter((el: any) => {
				const value = el.invoice_number_advertiser_added_time
				let currentDate = new Date()
				if (el.payment_advertiser) {
					return false
				}
				const currentDate_ms = currentDate.getTime()
				const value_ms = new Date(value).getTime()
				const difference_ms = currentDate_ms - value_ms
				const difference_days = Math.floor(
					difference_ms / (1000 * 60 * 60 * 24),
				)
				// const currentAdvertiser = advertiser
				// const paymentLimit: any = currentAdvertiser
				// 	? extractPaymentLimit(currentAdvertiser.payment_terms)
				// 	: ''
				let paymentLimit: any = ''
				if (el.custom_advertiser_payment_term) {
					paymentLimit = extractPaymentLimit(el.custom_advertiser_payment_term)
				} else {
					paymentLimit = advertiser
						? extractPaymentLimit(advertiser.payment_terms)
						: ''
				}
				return paymentLimit >= difference_days
			})
		} else if (filterStatus.toLowerCase() === 'paid') {
			filteredArr = result.filter((el: any) => {
				return el.payment_advertiser
			})
		}

		// console.log('RESULT', filteredArr)
		return filteredArr
	}
	// console.log('RESULT', result)
	return result
}

const calculatePinnedTotalRow = (rows: any) => {
	const pinnedRow = {
		currency: '',
		custom_advertiser_payment_term: '',
		invoice_number_advertiser: '',
		pid: '',
		publisher: '',
		final_confirmation_advertiser: 0,
		final_with_vat: 0,
		received_amount_advertiser: 0,
		bank_fees_advertiser: 0,
		period: '',
		status: '',
		final_amount: 0,
		id: 9999999999,
	}
	for (const row of rows) {
		if (row.final_with_vat) {
			pinnedRow.final_with_vat =
				Number(pinnedRow.final_with_vat) + Number(row.final_with_vat)
		}
		if (row.final_confirmation_advertiser) {
			pinnedRow.final_confirmation_advertiser =
				Number(pinnedRow.final_confirmation_advertiser) +
				Number(row.final_confirmation_advertiser)
		}
		if (row.currency) {
			if (!pinnedRow.currency) {
				pinnedRow.currency = row.currency
			} else if (pinnedRow.currency !== row.currency) {
				pinnedRow.currency = 'Multiple'
			}
		}
		if (row.custom_advertiser_payment_term) {
			if (!pinnedRow.custom_advertiser_payment_term) {
				pinnedRow.custom_advertiser_payment_term =
					row.custom_advertiser_payment_term
			} else if (
				pinnedRow.custom_advertiser_payment_term !==
				row.custom_advertiser_payment_term
			) {
				pinnedRow.custom_advertiser_payment_term = 'Multiple'
			}
		}
		if (row.received_amount_advertiser) {
			pinnedRow.received_amount_advertiser =
				Number(pinnedRow.received_amount_advertiser) +
				Number(row.received_amount_advertiser)
		}
		if (row.bank_fees_advertiser) {
			pinnedRow.bank_fees_advertiser =
				Number(pinnedRow.bank_fees_advertiser) +
				Number(row.bank_fees_advertiser)
		}
		if (row.final_amount) {
			pinnedRow.final_amount =
				Number(pinnedRow.final_amount) + Number(row.final_amount)
		}
	}
	pinnedRow.final_confirmation_advertiser = Number(
		pinnedRow.final_confirmation_advertiser,
	).toFixed(2) as any
	pinnedRow.received_amount_advertiser = Number(
		pinnedRow.received_amount_advertiser,
	).toFixed(2) as any
	pinnedRow.final_with_vat = Number(pinnedRow.final_with_vat).toFixed(2) as any
	pinnedRow.bank_fees_advertiser = Number(
		pinnedRow.bank_fees_advertiser,
	).toFixed(2) as any
	pinnedRow.final_amount = Number(pinnedRow.final_amount).toFixed(2) as any
	return [pinnedRow]
}

export default DemandInvoiceTable
