import { ThemeProvider } from '@emotion/react'
import '@inovua/reactdatagrid-community/index.css'
import { Grid, MenuItem } from '@mui/material'
import { GridColDef } from '@mui/x-data-grid-pro'
import saveAs from 'file-saver'
import { useEffect, useState } from 'react'
import { useActions } from '../../hooks/useActions'
import { useTypedSelector } from '../../hooks/useTypedSelector'
import { fetchData } from '../../utils/helpers/navigationHelper'
import { generateRowsWithIds, searchFor } from '../../utils/helpers/tableHelper'
import { WarningPrompt } from '../../utils/notifications/WarningPrompt'
import theme from '../../utils/theme2024'
import { PageWrapper, TableWrapper } from '../components/reusableComponents'
import WishListAdd from './WishListAdd'
import { TableComponent } from '../components/TableComponents'
import { SimpleActionsButton } from '../components/Buttons'
// import AccountsEdit from './AppsflyerAccountsAdd'

const WishListTable = (props: {
	setLoading: any
	errorMessage: any
	setErrorMessage: any
	isErrorPromptOpened: any
	setIsErrorPromptOpened: any
	isSuccessPromptOpened: any
	setIsSuccessPromptOpened: any
	isEditorOpened?: any
	isAddEditorOpened?: any
	presetSearch: any
	setPresetSearch: any
	PopUpVisible: any
	setPopUpVisible: any
}) => {
	const rowCount = 9
	const { login, presearch, wishlist } = useTypedSelector((state) => state)
	const { presearchAction, getPublisherWishList, deletePublisherWishList } =
		useActions()
	const [pageSize, setPageSize] = useState(15)
	const [AddVisible, setAddVisible] = useState(false)
	const [row, setRow] = useState<any>(undefined)
	const [search, setSearch] = useState('')
	const [found, setFound] = useState<any[]>(
		// searchFor(search, wishlist.wishlist),
		[],
	)
	const [update, setUpdate] = useState(false)
	const [isWarningPromptOpened, setIsWarningPromptOpened] = useState(false)
	const [rowWidth, setRowWidth] = useState(
		Math.floor(window.innerWidth - theme.dimentions.drawerWidth) / rowCount,
	)
	const [isAdding, setIsAdding] = useState(false)
	const [sortModel, setSortModel] = useState([{ field: 'name', sort: 'asc' }])
	const headers = {
		Authorization: `Token ${login.user.token}`,
	}
	const actionOptions = (row: any) => {
		return (
			<div>
				<MenuItem onClick={() => handleEdit(row)}>Edit</MenuItem>
				<MenuItem
					onClick={() => {
						setRow(row.row)
						setIsWarningPromptOpened(true)
					}}
				>
					Delete
				</MenuItem>
			</div>
		)
	}

	const columns: GridColDef[] = [
		{
			field: 'publisher',

			headerName: 'Publisher',
			width: rowWidth,
		},
		{
			field: 'app',
			headerName: 'App',
			width: rowWidth,
		},
		{
			field: 'app_name',
			headerName: 'App Name',
			width: rowWidth,
		},
		{
			field: 'requested_PO',
			headerName: 'Requested PO',
			width: rowWidth,
		},
		{
			field: 'preview_link',
			headerName: 'Preview Link',
			width: rowWidth,
		},
		{
			field: 'platform',
			headerName: 'Platform',
			width: rowWidth,
		},
		{
			field: 'geo',
			headerName: 'Geo',
			width: rowWidth,
		},
		{
			field: 'comments',
			headerName: 'Comments',
			width: rowWidth,
		},
		{
			field: 'actions',

			headerName: 'Actions',

			// width: rowWidth,
			sortable: false,
			renderCell: (row: any) => (
				<SimpleActionsButton
					// width={rowWidth}
					// row={row}
					label={'Actions'}
					options={actionOptions(row)}
					// style={{ width: '40px', height: '22px', fontSize: '30px' }}
					// arrowColor={'rgba(0,0,0,0.75)'}
				/>
			),
		},
	]

	// const handlePopUp = () => {
	// 	if (!props.PopUpVisible && !AddVisible) {
	// 		fetchData(login, getPublisherWishList, wishlist.wishlist)
	// 		//setRow(undefined)
	// 	}
	// 	if (!AddVisible) {
	// 		props.setPopUpVisible(!props.PopUpVisible)
	// 	}
	// 	if (!props.PopUpVisible) {
	// 		setAddVisible(!AddVisible)
	// 	}
	// }
	const showPopUps = () => {
		if (AddVisible) {
			return (
				<WishListAdd
					setLoading={props.setLoading}
					errorMessage={props.errorMessage}
					setErrorMessage={props.setErrorMessage}
					isErrorPromptOpened={props.isErrorPromptOpened}
					setIsErrorPromptOpened={props.setIsErrorPromptOpened}
					setIsWarningPromptOpened={setIsWarningPromptOpened}
					setIsSuccessPromptOpened={props.setIsSuccessPromptOpened}
					setIsAdding={setIsAdding}
					setAddVisible={setAddVisible}
					row={row}
					setRow={setRow}
				/>
				// <AccountsEdit
				// 	advertisers={undefined}
				// 	PopUpVisible={AddVisible}
				// 	setPopUpVisible={handlePopUp}
				// 	row={row}
				// 	setLoading={props.setLoading}
				// 	blockPasswords={blockPasswords}
				// 	isAdding={isAdding}
				// 	setIsAdding={setIsAdding}
				// 	setRow={setRow}
				// 	errorMessage={props.errorMessage}
				// 	setErrorMessage={props.setErrorMessage}
				// 	isErrorPromptOpened={props.isErrorPromptOpened}
				// 	setIsErrorPromptOpened={props.setIsErrorPromptOpened}
				// 	isSuccessPromptOpened={props.isSuccessPromptOpened}
				// 	setIsSuccessPromptOpened={props.setIsSuccessPromptOpened}
				// />
			)
		}
		return null
	}
	const popUp = showPopUps()
	const handleEdit = (row: any) => {
		setRow(row.row)
		setIsAdding(false)
		setAddVisible(!AddVisible)
	}
	const createDeletingPayload = (row: any) => {
		if (row) {
			return {
				publisher: row.publisher,
				app: row.app,
				app_name: row.app_name,
				requested_PO: row.requested_PO,
				preview_link: row.preview_link,
				platform: row.platform,
				comments: row.comments,
				geo: row.geo,
				uuid: row.uuid,
			}
		}
		return {}
	}
	const handleDelete = async (row: any) => {
		await deletePublisherWishList(
			headers,
			{ data: createDeletingPayload(row) },
			props.setErrorMessage,
		)
		await fetchData(login, getPublisherWishList, wishlist.wishlist)
		setIsWarningPromptOpened(false)
		//setAddVisible(!AddVisible)
	}
	//MISSING USEEFFECT LOGIC FOR NULIFY FIELDS OF ADDCAMPAIGN
	useEffect(() => {
		if (search.length === 0 && presearch !== null) {
			setSearch(presearch.toString())
			props.setPresetSearch(null)
		}
		if (search.length > 0) {
			setFound(searchFor(search, wishlist.wishlist, columns))
		}
	}, [search, props, wishlist.wishlist, presearch])

	useEffect(() => {
		if (search.length === 0) {
			if (presearch !== null) {
				presearchAction(null)
			}
		}
	}, [search, presearchAction, presearch])

	useEffect(() => {
		const fetchUpdate = async () => {
			setRow(undefined)
			await fetchData(login, getPublisherWishList, wishlist.wishlist)
			setUpdate(!update)
		}
		if (!props.PopUpVisible) {
			fetchUpdate()
		}
	}, [props.PopUpVisible])

	// const handlePasswordVisibility = () => {
	// showPasswordAction(headers, login.user.email, setCodeHash)
	// 	props.setPopUpVisible(!props.PopUpVisible)
	// }
	const handleNewWish = () => {
		setIsAdding(true)
		setAddVisible(true)
	}

	return popUp === null ? (
		<ThemeProvider theme={theme}>
			<PageWrapper style={{ marginTop: '20px' }}>
				<Grid
					container
					spacing={0}
					direction={'row'}
					alignItems={'center'}
					width={'100%'}
				></Grid>
				<TableWrapper
					theme={theme}
					rowCount={2}
					setRowWidth={() => 10}
					style={{ paddingBottom: '60px', width: '90%' }}
				>
					<TableComponent
						columns={columns}
						rows={generateRowsWithIds(
							search.length > 0 ? found : wishlist.wishlist,
						)}
						setPageSize={setPageSize}
						pageSize={pageSize}
						rowsPerPageOptions={[15, 25, 50]}
						setLoading={props.setLoading}
						experimentalFeatures={{ newEditingApi: true }}
						setSearch={setSearch}
						search={search}
						handlePopUp={handleNewWish}
						// handlePasswordVisibility={handlePasswordVisibility}
						sortModel={sortModel}
						onSortModelChange={(model: any) => setSortModel(model)}
						// downloadButtonOptions={downloadButtonoptions}

						// onRowEditStop={(params: any, event: any) =>
						// 	updatePasswordsAction(headers, { dto: createPayload(params.row) })
						// }
					></TableComponent>
				</TableWrapper>
				<WarningPrompt
					message={'Deleting the row now?'}
					PopUpVisible={isWarningPromptOpened}
					setPopUpVisible={setIsWarningPromptOpened}
					onAgree={() => handleDelete(row)}
					onCancel={() => props.setLoading(false)}
					obj={row}
				/>
			</PageWrapper>
		</ThemeProvider>
	) : (
		popUp
	)
}
export default WishListTable
