import { Typography } from '@mui/material'
import React from 'react'
import { useNavigate } from 'react-router-dom'
import { LoginButton } from '../pages/login/loginPageComponents'
import theme from './theme2024'
import coloredLogo from '../assets/varys-logo-colored.png'
import hero from '../assets/locked-out-hero.png'

const refreshButtonStyles = {
	width: '436px',
	height: '44px',
	font: 'Lato',
	backgroundColor: theme.customComponents.button.green,
	color: '#242D32',
	borderRadius: '6px',
	padding: '8px, 24px, 8px, 24px'
}

const NotLogged = () => {
	const navigate = useNavigate()
	return (
		<div style={{
			display: 'flex',
			height: '100vh',
		}}>
			{/* Left side - gradient + Image container */}
			<div style={{
            flex: 1,  
            backgroundImage: theme.colors.gradientLoginPage,
            backgroundSize: 'cover',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',  
        }}>
            <div style={{
                width: '50%',  
                height: '50%',  
            }}>
                <img src={hero} alt="secondary-hero" style={{
                    width: '100%',
                    height: '100%',
                    objectFit: 'contain',
                }} />
            </div>
        </div>
	
			{/* Right side - component */}
			<div style={{
				flex: 1,  
				display: 'flex',
				alignItems: 'center',
				justifyContent: 'center',
				flexDirection: 'column',
				backgroundColor: theme.colors.base.white, 
				color: theme.colors.base.grey900,
			}}>
				<div style={{
					display: 'flex',
					alignItems: 'center',
					position: 'absolute',
					top: '5%',
					left: '88%',
					transition: 'left 0.5s ease-in-out',
				}}>
					<div style={{ width: '3.75rem' }}>
						<img src={coloredLogo} alt='logo' style={{ width: '100%' }} />
					</div>
					<span style={{ fontSize: '1.75rem', color: '#242D32' }}>Varys</span>
				</div>
				<div style={{
					display: 'flex',
					flexDirection: 'column',
					justifyContent: 'center',
					alignItems: 'center',
					gap: '10px',
				}}>
					<Typography style={{
						color: '#242D32',
						fontSize: '46px',
						fontWeight: '600',
						font: 'Lato',
					}}>
						Oops! Session expired
					</Typography>
					<Typography style={{
						color: '#242D32',
						fontSize: '20px',
						fontWeight: '400',
						font: 'Lato',
					}}>
						Please refresh the page to continue
					</Typography>
					<LoginButton variant='contained' size='large' onClick={() => {
						navigate('/')
					}} style={refreshButtonStyles}>
						Refresh
					</LoginButton>
				</div>
			</div>
		</div>
	);
	
}

export default NotLogged
