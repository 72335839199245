import moment from 'moment'
import { itemExists, padTo2Digits } from './tableHelper'
import theme from '../theme2024'

export const emailToUserConvertor = (email: string): string => {
	const userNamePart = email.replace('@thing02.com', '')
	const capitalized =
		userNamePart.charAt(0).toUpperCase() + userNamePart.slice(1)
	const noDots = capitalized.replace('.', ' ')
	if (noDots.includes(' ')) {
		const arr = noDots.split(' ')
		const result = []
		for (const el of arr) {
			result.push(emailToUserConvertor(el))
		}
		return result.join(' ') + '.'
	}
	return capitalized
}
//main function to generate nodes for the checkboxes. Mainly used for publisher media capabilities.
export const generateNodesFromArray = (arr: string[], structure: any) => {
	try {
		const baseNodes = arr.map((value: string) => {
			const obj = {}
			for (const [key] of Object.entries(structure)) {
				if (key === 'value' || key === 'label') {
					Object.assign(obj, { [key]: value })
				} else {
					Object.assign(obj, { [key]: '' })
				}
			}
			return obj
		})
		return baseNodes
	} catch (error) {
		console.error(error)
		return []
	}
}
export const filterContactsByType = (arr: any, type: string) => {
	if (!arr || arr.length === 0) return []
	const filtered: string[] = []
	arr.forEach((el: any) => {
		if (el.type === type) filtered.push(el.email)
	})
	return filtered.sort()
}

export function formatDate(date: Date) {
	let dateCopy = new Date(date)
	let offset = 0

	if (date && date.getUTCHours() > 12) {
		offset = 1
		dateCopy.setUTCDate(dateCopy.getUTCDate() + offset)
	}
	return [
		dateCopy.getUTCFullYear(),
		padTo2Digits(dateCopy.getUTCMonth() + 1),
		padTo2Digits(dateCopy.getUTCDate()),
	].join('-')
}

export function stringIsValidName(string: string) {
	if (
		string.includes('/') ||
		string.endsWith(' ') ||
		string.startsWith(' ') ||
		string.includes('%') ||
		string.includes('.') ||
		string.includes(',') ||
		string.length === 0
	) {
		return false
	}
	return true
}
export function checkValidBudgets(daily: number, monthly: number) {
	if (daily > 0 && monthly > 0) {
		return daily > monthly
	}
	return false
}
export function isValidEmail(email: string) {
	return email.includes('@')
}

export const makeDictionaryFromEntries = (object: any) => {
	return Object.assign(
		{},
		...Object.entries(object).map((x) => ({
			[x[0]]: x[1],
		})),
	)
}

export const makeDictionaryByKey = (array: any, key: string) => {
	const result: Record<string, any[]> = Object.assign(
		{},
		...array.map((x: any) => ({
			[x[key]]: [],
		})),
	)
	for (var i = 0; i < array.length; i++) {
		if (array[i][key]) {
			if (!itemExists(Object.values(result), array[i])) {
				for (const [keys, values] of Object.entries(result)) {
					if (keys === array[i][key] && result[array[i][key]]) {
						result[array[i][key]].push(array[i])
					}
				}
			}
		}
	}
	return result
}

export const formatDateToMonth = (inputDate: string) => {
	if (!inputDate) return null
	return moment(inputDate, 'DD/MM/YYYY').format('MMM YYYY')
}

export const getMonthAbbreviation = (dateStr: string) => {
	if (!dateStr) return null
	const month = moment(dateStr, 'DD/MM/YYYY').format('MMM')
	return month
}
export const getMonth = (dateStr: string) => {
	if (!dateStr) return null

	const month = moment(dateStr, 'DD/MM/YYYY').format('MMMM')
	return month
}
export const getYear = (dateStr: string) => {
	if (!dateStr) return null
	const year = moment(dateStr, 'DD/MM/YYYY').format('YYYY')
	return year
}

export const handleFinanceStatusColor = (value: string) => {
	if (!value) {
		return ''
	}
	const valueToCheck = value.toLowerCase()
	if (valueToCheck === 'yes') {
		return theme.colors.base.green500
		// return 'linear-gradient(90deg, rgba(0, 255, 44, 1) 0%, rgba(0, 204, 38, 1) 100%)'
	} else if (valueToCheck === 'on hold') {
		// return 'linear-gradient(90deg, rgba(230, 230, 230, 1) 0%, rgba(179, 179, 179, 1) 100%)'
		// return 'linear-gradient(90deg, rgba(252, 238, 33, 1) 0%, rgba(251, 176, 59, 1) 100%)'
		return theme.colors.base.yellow400
	} else if (valueToCheck === 'on hold yellow') {
		// return 'linear-gradient(90deg, rgba(252, 238, 33, 1) 0%, rgba(251, 176, 59, 1) 100%)'
		return theme.colors.base.yellow400
	} else if (valueToCheck === 'na') {
		// return 'linear-gradient(90deg, rgba(72, 76, 255, 1) 0%, rgba(0, 175, 255, 1) 100%)'
		return theme.colors.base.blue400
	} else {
		// return 'linear-gradient(90deg, rgba(227, 120, 131, 1) 0%, rgba(233, 43, 74, 1) 100%)'
		return theme.colors.base.red400
	}
}

export const returnAbbreviatedMonth = (month: string) => {
	const map = {
		January: 'Jan',
		February: 'Feb',
		March: 'Mar',
		April: 'Apr',
		May: 'May',
		June: 'Jun',
		July: 'Jul',
		August: 'Aug',
		September: 'Sep',
		October: 'Oct',
		November: 'Nov',
		December: 'Dec',
	}
	return map[month as keyof typeof map]
}
export const findPublisherFromPid = (pid: string, publishers: any) => {
	const found = []
	for (const pub of publishers) {
		if (pub.media_source_pid.includes(pid)) {
			found.push(pub.publisher_name)
		}
	}
	if (found.length === 0) {
		return publishers
			.filter((pub: any) => pub.status === true)
			.map((pub: any) => pub.publisher_name)
			.sort((a: string, b: string) => a.localeCompare(b))
	}
	return found.sort((a: string, b: string) => a.localeCompare(b))
}
export const findCreatorFromId = (id: string, creators: any) => {
	const found = []
	for (const creator of creators) {
		if (creator.creator_id === id) {
			found.push(creator.name)
		}
	}
	if (found.length === 0) {
		return creators
			.map((creator: any) => creator.name)
			.sort((a: string, b: string) => a.localeCompare(b))
	}
	return found.sort((a: string, b: string) => a.localeCompare(b))
}
export const findAdvertiserByOwner = (owner: string, advertiser: any) => {
	const found = []
	for (const pub of advertiser) {
		if (pub.email === owner) {
			found.push(pub.advertiser_name)
		}
	}
	if (found.length === 0) {
		return advertiser
			.filter((adv: any) => adv.status === true)
			.map((adv: any) => adv.advertiser_name)
			.sort((a: string, b: string) => a.localeCompare(b))
	}
	return found.sort((a: string, b: string) => a.localeCompare(b))
}
//function used for global finance. It generates the hierarchy for the rows based on the given key value (campaign by default).
export const createHierarchy = (array: any, key: string = 'campaign') => {
	return array
	// console.log(array, key)
	// const arrayCopy = [...array]
	// const alreadyRegistered: any = []
	// const result = []
	// for (const element of array) {
	// 	let row = element
	// 	if (alreadyRegistered.includes(element[key])) {
	// 		row.hierarchy = [element[key], element.uuid]
	// 		row.row_status = row.status
	// 	} else {
	// 		let mainRow: any = {}
	// 		mainRow.campaign = element[key]
	// 		mainRow.advertiser = element.advertiser
	// 		mainRow.platform = ''
	// 		mainRow.uuid = element.uuid + 'main'
	// 		mainRow.pid = ''
	// 		mainRow.month = ''
	// 		mainRow.notes = ''
	// 		mainRow.owner = ''
	// 		mainRow.row_status = ''
	// 		mainRow.revenue = 0
	// 		mainRow.cost = 0
	// 		mainRow.approved_conversions = 0
	// 		mainRow.deduction_amount = 0
	// 		mainRow.final_amount = 0

	// 		const summableKeys = [
	// 			'revenue',
	// 			'cost',
	// 			'approved_conversions',
	// 			'deduction_amount',
	// 			'final_amount',
	// 		]
	// 		const statusCostMap = {
	// 			yes: 0,
	// 			no: 0,
	// 			on_hold: 0,
	// 		}
	// 		let multipleRows = false
	// 		for (const savedElement of arrayCopy) {
	// 			if (element[key] === savedElement[key]) {
	// 				if (element.uuid !== savedElement.uuid) {
	// 					multipleRows = true
	// 				}
	// 				for (const key in savedElement) {
	// 					if (summableKeys.includes(key)) {
	// 						if (savedElement[key]) {
	// 							if (key === 'final_amount') {
	// 								if (savedElement.status.toLowerCase() === 'no') {
	// 									mainRow[key] = Number(mainRow[key]) + 0
	// 								} else {
	// 									mainRow[key] =
	// 										Number(mainRow[key]) + Number(savedElement[key])
	// 								}
	// 							} else {
	// 								mainRow[key] =
	// 									Number(mainRow[key]) + Number(savedElement[key])
	// 							}
	// 						}
	// 					}
	// 				}

	// 				if (savedElement.cost) {
	// 					if (savedElement.status.toLowerCase() === 'yes') {
	// 						statusCostMap.yes =
	// 							statusCostMap.yes + parseInt(savedElement.cost)
	// 					} else if (savedElement.status.toLowerCase() === 'no') {
	// 						statusCostMap.no = statusCostMap.no + parseInt(savedElement.cost)
	// 					} else if (savedElement.status.toLowerCase() === 'on hold') {
	// 						statusCostMap.on_hold =
	// 							statusCostMap.on_hold + parseInt(savedElement.cost)
	// 					}
	// 				}
	// 			}
	// 		}
	// 		mainRow.yes_status_values = Math.floor(
	// 			(100 * statusCostMap.yes) / mainRow.cost,
	// 		)
	// 		mainRow.no_status_values = Math.floor(
	// 			(100 * statusCostMap.no) / mainRow.cost,
	// 		)
	// 		mainRow.on_hold_status_values = Math.floor(
	// 			(100 * statusCostMap.on_hold) / mainRow.cost,
	// 		)
	// 		mainRow.row_status = row.status.toLowerCase()
	// 		row.row_status = row.status
	// 		if (multipleRows) {
	// 			mainRow.hierarchy = [element[key]]
	// 			alreadyRegistered.push(element[key])
	// 			result.push(mainRow)
	// 			row.hierarchy = [element[key], element.uuid]
	// 		} else {
	// 			row.hierarchy = [element[key]]
	// 		}
	// 	}
	// 	result.push(row)
	// }
	// if (result[0] && !result[0].campaign) {
	// 	result.shift()
	// }
	// return result
}
export function formatDateToDdMmYyyy(isoDate: string) {
	const date = new Date(isoDate)
	const day = String(date.getUTCDate()).padStart(2, '0')
	const month = String(date.getUTCMonth() + 1).padStart(2, '0')
	const year = date.getUTCFullYear()
	return `${day}/${month}/${year}`
}
export function formatFinancePaymentDate(
	date: Date,
	noOffset: boolean = false,
) {
	if (!date || isNaN(date.getTime())) {
		return ''
	}
	let dateCopy = new Date(date)
	let offset = 0
	if (date && new Date(date).getUTCHours() > 12) {
		if (!noOffset) {
			offset = 1
		}
		dateCopy.setUTCDate(dateCopy.getUTCDate() + offset)
	}
	const result = [
		padTo2Digits(dateCopy.getUTCDate()),
		padTo2Digits(dateCopy.getUTCMonth() + 1),
		dateCopy.getUTCFullYear(),
	].join('/')
	return result
}

export function stringToColor(string: string) {
	let hash = 0
	let i

	/* eslint-disable no-bitwise */
	for (i = 0; i < string.length; i += 1) {
		hash = string.charCodeAt(i) + ((hash << 5) - hash)
	}

	let color = '#'

	for (i = 0; i < 3; i += 1) {
		const value = (hash >> (i * 8)) & 0xff
		color += `00${value.toString(16)}`.slice(-2)
	}
	/* eslint-enable no-bitwise */

	return color
}

export const stringAvatar = (name: string) => {
	const secondLetter =
		name.split(' ').length > 1 ? name.split(' ')[1][0] : name[1]
	return {
		sx: {
			bgcolor: stringToColor(name),
		},
		children: `${name.split(' ')[0][0]}${secondLetter}`,
	}
}

export function formatDateMonthYear(inputStr: any, entireMonthName = true) {
	// Parse the input string to a Date object
	const date = moment(inputStr, 'DD/MM/YYYY').format(
		entireMonthName ? 'MMMM-YYYY' : 'MMM-YYYY',
	)

	return date
}

export const extractPaymentLimit = (input: string) => {
	if (!input) return ''
	const lowerCaseInput = input.toLowerCase()
	return lowerCaseInput.slice(3)
}

export const sortDates = (value1: any, value2: any) => {
	if (!value1 || isNaN(value1)) {
		return -1
	}
	if (!value2 || isNaN(value2)) {
		return 1
	}
	const date1 = new Date(value1)
	const date2 = new Date(value2)
	if (date1 < date2) return -1
	if (date1 > date2) return 1
	return 0
}

export const sortDatesStrings = (str1: string, str2: string) => {
	if (!str1 && !str2) {
		return 0
	}
	if (!str1) {
		return -1
	}
	if (!str2) {
		return 1
	}

	// Check and format the dates to 'MMM YYYY'
	const formattedStr1 = moment(str1, ['DD/MM/YYYY', 'MMM YYYY']).format(
		'MMM YYYY',
	)
	const formattedStr2 = moment(str2, ['DD/MM/YYYY', 'MMM YYYY']).format(
		'MMM YYYY',
	)

	const date1 = moment(formattedStr1, 'MMM YYYY')
	const date2 = moment(formattedStr2, 'MMM YYYY')

	if (date1.isBefore(date2)) return -1
	if (date1.isAfter(date2)) return 1
	return 0
}

export const sortHardcodedDates = (str1: string, str2: string) => {
	if (!str1) {
		return -1
	}
	if (!str2) {
		return 1
	}
	const date1 = moment(str1)
	const date2 = moment(str2)
	if (date1.isBefore(date2)) return -1
	if (date1.isAfter(date2)) return 1
	return 0
}

export const findDifferences = (obj1: any, obj2: any) => {
	const changes = []
	for (const key in obj1) {
		if (obj1[key] !== obj2[key]) {
			changes.push(key)
		}
	}
	return changes
}
export function getApplicableRules(
	users: any,
	login: any,
	settings: any,
	page: string,
) {
	if (users.users.length === 0 || settings.settings.length === 0) return false
	let user = users.users.find((user: any) => user.email === login.user.email)
	const userType = user.user_type
	const userRole = user.role

	const perms = settings.settings.permissionGroups[userType][userRole]
	if (!perms) {
		console.error(
			'Permissions for the given userType and userRole are undefined.',
		)
		return {}
	}
	let ruleSet = []

	if (perms) {
		ruleSet = perms.find((e: any) => e.endpoint === page)
	}

	return ruleSet
}

export function convertPathName(pathname: string) {
	const map = {
		'/campaigns': 'Campaign',
		'/advertisers': 'Advertiser',
		'/p360apps': 'Schedule',
		'/users': 'User',
		'/publishers': 'Publisher',
		'/creators': 'Creator',
	}
	if (map[pathname as keyof typeof map]) {
		return ' ' + map[pathname as keyof typeof map]
	}
	return ''
}

export function isValidImageType(file: File): boolean {
	const validTypes = ['image/jpeg', 'image/png', 'image/svg+xml']
	return validTypes.includes(file.type)
}

export const handleIconUrl = (iconUrl: string, defaultIcon: string) => {
	return !iconUrl || iconUrl === '' ? defaultIcon : iconUrl
}

export const stringIsValidEmail = (email: string): boolean => {
	const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/
	return emailRegex.test(email)
}

export const stringIsValidGender = (gender: string): boolean => {
	const validGenders = ['Male', 'Female', 'Binary', 'None']
	return validGenders.includes(gender)
}

export const stringIsValidType = (type: string): boolean => {
	const validTypes = ['IM', 'UGC']
	return validTypes.includes(type)
}

export const stringIsValidCreatorId = (creatorId: string): boolean => {
	return creatorId.trim() !== ''
}

export const stringIsValidAddress = (address: string): boolean => {
	return address.trim() !== ''
}
