import moment from 'moment'
import { FinanceInterface } from '../../models/model.interface'
import { formatDateMonthYear } from '../../utils/helpers/helperFuncs'
import { ActionType } from '../action-types/index'
import { Action } from '../actions'

export interface GetFinanceInterface {
	finance: FinanceInterface | any
	index?: number
}

const initialState: GetFinanceInterface = {
	finance: [],
}

const reducer = (
	state: GetFinanceInterface = initialState,
	action: Action,
): GetFinanceInterface => {
	switch (action.type) {
		case ActionType.UPLOAD_FINANCE: {
			const newState = state.finance.map((el: any) => {
				if (el.uuid === action.payload.uuid) {
					return action.payload
				} else {
					return el
				}
			})
			return { finance: newState }
		}
		case ActionType.DELETE_FINANCE: {
			const newState = state.finance.filter(
				(el: any) => el.uuid !== action.payload.uuid,
			)

			return { finance: newState }
		}
		case ActionType.UPLOAD_FINANCE_ROLLOVER: {
			const newState = []
			for (const row of state.finance) {
				if (row.uuid === action.payload.uuid) {
					const newRow = { ...row }
					newRow.roll_over = action.payload.roll_over
					newRow.roll_over_month = action.payload.roll_over_month
					newState.push(newRow)
				} else {
					newState.push(row)
				}
			}
			return { finance: newState }
		}
		case ActionType.UPLOAD_FINANCE_DEMAND: {
			const newState = []
			for (const row of state.finance) {
				if (
					row.advertiser === action.payload.advertiser &&
					row.month === action.payload.month
				) {
					const newRow = { ...row }
					newRow.currency = action.payload.currency
					newRow.entity = action.payload.entity
					newRow.final_confirmation_advertiser =
						action.payload.final_confirmation_advertiser
					newRow.final_confirmation_advertiser_sent =
						action.payload.final_confirmation_advertiser_sent
					newRow.invoice_number_advertiser =
						action.payload.invoice_number_advertiser
					if (action.payload.invoice_number_advertiser_added_time_date) {
						newRow.invoice_number_advertiser_added_time =
							action.payload.invoice_number_advertiser_added_time_date
					} else {
						newRow.invoice_number_advertiser_added_time =
							action.payload.invoice_number_advertiser_added_time
					}
					newRow.payment_advertiser = action.payload.payment_advertiser
					newRow.final_with_vat =
						Number(action.payload.final_confirmation_advertiser) +
						Number(action.payload.final_confirmation_advertiser) *
							(Number(action.payload.advertiser_vat) / 100)
					if (action.payload.payment_advertiser) {
						newRow.proof = ''
					}
					newState.push(newRow)
				} else {
					newState.push(row)
				}
			}
			// console.log('newstate', newState)
			return { finance: newState }
		}
		case ActionType.UPLOAD_FINANCE_BALANCEIN: {
			const newState = []
			for (const row of state.finance) {
				if (
					row.advertiser === action.payload.advertiser &&
					row.month === action.payload.month
				) {
					const newRow = { ...row }
					newRow.bank_fees_advertiser = action.payload.bank_fees_advertiser
					newRow.received_amount_advertiser =
						action.payload.received_amount_advertiser
					newState.push(newRow)
				} else {
					newState.push(row)
				}
			}
			return { finance: newState }
		}
		case ActionType.UPLOAD_FINANCE_BALANCEOUT: {
			const newState = []
			for (const row of state.finance) {
				if (
					row.publisher === action.payload.publisher &&
					((!row.roll_over && row.month === action.payload.month) ||
						(row.roll_over && row.roll_over_month === action.payload.month))
				) {
					const newRow = { ...row }
					newRow.bank_fees_publisher = action.payload.bank_fees_publisher
					newRow.payment = action.payload.payment
					newRow.invoice_number = action.payload.invoice_number
					newRow.due_date = action.payload.due_date
					newRow.paid_amount_publisher = action.payload.paid_amount_publisher
					newRow.payment_method_publisher =
						action.payload.payment_method_publisher
					if (newRow.bank_fees_publisher) {
						newRow.profit =
							Number(newRow.profit) - Number(newRow.bank_fees_publisher)
					}
					newState.push(newRow)
				} else {
					newState.push(row)
				}
			}
			return { finance: newState }
		}
		case ActionType.SEND_EMAIL_FINANCE: {
			const newState = []
			const publisher = action.payload.payload.publisher[0]
			const period = action.payload.payload.month
			for (const row of state.finance) {
				const newRow = { ...row }
				if (
					row.publisher.toLowerCase() ===
						publisher.publisher_name.toLowerCase() &&
					row.month === period
				) {
					const currentTime = new Date()
					const currentTimeISO = currentTime.toISOString()
					newRow.last_send.push(currentTimeISO)
					if (action.payload.type !== 'status') {
						newRow.final_numbers_sent = true
					}
				}
				newState.push(newRow)
			}
			return { finance: newState }
		}
		case ActionType.SEND_EMAIL_FINANCE_ADVERTISER: {
			const newState = []
			if (action.payload.row.mainInvoice) {
				const newRow = { ...action.payload.row }
				newRow.final_confirmation_advertiser_sent = true
				newState.push(newRow)
			} else {
				const newInvoicesArr = []

				for (const invoice of action.payload.main_row.extra_invoices) {
					const newRow = { ...invoice }
					if (invoice.uuid === action.payload.row.uuid) {
						// console.log(newRow)
						newRow.final_confirmation_advertiser_sent = true
					}
					newInvoicesArr.push(newRow)
				}
				const newMainRow = {
					...action.payload.main_row,
					extra_invoices: newInvoicesArr,
				}
				newState.push(newMainRow)
			}
			return { finance: newState }
		}
		case ActionType.UPLOAD_INSTRUCTIONS_FINANCE: {
			const newState = []
			if (action.payload.new_row.mainInvoice) {
				for (const row of state.finance) {
					let newRow: any = { ...row }
					if (
						row.advertiser === action.payload.new_row.advertiser &&
						row.month === action.payload.new_row.month
					) {
						newRow.invoice_instructions =
							action.payload.new_row.invoice_instructions
					}
					newState.push(newRow)
				}
			} else {
				for (const row of state.finance) {
					let newRow: any = { ...row }
					if (
						row.advertiser === action.payload.main_row.advertiser &&
						row.month === action.payload.main_row.month
					) {
						newRow.extra_invoices = action.payload.main_row.extra_invoices
					}
					newState.push(newRow)
				}
			}
			return { finance: newState }
		}
		case ActionType.UPLOAD_PROOF_FINANCE: {
			const newState = []
			if (action.payload.type === 'publisher') {
				for (const row of state.finance) {
					let newRow: any = { ...row }
					if (row.uuid === action.payload.row.uuid) {
						newRow.proof_publisher = action.payload.row.proof_publisher
					}
					newState.push(newRow)
				}
			} else {
				if (action.payload.row.mainInvoice) {
					for (const row of state.finance) {
						let newRow: any = { ...row }
						if (
							row.advertiser === action.payload.row.advertiser &&
							row.month === action.payload.row.month
						) {
							newRow.proof = action.payload.row.proof
						}
						newState.push(newRow)
					}
				} else {
					for (const row of state.finance) {
						let newRow: any = { ...row }
						if (
							row.advertiser === action.payload.main_row.advertiser &&
							row.month === action.payload.main_row.month
						) {
							newRow.extra_invoices = action.payload.main_row.extra_invoices
						}
						newState.push(newRow)
					}
				}
			}

			return { finance: newState }
		}
		case ActionType.DELETE_PROOF_FINANCE: {
			// console.log(action.payload)
			const newState = []
			if (action.payload.type === 'publisher') {
				for (const row of state.finance) {
					let newRow: any = { ...row }
					if (row.uuid === action.payload.row.uuid) {
						newRow.proof_publisher = ''
					}
					newState.push(newRow)
				}
			} else {
				if (action.payload.row.mainInvoice) {
					for (const row of state.finance) {
						let newRow: any = { ...row }
						if (
							row.advertiser === action.payload.row.advertiser &&
							row.month === action.payload.row.month
						) {
							newRow.proof = ''
						}
						newState.push(newRow)
					}
				} else {
					for (const row of state.finance) {
						let newRow: any = { ...row }
						if (
							row.advertiser === action.payload.main_row.advertiser &&
							row.month === action.payload.main_row.month
						) {
							const newExtraInvoices: any = []
							for (const invoice of action.payload.main_row.extra_invoices) {
								if (invoice.uuid === action.payload.row.uuid) {
									invoice.proof = ''
								}
								newExtraInvoices.push(invoice)
							}
							newRow.extra_invoices = newExtraInvoices
						}
						newState.push(newRow)
					}
				}
			}
			return { finance: newState }
		}
		case ActionType.GET_FINANCE: {
			const alreadyIn = state.finance
			let updated = []
			//we check the index because if it's 0 it means we are pulling the data again so we force a clean up just in case.
			if (action.index === 0) {
				updated = [...action.payload]
			} else {
				updated = [...alreadyIn, ...action.payload]
			}
			return {
				...state,
				finance: updated,
			}
		}
		case ActionType.EMPTY_FINANCE: {
			return {
				finance: [],
			}
		}
		//////////////////creators:
		case ActionType.CREATE_CREATOR_CAMPAIGN: {
			action.payload.raw[0].uuid =
				action.payload.raw[0].uuid +
				'-creator-' +
				moment(action.payload.raw[0].month, 'DD/MM/YYYY').format('DD-MM-YYYY')
			const newState = [...state.finance, action.payload.raw[0]]

			return { finance: newState }
		}
		default: {
			return state
		}
	}
}

export default reducer
