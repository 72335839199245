import { CreatorInterface } from '../../models/model.interface'
import { ActionType } from '../action-types/index'
import { Action } from '../actions'

export interface CreatorReducerInterface {
	creator: CreatorInterface | any
}

const initialState = {
	creator: [],
}

const reducer = (
	state: CreatorReducerInterface = initialState,
	action: Action,
): CreatorReducerInterface => {
	switch (action.type) {
		case ActionType.GET_CREATOR:
			return {
				...state,
				creator: action.payload,
			}
		case ActionType.INSERT_CREATOR: {
			console.log(state, action.payload)
			const updatedState = []
			let updated = false
			for (const creator of state.creator) {
				if (creator.uuid === action.payload.uuid) {
					updatedState.push(action.payload)
					updated = true
				} else {
					updatedState.push(creator)
				}
			}
			//if uuid is not found it means that it's a new creator
			if (!updated) {
				updatedState.push(action.payload)
			}
			return {
				creator: updatedState,
			}
		}
		case ActionType.UPDATE_CREATOR_ICON:
			return {
				...state,
				creator: state.creator.map((creator: CreatorInterface) =>
					creator.creator_name === action.payload.name
						? { ...creator, iconUrl: action.payload.iconUrl }
						: creator,
				),
			}
		case ActionType.DELETE_CREATOR_ICON:
			return {
				...state,
				creator: state.creator.map((creator: CreatorInterface) => {
					if (creator.creator_name === action.payload.name) {
						return { ...creator, iconUrl: action.payload.iconUrl }
					}
					return creator
				}),
			}
		default:
			return state
	}
}

export default reducer
