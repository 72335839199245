import { ThemeProvider } from '@emotion/react'
import '@inovua/reactdatagrid-community/index.css'
import { MenuItem } from '@mui/material'
import { GridColDef } from '@mui/x-data-grid'
import { Dispatch, SetStateAction, useEffect, useState } from 'react'
import { useActions } from '../../hooks/useActions'
import { useTypedSelector } from '../../hooks/useTypedSelector'
import { getAvatars } from '../../state/action-creators'
import { getApplicableRules } from '../../utils/helpers/helperFuncs'
import { fetchData } from '../../utils/helpers/navigationHelper'
import { generateRowsWithIds, searchFor } from '../../utils/helpers/tableHelper'
import theme from '../../utils/theme2024'
import './users.css'
import {
	PageWrapper,
	TableWrapper,
	UserNameWithAvatar,
} from '../components/reusableComponents'
import UserPopUp from './UserPopUp'
import ResetPwdPopUp from './UserResetPasswordPopUp'
import { InfoField } from '../advertisers/AdvertiserPreviewPage'
import { TableComponent } from '../components/TableComponents'
import { ActionsButton, SimpleActionsButton } from '../components/Buttons'
import LockResetSharpIcon from '@mui/icons-material/LockResetSharp'
import { ReactComponent as EditIcon } from '../../assets/svg/edit-icon.svg'
import { ReactComponent as DeleteIcon } from '../../assets/svg/delete-icon.svg'

const UserPage = (props: {
	children?: any
	setLoading?: any
	errorMessage: null | string
	setErrorMessage: any
	isErrorPromptOpened: boolean
	setIsErrorPromptOpened: any
	PopUpVisible: boolean
	setPopUpVisible: any
	search: string
	setSearch: Dispatch<SetStateAction<string>>
	presetSearch: string
	setPresetSearch: Dispatch<SetStateAction<string>>
}) => {
	const rowCount = 9
	const { usersAction, deleteUser } = useActions()
	const [pageSize, setPageSize] = useState(15)
	const { users, login, avatars, settings } = useTypedSelector(
		(state: any) => state,
	)
	const { rules } = getApplicableRules(users, login, settings, 'users')
	// const [PopUpVisible, setPopUpVisible] = useState(false)
	const [update, setUpdate] = useState(false)
	const [resetPwdPopUpVisible, setResetPwdPopUpVisible] = useState(false)
	const [row, setRow] = useState()
	const [found, setFound] = useState<any[]>([])
	const [rowWidth, setRowWidth] = useState(
		Math.floor((window.innerWidth - theme.dimentions.drawerWidth) / rowCount),
	)
	const [sortModel, setSortModel] = useState([{ field: 'name', sort: 'asc' }])

	let ruleSet = getApplicableRules(users, login, settings, 'users')

	const actionOptions = (row: any) => {
		const options = [
			{
				label: '',
				icon: (
					<LockResetSharpIcon
						style={{ color: theme.colors.base.grey700, scale: '1' }}
						className={'action-button'}
					/>
				),
				onClick: () => openResetPassword(row),
				rule: ruleSet.post,
				key: 'reset_password',
			},
			{
				label: '',
				icon: (
					<EditIcon
						style={{ color: theme.colors.text.titles, scale: '6.4' }}
						className={'action-button'}
					/>
				),
				onClick: () => handleEdit(row),
				rule: ruleSet.post,
				key: 'edit',
			},
			{
				label: '',
				icon: (
					<DeleteIcon
						style={{ color: theme.colors.text.titles, scale: '6.4' }}
						className={'action-button'}
					/>
				),
				onClick: () => {
					handleDelete(row)
				},
				// 		{/* we use post here because we want the publishers who are allowed to add to be allowed to delete THEIR publishers. */}
				rule: ruleSet.post,
				key: 'delete',
			},
		]

		return options
	}

	const columns: GridColDef[] = [
		// {
		// 	field: 'department',
		// 	headerName: 'Division',
		// 	width: rowWidth / 1.2,
		// },
		{
			field: 'name',
			headerName: 'Name',
			width: rowWidth / 0.6,
			renderCell: ({ value }) => {
				return (
					<UserNameWithAvatar
						avatars={avatars.avatars}
						value={value}
					></UserNameWithAvatar>
				)
			},
		},
		{
			field: 'location',
			headerName: 'Location',
			width: rowWidth / 1.2,
			renderCell: ({ value }) => (
				<InfoField label={''} value={value ? value : 'IL'} isCountry />
			),
		},
		// {
		// 	field: 'blocked',
		// 	headerName: 'Blocked',
		// 	width: rowWidth * 1,
		// 	renderCell: (row: any) => {
		// 		return row.row.blocked ? (
		// 			<span style={{ color: theme.colors.red, fontWeight: 500 }}>
		// 				Blocked
		// 			</span>
		// 		) : (
		// 			<span>Not blocked</span>
		// 		)
		// 	},
		// },
		{
			field: 'position',
			headerName: 'Position',
			width: rowWidth * 2,
		},
		// {
		// 	field: 'team',
		// 	headerName: 'Department',
		// 	width: rowWidth * 1,
		// },
		// {
		// 	field: 'team_leader',
		// 	headerName: 'Team',
		// 	width: rowWidth / 1.5,
		// },
		{
			field: 'email',
			headerName: 'E-Mail',
			width: rowWidth * 1.5,
		},
		{
			field: 'user_type',
			headerName: 'Type',
			width: rowWidth * 0.8,
			renderCell: (row: any) => (
				<span>
					{row.row.user_type
						? row.row.user_type.toString().charAt(0).toUpperCase() +
						  row.row.user_type.toString().slice(1)
						: ''}
				</span>
			),
		},
		{
			field: 'role',
			headerName: 'Role',
			width: rowWidth,
			renderCell: (row: any) => (
				<span>
					{row.row.role
						? row.row.role.toString().charAt(0).toUpperCase() +
						  row.row.role.toString().slice(1)
						: ''}
				</span>
			),
		},
		ruleSet.post && {
			field: 'actions',
			headerName: 'Actions',
			resizable: false,
			sortable: false,
			renderCell: (row) => <ActionsButton options={actionOptions(row)} />,
		},
	]

	props.search.length > 0 &&
		columns.splice(6, 0, {
			field: 'blocked',
			headerName: 'Blocked',
			width: rowWidth * 0.6,
			renderCell: ({ value }) => (
				<span
					style={{
						color: value ? theme.colors.base.red300 : theme.colors.text.titles,
						fontWeight: value ? '600' : '400',
					}}
				>
					{value ? 'Yes' : 'No'}
				</span>
			),
		})
	const handleDelete = async (row: any) => {
		const payload = { email: row.row.email, user_login: login.user.email }
		const headers = {
			Authorization: `Token ${login.user.token}`,
		}
		props.setLoading(true)

		await deleteUser(headers, payload, props.setErrorMessage)

		await fetchData(login, usersAction, users.users)
		props.setLoading(false)
		setUpdate(!update)
	}

	const ActionOptions = (row: any) => {
		return (
			<div>
				{rules['changing users password'] && (
					<MenuItem onClick={() => openResetPassword(row)}>
						Reset Password
					</MenuItem>
				)}
				{ruleSet.post && (
					<>
						{<MenuItem onClick={() => handleEdit(row)}>Edit</MenuItem>}
						{<MenuItem onClick={() => handleDelete(row)}>Delete</MenuItem>}
					</>
				)}
			</div>
		)
	}

	const handleEdit = (row: any) => {
		setRow(row.row)
		props.setPopUpVisible(true)
	}
	const handleClose = async () => {
		props.setPopUpVisible(false)
		setResetPwdPopUpVisible(false)
		setRow(undefined)
		await fetchData(login, usersAction, users.users)
		setUpdate(!update)
	}

	const openResetPassword = (row: any) => {
		setRow(row.row)
		setResetPwdPopUpVisible(true)
	}

	useEffect(() => {
		if (props.search.length > 0) {
			setFound(searchFor(props.search, users.users, columns))
		}
	}, [props.search, users.users])
	const headers = {
		Authorization: `Token ${login.user.token}`,
	}
	useEffect(() => {
		props.setPopUpVisible(false)
		getAvatars(headers)
		if (props.search.length > 0) {
			setFound(searchFor(props.search, users.users, columns))
		}
		props.setLoading(false)
	}, [])

	return (
		<ThemeProvider theme={theme}>
			<PageWrapper>
				<UserPopUp
					PopUpVisible={props.PopUpVisible}
					setPopUpVisible={handleEdit}
					handleClose={handleClose}
					setLoading={props.setLoading}
					row={row}
					errorMessage={props.errorMessage}
					setErrorMessage={props.setErrorMessage}
					isErrorPromptOpened={props.isErrorPromptOpened}
					setIsErrorPromptOpened={props.setIsErrorPromptOpened}
				/>
				<ResetPwdPopUp
					PopUpVisible={resetPwdPopUpVisible}
					setPopUpVisible={openResetPassword}
					handleClose={handleClose}
					row={row}
					setLoading={props.setLoading}
					errorMessage={props.errorMessage}
					setErrorMessage={props.setErrorMessage}
					isErrorPromptOpened={props.isErrorPromptOpened}
					setIsErrorPromptOpened={props.setIsErrorPromptOpened}
				/>
				<TableWrapper
					theme={theme}
					rowCount={rowCount}
					setRowWidth={setRowWidth}
					style={{ paddingBottom: '60px', width: '90%' }}
				>
					<TableComponent
						columns={columns}
						rows={generateRowsWithIds(
							props.search.length > 0
								? found
								: users.users.filter((user: any) => !user.blocked),
						)}
						setPageSize={setPageSize}
						pageSize={pageSize}
						rowsPerPageOptions={[15, 25, 50]}
						setLoading={props.setLoading}
						handlePopUp={ruleSet.post ? handleEdit : undefined}
						sortModel={sortModel}
						onSortModelChange={(model: any) => setSortModel(model)}
						getRowClassName={(row: any) => {
							if (row.row.blocked) {
								return 'blocked_user'
							}
						}}
					/>
				</TableWrapper>
			</PageWrapper>
		</ThemeProvider>
	)
}

export default UserPage
