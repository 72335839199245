import { Grid } from '@mui/material'
import React, { Dispatch, SetStateAction } from 'react'
import { InputFieldText } from '../components/Inputs'
import {
	CountryListSelector,
	DropList,
	OwnerSelect,
} from '../components/SelectableInputs'
import { countryList } from '../components/countryList'
import { ChipInputList } from '../components/ChipInput'
import { useTypedSelector } from '../../hooks/useTypedSelector'
import { SwitchComponent } from '../components/ToggleComponents'
import {
	stringIsValidAddress,
	// stringIsValidCreatorId,
	stringIsValidEmail,
	stringIsValidGender,
	stringIsValidName,
	stringIsValidType,
} from '../../utils/helpers/helperFuncs'
import { checkInputStartsWithHttp } from '../../utils/helpers/tableHelper'

const CreatorInfoComponent = (props: {
	name: string
	setName: Dispatch<SetStateAction<string>>
	userName: string
	setUserName: Dispatch<SetStateAction<string>>
	campaign: string[]
	setCampaign: Dispatch<SetStateAction<string[]>>
	type: string
	setType: Dispatch<SetStateAction<string>>
	email: string
	setEmail: Dispatch<SetStateAction<string>>
	gender: string
	setGender: Dispatch<SetStateAction<string>>
	geo: string[]
	setGeo: Dispatch<SetStateAction<string[]>>
	instagram: string
	setInstagram: Dispatch<SetStateAction<string>>
	tiktok: string
	setTiktok: Dispatch<SetStateAction<string>>
	youtube: string
	setYoutube: Dispatch<SetStateAction<string>>
	linkedin: string
	setLinkedin: Dispatch<SetStateAction<string>>
	portfolio: string
	setPortfolio: Dispatch<SetStateAction<string>>
	fiverr: string
	setFiverr: Dispatch<SetStateAction<string>>
	linkToIO: string
	setLinkToIO: Dispatch<SetStateAction<string>>
	linkToDrafts: string
	setLinkToDrafts: Dispatch<SetStateAction<string>>
	ownerEmail: string | null
	setOwnerEmail: Dispatch<SetStateAction<string | null>>
	address: string
	setAddress: Dispatch<SetStateAction<string>>
	// setCreatorId: Dispatch<SetStateAction<string>>
	status: boolean
	setStatus: Dispatch<SetStateAction<boolean>>
	isValid: boolean
	hasAgent: boolean
	setHasAgent: Dispatch<SetStateAction<boolean>>
	goodCommunicationBadge: boolean
	setGoodCommunicationBadge: Dispatch<SetStateAction<boolean>>
	highQualityWorkBadge: boolean
	setHighQualityWorkBadge: Dispatch<SetStateAction<boolean>>
	mandatoryFields?: any
	fieldValidations?: any
}) => {
	const { campaign } = useTypedSelector((state) => state)

	const campaignList = campaign.campaign
		.filter((el: any) => el.role === 'M')
		.map((el: any) => el.campaign_name)
		.sort()

	return (
		<Grid
			container
			columnSpacing={6}
			rowSpacing={2}
			direction='row'
			style={{
				display: 'flex',
			}}
			columns={12}
		>
			<Grid item xs={3}>
				<InputFieldText
					label='Full Name'
					type='text'
					value={props.name}
					onChange={(e: any) => {
						props.setName(e)
					}}
					errormessage={
						props.name && !stringIsValidName(props.name as string)
							? "Creator name is mandatory. Creator name must not start or end with whitespace and must not contain '/', '%', ',' or '.'"
							: undefined
					}
					mandatory={!props.fieldValidations.name}
				/>
			</Grid>
			<Grid item xs={3}>
				<InputFieldText
					label='Username'
					type='text'
					value={props.userName}
					onChange={props.setUserName}
					errormessage={
						props.userName && !stringIsValidName(props.userName)
							? "Username is mandatory. Username must not start or end with whitespace and must not contain '/', '%', ',' or '.'"
							: undefined
					}
					mandatory={!props.fieldValidations.userName}
				/>
			</Grid>
			<Grid item xs={3}>
				<DropList
					onChange={props.setType}
					options={['IM', 'UGC']}
					label={'Type'}
					value={props.type}
					errormessage={
						props.type && !stringIsValidType(props.type)
							? 'Type is mandatory and must be either "IM" or "UGC".'
							: undefined
					}
					mandatory={!props.fieldValidations.type}
				/>
			</Grid>
			<Grid item xs={3}>
				<InputFieldText
					label='Email'
					type='text'
					value={props.email}
					onChange={props.setEmail}
					errormessage={
						props.email && !stringIsValidEmail(props.email)
							? 'Email is mandatory and must be a valid email address (e.g., user@example.com).'
							: undefined
					}
					mandatory={!props.fieldValidations.email}
				/>
			</Grid>
			<Grid item xs={3}>
				<DropList
					onChange={props.setGender}
					options={['Male', 'Female', 'Binary', 'None']}
					label={'Gender'}
					value={props.gender}
					errormessage={
						props.gender && !stringIsValidGender(props.gender)
							? 'Gender is mandatory and must be one of "Male", "Female", "Binary", or "None".'
							: undefined
					}
					mandatory={!props.fieldValidations.gender}
				/>
			</Grid>
			<Grid item xs={3}>
				<CountryListSelector
					onChange={props.setGeo}
					value={props.geo}
					label={'Country'}
					options={countryList}
					singular
				/>
			</Grid>
			<Grid item xs={3}>
				<InputFieldText
					label='Link to Instagram'
					type='text'
					value={props.instagram}
					onChange={props.setInstagram}
				/>
			</Grid>
			<Grid item xs={3}>
				<InputFieldText
					label='Link to Tik Tok'
					type='text'
					value={props.tiktok}
					onChange={props.setTiktok}
				/>
			</Grid>
			<Grid item xs={3}>
				<InputFieldText
					label='Link to Youtube'
					type='text'
					value={props.youtube}
					onChange={props.setYoutube}
				/>
			</Grid>
			<Grid item xs={3}>
				<InputFieldText
					label='Link to LinkedIn'
					type='text'
					value={props.linkedin}
					onChange={props.setLinkedin}
				/>
			</Grid>
			<Grid item xs={3}>
				<InputFieldText
					label='Link to Portfolio'
					type='text'
					value={props.portfolio}
					onChange={props.setPortfolio}
				/>
			</Grid>
			<Grid item xs={3}>
				<InputFieldText
					label='Link to Fiverr'
					type='text'
					value={props.fiverr}
					onChange={props.setFiverr}
				/>
			</Grid>
			<Grid item xs={3}>
				<InputFieldText
					label='Link to IO'
					type='text'
					value={props.linkToIO}
					onChange={props.setLinkToIO}
					errormessage={
						props.linkToIO && !checkInputStartsWithHttp(props.linkToIO)
							? 'Link to IO is mandatory. Links must start with "https://"'
							: undefined
					}
				/>
			</Grid>
			<Grid item xs={3}>
				<InputFieldText
					label='Link to Drafts'
					type='text'
					value={props.linkToDrafts}
					onChange={props.setLinkToDrafts}
				/>
			</Grid>
			<Grid item xs={3}>
				<OwnerSelect
					onChange={props.setOwnerEmail}
					label={'Owner'}
					value={props.ownerEmail}
				/>
			</Grid>
			<Grid item xs={3}>
				<InputFieldText
					label='Address'
					type='text'
					value={props.address}
					onChange={props.setAddress}
					errormessage={
						props.address && !stringIsValidAddress(props.address)
							? 'Address is mandatory.'
							: undefined
					}
					mandatory={!props.fieldValidations.address}
				/>
			</Grid>
			<Grid item xs={3}>
				<DropList
					onChange={(e: any) => props.setStatus(e === 'Active' ? true : false)}
					options={['Active', 'Disabled']}
					label={'Status'}
					value={props.status === true ? 'Active' : 'Disabled'}
				/>
			</Grid>
			<Grid item xs={3}>
				{/* <InputFieldText
					label='Creator ID'
					type='text'
					value={props.creatorId}
					onChange={props.setCreatorId}
					errormessage={
						props.creatorId && !stringIsValidCreatorId(props.creatorId)
							? 'Creator ID is mandatory.'
							: undefined
					}
				/> */}
			</Grid>
			<Grid item xs={6 / 3}>
				<SwitchComponent
					label='Agent'
					value={props.hasAgent}
					onChange={props.setHasAgent}
					disabled={false}
				/>
			</Grid>
			<Grid item xs={6 / 3}>
				<SwitchComponent
					label='Good Communication'
					value={props.goodCommunicationBadge}
					onChange={props.setGoodCommunicationBadge}
					disabled={false}
				/>
			</Grid>
			<Grid item xs={6 / 3}>
				<SwitchComponent
					label='High Quality Work'
					value={props.highQualityWorkBadge}
					onChange={props.setHighQualityWorkBadge}
					disabled={false}
				/>
			</Grid>
			<Grid item xs={12}>
				<ChipInputList
					value={props.campaign}
					label={'Campaigns'}
					onChange={props.setCampaign}
					options={campaignList}
					openWithClick
					mandatory={!props.fieldValidations.campaign}
				/>
			</Grid>
		</Grid>
	)
}

export default CreatorInfoComponent
