import { useEffect, useState } from 'react'
import { useTypedSelector } from '../../hooks/useTypedSelector'
import { UserInterface } from '../../models/model.interface'
import theme from '../../utils/theme2024'
import { Autocomplete, Chip, MenuItem, TextField, Paper } from '@mui/material'
import { ErrorTag, StyledLabel } from './Informative'
import ReactCountryFlag from 'react-country-flag'
import React from 'react'
import { Public } from '@mui/icons-material'
import {
	StyledAutocomplete,
	StyledInputBase,
	StyledTextInputField,
} from './Inputs'
import ClearIcon from '@mui/icons-material/Clear'
import { SubmitButton, UnborderedButton } from './Buttons'
import { ChipInputList } from './ChipInput'
import {
	DateRange,
	DateRangePicker,
	LocalizationProvider,
} from '@mui/x-date-pickers-pro'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import { UseSingleInputDateRangeFieldProps } from '@mui/x-date-pickers-pro/SingleInputDateRangeField'
import { MuiTextFieldProps } from '@mui/x-date-pickers/internals'

export const OwnerSelect = (props: {
	onChange: any
	disabled?: boolean
	float?: 'right' | 'left'
	label: string
	value: any
	fontSize?: string
	multiple?: boolean
}) => {
	const { users } = useTypedSelector((state) => state)
	const [focused, setFocused] = useState(false)
	const [hovered, setHovered] = useState(false)
	const usersList: JSX.Element[] = users.users.map(
		(user: UserInterface) => user.name,
	)
	const getNameFromUserEmail = (email: string) => {
		const user = users.users.filter((el: UserInterface) => el.email === email)
		if (user[0]) {
			return user[0].name
		}
	}
	const handleChange = (event: any, value: any) => {
		const user = users.users.filter((el: UserInterface) => el.name === value)

		if (user.length > 0) props.onChange(user[0].email)
	}
	const responsiveStyle = () => {
		const style: any = {
			border: '1px solid ' + theme.colors.base.grey300,
			borderRight: '6px solid ' + theme.colors.base.grey300,
		}
		if (focused) {
			style.borderRight = '6px solid ' + theme.colors.base.green200
		}
		if (!props.value && hovered) {
			style.borderRight = '6px solid ' + theme.colors.base.green200
		}
		if (props.value) {
			style.borderRight = '6px solid ' + theme.colors.base.green200
		}

		return style
	}

	return (
		<div style={{ float: props.float, marginTop: '0px' }}>
			<StyledLabel isFocused={focused}>{props.label}</StyledLabel>
			<Autocomplete
				style={{
					minHeight: '34px',
					width: '100%',
					background: 'transparent',
					fontWeight: theme.font.weight.normal,
					fontSize: theme.font.size.caption,
					borderRadius: theme.dimentions.smallerRadius,
					color: theme.colors.text.general,
					...responsiveStyle(),
				}}
				sx={{
					'& .MuiOutlinedInput-root': {
						border: 'none',
						padding: '0px 0px 0px 8px',
						minHeight: '34px',
						fontWeight: theme.font.weight.normal,
						fontSize: theme.font.size.caption,
					},
					'& .MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline': {
						border: 'none',
						padding: '0px',
						fontWeight: theme.font.weight.normal,
						fontSize: theme.font.size.caption,
					},
					'& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline': {
						border: 'none',
						padding: '0px',
						fontWeight: theme.font.weight.normal,
						fontSize: theme.font.size.caption,
					},
					'& .MuiOutlinedInput-root .MuiAutocomplete-input': {
						minHeight: '34px',
						padding: '0px',
						fontWeight: theme.font.weight.normal,
						fontSize: theme.font.size.caption,
					},
				}}
				options={usersList}
				renderInput={(params) => (
					<TextField
						{...params}
						style={{ height: 'inherit' }}
						placeholder={props.label}
					/>
				)}
				PaperComponent={({ children, ...paperProps }) => (
					<Paper
						{...paperProps}
						sx={{
							border: `1px solid ${theme.colors.base.grey300}`,
							padding: 1,
						}}
					>
						{children}
					</Paper>
				)}
				onChange={handleChange}
				value={getNameFromUserEmail(props.value)}
				// onFocus={() => {
				// 	setFocused(true)
				// }}
				// onBlur={() => {
				// 	setFocused(false)
				// }}
				// onMouseEnter={() => {
				// 	setHovered(true)
				// }}
				// onMouseLeave={() => {
				// 	setHovered(false)
				// }}
			/>
		</div>
	)
}

export const DropList = (props: {
	onChange: any
	disabled?: boolean
	float?: 'right' | 'left'
	options: string[]
	label: string
	value: any
	fontSize?: string
	multiple?: boolean
	noNull?: boolean
	noBackground?: boolean
	customColor?: string
	fontWeight?: string
	errored?: boolean
	style?: any
	autoCompleteStyle?: any
	errormessage?: any
	noClearOption?: boolean
	mandatory?: boolean
	placeholder?: string
}) => {
	const [focused, setFocused] = useState(false)
	const [firstFocus, setFirstFocus] = useState(false)
	const [hovered, setHovered] = useState(false)
	const [isFocused, setIsFocused] = useState(false)
	const [interacted, setInteracted] = useState(false)

	const responsiveStyle = () => {
		const style: any = {
			border: '1px solid ' + theme.colors.base.grey300,
			borderRight: '6px solid ' + theme.colors.base.grey300,
		}
		if (props.disabled) {
			style.background = theme.colors.base.grey50
			return style
		}
		if (focused) {
			style.borderRight = '6px solid ' + theme.colors.base.green200
		}
		if (!props.value && hovered) {
			style.borderRight = '6px solid ' + theme.colors.base.green200
		}
		if (props.value && !props.errormessage) {
			style.borderRight = '6px solid ' + theme.colors.base.green200
		}
		if (props.errormessage && firstFocus) {
			style.borderRight = '6px solid ' + theme.colors.base.red300
		}
		return style
	}

	return (
		<div
			style={{
				width: '100%',
				marginTop: '0px',
				position: 'relative',
				...props.style,
			}}
		>
			<StyledLabel
				disabled={props.disabled}
				isFocused={focused}
				hasError={props.errormessage ? true : false}
			>
				{props.label}
				{props.mandatory && !interacted && (
					<span
						style={{
							color: `${theme.colors.base.red300}`,
							fontSize: '1rem',
							fontWeight: 'bold',
						}}
					>
						*
					</span>
				)}
			</StyledLabel>
			{props.errormessage && firstFocus ? (
				<div
					style={{
						position: 'absolute',
						top: 29,
						right: 50,
						border: 'none',
						cursor: 'pointer',
						zIndex: '2',
					}}
				>
					<ErrorTag title={props.errormessage} />
				</div>
			) : null}
			<Autocomplete
				disabled={props.disabled}
				style={{
					marginTop: '0px',
					minHeight: '34px',
					width: '100%',
					background: 'transparent',
					fontWeight: theme.font.weight.normal,
					fontSize: theme.font.size.caption,
					borderRadius: theme.dimentions.smallerRadius,
					color: theme.colors.text.general,
					...responsiveStyle(),
					...props.autoCompleteStyle,
				}}
				sx={{
					'& .MuiAutocomplete-endAdornment [title="Clear"]': {
						opacity: props.errormessage ? 0 : 1,
						display: props.noClearOption ? 'none' : 'ms-flexbox',
					},
					'& .MuiOutlinedInput-root': {
						border: 'none',
						padding: '0px 0px 0px 8px',
						minHeight: '34px',
						fontWeight: theme.font.weight.normal,
						fontSize: theme.font.size.caption,
						paddingRight: props.noClearOption ? '12px !important' : '0px',
					},
					'& .MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline': {
						border: 'none',
						padding: '0px',
						fontWeight: theme.font.weight.normal,
						fontSize: theme.font.size.caption,
					},
					'& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline': {
						border: 'none',
						padding: '0px',
						fontWeight: theme.font.weight.normal,
						fontSize: theme.font.size.caption,
					},
					'& .MuiOutlinedInput-root .MuiAutocomplete-input': {
						minHeight: '34px',
						padding: '0px',
						fontWeight: theme.font.weight.normal,
						fontSize: theme.font.size.caption,
					},
				}}
				options={props.options}
				renderInput={(params) => (
					<TextField
						{...params}
						style={{
							height: 'inherit',
						}}
						InputProps={{
							...params.InputProps,
						}}
					/>
				)}
				PaperComponent={({ children, ...paperProps }) => {
					return (
						<Paper
							{...paperProps}
							sx={{
								border: `1px solid ${theme.colors.base.grey300}`,
								padding: 1,
								zIndex: '30',
							}}
						>
							{children}
						</Paper>
					)
				}}
				getOptionLabel={(option) => {
					return option?.toString() ?? ''
				}}
				onChange={(e: any, value: any) => {
					setInteracted(true)
					if (props.noNull && !value) {
						props.onChange('')
					} else {
						props.onChange(value)
					}
				}}
				value={props.value || []}
				isOptionEqualToValue={(option: any, value: any) => {
					if (typeof option === 'object') {
						return (
							value === '' ||
							option.email.toLowerCase() ===
								(value && typeof value === 'string' ? value.toLowerCase() : '')
						)
					} else {
						return (
							value === '' ||
							option.toLowerCase() ===
								(value && typeof value === 'string' ? value.toLowerCase() : '')
						)
					}
				}}
				onFocus={() => {
					setFirstFocus(true)
					setIsFocused(true)
				}}
			/>
		</div>
	)
}

export const CountryListSelector = (props: {
	onChange?: any
	label?: string
	options?: any[]
	disabled?: boolean
	value?: string[]
	fullWidth?: boolean
	multiple?: boolean
	singular?: boolean
	float?: 'left' | 'right'
	style?: React.CSSProperties
	onBlur?: any
	loginRole?: string
	chipStyle?: React.CSSProperties
}) => {
	const [open, setOpen] = useState(false)
	const [focused, setFocused] = useState(false)
	const [hovered, setHovered] = useState(false)
	const StyleForSelectedOption = (value: string, list: string[]) => {
		if (
			(list.length === 0 && value === 'GLOBAL') ||
			list.indexOf(value.substring(0, 2)) !== -1 ||
			list.indexOf(value.substring(0, 2).toLowerCase()) !== -1
		) {
			return {
				color: theme.colors.base.green100,
				fontSize: theme.font.size.body,
				width: '100%',
			}
		}
	}

	const responsiveStyle = () => {
		const style: any = {
			border: '1px solid ' + theme.colors.base.grey300,
			borderRight: '6px solid ' + theme.colors.base.grey300,
		}
		if (props.disabled) {
			style.background = theme.colors.base.grey50
			return style
		}
		if (focused) {
			style.borderRight = '6px solid ' + theme.colors.base.green200
		}
		if (!props.value && hovered) {
			style.borderRight = '6px solid ' + theme.colors.base.green200
		}
		if (props.value) {
			style.borderRight = '6px solid ' + theme.colors.base.green200
		}

		return style
	}

	const selectedOptions: string[] = []
	const notSelectedOptions: string[] = []
	props.options!.forEach((el) => {
		if (
			props.value?.includes(el.substring(0, 2)) ||
			props.value?.includes(el.substring(0, 2).toLowerCase())
		) {
			selectedOptions.push(el)
		} else {
			notSelectedOptions.push(el)
		}
	})
	const orderedOptions = [...selectedOptions, 'GLOBAL', ...notSelectedOptions]

	const renderValueIfEmpty = (values: string[]) => {
		if (values?.length === 0 || (values?.length === 1 && values[0] === '')) {
			return ['GLOBAL']
		}
		return values.map((el: string) => el.toUpperCase())
	}
	const optionsCovertor = (options: string) => {
		let option
		if (options === 'GLOBAL') option = options
		else option = options.length > 2 ? options.substring(0, 2) : options
		const checkedOptions = option === 'UK' ? 'GB' : option
		return checkedOptions
	}
	const renderOptionsWhenOpening = (propsd: any, options: any) => {
		const checkedOption = optionsCovertor(options)
		return checkedOption !== 'GLOBAL' ? (
			<li {...propsd} key={propsd.id}>
				<ReactCountryFlag
					style={{
						width: '2em',
						height: '2em',
						borderRadius: '30px',
						display: 'block',
					}}
					key={options + '_flags'}
					countryCode={checkedOption}
					svg
				/>
				<MenuItem
					key={options}
					value={options}
					style={{
						fontSize: theme.font.size.body,
						...StyleForSelectedOption(options, props.value || []),
					}}
				>
					{options}
				</MenuItem>
			</li>
		) : (
			<></>
		)
	}
	const renderSelectedOptions = (value: any[], getTagProps: any) => {
		const chips: any = value.map((options: any, index: number) => {
			const checkedOption = optionsCovertor(options)
			if (!open) {
				if (index <= 5) {
					return (
						<React.Fragment key={index + '-fragment-option'}>
							{index !== 5 ? (
								<Chip
									style={{
										maxHeight: '22px',
										...props.chipStyle,
									}}
									icon={
										checkedOption !== 'GLOBAL' ? (
											<ReactCountryFlag
												style={{
													width: '1rem',
													height: '1rem',
													borderRadius: '30px',
												}}
												countryCode={checkedOption}
												svg
											/>
										) : (
											<Public style={{ scale: '0.7' }} />
										)
									}
									variant='outlined'
									label={options}
									deleteIcon={
										// checkedOption !== 'GLOBAL' ? <ClearIcon /> : <></>
										<ClearIcon style={{ scale: '0.7' }} />
									}
									sx={{
										'& .MuiChip-label': {
											fontSize: theme.font.size.caption,
										},
									}}
									{...getTagProps({ index })}
								/>
							) : (
								<Chip
									style={{ marginLeft: '18px' }}
									onClick={() => {
										setOpen(!open)
									}}
									variant='outlined'
									label={` + ${(value?.length || 5) - 5}`}
								/>
							)}
						</React.Fragment>
					)
				}
			} else {
				return (
					<Chip
						icon={
							checkedOption !== 'GLOBAL' ? (
								<ReactCountryFlag
									style={{
										width: '1rem',
										height: '1rem',
										borderRadius: '30px',
									}}
									countryCode={checkedOption}
									svg
								/>
							) : (
								<Public />
							)
						}
						style={{ maxHeight: '22px' }}
						variant='outlined'
						label={options}
						// deleteIcon={checkedOption !== 'GLOBAL' ? <ClearIcon /> : <></>}
						deleteIcon={<ClearIcon />}
						sx={{
							'& .MuiChip-label': {
								fontSize: theme.font.size.caption,
							},
						}}
						{...getTagProps({ index })}
					/>
				)
			}
		})
		return chips
	}
	const renderInputField = (params: any) => (
		<>
			<StyledLabel>{props.label}</StyledLabel>
			<StyledTextInputField
				{...params}
				focused
				label={undefined}
				margin='dense'
				fullWidth
				key={params + 'key-input'}
				variant='outlined'
				style={{
					float: props.float,
					fontSize: theme.font.size.caption,
					...responsiveStyle(),
				}}
				InputProps={{
					...params.InputProps,
					style: {
						paddingLeft: '10px',
					},
				}}
				onClick={() => !props.disabled && setOpen(!open)}
			/>
		</>
	)
	const customFilter = (options: any, state: any) => {
		const geoCodeResult =
			state.inputValue.length <= 2
				? options.filter((el: any) => {
						return el
							.substring(0, 2)
							.toLowerCase()
							.includes(state.inputValue.toLowerCase())
				  })
				: []
		const countryResult = options.filter((el: any) => {
			return (
				el.toLowerCase().includes(state.inputValue.toLowerCase()) &&
				el.substring(0, 2).toLowerCase() !== state.inputValue.toLowerCase()
			)
		})
		return [...geoCodeResult, ...countryResult]
	}

	return (
		<StyledAutocomplete
			multiple={props.multiple ? false : true}
			freeSolo={!props.singular}
			disabled={props.disabled}
			open={open}
			options={orderedOptions || []}
			style={props.style}
			id='tags-filled'
			value={renderValueIfEmpty(props.value as string[])}
			onChange={(a: any, n: any) => {
				if (n[0] === 'GLOBAL') n.shift()
				if (n.includes('GLOBAL')) props.onChange([])
				else {
					let result: string[]
					if (props.singular && n.at(-1)) {
						result = [n.at(-1).substring(0, 2)]
					} else {
						result = n.map((el: string) => {
							return el.substring(0, 2) === 'GB' ? 'UK' : el.substring(0, 2)
						})
					}
					props.onChange(result)
				}
			}}
			PaperComponent={({ children, ...paperProps }) => (
				<Paper
					{...paperProps}
					sx={{
						border: `1px solid ${theme.colors.base.grey300}`,
						padding: 1,
					}}
				>
					{children}
				</Paper>
			)}
			isOptionEqualToValue={(option: any, value: any) => {
				if (value === 'GLOBAL') return option === value
				return option.substring(0, 2) === value.substring(0, 2)
			}}
			onBlur={() => {
				setOpen(false)
				setFocused(false)
				return props.onBlur
			}}
			sx={{
				'& .MuiOutlinedInput-root': {
					border: 'none',
					padding: '0px 0px 0px 8px',
					minHeight: '34px',
					fontWeight: theme.font.weight.normal,
					fontSize: theme.font.size.caption,
				},
				'& .MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline': {
					border: 'none',
					padding: '0px',
					fontWeight: theme.font.weight.normal,
					fontSize: theme.font.size.caption,
				},
				'& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline': {
					border: 'none',
					padding: '0px',
					fontWeight: theme.font.weight.normal,
					fontSize: theme.font.size.caption,
				},
				'& .MuiOutlinedInput-root .MuiAutocomplete-input': {
					minHeight: '34px',
					padding: '0px',
					fontWeight: theme.font.weight.normal,
					fontSize: theme.font.size.caption,
				},
			}}
			filterOptions={customFilter}
			renderOption={renderOptionsWhenOpening}
			renderTags={renderSelectedOptions}
			renderInput={renderInputField}
			// onFocus={() => {
			// 	setFocused(true)
			// }}
			// onMouseEnter={() => {
			// 	setHovered(true)
			// }}
			// onMouseLeave={() => {
			// 	setHovered(false)
			// }}
		/>
	)
}

export const FilterSearchComponent = (props: {
	setSearch: any
	search: string[]
	style?: any
	applyFilter?: any
	filterLabel?: any
	currentFilters?: any
	clearFilter?: any
	optionsFilters?: any
	setFilterHander?: any
	filtersKey?: any
	customFilter?: any
}) => {
	let options: any = []
	for (const key in props.optionsFilters) {
		if (key === props.currentFilters) {
			if (typeof [...props.optionsFilters[key]][0] === 'string') {
				options = [...props.optionsFilters[key]].sort()
			} else if (
				typeof [...props.optionsFilters[key]][0] === 'object' &&
				!Array.isArray([...props.optionsFilters[key]][0])
			) {
				for (const element of props.optionsFilters[key]) {
					if (key === 'email' || key === 'publisher_manager') {
						options.push(element.name)
					} else {
						options.push(element[key])
					}
				}
				options.sort()
			} else if (Array.isArray([...props.optionsFilters[key]][0])) {
				for (const element of props.optionsFilters[key]) {
					for (const option of element) {
						if (!options.includes(option)) {
							options.push(option)
						}
					}
				}
				options = options.sort().filter((el: string) => el !== '')
			}
			// if (options)
			break
		}
	}

	useEffect(() => {
		if (props.customFilter) {
			props.setSearch(props.customFilter[props.filtersKey])
		}
	}, [props.currentFilters])
	useEffect(() => {
		if (props.customFilter) {
			props.setSearch(props.customFilter[props.filtersKey])
		} else {
			props.setSearch([])
		}
	}, [])

	return (
		<>
			<div
				style={{
					display: 'flex',
					alignItems: 'center',
					justifyContent: 'center',
					height: 'inherit',
					width: '100%',
				}}
			>
				{options.length === 0 ? (
					<StyledInputBase
						placeholder={'Filter for ' + props.filterLabel}
						inputProps={{
							'aria-label': 'search',
						}}
						autoFocus
						onChange={(e: any) => {
							props.setSearch(e.target.value)
						}}
						value={props.search}
						onKeyDown={(e: any) => {
							if (e.key === 'Enter') {
								props.applyFilter()
								props.setFilterHander(false)
							}
						}}
					/>
				) : (
					<ChipInputList
						onChange={props.setSearch}
						options={options}
						value={props.search}
						openWithClick={true}
						style={
							{
								height: 'inherit',
								display: 'flex',
								alignItems: 'center',
								justifyContent: 'center',
								margin: '8px',
								width: 'inherit',
								// marginTop: '-25px',
							} as any
						}
						onlyOptionsAllowed
						noColoredBorder
					/>
				)}
			</div>
			<SubmitButton
				style={{
					fontSize: theme.font.size.caption,
					width: 60,
					height: 30,
					display: 'flex',
					justifyContent: 'center',
					alignItems: 'center',
					borderRadius: '10px',
					padding: '10px 34px',
				}}
				onClick={() => props.applyFilter()}
			>
				Apply
			</SubmitButton>
			<UnborderedButton
				style={{
					fontSize: '12px',
					border: 'none',
					boxShadow: 'none',
					textDecoration: 'solid underline 2px',
					textUnderlineOffset: '6px',
					marginLeft: '4px',
					paddingLeft: '0px',
					cursor: 'pointer',
				}}
				onClick={props.clearFilter}
				disabled={props.search.length === 0}
			>
				Clear
			</UnborderedButton>
		</>
	)
}
export const DateFilterSearchComponent = (props: {
	setSearch: any
	search: string[]
	style?: any
	applyFilter?: any
	filterLabel?: any
	currentFilters?: any
	clearFilter?: any
	setFilterHander?: any
	filtersKey?: any
	customFilter?: any
}) => {
	const [value, setValue] = useState<any>(props.search ? props.search : [])

	// console.log(props.customFilter, props.filtersKey, props.search)
	useEffect(() => {
		if (props.customFilter) {
			props.setSearch(props.customFilter[props.filtersKey])
		}
	}, [props.currentFilters])
	useEffect(() => {
		if (props.customFilter) {
			props.setSearch(props.customFilter[props.filtersKey])
		} else {
			props.setSearch([])
		}
	}, [])
	useEffect(() => {
		if (value[0] && value[1]) {
			props.setSearch(value)
		}
	}, [value])

	return (
		<>
			<div
				style={{
					display: 'flex',
					alignItems: 'center',
					justifyContent: 'center',
					height: 'inherit',
					width: '100%',
				}}
			>
				<LocalizationProvider dateAdapter={AdapterDayjs}>
					<DateRangePicker
						value={value}
						onChange={(e) => {
							setValue(e)
						}}
						renderInput={(startProps, endProps) => {
							const { value: startValue, ...restStartInputProps } =
								startProps.inputProps || {}
							const endValue = endProps?.inputProps?.value

							return (
								<StyledTextInputField
									{...startProps}
									label={null}
									inputProps={restStartInputProps}
									value={`${startValue || 'From'} - ${endValue || 'To'}`}
									onKeyDown={(e: any) => {
										if (e.key === 'Enter') {
											props.applyFilter()
											props.setFilterHander(false)
										}
									}}
								/>
							)
						}}
					/>
				</LocalizationProvider>
			</div>
			<SubmitButton
				style={{
					fontSize: theme.font.size.caption,
					width: 60,
					height: 30,
					display: 'flex',
					justifyContent: 'center',
					alignItems: 'center',
					borderRadius: '10px',
					padding: '10px 34px',
				}}
				onClick={() => props.applyFilter()}
			>
				Apply
			</SubmitButton>
			<UnborderedButton
				style={{
					fontSize: '12px',
					border: 'none',
					boxShadow: 'none',
					textDecoration: 'solid underline 2px',
					textUnderlineOffset: '6px',
					marginLeft: '4px',
					paddingLeft: '0px',
					cursor: 'pointer',
				}}
				onClick={() => {
					setValue([])
					props.clearFilter()
				}}
				disabled={props.search && props.search.length === 0}
			>
				Clear
			</UnborderedButton>
		</>
	)
}
