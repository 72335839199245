import React, { useEffect, useState } from 'react'
import { TableWrapper } from '../components/reusableComponents'
import theme from '../../utils/theme2024'
import { formatDateToMonth, sortDates } from '../../utils/helpers/helperFuncs'
import { Typography } from '@mui/material'
import { GridColDef } from '@mui/x-data-grid-pro'
import { generateRowsWithIds } from '../../utils/helpers/tableHelper'
import './finance.css'
import { useTypedSelector } from '../../hooks/useTypedSelector'
import { TableComponent } from '../components/TableComponents'

//this is the page that renders when the user clicks on preview in main supply and clicks on status.
//It just shows a table with generic information.
//All the rows are grouped by platform.
const SupplyStatus = (props: {
	setLoading: any
	setErrorMessage: any
	headers: any
	publisher: any
	month: any
	paramsUuid: any
	data: any
}) => {
	const rowCount = 4
	const [rowWidth, setRowWidth] = useState(
		Math.floor(
			(window.innerWidth - theme.dimentions.drawerWidth) / 2 / rowCount,
		),
	)
	const { finance } = useTypedSelector((state) => state)

	const [sortModel, setSortModel] = useState([
		{ field: 'campaign', sort: 'asc' },
	])
	const filteredRows = props.data.filter(
		(el: any) => el.publisher === props.publisher && el.month === props.month,
	)
	const [tableRows, setTableRows] = useState<any>(
		Array.isArray(props.data) ? generateRowsWithIds(props.data) : [],
	)
	const [pinnedBottomRow, setPinnedBottomRow] = useState<any>([])

	//here we trigger the generation of the table data.
	useEffect(() => {
		if (props.data.length > 0) {
			const foundRow = props.data.filter((el: any) => {
				return el.uuid === props.paramsUuid
			})
			const publisher = foundRow[0].publisher
			let month = foundRow[0].month
			if (foundRow[0].roll_over && foundRow[0].roll_over_month) {
				month = foundRow[0].roll_over_month
			}
			const filteredRows = props.data.filter(
				(el: any) =>
					el.publisher === publisher &&
					(el.month === month || el.roll_over_month === month),
			)
			const arr = generateStatusTable(filteredRows, props.month)
			const finalArr = generateRowsWithIds(arr)
			setTableRows(finalArr)
			setPinnedBottomRow(calculatePinnedTotalRow(finalArr))
		}
	}, [])
	useEffect(() => {
		if (props.data.length > 0) {
			const foundRow = props.data.filter((el: any) => {
				return el.uuid === props.paramsUuid && el.uuid
			})
			const publisher = foundRow[0].publisher
			let month = foundRow[0].month
			if (foundRow[0].roll_over && foundRow[0].roll_over_month) {
				month = foundRow[0].roll_over_month
			}
			const filteredRows = props.data.filter(
				(el: any) =>
					el.publisher === publisher &&
					(el.month === month || el.roll_over_month === month),
			)
			const arr = generateStatusTable(filteredRows, props.month)
			const finalArr = generateRowsWithIds(arr)
			setTableRows(finalArr)
			setPinnedBottomRow(calculatePinnedTotalRow(finalArr))
		}
	}, [props.data])

	const [pageSize, setPageSize] = useState(15)

	const columns: GridColDef[] = [
		{
			field: 'platform',
			headerName: 'Platform',
			width: rowWidth * 0.8,
			editable: false,
		},
		{
			field: 'month',
			headerName: 'Period',
			headerAlign: 'center',
			align: 'center',
			width: rowWidth,
			renderCell: (row) => {
				if (row.id === 9999999999) {
					return <span></span>
				}
				const value = row.value
				const date = formatDateToMonth(value)
				return <Typography sx={{ fontSize: 14 }}>{date}</Typography>
			},
			sortComparator: (str1, str2) => {
				return sortDates(str1, str2)
			},
		},
		// {
		// 	field: 'not_approved',
		// 	headerName: 'Not Aprroved',
		// 	width: rowWidth,
		// 	type: 'number',
		// 	align: 'left',
		// 	headerAlign: 'left',
		// 	editable: false,
		// },
		{
			field: 'on_hold',
			headerName: 'On Hold',
			width: rowWidth * 0.9,
			type: 'number',
			align: 'left',
			headerAlign: 'left',
			editable: false,
		},
		{
			field: 'total_approved',
			headerName: 'Approved',
			headerAlign: 'center',
			width: rowWidth,
			type: 'number',
			align: 'center',
			headerClassName: 'finance-final-header',
			cellClassName: 'finance-final-cell',
			editable: false,
		},
	]
	const handleRowStyle = (params: any) => {
		const row = params.row
		//9999999999 this id is the hardcoded id we use for the bottom pinned row.
		if (row.id === 9999999999) {
			return 'pinned-row'
		}
		return ''
	}

	return (
		<TableWrapper
			theme={theme}
			rowCount={rowCount}
			setRowWidth={setRowWidth}
			style={{
				marginTop: 30,
				paddingBottom: '60px',
				width: '90%',
			}}
		>
			<TableComponent
				style={{
					width: '50%',
					height: '50%',
					marginLeft: '20%',
					// marginTop: '1%',
					border: '1px solid ' + theme.colors.base.grey300,
					borderTop: 'none',
				}}
				columns={columns}
				rows={tableRows}
				setPageSize={setPageSize}
				pageSize={pageSize}
				rowsPerPageOptions={[15, 25, 50]}
				setLoading={props.setLoading}
				rowHeight={'auto'}
				sortModel={sortModel}
				onSortModelChange={(model: any) => setSortModel(model)}
				pinnedBottomRow={pinnedBottomRow}
				getRowClassName={handleRowStyle}
				noToolbar={true}
			></TableComponent>
		</TableWrapper>
	)
}
//this is the function we use to generate the status table. It's similar to the one we use for the status page but in this one we
//group everything based on the type.
//table structure:
// not approved = total number status no
// on hold = total number status on hold
// total approved = total number status yes
export const generateStatusTable = (rows: any, period: string) => {
	const newTableRows: any = []
	const alreadyAdded: string[] = []
	for (const row of rows) {
		let newRow: any = {}
		if (row.pid === '_nontrackedconv') {
			continue
		}
		if (!alreadyAdded.includes(row.platform + row.month.toString())) {
			newRow.platform = row.platform
			newRow.month = row.month
			newRow.last_send = row.last_send
			newRow.not_approved = 0
			newRow.on_hold = 0
			newRow.total_approved = 0
			if (
				row.status.toLowerCase() === 'yes' &&
				(!row.roll_over || row.roll_over_month === period)
			) {
				newRow.total_approved = Number(row.final_amount)
			} else if (row.status.toLowerCase() === 'no') {
				newRow.not_approved = Number(row.final_amount)
			} else if (
				row.status.toLowerCase() === 'on hold' &&
				(!row.roll_over || row.roll_over_month === period)
			) {
				newRow.on_hold = Number(row.final_amount)
			}
			alreadyAdded.push(row.platform + row.month.toString())
			newTableRows.push(newRow)
		} else {
			const rowToEdit = newTableRows.findIndex(
				(el: any) => el.platform === row.platform && el.month === row.month,
			)
			if (
				row.status.toLowerCase() === 'yes' &&
				(!row.roll_over || row.roll_over_month === period)
			) {
				newTableRows[rowToEdit].total_approved =
					Number(newTableRows[rowToEdit].total_approved) +
					Number(row.final_amount)
			} else if (row.status.toLowerCase() === 'no') {
				newTableRows[rowToEdit].not_approved =
					Number(newTableRows[rowToEdit].not_approved) +
					Number(row.final_amount)
			} else if (
				row.status.toLowerCase() === 'on hold' &&
				(!row.roll_over || row.roll_over_month === period)
			) {
				newTableRows[rowToEdit].on_hold =
					Number(newTableRows[rowToEdit].on_hold) + Number(row.final_amount)
			}
		}
	}
	return newTableRows
}
const calculatePinnedTotalRow = (rows: any) => {
	const pinnedRow = {
		platform: 'TOTAL',
		month: '',
		on_hold: 0,
		total_approved: 0,
		id: 9999999999,
	}
	for (const row of rows) {
		if (row.on_hold) {
			pinnedRow.on_hold = Number(pinnedRow.on_hold) + Number(row.on_hold)
		}
		pinnedRow.month = row.month
		if (row.total_approved) {
			pinnedRow.total_approved =
				Number(pinnedRow.total_approved) + Number(row.total_approved)
		}
	}
	pinnedRow.on_hold = Number(pinnedRow.on_hold).toFixed(2) as any
	pinnedRow.total_approved = Number(pinnedRow.total_approved).toFixed(2) as any
	return [pinnedRow]
}

export default SupplyStatus
