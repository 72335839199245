import { ThemeProvider } from '@emotion/react'
import { Box, Grid, Paper } from '@mui/material'
import { useEffect, useState } from 'react'
import { useActions } from '../../hooks/useActions'
import { useTypedSelector } from '../../hooks/useTypedSelector'

import { getApplicableRules } from '../../utils/helpers/helperFuncs'
import { WarningPrompt } from '../../utils/notifications/WarningPrompt'
import theme from '../../utils/theme2024'
import { PageWrapper } from '../components/reusableComponents'
import { PermissionsPage, permLookup } from '../permissions/permissionsPage'
import { PermissionsGroupList } from './permissionsGroupComponent'
import { SubmitButton } from '../components/Buttons'
import { TitleComponent } from '../components/Informative'
import { ChipInputList } from '../components/ChipInput'
const SettingsPage = (props: {
	setLoading: any
	errorMessage: null | string
	setErrorMessage: any
	isErrorPromptOpened: boolean
	setIsErrorPromptOpened: any
	setIsWarningPromptOpened: any
	setIsSuccessPromptOpened: any
}) => {
	const { settings, login, users, avatars } = useTypedSelector((state) => state)
	const { getSettingsAction, updateSettings, putAvatar } = useActions()
	const [allowedPlatforms, setAllowedPlatforms] = useState([])
	const [vertical, setVertical] = useState([])
	const [creatorVertical, setCreatorVertical] = useState([])
	const [capabilities, setCapabilities] = useState([])
	const [salesRepresentative, setSalesRepresentative] = useState([])
	const headers = {
		Authorization: `Token ${login.user.token}`,
	}
	const [teams, setTeams] = useState([])
	const [teamLeaders, setTeamLeaders] = useState([])
	const [fraudTools, setFraudTools] = useState([])
	const [contactRole, setContactRole] = useState([])
	const [platforms, setPlatforms] = useState([])
	const [banners, setBanners] = useState([])
	const [logos, setLogos] = useState([])
	const [videos, setVideos] = useState([])
	const [trafficRestrictions, setTrafficRestrictions] = useState([])
	const [existingChannels, setExistingChannels] = useState([])
	const [targetAudience, setTargetAudience] = useState([])
	const [targetAudienceOthers, setTargetAudienceOthers] = useState([])
	const [campaignGoals, setCampaignGoals] = useState([])
	const [paymentTerms, setPaymentTerms] = useState([])
	const [pauseReason, setPauseReason] = useState([])
	const [communicationChannel, setCommunicationChannel] = useState([])
	const [currency, setCurrency] = useState([])
	const [paymentMethod, setPaymentMethod] = useState([])
	const [branches, setBranches] = useState([])
	const [vatValues, setVatValues] = useState([])
	const [isWarningPromptOpened, setIsWarningPromptOpened] = useState(false)
	const [fileOpenLink, setFileOpenLink] = useState('')
	const [file, setFile] = useState<any>(null)
	const [permGroup, setPermGroup] = useState<any>([])
	const [openPermissionGroupsSettings, setOpenPermissionGroupsSettings] =
		useState(false)
	const [selectedName, setSelectedName] = useState('')
	const [userPositions, setUserPositions] = useState([])
	const [userTypes, setUserTypes] = useState<string[]>([])
	const [selectedType, setSelectedType] = useState('')
	const [selectedGroup, setSelectedGroup] = useState([])

	const ruleSet = getApplicableRules(users, login, settings, 'settings')

	const usersList = users.users.map((el: any) => el.name).sort()
	useEffect(() => {
		loadCurrentSettings()
		props.setLoading(false)
	}, [])

	useEffect(() => {
		if (settings.settings.allowedPlatforms) {
			setAllowedPlatforms(settings.settings.allowedPlatforms)
			setVertical(settings.settings.vertical)
			setCreatorVertical(settings.settings.creatorVertical || [])
			setCapabilities(settings.settings.capabilities)
			setSalesRepresentative(settings.settings.salesRepresentative)
			setFraudTools(settings.settings.fraudTools || [])
			setPlatforms(settings.settings.platforms)
			setContactRole(settings.settings.contactRole)
			setBanners(settings.settings.banners || [])
			setLogos(settings.settings.logos || [])
			setVideos(settings.settings.videos || [])
			setPaymentTerms(settings.settings.paymentTerms || [])
			setPauseReason(settings.settings.pauseReason || [])
			setCommunicationChannel(settings.settings.communicationChannel || [])
			setCurrency(settings.settings.currency || [])
			setTrafficRestrictions(settings.settings.trafficRestrictions || [])
			setExistingChannels(settings.settings.existingChannels || [])
			setTargetAudience(settings.settings.targetAudience || [])
			setTargetAudienceOthers(settings.settings.targetAudienceOthers || [])
			setCampaignGoals(settings.settings.campaignGoals || [])
			setTeams(settings.settings.teams || [])
			setBranches(settings.settings.branches || [])
			setPaymentMethod(settings.settings.paymentMethod || [])
			setVatValues(settings.settings.vatValues || [])
			setPermGroup(settings.settings.permissionGroups || [])
			setUserPositions(settings.settings.userPositions || [])
			setUserTypes(settings.settings.userTypes || [])
		}
	}, [settings])

	const loadCurrentSettings = async () => {
		const headers = {
			Authorization: `Token ${login.user.token}`,
		}
		getSettingsAction(headers)
	}

	const checkValidInput: any = (arr: string[]) => {
		const nonAcceptedValues = '!@#$%^*(){}<>.;:~/?'
		const nonAcceptedArr = nonAcceptedValues.split('')
		if (arr.length === 0) {
			return true
		}
		const result = !arr.every((el) => {
			for (const char of nonAcceptedArr) {
				el.includes(char)
			}
		})
		return result
	}

	const handleSubmit = async () => {
		if (
			checkValidInput(allowedPlatforms) &&
			checkValidInput(vertical) &&
			checkValidInput(creatorVertical) &&
			checkValidInput(capabilities) &&
			checkValidInput(salesRepresentative) &&
			checkValidInput(fraudTools) &&
			checkValidInput(platforms) &&
			checkValidInput(contactRole) &&
			checkValidInput(banners) &&
			checkValidInput(logos) &&
			checkValidInput(videos) &&
			checkValidInput(communicationChannel) &&
			checkValidInput(existingChannels) &&
			checkValidInput(targetAudience) &&
			checkValidInput(targetAudienceOthers) &&
			checkValidInput(campaignGoals) &&
			checkValidInput(trafficRestrictions) &&
			checkValidInput(pauseReason) &&
			checkValidInput(paymentTerms) &&
			checkValidInput(trafficRestrictions) &&
			checkValidInput(existingChannels) &&
			checkValidInput(teams) &&
			checkValidInput(currency) &&
			checkValidInput(paymentMethod) &&
			checkValidInput(branches) &&
			checkValidInput(vatValues)
		) {
			const updatedSettings = {
				allowedPlatforms,
				vertical,
				creatorVertical,
				capabilities,
				salesRepresentative,
				fraudTools,
				platforms,
				contactRole,
				banners,
				logos,
				videos,
				paymentTerms,
				pauseReason,
				communicationChannel,
				trafficRestrictions,
				existingChannels,
				targetAudience,
				targetAudienceOthers,
				campaignGoals,
				teams,
				currency,
				branches,
				paymentMethod,
				vatValues,
				permissionGroups: permGroup,
				// just for now beacuse im gettings bugs about this
				teamLeaders,
				userPositions,
				userTypes,
			}
			const headers = {
				Authorization: `Token ${login.user.token}`,
			}
			await updateSettings(
				headers,
				updatedSettings,
				props.setErrorMessage,
				props.setIsSuccessPromptOpened,
			)
		} else {
			console.error('check inputs')
		}
	}
	const updateTypeRoles = (type: string, rolesArr: any) => {
		let currUserType = login.user.user_type.toLowerCase()
		let currUserRole = login.user.role.toLowerCase()
		let permissionRules = JSON.parse(JSON.stringify(permGroup))
		rolesArr = rolesArr.map((role: string) => role.trim())
		rolesArr = rolesArr.filter((role: string) => role)
		type = type.toLowerCase()
		const currRoles = Object.keys(permissionRules[type])

		if (currRoles.length < rolesArr.length) {
			for (let i = 0; i < rolesArr.length; i++) {
				if (!permissionRules[type].hasOwnProperty(rolesArr[i])) {
					permissionRules[type][rolesArr[i]] = permLookup
				}
			}
		} else {
			for (let i = 0; i < currRoles.length; i++) {
				if (!rolesArr.includes(currRoles[i])) {
					// uncomment this if you want to forbid the removal of the current user role

					// if (
					// 	currRoles[i].toLowerCase() === currUserRole &&
					// 	type === currUserType
					// ) {
					// 	props.setErrorMessage(
					// 		'Error: You cannot remove your current user role',
					// 	)
					// 	return
					// }
					delete permissionRules[type][currRoles[i]]
				}
			}
		}
		setPermGroup(permissionRules)
	}

	const updateTypeData = (type: string, data: any) => {
		let permissionRules = JSON.parse(JSON.stringify(permGroup))
		permissionRules[type] = data
		setPermGroup(permissionRules)
	}

	const updateTypeInPermissionGroups = (
		userTypesArr: string[],
		userTypes: string[],
	) => {
		const currUserType = login.user.user_type.toLowerCase()
		userTypesArr = userTypesArr.map((type: string) => type.trim())
		userTypesArr = userTypesArr.filter((type: string) => type)

		if (!userTypes.includes(currUserType)) {
			userTypesArr.push(currUserType)
		}
		let permissionRules = JSON.parse(JSON.stringify(permGroup))

		userTypesArr = userTypesArr.map((t: string) => t.toLowerCase())

		const currTypes = Object.keys(permissionRules)
		setUserTypes(userTypesArr)
		if (currTypes.length < userTypesArr.length) {
			for (let i = 0; i < userTypesArr.length; i++) {
				const newType = userTypesArr[i].toLowerCase()

				if (!permGroup.hasOwnProperty(newType)) {
					permissionRules[newType] = {}
				}
			}
		} else {
			for (let i = 0; i < currTypes.length; i++) {
				if (!userTypesArr.includes(currTypes[i].toLowerCase())) {
					if (currUserType === currTypes[i].toLowerCase()) {
						props.setErrorMessage(
							'Error: You cannot remove your current user type',
						)
						setUserTypes([...userTypesArr, currUserType])
						return
					}
					delete permissionRules[currTypes[i].toLowerCase()]
				}
			}
		}
		setPermGroup(permissionRules)
	}
	// console.log(userTypes)
	return (
		<ThemeProvider theme={theme}>
			<TitleComponent
				component='span'
				style={{
					display: 'flex',
					margin: 30,
					marginLeft: 70,
					color: theme.colors.text.titles,
				}}
			>
				Settings
			</TitleComponent>

			<Box
				sx={{
					width: '90%',
					display: 'flex',
					flexDirection: 'column',
					alignItems: 'center',
					justifyContent: 'space-between',
					height: '90%',
					paddingBottom: '5%',
					gap: '18px',
				}}
			>
				<Box
					sx={{
						width: '90%',
						display: 'flex',
						flexDirection: 'column',
						alignItems: 'center',
						justifyContent: 'space-between',
						height: '90%',
						paddingBottom: '5%',
						gap: '18px',
					}}
				>
					<ChipInputList
						disabled={!ruleSet.post}
						onChange={(e: any) => setPlatforms(e)}
						label={'Active platforms'}
						options={platforms}
						value={platforms}
						style={{ width: '100%' }}
					/>
					<ChipInputList
						disabled={!ruleSet.post}
						onChange={(e: any) => setAllowedPlatforms(e)}
						label={'Allowed Platforms for Advertisers'}
						options={allowedPlatforms}
						value={allowedPlatforms}
						style={{ width: '100%' }}
					/>
					<ChipInputList
						disabled={!ruleSet.post}
						onChange={(e: any) => setVertical(e)}
						label={'Verticals'}
						options={vertical}
						value={vertical}
						style={{ width: '100%' }}
					/>
					<ChipInputList
						disabled={!ruleSet.post}
						onChange={(e: any) => setSalesRepresentative(e)}
						label={'Sales Representative'}
						options={usersList}
						value={salesRepresentative}
						style={{ width: '100%' }}
						openWithClick
					/>
					<ChipInputList
						disabled={!ruleSet.post}
						onChange={(e: any) => setTeams(e)}
						label={'Teams'}
						options={usersList}
						value={teams}
						style={{ width: '100%' }}
						openWithClick
					/>
					<ChipInputList
						onChange={(e: any) => setTeamLeaders(e)}
						label={'Team leaders'}
						options={usersList}
						value={teamLeaders}
						style={{ width: '100%' }}
						openWithClick
						disabled={!ruleSet.post}
					/>
					<ChipInputList
						disabled={!ruleSet.post}
						onChange={(e: any) => setFraudTools(e)}
						label={'Fraud Tools'}
						options={fraudTools || []}
						value={fraudTools || []}
						style={{ width: '100%' }}
					/>
					<ChipInputList
						disabled={!ruleSet.post}
						onChange={(e: any) => setCommunicationChannel(e)}
						label={'Communication Channel'}
						options={communicationChannel}
						value={communicationChannel}
						style={{ width: '100%' }}
					/>
					<ChipInputList
						disabled={!ruleSet.post}
						onChange={(e: any) => setContactRole(e)}
						label={'Contacts roles'}
						options={contactRole}
						value={contactRole}
						style={{ width: '100%' }}
					/>
					<ChipInputList
						disabled={!ruleSet.post}
						onChange={(e: any) => setBranches(e)}
						label={'Internal Legal Entities'}
						options={branches}
						value={branches}
						style={{ width: '100%' }}
					/>
					<TitleComponent
						style={{
							color: theme.colors.text.titles,
							alignSelf: 'flex-start',
						}}
					>
						Advertiser
					</TitleComponent>
					<ChipInputList
						disabled={!ruleSet.post}
						onChange={(e: any) => setPaymentTerms(e)}
						label={'Payment Terms'}
						options={paymentTerms}
						value={paymentTerms}
						style={{ width: '100%' }}
					/>
					<ChipInputList
						disabled={!ruleSet.post}
						onChange={(e: any) => setPauseReason(e)}
						label={'Pause posible reasons'}
						options={pauseReason}
						value={pauseReason}
						style={{ width: '100%' }}
					/>
					<ChipInputList
						disabled={!ruleSet.post}
						onChange={(e: any) => setTrafficRestrictions(e)}
						label={'Traffic Restrictions'}
						options={trafficRestrictions || []}
						value={trafficRestrictions || []}
						style={{ width: '100%' }}
					/>
					<ChipInputList
						disabled={!ruleSet.post}
						onChange={(e: any) => setExistingChannels(e)}
						label={'Existing Channels'}
						options={existingChannels || []}
						value={existingChannels || []}
						style={{ width: '100%' }}
					/>
					<TitleComponent
						style={{
							color: theme.colors.text.titles,
							alignSelf: 'flex-start',
						}}
					>
						Publishers
					</TitleComponent>
					<ChipInputList
						disabled={!ruleSet.post}
						onChange={(e: any) => setCapabilities(e)}
						label={'Abilities for Publishers'}
						options={capabilities}
						value={capabilities}
						style={{ width: '100%' }}
					/>
					<ChipInputList
						disabled={!ruleSet.post}
						onChange={(e: any) => setLogos(e)}
						label={'Format Types (Logos)'}
						options={logos || []}
						value={logos || []}
						style={{ width: '100%' }}
					/>
					<ChipInputList
						disabled={!ruleSet.post}
						onChange={(e: any) => setBanners(e)}
						label={'Format Types (Banners)'}
						options={banners || []}
						value={banners || []}
						style={{ width: '100%' }}
					/>
					<ChipInputList
						disabled={!ruleSet.post}
						onChange={(e: any) => setVideos(e)}
						label={'Format Types (Videos)'}
						options={videos || []}
						value={videos || []}
						style={{ width: '100%' }}
					/>
					<TitleComponent
						style={{
							color: theme.colors.text.titles,
							alignSelf: 'flex-start',
						}}
					>
						Creators
					</TitleComponent>
					<ChipInputList
						disabled={!ruleSet.post}
						onChange={(e: any) => setCreatorVertical(e)}
						label={'Verticals'}
						options={creatorVertical || []}
						value={creatorVertical || []}
						style={{ width: '100%' }}
					/>
					<TitleComponent
						style={{
							color: theme.colors.text.titles,
							alignSelf: 'flex-start',
						}}
					>
						Campaigns
					</TitleComponent>
					<ChipInputList
						disabled={!ruleSet.post}
						onChange={(e: any) => setTrafficRestrictions(e)}
						label={'Traffic Restrictions'}
						options={trafficRestrictions || []}
						value={trafficRestrictions || []}
						style={{ width: '100%' }}
					/>
					<ChipInputList
						disabled={!ruleSet.post}
						onChange={(e: any) => setExistingChannels(e)}
						label={'Existing Channels'}
						options={existingChannels || []}
						value={existingChannels || []}
						style={{ width: '100%' }}
					/>
					<ChipInputList
						disabled={!ruleSet.post}
						onChange={(e: any) => setTargetAudience(e)}
						label={'Target Audience'}
						options={targetAudience || []}
						value={targetAudience || []}
						style={{ width: '100%' }}
					/>
					<ChipInputList
						disabled={!ruleSet.post}
						onChange={(e: any) => setTargetAudienceOthers(e)}
						label={'Target Audience Others'}
						options={targetAudienceOthers || []}
						value={targetAudienceOthers || []}
						style={{ width: '100%' }}
					/>
					<ChipInputList
						disabled={!ruleSet.post}
						onChange={(e: any) => setCampaignGoals(e)}
						label={'Campaign Goals'}
						options={campaignGoals || []}
						value={campaignGoals || []}
						style={{ width: '100%' }}
					/>
					<TitleComponent
						style={{
							color: theme.colors.text.titles,
							alignSelf: 'flex-start',
						}}
					>
						Finance
					</TitleComponent>
					<ChipInputList
						disabled={!ruleSet.post}
						onChange={(e: any) => setCurrency(e)}
						label={'Currencies'}
						options={currency}
						value={currency}
						style={{ width: '100%' }}
					/>
					<ChipInputList
						disabled={!ruleSet.post}
						onChange={(e: any) => setPaymentMethod(e)}
						label={'Payment Methods'}
						options={paymentMethod}
						value={paymentMethod}
						style={{ width: '100%' }}
					/>
					<ChipInputList
						disabled={!ruleSet.post}
						onChange={(e: any) => setVatValues(e)}
						label={'VAT (%)'}
						options={vatValues}
						value={vatValues}
						style={{ width: '100%' }}
					/>
					{login.user.role.toLowerCase() === 'super' &&
						login.user.user_type.toLowerCase() === 'internal' && (
							<>
								<TitleComponent style={{ fontSize: '24px' }}>
									Users
								</TitleComponent>
								<ChipInputList
									disabled={!ruleSet.post}
									onChange={(e: any) => {
										setUserTypes(e)
										updateTypeInPermissionGroups(e, userTypes)
									}}
									label={'User Types'}
									options={userTypes.map(
										(type: string) =>
											type.charAt(0).toUpperCase() + type.slice(1),
									)}
									value={userTypes.map(
										(type: string) =>
											type.charAt(0).toUpperCase() + type.slice(1),
									)}
									style={{ width: '100%' }}
								/>
								<ChipInputList
									disabled={!ruleSet.post}
									onChange={(e: any) => setUserPositions(e)}
									label={'User Positions'}
									options={userPositions}
									value={userPositions}
									style={{ width: '100%' }}
								/>
							</>
						)}
					{login.user.role.toLowerCase() === 'super' &&
						login.user.user_type.toLowerCase() === 'internal' && (
							<>
								<TitleComponent
									style={{
										color: theme.colors.text.titles,
										alignSelf: 'flex-start',
									}}
								>
									Permissions Groups
								</TitleComponent>
								{Object.entries(permGroup).map((group: any) => {
									const key = group[0]
									const keyString = key.charAt(0).toUpperCase() + key.slice(1)
									const value = group[1]
									const roles = Object.keys(value)
									return (
										<PermissionsGroupList
											key={keyString}
											disabled={!ruleSet.post}
											onChange={(e: any) => updateTypeRoles(key, e)}
											label={keyString + ' Roles'}
											// options={roles.map(
											// 	(role: string) =>
											// 		role.charAt(0).toUpperCase() + role.slice(1),
											// )}
											options={roles}
											// value={roles.map(
											// 	(role: string) =>
											// 		role.charAt(0).toUpperCase() + role.slice(1),
											// )}
											value={roles}
											style={{
												marginTop: '-6px',
												width: '100%',
												overflow: 'auto',
											}}
											setOpenPermissionGroupsSettings={
												setOpenPermissionGroupsSettings
											}
											setSelectedName={(e: any) => {
												setSelectedName(e)
												setSelectedType(key.toLowerCase())
											}}
										/>
									)
								})}
							</>
						)}
					{ruleSet.post && (
						<SubmitButton
							onClick={() => {
								setIsWarningPromptOpened(true)
							}}
						>
							Save
						</SubmitButton>
					)}

					<WarningPrompt
						message={'Saving current changes?'}
						PopUpVisible={isWarningPromptOpened}
						setPopUpVisible={setIsWarningPromptOpened}
						onAgree={() => {
							handleSubmit()
							setIsWarningPromptOpened(false)
						}}
						onCancel={() => setIsWarningPromptOpened(false)}
					/>
				</Box>
			</Box>
			{openPermissionGroupsSettings && (
				<Paper>
					<PermissionsPage
						selectedName={selectedName}
						groups={permGroup[selectedType]}
						setPermGroup={(data: any) => updateTypeData(selectedType, data)}
					/>
				</Paper>
			)}
		</ThemeProvider>
	)
}

export default SettingsPage
