import ClearIcon from '@mui/icons-material/Clear'
import { Chip, MenuItem } from '@mui/material'
import { useEffect, useRef, useState } from 'react'
import { StyledAutocomplete } from '../components/reusableComponents'
import { permLookup } from '../permissions/permissionsPage'
import { StyledTextField } from '../reports/ReportsStyled'
export const PermissionsGroupList = (props: {
	onChange?: any
	label?: string
	options?: any[]
	hint?: string
	hintmargin?: string
	hintmarginTop?: string
	disabled?: boolean
	value: any[]
	float?: 'left' | 'right'
	fullWidth?: boolean
	multiple?: boolean
	singular?: boolean
	style?: React.CSSProperties
	onBlur?: any
	seeAll?: boolean
	loginRole?: string
	openWithClick?: boolean
	setMainHeightChange?: any
	onlyOneOptionAllowed?: boolean
	noMarginTop?: boolean
	ShowSelected?: boolean
	onlyOptionsAllowed?: boolean
	placeholder?: string
	clickableFunction?: (option: any) => void
	errored?: boolean
	errormessage?: any
	inputStyle?: any
	anchorId?: string
	chipStyle?: React.CSSProperties
	setOpenPermissionGroupsSettings: any
	setSelectedName: any
}) => {
	const ref = useRef()
	const [open, setOpen] = useState(false)
	const [anchorEl, setAnchorEl] = useState<any>('')

	useEffect(() => {
		if (props.anchorId) {
			setAnchorEl(document.getElementById(props.anchorId))
		}
	}, [])

	let gotCurrentHeight = false
	const getCurrentHeight = () => {
		const { current }: any = ref
		if (current?.clientHeight) {
			if (!gotCurrentHeight) {
				requestAnimationFrame(getCurrentHeight)
			}
			gotCurrentHeight = true
			//2.0 is hardcoded. It's the number of max px/ this element px that looks optimal to messure if the main buttons need to move.
			if (2.0 > window.innerHeight / current.clientHeight) {
				props.setMainHeightChange !== undefined &&
					props.setMainHeightChange(true)
			}
		}
	}
	getCurrentHeight()
	const StyleForSelectedOption = (value: string, list: string[]): any => {
		if (list.indexOf(value) !== -1) {
			return {
				fontWeight: '900',
				backgroundColor: 'rgba(238,238,238,0.7)',
				width: '100%',
				position: 'relative',
			}
		}
	}

	const errorStyling = () => {
		if (props.errormessage) {
			return {
				boxShadow: 'red 0px 0px 7px',
			}
		}
	}
	const selectedOptions: string[] = []
	const notSelectedOptions: string[] = []
	props.options!.forEach((el) => {
		if (props.value?.includes(el)) {
			selectedOptions.push(el)
		} else {
			notSelectedOptions.push(el)
		}
	})
	const orderedOptions = [...selectedOptions, ...notSelectedOptions]
	props.seeAll && orderedOptions.push('All')

	const renderOptionsWhenOpening = (propsd: any, option: any) => {
		// if (props.seeAll && option === 'All') {
		// 	return null
		// }
		return (
			<div {...propsd} key={propsd.id}>
				<MenuItem
					key={option}
					value={option}
					style={StyleForSelectedOption(option, props.value ? props.value : [])}
				>
					{option}
				</MenuItem>
			</div>
		)
	}

	const renderSelectedOptions = (value: any[], getTagProps: any) => {
		const chips: any = value.map((option: any, index: number) => {
			const CustomLabel = (props: any) => {
				const { children } = props
				return (
					<div
						style={{
							whiteSpace: 'normal',
							overflow: 'auto',
							textOverflow: 'ellipsis',
							paddingLeft: '2px',
							paddingRight: '2px',
							// width: '400px',
						}}
						// onClick={}
					>
						{children}
					</div>
				)
			}
			return (
				<Chip
					variant='outlined'
					onClick={() => {
						const name = option
						props.setOpenPermissionGroupsSettings((e: boolean) => !e)
						props.setSelectedName(name)
					}}
					onDelete={() => {
						// props.onChange(
						// 	props.value.filter((e: any, inde: any) => inde === index),
						// )
					}}
					label={
						props.ShowSelected ? <CustomLabel>{option}</CustomLabel> : option
					}
					deleteIcon={<ClearIcon />}
					style={{
						width: 'auto',
						minWidth: props.ShowSelected ? '100px' : '0px',
						height: 'auto',
						...props.chipStyle,
					}}
					{...getTagProps({ index })}
				/>
			)
		})

		return chips
	}

	// const CustomPopper = function (props: any) {
	// 	return (
	// 		<Popper
	// 			{...props}
	// 			disablePortal
	// 			anchorEl={anchorEl ? anchorEl : props.anchorEl}
	// 			style={{ width: 'auto' }}
	// 			placement='bottom-start'
	// 		/>
	// 	)
	// }
	const renderInputField = (params: any) => (
		<>
			<StyledTextField
				{...params}
				focused
				label={props.label ? props.label : undefined}
				margin='dense'
				fullWidth
				key={params + 'key-input'}
				variant='outlined'
				style={{
					float: props.float,
					height: 'auto',
					margin: props.noMarginTop ? '0px' : '',
					// backgroundColor: props.errored ? '#eeafaf' : 'rgba(238,238,238,0.6)',
					boxShadow: props.errored ? 'red 0px 0px 7px' : 'none',
					...errorStyling(),
					...props.inputStyle,
				}}
				InputProps={{
					...params.InputProps,
					style: {
						...props.inputStyle,
						paddingLeft: '10px',
					},
				}}
				InputLabelProps={{
					style: { top: '-10px' },
				}}
				onClick={() => {
					if (props.openWithClick && !props.disabled) {
						setOpen(!open)
					}
				}}
				placeholder={props.placeholder}
			/>
		</>
	)
	function compareArrays(array1: string[], array2: string[]) {
		for (let i = 0; i < array1.length; i++) {
			if (!array2.includes(array1[i])) {
				return false
			}
		}
		return true
	}

	return (
		<StyledAutocomplete
			ref={props.setMainHeightChange !== undefined ? ref : undefined}
			multiple={props.multiple ? false : true}
			freeSolo={!props.singular}
			disabled={props.disabled}
			open={
				props.seeAll &&
				props.value &&
				props.options &&
				props.value.length === props.options.length
					? false
					: open
			}
			options={orderedOptions}
			style={props.style}
			id={'tags-filled'}
			value={
				props.seeAll
					? props.value &&
					  props.value.length > 0 &&
					  props.value.length === (props.options?.length || 0)
						? ['All']
						: props.value || []
					: props.value && props.value.length === 1 && props.value[0] === ''
					? []
					: props.value
			}
			onChange={(a: any, n: any) => {
				if (a.target.value) {
					const newValues = [...props.value]
					newValues.push(a.target.value)
					props.onChange(newValues)
				} else {
					props.onChange(props.value.filter((x) => n.includes(x)))
				}
			}}
			isOptionEqualToValue={(option: any, value: any) => {
				return option === value ? true : false
			}}
			onBlur={() => {
				setOpen(false)
				props.setMainHeightChange !== undefined &&
					props.setMainHeightChange(false)
				return props.onBlur
			}}
			renderOption={renderOptionsWhenOpening}
			renderTags={renderSelectedOptions}
			renderInput={renderInputField}
			// PopperComponent={CustomPopper}
		/>
	)
}
