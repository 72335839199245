import ArrowBackIcon from '@mui/icons-material/ArrowBack'
import VisibilityIcon from '@mui/icons-material/Visibility'
import {
	Box,
	Button,
	Dialog,
	DialogActions,
	DialogContent,
	ThemeProvider,
	Tooltip,
	Typography,
} from '@mui/material'
import _ from 'lodash'
import React, { useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { v4 as uuidv4 } from 'uuid'
import { useActions } from '../../hooks/useActions'
import { useTypedSelector } from '../../hooks/useTypedSelector'
import { AdvertiserInterface, AppInterface } from '../../models/model.interface'
import { getAdvertiserAction, uploadIcon } from '../../state/action-creators'
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos'
import {
	checkValidBudgets,
	getApplicableRules,
	handleIconUrl,
	isValidImageType,
	stringIsValidName,
} from '../../utils/helpers/helperFuncs'
import { fetchData } from '../../utils/helpers/navigationHelper'
import {
	capitalizeFirstLetter,
	cellGenerator,
	checkInputStartsWithHttp,
	shallowEqual,
} from '../../utils/helpers/tableHelper'
import {
	ContactInterface,
	ContactsComponent,
} from '../components/ContactComponent'
import { PageWrapper } from '../components/reusableComponents'
import { CompanyInfoComponent } from './AdvertiserCompanyInfoComponent'
import { StyledTab, StyledTabs } from './AdvertiserStyled'
import { TrafficBudgetComponent } from './TrafficBudgetComponent'
import { AdvertiserFinanceInfo } from './AdvertiserFinanceInfoComponent'
import { TabContainer } from '../components/TabComponent'
import { SubmitButton, UnborderedButton } from '../components/Buttons'
import theme from '../../utils/theme2024'
import { FileUploadOutlined } from '@mui/icons-material'
import CustomModal from '../components/CustomModal'
import { CheckCircle } from '@mui/icons-material'
import { CSSProperties } from 'styled-components'
import CompanyInformationHeader from '../components/CompanyInformationHeader'
import defaultIcon from '../../assets/default-icon.webp'
import { ACCEPTED_FILES, endpoints } from '../../config'
import CustomFileUploader from '../components/CustomFileUploader'
import CustomDialog from '../components/CustomDialog'
import EditPageHeader from '../components/EditPageHeader'
import { editPageTopPanelStyle } from '../../utils/helpers/commonStyles'

interface TabPanelProps {
	children?: React.ReactNode
	index: number
	value: number
}

export function TabPanel(props: TabPanelProps) {
	const { children, value, index, ...other } = props

	return (
		<div
			role='tabpanel'
			hidden={value !== index}
			id={`simple-tabpanel-${index}`}
			aria-labelledby={`simple-tab-${index}`}
			{...other}
		>
			{value === index && (
				<Box sx={{ p: 3 }}>
					<Typography component={'span'}>{children}</Typography>
				</Box>
			)}
		</div>
	)
}
export interface AllowedPlatform {
	title: string
	value: boolean
}
const AdvertiserPopUp = (props: {
	PopUpVisible: boolean
	setPopUpVisible: any
	row?: AdvertiserInterface
	setLoading: any
	errorMessage: null | string
	setErrorMessage: any
	isErrorPromptOpened: boolean
	setIsErrorPromptOpened: any
	isSuccessPromptOpened: any
	setIsSuccessPromptOpened: any
}): JSX.Element => {
	const {
		insertAdvertiserAction,
		updateAdvertiserAction,
		uploadIcon,
		deleteIcon,
		insertLog,
		getAppsAction,
	} = useActions()
	const { settings, login, app, advertiser, publisher, users } =
		useTypedSelector((state) => state)
	const ruleSet = getApplicableRules(users, login, settings, 'advertiser')
	const [name, setName] = useState<string>('')
	const [uploadedId, setUploadedId] = useState<string>('')
	const [legalName, setLegalName] = useState<string>('')
	const [address, setAddress] = useState<string>('')
	const [website, setWebsite] = useState<string>('')
	const [linkedin, setLinkedin] = useState<string>('')
	const [brief, setBrief] = useState<string>('')
	const [parametersTemplate, setParametersTemplate] = useState<string>('')
	const [specialRequest, setSpecialRequest] = useState<string>('')
	const [geo, setGeo] = useState<string[]>([])
	const [appList, setAppList] = useState<any[]>([])
	const [linkToIO, setLinkToIO] = useState('')
	const [salesRep, setSalesRep] = useState('')
	const [pauseReason, setPauseReason] = useState('')
	const [pauseExplanation, setPauseExplanation] = useState('')
	const [paymentTerms, setPaymentTerms] = useState('')
	const [status, setStatus] = useState(true)
	const [statusChanged, setStatusChanged] = useState(false)
	const [ViewPauseBox, setViewPauseBox] = useState(false)
	const [fraudTool, setFraudTool] = useState<any>([])
	const [linkToAdvFile, setLinkToAdvFile] = useState('')
	const [linkToCreative, setLinkToCreative] = useState('')
	const [team, setTeam] = useState<string>('')
	const [invoiceInstructions, setInvoiceInstructions] = useState<string>('')
	const [createdBy, setCreatedBy] = useState<string>('')
	const [communicationChannel, setCommunicationChannel] = useState<string[]>([])
	const [advertiserType, setAdvertiserType] = useState<string>('')
	const [vertical, setVertical] = useState<string[]>([])
	const [trafficRestrictions, setTrafficRestrictions] = useState<string[]>([])
	const [existingChannels, setExistingChannels] = useState<string[]>([])
	const [platforms, setPlatforms] = useState<string[] | null>([])
	const [ownerEmail, setOwnerEmail] = useState<string | null>(login.user.email)
	const [apps, setApps] = useState<string[]>([])
	const [pastOwner, setPastOwner] = useState<any>([])
	const [pastOwnerFinance, setPastOwnerFinance] = useState<any>([])
	const [contacts, setContacts] = useState<ContactInterface[]>([])
	const [isValid, setIsValid] = useState<boolean>(false)
	const [prevState, setPrevState] = useState(null)
	const [usedPublishers, setUsedPublishers] = useState<string[]>([])
	const [dailyBudgetLimit, setDailyBudgetLimit] = useState<number>(0)
	const [monthlyBudgetLimit, setMonthlyBudgetLimit] = useState<number>(0)
	const [totalBudgetLimit, setTotalBudgetLimit] = useState<number>(0)
	const [dailyRevenue, setDailyRevenue] = useState<number>(0)
	const [monthlyRevenue, setMonthlyRevenue] = useState<number>(0)
	const [totalRevenue, setTotalRevenue] = useState<number>(0)
	const [notUsedPublishers, setNotUsedPublishers] = useState<string[]>([])
	const [mainHeightChange, setMainHeightChange] = useState(false)
	const [allowedPlatforms, setAllowedPlatforms] = useState<AllowedPlatform[]>(
		[],
	)
	const [branch, setBranch] = useState<string>('')
	const [uploadStatus, setUploadStatus] = useState<{
		message: string
		success: boolean
	} | null>(null)

	const [vat, setVat] = useState<string>('')
	const [tab, setTab] = useState(0)
	const [isDeleteModalOpen, setDeleteModalOpen] = useState(false)
	const [isUploadModalOpen, setIsUploadModalOpen] = useState(false)
	const navigate = useNavigate()
	const params = useParams()
	//we need to select all the data for the row that we opened (if it was already created, meaning that it has params.id)
	const row = useTypedSelector((state) =>
		state.advertiser.advertiser.find(
			(ad: AdvertiserInterface) => ad.advertiser_name.trim() === params.id,
		),
	)
	const [iconUrl, setIconUrl] = useState<string>(row?.advertiser_icon)

	const [mandatoryFields, setMandatoryFields] = useState({
		name: true,
		legalName: true,
		branch: true,
		paymentTerms: true,
	})

	const [fieldValidations, setFieldValidations] = useState({
		name: false,
		legalName: false,
		branch: false,
		paymentTerms: false,
	})

	const publisherList = publisher.publisher
		.map((el: any) => el.publisher_name)
		.sort((a: any, b: any) => a.localeCompare(b))

	//we use prevState and newState to compare the changes done by the user. We then upload the logs based on the differences.
	const preparePrevState = (prevState: any) => {
		try {
			const copiedState = _.cloneDeep(prevState)
			const { id, count, createdAt, status, platform, ...result } = copiedState
			return result
		} catch (error) {
			return null
		}
	}

	const prepareNewState = (prevState: any) => {
		const { id, user_login, ...result } = prevState
		return result
	}

	//we use this in order to generate the different tabs.
	function a11yProps(index: number) {
		return {
			id: `simple-tab-${index}`,
			'aria-controls': `simple-tabpanel-${index}`,
		}
	}
	//function to clear up the entire component in case we go back.
	const nullifyFields = () => {
		setPlatforms([])
		setOwnerEmail(null)
		setApps([])
		setName('')
		setLegalName('')
		setAddress('')
		setGeo([])
		setLinkToIO('')
		setSalesRep('')
		setFraudTool([])
		setLinkToAdvFile('')
		setLinkToCreative('')
		setStatus(false)
		setTeam('')
		setCommunicationChannel([])
		setAdvertiserType('')
		setVertical([])
		setCreatedBy('')
		setPauseReason('')
		setPauseExplanation('')
		setWebsite('')
		setLinkedin('')
		setBrief('')
		setSpecialRequest('')
		setTrafficRestrictions([])
		setExistingChannels([])
		setUsedPublishers([])
		setPastOwner([])
		setPastOwnerFinance([])
		setParametersTemplate('')
		setDailyRevenue(0)
		setMonthlyRevenue(0)
		setTotalRevenue(0)
		setDailyBudgetLimit(0)
		setMonthlyBudgetLimit(0)
		setTotalBudgetLimit(0)
		setBranch('')
		setVat('')
	}

	const handleLog = async (headers: any, payload: any) => {
		const shallow = shallowEqual(prevState, prepareNewState(payload))
		if (shallow.length > 0) {
			const log = {
				email: login.user.email,
				object: name! + ' in Advertisers',
				change: JSON.stringify(shallow),
			}
			await insertLog(headers, log)
		}
	}

	const handleUploadSuccess = (filename: string) => {
		setUploadStatus({
			message: `${filename} uploaded successfully`,
			success: true,
		})
		setTimeout(() => {
			setIsUploadModalOpen(false)
			setUploadStatus(null)
		}, 3000)
	}

	const handleUploadError = (filename: string) => {
		setUploadStatus({ message: `Error uploading ${filename}`, success: false })
		setTimeout(() => {
			setIsUploadModalOpen(false)
			setUploadStatus(null)
		}, 3000)
	}

	const handleOpenUploadModal = () => setIsUploadModalOpen(true)

	const handleIconClick = () => {
		if (iconUrl && iconUrl !== defaultIcon) {
			setDeleteModalOpen(true)
		}
	}

	const handleCloseDeleteModal = () => {
		setDeleteModalOpen(false)
	}

	const handleDeleteIcon = () => {
		if (iconUrl !== defaultIcon) {
			const headers = { Authorization: `Bearer ${login.user.token}` }
			deleteIcon('ADVERTISER', row.advertiser_name, headers)
			setDeleteModalOpen(false)
			setIconUrl(defaultIcon)
		}
	}

	const handleFileUpload = async (files: any) => {
		if (files.length > 0) {
			const file = files[0]
			if (!isValidImageType(file)) {
				props.setErrorMessage(
					'Unsupported file type. Please upload JPEG, PNG, or SVG files.',
				)
				return
			}
			const headers = {
				Authorization: `Bearer ${login.user.token}`,
			}

			const uploadResult = await uploadIcon(
				'ADVERTISER',
				file,
				row.advertiser_name,
				headers,
			)
			setIconUrl(uploadResult.toString())
		}
	}

	//function to save the advertiser.
	const trySubmit = async (saveContinue?: boolean) => {
		if (isValid) {
			let payload: any = {
				advertiser_name: name!,
				user_login: login.user.email,
				email: ownerEmail,
				platform: platforms!,
				apps: apps!,
				legal_name: legalName,
				address: address,
				geo: geo,
				link_to_io: linkToIO,
				sales_rep: salesRep,
				status: status,
				fraud_tool: fraudTool,
				link_to_adv_file: linkToAdvFile,
				link_to_creative: linkToCreative,
				contacts: _.cloneDeep(contacts),
				allowed_platforms: allowedPlatforms,
				team: team,
				communication_channel: communicationChannel,
				advertiser_type: advertiserType,
				vertical: vertical,
				created_by: login.user.email,
				payment_terms: paymentTerms,
				pause_reason: pauseReason,
				pause_explanation: pauseExplanation,
				website,
				linkedin,
				brief,
				parameters_template: parametersTemplate,
				special_request: specialRequest,
				traffic_restrictions: JSON.stringify(trafficRestrictions),
				existing_channels: JSON.stringify(existingChannels),
				past_owner: JSON.stringify(pastOwner),
				past_owner_finance: JSON.stringify(pastOwnerFinance),
				daily_budget_limit: dailyBudgetLimit,
				monthly_budget_limit: monthlyBudgetLimit,
				total_budget_limit: totalBudgetLimit,
				branch: branch,
				vat,
				invoice_instructions_finance: invoiceInstructions,
			}
			if (row) {
				payload.advertiser_name = row.advertiser_name
				payload['new_name'] = name!
			}
			const headers = {
				Authorization: `Token ${login.user.token}`,
			}
			props.setLoading(true)
			const failed = await insertAdvertiserAction(
				headers,
				payload,
				props.setErrorMessage,
			)
			getAppsAction(headers)
			//if the save is successful then we will handle the logs and navigate to the advertiser page
			//(in case it was a new advertiser, in order to allow the user to edit from now on if he wants)
			if (!failed) {
				await handleLog(headers, payload)
				if (!saveContinue) {
					nullifyFields()
					navigate('/advertisers')
					props.setPopUpVisible(false)
				} else {
					payload.contacts = contacts
					const obj = _.cloneDeep(payload)
					const prev = preparePrevState(obj)
					setPrevState({ ...prev })
					navigate('/advertisers/' + name)
					window.location.reload() // Force a reload to ensure the parent component re-renders
					props.setIsSuccessPromptOpened(true)
				}
			}
			props.setLoading(false)
		}
	}
	//main function to fill all the data from the row to render it.
	const fillFields = (row: AdvertiserInterface, appList: string[]) => {
		setPlatforms(row!.platform)
		setOwnerEmail(row!.email)
		setApps(row.apps ? row.apps : appList)
		setName(row!.advertiser_name)
		setLegalName(row!.legal_name)
		setAddress(row!.address)
		setGeo(row!.geo)
		setLinkToIO(row!.link_to_io)
		setSalesRep(row!.sales_rep)
		setFraudTool(row!.fraud_tool)
		setLinkToAdvFile(row!.link_to_adv_file)
		setLinkToCreative(row!.link_to_creative)
		setStatus(row!.status)
		setContacts(row!.contacts)
		setTeam(row!.team)
		setCreatedBy(row!.created_by)
		setCommunicationChannel(
			row.communication_channel ? row.communication_channel : [],
		)
		setPaymentTerms(row!.payment_terms)
		setAdvertiserType(row!.advertiser_type)
		setVertical(row.vertical ? row.vertical : [])
		setAllowedPlatforms(row!.allowed_platforms)
		setPauseReason(row!.pause_reason)
		setPauseExplanation(row!.pause_explanation)
		setWebsite(row!.website)
		setLinkedin(row!.linkedin)
		setBrief(row!.brief)
		setSpecialRequest(row!.special_request)
		setParametersTemplate(row!.parameters_template)
		setUsedPublishers(row!.used_publisher)
		setDailyRevenue(row!.daily_revenue)
		setMonthlyRevenue(row!.monthly_revenue)
		setTotalRevenue(row!.total_revenue)
		setDailyBudgetLimit(row!.daily_budget_limit)
		setMonthlyBudgetLimit(row!.monthly_budget_limit)
		setTotalBudgetLimit(row!.total_budget_limit)
		row!.traffic_restrictions &&
			setTrafficRestrictions(JSON.parse(row!.traffic_restrictions))
		row!.existing_channels &&
			setExistingChannels(JSON.parse(row!.existing_channels))
		row!.past_owner && setPastOwner(JSON.parse(row!.past_owner))
		row!.past_owner_finance &&
			setPastOwnerFinance(JSON.parse(row!.past_owner_finance))
		setBranch(row!.branch)
		setVat(row!.vat)
		setInvoiceInstructions(row!.invoice_instructions_finance)
	}
	useEffect(() => {
		if (advertiserType === 'Network') {
			setFraudTool(['No Fraud Tool'])
		}
	}, [advertiserType])
	//function to UPDATE an advertise that already exists.
	const tryUpdate = async (saveContinue?: boolean) => {
		if (isValid) {
			let payload: any = {
				id: uploadedId ? uploadedId : row!.advertiser_name,
				advertiser_name: name!,
				user_login: login.user.email,
				email: ownerEmail,
				platform: platforms!,
				apps: apps!,
				legal_name: legalName,
				address: address,
				geo: geo,
				link_to_io: linkToIO,
				sales_rep: salesRep,
				status: status,
				fraud_tool: fraudTool,
				link_to_adv_file: linkToAdvFile,
				link_to_creative: linkToCreative,
				contacts: _.cloneDeep(contacts),
				allowed_platforms: allowedPlatforms,
				team: team,
				communication_channel: communicationChannel,
				advertiser_type: advertiserType,
				vertical: vertical,
				created_by: createdBy,
				payment_terms: paymentTerms,
				pause_reason: pauseReason,
				pause_explanation: pauseExplanation,
				website,
				linkedin,
				brief,
				parameters_template: parametersTemplate,
				special_request: specialRequest,
				traffic_restrictions: JSON.stringify(trafficRestrictions),
				existing_channels: JSON.stringify(existingChannels),
				past_owner: JSON.stringify(pastOwner),
				past_owner_finance: JSON.stringify(pastOwnerFinance),
				daily_budget_limit: dailyBudgetLimit,
				monthly_budget_limit: monthlyBudgetLimit,
				total_budget_limit: totalBudgetLimit,
				branch: branch,
				vat,
				invoice_instructions_finance: invoiceInstructions,
			}

			const headers = {
				Authorization: `Token ${login.user.token}`,
			}
			props.setLoading(true)

			const failed = await updateAdvertiserAction(
				headers,
				payload,
				ruleSet,
				props.setErrorMessage,
			)
			props.setLoading(false)
			getAppsAction(headers)
			if (!failed) {
				await handleLog(headers, payload)
				payload.contacts = contacts
				const obj = _.cloneDeep(payload)
				const prev = preparePrevState(obj)
				setPrevState({ ...prev })
				props.setIsSuccessPromptOpened(true)
				setUploadedId(name)
			}
			await fetchData(login, getAdvertiserAction, advertiser.advertiser)
			//if everything went correct we want to refill all the data with the updated one
			fillFields(
				payload,
				app.app
					.filter(
						(a: AppInterface) => a.advertiser_name === row!.advertiser_name,
					)
					.map((a: AppInterface) => a.app_id),
			)
		}
	}
	const handleNewContact = (type: string) => {
		const new_contact: ContactInterface = {
			name: '',
			role: '',
			email: '',
			phone: '',
			skype: '',
			geo: [],
			type: type,
			uuid: uuidv4(),
		}
		let addition = [new_contact]
		if (contacts !== null && contacts !== undefined) {
			addition = [...contacts, new_contact]
		}

		setContacts(addition)
	}
	//this is legacy code. Could probably be removed because we don't use this on the edit page. TODO: refactor ?
	useEffect(() => {
		if (usedPublishers && usedPublishers.length > 0) {
			const arr = publisherList.filter(
				(pub: any) => !usedPublishers.includes(pub),
			)
			setNotUsedPublishers(arr)
		}
	}, [usedPublishers])
	//basically triggering the fill for the selected row.
	useEffect(() => {
		if (row) {
			const updatedAppList = app.app.filter(
				(a: AppInterface) => a.advertiser_name === row!.advertiser_name,
			)
			setAppList(updatedAppList)
			fillFields(
				row,
				updatedAppList.map((a: AppInterface) => a.app_id),
			)
		}
	}, [row, app.app, advertiser.advertiser])

	useEffect(() => {
		if (row) {
			const obj = _.cloneDeep(row)
			const apps = []
			const updatedAppList = app.app.filter(
				(a: AppInterface) => a.advertiser_name === row!.advertiser_name,
			)
			for (const app of updatedAppList) {
				apps.push(app.app_id)
			}
			obj.apps = apps
			const prev = preparePrevState(obj)
			if (!prevState) {
				setPrevState({ ...prev })
			}
		}
	}, [appList])
	//function we use to update the tab we are in.
	const handleChangeTab = (event: React.SyntheticEvent, newValue: number) => {
		setTab(newValue)
	}

	//this use Effect will check if some sensitive data is correct and will allow or not to update/create the advertiser.
	useEffect(() => {
		const mandatoryFieldsObj = {
			name: true,
			legalName: true,
			branch: advertiserType !== 'Direct' || apps.length > 0,
			paymentTerms: true,
		}

		const isNameValid = name.trim() !== ''
		const isLegalNameValid = legalName.trim() !== ''
		const isBranchValid = branch.trim() !== ''
		const isPaymentTermsValid = paymentTerms.length > 0

		setFieldValidations({
			name: isNameValid,
			legalName: isLegalNameValid,
			branch: isBranchValid,
			paymentTerms: isPaymentTermsValid,
		})

		setMandatoryFields(mandatoryFieldsObj)

		if (
			(linkToAdvFile && !checkInputStartsWithHttp(linkToAdvFile)) ||
			(linkToCreative && !checkInputStartsWithHttp(linkToCreative)) ||
			(linkToIO && !checkInputStartsWithHttp(linkToIO)) ||
			(linkedin && !checkInputStartsWithHttp(linkedin)) ||
			(website && !checkInputStartsWithHttp(website)) ||
			(name && !stringIsValidName(name)) ||
			(dailyBudgetLimit &&
				monthlyBudgetLimit &&
				checkValidBudgets(
					parseInt(dailyBudgetLimit as unknown as string),
					parseInt(monthlyBudgetLimit as unknown as string),
				))
		) {
			setIsValid(false)
		} else {
			if (
				(name &&
					advertiserType !== 'Direct' &&
					!!branch &&
					!!legalName &&
					!!paymentTerms) ||
				(name &&
					advertiserType === 'Direct' &&
					vertical.length > 0 &&
					apps.length > 0 &&
					!!branch &&
					!!legalName &&
					!!paymentTerms)
			) {
				setIsValid(true)
			} else {
				setIsValid(false)
			}
		}
	}, [
		linkToAdvFile,
		linkToCreative,
		linkToIO,
		linkedin,
		website,
		name,
		dailyBudgetLimit,
		monthlyBudgetLimit,
		prevState,
		advertiserType,
		vertical,
		apps,
		branch,
		legalName,
		paymentTerms,
	])

	//this is handled in order to show the pause reasons (or remove them) based on the status.
	useEffect(() => {
		if (status === false && statusChanged) {
			setViewPauseBox(true)
		} else {
			setViewPauseBox(false)
			setPauseExplanation('')
			setPauseReason('')
		}
	}, [status])

	//this might be legacy code. Team is actually taken from the user now. We should refactor. TODO: refactor
	useEffect(() => {
		for (const user of users.users) {
			if (user.email === ownerEmail) {
				setTeam(user.team)
			}
		}
	}, [ownerEmail])
	//we use this to go back to the main table.
	const closeDetailedView = () => {
		nullifyFields()
		navigate('/advertisers')
		props.setPopUpVisible(false)
	}
	const openPreview = () => {
		navigate('/advertisers/' + params.id + '/preview')
	}
	const tabOptions = [
		{ label: 'Company Information', tabIndex: 0 },
		{ label: 'Traffic & Budget', tabIndex: 1 },
		{ label: 'Finance', tabIndex: 2 },
	]

	return (
		<ThemeProvider theme={theme}>
			<EditPageHeader
				onBack={closeDetailedView}
				onPreview={openPreview}
				onUpload={handleOpenUploadModal}
				tabValue={tab}
				handleChangeTab={handleChangeTab}
				hasPreview={!!(row && ruleSet.rules.preview && params.id)}
				options={tabOptions}
				customStyles={editPageTopPanelStyle}
				ariaLabel='basic tabs'
			/>

			<CustomModal
				title='Upload Icon'
				open={isUploadModalOpen}
				onClose={() => setIsUploadModalOpen(false)}
			>
				{uploadStatus ? (
					<Box
						display='flex'
						flexDirection='column'
						alignItems='center'
						justifyContent='center'
						p={2}
					>
						<Typography variant='subtitle1'>{uploadStatus.message}</Typography>
						<CheckCircle style={{ color: 'green', fontSize: 40 }} />
					</Box>
				) : (
					<CustomFileUploader
						onSetFiles={handleFileUpload}
						onSuccess={handleUploadSuccess}
						onError={handleUploadError}
						acceptedFiles={ACCEPTED_FILES.IMAGES_ONLY}
					/>
				)}
			</CustomModal>
			<PageWrapper
				style={{
					marginTop: '2vh',
					maxHeight: '200%',
					overflow: 'visible',
					height: 'fit-content',
					display: 'flex',
					flexDirection: 'column',
				}}
			>
				<CompanyInformationHeader
					name={row?.advertiser_name}
					iconUrl={handleIconUrl(iconUrl, defaultIcon)}
					onClickIcon={
						iconUrl !== defaultIcon && iconUrl ? handleIconClick : undefined
					}
					tooltipText={
						iconUrl === defaultIcon || !iconUrl
							? 'No icon to delete'
							: 'Click to delete icon'
					}
					cursorStyle={
						iconUrl === defaultIcon || !iconUrl ? 'default' : 'pointer'
					}
					style={{ marginLeft: '1rem' }}
				/>

				<CustomDialog
					open={isDeleteModalOpen}
					onClose={handleCloseDeleteModal}
					onConfirm={handleDeleteIcon}
					imageUrl={handleIconUrl(iconUrl, defaultIcon)}
					message='Click delete to remove the icon.'
				/>

				<TabPanel value={tab} index={0}>
					<CompanyInfoComponent
						name={name}
						setName={setName}
						legalName={legalName}
						setLegalName={setLegalName}
						address={address}
						setAddress={setAddress}
						geo={geo}
						setGeo={setGeo}
						linkToIO={linkToIO}
						setLinkToIO={setLinkToIO}
						setOwnerEmail={setOwnerEmail}
						ownerEmail={ownerEmail}
						setSalesRep={setSalesRep}
						salesRep={salesRep}
						setStatus={setStatus}
						status={status}
						setApps={setApps}
						apps={apps}
						appList={apps}
						setPlatforms={setPlatforms}
						platforms={platforms}
						setFraudTool={setFraudTool}
						fraudTool={fraudTool}
						linkToAdvFile={linkToAdvFile}
						setLinkToAdvFile={setLinkToAdvFile}
						linkToCreative={linkToCreative}
						setLinkToCreative={setLinkToCreative}
						team={team}
						setTeam={setTeam}
						communicationChannel={communicationChannel}
						setCommunicationChannel={setCommunicationChannel}
						advertiserType={advertiserType}
						setAdvertiserType={setAdvertiserType}
						vertical={vertical}
						setVertical={setVertical}
						isValid={isValid}
						settings={settings.settings.vertical ? settings : []}
						loginRole={login.user.role}
						createdBy={createdBy}
						loginUser={login.user.email}
						setMainHeightChange={setMainHeightChange}
						pauseReason={pauseReason}
						setPauseReason={setPauseReason}
						pauseExplanation={pauseExplanation}
						setPauseExplanation={setPauseExplanation}
						ViewPauseBox={ViewPauseBox}
						setViewPauseBox={setViewPauseBox}
						setStatusChanged={setStatusChanged}
						website={website}
						setWebsite={setWebsite}
						linkedin={linkedin}
						setLinkedin={setLinkedin}
						brief={brief}
						setBrief={setBrief}
						specialRequest={specialRequest}
						setSpecialRequest={setSpecialRequest}
						setPastOwner={setPastOwner}
						pastOwner={pastOwner}
						setPastOwnerFinance={setPastOwnerFinance}
						pastOwnerFinance={pastOwnerFinance}
						parametersTemplate={parametersTemplate}
						setParametersTemplate={setParametersTemplate}
						users={users}
						mandatoryFields={mandatoryFields}
						fieldValidations={fieldValidations}
					/>

					{ruleSet.rules['view advertiser contacts'] && (
						<div style={{ marginTop: '30px' }}>
							<ContactsComponent
								contacts={contacts}
								setContacts={setContacts}
								handleNewContact={handleNewContact}
								loginRole={login.user.role}
								publisherName={name}
								setErrorMessage={props.setErrorMessage}
								p360active={false}
								setIsSuccessPromptOpened={props.setIsSuccessPromptOpened}
								owner={ownerEmail}
								ruleSet={ruleSet}
							></ContactsComponent>
						</div>
					)}
				</TabPanel>
				<TabPanel value={tab} index={1}>
					<TrafficBudgetComponent
						loginRole={login.user.role}
						settings={settings}
						trafficRestrictions={trafficRestrictions}
						setTrafficRestrictions={setTrafficRestrictions}
						existingChannels={existingChannels}
						setExistingChannels={setExistingChannels}
						dailyBudgetLimit={dailyBudgetLimit}
						setDailyBudgetLimit={setDailyBudgetLimit}
						monthlyBudgetLimit={monthlyBudgetLimit}
						setMonthlyBudgetLimit={setMonthlyBudgetLimit}
						totalBudgetLimit={totalBudgetLimit}
						setTotalBudgetLimit={setTotalBudgetLimit}
					/>
				</TabPanel>
				<TabPanel value={tab} index={2}>
					<AdvertiserFinanceInfo
						loginRole={login.user.role}
						settings={settings}
						branch={branch}
						setBranch={setBranch}
						vat={vat}
						setVat={setVat}
						paymentTerms={paymentTerms}
						setPaymentTerms={setPaymentTerms}
						invoiceInstructions={invoiceInstructions}
						setInvoiceInstructions={setInvoiceInstructions}
						mandatoryFields={mandatoryFields}
						fieldValidations={fieldValidations}
					/>
				</TabPanel>
				<DialogActions
					style={{
						paddingBottom: '80px',
						display: 'flex',
						alignSelf: 'flex-end',
						justifyContent: 'center',
						width: '100%',
						gap: 160,
					}}
				>
					<UnborderedButton
						// style={{ margin: 'auto' }}
						onClick={() => {
							nullifyFields()
							navigate('/advertisers')
							props.setPopUpVisible(false)
						}}
						label={'Cancel'}
					></UnborderedButton>
					{ruleSet.post && (
						<SubmitButton
							// style={{ margin: 'auto' }}
							disabled={!isValid}
							onClick={() => {
								if (!!row) {
									tryUpdate(true)
								} else {
									trySubmit(true)
								}
							}}
						>
							{row ? 'Update' : 'Save'}
						</SubmitButton>
					)}
				</DialogActions>
			</PageWrapper>
		</ThemeProvider>
	)
}
export default AdvertiserPopUp
