import { subDays } from 'date-fns'
import { Dispatch } from 'redux'
import { endpoints } from '../../config'
import { PublisherInterface, UserInterface } from '../../models/model.interface'
import { isUuid } from '../../pages/campaigns/CampaignComponents'
import { downloadEverything } from '../../pages/dashboard/Dashboard'
import { NetworkFieldsInterface } from '../../pages/network/FiltersPage'
import { ReportFieldsInterface } from '../../pages/reports/FiltersPage'
import AxiosCustom from '../../utils/Axios'
import { formatDateToDdMmYyyy } from '../../utils/helpers/helperFuncs'
import {
	capitalizeFirstLetter,
	cellGenerator,
} from '../../utils/helpers/tableHelper'
import { ActionType } from '../action-types'
import { Action } from '../actions/index'
import { persistor } from '../store'
import { RootState } from '../reducers'
import axios from 'axios'

export interface loginParams {
	email: string
	password: string
}

export interface signUpParams {
	name: string
	type: string
	email: string
	password: string
}

export interface newPasswordParams {
	email: string
	newPassword: string
}

export interface userParams {
	Authorization: string
}

export interface updateAppParams {
	app_id: string
	status: boolean
	email: string
	created_by: string
}

//------------NEWS OPERATIONS ------------

export const getNewsAction = (headers: any) => {
	return async (dispatch: Dispatch<Action>) => {
		try {
			const { data } = await AxiosCustom.get(endpoints.NEWS, {
				headers,
			})
			if (!data.successful) {
				return
			}

			dispatch({
				type: ActionType.GET_NEWS,
				payload: data.payload,
			})
		} catch (err) {
			console.error(err)
			window.location.replace(window.location.origin)
		}
	}
}

export const postNewsAction = (headers: any, obj: any) => {
	return async (dispatch: Dispatch<Action>) => {
		try {
			const payload = {
				post: {
					id: 1,
					topics: obj.topics,
					deleted: obj.deleted,
				},
			}
			const { data } = await AxiosCustom.post(endpoints.NEWS, payload, {
				headers,
			})
			if (!data.successful) {
				return
			}

			dispatch({
				type: ActionType.POST_NEWS,
				payload: data.payload,
			})
		} catch (err) {
			console.error(err)
		}
	}
}

export const postNewsImageAction = (headers: any, payload: any) => {
	return async (dispatch: Dispatch<Action>) => {
		try {
			function base64Encode(str: any) {
				var CHARS =
					'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789+/'
				var out = '',
					i = 0,
					len = str.length,
					c1,
					c2,
					c3
				while (i < len) {
					c1 = str.charCodeAt(i++) & 0xff
					if (i == len) {
						out += CHARS.charAt(c1 >> 2)
						out += CHARS.charAt((c1 & 0x3) << 4)
						out += '=='
						break
					}
					c2 = str.charCodeAt(i++)
					if (i == len) {
						out += CHARS.charAt(c1 >> 2)
						out += CHARS.charAt(((c1 & 0x3) << 4) | ((c2 & 0xf0) >> 4))
						out += CHARS.charAt((c2 & 0xf) << 2)
						out += '='
						break
					}
					c3 = str.charCodeAt(i++)
					out += CHARS.charAt(c1 >> 2)
					out += CHARS.charAt(((c1 & 0x3) << 4) | ((c2 & 0xf0) >> 4))
					out += CHARS.charAt(((c2 & 0xf) << 2) | ((c3 & 0xc0) >> 6))
					out += CHARS.charAt(c3 & 0x3f)
				}
				return out
			}
			const obj = { post: payload }
			const { data } = await AxiosCustom.post(
				endpoints.NEWS_I,
				JSON.stringify(obj),
				{
					headers,
				},
			)
			if (!data.successful) {
				return
			}
			dispatch({
				type: ActionType.POST_IMAGE,
				payload: data.payload,
			})
		} catch (err) {
			console.error(err)
		}
	}
}

// --------- LOGS OPERATION --------

export const getLogsAction = (headers: any) => {
	return async (dispatch: Dispatch<Action>) => {
		try {
			const { data } = await AxiosCustom.get(endpoints.LOGS, {
				headers,
			})
			if (!data.successful) {
				return
			}

			dispatch({
				type: ActionType.GET_LOGS,
				payload: data.payload,
			})
		} catch (err) {
			console.error(err)
		}
	}
}

export const getLogsByDateAction = (
	headers: any,
	from_date: Date,
	to_date: Date,
) => {
	return async (dispatch: Dispatch<Action>) => {
		try {
			const payload = { logs: { from_date, to_date } }
			const { data } = await AxiosCustom.post(endpoints.LOGS, payload, {
				headers,
			})

			if (!data.successful) {
				return
			}

			dispatch({
				type: ActionType.GET_LOGS,
				payload: data.payload,
			})
		} catch (err) {
			console.error(err)
		}
	}
}

export const insertLog = (headers: any, obj: any) => {
	return async (dispatch: Dispatch<Action>) => {
		try {
			const log = {
				log: obj,
			}
			const { data } = await AxiosCustom.post(endpoints.LOGS + '/add', log, {
				headers,
			})

			if (!data.successful) {
				return
			}
			dispatch({
				type: ActionType.INSERT_LOG,
				payload: data.payload,
			})
		} catch (err) {
			console.error(err)
		}
	}
}

// --------- LEARNING OPERATION --------

export const getLearningAction = (headers: any) => {
	return async (dispatch: Dispatch<Action>) => {
		try {
			const { data } = await AxiosCustom.get(endpoints.DOCUMENTS, {
				headers,
			})
			// const learning = {
			// 	allowedPlatforms: JSON.parse(data.payload[0].allowedPlatforms),
			// 	vertical: JSON.parse(data.payload[0].vertical),
			// 	capabilities: JSON.parse(data.payload[0].capabilities),
			// 	salesRepresentative: JSON.parse(data.payload[0].salesRepresentative),
			// 	platforms: JSON.parse(data.payload[0].platforms),
			// 	contactRole: JSON.parse(data.payload[0].contactRole),
			// }
			if (!data.successful) {
				return
			}

			dispatch({
				type: ActionType.GET_LEARNING,
				payload: data.payload,
			})
		} catch (err) {
			console.error(err)
		}
	}
}
export const addLearningRow = (
	headers: any,
	obj: any,
	setErrorMessage: any,
	setIsSuccessPromptOpened: any,
	setLoading: any,
) => {
	return async (dispatch: Dispatch<Action>) => {
		try {
			const learning = {
				learning: obj,
			}
			const { data } = await AxiosCustom.post(endpoints.DOCUMENTS, learning, {
				headers,
			})
			if (!data.successful) {
				setErrorMessage(data.payload)
				setLoading(false)
				return
			}
			setIsSuccessPromptOpened(true)
			setLoading(false)
		} catch (err) {
			console.error(err)
		}
	}
}
export const updateLearningRow = (
	headers: any,
	obj: any,
	setErrorMessage: any,
	setIsSuccessPromptOpened: any,
	setLoading: any,
) => {
	return async (dispatch: Dispatch<Action>) => {
		try {
			const learning = {
				learning: obj,
			}
			const { data } = await AxiosCustom.put(endpoints.DOCUMENTS, learning, {
				headers,
			})
			if (!data.successful) {
				setErrorMessage(data.payload)
				setLoading(false)
				return
			}
			setIsSuccessPromptOpened(true)
			setLoading(false)
		} catch (err) {
			console.error(err)
		}
	}
}
export const removeRow = (
	headers: any,
	obj: any,
	setErrorMessage: any,
	setIsSuccessPromptOpened: any,
) => {
	return async (dispatch: Dispatch<Action>) => {
		try {
			const dataSend = { learning: obj }

			const { data } = await AxiosCustom.delete(
				endpoints.DOCUMENTS + `/${obj.id}`,
				{
					headers,
					data: dataSend,
				},
			)
			if (!data.successful) {
				setErrorMessage(data.payload)
				return
			}
			setIsSuccessPromptOpened(true)
			dispatch({
				type: ActionType.REMOVE_LEARNING,
				payload: obj,
			})
		} catch (err) {
			console.error(err)
		}
	}
}
// --------- SETTINGS OPERATION --------

export const getSettingsAction = (headers: any) => {
	return async (dispatch: Dispatch<Action>) => {
		try {
			const { data } = await AxiosCustom.get(endpoints.SETTINGS, {
				headers,
			})
			// console.log(data.payload[0])
			// const settings = {
			// 	allowedPlatforms: data.payload[0].allowedPlatforms
			// 		? JSON.parse(data.payload[0].allowedPlatforms)
			// 		: [],
			// 	vertical: data.payload[0].vertical
			// 		? JSON.parse(data.payload[0].vertical)
			// 		: [],
			// 	capabilities: data.payload[0].capabilities
			// 		? JSON.parse(data.payload[0].capabilities)
			// 		: [],
			// 	salesRepresentative: data.payload[0].salesRepresentative
			// 		? JSON.parse(data.payload[0].salesRepresentative)
			// 		: [],
			// 	teams: data.payload[0].teams ? JSON.parse(data.payload[0].teams) : [],
			// 	fraudTools: data.payload[0].fraudTools
			// 		? JSON.parse(data.payload[0].fraudTools)
			// 		: [],
			// 	platforms: data.payload[0].platforms
			// 		? JSON.parse(data.payload[0].platforms)
			// 		: [],
			// 	contactRole: data.payload[0].contactRole
			// 		? JSON.parse(data.payload[0].contactRole)
			// 		: [],
			// 	banners: data.payload[0].banners
			// 		? JSON.parse(data.payload[0].banners)
			// 		: [],
			// 	videos: data.payload[0].videos
			// 		? JSON.parse(data.payload[0].videos)
			// 		: [],
			// 	paymentTerms: data.payload[0].paymentTerms
			// 		? JSON.parse(data.payload[0].paymentTerms)
			// 		: [],
			// 	pauseReason: data.payload[0].pauseReason
			// 		? JSON.parse(data.payload[0].pauseReason)
			// 		: [],
			// 	trafficRestrictions: data.payload[0].trafficRestrictions
			// 		? JSON.parse(data.payload[0].trafficRestrictions)
			// 		: [],
			// 	existingChannels: data.payload[0].existingChannels
			// 		? JSON.parse(data.payload[0].existingChannels)
			// 		: [],
			// 	communicationChannel: data.payload[0].communicationChannel
			// 		? JSON.parse(data.payload[0].communicationChannel)
			// 		: [],

			// 	targetAudience: data.payload[0].targetAudience
			// 		? JSON.parse(data.payload[0].targetAudience)
			// 		: [],
			// 	targetAudienceOthers: data.payload[0].targetAudienceOthers
			// 		? JSON.parse(data.payload[0].targetAudienceOthers)
			// 		: [],
			// 	campaignGoals: data.payload[0].campaignGoals
			// 		? JSON.parse(data.payload[0].campaignGoals)
			// 		: [],
			// 	currency: data.payload[0].currency
			// 		? JSON.parse(data.payload[0].currency)
			// 		: [],
			// 	paymentMethod: data.payload[0].paymentMethod
			// 		? JSON.parse(data.payload[0].paymentMethod)
			// 		: [],
			// 	branches: data.payload[0].branches
			// 		? JSON.parse(data.payload[0].branches)
			// 		: [],
			// 	vatValues: data.payload[0].vatValues
			// 		? JSON.parse(data.payload[0].vatValues)
			// 		: [],
			// 	permissionGroups: data.payload[0].permissionGroups
			// 		? JSON.parse(data.payload[0].permissionGroups)
			// 		: [],
			// }
			const settings = {
				allowedPlatforms: data.payload[0].allowedPlatforms
					? data.payload[0].allowedPlatforms
					: [],
				vertical: data.payload[0].vertical ? data.payload[0].vertical : [],
				creatorVertical: data.payload[0].creatorVertical
					? data.payload[0].creatorVertical
					: [],
				capabilities: data.payload[0].capabilities
					? data.payload[0].capabilities
					: [],
				salesRepresentative: data.payload[0].salesRepresentative
					? data.payload[0].salesRepresentative
					: [],
				teams: data.payload[0].teams ? data.payload[0].teams : [],
				fraudTools: data.payload[0].fraudTools
					? data.payload[0].fraudTools
					: [],
				platforms: data.payload[0].platforms ? data.payload[0].platforms : [],
				contactRole: data.payload[0].contactRole
					? data.payload[0].contactRole
					: [],
				logos: data.payload[0].logos ? data.payload[0].logos : [],
				banners: data.payload[0].banners ? data.payload[0].banners : [],
				videos: data.payload[0].videos ? data.payload[0].videos : [],
				paymentTerms: data.payload[0].paymentTerms
					? data.payload[0].paymentTerms
					: [],
				pauseReason: data.payload[0].pauseReason
					? data.payload[0].pauseReason
					: [],
				trafficRestrictions: data.payload[0].trafficRestrictions
					? data.payload[0].trafficRestrictions
					: [],
				existingChannels: data.payload[0].existingChannels
					? data.payload[0].existingChannels
					: [],
				communicationChannel: data.payload[0].communicationChannel
					? data.payload[0].communicationChannel
					: [],

				targetAudience: data.payload[0].targetAudience
					? data.payload[0].targetAudience
					: [],
				targetAudienceOthers: data.payload[0].targetAudienceOthers
					? data.payload[0].targetAudienceOthers
					: [],
				campaignGoals: data.payload[0].campaignGoals
					? data.payload[0].campaignGoals
					: [],
				currency: data.payload[0].currency ? data.payload[0].currency : [],
				paymentMethod: data.payload[0].paymentMethod
					? data.payload[0].paymentMethod
					: [],
				branches: data.payload[0].branches ? data.payload[0].branches : [],
				vatValues: data.payload[0].vatValues ? data.payload[0].vatValues : [],
				permissionGroups: data.payload[0].permissionGroups
					? data.payload[0].permissionGroups
					: [],
				teamLeaders: data.payload[0].teamLeaders
					? data.payload[0].teamLeaders
					: [],
				userPositions: data.payload[0].userPositions
					? data.payload[0].userPositions
					: [],
				userTypes: data.payload[0].userTypes ? data.payload[0].userTypes : [],
			}
			if (!data.successful) {
				return
			}

			dispatch({
				type: ActionType.GET_SETTINGS,
				payload: settings,
			})
		} catch (err) {
			console.error(err)
		}
	}
}
export const updateSettings = (
	headers: any,
	obj: any,
	setErrorMessage: any,
	setSucessMessage: any,
) => {
	return async (dispatch: Dispatch<Action>) => {
		try {
			const settings = {
				settings: obj,
			}
			const { data } = await AxiosCustom.post(endpoints.SETTINGS, settings, {
				headers,
			})
			if (!data.successful) {
				setErrorMessage(data)
				return
			}
			setSucessMessage(true)
			dispatch({
				type: ActionType.UPDATE_SETTINGS,
				payload: data.payload,
			})
		} catch (err) {
			console.error(err)
		}
	}
}
// --------- USERS OPERATION --------
export const loginAction = (user: loginParams, setErrorMessage: any) => {
	return async (dispatch: Dispatch<Action>) => {
		try {
			const { data } = await AxiosCustom.post(endpoints.LOGIN, {
				user,
			})
			if (!data.successful) {
				setErrorMessage(data.payload)
				return data.payload
			}
			dispatch({
				type: ActionType.LOGIN,
				payload: data.payload.login,
			})
			dispatch({
				type: ActionType.GET_USERS,
				payload: data.payload.users,
			})
			const settings = {
				allowedPlatforms: data.payload.settings[0].allowedPlatforms
					? data.payload.settings[0].allowedPlatforms
					: [],
				vertical: data.payload.settings[0].vertical
					? data.payload.settings[0].vertical
					: [],
				creatorVertical: data.payload.settings[0].creatorVertical
					? data.payload.settings[0].creatorVertical
					: [],
				capabilities: data.payload.settings[0].capabilities
					? data.payload.settings[0].capabilities
					: [],
				salesRepresentative: data.payload.settings[0].salesRepresentative
					? data.payload.settings[0].salesRepresentative
					: [],
				teams: data.payload.settings[0].teams
					? data.payload.settings[0].teams
					: [],
				fraudTools: data.payload.settings[0].fraudTools
					? data.payload.settings[0].fraudTools
					: [],
				platforms: data.payload.settings[0].platforms
					? data.payload.settings[0].platforms
					: [],
				contactRole: data.payload.settings[0].contactRole
					? data.payload.settings[0].contactRole
					: [],
				banners: data.payload.settings[0].banners
					? data.payload.settings[0].banners
					: [],
				videos: data.payload.settings[0].videos
					? data.payload.settings[0].videos
					: [],
				paymentTerms: data.payload.settings[0].paymentTerms
					? data.payload.settings[0].paymentTerms
					: [],
				pauseReason: data.payload.settings[0].pauseReason
					? data.payload.settings[0].pauseReason
					: [],
				trafficRestrictions: data.payload.settings[0].trafficRestrictions
					? data.payload.settings[0].trafficRestrictions
					: [],
				existingChannels: data.payload.settings[0].existingChannels
					? data.payload.settings[0].existingChannels
					: [],
				communicationChannel: data.payload.settings[0].communicationChannel
					? data.payload.settings[0].communicationChannel
					: [],
				targetAudience: data.payload.settings[0].targetAudience
					? data.payload.settings[0].targetAudience
					: [],
				targetAudienceOthers: data.payload.settings[0].targetAudienceOthers
					? data.payload.settings[0].targetAudienceOthers
					: [],
				campaignGoals: data.payload.settings[0].campaignGoals
					? data.payload.settings[0].campaignGoals
					: [],
				currency: data.payload.settings[0].currency
					? data.payload.settings[0].currency
					: [],
				paymentMethod: data.payload.settings[0].paymentMethod
					? data.payload.settings[0].paymentMethod
					: [],
				branches: data.payload.settings[0].branches
					? data.payload.settings[0].branches
					: [],
				vatValues: data.payload.settings[0].vatValues
					? data.payload.settings[0].vatValues
					: [],
				permissionGroups: data.payload.settings[0].permissionGroups
					? data.payload.settings[0].permissionGroups
					: [],
			}
			dispatch({
				type: ActionType.GET_SETTINGS,
				payload: settings,
			})

			data.payload.user.name = capitalizeFirstLetter(data.payload.user.name)
			return true
		} catch (err) {
			return false
		}
	}
}

export const signUpAction = (user: signUpParams, setErrorMessage: any) => {
	return async (dispatch: Dispatch<Action>) => {
		try {
			let { data } = await AxiosCustom.post(endpoints.SIGNUP, { user })

			if (!data.successful) {
				setErrorMessage(data.payload)
				return
			}
		} catch (error) {
			console.error(error)
		}
	}
}

export const resetPasswordAction = (email: string, setErrorMessage: any) => {
	return async (dispatch: Dispatch<Action>) => {
		try {
			let { data } = await AxiosCustom.post(endpoints.RESET_PASSWORD, { email })

			if (!data.successful) {
				setErrorMessage(data.payload)
				return data.successful
			}
		} catch (error) {
			console.error(error)
		}
	}
}

export const resetPasswordWithToken = (
	email: string,
	password: string,
	token: string,
	setErrorMessage: any,
) => {
	return async () => {
		try {
			let { data } = await AxiosCustom.post(
				endpoints.CHANGE_PASSWORD_WITH_TOKEN,
				{ email, password, token },
			)
			if (!data.successful) {
				setErrorMessage(data.payload)
				return data.successful
			}
		} catch (err) {
			console.error(err)
		}
	}
}

export const logOutAction = () => {
	return async (dispatch: Dispatch<Action>) => {
		try {
			dispatch({
				type: ActionType.LOGOUT,
				payload: undefined,
			})
		} catch (err) {
			console.error(err)
		}
	}
}

export const usersAction = (headers: any) => {
	return async (dispatch: Dispatch<Action>) => {
		try {
			let { data } = await AxiosCustom.get(endpoints.USER, {
				headers,
			})
			if (!data.successful) {
				return
			}

			data.payload.forEach((user: UserInterface) => {
				user.name = capitalizeFirstLetter(user.name)
			})
			dispatch({
				type: ActionType.GET_USERS,
				payload: data.payload,
			})
		} catch (err) {
			console.error(err)
		}
	}
}

export const forgotPasswordAction = (email: string, setCodeHash: any) => {
	return async (dispatch: Dispatch<Action>) => {
		try {
			const emailobj = { mail: email }
			let { data } = await AxiosCustom.post(endpoints.FORGOTPASSWORD, emailobj)
			if (!data.successful) {
				return data.payload
			}
			setCodeHash(data.payload)
			// dispatch({
			// 	type: ActionType.GET_USERS,
			// 	payload: data.payload,
			// })
		} catch (err) {
			console.error(err)
		}
	}
}
export const showPasswordAction = (
	headers: any,
	email: string,
	setCodeHash: any,
) => {
	return async (dispatch: Dispatch<Action>) => {
		try {
			const emailobj = { mail: email }
			let { data } = await AxiosCustom.post(endpoints.SHOW_PASSWORD, emailobj, {
				headers,
			})
			if (!data.successful) {
				return
			}
			setCodeHash(data.payload)
			// dispatch({
			// 	type: ActionType.GET_USERS,
			// 	payload: data.payload,
			// })
		} catch (err) {
			console.error(err)
		}
	}
}

export const insertUser = (headers: any, obj: any, setErrorMessage: any) => {
	return async (dispatch: Dispatch<Action>) => {
		try {
			const user = { user: obj }
			const { data } = await AxiosCustom.post(endpoints.REGISTER, user, {
				headers,
			})

			if (!data.successful) {
				setErrorMessage(data.payload)
				return
			}
			dispatch({
				type: ActionType.INSERT_USER,
				payload: obj,
			})
		} catch (err) {
			setErrorMessage(err)
			console.error(err)
		}
	}
}

export const editUser = (headers: any, obj: any, setErrorMessage: any) => {
	return async (dispatch: Dispatch<Action>) => {
		try {
			const user = { user: obj }
			const { data } = await AxiosCustom.put(endpoints.USER, user, {
				headers,
			})

			if (!data.successful) {
				setErrorMessage(data.payload)
				return
			}
			// dispatch({
			// 	type: ActionType.EDIT_USER,
			// 	payload: obj,
			// })
		} catch (err) {
			setErrorMessage(err)
			console.error(err)
		}
	}
}

export const deleteUser = (headers: any, obj: any, setErrorMessage: any) => {
	return async (dispatch: Dispatch<Action>) => {
		try {
			const { data } = await AxiosCustom.delete(endpoints.USER, {
				headers,
				data: {
					user: obj,
				},
			})

			if (!data.successful) {
				setErrorMessage(data.payload)
				return
			}
			dispatch({
				type: ActionType.DELETE_USER,
				payload: obj,
			})
		} catch (err) {
			setErrorMessage(err)
			console.error(err)
		}
	}
}

export const resetPassword = (
	headers: any,
	obj: newPasswordParams,
	setErrorMessage: any,
) => {
	return async (dispatch: Dispatch<Action>) => {
		try {
			const user = { user: obj }
			const { data } = await AxiosCustom.post(endpoints.PASSWORD, user, {
				headers,
			})

			if (!data.successful) {
				setErrorMessage(data.payload)
				return
			}
			dispatch({
				type: ActionType.RESET_PASSWORD,
				payload: obj,
			})
		} catch (err) {
			setErrorMessage(err)
			console.error(err)
		}
	}
}
// ------- APPS OPERATIONS--------
export const getAppsAction = (headers: any) => {
	return async (dispatch: Dispatch<Action>) => {
		try {
			const { data } = await AxiosCustom.get(endpoints.APP, {
				headers,
			})
			if (!data.successful) {
				return
			}
			dispatch({
				type: ActionType.GET_APP,
				payload: data.payload,
			})
		} catch (err) {
			console.error(err)
			// if (
			// 	window.location.pathname !== '/notlogged' &&
			// 	window.location.pathname !== '/'
			// ) {
			// 	window.location.replace(window.location.origin + '/notlogged')
			// }
		}
	}
}
export const getAppsIcons = (headers: any) => {
	return async (dispatch: Dispatch<Action>) => {
		try {
			const { data } = await AxiosCustom.get(endpoints.ICONS, {
				headers,
			})
			if (!data.successful) {
				return
			}
			dispatch({
				type: ActionType.GET_ICONS,
				payload: data.payload,
			})
		} catch (err) {
			console.error(err)
			// if (
			// 	window.location.pathname !== '/notlogged' &&
			// 	window.location.pathname !== '/'
			// ) {
			// 	window.location.replace(window.location.origin + '/notlogged')
			// }
		}
	}
}

export const updateAppP360 = (
	headers: any,
	obj: any,
	setErrorMessage: any,
	setSucessMessage: any,
) => {
	return async (dispatch: Dispatch<Action>) => {
		try {
			const app = { app: obj }
			const { data } = await AxiosCustom.post(endpoints.P360APP, app, {
				headers,
			})
			if (data.payload.successful === false) {
				setErrorMessage(data.payload.payload)
				return data.payload.payload
			}
			setSucessMessage(true)
			dispatch({
				type: ActionType.UPDATE_APP_P360,
				payload: app,
			})
			return
		} catch (err) {
			setErrorMessage(err)
			console.error(err)
		}
	}
}
export const removeAppP360 = (
	headers: any,
	obj: any,
	setErrorMessage: any,
	setSucessMessage: any,
) => {
	return async (dispatch: Dispatch<Action>) => {
		try {
			const app = { app: obj }
			const { data } = await AxiosCustom.post(endpoints.REMOVEP360APP, app, {
				headers,
			})
			if (data.payload.successful === false) {
				setErrorMessage(data.payload.payload)
				return data.payload
			}
			setSucessMessage(true)
			dispatch({
				type: ActionType.REMOVE_APP_P360,
				payload: app,
			})
			return
		} catch (err) {
			setErrorMessage(err)
			console.error(err)
		}
	}
}
export const insertApp = (headers: any, obj: any, setErrorMessage: any) => {
	return async (dispatch: Dispatch<Action>) => {
		try {
			const app = { app: obj }
			const { data } = await AxiosCustom.post(endpoints.APP, app, {
				headers,
			})

			if (!data.successful) {
				setErrorMessage(data.payload)
				return data.payload
			}
			dispatch({
				type: ActionType.ADD_APP,
				payload: obj,
			})
		} catch (err) {
			setErrorMessage(err)
			console.error(err)
		}
	}
}
export const insertP360Rule = (
	headers: any,
	obj: any,
	setErrorMessage: any,
) => {
	return async (dispatch: Dispatch<Action>) => {
		try {
			const app = { app: obj }
			const { data } = await AxiosCustom.post(endpoints.P360APPADD, app, {
				headers,
			})

			if (!data.successful) {
				setErrorMessage(data.payload)
				return data.payload
			}
			dispatch({
				type: ActionType.INSERT_P360_RULE,
				payload: obj,
			})
		} catch (err) {
			setErrorMessage(err)
			console.error(err)
		}
	}
}
export const removeP360Rule = (
	headers: any,
	obj: any,
	setErrorMessage: any,
) => {
	return async (dispatch: Dispatch<Action>) => {
		try {
			const app = { app: obj }
			const { data } = await AxiosCustom.post(endpoints.P360APPREMOVE, app, {
				headers,
			})

			if (!data.successful) {
				setErrorMessage(data.payload)
				return data.payload
			}
			dispatch({
				type: ActionType.REMOVE_P360_RULE,
				payload: obj,
			})
		} catch (err) {
			setErrorMessage(err)
			console.error(err)
		}
	}
}
export const updateAppInfo = (headers: any, obj: any, setErrorMessage: any) => {
	return async (dispatch: Dispatch<Action>) => {
		try {
			const app = { app: obj }
			const { data } = await AxiosCustom.post(endpoints.APPUPDATE, app, {
				headers,
			})

			if (!data.successful) {
				setErrorMessage(data.payload)
				return data.payload
			}
			dispatch({
				type: ActionType.UPDATE_APP_INFO,
				payload: obj,
			})
		} catch (err) {
			setErrorMessage(err)
			console.error(err)
		}
	}
}

export const updateAppsAction = (
	headers: any,
	obj: updateAppParams,
	row: any,
	setErrorMessage: any,
) => {
	return async (dispatch: Dispatch<Action>) => {
		try {
			const app = { app: obj }
			const { data } = await AxiosCustom.put(endpoints.APP, app, {
				headers,
			})
			if (!data.successful) {
				setErrorMessage(data.payload)
				return
			}
			row.status = !row.status

			dispatch({
				type: ActionType.UPDATE_APP,
				payload: row,
			})
		} catch (err) {
			setErrorMessage(err)
			console.error(err)
		}
	}
}
// ------- ADVERTISER OPERATIONS--------

export const getAdvertiserAction = (headers: any) => {
	return async (dispatch: Dispatch<Action>) => {
		try {
			const { data } = await AxiosCustom.get(endpoints.ADVERTISER, {
				headers,
			})

			if (!data.successful) {
				return
			}
			dispatch({
				type: ActionType.GET_ADVERTISER,
				payload: data.payload,
			})
		} catch (err) {
			console.error(err)
			// if (
			// 	window.location.pathname !== '/notlogged' &&
			// 	window.location.pathname !== '/'
			// ) {
			// 	window.location.replace(window.location.origin + '/notlogged')
			// }
		}
	}
}

export const getAdvertiserReport = (
	setDownloadFile: any,
	rows: any,
	headers: any,
) => {
	return async (dispatch: Dispatch<Action>) => {
		try {
			const report = { report: rows }
			const { data } = await AxiosCustom.post(
				endpoints.ADVERTISER + '/report',
				report,
				{
					headers,
				},
			)
			if (!data.successful) {
				return
			}
			// dispatch({
			// 	type: ActionType.GET_ADVERTISER_REPORT,
			// 	payload: data.payload,
			// })
			setDownloadFile(data.payload)
		} catch (err) {
			console.error(err)
		}
	}
}
export const getAdvertiserContacts = (setDownloadFile: any, headers: any) => {
	return async (dispatch: Dispatch<Action>) => {
		try {
			const { data } = await AxiosCustom.get(
				endpoints.ADVERTISER + '/contacts',
				{
					headers,
				},
			)
			if (!data.successful) {
				return
			}
			// dispatch({
			// 	type: ActionType.GET_ADVERTISER_CONTACTS,
			// 	payload: data.payload,
			// })
			setDownloadFile(data.payload)
		} catch (err) {
			console.error(err)
		}
	}
}

export const insertAdvertiserAction = (
	headers: any,
	obj: any,
	setErrorMessage: any,
) => {
	return async (dispatch: Dispatch<Action>) => {
		try {
			const advertiser = { advertiser: obj }
			const { data } = await AxiosCustom.post(
				endpoints.ADVERTISER,
				advertiser,
				{
					headers,
				},
			)

			if (!data.successful) {
				setErrorMessage(data.payload)
				return data.payload
			}
			// const newObj = {
			// 	advertiser_name: obj.advertiser_name,
			// 	email: obj.ownerEmail,
			// 	status: obj.status,
			// 	platforms: obj.platform,
			// 	app: obj.campaigns,
			// }

			dispatch({
				type: ActionType.INSERT_ADVERTISER,
				payload: obj,
			})
		} catch (err) {
			setErrorMessage(err)
		}
	}
}

export const updateAdvertiserAction = (
	headers: any,
	obj: any,
	ruleSet: any,
	setErrorMessage: any,
) => {
	return async (dispatch: Dispatch<Action>) => {
		try {
			const advertiser = { advertiser: obj, rule_set: ruleSet }
			const { data } = await AxiosCustom.put(endpoints.ADVERTISER, advertiser, {
				headers,
			})

			if (!data.successful) {
				setErrorMessage(data.payload)

				return data.payload
			}
			// const newObj = {
			// 	advertiser_name: obj.advertiser_name,
			// 	email: obj.ownerEmail,
			// 	status: obj.status,
			// 	platforms: obj.platform,
			// 	app: obj.campaigns,
			// }

			dispatch({
				type: ActionType.INSERT_ADVERTISER,
				payload: obj,
			})
		} catch (err) {
			setErrorMessage(err)
		}
	}
}
export const deleteAdvertiser = (
	headers: any,
	obj: any,
	setErrorMessage: any,
) => {
	return async (dispatch: Dispatch<Action>) => {
		try {
			const { data } = await AxiosCustom.delete(endpoints.ADVERTISER, {
				headers,
				data: {
					advertiser: obj,
				},
			})
			if (!data.successful) {
				setErrorMessage(data.payload)
				return data.payload
			}

			dispatch({
				type: ActionType.DELETE_ADVERTISER,
				payload: obj,
			})
		} catch (err) {
			setErrorMessage(err)
			console.error(err)
		}
	}
}

// --------- ICONS OPERATION --------

export const deleteIcon = (
	userType: keyof typeof endpoints,
	name: string,
	headers: any,
) => {
	return async (dispatch: Dispatch<Action>) => {
		try {
			const { data } = await AxiosCustom.delete(
				`${endpoints[userType]}/${name}/icon`,
				{
					headers,
				},
			)

			if (data.successful) {
				switch (userType) {
					case 'ADVERTISER':
						dispatch({
							type: ActionType.DELETE_ADVERTISER_ICON,
							payload: { name, iconUrl: null },
						})
						break
					case 'PUBLISHER':
						dispatch({
							type: ActionType.DELETE_PUBLISHER_ICON,
							payload: { name, iconUrl: null },
						})
						break
					case 'CREATOR':
						dispatch({
							type: ActionType.DELETE_CREATOR_ICON,
							payload: { name, iconUrl: null },
						})
						break
					default:
						throw new Error(`Unsupported user type: ${userType}`)
				}

				return data
			} else {
				throw new Error('Failed to delete icon:', data.message)
			}
		} catch (error: any) {
			return { successful: false, payload: error.message }
		}
	}
}
export const uploadIcon = (
	userType: keyof typeof endpoints,
	file: File,
	name: string,
	headers: any,
) => {
	return async (dispatch: Dispatch<Action>) => {
		const formData = new FormData()
		formData.append('file', file)
		formData.append(`${userType.toLowerCase()}_name`, name)

		const config = {
			headers: {
				...headers,
			},
		}

		try {
			const response = await AxiosCustom.post(
				`${endpoints[userType]}/uploadIcon`,
				formData,
				config,
			)

			if (response.data.successful) {
				switch (userType) {
					case 'ADVERTISER':
						dispatch({
							type: ActionType.UPDATE_ADVERTISER_ICON,
							payload: { name, iconUrl: response.data?.iconUrl },
						})
						break
					case 'PUBLISHER':
						dispatch({
							type: ActionType.UPDATE_PUBLISHER_ICON,
							payload: { name, iconUrl: response.data?.iconUrl },
						})
						break
					case 'CREATOR':
						dispatch({
							type: ActionType.UPDATE_CREATOR_ICON,
							payload: { name, iconUrl: response.data?.iconUrl },
						})
						break
					default:
						throw new Error(`Unsupported user type: ${userType}`)
				}
				return response.data.payload
			} else {
				throw new Error(`Failed to Upload file`)
			}
		} catch (error: any) {
			return { successful: false, payload: error.message }
		}
	}
}

// ------- PUBLISHER OPERATIONS--------

export const getPublisherAction = (headers: any) => {
	return async (dispatch: Dispatch<Action>) => {
		try {
			const { data } = await AxiosCustom.get(endpoints.PUBLISHER, {
				headers,
			})
			if (!data.successful) {
				return
			}
			// console.log(data)
			for (const element of data.payload) {
				if (
					typeof element.media_buying_capabilities === 'string' &&
					element.media_buying_capabilities.length > 4
				) {
					element.media_buying_capabilities = JSON.parse(
						element.media_buying_capabilities,
					)
				}
			}
			dispatch({
				type: ActionType.GET_PUBLISER,
				payload: data.payload,
			})
		} catch (err) {
			console.error(err)
			// if (
			// 	window.location.pathname !== '/notlogged' &&
			// 	window.location.pathname !== '/'
			// ) {
			// 	window.location.replace(window.location.origin + '/notlogged')
			// }
		}
	}
}

export const insertPublisherAction = (
	headers: any,
	obj: PublisherInterface,
	handshakeArr: any,
	setErrorMessage: any,
) => {
	return async (dispatch: Dispatch<Action>) => {
		try {
			const publisher = { publisher: obj, handshake: handshakeArr }
			const { data } = await AxiosCustom.post(endpoints.PUBLISHER, publisher, {
				headers,
			})
			if (!data.successful) {
				setErrorMessage(data.payload)
				return data.payload
			}

			dispatch({
				type: ActionType.INSERT_PUBLIHSER,
				payload: obj,
			})
		} catch (err) {
			setErrorMessage(err)
			console.error(err)
		}
	}
}

export const getPublisherReport = (
	setDownloadFile: any,
	rows: any,
	skypeGroupUserAllowed: boolean,
	headers: any,
) => {
	return async (dispatch: Dispatch<Action>) => {
		try {
			const publisher = {
				publisher: {
					skype_private_allowed: skypeGroupUserAllowed,
					report: rows,
				},
			}
			const { data } = await AxiosCustom.post(
				endpoints.PUBLISHER + '/report',
				publisher,
				{
					headers,
				},
			)
			if (!data.successful) {
				return
			}
			dispatch({
				type: ActionType.GET_PUBLISHER_REPORT,
				payload: data.payload,
			})
			setDownloadFile(data.payload)
		} catch (err) {
			console.error(err)
		}
	}
}
export const getPublisherReportPid = (
	setDownloadFile: any,
	rows: any,
	skypeGroupUserAllowed: boolean,
	headers: any,
) => {
	return async (dispatch: Dispatch<Action>) => {
		try {
			const publisher = {
				publisher: {
					skype_private_allowed: skypeGroupUserAllowed,
					report: rows,
				},
			}
			const { data } = await AxiosCustom.post(
				endpoints.PUBLISHER + '/reportpid',
				publisher,
				{
					headers,
				},
			)
			if (!data.successful) {
				return
			}
			dispatch({
				type: ActionType.GET_PUBLISHER_REPORT,
				payload: data.payload,
			})
			setDownloadFile(data.payload)
		} catch (err) {
			console.error(err)
		}
	}
}
export const getPublisherContacts = (setDownloadFile: any, headers: any) => {
	return async (dispatch: Dispatch<Action>) => {
		try {
			const { data } = await AxiosCustom.get(
				endpoints.PUBLISHER + '/contacts',
				{
					headers,
				},
			)
			if (!data.successful) {
				return
			}
			// dispatch({
			// 	type: ActionType.GET_PUBLISHER_CONTACTS,
			// 	payload: data.payload,
			// })
			setDownloadFile(data.payload)
		} catch (err) {
			console.error(err)
		}
	}
}

export const updatePublisherAction = (
	headers: any,
	obj: PublisherInterface,
	handshakeArr: any,
	setErrorMessage: any,
) => {
	return async (dispatch: Dispatch<Action>) => {
		try {
			const objCopy = { ...obj }
			const publisher = { publisher: obj, handshake: handshakeArr }
			const { data } = await AxiosCustom.put(endpoints.PUBLISHER, publisher, {
				headers,
			})
			if (!data.successful) {
				setErrorMessage(data.payload)
				return data.payload
			}

			dispatch({
				type: ActionType.INSERT_PUBLIHSER,
				payload: objCopy,
			})
		} catch (err) {
			setErrorMessage(err)
			console.error(err)
		}
	}
}
export const deletePublisher = (
	headers: any,
	obj: any,
	setErrorMessage: any,
) => {
	return async (dispatch: Dispatch<Action>) => {
		try {
			const { data } = await AxiosCustom.delete(endpoints.PUBLISHER, {
				headers,
				data: {
					publisher: obj,
				},
			})
			if (!data.successful) {
				setErrorMessage(data.payload)
				return
			}

			// dispatch({
			// 	type: ActionType.DELETE_PUBLISHER,
			// 	payload: obj,
			// })
		} catch (err) {
			setErrorMessage(err)
			console.error(err)
		}
	}
}

export const uploadAdFileToStorage = async (headers: any, payload: any) => {
	const obj = { post: payload }
	try {
		const { data } = await AxiosCustom.post(
			endpoints.ADS_PLACEMENT + '/file',
			JSON.stringify(obj),
			{
				headers,
			},
		)
	} catch (err) {
		console.error(err)
	}
}
export const getPublisherWishList = (headers: any, setErrorMessage: any) => {
	return async (dispatch: Dispatch<Action>) => {
		try {
			const { data } = await AxiosCustom.get(endpoints.PUBLISHER_WISHLIST, {
				headers,
			})

			if (!data.successful) {
				setErrorMessage(data.payload)
				return data.payload
			}
			dispatch({
				type: ActionType.GETWISHLIST,
				payload: data.payload,
			})
		} catch (err) {
			setErrorMessage(err)
			console.error(err)
		}
	}
}
export const addPublisherWishList = (
	headers: any,
	obj: any,
	setErrorMessage: any,
) => {
	return async (dispatch: Dispatch<Action>) => {
		try {
			const dataToUpload = { data: obj }
			const { data } = await AxiosCustom.post(
				endpoints.PUBLISHER_WISHLIST,
				dataToUpload,
				{
					headers,
				},
			)

			if (!data.successful) {
				setErrorMessage(data.payload)
				return data.payload
			}
			if (obj.uuid) {
				dispatch({
					type: ActionType.UPDATEWISHLIST,
					payload: obj,
				})
			} else {
				dispatch({
					type: ActionType.ADDWISHLIST,
					payload: obj,
				})
			}
		} catch (err) {
			setErrorMessage(err)
			console.error(err)
		}
	}
}
export const deletePublisherWishList = (
	headers: any,
	obj: any,
	setErrorMessage: any,
) => {
	return async (dispatch: Dispatch<Action>) => {
		try {
			const { data } = await AxiosCustom.post(
				endpoints.PUBLISHER_WISHLIST_DELETE,
				obj,
				{
					headers,
				},
			)

			if (!data.successful) {
				setErrorMessage(data.payload)
				return data.payload
			}
			dispatch({
				type: ActionType.DELETEWISHLIST,
				payload: obj,
			})
		} catch (err) {
			setErrorMessage(err)
			console.error(err)
		}
	}
}

// ------- SALES PLAN OPERATIONS -------
export const getSalesPlanAppsAction = (headers: any) => {
	return async (dispatch: Dispatch<Action>) => {
		try {
			const { data } = await AxiosCustom.get(endpoints.SALES_PLAN, {
				headers,
			})
			if (!data.successful) {
				return
			}
			dispatch({
				type: ActionType.GET_SALES_PLAN_APPS,
				payload: data.payload,
			})
		} catch (err) {
			console.error(err)
			window.location.replace(window.location.origin)
		}
	}
}

export const downloadAppListAction = (
	setFileDownload: any,
	rows: any,
	headers: any,
	setErrorMessage: any,
) => {
	return async (dispatch: Dispatch<Action>) => {
		try {
			const report = { report: rows }
			const { data } = await AxiosCustom.post(
				endpoints.SALES_PLAN + '/download',
				report,
				{
					headers,
				},
			)
			if (!data.successful) {
				setErrorMessage(data.payload)
				return
			}
			setFileDownload(data.payload)
		} catch (err) {
			console.error(err)
			setErrorMessage(err)
		}
	}
}

// ------- CAMPAIGN OPERATIONS--------
export const getCampaignAction = (headers: any) => {
	return async (dispatch: Dispatch<Action>) => {
		try {
			const { data } = await AxiosCustom.get(endpoints.CAMPAIGN, {
				headers,
			})
			if (!data.successful) {
				return
			}

			dispatch({
				type: ActionType.GET_CAMPAIGN,
				payload: data.payload,
			})
		} catch (err) {
			console.error(err)
			// if (
			// 	window.location.pathname !== '/notlogged' &&
			// 	window.location.pathname !== '/'
			// ) {
			// 	window.location.replace(window.location.origin + '/notlogged')
			// }
		}
	}
}

export const reevaluateAction = (headers: any, setErrorMessage: any) => {
	return async (dispatch: Dispatch<Action>) => {
		try {
			const { data } = await AxiosCustom.post(
				endpoints.APPSFLYER + '/reevaluate',
				undefined,
				{
					headers,
				},
			)
			if (!data.successful) {
				//setErrorMessage(data.payload)
				return
			}

			dispatch({
				type: ActionType.REEVALUATE,
				payload: data.payload,
			})
		} catch (err) {
			console.error(err)
			setErrorMessage(err)
		}
	}
}

export const getCampaignsInternalReport = (
	setFileDownload: any,
	rows: any,
	headers: any,
	setErrorMessage: any,
) => {
	return async (dispatch: Dispatch<Action>) => {
		try {
			const report = { report: rows }
			const { data } = await AxiosCustom.post(
				endpoints.CAMPAIGN + '/internalreport',
				report,
				{
					headers,
				},
			)
			if (!data.successful) {
				setErrorMessage(data.payload)
				return
			}
			setFileDownload(data.payload)
		} catch (err) {
			console.error(err)
			setErrorMessage(err)
		}
	}
}
export const getCampaignsExternalWithCostReport = (
	setFileDownload: any,
	rows: any,
	headers: any,
	setErrorMessage: any,
) => {
	return async (dispatch: Dispatch<Action>) => {
		try {
			const report = { report: rows }
			// console.log(report)
			const { data } = await AxiosCustom.post(
				endpoints.CAMPAIGN + '/externalreportwithcost',
				report,
				{
					headers,
				},
			)
			if (!data.successful) {
				setErrorMessage(data.payload)
				return
			}
			setFileDownload(data.payload)
		} catch (err) {
			console.error(err)
			setErrorMessage(err)
		}
	}
}
export const getCampaignsExternalWithoutCostReport = (
	setFileDownload: any,
	rows: any,
	headers: any,
	setErrorMessage: any,
) => {
	return async (dispatch: Dispatch<Action>) => {
		try {
			const report = { report: rows }
			// console.log(report)
			const { data } = await AxiosCustom.post(
				endpoints.CAMPAIGN + '/externalreportwithoutcost',
				report,
				{
					headers,
				},
			)
			if (!data.successful) {
				setErrorMessage(data.payload)
				return
			}
			setFileDownload(data.payload)
		} catch (err) {
			console.error(err)
			setErrorMessage(err)
		}
	}
}

export const insertCampaignAction = (
	headers: any,
	obj: any,
	ruleSet: any,
	setErrorMessage: any,
	setNewUuid: any,
	campaignObject: any,
) => {
	console.log('ss')

	return async (dispatch: Dispatch<Action>) => {
		try {
			const campaign = { campaign: obj, rule_set: ruleSet }
			console.log('>>')

			const { data } = await AxiosCustom.put('campaign', campaign, {
				headers,
			})
			if (!data.successful) {
				setErrorMessage(data.payload)
			}
			if (Array.isArray(data.payload)) {
				for (const element of data.payload) {
					if (!isUuid(element.uuid)) {
						return data.payload
					} else {
						setNewUuid(data.payload)
						// if (campaignObject.uniques[element.role]) {
						// 	campaignObject.uniques[element.role].uuid = element.uuid
						// }
					}
				}
			} else if (isUuid(data.payload)) {
				setNewUuid(data.payload)
			}

			return data.payload
		} catch (err) {
			setErrorMessage(err)
			console.error(err)
		}
	}
}
export const saveCampaignHistoryAction = async (
	headers: any,
	obj: any,
	setErrorMessage: any,
	setNewUuid: any,
	campaignObject: any,
) => {
	try {
		const campaign = { campaign: obj }
		const { data } = await AxiosCustom.put(
			endpoints.CAMPAIGNHISTORY,
			campaign,
			{
				headers,
			},
		)
		if (!data.successful) {
			setErrorMessage(data.payload)
		}

		return data.payload
	} catch (err) {
		setErrorMessage(err)
		console.error(err)
	}
}
export const duplicateCampaignAction = (
	headers: any,
	obj: any,
	ruleSet: any,
	setErrorMessage: any,
	setNewUuid: any,
) => {
	console.log('ss')

	return async (dispatch: Dispatch<Action>) => {
		try {
			const campaign = { campaign: obj, rule_set: ruleSet }
			console.log('???????')

			const { data } = await AxiosCustom.put('campaign/duplicate', campaign, {
				headers,
			})
			if (!data.successful) {
				setErrorMessage(data.payload)
			}
			if (Array.isArray(data.payload)) {
				for (const element of data.payload) {
					if (!isUuid(element.uuid)) {
						return data.payload
					}
				}
				setNewUuid(data.payload)
			} else if (isUuid(data.payload)) {
				setNewUuid(data.payload)
			}

			return data.payload
		} catch (err) {
			setErrorMessage(err)
			console.error(err)
		}
	}
}
export const getCampaignHistoryAction = (headers: any) => {
	return async (dispatch: Dispatch<Action>) => {
		try {
			const { data } = await AxiosCustom.get(endpoints.CAMPAIGNHISTORY, {
				headers,
			})
			if (!data.successful) {
				return
			}

			dispatch({
				type: ActionType.GET_CAMPAIGN_HISTORY,
				payload: data.payload,
			})
		} catch (err) {
			console.error(err)
			// if (
			// 	window.location.pathname !== '/notlogged' &&
			// 	window.location.pathname !== '/'
			// ) {
			// 	window.location.replace(window.location.origin + '/notlogged')
			// }
		}
	}
}
export const getCampaignHistoryByMainDtoAction = (
	headers: any,
	dto: any,
	setHistoryLoading: any,
) => {
	return async (dispatch: Dispatch<Action>) => {
		try {
			const { data } = await AxiosCustom.post(
				endpoints.CAMPAIGNHISTORY,
				{ campaign: dto },
				{
					headers,
				},
			)
			if (!data.successful) {
				return
			}
			setHistoryLoading(false)
			dispatch({
				type: ActionType.GET_CAMPAIGN_HISTORY,
				payload: data.payload,
			})
		} catch (err) {
			console.error(err)
			// if (
			// 	window.location.pathname !== '/notlogged' &&
			// 	window.location.pathname !== '/'
			// ) {
			// 	window.location.replace(window.location.origin + '/notlogged')
			// }
		}
	}
}
export const getCampaignPids = (
	headers: any,
	obj: any,
	setPidList: any,
	setErrorMessage: any,
) => {
	return async (dispatch: Dispatch<Action>) => {
		try {
			const payload = { app_id: obj }
			const { data } = await AxiosCustom.post(endpoints.CAMPAIGN, payload, {
				headers,
			})

			if (!data.successful) {
				setErrorMessage(data.payload)
				return
			}
			setPidList(data.payload)
			dispatch({
				type: ActionType.INSERT_CAMPAIGN,
				payload: data,
			})
		} catch (err) {
			setErrorMessage(err)
			console.error(err)
		}
	}
}

export const deleteCampaign = (
	headers: any,
	obj: any,
	setErrorMessage: any,
) => {
	return async (dispatch: Dispatch<Action>) => {
		try {
			const { data } = await AxiosCustom.delete(endpoints.CAMPAIGN, {
				headers,
				data: {
					campaign: obj,
				},
			})
			if (!data.successful) {
				setErrorMessage(data.payload)
				return
			}
			dispatch({
				type: ActionType.DELETE_CAMPAIGN,
				payload: obj,
			})
		} catch (err) {
			setErrorMessage(err)
			console.error(err)
		}
	}
}

// ------- APPSFLYER OPERATIONS--------
export const getAppsflyerAction = (headers: any, setErrorMessage: any) => {
	return async (dispatch: Dispatch<Action>) => {
		try {
			const { data } = await AxiosCustom.get(endpoints.APPSFLYER, {
				headers,
			})
			if (!data.successful) {
				setErrorMessage(data.payload)
				return
			}
			dispatch({
				type: ActionType.GET_APPSFLYER,
				payload: data.payload,
			})
		} catch (err) {
			setErrorMessage(err)
			console.error(err)
		}
	}
}
export const getRefreshNetworkAction = (headers: any, setErrorMessage: any) => {
	return async (dispatch: Dispatch<Action>) => {
		try {
			const dates: { from: any; to: any } = {
				to: new Date().toISOString().split('T')[0],
				from: new Date(),
			}
			dates.from.setDate(new Date().getDate() - 1)
			dates.from = dates.from.toISOString().split('T')[0]
			const { data } = await AxiosCustom.post(
				endpoints.NETWORK_U,
				{
					network: {
						from_date: dates.from,
						to_date: dates.to,
					},
				},
				{
					headers,
				},
			)
			if (!data.successful) {
				setErrorMessage(data.payload)
				return
			}
			dispatch({
				type: ActionType.UPDATE_NETWORK,
				payload: data.payload,
			})
		} catch (err) {
			setErrorMessage(err)
			console.error(err)
		}
	}
}
export const getRefreshAction = (headers: any, setErrorMessage: any) => {
	return async (dispatch: Dispatch<Action>) => {
		try {
			const { data } = await AxiosCustom.post(
				endpoints.APPSFLYER_U,
				undefined,
				{
					headers,
				},
			)
			if (!data.successful) {
				setErrorMessage(data.payload)
				return
			}
			dispatch({
				type: ActionType.UPDATE_APPSFLYER,
				payload: data.payload,
			})
		} catch (err) {
			setErrorMessage(err)
			console.error(err)
		}
	}
}

export const saveAppsflyerAction = (
	setFileDownload: any,
	headers: any,
	obj: any,
	user_email: any,
) => {
	return async (dispatch: Dispatch<Action>) => {
		try {
			const dataObj = { save: { report: obj, user_email: user_email } }
			const save = JSON.stringify(dataObj)
			const { data } = await AxiosCustom.post(endpoints.SAVE, save, {
				headers,
			})
			if (!data.successful) {
				return
			}
			dispatch({
				type: ActionType.SAVE_APPSFLYER,
				payload: data.payload,
			})
			setFileDownload(data.payload)
		} catch (err) {
			console.error(err)
		}
	}
}

export const getFiltersAction = (
	headers: any,
	obj: ReportFieldsInterface,
	setData: any,
	setErrorPopup: any,
	setLoading: any,
	setIsReportOpen: any,
	report: any,
	setGraph: any,
) => {
	return async (dispatch: Dispatch<Action>) => {
		try {
			setLoading(true)
			const filters = { appsflyer: obj }
			const { data } = await AxiosCustom.post(endpoints.APPSFLYER, filters, {
				headers,
			})
			const timer = Date.now()
			if (!data.successful) {
				setIsReportOpen(false)
				setData(null)
				setGraph(null)
				setErrorPopup(data.payload)
				setLoading(false)
				return
			}

			let resultStatus: any = {}
			const pl = { storage: { id: data.payload } }
			let result: any = {}
			while (resultStatus.successful !== true) {
				try {
					if ((Date.now() - timer) / 1000 > 300) {
						break
					}
					result = await AxiosCustom.post(endpoints.STORAGE, pl, {
						headers,
					})

					if (result.data) {
						resultStatus = result.data
					}
					if (result.data.payload === 'failed') {
						setErrorPopup('Error')
					}
				} catch (err) {
					console.error(err)
				}
				await delay(5000)
			}
			result = await AxiosCustom.post(endpoints.STORAGE + 'get', pl, {
				headers,
			})
			if (result.data.payload === 'pending') {
				setErrorPopup('Error, timeout.')
			}

			// await AxiosCustom.post(endpoints.STORAGE, filters, {
			// 	headers,

			dispatch({
				type: ActionType.SEND_APPSFLYER,
				payload: result.data.payload.report.report,
			})
			setGraph(result.data.payload.report.graph)
			setData(result.data.payload.report.report)
			setIsReportOpen(true)

			// setData(data.payload)
			// setIsReportOpen(true)
			setLoading(false)
		} catch (err) {
			// setIsReportOpen(!!report)
			// setData(null)
			setErrorPopup(err)
			setLoading(false)
			console.error(err)
		}
	}
}
//-------- ADJUST ---------------
export const getAdjustFiltersAction = (
	headers: any,
	obj: ReportFieldsInterface,
	setData: any,
	setErrorPopup: any,
	setLoading: any,
	setIsReportOpen: any,
	report: any,
	setGraph: any,
) => {
	return async (dispatch: Dispatch<Action>) => {
		try {
			setLoading(true)
			const filters = { adjust: obj }
			const { data } = await AxiosCustom.post(endpoints.ADJUST, filters, {
				headers,
			})
			const timer = Date.now()
			if (!data.successful) {
				setIsReportOpen(false)
				setData(null)
				setGraph(null)
				setErrorPopup(data.payload)
				setLoading(false)
				return
			}

			let resultStatus: any = {}
			const pl = { storage: { id: data.payload } }
			let result: any = {}
			while (resultStatus.successful !== true) {
				try {
					if ((Date.now() - timer) / 1000 > 300) {
						break
					}
					result = await AxiosCustom.post(endpoints.STORAGE, pl, {
						headers,
					})

					if (result.data) {
						resultStatus = result.data
					}
					if (result.data.payload === 'failed') {
						setErrorPopup('Error')
					}
				} catch (err) {
					console.error(err)
				}
				await delay(5000)
			}
			result = await AxiosCustom.post(endpoints.STORAGE + 'get', pl, {
				headers,
			})
			if (result.data.payload === 'pending') {
				setErrorPopup('Error, timeout.')
			}

			// await AxiosCustom.post(endpoints.STORAGE, filters, {
			// 	headers,

			dispatch({
				type: ActionType.SEND_APPSFLYER,
				payload: result.data.payload.report.report,
			})

			setGraph(result.data.payload.report.graph)
			setData(result.data.payload.report)
			setIsReportOpen(true)

			// setData(data.payload)
			// setIsReportOpen(true)
			setLoading(false)
		} catch (err) {
			// setIsReportOpen(!!report)
			// setData(null)
			setErrorPopup(err)
			setLoading(false)
			console.error(err)
		}
	}
}
// ------- APP OPERATIONS--------
export const getAppsByAdvertiserAction = (headers: any, advertiser: string) => {
	return async (dispatch: Dispatch<Action>) => {
		try {
			const { data } = await AxiosCustom.get(endpoints.APP + '/' + advertiser, {
				headers,
			})

			if (!data.successful) {
				return
			}

			dispatch({
				type: ActionType.GET_APP,
				payload: data.payload,
			})
		} catch (err) {
			console.error(err)
		}
	}
}

export const getEventsByAppsAction = (
	headers: any,
	appId: string,
	setEventList: any,
) => {
	return async (dispatch: Dispatch<Action>) => {
		try {
			const { data } = await AxiosCustom.get(
				endpoints.APPSFLYER + '/' + appId,
				{
					headers,
				},
			)
			console.log(data)
			if (!data.successful) {
				return
			}

			setEventList(data.payload)
		} catch (err) {
			console.error(err)
		}
	}
}

export function delay(milliseconds: number) {
	return new Promise((resolve) => {
		setTimeout(resolve, milliseconds)
	})
}
//------------NAVIGATION ACTIONS---------------//

export const navigationAction = (data: any) => {
	return async (dispatch: Dispatch<Action>) => {
		try {
			dispatch({
				type: ActionType.NAVIGATION,
				payload: data,
			})
		} catch (err) {
			console.error(err)
		}
	}
}

//------------PRESEARCH ACTIONS---------------//

export const presearchAction = (data: any) => {
	return async (dispatch: Dispatch<Action>) => {
		try {
			dispatch({
				type: ActionType.PRESEARCH,
				payload: data,
			})
		} catch (err) {
			console.error(err)
		}
	}
}

//------------PRESETS ACTIONS---------------//
export const getPresetsAction = (headers: any, user: string) => {
	return async (dispatch: Dispatch<Action>) => {
		try {
			const url = endpoints.PRESET + (user !== undefined ? '/' + user : '')
			const { data } = await AxiosCustom.get(url, {
				headers,
			})
			dispatch({
				type: ActionType.PRESET,
				payload: data.payload,
			})
		} catch (err) {
			console.error(err)
		}
	}
}
export const updatePresetAction = (headers: any, obj: any) => {
	return async (dispatch: Dispatch<Action>) => {
		try {
			const preset = { preset: obj }
			const { data } = await AxiosCustom.put(endpoints.PRESET, preset, {
				headers,
			})
			dispatch({
				type: ActionType.PRESET,
				payload: data.payload,
			})
		} catch (err) {
			console.error(err)
		}
	}
}

export const getp360aggregated = (headers: any, obj: any) => {
	return async (dispatch: Dispatch<Action>) => {
		try {
			const { data } = await AxiosCustom.get(endpoints.p360aggregated, {
				headers,
			})
			dispatch({
				type: ActionType.P360AGGREGATED,
				payload: data.payload,
			})
		} catch (err) {
			console.error(err)
		}
	}
}
export const deletePresetAction = (headers: any, obj: any) => {
	return async (dispatch: Dispatch<Action>) => {
		try {
			const preset = { preset: obj }
			const { data } = await AxiosCustom.put(
				endpoints.PRESET + 'delete',
				preset,
				{
					headers,
				},
			)
			dispatch({
				type: ActionType.PRESET,
				payload: data.payload,
			})
		} catch (err) {
			console.error(err)
		}
	}
}

//---------------NETWORK ACTIONS----------------//

export const getNetworkAction = (headers: any) => {
	return async (dispatch: Dispatch<Action>) => {
		try {
			const { data } = await AxiosCustom.get(endpoints.NETWORK, {
				headers,
			})
			if (!data.successful) {
				return
			}
			dispatch({
				type: ActionType.GET_NETWORK,
				payload: data.payload,
			})
		} catch (err) {
			console.error(err)
		}
	}
}

export const postNetworkAction = (headers: any, obj: any) => {
	return async (dispatch: Dispatch<Action>) => {
		try {
			const payload = { network: obj }
			const { data } = await AxiosCustom.post(endpoints.NETWORK, payload, {
				headers,
			})
			if (!data.successful) {
				return
			}
			dispatch({
				type: ActionType.POST_NETWORK,
				payload: data.payload,
			})
		} catch (err) {
			console.error(err)
		}
	}
}

export const getNetworksFiltersAction = (
	headers: any,
	obj: NetworkFieldsInterface,
	setData: any,
	setErrorPopup: any,
	setLoading: any,
	setIsReportOpen: any,
) => {
	return async (dispatch: Dispatch<Action>) => {
		try {
			setLoading(true)
			const filters = { network: obj }
			const { data } = await AxiosCustom.post(endpoints.NETWORK, filters, {
				headers,
			})
			if (!data.successful) {
				setIsReportOpen(false)
				setData(null)
				setErrorPopup(data.payload)
				setLoading(false)
				return
			}
			const timer = Date.now()
			let resultStatus: any = {}
			const pl = { storage: { id: data.payload } }
			let result: any = {}
			while (resultStatus.successful !== true) {
				try {
					if ((Date.now() - timer) / 1000 > 300) {
						break
					}
					result = await AxiosCustom.post(endpoints.STORAGE, pl, {
						headers,
					})

					if (result.data) {
						resultStatus = result.data
					}
				} catch (err) {
					console.error(err)
				}
				await delay(5000)
			}
			if (result.data.payload === 'pending') {
				setErrorPopup('Error, timeout.')
			}
			result = await AxiosCustom.post(endpoints.STORAGE + 'get', pl, {
				headers,
			})
			dispatch({
				type: ActionType.POST_NETWORK,
				payload: result.data.payload.report,
			})

			setData(result.data.payload.report)
			setIsReportOpen(true)
			setLoading(false)
		} catch (err) {
			setIsReportOpen(false)
			setData(null)
			setErrorPopup(err)
			setLoading(false)
			console.error(err)
		}
	}
}

export const getRawdataReportAction = (
	headers: any,
	obj: any,
	setData: any,
	setErrorPopup: any,
	setLoading: any,
	setIsReportOpen: any,
) => {
	return async (dispatch: Dispatch<Action>) => {
		try {
			setLoading(true)
			const filters = { rawdata: obj }
			const { data } = await AxiosCustom.post(endpoints.RAWDATA, filters, {
				headers,
			})

			const timer = Date.now()
			if (!data.successful) {
				setIsReportOpen(false)
				setData(null)
				setErrorPopup(data.payload)
				setLoading(false)
				return
			}

			let resultStatus: any = {}
			const pl = { storage: { id: data.payload } }
			let result: any = {}
			while (resultStatus.successful !== true) {
				try {
					if ((Date.now() - timer) / 1000 > 300) {
						break
					}
					result = await AxiosCustom.post(endpoints.STORAGE, pl, {
						headers,
					})

					if (result.data) {
						resultStatus = result.data
					}
				} catch (err) {
					console.error(err)
				}
				await delay(5000)
			}
			result = await AxiosCustom.post(endpoints.STORAGE + 'get', pl, {
				headers,
			})
			// await AxiosCustom.post(endpoints.STORAGE, filters, {
			// 	headers,
			// })
			dispatch({
				type: ActionType.POST_RAWDATA,
				payload: result.data.payload.report,
			})

			setData(result.data.payload.report)
			setIsReportOpen(true)
		} catch (err) {
			setIsReportOpen(false)
			setData(null)
			setErrorPopup(err)
			setLoading(false)
			console.error(err)
		}
	}
}

export const getRawdataRefreshAction = (headers: any, setErrorMessage: any) => {
	return async (dispatch: Dispatch<Action>) => {
		try {
			const obf = {
				rawdata: {
					from_date: subDays(new Date(), 1),
					to_date: new Date(),
				},
			}
			const { data } = await AxiosCustom.post(
				endpoints.RAWDATA + '/update',
				obf,
				{
					headers,
				},
			)
			if (!data.successful) {
				setErrorMessage(data.payload)
				return
			}
			dispatch({
				type: ActionType.GET_RAWDATA,
				payload: data.payload,
			})
		} catch (err) {
			setErrorMessage(err)
			console.error(err)
		}
	}
}

export const getUpdatedAtAction = (
	headers: any,
	report: string,
	setLastRefreshed: any,
) => {
	return async (dispatch: Dispatch<Action>) => {
		try {
			const payload = { update: { report: report } }
			const { data } = await AxiosCustom.get(endpoints.UPDATE + '/' + report, {
				headers,
			})
			if (!data.successful) {
				return
			}

			setLastRefreshed(data.payload.updatedAt)
			dispatch({
				type: ActionType.UPDATE,
				payload: data.payload,
			})
		} catch (err) {
			console.error(err)
		}
	}
}

export const getPasswordsAction = (headers: any) => {
	return async (dispatch: Dispatch<Action>) => {
		try {
			const { data } = await AxiosCustom.get(endpoints.PASSWORDS, {
				headers,
			})
			if (!data.successful) {
				return
			}

			dispatch({
				type: ActionType.GETPASSWORDS,
				payload: data.payload,
			})
		} catch (err) {
			console.error(err)
		}
	}
}
export const updatePasswordsAction = (
	headers: any,
	payload: any,
	setErrorMessage: any,
) => {
	return async (dispatch: Dispatch<Action>) => {
		try {
			const { data } = await AxiosCustom.post(endpoints.PASSWORDS, payload, {
				headers,
			})
			if (!data.successful) {
				setErrorMessage(data.payload)
				return data.payload
			}

			dispatch({
				type: ActionType.GETPASSWORDS,
				payload: data.payload,
			})
		} catch (err) {
			console.error(err)
		}
	}
}
export const deletePasswordsAction = (headers: any, payload: any) => {
	return async (dispatch: Dispatch<Action>) => {
		try {
			const { data } = await AxiosCustom.post(
				endpoints.PASSWORDS + 'delete',
				payload,
				{
					headers,
				},
			)
			if (!data.successful) {
				return
			}

			dispatch({
				type: ActionType.GETPASSWORDS,
				payload: data.payload,
			})
		} catch (err) {
			console.error(err)
		}
	}
}
//===========  invalid

export const getInvalidAction = (
	headers: any,
	obj: ReportFieldsInterface,
	setData: any,
	setErrorPopup: any,
	setLoading: any,
	setIsReportOpen: any,
) => {
	return async (dispatch: Dispatch<Action>) => {
		try {
			setLoading(true)
			const filters = { invalid: obj }
			const { data } = await AxiosCustom.post(endpoints.INVALID, filters, {
				headers,
			})
			const timer = Date.now()
			if (!data.successful) {
				setIsReportOpen(false)
				setData(null)
				setErrorPopup(data.payload)
				setLoading(false)
				return
			}

			let resultStatus: any = {}
			const pl = { storage: { id: data.payload } }
			let result: any = {}
			while (resultStatus.successful !== true) {
				try {
					if ((Date.now() - timer) / 1000 > 300) {
						break
					}
					result = await AxiosCustom.post(endpoints.STORAGE, pl, {
						headers,
					})

					if (result.data) {
						resultStatus = result.data
					}
				} catch (err) {
					console.error(err)
				}
				await delay(5000)
			}
			if (result.data.payload === 'pending') {
				setErrorPopup('Error, timeout.')
			}
			result = await AxiosCustom.post(endpoints.STORAGE + 'get', pl, {
				headers,
			})

			// await AxiosCustom.post(endpoints.STORAGE, filters, {
			// 	headers,
			// })
			dispatch({
				type: ActionType.SEND_APPSFLYER,
				payload: result.data.payload.report,
			})

			setData(result.data.payload.report)
			setIsReportOpen(true)

			// setData(data.payload)
			// setIsReportOpen(true)
			setLoading(false)
		} catch (err) {
			setIsReportOpen(false)
			setData(null)
			setErrorPopup(err)
			setLoading(false)
			console.error(err)
		}
	}
}

// ==========accounts for appsflyer CRON ====================

export const getAccountsAction = (headers: any) => {
	return async (dispatch: Dispatch<Action>) => {
		try {
			const { data } = await AxiosCustom.get(endpoints.APPSFLYER_ACCOUNTS, {
				headers,
			})
			if (!data.successful) {
				return
			}

			dispatch({
				type: ActionType.GETACCOUNTS,
				payload: data.payload,
			})
		} catch (err) {
			console.error(err)
		}
	}
}
export const putAvatar = async (headers: any, payload: any) => {
	try {
		const obj = { avatar: payload }
		await AxiosCustom.put(endpoints.AVATAR, obj, {
			headers,
		})
		await new Promise((resolve) => setTimeout(resolve, 10000))
	} catch (err) {
		console.error(err)
	}
}
function blobToBase64(blob: Blob) {
	return new Promise((resolve, _) => {
		const reader = new FileReader()
		reader.onloadend = () => resolve(reader.result)
		reader.readAsDataURL(blob)
	})
}
const arrayBufferToBase64 = (buffer: any) => {
	var binary = ''
	var bytes = [].slice.call(new Uint8Array(buffer))
	bytes.forEach((b) => (binary += String.fromCharCode(b)))
	return window.btoa(binary)
}
export const getAvatars = (headers: any) => {
	return async (dispatch: Dispatch<Action>) => {
		try {
			// const { data } = await AxiosCustom.get(endpoints.AVATAR, {
			// 	headers,
			// })
			let { data } = await AxiosCustom.get(endpoints.USER, {
				headers,
			})
			// console.log(data)
			// data.payload.forEach((user: UserInterface) => {
			// 	user.name = capitalizeFirstLetter(user.name)
			// })
			let imageArray: any = {}
			for (let user of data.payload) {
				if (user.image_uuid) {
					const respomse = await fetch(
						'https://varys-avatar-storage.s3.eu-central-1.amazonaws.com/' +
							user.image_uuid,
					)

					const arrayBuffer = await respomse.arrayBuffer()
					const url = arrayBufferToBase64(arrayBuffer)

					//
					// let srcFnal = Buffer.from(url as any).toString('base64')
					let srcFnal = url
						.replace('data', 'data:')
						.replace('base64', ';base64,')

					imageArray[user.name] = srcFnal
				}
			}
			dispatch({
				type: ActionType.AVATAR,
				payload: imageArray,
			})
		} catch (err) {
			console.error(err)
		}
	}
}
export const addAccountsAction = (headers: any, payload: any) => {
	return async (dispatch: Dispatch<Action>) => {
		try {
			const { data } = await AxiosCustom.post(
				endpoints.APPSFLYER_ACCOUNTS,
				payload,
				{
					headers,
				},
			)
			if (!data.successful) {
				return
			}

			dispatch({
				type: ActionType.GETACCOUNTS,
				payload: data.payload,
			})
		} catch (err) {
			console.error(err)
		}
	}
}
export const updateAccountsAction = (headers: any, payload: any) => {
	return async (dispatch: Dispatch<Action>) => {
		try {
			const { data } = await AxiosCustom.put(
				endpoints.APPSFLYER_ACCOUNTS,
				payload,
				{
					headers,
				},
			)
			if (!data.successful) {
				return
			}

			dispatch({
				type: ActionType.GETACCOUNTS,
				payload: data.payload,
			})
		} catch (err) {
			console.error(err)
		}
	}
}
export const deleteAccountsAction = (headers: any, payload: any) => {
	return async (dispatch: Dispatch<Action>) => {
		try {
			const { data } = await AxiosCustom.post(
				endpoints.APPSFLYER_ACCOUNTS + 'delete',
				payload,
				{
					headers,
				},
			)
			if (!data.successful) {
				return
			}

			dispatch({
				type: ActionType.GETACCOUNTS,
				payload: data.payload,
			})
		} catch (err) {
			console.error(err)
		}
	}
}
// ==========FINANCE ======================
export const downloadFinanceTemplateAction = (headers: any) => {
	return async (dispatch: Dispatch<Action>) => {
		try {
			const { data } = await AxiosCustom.get(
				endpoints.FINANCE + '/downloadtemplate',
				{
					headers,
				},
			)
			if (data.successful) {
				return data.payload
			}
		} catch (err) {
			console.error(err)
		}
	}
}
export const downloadFinanceMBTemplateAction = (headers: any) => {
	return async (dispatch: Dispatch<Action>) => {
		try {
			const { data } = await AxiosCustom.get(
				endpoints.FINANCE + '/downloadmediabuyingtemplate',
				{
					headers,
				},
			)
			if (data.successful) {
				return data.payload
			}
		} catch (err) {
			console.error(err)
		}
	}
}
export const downloadFinanceCreatorTemplateAction = (
	headers: any,
	mainUuid: string,
) => {
	return async (dispatch: Dispatch<Action>) => {
		try {
			const { data } = await AxiosCustom.get(
				endpoints.FINANCE + '/downloadcreatortemplate/' + mainUuid,
				{
					headers,
				},
			)
			if (data.successful) {
				return data.payload
			}
		} catch (err) {
			console.error(err)
		}
	}
}
export const downloadFinanceFilledTemplateAction = (
	headers: any,
	rows: any,
) => {
	return async (dispatch: Dispatch<Action>) => {
		try {
			delete rows.id
			delete rows.isNew
			delete rows.hierarchy
			const dataToSave = { rows: rows }
			const obj = { data: dataToSave }
			const { data } = await AxiosCustom.post(
				endpoints.FINANCE + '/downloadfilledtemplate',
				obj,
				{
					headers,
				},
			)
			if (data.successful) {
				return data.payload
			}
		} catch (err) {
			console.error(err)
		}
	}
}
export const downloadFinanceFilledCreatorTemplateAction = (
	headers: any,
	rows: any,
	uuid: string,
) => {
	return async (dispatch: Dispatch<Action>) => {
		try {
			delete rows.id
			delete rows.isNew
			delete rows.hierarchy
			const dataToSave = { rows: rows }
			const obj = { data: dataToSave, uuid: uuid }
			const { data } = await AxiosCustom.post(
				endpoints.FINANCE + '/downloadfilledcreatortemplate',
				obj,
				{
					headers,
				},
			)
			if (data.successful) {
				return data.payload
			}
		} catch (err) {
			console.error(err)
		}
	}
}
export const uploadFinanceTemplate = (
	headers: any,
	payload: any,
	setErrorMessage: any,
) => {
	return async (dispatch: Dispatch<Action>) => {
		try {
			const updatedHeaders = {
				...headers,
				Accept: 'application/json',
				'Content-Type': 'multipart/form-data',
			}
			const { data } = await AxiosCustom.post(
				endpoints.FINANCE + '/uploadtemplate',
				payload,
				{
					headers: updatedHeaders,
				},
			)
			// console.log(data)
			if (!data.successful) {
				setErrorMessage(data.payload)
				return data.payload
			}

			// dispatch({
			// 	type: ActionType.UPLOAD_FINANCE,
			// 	payload: data.payload,
			// })
		} catch (err) {
			console.error(err)
		}
	}
}
export const uploadFinanceMBTemplate = (
	headers: any,
	payload: any,
	setErrorMessage: any,
) => {
	return async (dispatch: Dispatch<Action>) => {
		try {
			const updatedHeaders = {
				...headers,
				Accept: 'application/json',
				'Content-Type': 'multipart/form-data',
			}
			const { data } = await AxiosCustom.post(
				endpoints.FINANCE + '/uploadmediabuyingtemplate',
				payload,
				{
					headers: updatedHeaders,
				},
			)
			// console.log(data)
			if (!data.successful) {
				setErrorMessage(data.payload)
				return data.payload
			}

			// dispatch({
			// 	type: ActionType.UPLOAD_FINANCE,
			// 	payload: data.payload,
			// })
		} catch (err) {
			console.error(err)
		}
	}
}
export const uploadFinanceControlTemplate = (
	headers: any,
	payload: any,
	setErrorMessage: any,
) => {
	return async (dispatch: Dispatch<Action>) => {
		try {
			const updatedHeaders = {
				...headers,
				Accept: 'application/json',
				'Content-Type': 'multipart/form-data',
			}
			const { data } = await AxiosCustom.post(
				endpoints.FINANCE + '/uploadcontroltemplate',
				payload,
				{
					headers: updatedHeaders,
				},
			)
			// console.log(data)
			if (!data.successful) {
				setErrorMessage(data.payload)
				return data.payload
			}

			// dispatch({
			// 	type: ActionType.UPLOAD_FINANCE,
			// 	payload: data.payload,
			// })
		} catch (err) {
			console.error(err)
		}
	}
}
export const uploadFinanceCreatorTemplate = (
	headers: any,
	payload: any,
	setErrorMessage: any,
) => {
	return async (dispatch: Dispatch<Action>) => {
		try {
			const updatedHeaders = {
				...headers,
				Accept: 'application/json',
				'Content-Type': 'multipart/form-data',
			}
			const { data } = await AxiosCustom.post(
				endpoints.FINANCE + '/uploadcreatortemplate',
				payload,
				{
					headers: updatedHeaders,
				},
			)
			// console.log(data)
			if (!data.successful) {
				setErrorMessage(data.payload)
				return data.payload
			}

			// dispatch({
			// 	type: ActionType.UPLOAD_FINANCE,
			// 	payload: data.payload,
			// })
		} catch (err) {
			console.error(err)
		}
	}
}
export const saveProofFinance = (
	headers: any,
	payload: any,
	refresh: any,
	setErrorMessage: any,
	setLoading: any,
) => {
	return async (dispatch: Dispatch<Action>) => {
		try {
			setLoading(true)
			console.log(payload)
			const updatedHeaders = {
				...headers,
				Accept: 'application/json',
				'Content-Type': 'multipart/form-data',
			}
			const { data } = await AxiosCustom.post(
				endpoints.FINANCE + '/uploadprooffinance',
				payload,
				{
					headers: updatedHeaders,
				},
			)
			if (!data.successful) {
				setErrorMessage(data.payload)
				return data.payload
			}
			dispatch({
				type: ActionType.UPLOAD_PROOF_FINANCE,
				payload: data.payload,
			})
			refresh()
			setLoading(false)
		} catch (err) {
			console.error(err)
		}
	}
}
export const uploadFinanceRow = (
	headers: any,
	payload: any,
	email: string,
	setErrorMessage: any,
) => {
	return async (dispatch: Dispatch<Action>) => {
		try {
			delete payload.new_row.id
			delete payload.new_row.isNew
			delete payload.new_row.hierarchy
			payload.new_row.email_for_logs = email

			const obj = { row: payload }
			const { data } = await AxiosCustom.post(
				endpoints.FINANCE + '/updaterow',
				obj,
				{
					headers,
				},
			)
			if (!data.successful) {
				setErrorMessage(data.payload)
				return data.payload
			} else {
				payload.new_row.final_amount =
					payload.new_row.cost - payload.new_row.deduction_amount
				dispatch({
					type: ActionType.UPLOAD_FINANCE,
					payload: data.payload,
				})
			}
		} catch (err) {
			console.error(err)
		}
	}
}
export const uploadControlFinanceRow = (
	headers: any,
	payload: any,
	email: string,
	setErrorMessage: any,
) => {
	return async (dispatch: Dispatch<Action>) => {
		try {
			delete payload.new_row.id
			delete payload.new_row.isNew
			delete payload.new_row.hierarchy
			payload.new_row.email_for_logs = email

			const obj = { row: payload }
			const { data } = await AxiosCustom.post(
				endpoints.FINANCE + '/updatecontrolrow',
				obj,
				{
					headers,
				},
			)
			if (!data.successful) {
				setErrorMessage(data.payload)
				return data.payload
			} else {
				payload.new_row.final_amount =
					payload.new_row.cost - payload.new_row.deduction_amount
				dispatch({
					type: ActionType.UPLOAD_FINANCE,
					payload: data.payload,
				})
			}
		} catch (err) {
			console.error(err)
		}
	}
}
export const uploadMBFinanceRow = (
	headers: any,
	payload: any,
	email: string,
	setErrorMessage: any,
) => {
	return async (dispatch: Dispatch<Action>) => {
		try {
			delete payload.new_row.id
			delete payload.new_row.isNew
			delete payload.new_row.hierarchy
			payload.new_row.email_for_logs = email
			const obj = { row: payload }
			const { data } = await AxiosCustom.post(
				endpoints.FINANCE + '/updatemediabuyingrow',
				obj,
				{
					headers,
				},
			)
			if (!data.successful) {
				setErrorMessage(data.payload)
				return data.payload
			} else {
				payload.new_row.final_amount =
					payload.new_row.cost - payload.new_row.deduction_amount
				dispatch({
					type: ActionType.UPLOAD_FINANCE,
					payload: data.payload,
				})
			}
		} catch (err) {
			console.error(err)
		}
	}
}
export const uploadFinanceCreatorRow = (
	headers: any,
	payload: any,
	email: string,
	setErrorMessage: any,
) => {
	return async (dispatch: Dispatch<Action>) => {
		try {
			delete payload.new_row.id
			delete payload.new_row.isNew
			delete payload.new_row.hierarchy
			payload.new_row.email_for_logs = email

			const obj = { row: payload }
			const { data } = await AxiosCustom.post(
				endpoints.FINANCE + '/updatecreatorrow',
				obj,
				{
					headers,
				},
			)
			if (!data.successful) {
				setErrorMessage(data.payload)
				return data.payload
			} else {
				payload.new_row.final_amount = payload.new_row.cost
				dispatch({
					type: ActionType.UPLOAD_FINANCE,
					payload: data.payload,
				})
			}
		} catch (err) {
			console.error(err)
		}
	}
}
export const uploadFinancePublisher = (
	headers: any,
	payload: any,
	type: string,
	email: string,
	setErrorMessage: any,
) => {
	return async (dispatch: Dispatch<Action>) => {
		try {
			delete payload.id
			delete payload.isNew
			delete payload.hierarchy
			payload.email_for_logs = email
			const obj = { row: payload, type }
			console.log('payload', obj)
			const { data } = await AxiosCustom.post(
				endpoints.FINANCE + '/updatefinancepublisherrow',
				obj,
				{
					headers,
				},
			)
			// console.log(data)
			if (!data.successful) {
				setErrorMessage(data.payload)
				return data.payload
			} else {
				// dispatch({
				// 	type: ActionType.UPLOAD_FINANCE,
				// 	payload: payload,
				// })
			}
		} catch (err) {
			console.error(err)
		}
	}
}
export const uploadRollOverFinance = (
	headers: any,
	payload: any,
	email: string,
	setErrorMessage: any,
) => {
	return async (dispatch: Dispatch<Action>) => {
		try {
			delete payload.id
			delete payload.isNew
			delete payload.hierarchy
			payload.email_for_logs = email
			const obj = { row: payload }
			console.log('payload', obj)
			const { data } = await AxiosCustom.post(
				endpoints.FINANCE + '/uploadfinancerollover',
				obj,
				{
					headers,
				},
			)
			// console.log(data)
			if (!data.successful) {
				setErrorMessage(data.payload)
				return data.payload
			} else {
				dispatch({
					type: ActionType.UPLOAD_FINANCE_ROLLOVER,
					payload: payload,
				})
			}
		} catch (err) {
			console.error(err)
		}
	}
}
export const uploadFinanceDemand = (
	headers: any,
	payload: any,
	email: string,
	setErrorMessage: any,
) => {
	return async (dispatch: Dispatch<Action>) => {
		try {
			delete payload.id
			delete payload.isNew
			delete payload.hierarchy
			payload.email_for_logs = email
			const obj = { row: payload }
			// console.log('payload', obj)
			const { data } = await AxiosCustom.post(
				endpoints.FINANCE + '/updatefinancedemandrow',
				obj,
				{
					headers,
				},
			)
			if (!data.successful) {
				setErrorMessage(data.payload)
				return data.payload
			} else {
				dispatch({
					type: ActionType.UPLOAD_FINANCE_DEMAND,
					payload: payload,
				})
			}
		} catch (err) {
			console.error(err)
		}
	}
}
export const uploadInvoiceDemand = (
	headers: any,
	payload: any,
	email: string,
	setErrorMessage: any,
) => {
	return async (dispatch: Dispatch<Action>) => {
		try {
			// delete payload.new_row.id
			delete payload.new_row.isNew
			delete payload.new_row.hierarchy
			payload.new_row.email_for_logs = email

			const obj = { row: payload }
			const { data } = await AxiosCustom.post(
				endpoints.FINANCE + '/updateinvoice',
				obj,
				{
					headers,
				},
			)
			if (!data.successful) {
				setErrorMessage(data.payload)
				return data
			} else {
				payload.new_row.final_amount =
					payload.new_row.cost - payload.new_row.deduction_amount
				console.log('data from upate', data.payload)
				dispatch({
					type: ActionType.UPLOAD_FINANCE,
					payload: data.payload,
				})
				return data
			}
		} catch (err) {
			console.error(err)
		}
	}
}
export const removeInvoiceDemand = (
	headers: any,
	payload: any,
	email: string,
	setErrorMessage: any,
) => {
	return async (dispatch: Dispatch<Action>) => {
		try {
			console.log('deleting', payload)
			const obj = { row: payload }
			const { data } = await AxiosCustom.post(
				endpoints.FINANCE + '/removeinvoice',
				obj,
				{
					headers,
				},
			)
			if (!data.successful) {
				setErrorMessage(data.payload)
				return data.payload
			} else {
				// payload.new_row.final_amount =
				// 	payload.new_row.cost - payload.new_row.deduction_amount
				dispatch({
					type: ActionType.UPLOAD_FINANCE,
					payload: data.payload,
				})
			}
			return data.payload
		} catch (err) {
			console.error(err)
		}
	}
}
export const updateInvoiceInstructionsFinance = (
	headers: any,
	payload: any,
	type: string,
	refreshFunc: any,
	setErrorMessage: any,
) => {
	return async (dispatch: Dispatch<Action>) => {
		try {
			const obj = { data: payload, type }
			// console.log('payload', obj)
			const { data } = await AxiosCustom.post(
				endpoints.FINANCE + '/updateinvoiceinstructions',
				obj,
				{
					headers,
				},
			)
			// console.log(data)
			if (!data.successful) {
				setErrorMessage(data.payload)
				return data.payload
			} else {
				dispatch({
					type: ActionType.UPLOAD_INSTRUCTIONS_FINANCE,
					payload: data.payload,
				})
				refreshFunc()
			}
		} catch (err) {
			console.error(err)
		}
	}
}
export const getProofImageFinance = (
	headers: any,
	payload: any,
	type: string,
	setImageSrc: any,
	setErrorMessage: any,
) => {
	return async (dispatch: Dispatch<Action>) => {
		try {
			const obj = { data: payload, type }
			const { data } = await AxiosCustom.post(
				endpoints.FINANCE + '/getproofimage',
				obj,
				{
					headers,
				},
			)
			if (!data.successful) {
				setErrorMessage(data.payload)
				return data.payload
			} else {
				let dataToUse = undefined
				if (type === 'demand') {
					dataToUse = data.payload.proof
				} else if (type === 'publisher') {
					dataToUse = data.payload.proof_publisher
				}
				const srcTo64 = arrayBufferToBase64(dataToUse.Body.data)
				let src = ''
				if (dataToUse.ContentType.includes('pdf')) {
					src = `data:application/pdf;base64,${srcTo64}`
				} else if (dataToUse.ContentType.includes('png')) {
					src = `data:image/jpeg;base64,${srcTo64}`
				}
				setImageSrc(src)
			}
		} catch (err) {
			console.error(err)
		}
	}
}
export const removeProofImageFinance = (
	headers: any,
	payload: any,
	setErrorMessage: any,
	setLoading: any,
) => {
	return async (dispatch: Dispatch<Action>) => {
		try {
			setLoading(true)
			const obj = { data: payload }
			const { data } = await AxiosCustom.post(
				endpoints.FINANCE + '/removeproofimage',
				obj,
				{
					headers,
				},
			)
			if (!data.successful) {
				setErrorMessage(data.payload)
				return data.payload
			} else {
				console.log(payload, data)
				dispatch({
					type: ActionType.DELETE_PROOF_FINANCE,
					payload: payload,
				})
				setLoading(false)
			}
		} catch (err) {
			console.error(err)
		}
	}
}
export const downloadFinance = (
	headers: any,
	payload: any,
	type: any,
	setFileDownload: any,
	setErrorMessage: any,
) => {
	return async (dispatch: Dispatch<Action>) => {
		try {
			delete payload.id
			delete payload.isNew
			delete payload.hierarchy
			const dataToSave = { rows: payload, type: type }
			const obj = { data: dataToSave }
			const { data } = await AxiosCustom.post(
				endpoints.FINANCE + '/downloaddata',
				obj,
				{
					headers,
				},
			)
			// console.log(data)
			if (!data.successful) {
				setErrorMessage(data.payload)
				return data.payload
			} else {
				// dispatch({
				// 	type: ActionType.UPLOAD_FINANCE,
				// 	payload: payload,
				// })
				setFileDownload(data.payload.payload)
			}
		} catch (err) {
			console.error(err)
		}
	}
}
export const downloadFinanceTipalti = (
	headers: any,
	payload: any,
	setFileDownload: any,
	setErrorMessage: any,
) => {
	return async (dispatch: Dispatch<Action>) => {
		try {
			delete payload.id
			delete payload.isNew
			delete payload.hierarchy
			const dataToSave = { rows: payload }
			const obj = { data: dataToSave }
			const { data } = await AxiosCustom.post(
				endpoints.FINANCE + '/downloadtipaltireport',
				obj,
				{
					headers,
				},
			)
			// console.log(data)
			if (!data.successful) {
				setErrorMessage(data.payload)
				return data.payload
			} else {
				// dispatch({
				// 	type: ActionType.UPLOAD_FINANCE,
				// 	payload: payload,
				// })
				setFileDownload(data.payload.payload)
			}
		} catch (err) {
			console.error(err)
		}
	}
}
export const downloadFinanceBreakdownFile = (
	headers: any,
	payload: any,
	month: any,
	setFileDownload: any,
	setErrorMessage: any,
) => {
	return async (dispatch: Dispatch<Action>) => {
		try {
			delete payload.id
			delete payload.isNew
			delete payload.hierarchy
			const dataToSave = { rows: payload, month: month }
			const obj = { data: dataToSave }
			const { data } = await AxiosCustom.post(
				endpoints.FINANCE + '/downloadsupplybreakdown',
				obj,
				{
					headers,
				},
			)
			// console.log(data)
			if (!data.successful) {
				setErrorMessage(data.payload)
				return data.payload
			} else {
				// dispatch({
				// 	type: ActionType.UPLOAD_FINANCE,
				// 	payload: payload,
				// })

				setFileDownload(data.payload)
			}
		} catch (err) {
			console.error(err)
		}
	}
}
export const sendEmailAdvertiserFinance = (
	headers: any,
	payload: any,
	mainRow: any,
	user: any,
	currentRows: any,
	setErrorMessage: any,
) => {
	return async (dispatch: Dispatch<Action>) => {
		try {
			const dataToSave = { data: payload, user: user, main_row: mainRow }
			const obj = { data: dataToSave }
			console.log(obj)
			const { data } = await AxiosCustom.post(
				endpoints.FINANCE + '/sendemailadvertiser',
				obj,
				{
					headers,
				},
			)
			// console.log(data)
			if (!data.successful) {
				setErrorMessage(data.payload)
				return data.payload
			} else {
				dispatch({
					type: ActionType.SEND_EMAIL_FINANCE_ADVERTISER,
					payload: { row: payload, main_row: mainRow },
				})
				return false
			}
		} catch (err) {
			console.error(err)
		}
	}
}
export const sendEmailFinance = (
	headers: any,
	payload: any,
	type: any,
	setErrorMessage: any,
	setLastSend: any,
	setFinanceFinalSent?: any,
) => {
	return async (dispatch: Dispatch<Action>) => {
		try {
			const dataToSave = { data: payload, type: type }
			const obj = { data: dataToSave }
			const { data } = await AxiosCustom.post(
				endpoints.FINANCE + '/sendemail',
				obj,
				{
					headers,
				},
			)
			// console.log(data)
			if (!data.successful) {
				setErrorMessage(data.payload)
				return data.payload
			} else {
				dispatch({
					type: ActionType.SEND_EMAIL_FINANCE,
					payload: { payload, type },
				})
				const currentTime = new Date()
				const currentTimeISO = currentTime.toISOString()
				setLastSend(formatDateToDdMmYyyy(currentTimeISO))
				if (type !== 'status') {
					setFinanceFinalSent(true)
				}
				return true
			}
		} catch (err) {
			console.error(err)
		}
	}
}
export const deleteFinanceRow = (
	headers: any,
	payload: any,
	email: string,
	setErrorMessage: any,
) => {
	return async (dispatch: Dispatch<Action>) => {
		try {
			delete payload.id
			delete payload.isNew
			payload.email_for_logs = email
			const obj = { row: payload }
			// console.log('payload', obj)
			const { data } = await AxiosCustom.post(
				endpoints.FINANCE + '/deleterow',
				obj,
				{
					headers,
				},
			)
			console.log(data)
			if (!data.successful) {
				setErrorMessage(data.payload)
				return data.payload
			}
			dispatch({
				type: ActionType.DELETE_FINANCE,
				payload: data.payload,
			})
		} catch (err) {
			console.error(err)
		}
	}
}
export const deleteFinanceCreatorRow = (
	headers: any,
	payload: any,
	mainUuid: string,
	email: string,
	setErrorMessage: any,
) => {
	return async (dispatch: Dispatch<Action>) => {
		try {
			delete payload.id
			delete payload.isNew
			payload.email_for_logs = email
			const obj = { row: payload, mainUuid: mainUuid }
			// console.log('payload', obj)
			const { data } = await AxiosCustom.post(
				endpoints.FINANCE + '/deletecreatorrow',
				obj,
				{
					headers,
				},
			)
			console.log(data)
			if (!data.successful) {
				setErrorMessage(data.payload)
				return data.payload
			}
			// dispatch({
			// 	type: ActionType.DELETE_FINANCE,
			// 	payload: data.payload,
			// })
		} catch (err) {
			console.error(err)
		}
	}
}
export const deleteFinanceCreatorCampaignRow = (
	headers: any,
	payload: any,
	email: string,
	setErrorMessage: any,
) => {
	return async (dispatch: Dispatch<Action>) => {
		try {
			delete payload.id
			delete payload.isNew
			payload.email_for_logs = email
			const obj = { row: payload }
			// console.log('payload', obj)
			const { data } = await AxiosCustom.post(
				endpoints.FINANCE + '/deletecreatorcampaignrow',
				obj,
				{
					headers,
				},
			)
			console.log(data)
			if (!data.successful) {
				setErrorMessage(data.payload)
				return data.payload
			}
			// dispatch({
			// 	type: ActionType.DELETE_FINANCE,
			// 	payload: data.payload,
			// })
		} catch (err) {
			console.error(err)
		}
	}
}
export const uploadBalanceIn = (
	headers: any,
	payload: any,
	email: string,
	setErrorMessage: any,
) => {
	return async (dispatch: Dispatch<Action>) => {
		try {
			delete payload.id
			delete payload.isNew
			delete payload.hierarchy
			payload.email_for_logs = email
			const obj = { row: payload }
			// console.log('payload', obj)
			const { data } = await AxiosCustom.post(
				endpoints.FINANCE + '/updatefinancebalanceinrow',
				obj,
				{
					headers,
				},
			)
			if (!data.successful) {
				setErrorMessage(data.payload)
				return data.payload
			} else {
				dispatch({
					type: ActionType.UPLOAD_FINANCE_BALANCEIN,
					payload: payload,
				})
			}
		} catch (err) {
			console.error(err)
		}
	}
}
export const uploadBalanceOut = (
	headers: any,
	payload: any,
	email: string,
	setErrorMessage: any,
) => {
	return async (dispatch: Dispatch<Action>) => {
		try {
			delete payload.id
			delete payload.isNew
			delete payload.hierarchy
			payload.email_for_logs = email
			const obj = { row: payload }
			const { data } = await AxiosCustom.post(
				endpoints.FINANCE + '/updatefinancebalanceoutrow',
				obj,
				{
					headers,
				},
			)
			if (!data.successful) {
				setErrorMessage(data.payload)
				return data.payload
			} else {
				dispatch({
					type: ActionType.UPLOAD_FINANCE_BALANCEOUT,
					payload: payload,
				})
			}
		} catch (err) {
			console.error(err)
		}
	}
}
export const createCreatorFinanceRow = (
	headers: any,
	payload: any,
	email: string,
	setErrorMessage: any,
) => {
	return async (dispatch: Dispatch<Action>) => {
		try {
			payload.email_for_logs = email
			const obj = { row: payload }
			const { data } = await AxiosCustom.post(
				endpoints.FINANCE + '/createcreatorfinancerow',
				obj,
				{
					headers,
				},
			)
			if (!data.successful) {
				setErrorMessage(data.payload)
				return data.payload
			} else {
				dispatch({
					type: ActionType.CREATE_CREATOR_CAMPAIGN,
					payload: data.payload,
				})
			}
		} catch (err) {
			console.error(err)
		}
	}
}
export const editCreatorFinanceRow = (
	headers: any,
	payload: any,
	email: string,
	setErrorMessage: any,
) => {
	return async (dispatch: Dispatch<Action>) => {
		try {
			payload.email_for_logs = email
			const obj = { row: payload }
			const { data } = await AxiosCustom.post(
				endpoints.FINANCE + '/editcreatorfinancerow',
				obj,
				{
					headers,
				},
			)
			if (!data.successful) {
				setErrorMessage(data.payload)
				return data.payload
			} else {
				// dispatch({
				// 	type: ActionType.UPLOAD_FINANCE_BALANCEOUT,
				// 	payload: payload,
				// })
			}
		} catch (err) {
			console.error(err)
		}
	}
}
export const getFinanceData = (
	headers: any,
	type: string,
	setErrorMessage: any,
	email: string = '',
	chunkSize: number = 200,
	index: number = 0,
	controller: any = new AbortController().signal,
) => {
	return async (dispatch: Dispatch<Action>) => {
		try {
			const { data } = await AxiosCustom.get(endpoints.FINANCE + `/getdata/`, {
				headers,
				params: {
					type: type,
					chunkSize: chunkSize,
					index: index,
					email: email,
				},
				signal: controller,
			})
			if (!data.successful) {
				setErrorMessage(data.payload)
				return data.payload
			}

			dispatch({
				type: ActionType.GET_FINANCE,
				payload: data.payload,
				index: index,
			})
			return data.payload
		} catch (err) {
			console.error(err)
		}
	}
}
export const getInternalFinanceData = (
	headers: any,
	type: string,
	setErrorMessage: any,
	endpoint: string,
	email: string = '',
	chunkSize: number = 200,
	index: number = 0,
	controller: any = new AbortController().signal,
) => {
	return async (dispatch: Dispatch<Action>) => {
		try {
			const { data } = await AxiosCustom.get(
				endpoints.FINANCE + `/getinternaldata/`,
				{
					headers,
					params: {
						type,
						chunkSize,
						index,
						email,
						endpoint,
					},
					signal: controller,
				},
			)

			if (!data.successful) {
				setErrorMessage(data.payload)
				return data.payload
			}

			dispatch({
				type: ActionType.GET_FINANCE,
				payload: data.payload,
				index: index,
			})
			return data.payload
		} catch (err) {
			console.error(err)
		}
	}
}
// ==========dashboard ====================

export const getDashboardAction = () => {
	return async (dispatch: Dispatch<Action>) => {
		try {
			const data = await downloadEverything()
			if (data === undefined) {
				return
			}
			console.log(data)
			dispatch({
				type: ActionType.GETDASHBOARD,
				payload: data,
			})
		} catch (err) {
			console.error(err)
		}
	}
}

// ==================AutoSwitch====================

export const getAutoSwitchAction = (headers: any) => {
	return async (dispatch: Dispatch<Action>) => {
		try {
			const { data } = await AxiosCustom.get(endpoints.AUTOSWITCH, { headers })
			console.log('GET ', { data })
			if (data === undefined) {
				return
			}

			dispatch({
				type: ActionType.GETAUTOSWITCH,
				payload: data.payload,
			})
		} catch (err) {
			console.error(err)
		}
	}
}

export const saveAutoSwitchRuleAction = async (payload: any, headers: any) => {
	try {
		const pl = { payload: payload }
		const data = await AxiosCustom.post(endpoints.AUTOSWITCH, pl, { headers })
		if (data === undefined) {
			return
		}
		return data
		// dispatch({
		// 	type: ActionType.PUTAUTOSWITCH,
		// 	payload: data,
		// })
	} catch (err) {
		console.error(err)
	}
}

export const deleteAutoSwitchRuleAction = async (
	payload: any,
	headers: any,
) => {
	try {
		const pl = { headers, data: payload }

		const data = await AxiosCustom.delete(endpoints.AUTOSWITCH, pl)
		if (data === undefined) {
			return
		}

		return data
	} catch (err) {
		console.error(err)
	}
}

export const getVersionAction = async (payload: any, headers: any) => {
	try {
		const pl = { payload: payload }
		const data = await AxiosCustom.post(endpoints.VERSION, pl, { headers })
		if (data === undefined) {
			return
		}

		return data
	} catch (err) {
		console.error(err)
	}
}

// creators / influencers
export const getCreatorsAction = (headers: any) => {
	return async (dispatch: Dispatch<Action>) => {
		try {
			const { data } = await AxiosCustom.get(endpoints.CREATOR, {
				headers,
			})
			if (!data.successful) {
				return
			}
			console.log(data)
			dispatch({
				type: ActionType.GET_CREATOR,
				payload: data.payload,
			})
		} catch (err) {
			console.error(err)
			// if (
			// 	window.location.pathname !== '/notlogged' &&
			// 	window.location.pathname !== '/'
			// ) {
			// 	window.location.replace(window.location.origin + '/notlogged')
			// }
		}
	}
}
export const insertCreatorAction = (
	headers: any,
	obj: any,
	setErrorMessage: any,
) => {
	return async (dispatch: Dispatch<Action>) => {
		try {
			const creator = { data: obj }
			const { data } = await AxiosCustom.post(endpoints.CREATOR, creator, {
				headers,
			})
			if (!data.successful) {
				setErrorMessage(data.payload)
				return data.payload
			}

			dispatch({
				type: ActionType.INSERT_CREATOR,
				payload: data.payload,
			})
		} catch (err) {
			setErrorMessage(err)
			console.error(err)
		}
	}
}
export const deleteCreator = (headers: any, obj: any, setErrorMessage: any) => {
	return async (dispatch: Dispatch<Action>) => {
		try {
			console.log(obj)
			const { data } = await AxiosCustom.delete(endpoints.CREATOR, {
				headers,
				data: {
					data: obj,
				},
			})
			if (!data.successful) {
				setErrorMessage(data.payload)
				return
			}
		} catch (err) {
			setErrorMessage(err)
			console.error(err)
		}
	}
}
export const insertCreatorsAction = (headers: any, formData: FormData) => {
	return async () => {
		try {
			const updatedHeaders = {
				...headers,
				Accept: 'application/json',
				'Content-Type': 'multipart/form-data',
			}
			const { data } = await AxiosCustom.post(endpoints.CREATORS, formData, {
				headers: updatedHeaders,
			})
			if (!data.successful) {
				return data.payload
			}
			// dispatch({
			// 	type: ActionType.INSERT_CREATORS,
			// 	payload: data.payload,
			// })
		} catch (err) {
			console.error(err)
		}
	}
}

//HANDSHAKE
export const insertPidHandshakeAction = (headers: any, formData: FormData) => {
	return async () => {
		try {
			const updatedHeaders = {
				...headers,
				Accept: 'application/json',
				'Content-Type': 'multipart/form-data',
			}
			const { data } = await AxiosCustom.post(
				endpoints.HANDSHAKE + '/updatelist',
				formData,
				{
					headers: updatedHeaders,
				},
			)
			if (!data.successful) {
				return data.payload
			}
			// dispatch({
			// 	type: ActionType.INSERT_CREATORS,
			// 	payload: data.payload,
			// })
		} catch (err) {
			console.error(err)
		}
	}
}

export const getHandshakeAction = (headers: any) => {
	return async (dispatch: Dispatch<Action>) => {
		try {
			const { data } = await AxiosCustom.get(endpoints.HANDSHAKE, {
				headers,
			})
			if (!data.successful) {
				return
			}
			console.log('handshake', data)
			dispatch({
				type: ActionType.GETHANDSHAKE,
				payload: data.payload,
			})
		} catch (err) {
			console.error(err)
			// if (
			// 	window.location.pathname !== '/notlogged' &&
			// 	window.location.pathname !== '/'
			// ) {
			// 	window.location.replace(window.location.origin + '/notlogged')
			// }
		}
	}
}
export const updateHandshake = (
	headers: any,
	payload: any,
	setErrorMessage: any,
) => {
	return async (dispatch: Dispatch<Action>) => {
		try {
			const { data } = await AxiosCustom.post(
				endpoints.HANDSHAKE,
				{ handshake: payload },
				{
					headers,
				},
			)
			if (!data.successful) {
				setErrorMessage(data.payload)
				return data.payload
			}
			console.log('update', data)
			// dispatch({
			// 	type: ActionType.GETHANDSHAKE,
			// 	payload: data.payload,
			// })
		} catch (err) {
			console.error(err)
		}
	}
}
