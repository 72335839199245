import React, { SyntheticEvent } from 'react'
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos'
import VisibilityIcon from '@mui/icons-material/Visibility'
import FileUploadOutlined from '@mui/icons-material/FileUploadOutlined'
import { TabContainer } from './TabComponent'
import { UnborderedButton } from './Buttons'

interface EditPageHeaderProps {
	onBack: () => void
	onPreview: () => void
	onUpload: () => void
	tabValue: number
	handleChangeTab: (event: SyntheticEvent, newValue: number) => void
	hasPreview?: boolean
	options: Array<{ label: string; tabIndex: number }>
	customStyles?: React.CSSProperties
	ariaLabel: string
}

const EditPageHeader: React.FC<EditPageHeaderProps> = ({
	onBack,
	onPreview,
	onUpload,
	tabValue,
	handleChangeTab,
	hasPreview = true,
	options,
	customStyles = {},
	ariaLabel,
}) => {
	return (
		<div
			style={{
				display: 'flex',
				alignItems: 'center',
				justifyContent: 'space-between',
				...customStyles,
			}}
		>
			<UnborderedButton
				onClick={onBack}
				icon={<ArrowBackIosIcon />}
				label='Back'
			/>
			<TabContainer
				tab={tabValue}
				handleChangeTab={handleChangeTab}
				options={options}
				tabProps={{ ariaLabel }}
			/>
			<div style={{ display: 'flex', alignItems: 'center' }}>
				{hasPreview && (
					<UnborderedButton
						onClick={onPreview}
						icon={<VisibilityIcon />}
						label='Preview'
					/>
				)}
				<UnborderedButton
					onClick={onUpload}
					icon={<FileUploadOutlined />}
					label='Upload Icon'
				/>
			</div>
		</div>
	)
}

export default EditPageHeader
