import {
	Checkbox,
	DialogActions,
	DialogContent,
	DialogTitle,
	FormControlLabel,
	styled,
	Typography,
} from '@mui/material'
import _ from 'lodash'
import { useEffect, useState } from 'react'
import { useActions } from '../../hooks/useActions'
import { useTypedSelector } from '../../hooks/useTypedSelector'
import { UserInterface } from '../../models/model.interface'
import { getApplicableRules } from '../../utils/helpers/helperFuncs'
import {
	capitalizeFirstLetter,
	shallowEqual,
} from '../../utils/helpers/tableHelper'
import theme from '../../utils/theme2024'
import {
	ChipInputList,
	StyledNameField,
} from '../components/reusableComponents'
import { UserPopUpForm } from './UserPopUpComponents'
import { countryList } from '../components/countryList'
import { InputFieldText } from '../components/Inputs'
import { DropList } from '../components/SelectableInputs'
import { SubmitButton, UnborderedButton } from '../components/Buttons'

const country_codes = countryList.map((item) => item.slice(0, 2))

const UserPopUp = (props: {
	PopUpVisible: boolean
	setPopUpVisible: any
	handleClose: any
	setLoading: any
	row?: UserInterface
	errorMessage: null | string
	setErrorMessage: any
	isErrorPromptOpened: boolean
	setIsErrorPromptOpened: any
}): JSX.Element => {
	const addingUser = props.row === undefined
	const { insertUser, editUser, insertLog } = useActions()
	const { login, settings, users } = useTypedSelector((state) => state)
	const ruleSet = getApplicableRules(users, login, settings, 'users')
	const [name, setName] = useState<string | null>(null)
	const [email, setEmail] = useState<string | null>(null)
	const [password, setPassword] = useState<string | null>(null)
	const [role, setRole] = useState<string | null>(null)
	const [team, setTeam] = useState<string | null>(null)
	const [teamLeader, setTeamLeader] = useState<string | null>(null)
	const [location, setLocation] = useState<string | null>(null)
	const [position, setPosition] = useState<string | null>(null)
	const [department, setDepartment] = useState<string | null>(null)
	const [isValid, setIsValid] = useState<boolean>(false)
	const [isBlocked, setIsBlocked] = useState<boolean>(false)
	const [prevState, setPrevState] = useState<any>(null)
	const [isStrong, setIsStrong] = useState(false)
	const [isTestAccess, setIsTestAccess] = useState(false)
	const [financeControlAccess, setFinanceControlAccess] = useState(false)
	const [userType, setUserType] = useState<string | null>(null)

	const fillFields = (row: any) => {
		setRole(row!.role)
		setUserType(row!.user_type)
		setEmail(row!.email)
		setName(row!.name)
		setIsBlocked(row!.blocked)
		setPosition(row!.position)
		setTeam(row!.team)
		setIsTestAccess(row!.testaccess)
		setLocation(row!.location)
		setDepartment(row!.department)
		setTeamLeader(row!.team_leader)
		setFinanceControlAccess(row!.finance_control_access)
	}
	const preparePrevState = (prevState: any) => {
		const { ...result } = prevState
		return result
	}
	const prepareNewState = (newState: any) => {
		const { ...result } = newState
		return result
	}
	const handleLog = async (headers: any, payload: any) => {
		const shallow = shallowEqual(prevState, prepareNewState(payload))
		if (shallow.length > 0) {
			const log = {
				email: login.user.email,
				object: name! + ' in Users',
				change: JSON.stringify(shallow),
			}
			await insertLog(headers, log)
		}
	}

	const nullifyFields = () => {
		setName(null)
		setEmail(null)
		setPassword(null)
		setUserType(null)
		setRole(null)
		setIsBlocked(false)
		setPosition(null)
		setTeam(null)
		setTeamLeader(null)
		setFinanceControlAccess(false)
	}
	useEffect(() => {
		if (props.row) {
			const obj = _.cloneDeep(props.row)
			const prev = preparePrevState(obj)
			// if (!prevState) {
			setPrevState({ ...prev })
			// }
		}
	}, [props.row])

	const trySubmit = async () => {
		if (isValid) {
			const headers = {
				Authorization: `Token ${login.user.token}`,
			}
			const payload = {
				id: addingUser ? null : props.row?.email,
				name: name!,
				email: email!,
				role: role,
				position: position,
				password: addingUser ? password! : null,
				department: department,
				user_login: login.user.email,
				blocked: isBlocked,
				team: team,
				testaccess: isTestAccess,
				location: location,
				finance_control_access: financeControlAccess,
				team_leader: teamLeader,
				user_type: userType,
			}
			addingUser
				? await insertUser(headers, payload, props.setErrorMessage)
				: await editUser(headers, payload, props.setErrorMessage)
			props.setLoading(false)
			props.handleClose()
			handleLog(headers, payload)
		}
	}
	useEffect(() => {
		if (!addingUser) {
			fillFields(props.row)
		}
	}, [props.PopUpVisible])

	// useEffect(() => {
	// 	const handlePasswordVerification = (password: string) => {
	// 		let strongPassword = new RegExp(
	// 			'(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[^A-Za-z0-9])(?=.{8,})',
	// 		)
	// 		if (strongPassword.test(password)) {
	// 			return true
	// 		}
	// 		return false
	// 	}
	// 	setIsStrong(handlePasswordVerification(password || ''))
	// }, [password])

	useEffect(() => {
		setIsValid(
			!!name &&
				!!email &&
				!!role &&
				!!userType &&
				!!location &&
				!!department &&
				!!position &&
				!!team,
		)
	}, [name, email, role, userType, location, department, position, team])

	return (
		<UserPopUpForm
			open={props.PopUpVisible}
			style={{ zIndex: '20' }}
			sx={{ width: 900, maxWidth: 900, mx: 'auto' }}
		>
			<DialogTitle
				style={{
					display: 'flex',
					justifyContent: 'row',
					placeContent: 'space-between',
					// height: 10,
				}}
			>
				<span style={{ padding: '10px 40px' }}>
					{addingUser ? 'Add user' : 'Edit user'}
				</span>
				{isBlocked && (
					<span
						style={{
							fontSize: '16px',
							color: theme.colors.red,
							marginTop: '4px',
						}}
					>
						User is blocked
					</span>
				)}
			</DialogTitle>
			<DialogContent
				style={{
					display: 'flex',
					flexDirection: 'column',
					gap: '12px',
					padding: '0px 60px 60px 60px',
				}}
			>
				<InputFieldText
					label='Name'
					type='text'
					value={name}
					onChange={setName}
				/>
				<InputFieldText
					label='Email Address'
					type='email'
					value={email}
					onChange={setEmail}
					disabled={!addingUser ? true : false}
				/>
				<DropList
					label='Type'
					options={
						settings.settings.userTypes.map(
							(type: string) => type.charAt(0).toUpperCase() + type.slice(1),
						) || []
					}
					value={
						userType ? userType.charAt(0).toUpperCase() + userType.slice(1) : ''
					}
					onChange={(e: any) => setUserType(e.toLowerCase())}
				/>
				<DropList
					label={'Role'}
					options={
						userType
							? Object.keys(settings.settings.permissionGroups[userType])
							: []
					}
					value={role ? role : ''}
					onChange={(e: any) => setRole(e)}
				/>
				<DropList
					label={'Division'}
					options={[
						'Administration',
						'BizDev',
						'Creative',
						'Management',
						'Marketing',
						'Technology',
					]}
					value={department}
					onChange={(e: any) => setDepartment(e)}
				/>
				<DropList
					label={'Department'}
					options={settings.settings.teams || []}
					value={team}
					onChange={(e: any) => setTeam(e)}
				/>
				<DropList
					label={'Team Leaders'}
					options={settings.settings.teamLeaders || []}
					value={teamLeader}
					onChange={(e: any) => setTeamLeader(e)}
				/>
				<DropList
					label='Position'
					options={settings.settings.userPositions || []}
					value={position}
					onChange={(e: any) => setPosition(e)}
				/>
				<DropList
					label={'Location'}
					// options={
					// 	settings.settings.branches.map((l: string) => l + ' Branch') || []
					// }
					options={country_codes}
					value={location}
					onChange={(e: any) => setLocation(e)}
				/>
				{/* <StyledCheckboxFormControl
					key={'QAaccess'}
					control={
						<Checkbox
							onChange={() => {
								setIsTestAccess(!isTestAccess)
							}}
							checked={isTestAccess}
							key={'qa_checkbox'}
							style={{ marginLeft: '25px', color: '#ee1d4a' }}
							disabled={login.user.role !== 'super'}
						/>
					}
					label={
						<Typography fontSize='13px' fontWeight={500} color={'secondary'}>
							{'QA Access'}
						</Typography>
					}
				/> */}
				{/* <StyledCheckboxFormControl
					key={'financeController'}
					control={
						<Checkbox
							onChange={() => {
								setFinanceControlAccess(!financeControlAccess)
							}}
							checked={financeControlAccess}
							key={'finance-checkbox'}
							style={{ marginLeft: '25px', color: '#ee1d4a' }}
							disabled={login.user.role !== 'super'}
						/>
					}
					label={
						<Typography fontSize='13px' fontWeight={500} color={'secondary'}>
							{'Finance Control Access'}
						</Typography>
					}
				/> */}
			</DialogContent>
			<DialogActions style={{ paddingBottom: '34px', height: 120 }}>
				<UnborderedButton
					style={{ margin: 'auto' }}
					onClick={() => {
						nullifyFields()
						props.handleClose()
					}}
					label={'Cancel'}
				></UnborderedButton>
				{ruleSet.post && (
					<SubmitButton
						onClick={() => trySubmit()}
						style={{ margin: 'auto' }}
						disabled={!isValid}
					>
						{addingUser ? 'Add' : 'Update'}
					</SubmitButton>
				)}
				{!addingUser && ruleSet.rules['blocking users'] && (
					<SubmitButton
						onClick={() => setIsBlocked(!isBlocked)}
						style={{ margin: 'auto', background: theme.colors.base.white }}
						disabled={!isValid}
					>
						{isBlocked ? 'Unblock' : 'Block'}
					</SubmitButton>
				)}
			</DialogActions>
		</UserPopUpForm>
	)
}
export default UserPopUp

export const StyledCheckboxFormControl = styled(FormControlLabel)(
	({ theme }) => ({
		color: theme.colors.gradient,
		fontSize: '13px',
		maxWidth: '280px',
		fontWeight: 600,
		'&.MuiTypography-root': {
			fontSize: '13px',
		},
	}),
)
