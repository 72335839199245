import React from 'react'
import { countryContinentList } from '../components/countryContinentList'
import WarningAmberIcon from '@mui/icons-material/WarningAmber'
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline'
import theme from '../../utils/theme2024'
import FilterComponent from './FilterComponent'

const FiltersContainer = (props: {
	publisherCapabilitiesMap: any
	setChangeToFilterDone: any
	settings: any
}) => {
	const generateNodesFromArray = (arr: string[], structure: any) => {
		try {
			const baseNodes = arr.map((value: string) => {
				const obj = {}
				for (const [key] of Object.entries(structure)) {
					if (key === 'value' || key === 'label') {
						Object.assign(obj, { [key]: value })
					} else {
						Object.assign(obj, { [key]: '' })
					}
				}
				return obj
			})
			return baseNodes
		} catch (error) {
			console.error(error)
			return []
		}
	}
	const generateNodesDevices = () => {
		return generateNodesFromArray(['iOS', 'Android', 'Desktop', 'SmartTV'], {
			value: '',
			label: '',
		})
	}
	const generateNodesRelevantCategories = () => {
		const children = generateNodesFromArray(
			['Hyper Casual Gaming', 'Casual Gaming', 'RPG Gaming'],
			{ value: '', label: '' },
		)
		const nodes = generateNodesFromArray(
			[
				'Gambling',
				'Gambling License Only',
				'Sport Betting',
				'Sport Betting License Only',
				'Social Casino',
				'Shopping',
				'Food & Drinks',
				'Fashion',
				'Auto parts',
				'Dating',
				'Finance',
				'Trading',
				'Trading License Only',
				'Streaming',
				'Entertainment',
				'Sports',
				'Social Platform',
				'Food Delivery',
				'Loans',
				'Surveys & Freebies',
				'VPN',
				'Travel App',
				'Utility',
				'Real Estate',
				'Job Search',
				'Insurance',
			],
			{ value: '', label: '' },
		)
		return [
			{
				value: 'Gaming',
				label: 'Gaming',
				children: children,
			},
			...nodes,
		]
	}
	const countryGenerateNodes = (baseNodes: any, updatedNodes: any) => {
		try {
			for (const node of updatedNodes) {
				for (const continent of baseNodes) {
					for (const country of continent.children) {
						if (node.value === country.value) {
							country.payout = node.payout
							country.volume = node.volume
							continue
						}
					}
				}
			}
		} catch (error) {
			console.error(error)
		}
		return baseNodes
	}
	const generateNodesAdType = () => {
		return generateNodesFromArray(
			[
				'Rewarded Video',
				'Video',
				'Banner Ads',
				'Interstitials',
				'Incent',
				'Point Incent',
				'Cash Incent',
				'Content Locking Incent',
				'Pop',
				'Push',
				'Native Ads',
				'Search',
				'App Discovery',
				'Playable Ads',
				'Influencer',
				'Direct Linking',
				'Contextual',
				'Mrec',
				'Calendar',
			],
			{ value: '', label: '' },
		)
	}
	const generateNodesPublisherType = () => {
		const mediaBuyingPlatforms = generateNodesFromArray(
			[
				'Direct App/Website',
				'Social Platform',
				'DSP',
				'in App Self-Serve',
				'Pop & Push Self-Serve',
				'Incent Self-Serve',
				'Incent Offerwall',
				'Native Ads Platform',
			],
			{ value: '', label: '' },
		)
		const nonMediaBuyingPlatforms = generateNodesFromArray(
			['Ad Network', 'External Media Buying'],
			{ value: '', label: '' },
		)
		return [
			{
				value: 'Media Buying platforms',
				label: 'Media Buying platforms',
				children: mediaBuyingPlatforms,
			},
			{
				value: 'Non media Buying',
				label: 'Non media Buying',
				children: nonMediaBuyingPlatforms,
			},
		]
	}
	const generateNodesDailyCap = () => {
		return [
			{
				value: 'Daily Budget in USD',
				label: 'Daily Budget in USD',
				inputValue: 'X',
			},
		]
	}
	const generateNodesFraudTool = () => {
		return generateNodesFromArray(props.settings.settings.fraudTools, {
			value: '',
			label: '',
		})
	}
	const generateNodesPaymentModel = () => {
		return generateNodesFromArray(
			[
				'CPI',
				'CPA',
				'CPC',
				'CPM',
				'Revenue Share',
				'Prepaid Platform',
				'Fixed Cost (Influencers)',
			],
			{ value: '', label: '' },
		)
	}
	const generateNodesTrafficIndicators = () => {
		const indicatorsCR = generateNodesFromArray(
			['High CR', 'Average CR', 'Low CR'],
			{ value: '', label: '' },
		)
		const indicatorsQuality = generateNodesFromArray(
			['High', 'Average', 'Low'],
			{ value: '', label: '' },
		)
		return [
			{
				value: 'CR',
				label: 'CR',
				children: indicatorsCR,
			},
			{
				value: 'Quality',
				label: 'Quality',
				children: indicatorsQuality,
			},
		]
	}
	const generateAbilities = () => {
		try {
			return generateNodesFromArray(props.settings.settings.capabilities, {
				value: '',
				label: '',
			})
		} catch (error) {
			console.error(error)
			return generateNodesFromArray([], { value: '', label: '' })
		}
	}
	const generateNodesAvailablePlatforms = () => {
		const children = generateNodesFromArray(
			[
				'Appsflyer',
				'Adjust',
				'Branch',
				'Kochava',
				'Singular',
				'AppMetrica',
				'Airbridge',
				'Tenjin',
				'Income Access',
			],
			{ value: '', label: '' },
		)
		return [
			{
				value: 'Affise',
				label: 'Affise',
			},
			{
				value: 'MMP',
				label: 'MMP',
				children: children,
			},
		]
	}

	return (
		<div
			style={{
				display: 'flex',
				flexDirection: 'column',
				marginLeft: '2vw',
				width: '94%',
			}}
		>
			<FilterComponent
				nodes={[
					{ value: 'Direct', label: 'Direct' },
					{ value: 'Non Direct', label: 'Non Direct' },
				]}
				label={'My campaign is'}
				selectedElements={props.publisherCapabilitiesMap.selectedDirect}
				setSelectedElements={props.publisherCapabilitiesMap.setSelectedDirect}
				notExpandable={true}
				disabledBasedOnLength={2}
				elementsWidth={'350px'}
			/>
			<FilterComponent
				nodes={generateNodesDevices()}
				label={'My campaign device is'}
				selectedElements={props.publisherCapabilitiesMap.selectedDevices}
				setSelectedElements={props.publisherCapabilitiesMap.setSelectedDevices}
				notExpandable={true}
				elementsWidth={'350px'}
			/>
			<FilterComponent
				nodes={generateNodesRelevantCategories()}
				label={'The campaign category is'}
				selectedElements={
					props.publisherCapabilitiesMap.selectedRelevantCategories
				}
				setSelectedElements={
					props.publisherCapabilitiesMap.setSelectedRelevantCategories
				}
				elementsWidth={'350px'}
			/>
			<FilterComponent
				nodes={countryGenerateNodes(
					countryContinentList,
					props.publisherCapabilitiesMap.selectedCountryElements,
				)}
				label={'Target to the following Geos'}
				selectedElements={
					props.publisherCapabilitiesMap.selectedCountryElements
				}
				setSelectedElements={
					props.publisherCapabilitiesMap.setSelectedCountryElements
				}
				isCountry={true}
				countryData={props.publisherCapabilitiesMap.selectedGeneralCountryData}
				setCountryData={
					props.publisherCapabilitiesMap.setSelectedGeneralCountryData
				}
				setChangeToFilterDone={props.setChangeToFilterDone}
				elementsWidth={'350px'}
			/>
			<FilterComponent
				nodes={generateNodesPublisherType()}
				label={"I'm looking for"}
				selectedElements={
					props.publisherCapabilitiesMap.selectedTypeOfPublisher
				}
				setSelectedElements={
					props.publisherCapabilitiesMap.setSelectedTypeOfPublisher
				}
				elementsWidth={'350px'}
			/>
			<FilterComponent
				nodes={generateNodesAdType()}
				label={'Who has the following ad type'}
				selectedElements={props.publisherCapabilitiesMap.selectedAdType}
				setSelectedElements={props.publisherCapabilitiesMap.setSelectedAdType}
				notExpandable={true}
				elementsWidth={'350px'}
			/>
			<FilterComponent
				nodes={generateNodesAvailablePlatforms()}
				label={'Who can run on'}
				selectedElements={
					props.publisherCapabilitiesMap.selectedAvailablePlatforms
				}
				setSelectedElements={
					props.publisherCapabilitiesMap.setSelectedAvailablePlatforms
				}
				elementsWidth={'350px'}
			/>
			<FilterComponent
				nodes={generateNodesFraudTool()}
				label={'With the fraud tool'}
				selectedElements={props.publisherCapabilitiesMap.selectedFraudTools}
				setSelectedElements={
					props.publisherCapabilitiesMap.setSelectedFraudTools
				}
				notExpandable={true}
				elementsWidth={'350px'}
			/>
			<FilterComponent
				nodes={generateNodesPaymentModel()}
				label={'The campaign payout model is'}
				selectedElements={props.publisherCapabilitiesMap.selectedPaymentModel}
				setSelectedElements={
					props.publisherCapabilitiesMap.setSelectedPaymentModel
				}
				notExpandable={true}
				elementsWidth={'350px'}
			/>
			<FilterComponent
				nodes={generateNodesDailyCap()}
				label={'My daily budget is'}
				selectedElements={props.publisherCapabilitiesMap.selectedDailyCap}
				setSelectedElements={props.publisherCapabilitiesMap.setSelectedDailyCap}
				setChangeToFilterDone={props.setChangeToFilterDone}
				elementsWidth={'350px'}
				customInputLabel={'$'}
			/>
			<FilterComponent
				nodes={generateAbilities()}
				label={'The publisher must have the following abilities'}
				selectedElements={props.publisherCapabilitiesMap.selectedAbilities}
				setSelectedElements={
					props.publisherCapabilitiesMap.setSelectedAbitilies
				}
				notExpandable={true}
				elementsWidth={'350px'}
			/>
			<FilterComponent
				nodes={generateNodesTrafficIndicators()}
				label={'The publisher must have'}
				selectedElements={
					props.publisherCapabilitiesMap.selectedTrafficIndicators
				}
				setSelectedElements={
					props.publisherCapabilitiesMap.setSelectedTrafficIndicators
				}
				elementsWidth={'350px'}
			/>
		</div>
	)
}

export default FiltersContainer
