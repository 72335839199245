import {
	Dialog,
	DialogTitle,
	DialogContent,
	DialogContentText,
	DialogActions,
} from '@mui/material'
import React from 'react'
import { SubmitButton } from '../../pages/components/Buttons'

export const WarningPrompt = ({
	PopUpVisible,
	setPopUpVisible,
	message,
	onAgree,
	onCancel,
	obj,
}: {
	PopUpVisible: boolean
	setPopUpVisible: any
	message: string
	onAgree?: any
	onCancel?: any
	obj?: any
}) => {
	return (
		<Dialog open={PopUpVisible}>
			<DialogTitle>WARNING</DialogTitle>
			<DialogContent>
				<DialogContentText>{message.toString()}</DialogContentText>
			</DialogContent>
			<DialogActions>
				{onCancel ? (
					<SubmitButton
						style={{ margin: 'auto' }}
						onClick={() => {
							if (onCancel) onCancel()
							setPopUpVisible(false)
						}}
					>
						Cancel
					</SubmitButton>
				) : (
					<></>
				)}
				<SubmitButton
					style={{ margin: 'auto', width: 'auto' }}
					onClick={() => {
						if (onAgree) {
							onAgree(obj)
						}
						setPopUpVisible(false)
					}}
				>
					Understood
				</SubmitButton>
			</DialogActions>
		</Dialog>
	)
}

export const DeletionWarningPrompt = (props: {
	PopUpVisible: boolean
	setPopUpVisible: any
	message: string
	onAgree: any
	setObj: any
	obj: any
}) => {
	const handleOk = async (obj: any) => {
		console.log('OBJ : ', obj)
		props.setPopUpVisible(false)
		await props.onAgree(obj)
	}
	return (
		<Dialog open={props.PopUpVisible}>
			<DialogTitle>CAUTION</DialogTitle>
			<DialogContent>
				<DialogContentText>{props.message.toString()}</DialogContentText>
			</DialogContent>
			<DialogActions>
				<SubmitButton
					style={{ margin: 'auto' }}
					onClick={() => {
						props.setObj(undefined)
						props.setPopUpVisible(!props.PopUpVisible)
					}}
				>
					Cancel
				</SubmitButton>

				<SubmitButton
					style={{ margin: 'auto', width: 'auto', color: 'red' }}
					onClick={() => handleOk(props.obj)}
				>
					DELETE
				</SubmitButton>
			</DialogActions>
		</Dialog>
	)
}
