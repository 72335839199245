import { Checkbox, Grid, Typography } from '@mui/material'
import { useEffect, useRef, useState } from 'react'
import { useTypedSelector } from '../../hooks/useTypedSelector'
import {
	StyledNameField,
	StyledTextField,
} from '../components/reusableComponents'
import { StyledCheckboxFormControl } from '../reports/FiltersStyled'
import PublishersMediaCapabilities from './PublishersMediaCapabilities'
import { StyledTextAreaField } from '../components/Inputs'
import { SwitchComponent } from '../components/ToggleComponents'
import { StyledLabel } from '../components/Informative'
import theme from '../../utils/theme2024'

//this is the component we use to render the media capabilities.
export const PublisherAllowedPlatformsComponent = (props: {
	capabilities: any[]
	setCapabilities: any
	loginRole: string
	setMainHeightChange: any
	vertical: string[]
	setVertical: any
	publisherCapabilitiesMap: any
	notes: any
	setNotes: any
}) => {
	const { settings } = useTypedSelector((state) => state)
	//with this we just set up the possible capabilities based on the settings.
	useEffect(() => {
		const defaultCapabilities: any = []
		for (const title of settings.settings.capabilities) {
			const obj = {
				title: title,
				value: false,
			}
			defaultCapabilities.push(obj)
		}
		props.setCapabilities(defaultCapabilities)
		if (props.capabilities) {
			const currentPlatforms = props.capabilities.filter(
				(el) => el.value === true,
			)
			for (const activePlatform of currentPlatforms) {
				for (const platform of defaultCapabilities) {
					if (activePlatform.title === platform.title) {
						platform.value = activePlatform.value
					}
				}
			}
			props.setCapabilities(defaultCapabilities)
		}
	}, [settings])

	return (
		<>
			<Grid
				style={{ display: 'flex', flexDirection: 'row', marginLeft: '-20px' }}
			>
				<PublishersMediaCapabilities
					settings={settings}
					publisherCapabilitiesMap={props.publisherCapabilitiesMap}
				/>
			</Grid>
			<Grid
				container
				columns={12}
				columnGap={0}
				rowGap={2}
				style={{
					marginTop: 34,
				}}
			>
				<Grid item xs={12}>
					<StyledLabel style={{ left: 2, fontSize: theme.font.size.body }}>
						Badges
					</StyledLabel>
				</Grid>
				<Grid item xs={1}>
					<SwitchComponent
						// required
						label='Good Quality'
						value={props.publisherCapabilitiesMap.badgeGoodQuality}
						onChange={props.publisherCapabilitiesMap.setBadgeGoodQuality}
						disabled={false}
					/>
				</Grid>
				<Grid item xs={1}>
					<SwitchComponent
						// required
						label='Low Fraud'
						value={props.publisherCapabilitiesMap.badgeLowFraud}
						onChange={props.publisherCapabilitiesMap.setBadgeLowFraud}
						disabled={false}
					/>
				</Grid>
				<Grid item xs={1}>
					<SwitchComponent
						// required
						label='High Volume'
						value={props.publisherCapabilitiesMap.badgeHighVolume}
						onChange={props.publisherCapabilitiesMap.setBadgeHighVolume}
						disabled={false}
					/>
				</Grid>
				<Grid item xs={1}>
					<SwitchComponent
						// required
						label='High CR'
						value={props.publisherCapabilitiesMap.badgeHighCR}
						onChange={props.publisherCapabilitiesMap.setBadgeHighCR}
						disabled={false}
					/>
				</Grid>
			</Grid>
			<div
				style={{
					// display: 'flex',
					marginTop: '2rem',
					// gap: '24px',
				}}
				id={'specialRequest-container'}
			>
				<Grid
					container
					columns={12}
					columnGap={6}
					// style={{
					// 	display: 'flex',
					// 	width: '100%',
					// }}
				>
					<Grid item xs={5}>
						<StyledTextAreaField
							// required
							label='Brief'
							value={props.notes}
							onChange={props.setNotes}
						/>
					</Grid>
					<Grid item xs={5}>
						<StyledTextAreaField
							label={'Special Request'}
							value={props.publisherCapabilitiesMap.specialRequestList}
							onChange={props.publisherCapabilitiesMap.setSpecialRequestList}
						/>
					</Grid>
				</Grid>
			</div>
		</>
	)
}
