import React from 'react'
import LanguageIcon from '@mui/icons-material/Language'
import LinkedInIcon from '@mui/icons-material/LinkedIn'
import FolderOpenIcon from '@mui/icons-material/FolderOpen'
import driveIcon from '../../assets/drive-icon.svg'

interface SocialLinksProps {
	links: {
		website?: string
		linkedin?: string
		io?: string
		pub?: string
	}
}

const SocialLinks: React.FC<SocialLinksProps> = ({ links }) => {
	const handleClickedLink = (url: string) => {
		window.open(url, '_blank')
	}

	return (
		<div
			style={{
				display: 'flex',
				alignItems: 'center',
				gap: '10px',
				padding: '0px',
			}}
		>
			{links.website && (
				<button
					style={{
						display: 'flex',
						alignItems: 'center',
						padding: '0px',
						border: 'none',
						cursor: 'pointer',
					}}
					onClick={() => links.website && handleClickedLink(links.website)}
				>
					<LanguageIcon />
				</button>
			)}
			{links.linkedin && (
				<button
					style={{
						display: 'flex',
						alignItems: 'center',
						padding: '0px',
						border: 'none',
						cursor: 'pointer',
					}}
					onClick={() => links.linkedin && handleClickedLink(links.linkedin)}
				>
					<LinkedInIcon />
				</button>
			)}
			{links.io && (
				<button
					style={{
						display: 'flex',
						alignItems: 'center',
						padding: '0px',
						border: 'none',
						cursor: 'pointer',
					}}
					onClick={() => links.io && handleClickedLink(links.io)}
				>
					<FolderOpenIcon />
				</button>
			)}
			{links.pub && (
				<button
					style={{
						display: 'flex',
						alignItems: 'center',
						padding: '0px',
						border: 'none',
						cursor: 'pointer',
					}}
					onClick={() => links.pub && handleClickedLink(links.pub)}
				>
					src={driveIcon}
					style=
					{{
						marginTop: '3px',
					}}
					alt={'drive'}
				</button>
			)}
		</div>
	)
}

export default SocialLinks
