import { ThemeProvider } from '@emotion/react'
import '@inovua/reactdatagrid-community/index.css'
import { Typography } from '@mui/material'
import {
	DataGridPro,
	GridColDef,
	GridToolbarContainer,
	GridToolbarExport,
} from '@mui/x-data-grid-pro'
import { useEffect, useState } from 'react'
import { useTypedSelector } from '../../hooks/useTypedSelector'
import {
	generateRowsWithIds,
	sortFieldNamesp360,
} from '../../utils/helpers/tableHelper'
import theme from '../../utils/theme2024'
import { PageWrapper, TableWrapper } from '../components/reusableComponents'
export const ReportsTable360 = (props: {
	report: any
	setIsReportOpen: any
	reportFields: any
	isReportOpen: boolean
	customReportFields: any
}) => {
	let fieldNames: any[] = []
	const { users, app } = useTypedSelector((state) => state)

	const sortedfieldNames: string[] = sortFieldNamesp360(props.reportFields)
	const [pinnedRow, setPinnedRow] = useState({})

	const rowCount = Object.keys(props.report[0] || {}).length

	const [rowWidth, setRowWidth] = useState(
		Math.floor((window.innerWidth - theme.dimentions.drawerWidth) / rowCount) *
			0.85,
	)
	let iconsBank: any = {}
	for (const icon of app.appIcons) {
		for (const row of props.report) {
			if (icon.app_id == row['App ID']) {
				if (icon.icon_url && icon.icon_url !== '') {
					iconsBank[row['App ID']] = icon.icon_url
				}
			}
		}
	}

	const columns: GridColDef[] = sortedfieldNames.map(
		(e: string, index: number): any => {
			const headerName = e.replace('%', 'percent')
			if (e === 'App ID') {
				return {
					field: headerName,
					headerName: e,
					width: rowWidth,
					type: 'string',

					renderCell: ({ value }: { value: any }) => (
						<div style={{ display: 'flex' }}>
							<img
								alt={''}
								src={iconsBank[value]}
								style={{
									height: '28px',
									borderRadius: '16px',
									margin: '10px',
								}}
							></img>

							<Typography style={{ fontWeight: 500, alignSelf: 'center' }}>
								{value}
							</Typography>
						</div>
					),
				}
			} else if (headerName.includes('percent')) {
				return {
					field: headerName,
					headerName: e,
					width: rowWidth,
					type: 'number',

					renderCell: ({ value }: { value: any }) => (
						<Typography
							style={{
								fontWeight: 500,
								alignSelf: 'center',
								color: value > 0 ? 'red' : 'green',
							}}
						>
							{Number(value).toFixed(1) + '%'}
						</Typography>
					),
				}
			}
			return {
				field: headerName,
				headerName: e,
				width: rowWidth,
				type: 'number',
			}
		},
	)

	const pageSize = 20

	useEffect(() => {
		setRowWidth(
			Math.floor((window.innerWidth - theme.dimentions.drawerWidth) / rowCount),
		)
	}, [rowCount])

	// useEffect(() => {
	// 	if (props.report) {
	// 		const sumRow = props.report.slice(props.report.length - 1)
	// 		sumRow[0].id = props.report.length - 1
	// 		const pinnedSumRow = { bottom: sumRow }
	// 		setPinnedRow(pinnedSumRow)
	// 	}
	// }, [props.report])

	return (
		<ThemeProvider theme={theme}>
			<PageWrapper style={{ zIndex: 0 }}>
				<TableWrapper
					style={{
						margin: '-1rem',
						marginTop: '60px',
						width: '90%',
						paddingBottom: '60px',
					}}
					theme={theme}
					rowCount={rowCount}
					setRowWidth={setRowWidth}
				>
					<DataGridPro
						style={{
							fontSize: '1rem',
						}}
						columns={columns}
						rowHeight={40}
						rows={generateRowsWithIds(props.report)}
						pageSizeOptions={[15, 25, 50]}
						showColumnVerticalBorder={true}
						pinnedRows={pinnedRow}
						slots={{ toolbar: CustomToolbar }}
					></DataGridPro>
				</TableWrapper>
			</PageWrapper>
		</ThemeProvider>
	)
}
function CustomToolbar() {
	return (
		<GridToolbarContainer>
			<GridToolbarExport />
		</GridToolbarContainer>
	)
}
