import { ThemeProvider } from '@emotion/react'
import '@inovua/reactdatagrid-community/index.css'
import { MenuItem, Typography } from '@mui/material'
import { GridColDef } from '@mui/x-data-grid-pro'
import saveAs from 'file-saver'
import { Dispatch, SetStateAction, useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router'
import { useActions } from '../../hooks/useActions'
import { useTypedSelector } from '../../hooks/useTypedSelector'
import { CampaignInterface } from '../../models/model.interface'
import { fetchData } from '../../utils/helpers/navigationHelper'
import {
	findUsernameByEmail,
	generateRowsWithIds,
	searchFor,
} from '../../utils/helpers/tableHelper'
import { WarningPrompt } from '../../utils/notifications/WarningPrompt'
import theme from '../../utils/theme2024'
import { PageWrapper, TableWrapper } from '../components/reusableComponents'
import { ActiveChip, DisabledChip } from '../publishers/PublisherComponents'
import CampaignAdd from './CampaignAdd'
import { getApplicableRules } from '../../utils/helpers/helperFuncs'
import { StatusTag } from '../components/Informative'
import { TableComponent } from '../components/TableComponents'
import { ActionsButton, SimpleActionsButton } from '../components/Buttons'
import { ReactComponent as PreviewIcon } from '../../assets/svg/preview-icon.svg'
import { ReactComponent as DuplicateIcon } from '../../assets/svg/duplicate-icon.svg'
import { ReactComponent as EditIcon } from '../../assets/svg/edit-icon.svg'
import { ReactComponent as DeleteIcon } from '../../assets/svg/delete-icon.svg'
import { ReactComponent as AndroidIcon } from '../../assets/svg/android-icon.svg'
import AppleIcon from '@mui/icons-material/Apple'
import defaultIcon from '../../assets/default-icon.webp'
import { countryList } from '../components/countryList'
import { HeaderFilterComponent } from '../components/TableHelperComponents'

const CampaignPage = (props: {
	children?: any
	setLoading?: any
	errorMessage: null | string
	setErrorMessage: any
	isErrorPromptOpened: boolean
	setIsErrorPromptOpened: any
	isSuccessPromptOpened: any
	setIsSuccessPromptOpened: any
	isEditorOpened?: any
	PopUpVisible: boolean
	setPopUpVisible: any
	forcedFilterStatus: number
	search: string
	setSearch: Dispatch<SetStateAction<string>>
	presetSearch: string
	setPresetSearch: Dispatch<SetStateAction<string>>
}) => {
	const navigate = useNavigate()
	const params = useParams()

	const rowCount = 7
	const { campaign, login, users, app, settings } = useTypedSelector(
		(state) => state,
	)
	const {
		getCampaignAction,
		deleteCampaign,
		getCampaignsInternalReport,
		getCampaignsExternalWithCostReport,
		getCampaignsExternalWithoutCostReport,
		getAppsIcons,
		insertLog,
	} = useActions()
	const sessionKey = 'filters-campaigns-table'
	const sessionSerializedFilters = sessionStorage.getItem(sessionKey)
	let sessionFilters: any = {}
	if (sessionSerializedFilters && sessionSerializedFilters.length > 4) {
		sessionFilters = JSON.parse(sessionSerializedFilters)
	}
	const [pageSize, setPageSize] = useState(15)
	const [campaignsGroup, setCampaignsGroup] = useState<any[]>([])
	// const [PopUpVisible, setPopUpVisible] = useState(props.isEditorOpened)
	const [row, setRow] = useState<CampaignInterface | undefined>(undefined)
	const [isAdding, setIsAdding] = useState(false)
	const [sortModel, setSortModel] = useState([
		{ field: 'date_added', sort: 'asc' },
	])
	const [columnVisibility, setColumnVisibility] = useState({
		date_added: false,
	})
	// const [found, setFound] = useState<any[]>(
	// 	searchFor(search, campaign.campaign),
	// )
	const [filteredCampaigns, setFilteredCampaigns] = useState<
		CampaignInterface[]
	>(
		campaign.campaign.filter(
			(el: any) => el.status === true && el.role === 'M',
		),
	)
	const [update, setUpdate] = useState(false)
	const [campaignFilterStatus, setCampaignFilterStatus] = useState(1)
	const [isWarningPromptOpened, setIsWarningPromptOpened] = useState(false)
	const [duplicating, setDuplicating] = useState<boolean>(false)
	const [rowWidth, setRowWidth] = useState(
		Math.floor((window.innerWidth - theme.dimentions.drawerWidth) / rowCount),
	)
	const [applyFilter, setApplyFilter] = useState(false)
	const [uuidOfDuped, setUuidOfDuped] = useState(null)

	const [fileDownload, setFileDownload] = useState()
	const headers = {
		Authorization: `Token ${login.user.token}`,
	}
	const [tableRows, setTableRows] = useState(
		generateRowsWithIds(filteredCampaigns),
	)
	const [customFilter, setCustomFilter] = useState<any>(
		sessionFilters.customFilter
			? sessionFilters.customFilter
			: {
					campaign_name: [],
					advertiser_name: [],
					email: [],
					country_resume: [],
			  },
	)

	const activeUsersEmails = new Set(
		tableRows.map((element: any) => element.email),
	)
	const getActiveUsers = (emailsSet: any) => {
		const result = []
		for (const email of emailsSet) {
			for (const user of users.users) {
				if (email === user.email) {
					result.push(user)
					break
				}
			}
		}
		return result
	}
	const [optionsFilters, setOptionsFilter] = useState({
		email: getActiveUsers(activeUsersEmails),
		advertiser_name: new Set(
			tableRows
				.map((element: any) => element.advertiser_name)
				.filter((el) => el.length > 0),
		),
		campaign_name: new Set(
			tableRows
				.map((element: any) => element.campaign_name)
				.filter((el) => el.length > 0),
		),
		country_resume: countryList,
	})
	const [currentFilters, setCurrentFilters] = useState('')
	const [filterLabel, setFilterLabel] = useState('')
	const [unfiltered, setUnfiltered] = useState<any>([])
	const [openFilter, setOpenFilter] = useState(false)
	const possibleCampaignFilter = ['both', 'active', 'disabled']

	useEffect(() => {
		if (props.forcedFilterStatus !== 1) {
			setCampaignFilterStatus(props.forcedFilterStatus)
		}
	}, [props.forcedFilterStatus])

	useEffect(() => {
		props.setLoading(true)
		let filtered: any
		let data = campaign.campaign
		if (customFilter.country_resume && customFilter.country_resume.length > 0) {
			data = updateCountryData(campaign.campaign)
		}
		if (props.search.length > 0) {
			let searched = searchFor(props.search, data, [
				...columns,
				{ field: 'campaign_name' },
				{ field: 'country' },
			])
			if (possibleCampaignFilter[campaignFilterStatus] === 'both') {
				filtered = searched.filter((el: any) => el.role === 'M')
			} else if (possibleCampaignFilter[campaignFilterStatus] === 'active') {
				filtered = searched.filter(
					(el: any) => el.status === true && el.role === 'M',
				)
			} else if (possibleCampaignFilter[campaignFilterStatus] === 'disabled') {
				filtered = searched.filter(
					(el: any) => el.status === false && el.role === 'M',
				)
			}
		} else {
			if (possibleCampaignFilter[campaignFilterStatus] === 'both') {
				filtered = data.filter((el: any) => el.role === 'M')
			} else if (possibleCampaignFilter[campaignFilterStatus] === 'active') {
				filtered = data.filter(
					(el: any) => el.status === true && el.role === 'M',
				)
			} else if (possibleCampaignFilter[campaignFilterStatus] === 'disabled') {
				filtered = data.filter(
					(el: any) => el.status === false && el.role === 'M',
				)
			}
		}
		let anyfilters = false
		let additionalFiltersApplied = []

		for (let [index, data] of filtered.entries()) {
			let found = false
			let foundWrong = false

			for (const key in customFilter) {
				let wrongKey = false
				let foundByKey = false

				if (customFilter[key].length > 0) {
					anyfilters = true
					foundByKey = customFilter[key].includes(data[key])
					if (key === 'email') {
						const userFound = users.users.find(
							(e: any) =>
								customFilter[key].includes(e.name) && data.email === e.email,
						)
						foundByKey = !!userFound
					}
					if (key === 'country_resume') {
						// console.log(customFilter[key], data['allCountries'])
						for (const country of customFilter[key]) {
							if (
								data['allCountries'].includes(
									country.toLowerCase().substring(0, 2),
								) ||
								data['allCountries'].includes('')
							) {
								foundByKey = true
								break
							}
						}
					}
					if (!foundByKey) {
						foundWrong = true
						break
					}
				}
			}
			if (!foundWrong) {
				additionalFiltersApplied.push(data)

				continue
			}
		}
		let arr = []
		if (!anyfilters) {
			arr = generateRowsWithIds(filtered)
		} else if (additionalFiltersApplied.length > 0 && anyfilters) {
			arr = generateRowsWithIds(additionalFiltersApplied)
		} else {
			arr = generateRowsWithIds([])
		}

		setTableRows(arr)
		props.setLoading(false)
	}, [campaignFilterStatus, props.search, applyFilter, campaign.campaign])

	// useEffect(() => {
	// 	let multipleFilter = false
	// 	for (const key in customFilter) {
	// 		if (customFilter[key as keyof typeof customFilter].length > 0) {
	// 			//the idea here is that if multipleFilter turns true ONCE we still want to have all the filters options.
	// 			//so we are only doing the setter once the multiple is true (aka two or more filters are in)
	// 			if (multipleFilter) {
	// 				setOptionsFilter({
	// 					email: getActiveUsers(activeUsersEmails),
	// 					advertiser_name: new Set(
	// 						tableRows
	// 							.map((element: any) => element.advertiser_name)
	// 							.filter((el) => el.length > 0),
	// 					),
	// 				})
	// 				break
	// 			}
	// 			multipleFilter = true
	// 			const newObj: any = { ...optionsFilters }
	// 			for (const newKey in newObj) {
	// 				if (newKey !== key) {
	// 					if (newKey !== 'email') {
	// 						newObj[newKey as keyof typeof newObj] = new Set(
	// 							tableRows
	// 								.map((element: any) => element[newKey])
	// 								.filter((el) => el.length > 0),
	// 						)
	// 						setOptionsFilter(newObj)
	// 					} else {
	// 						newObj.email = getActiveUsers(activeUsersEmails)
	// 						setOptionsFilter(newObj)
	// 					}
	// 				}
	// 			}
	// 		}
	// 	}
	// }, [tableRows])

	useEffect(() => {
		if (!props.PopUpVisible && params.id) {
			console.log('2')

			// const found = campaign.campaign.find((e: any) => e.uuid === params.id)
			handleEdit(params.id)
		}
	}, [])
	const ruleSet = getApplicableRules(users, login, settings, 'campaign')

	const handlePreview = (uuid: any) => {
		navigate('/campaigns/' + uuid + '/preview')
		props.setPopUpVisible(!props.PopUpVisible)
	}

	const actionOptions = (row: any) => {
		return [
			{
				label: '',
				icon: (
					<PreviewIcon
						style={{ color: theme.colors.text.titles, scale: '10' }}
						className={'action-button'}
					/>
				),
				onClick: () => handlePreview(row.row.uuid),
				rule: ruleSet.rules.preview,
				key: 'preview',
			},
			{
				label: '',
				icon: (
					<EditIcon
						style={{ color: theme.colors.text.titles, scale: '7.2' }}
						className={'action-button'}
					/>
				),
				onClick: () => handleEdit(row.row.uuid),
				rule: ruleSet.post,
				key: 'edit',
			},
			{
				label: '',
				icon: (
					<DuplicateIcon
						style={{ color: theme.colors.text.titles, scale: '8' }}
						className={'action-button'}
					/>
				),
				onClick: () => handleDuplicate(row.row),
				rule: ruleSet.post,
				key: 'duplicate',
			},
			{
				label: '',
				icon: (
					<DeleteIcon
						style={{ color: theme.colors.text.titles, scale: '7.4' }}
						className={'action-button'}
					/>
				),
				onClick: () => {
					setIsWarningPromptOpened(true)
					setRow(row.row)
				},
				rule: ruleSet.delete,
				key: 'delete',
			},
		]
		// return ruleSet.post || ruleSet.delete || ruleSet.rules.preview ? (
		// 	<div>
		// 		{ruleSet.rules.preview && (
		// 			<MenuItem onClick={() => handlePreview(row.row.uuid)}>
		// 				Preview
		// 			</MenuItem>
		// 		)}

		// 		{ruleSet.post && (
		// 			<MenuItem onClick={() => handleEdit(row.row.uuid)}>Edit</MenuItem>
		// 		)}
		// 		{ruleSet.post && (
		// 			<MenuItem onClick={() => handleDuplicate(row.row)}>
		// 				Duplicate
		// 			</MenuItem>
		// 		)}
		// 		{ruleSet.delete && (
		// 			<MenuItem
		// 				onClick={() => {
		// 					setIsWarningPromptOpened(true)
		// 					setRow(row.row)
		// 				}}
		// 			>
		// 				Delete
		// 			</MenuItem>
		// 		)}
		// 	</div>
		// ) : (
		// 	<></>
		// )
	}
	const dateSortComparator = (v1: string, v2: string) => {
		const date1 = new Date(v1)
		const date2 = new Date(v2)
		if (date1 < date2) return 1
		if (date1 > date2) return -1
		return 0
	}
	const columns: GridColDef[] = [
		// {
		// 	field: 'icon',
		// 	headerName: '',
		// 	align: 'center',
		// 	width: rowWidth / 5,
		// 	renderCell: (row) => (
		// 		<img
		// 			alt={''}
		// 			src={row.row.icon}
		// 			style={{
		// 				height: '28px',
		// 				borderRadius: '16px',
		// 			}}
		// 		></img>
		// 	),
		// },
		// {
		// 	field: 'campaign_name',
		// 	headerName: 'Campaign Name',
		// 	width: rowWidth * 2,
		// 	renderCell: (row) => {
		// 		console.log(row.row)
		// 		return (
		// 			<Typography sx={{ fontWeight: 600, fontSize: 14 }}>
		// 				{row.row.campaign_name}
		// 			</Typography>
		// 		)
		// 	},
		// },
		{
			field: 'campaign_name',
			headerName: 'App Name',
			width: rowWidth * 1.4,
			renderCell: (row) => {
				const value = deconstructCampaignName(row.row.campaign_name)
				const iconSrc = row.row.icon ? row.row.icon : defaultIcon
				return (
					<div
						style={{
							display: 'flex',
							flexDirection: 'row',
							alignItems: 'center',
							gap: 12,
						}}
					>
						<img
							alt={''}
							src={iconSrc}
							style={{
								height: '28px',
								borderRadius: '16px',
							}}
						></img>
						<Typography sx={{ fontWeight: 600, fontSize: 14 }}>
							{value.appName}
						</Typography>
					</div>
				)
			},
		},

		// {
		// 	field: 'date_added',
		// 	headerName: 'date added',
		// 	width: rowWidth,
		// 	sortComparator: dateSortComparator,
		// },
		{
			field: 'advertiser_name',
			headerName: 'Advertiser',
			width: rowWidth * 1,
			renderHeader: () => {
				return (
					<HeaderFilterComponent
						label={'Advertiser'}
						width={rowWidth * 1.2}
						openFilter={openFilter}
						setOpenFilter={setOpenFilter}
						field={'advertiser_name'}
						customFilter={customFilter}
						currentFilters={currentFilters}
						setCurrentFilters={setCurrentFilters}
						setFilterLabel={setFilterLabel}
						optionsFilters={optionsFilters}
					/>
				)
			},
		},
		{
			field: 'email',
			headerName: 'Owner',
			width: rowWidth * 0.8,
			renderHeader: () => {
				return (
					<HeaderFilterComponent
						label={'Owner'}
						width={rowWidth * 1.2}
						openFilter={openFilter}
						setOpenFilter={setOpenFilter}
						field={'email'}
						customFilter={customFilter}
						currentFilters={currentFilters}
						setCurrentFilters={setCurrentFilters}
						setFilterLabel={setFilterLabel}
						optionsFilters={optionsFilters}
					/>
				)
			},
			renderCell: ({ value }) =>
				value ? findUsernameByEmail(value.toString(), users.users) : 'User',
		},
		{
			field: 'country_resume',
			headerName: 'Country',
			width: rowWidth * 0.5,
			renderCell: (row) => {
				let value = deconstructCampaignName(row.row.campaign_name)
				return (
					<Typography sx={{ fontWeight: 600, fontSize: 14 }}>
						{value.geo}
					</Typography>
				)
			},
			renderHeader: () => {
				return (
					<HeaderFilterComponent
						label={'Country'}
						width={rowWidth * 1.2}
						openFilter={openFilter}
						setOpenFilter={setOpenFilter}
						field={'country_resume'}
						customFilter={customFilter}
						currentFilters={currentFilters}
						setCurrentFilters={setCurrentFilters}
						setFilterLabel={setFilterLabel}
						optionsFilters={optionsFilters}
					/>
				)
			},
			valueGetter: (row) => deconstructCampaignName(row.row.campaign_name).geo,
			// sortComparator: (str1, str2) => {
			// 	console.log(str1, str2)
			// },
		},
		{
			field: 'status',
			headerName: 'Status',
			align: 'center',
			headerAlign: 'center',
			width: rowWidth * 0.8,
			renderCell: ({ value }) =>
				value ? (
					// <ActiveChip label='Active' />
					<StatusTag
						label={'Active'}
						style={{
							color: theme.colors.text.colored,
							fontWeight: theme.font.weight.normal,
						}}
					/>
				) : (
					// <DisabledChip label='Disabled' style={{ marginLeft: '10px' }} />
					<StatusTag label={'Disabled'} />
				),
		},
		{
			field: 'tag',
			headerName: 'Tag',
			width: rowWidth * 0.4,
			renderCell: (row) => {
				const value = deconstructCampaignName(row.row.campaign_name)
				return (
					<Typography sx={{ fontWeight: 600, fontSize: 14 }}>
						{value.tag}
					</Typography>
				)
			},
		},
		{
			field: 'flow',
			headerName: 'Flow',
			width: rowWidth * 0.4,
			renderCell: (row) => {
				const value = deconstructCampaignName(row.row.campaign_name)
				return (
					<Typography sx={{ fontWeight: 600, fontSize: 14 }}>
						{value.flow}
					</Typography>
				)
			},
		},
		{
			field: 'app_id',
			headerName: 'Operated System',
			align: 'center',
			headerAlign: 'center',
			width: rowWidth * 1,
			sortComparator: (str1) => {
				if (str1.startsWith('id')) {
					return 1
				} else {
					return -1
				}
			},
			renderCell: ({ row }) => {
				// console.log(row)
				let androidActive = false
				let iOSActive = false
				if (row.app_id.startsWith('id')) {
					iOSActive = true
				} else {
					androidActive = true
				}
				return (
					<div style={{ display: 'flex', justifyContent: 'center' }}>
						{iOSActive && (
							<AppleIcon
								style={{
									color: theme.colors.status.green,
									scale: '1.3',
								}}
							/>
						)}
						{androidActive && (
							<AndroidIcon
								style={{
									color: theme.colors.status.green,
									scale: '0.9',
								}}
							/>
						)}
					</div>
				)
			},
		},

		(ruleSet.post || ruleSet.delete || ruleSet.rules.preview) && {
			field: 'actions',
			headerName: 'Actions',
			resizable: false,
			align: 'center',
			headerAlign: 'center',
			// width: rowWidth * 0.1,
			sortable: false,
			renderCell: (row) => (
				<ActionsButton options={actionOptions(row)} style={{ marginTop: 6 }} />
			),
		},
	]

	const handleCellClick = (params: any, ev: React.MouseEvent) => {
		if (params.field !== 'actions') {
			const uuid = params.row.uuid
			if (ruleSet.rules.preview) {
				if (ev.ctrlKey || ev.metaKey) {
					window.open('/campaigns/' + uuid + '/preview', '_blank')
					return
				}
				handlePreview(uuid)
			}
		}
	}

	const handlePopUp = async () => {
		if (props.PopUpVisible === false) {
			setDuplicating(false)
			await fetchData(login, getCampaignAction, campaign.campaign)

			//setRow(undefined)
		}
		setIsAdding(true)
		props.setPopUpVisible(!props.PopUpVisible)
	}

	const handleDuplicate = (rowPicked: any) => {
		setDuplicating(true)
		// console.log('rowPicked', rowPicked)

		setUuidOfDuped(rowPicked.uuid)
		setIsAdding(false)
		// setCampaignsGroup(
		// 	campaign.campaign.filter(
		// 		(c: CampaignInterface) => c.campaign_name === row.campaign_name,
		// 	),
		// )
		// setRow(row)
		props.setPopUpVisible(!props.PopUpVisible)
	}
	const handleLogDelete = async (headers: any, campaign: any) => {
		const log = {
			email: login.user.email,
			object: campaign! + ' in Campaigns',
			change: 'removed campaign',
		}
		await insertLog(headers, log)
	}
	const handleDelete = async (row: any) => {
		const payload = {
			uuid: row.uuid,
			user_login: login.user.email,
			campaign_field: row.campaign_name,
			rule_set: ruleSet,
		}

		props.setLoading(true)
		await deleteCampaign(headers, payload, props.setErrorMessage)
		await fetchData(login, getCampaignAction, campaign.campaign)
		setUpdate(!update)
		handleLogDelete(headers, row.campaign_name)
		props.setLoading(false)
	}
	for (const app_id of app.appIcons) {
		for (const camp of campaign.campaign) {
			if (app_id.app_id == camp.app_id) {
				if (app_id.icon_url && app_id.icon_url !== '') {
					camp.icon = app_id.icon_url
				}
			}
		}
	}

	const handleEdit = (row: any) => {
		let found_campaign: any = null

		if (typeof row === 'string') {
			found_campaign = campaign.campaign.find((e: any) => e.uuid === row)
		}

		for (const app_id of app.appIcons) {
			if (!found_campaign && app_id.app_id === row.app_id) {
				row.icon = app_id.icon_url
			}
		}
		setDuplicating(false)
		setIsAdding(false)
		// setCampaignsGroup(
		// 	campaign.campaign.filter(
		// 		(c: CampaignInterface) =>
		// 			c.campaign_name === found_campaing.campaign_name,
		// 	),
		// )
		// setRow(row)

		navigate('/campaigns/' + found_campaign.uuid)
		props.setPopUpVisible(!props.PopUpVisible)
	}
	const handleLoading = async () => {
		props.setLoading(true)
		await fetchData(login, getAppsIcons, app.appIcons)
		await fetchData(login, getCampaignAction, campaign.campaign)
		props.setLoading(false)
		const sessionSerializedFilters = sessionStorage.getItem(sessionKey)
		if (sessionSerializedFilters && sessionSerializedFilters.length > 4) {
			const sessionFilters = JSON.parse(sessionSerializedFilters)
			setCurrentFilters('advertiser')
			sessionFilters.customFilter &&
				setCustomFilter(sessionFilters.customFilter)
		}
		if (props.presetSearch !== '') {
			props.setSearch(props.presetSearch)
			props.setPresetSearch('')
		}
	}

	useEffect(() => {
		handleLoading()
	}, [])

	useEffect(() => {
		if (filterLabel) {
			const filters = {
				customFilter,
			}
			const serializedFilters = JSON.stringify(filters)
			sessionStorage.setItem(sessionKey, serializedFilters)
		}
	}, [applyFilter])

	// useEffect(() => {
	// 	if (search.length === 0 && props.presetSearch !== '') {
	// 		props.setPresetSearch('')
	// 	}
	// }, [search])
	useEffect(() => {
		setFilteredCampaigns(
			filteredCampaigns.filter((c: CampaignInterface) => c.role === 'M'),
		)
	}, [campaign.campaign])
	const handleApplyFilter = () => {
		setApplyFilter(!applyFilter)
	}
	useEffect(() => {
		if (fileDownload) {
			saveAs(fileDownload, '*.csv')
		}
	}, [fileDownload])
	// useEffect(() => {
	// 	const fetchUpdate = async () => {
	// 		// setRow(undefined)
	// 		await fetchData(login, getCampaignAction, campaign.campaign)
	// 		setUpdate(!update)
	// 	}
	// 	if (!props.PopUpVisible) {
	// 		console.log('2')

	// 		fetchUpdate()
	// 	}
	// }, [props.PopUpVisible])

	const getUuidArray = (rows: any) => {
		return rows.map((el: any) => "'" + el.uuid + "'")
	}
	const downloadButtonoptions = (
		<div>
			<MenuItem
				onClick={() =>
					getCampaignsInternalReport(
						setFileDownload,
						getUuidArray(tableRows),
						headers,
						props.setErrorMessage,
					)
				}
			>
				Campaign list (Internal use)
			</MenuItem>
			<MenuItem
				onClick={() =>
					getCampaignsExternalWithCostReport(
						setFileDownload,
						getUuidArray(tableRows),
						headers,
						props.setErrorMessage,
					)
				}
			>
				Campaign list (External use with cost)
			</MenuItem>
			<MenuItem
				onClick={() =>
					getCampaignsExternalWithoutCostReport(
						setFileDownload,
						getUuidArray(tableRows),
						headers,
						props.setErrorMessage,
					)
				}
			>
				Campaign list (External use without cost)
			</MenuItem>
		</div>
	)

	return !props.PopUpVisible ? (
		<ThemeProvider theme={theme}>
			<PageWrapper>
				{/* <Grid
					container
					spacing={0}
					direction={'row'}
					alignItems={'center'}
					width={'100%'}
				>
					<Grid item xs={6}>
						<GradientedText>
							<TitleComponent>Campaigns</TitleComponent>
						</GradientedText>
					</Grid>
					<Grid item xs={3}>
						<TableSearchComponent setSearch={setSearch} search={search} />
					</Grid>
					<Grid item xs={1}>
						<AddButton
							onClick={() => props.setPopUpVisible(!props.PopUpVisible)}
						>
							+ Add
						</AddButton>
					</Grid>
					<Grid item xs={1}>
						<AddButton
							onClick={() =>
								getCampaignsReport(
									setFileDownload,
									headers,
									props.setErrorMessage,
								)
							}
						>
							<DownloadIcon />
						</AddButton>
					</Grid>
				</Grid> */}

				{ruleSet.get && (
					<TableWrapper
						theme={theme}
						rowCount={rowCount}
						setRowWidth={setRowWidth}
						style={{ paddingBottom: '60px', width: '80%' }}
					>
						<TableComponent
							columns={columns}
							rows={tableRows}
							setPageSize={setPageSize}
							pageSize={pageSize}
							rowsPerPageOptions={[15, 25, 50]}
							setLoading={props.setLoading}
							filterStatus={campaignFilterStatus}
							setFilterStatus={setCampaignFilterStatus}
							handlePopUp={
								ruleSet.post && ruleSet.rules['adding new campaigns']
									? handlePopUp
									: undefined
							}
							downloadButtonOptions={
								ruleSet.rules[
									'download a campaign report (and which types of report)'
								]
									? downloadButtonoptions
									: undefined
							}
							sortModel={sortModel}
							onSortModelChange={(model: any) => setSortModel(model)}
							filterHandler={openFilter}
							setFilterHander={setOpenFilter}
							customFilter={customFilter}
							setCustomFilter={setCustomFilter}
							currentFilters={currentFilters}
							applyFilter={handleApplyFilter}
							filterLabel={filterLabel}
							optionsFilters={optionsFilters}
							onCellClick={ruleSet.get ? handleCellClick : undefined}
							columnVisibility={columnVisibility}
						></TableComponent>
					</TableWrapper>
				)}
				{/* <ReactDataGrid
					idProperty='id'
					columns={columns}
					style={{ height: '80vh', marginTop: '40px' }}
					shareSpaceOnResize
					dataSource={generateRowsWithIds(
						search.length > 0 ? found : filteredCampaigns,
					)}
				/> */}
				<WarningPrompt
					message={'Deleting the row now?'}
					PopUpVisible={isWarningPromptOpened}
					setPopUpVisible={setIsWarningPromptOpened}
					onAgree={() => handleDelete(row)}
					onCancel={() => props.setLoading(false)}
					obj={row}
				/>
			</PageWrapper>
		</ThemeProvider>
	) : (
		<CampaignAdd
			PopUpVisible={props.PopUpVisible}
			isAdding={isAdding}
			setPopUpVisible={handlePopUp}
			row={row}
			setRow={setRow}
			setLoading={props.setLoading}
			setDuplicating={setDuplicating}
			duplicating={duplicating}
			campaignsGroup={campaignsGroup}
			setCampaignsGroup={setCampaignsGroup}
			fetchData={async () =>
				await fetchData(login, getCampaignAction, campaign.campaign)
			}
			handleDelete={handleDelete}
			errorMessage={props.errorMessage}
			setErrorMessage={props.setErrorMessage}
			isErrorPromptOpened={props.isErrorPromptOpened}
			setIsErrorPromptOpened={props.setIsErrorPromptOpened}
			isSuccessPromptOpened={props.isSuccessPromptOpened}
			setIsSuccessPromptOpened={props.setIsSuccessPromptOpened}
			uuidOfDuped={uuidOfDuped}
		/>
	)
}

export default CampaignPage

const deconstructCampaignName = (campaignName: string) => {
	const arr = campaignName.split(' - ')
	const geos = arr[1].split(',')
	let multiGeo = false
	if (geos.length > 3) {
		multiGeo = true
	}
	const result = {
		appName: arr[0],
		geo: multiGeo ? 'MultiGeo' : arr[1],
		system: arr[2],
		flow: arr[3],
		tag: arr[4],
	}

	return result
}

function updateCountryData(objects: any) {
	// This object will store all countries indexed by main_uuid
	const countryMapping: any = {}

	// First, gather all countries for each main_uuid, excluding the 'M' role objects
	objects.forEach((obj: any) => {
		if (!countryMapping[obj.main_uuid]) {
			countryMapping[obj.main_uuid] = new Set()
		}
		obj.country.forEach((country: any) =>
			countryMapping[obj.main_uuid].add(country),
		)
	})

	// Now, update the 'M' role objects with the collected countries
	objects.forEach((obj: any) => {
		if (obj.role === 'M' && countryMapping[obj.main_uuid]) {
			// Combine the existing countries of the 'M' role object with the collected ones
			obj.allCountries = Array.from(countryMapping[obj.main_uuid])
		}
	})

	// Optional: return the modified objects array if needed
	return objects
}
