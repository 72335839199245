import axios from 'axios'
import { devUrl } from '../config'

const Instance = axios.create({
	baseURL: window.location.href.includes('stage')
		? 'http://stage-main-1529679591.eu-central-1.elb.amazonaws.com/api/'
		: window.location.href.includes('varys')
		? 'https://varys.io/api/'
		: devUrl,
	headers: {
		Accept: 'application/json',
		'Content-type': 'application/json',
		'Access-Control-Allow-Origin': '*',
		'Access-Control-Allow-Methods': 'GET,PUT,POST,DELETE',
		'X-Requested-With': '*',
	},
})

export default Instance
