import {
	Card,
	FormControlLabel,
	List,
	ListItemButton,
	ListItemButtonProps,
} from '@mui/material'
import { styled } from '@mui/material/styles'
import theme from '../../utils/theme2024'

export const StyledCheckboxFormControl = styled(FormControlLabel)(() => ({
	color: theme.colors.base.white,
	fontSize: '13px',
	maxWidth: '280px',
	fontWeight: 600,
	'&.MuiTypography-root': {
		fontSize: '13px',
	},
}))

export const StyledList = styled(List)(() => ({
	width: '99%',
	height: '290px',
	overflow: 'auto',
}))

export const StyledListContainer = (props: {
	children: JSX.Element[]
	position?: string
}) => {
	const Comp = styled(Card)(() => ({
		textAlign: 'left',
		minHeight: '280px',
		maxHeight: '300px',
		width: '98%',
		fontWeight: '600',
		borderRadius: theme.dimentions.biggerRadius,
		boxShadow: props.position === 'left' ? theme.shadow.lightShadow : '2px',
	}))
	return <Comp>{props.children}</Comp>
}

export const StyledListItemButton = (
	props: ListItemButtonProps & {
		children?: any
		selected?: boolean
		button?: boolean
		disabled?: boolean
		dataObj: any
		value: any
	},
) => {
	const Component = styled(ListItemButton)(() => ({
		background: theme.colors.base.white,
		color: props.selected
			? theme.colors.base.green400 + '!important'
			: 'inherit',
		boxShadow: 'none',
		textAlign: 'left',
	}))
	return (
		<Component
			divider
			key={props.value}
			disabled={props.disabled}
			onClick={props.onClick}
		>
			{props.children}
		</Component>
	)
}
