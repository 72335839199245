import ArrowBackIcon from '@mui/icons-material/ArrowBack'
import VisibilityIcon from '@mui/icons-material/Visibility'
import { Box, DialogActions, ThemeProvider, Typography } from '@mui/material'
import _ from 'lodash'
import React, { useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router'
import { v4 as uuidv4 } from 'uuid'
import { useActions } from '../../hooks/useActions'
import { useTypedSelector } from '../../hooks/useTypedSelector'
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos'
import {
	getApplicableRules,
	handleIconUrl,
	isValidImageType,
	stringIsValidName,
} from '../../utils/helpers/helperFuncs'
import {
	checkInputStartsWithHttp,
	shallowEqual,
} from '../../utils/helpers/tableHelper'
import { StyledTab, StyledTabs } from '../advertisers/AdvertiserStyled'
import {
	ContactInterface,
	ContactsComponent,
} from '../components/ContactComponent'
import { PageWrapper } from '../components/reusableComponents'
import { PublisherAllowedPlatformsComponent } from './PublisherAllowedPlatformsComponent'
import { PublisherCompanyInfoComponent } from './PublisherCompanyInfoComponent'
import { FinanceInfo } from './PublisherFinanceComponent'
import { TabContainer } from '../components/TabComponent'
import { SubmitButton, UnborderedButton } from '../components/Buttons'
import theme from '../../utils/theme2024'
import EditPageHeader from '../components/EditPageHeader'
import { deleteIcon, uploadIcon } from '../../state/action-creators'
import { PublisherInterface } from '../../models/model.interface'
import defaultIcon from '../../assets/default-icon.webp'
import CompanyInformationHeader from '../components/CompanyInformationHeader'
import CustomModal from '../components/CustomModal'
import { CheckCircle } from '@mui/icons-material'
import { ACCEPTED_FILES } from '../../config'
import CustomFileUploader from '../components/CustomFileUploader'
import CustomDialog from '../components/CustomDialog'
import { CSSProperties } from 'styled-components'
import { editPageTopPanelStyle } from '../../utils/helpers/commonStyles'
import Tooltip from '@mui/material/Tooltip'
import HandshakeEdit from '../handshake/HandshakeEdit'

export interface AllowedPlatform {
	title: string
	value: boolean
}
interface TabPanelProps {
	children?: React.ReactNode
	index: number
	value: number
}

function TabPanel(props: TabPanelProps) {
	const { children, value, index, ...other } = props

	return (
		<div
			role='tabpanel'
			hidden={value !== index}
			id={`simple-tabpanel-${index}`}
			aria-labelledby={`simple-tab-${index}`}
			{...other}
		>
			{value === index && (
				<Box sx={{ p: 3 }}>
					<Typography component={'span'}>{children}</Typography>
				</Box>
			)}
		</div>
	)
}

//this is the main pop-up that renders either when we edit or create a new publisher.
//one of the main differences between advertiser and publisher is that we have media plan for publishers.
const PublisherPopUp = (props: {
	nullify?: any
	PopUpVisible: boolean
	setPopUpVisible: any
	setLoading: any
	row?: any
	errorMessage: null | string
	setErrorMessage: any
	isErrorPromptOpened: boolean
	setIsErrorPromptOpened: any
	isSuccessPromptOpened: any
	setIsSuccessPromptOpened: any
	loginRole: string
	reloadRow: any
}): JSX.Element => {
	const {
		insertPublisherAction,
		updatePublisherAction,
		insertLog,
		removeAppP360,
		uploadIcon,
		deleteIcon,
	} = useActions()
	const navigate = useNavigate()
	const params = useParams()

	const countryData = [
		{ value: 'global', payout: '', volume: '' },
		{ value: 'Antartica', payout: '', volume: '' },
		{ value: 'Africa', payout: '', volume: '' },
		{ value: 'Asia', payout: '', volume: '' },
		{ value: 'Europe', payout: '', volume: '' },
		{ value: 'North America', payout: '', volume: '' },
		{ value: 'Oceania', payout: '', volume: '' },
		{ value: 'South America', payout: '', volume: '' },
	]
	const { login, settings, publisher, users } = useTypedSelector(
		(state) => state,
	)
	const ruleSet = getApplicableRules(users, login, settings, 'publisher')
	const [name, setName] = useState<string>('')
	const [legalName, setLegalName] = useState<string>('')
	const [address, setAddress] = useState<string>('')
	const [financeId, setFinanceId] = useState<string>('')
	const [geo, setGeo] = useState<string[]>([])
	const [linkToIO, setLinkToIO] = useState('')
	const [salesRep, setSalesRep] = useState('')
	const [status, setStatus] = useState(true)
	const [type, setType] = useState<string>('')
	const [linkToAdvFile, setLinkToAdvFile] = useState('')
	const [linkToCreative, setLinkToCreative] = useState('')
	const [platforms, setPlatforms] = useState<string | null>(null)
	const [ownerEmail, setOwnerEmail] = useState<string | null>(login.user.email)
	const [pids, setPIDs] = useState<string[]>([])
	const [contacts, setContacts] = useState<any[]>([])
	const [isValid, setIsValid] = useState<boolean>(false)
	const [badgeGoodQuality, setBadgeGoodQuality] = useState<boolean>(false)
	const [badgeLowFraud, setBadgeLowFraud] = useState<boolean>(false)
	const [badgeHighVolume, setBadgeHighVolume] = useState<boolean>(false)
	const [badgeHighCR, setBadgeHighCR] = useState<boolean>(false)
	const [skypeGroup, setSkypeGroup] = useState<string>('')
	const [internalSegment, setInternalSegment] = useState<string[]>([])
	const [prevState, setPrevState] = useState<any>(null)
	const [notes, setNotes] = useState<string>('')
	const [capabilities, setCapabilities] = useState<AllowedPlatform[]>([])
	const [tab, setTab] = useState(0)
	const [linkToPubFile, setLinkToPubFile] = useState<string>('')
	const [linkToLinkedin, setLinkToLinkedin] = useState<string>('')
	const [linkToWebsite, setLinkToWebsite] = useState<string>('')
	const [freeText, setFreeText] = useState<string>('')
	const [vat, setVat] = useState<string>('')
	const [createdBy, setCreatedBy] = useState<string>('')
	const [externalId, setExternalId] = useState<number | null>(null)
	const [externalIdInUse, setExternalIdInUse] = useState<boolean>(false)
	const [financeIdInUse, setFinanceIdInUse] = useState<boolean>(false)
	const [publicSkype, setPublicSkype] = useState<boolean>(true)
	const [mainCategory, setMainCategory] = useState('')
	const [mainHeightChange, setMainHeightChange] = useState<boolean>(false)
	const [deletedContactList, setDeletedContactList] = useState<any>([])
	const [vertical, setVertical] = useState([])
	const [selectedCountryElements, setSelectedCountryElements] = useState([])
	const [selectedGeneralCountryData, setSelectedGeneralCountryData] =
		useState(countryData)
	const [selectedFraudTools, setSelectedFraudTools] = useState([])
	const [selectedTypeOfPublisher, setSelectedTypeOfPublisher] = useState([])
	const [selectedAdType, setSelectedAdType] = useState([])
	const [selectedDevices, setSelectedDevices] = useState([])
	const [selectedPaymentModel, setSelectedPaymentModel] = useState([])
	const [selectedAvailablePlatforms, setSelectedAvailablePlatforms] = useState(
		[],
	)
	const [selectedAbilities, setSelectedAbitilies] = useState([])
	const [selectedRelevantCategories, setSelectedRelevantCategories] = useState(
		[],
	)
	const [handShakeEditorOpen, setHandShakeEditorOpen] = useState(false)
	const [editablePid, setEditablePid] = useState<string>('')
	const [specialRequestList, setSpecialRequestList] = useState('')
	const [handshakeArr, setHandshakeArr] = useState([])
	const [selectedTrafficIndicators, setSelectedTrafficIndicators] = useState([])
	const [selectedFormatTypes, setSelectedFormatTypes] = useState([])
	const [selectedDailyCap, setSelectedDailyCap] = useState([])
	const publisherCapabilitiesMap = {
		selectedCountryElements,
		setSelectedCountryElements,
		selectedGeneralCountryData,
		setSelectedGeneralCountryData,
		selectedFraudTools,
		setSelectedFraudTools,
		selectedTypeOfPublisher,
		setSelectedTypeOfPublisher,
		selectedAdType,
		setSelectedAdType,
		selectedDevices,
		setSelectedDevices,
		selectedPaymentModel,
		setSelectedPaymentModel,
		selectedAvailablePlatforms,
		setSelectedAvailablePlatforms,
		selectedAbilities,
		setSelectedAbitilies,
		selectedRelevantCategories,
		setSelectedRelevantCategories,
		selectedTrafficIndicators,
		setSelectedTrafficIndicators,
		specialRequestList,
		setSpecialRequestList,
		selectedFormatTypes,
		setSelectedFormatTypes,
		selectedDailyCap,
		setSelectedDailyCap,
		badgeGoodQuality,
		setBadgeGoodQuality,
		badgeLowFraud,
		setBadgeLowFraud,
		badgeHighVolume,
		setBadgeHighVolume,
		badgeHighCR,
		setBadgeHighCR,
	}
	const [dataFilled, setDataFilled] = useState(false)
	const [paymentTerm, setPaymentTerm] = useState<string>('')
	const [pastOwnerFinance, setPastOwnerFinance] = useState<any>([])
	const [uploadStatus, setUploadStatus] = useState<{
		message: string
		success: boolean
	} | null>(null)

	const [isDeleteModalOpen, setDeleteModalOpen] = useState(false)
	const [isUploadModalOpen, setIsUploadModalOpen] = useState(false)
	const [pauseReason, setPauseReason] = useState('')
	const [pauseExplanation, setPauseExplanation] = useState('')

	const [loadedRow, setLoadedRow] = useState<any>(undefined)

	const [fieldValidations, setFieldValidations] = useState({
		name: false,
		legalName: false,
	})

	const row = useTypedSelector((state) =>
		state.publisher.publisher.find(
			(pub: PublisherInterface) => pub?.publisher_name?.trim() === params.id,
		),
	)

	const [iconUrl, setIconUrl] = useState<string>(row?.publisher_icon)

	const [mandatoryFields, setMandatoryFields] = useState({
		name: true,
		legalName: true,
	})

	//we use this to handle the logs.
	const preparePrevState = (prevState: any) => {
		try {
			const copiedState = _.cloneDeep(prevState)
			const { id, createdAt, name_history, ...result } = copiedState
			return result
		} catch (error) {
			return null
		}
	}

	const prepareNewState = (prevState: any) => {
		const { user_login, name_history, ...result } = prevState
		return result
	}

	const nullifyFields = () => {
		setPlatforms(null)
		setOwnerEmail(null)
		setPIDs([])
		setName('')
		setLegalName('')
		setAddress('')
		setFinanceId('')
		setGeo([])
		setLinkToIO('')
		setSalesRep('')
		setSkypeGroup('')
		setLinkToAdvFile('')
		setLinkToCreative('')
		setStatus(false)
		setFreeText('')
		setVat('')
		setLinkToPubFile('')
		setExternalId(null)
		setSelectedCountryElements([])
		setPublicSkype(true)
		// setSkypeGroupUserAllowed(false)
		setPastOwnerFinance([])
		setMainCategory('')
		setPauseReason('')
		setPauseExplanation('')
	}

	function a11yProps(index: number) {
		return {
			id: `simple-tab-${index}`,
			'aria-controls': `simple-tabpanel-${index}`,
		}
	}

	const handleChangeTab = (event: React.SyntheticEvent, newValue: number) => {
		setTab(newValue)
	}
	const handleLog = async (headers: any, payload: any) => {
		if (prevState && prevState.capabilities) {
			prevState['capabilities'] = capabilitiesFromJson(prevState?.capabilities)
		}
		const shallow = shallowEqual(prevState, prepareNewState(payload))
		if (shallow.length > 0) {
			const log = {
				email: login.user.email,
				object: name! + ' in Publishers',
				change: JSON.stringify(shallow),
			}
			await insertLog(headers, log)
		}
	}
	const trySubmit = async (isContinuing: boolean, data: any) => {
		if (isValid) {
			const mediaBuyingProps = {
				country: selectedCountryElements,
				country_general: selectedGeneralCountryData,
				type_publisher: selectedTypeOfPublisher,
				ad_type: selectedAdType,
				devices: selectedDevices,
				payment_model: selectedPaymentModel,
				available_platforms: selectedAvailablePlatforms,
				fraud_tools: selectedFraudTools,
				abilities: selectedAbilities,
				relevant_categories: selectedRelevantCategories,
				traffic_indicators: selectedTrafficIndicators,
				special_request_list: specialRequestList,
				format_types: selectedFormatTypes,
				daily_cap: selectedDailyCap,
				badges: {
					good_quality: badgeGoodQuality,
					low_fraud: badgeLowFraud,
					high_volume: badgeHighVolume,
					high_cr: badgeHighCR,
				},
			}
			let payload: any = {
				publisher_name: row?.publisher_name ?? name!,
				new_publisher_name: name!,
				user_login: login.user.email,
				email: ownerEmail,
				platform: platforms!,
				media_source_pid: pids!,
				legal_name: legalName,
				address: address,
				finance_id: financeId,
				geo: geo,
				type: type,
				internal_segment: internalSegment,
				notes: notes,
				link_to_io: linkToIO,
				sales_rep: salesRep,
				status: status,
				skype_group: skypeGroup,
				link_to_adv_file: linkToAdvFile,
				link_to_creative: linkToCreative,
				contacts: _.cloneDeep(contacts),
				capabilities: capabilities,
				payout_model: '',
				creative_needs: '',
				macro_list: '',
				link_to_pub: linkToPubFile,
				free_text: freeText,
				created_by: row ? createdBy : login.user.email,
				external_id: externalId,
				media_buying_capabilities: mediaBuyingProps,
				link_to_linkedin: linkToLinkedin,
				link_to_website: linkToWebsite,
				public_skype: publicSkype,
				payment_term: paymentTerm,
				past_owner_finance: JSON.stringify(pastOwnerFinance),
				vat,
				main_category: mainCategory,
				pause_reason: pauseReason,
				pause_explanation: pauseExplanation,
			}
			const headers = {
				Authorization: `Token ${login.user.token}`,
			}
			props.setLoading(true)
			let failed: any
			if (row) {
				failed = await updatePublisherAction(
					headers,
					payload,
					handshakeArr,
					props.setErrorMessage,
				)
				if (deletedContactList.length > 0) {
					failed += removeAppP360(
						headers,
						{ email: deletedContactList, publisher: name, direct: false },
						props.setErrorMessage,
						props.setIsSuccessPromptOpened,
					)
				}
			} else {
				failed = await insertPublisherAction(
					headers,
					payload,
					handshakeArr,
					props.setErrorMessage,
				)
			}
			setHandShakeEditorOpen(false)
			props.setLoading(false)
			if (!failed) {
				handleLog(headers, payload)
				props.setIsSuccessPromptOpened(true)
				payload.contacts = contacts
				const obj = _.cloneDeep(payload)
				const prev = preparePrevState(obj)
				setPrevState({ ...prev })
				try {
					await props.reloadRow()
					navigate('/publishers/' + name)
				} catch (e) {
					console.log(e)
				}
			}
		}
	}
	//same logic that in advertisers in order to create a new contact.
	const handleNewContact = (type: string) => {
		const new_contact: ContactInterface = {
			name: '',
			role: '',
			email: '',
			phone: '',
			skype: '',
			geo: [],
			type: type,
			uuid: uuidv4(),
		}
		let addition = [new_contact]
		if (contacts !== null && contacts !== undefined) {
			addition = [...contacts, new_contact]
		}

		setContacts(addition)
	}
	//once again, based on the user he will be able to see the skype link or not (in case it's set to private)
	// useEffect(() => {
	// 	const allowedList = []
	// 	if (settings.settings && users.users) {
	// 		for (const teamLeader of settings.settings.teams) {
	// 			for (const user of users.users) {
	// 				if (
	// 					(teamLeader === user.name ||
	// 						user.role === 'super' ||
	// 						user.role === 'admin' ||
	// 						user.position === 'publisher manager') &&
	// 					allowedList.indexOf(user.email) === -1
	// 				) {
	// 					allowedList.push(user.email)
	// 				}
	// 			}
	// 		}
	// 		setSkypeAllowedUsers(allowedList)
	// 	}
	// }, [settings, users])

	// useEffect(() => {
	// 	console.log(skypeAllowedUsers, skypeAllowedUsers.indexOf(login.user.email))
	// 	if (skypeAllowedUsers.length > 0) {
	// 		if (skypeAllowedUsers.indexOf(login.user.email) >= 0) {
	// 			setSkypeGroupUserAllowed(true)
	// 		}
	// 	}
	// }, [skypeAllowedUsers])

	//this is how we fill the data when opening a publisher.
	useEffect(() => {
		const defaultAllowedPlatforms: any = []
		for (const title of settings.settings.capabilities) {
			const obj = {
				title: title,
				value: false,
			}
			defaultAllowedPlatforms.push(obj)
		}
		const fillFields = (row: any, cap: any) => {
			setOwnerEmail(row!.email)
			setPIDs(row!.media_source_pid)
			setName(row!.publisher_name)
			setLegalName(row!.legal_name)
			setAddress(row!.address)
			setFinanceId(row!.finance_id)
			setSkypeGroup(row!.skype_group)
			setGeo(row!.geo)
			setLinkToIO(row!.link_to_io)
			setSalesRep(row!.sales_rep)
			setType(row!.type)
			setNotes(row!.notes)
			setInternalSegment(row!.internal_segment)
			setLinkToAdvFile(row!.link_to_adv_file)
			setLinkToCreative(row!.link_to_creative)
			setStatus(row!.status)
			setContacts(row!.contacts)
			setCapabilities(cap !== null ? cap : defaultAllowedPlatforms)
			// const prev = preparePrevState(row)
			// setPrevState({ ...prev })
			setFreeText(row!.freeText)
			setInternalSegment(row!.internal_segment)
			setLinkToPubFile(row!.link_to_pub)
			setLinkToLinkedin(row!.link_to_linkedin)
			setLinkToWebsite(row!.link_to_website)
			setFreeText(row!.free_text)
			setCreatedBy(row!.created_by)
			setExternalId(row!.external_id)
			setSelectedCountryElements(row!.media_buying_capabilities?.country || [])
			setSelectedFraudTools(row!.media_buying_capabilities?.fraud_tools || [])
			setSelectedTypeOfPublisher(
				row!.media_buying_capabilities?.type_publisher || [],
			)
			setSelectedAdType(row!.media_buying_capabilities?.ad_type || [])
			setSelectedDevices(row!.media_buying_capabilities?.devices || [])
			setSelectedPaymentModel(
				row!.media_buying_capabilities?.payment_model || [],
			)
			setSelectedAvailablePlatforms(
				row!.media_buying_capabilities?.available_platforms || [],
			)
			setSelectedAbitilies(row!.media_buying_capabilities?.abilities || [])
			setSelectedRelevantCategories(
				row!.media_buying_capabilities?.relevant_categories || [],
			)
			setSelectedTrafficIndicators(
				row!.media_buying_capabilities?.traffic_indicators || [],
			)
			setSpecialRequestList(
				row!.media_buying_capabilities?.special_request_list || '',
			)
			setSelectedFormatTypes(row!.media_buying_capabilities?.format_types || [])
			setSelectedDailyCap(row!.media_buying_capabilities?.daily_cap || '')
			setBadgeGoodQuality(
				row!.media_buying_capabilities?.badges?.good_quality || false,
			)
			setBadgeLowFraud(
				row!.media_buying_capabilities?.badges?.low_fraud || false,
			)
			setBadgeHighVolume(
				row!.media_buying_capabilities?.badges?.high_volume || false,
			)
			setBadgeHighCR(row!.media_buying_capabilities?.badges?.high_cr || false)
			setSelectedGeneralCountryData(
				row!.media_buying_capabilities?.country_general || [],
			)
			setPublicSkype(row!.public_skype)
			setPaymentTerm(row!.payment_term)
			row!.past_owner_finance &&
				setPastOwnerFinance(JSON.parse(row!.past_owner_finance))
			setVat(row!.vat)
			setMainCategory(row!.main_category)
			setPauseReason(row!.pause_reason)
			setPauseExplanation(row!.pause_explanation)
		}

		if (row && !dataFilled) {
			const cap = capabilitiesFromJson(row.capabilities)
			fillFields(row, cap)
			const obj = _.cloneDeep(row)
			const prev = preparePrevState(obj)

			setPrevState({ ...prev })
			setDataFilled(true)
		}
	}, [row])

	useEffect(() => {
		const obj = _.cloneDeep(row)
		const prev = preparePrevState(obj)
		if (!prevState) {
			setPrevState({ ...prev })
		}
		// console.log('FIRST STATE CHECK : ', row)
	}, [])

	useEffect(() => {
		const isNameValid = name.trim() !== ''
		const isLegalNameValid = legalName.trim() !== ''
		const isAddressValid = address.trim() !== ''
		const isGeoValid = geo.length > 0

		setFieldValidations({
			name: isNameValid,
			legalName: isLegalNameValid,
		})

		const mandatoryFieldsValid = isNameValid && isLegalNameValid

		if (
			!mandatoryFieldsValid ||
			(linkToPubFile && !checkInputStartsWithHttp(linkToPubFile)) ||
			(linkToIO && !checkInputStartsWithHttp(linkToIO)) ||
			(linkToWebsite && !checkInputStartsWithHttp(linkToWebsite)) ||
			(linkToLinkedin && !checkInputStartsWithHttp(linkToLinkedin)) ||
			(name && !stringIsValidName(name))
		) {
			setIsValid(false)
		} else {
			setIsValid(true)
		}
	}, [
		linkToPubFile,
		linkToIO,
		linkToWebsite,
		linkToLinkedin,
		name,
		legalName,
		address,
		geo,
	])

	//we use this in order to save the logs for capabilities.
	const capabilitiesFromJson = (capabilities: any[]) => {
		let result: any[] = []
		capabilities?.forEach((c) => {
			if (typeof c === 'string') {
				return result.push(JSON.parse(c))
			} else result.push(c)
		})
		return result
	}

	useEffect(() => {
		if (externalId) {
			const checkIfRepeat = publisher.publisher.some(
				(pub: any) =>
					pub.external_id !== null &&
					pub.external_id === parseInt(externalId as unknown as string) &&
					pub?.publisher_name !== name,
			)
			setExternalIdInUse(checkIfRepeat)
		}
	}, [externalId])
	useEffect(() => {
		if (financeId.trim() !== '') {
			const checkIfRepeat = publisher.publisher.some(
				(pub: any) =>
					pub.finance_id !== null &&
					pub.finance_id === parseInt(financeId as unknown as string) &&
					pub.publisher_name !== name,
			)
			setFinanceIdInUse(checkIfRepeat)
			setIsValid(!checkIfRepeat) // This should only set isValid to false if financeId is duplicate
		} else {
			setFinanceIdInUse(false) // If financeId is empty, reset the flag
			setIsValid(true) // Ensure the form remains valid if other mandatory fields are filled
		}
	}, [financeId])

	useEffect(() => {
		if (!props.PopUpVisible) nullifyFields()
	}, [props.PopUpVisible])

	const closeDetailedView = () => {
		navigate('/publishers')

		props.setPopUpVisible(false)
	}
	const openPreview = () => {
		navigate('/publishers/' + params.id + '/preview')
	}

	const handleOpenUploadModal = () => setIsUploadModalOpen(true)

	const handleIconClick = () => {
		if (iconUrl && iconUrl !== defaultIcon) {
			setDeleteModalOpen(true)
		}
	}

	const handleCloseDeleteModal = () => {
		setDeleteModalOpen(false)
	}

	const handleUploadSuccess = (filename: string) => {
		setUploadStatus({
			message: `${filename} uploaded successfully`,
			success: true,
		})
		setTimeout(() => {
			setIsUploadModalOpen(false)
			setUploadStatus(null)
		}, 3000)
	}

	const handleUploadError = (filename: string) => {
		setUploadStatus({ message: `Error uploading ${filename}`, success: false })
		setTimeout(() => {
			setIsUploadModalOpen(false)
			setUploadStatus(null)
		}, 3000)
	}

	const handleDeleteIcon = () => {
		if (iconUrl !== defaultIcon) {
			const headers = { Authorization: `Bearer ${login.user.token}` }
			deleteIcon('PUBLISHER', row.publisher_name, headers)
			setDeleteModalOpen(false)
			setIconUrl(defaultIcon)
		}
	}

	const handleFileUpload = async (files: any) => {
		if (files.length > 0) {
			const file = files[0]
			if (!isValidImageType(file)) {
				props.setErrorMessage(
					'Unsupported file type. Please upload JPEG, PNG, or SVG files.',
				)
				return
			}
			const headers = {
				Authorization: `Bearer ${login.user.token}`,
			}

			const uploadResult = await uploadIcon(
				'PUBLISHER',
				file,
				row?.publisher_name,
				headers,
			)
			setIconUrl(uploadResult.toString())
		}
	}

	const tabOptions = [
		{ label: 'Company Information', tabIndex: 0 },
		{ label: 'Capabilities', tabIndex: 1 },
		{ label: 'Finance', tabIndex: 2 },
	]

	return (
		<ThemeProvider theme={theme}>
			{handShakeEditorOpen && (
				<div
					style={{
						background: theme.colors.base.white,
						border: '1px solid ' + theme.colors.base.grey300,
						borderRadius: '10px',
						boxShadow: '0px 0px 1000px 20px ' + theme.colors.base.grey100,
						width: '70%',
						height: '30%',
						zIndex: 15,
						position: 'absolute',
						display: 'flex',
						top: '30%',
						left: '10%',
					}}
				>
					<UnborderedButton
						onClick={() => setHandShakeEditorOpen(false)}
						style={{
							display: 'flex',
							position: 'absolute',
							right: '2%',
							top: '6%',
						}}
					>
						X
					</UnborderedButton>
					<HandshakeEdit
						selected={editablePid}
						handshakeArr={handshakeArr}
						setHandshakeArr={setHandshakeArr}
					/>
				</div>
			)}
			<EditPageHeader
				onBack={closeDetailedView}
				onPreview={openPreview}
				onUpload={handleOpenUploadModal}
				tabValue={tab}
				handleChangeTab={handleChangeTab}
				hasPreview={!!(row && ruleSet.rules.preview && params.id)}
				options={tabOptions}
				customStyles={editPageTopPanelStyle}
				ariaLabel='basic tabs'
			/>

			<CustomModal
				title='Upload Icon'
				open={isUploadModalOpen}
				onClose={() => setIsUploadModalOpen(false)}
			>
				{uploadStatus ? (
					<Box
						display='flex'
						flexDirection='column'
						alignItems='center'
						justifyContent='center'
						p={2}
					>
						<Typography variant='subtitle1'>{uploadStatus.message}</Typography>
						<CheckCircle style={{ color: 'green', fontSize: 40 }} />
					</Box>
				) : (
					<CustomFileUploader
						onSetFiles={handleFileUpload}
						onSuccess={handleUploadSuccess}
						onError={handleUploadError}
						acceptedFiles={ACCEPTED_FILES.IMAGES_ONLY}
					/>
				)}
			</CustomModal>
			<CompanyInformationHeader
				name={row?.publisher_name}
				iconUrl={handleIconUrl(iconUrl, defaultIcon)}
				onClickIcon={
					iconUrl !== defaultIcon && iconUrl ? handleIconClick : undefined
				}
				tooltipText={
					iconUrl === defaultIcon || !iconUrl
						? 'No icon to delete'
						: 'Click to delete icon'
				}
				cursorStyle={
					iconUrl === defaultIcon || !iconUrl ? 'default' : 'pointer'
				}
				style={{
					display: 'flex',
					alignContent: 'center',
					marginLeft: '6%',
					marginTop: '1%',
				}}
			/>

			<CustomDialog
				open={isDeleteModalOpen}
				onClose={handleCloseDeleteModal}
				onConfirm={handleDeleteIcon}
				imageUrl={handleIconUrl(iconUrl, defaultIcon)}
				message='Click delete to remove the icon.'
			/>

			<PageWrapper
				style={{
					marginTop: '0.1vh',
				}}
			>
				<TabPanel value={tab} index={0}>
					<PublisherCompanyInfoComponent
						name={name}
						setName={setName}
						legalName={legalName}
						setLegalName={setLegalName}
						address={address}
						setAddress={setAddress}
						geo={geo}
						setGeo={setGeo}
						linkToIO={linkToIO}
						setLinkToIO={setLinkToIO}
						setOwnerEmail={setOwnerEmail}
						ownerEmail={ownerEmail}
						setSalesRep={setSalesRep}
						salesRep={salesRep}
						setStatus={setStatus}
						status={status}
						setPIDs={setPIDs}
						pids={pids}
						skypeGroup={skypeGroup}
						setSkypeGroup={setSkypeGroup}
						setFreeText={setFreeText}
						freeText={freeText}
						setLinkToPubFile={setLinkToPubFile}
						linkToPubFile={linkToPubFile}
						isValid={isValid}
						settings={settings.settings.vertical ? settings : []}
						loginRole={props.loginRole}
						externalId={externalId}
						setExternalId={setExternalId}
						externalIdInUse={externalIdInUse}
						setMainHeightChange={setMainHeightChange}
						linkToLinkedin={linkToLinkedin}
						setLinkToLinkedin={setLinkToLinkedin}
						linkToWebsite={linkToWebsite}
						setLinkToWebsite={setLinkToWebsite}
						notes={notes}
						setNotes={setNotes}
						publicSkype={publicSkype}
						setPublicSkype={setPublicSkype}
						// skypeGroupUserAllowed={skypeGroupUserAllowed}
						setPastOwnerFinance={setPastOwnerFinance}
						pastOwnerFinance={pastOwnerFinance}
						users={users}
						mainCategory={mainCategory}
						setMainCategory={setMainCategory}
						pauseReason={pauseReason}
						setPauseReason={setPauseReason}
						pauseExplanation={pauseExplanation}
						setPauseExplanation={setPauseExplanation}
						setHandShakeEditorOpen={setHandShakeEditorOpen}
						setEditablePid={setEditablePid}
						mandatoryFields={mandatoryFields}
						fieldValidations={fieldValidations}
					/>
					{ruleSet.rules['view publisher contacts'] && (
						<>
							<ContactsComponent
								contacts={contacts}
								setContacts={setContacts}
								handleNewContact={handleNewContact}
								loginRole={props.loginRole}
								publisherName={name}
								setErrorMessage={props.setErrorMessage}
								setDeletedContactList={setDeletedContactList}
								p360active={true}
								setIsSuccessPromptOpened={props.setIsSuccessPromptOpened}
								owner={ownerEmail}
								ruleSet={ruleSet}
							></ContactsComponent>
						</>
					)}
				</TabPanel>
				<TabPanel value={tab} index={1}>
					<PublisherAllowedPlatformsComponent
						capabilities={capabilities}
						setCapabilities={setCapabilities}
						loginRole={props.loginRole}
						setMainHeightChange={setMainHeightChange}
						vertical={vertical}
						setVertical={setVertical}
						publisherCapabilitiesMap={publisherCapabilitiesMap}
						notes={notes}
						setNotes={setNotes}
					/>
				</TabPanel>
				<TabPanel value={tab} index={2}>
					<FinanceInfo
						paymentTerm={paymentTerm}
						setPaymentTerm={setPaymentTerm}
						vat={vat}
						setVat={setVat}
						financeId={financeId}
						setFinanceId={setFinanceId}
						settings={settings}
						loginRole={props.loginRole}
						financeIdInUse={financeIdInUse}
						mandatoryFields={mandatoryFields}
						fieldValidations={fieldValidations}
					/>
				</TabPanel>

				<DialogActions
					style={{
						display: 'flex',
						alignItems: 'center',
						justifyContent: 'center',
						gap: 160,
					}}
				>
					<UnborderedButton
						onClick={() => {
							nullifyFields()
							navigate('/publishers')
							props.setPopUpVisible(false)
						}}
						label={'Cancel'}
					></UnborderedButton>
					{ruleSet.post && (
						<Tooltip
							title={
								!isValid
									? 'Please fill in all mandatory fields: Company Name, Legal Name.'
									: ''
							}
							placement='top'
						>
							<span>
								<SubmitButton
									disabled={!isValid}
									onClick={() => trySubmit(true, row)}
								>
									{row ? 'Update' : 'Save'}
								</SubmitButton>
							</span>
						</Tooltip>
					)}
				</DialogActions>
			</PageWrapper>
		</ThemeProvider>
	)
}

export default PublisherPopUp
