import { Grid } from '@mui/material'
import { Dispatch, SetStateAction, useEffect, useState } from 'react'
import { checkInputStartsWithHttp } from '../../utils/helpers/tableHelper'
import { countryList } from '../components/countryList'
import { OwnerSelect, StyledNameField } from '../components/reusableComponents'
import { stringIsValidName } from '../../utils/helpers/helperFuncs'
import moment from 'moment'
import { InputFieldText, RadioChipInput } from '../components/Inputs'

export const CreatorFinanceInfo = (props: {
	paymentTerm: string
	setPaymentTerm: any
	vat: string
	setVat: any
	settings: any
	financeId: string
	setFinanceId: Dispatch<SetStateAction<string>>
	creatorId: string | undefined
}) => {
	return (
		<Grid
			container
			columnSpacing={6}
			rowSpacing={2}
			direction='row'
			style={{
				display: 'flex',
				marginBottom: 300,
			}}
			columns={12}
		>
			{props.creatorId && (
				<Grid item xs={3}>
					<InputFieldText
						label='Finance ID'
						type='number'
						value={props.financeId}
						onChange={props.setFinanceId}
					/>
				</Grid>
			)}
			<Grid item xs={4}>
				<RadioChipInput
					label='Payment Term'
					options={
						props.settings.settings.paymentTerms
							? ['Prepayment', ...props.settings.settings.paymentTerms].sort()
							: []
					}
					value={props.paymentTerm}
					onChange={(e: string) => props.setPaymentTerm(e)}
					style={{ width: 'auto' }}
				/>
			</Grid>
			<Grid item xs={4}>
				<RadioChipInput
					label={'VAT (%)'}
					options={
						props.settings.settings.vatValues
							? props.settings.settings.vatValues.sort()
							: []
					}
					value={props.vat}
					onChange={(e: string) => props.setVat(e)}
					style={{ width: 'auto' }}
				/>
			</Grid>
		</Grid>
	)
}
