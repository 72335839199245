import React, { useEffect, useState } from 'react'
import theme from '../../utils/theme2024'
import { GradientedText } from '../components/reusableComponents'
import ClearIcon from '@mui/icons-material/Clear'
import { extractPaymentLimit } from '../../utils/helpers/helperFuncs'
import moment from 'moment'
import { SubmitButton } from '../components/Buttons'
import { TitleComponent } from '../components/Informative'
//this is the main component the user will render when sending an email. It's going to show the exactly same template that we send.
//based on the type it will show a status or a final number email.
//in general what this entire component does is rendering some stats (emails) and a simple table.
const EmailTemplateViewer = (props: {
	publisher: any
	statusTableRows: any
	period: any
	type: string
	sendEmail?: any
	setIsEmailPreviewOpen: any
	setPreviewEmail: any
	rollOverValues: boolean
}) => {
	const [validPublisher, setValidPublisher] = useState(
		props.publisher?.length === 1,
	)

	useEffect(() => {
		setValidPublisher(props.publisher?.length === 1)
	}, [props.publisher])

	const emails = ['supply@thing02.com', 'finance@thing02.com']
	const publisherEmails = []
	const publisherOwner = []
	let title = ''
	if (props.publisher[0] && props.publisher[0].contacts) {
		for (const contact of props.publisher[0].contacts) {
			publisherEmails.push(contact.email)
		}
		publisherOwner.push(props.publisher[0].email)

		title = `Numbers Approval ${props.period} || ${props.publisher[0].publisher_name}`
	}
	// console.log(props.publisher[0])
	//this is how we render the table.
	const sumTotalStatus: any = {
		platform: '',
		on_hold: 0,
		total_approved: 0,
	}
	for (const row of props.statusTableRows) {
		sumTotalStatus.on_hold = sumTotalStatus.on_hold + row.on_hold
		sumTotalStatus.total_approved =
			sumTotalStatus.total_approved + row.total_approved
	}
	const statusRowsObjects = [...props.statusTableRows, sumTotalStatus]
	const statusRowsString = statusRowsObjects.map((row, index) => {
		if (index === statusRowsObjects.length - 1) {
			return (
				<tr style={{ fontSize: '14px' }}>
					<td
						style={{
							paddingTop: '8px',
							border: '1px solid black',
							paddingRight: '20px',
							paddingLeft: '20px',
						}}
					>
						Total
					</td>
					<td
						style={{
							paddingTop: '8px',
							border: '1px solid black',
							paddingRight: '20px',
							paddingLeft: '20px',
						}}
					>
						{row.platform}
					</td>
					<td
						style={{
							paddingTop: '8px',
							border: '1px solid black',
							paddingRight: '20px',
							paddingLeft: '20px',
						}}
					></td>
					{props.type === 'status' && (
						<td
							style={{
								paddingTop: '8px',
								border: '1px solid black',
								paddingRight: '20px',
								paddingLeft: '20px',
							}}
						>
							{parseFloat(row.on_hold).toFixed(2)}
						</td>
					)}
					<td
						style={{
							paddingTop: '8px',
							border: '1px solid black',
							paddingRight: '20px',
							paddingLeft: '20px',
							fontWeight: '600',
						}}
					>
						{parseFloat(row.total_approved).toFixed(2)}
					</td>
				</tr>
			)
		} else {
			return (
				<tr style={{ fontSize: '14px' }}>
					<td
						style={{
							paddingTop: '8px',
							border: '1px solid black',
							paddingRight: '20px',
							paddingLeft: '20px',
						}}
					>
						{props.publisher[0].publisher_name}
					</td>
					<td
						style={{
							paddingTop: '8px',
							border: '1px solid black',
							paddingRight: '20px',
							paddingLeft: '20px',
						}}
					>
						{row.platform}
					</td>
					<td
						style={{
							paddingTop: '8px',
							border: '1px solid black',
							paddingRight: '20px',
							paddingLeft: '20px',
						}}
					>
						{moment(row.month, 'DD/MM/YYYY').format('MMM-YYYY')}
					</td>
					{props.type === 'status' && (
						<td
							style={{
								paddingTop: '8px',
								border: '1px solid black',
								paddingRight: '20px',
								paddingLeft: '20px',
							}}
						>
							{parseFloat(row.on_hold).toFixed(2)}
						</td>
					)}
					<td
						style={{
							paddingTop: '8px',
							border: '1px solid black',
							paddingRight: '20px',
							paddingLeft: '20px',
						}}
					>
						{parseFloat(row.total_approved).toFixed(2)}
					</td>
				</tr>
			)
		}
	})
	const netValue = extractPaymentLimit(props.publisher[0].payment_term)
	const body = () => (
		<div>
			<p>Hi Team,</p>
			{props.type === 'status' ? (
				<p>You can find below the status of your numbers:</p>
			) : (
				<p>You can find below the final amount approved:</p>
			)}
			<table>
				<thead style={{ fontSize: '14px' }}>
					<tr>
						<th></th>
						<th></th>
						<th></th>
						{props.type === 'status' && (
							<th
								style={{
									paddingTop: '8px',
									border: '1px solid black',
									background: 'rgb(69, 164, 196)',
									paddingRight: '20px',
								}}
							>
								On Hold
							</th>
						)}
						<th
							style={{
								paddingTop: '8px',
								border: '1px solid black',
								background: 'rgb(69, 164, 196)',
								paddingRight: '20px',
							}}
						>
							Approved
						</th>
					</tr>
				</thead>
				<tbody>{statusRowsString}</tbody>
			</table>
			{props.type === 'status' ? (
				<>
					<p>
						We are working to approve everything as soon as possible. Thanks for
						your support!
					</p>
					<p>Let us know if you have any questions,</p>
					<p>Best Regards</p>
				</>
			) : (
				<>
					<p>
						{props.rollOverValues
							? 'Unfortunately, we are unable to approve some offers this month. Check out the attached breakdown.'
							: ''}
					</p>
					<p>
						We appreciate your patience and thank you for your continued
						business!
					</p>
					<p>
						In order to improve our billing cycle and be transparent with our
						partners, we are sharing the invoice instructions below.
					</p>
					<p>Instructions:</p>
					<ul>
						<li>
							Invoices must be raised to Thing Or Two with a{' '}
							{netValue ? netValue : '30'}-day payment term.
						</li>
						<li>
							Send the invoices to: finance@thing02.com, CC supply@thing02.com
						</li>
					</ul>
					<p>Let us know if you have any questions,</p>
					<p>Best Regards</p>
				</>
			)}
		</div>
	)
	// console.log(props.type)

	return (
		<div
			style={{
				width: '55%',
				height: '85%',
				background: theme.colors.base.white,
				position: 'absolute',
				top: -180,
				left: '15%',
				zIndex: '20',
				border: '1px solid rgba(0,0,0,0.2)',
				borderRadius: '12px',
				fontSize: '16px',
				color: 'black',
				padding: '40px',
				display: 'flex',
				overflowY: 'auto',
				boxShadow: '2px 2px 2px 3px rgba(206,207,208,1)',
			}}
		>
			<div>
				<button
					style={{
						display: 'flex',
						alignItems: 'center',
						border: 'none',
						borderRadius: '100px',
						height: '28px',
						width: '28px',
						fontSize: '16px',
						position: 'absolute',
						left: '94%',
						top: '2%',
						cursor: 'pointer',
					}}
					onClick={() => {
						props.setPreviewEmail(false)
						props.setIsEmailPreviewOpen(false)
					}}
				>
					<ClearIcon fontSize={'inherit'} />
				</button>
				<TitleComponent style={{ fontSize: '18px' }}>
					Email preview:
				</TitleComponent>
				<div style={{ lineHeight: '28px' }}>
					<div style={{ marginTop: '4px' }}>
						<span style={{ fontWeight: '600', marginRight: '6px' }}>
							Title:
						</span>
						<span style={{ fontWeight: '600' }}>{title}</span>
					</div>
					<span style={{ fontWeight: '600' }}>It will be send to: </span>
					<li>
						<span style={{ fontWeight: '500', marginRight: '6px' }}>
							Publisher's Contacts:
						</span>
						{publisherEmails.join(' | ')}
					</li>
					<li>
						<span style={{ fontWeight: '500', marginRight: '6px' }}>
							Publisher Manager:
						</span>
						{publisherOwner.join(' | ')}
					</li>
					<li>
						<span style={{ fontWeight: '500', marginRight: '6px' }}>CC:</span>
						{emails.join(' | ')}
					</li>
				</div>
				<div style={{ marginTop: '4px' }}>
					<span style={{ fontWeight: '600', marginRight: '6px' }}>Text:</span>
					<span>{body()}</span>
				</div>
				<div style={{ marginTop: '4px' }}>
					<span style={{ fontWeight: '600', marginRight: '6px' }}>
						Attached:
					</span>
					<span>
						The email will contain an excel (.xslx) file with the breakdown data
						from
						<span
							style={{
								fontWeight: '500',
								marginLeft: '6px',
								marginRight: '6px',
							}}
						>
							{props.publisher[0].publisher_name}
						</span>
						for <span style={{ fontWeight: '500' }}>{props.period}</span>.
					</span>
				</div>
				<div
					style={{
						display: 'flex',
						justifyContent: 'center',
						gap: '60px',
						marginTop: '30px',
						paddingBottom: '20px',
					}}
				>
					<SubmitButton
						onClick={() => {
							const sendApproved = window.confirm(
								'Do you want to send the email?',
							)
							if (sendApproved) {
								props.sendEmail()
								props.setPreviewEmail(false)
								props.setIsEmailPreviewOpen(false)
							}
						}}
						style={{
							width: 120,
							height: 30,
							display: 'flex',
							alignItems: 'center',
							justifyContent: 'center',
						}}
					>
						Send
					</SubmitButton>
				</div>
			</div>
		</div>
	)
}

export default EmailTemplateViewer
