import CheckBoxIcon from '@mui/icons-material/CheckBox'
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank'
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined'
import { ReactComponent as DownloadIcon } from '../../assets/svg/download-icon.svg'
import { ReactComponent as SendIcon } from '../../assets/svg/send-icon.svg'
import { ReactComponent as UploadIcon } from '../../assets/svg/upload-icon-2.svg'
import AddIcon from '@mui/icons-material/Add'
import { default as VisibilityIcon } from '@mui/icons-material/Visibility'
import { Checkbox } from '@mui/material'
import { GridColDef, GridToolbarContainer } from '@mui/x-data-grid'
import { DataGridPro } from '@mui/x-data-grid-pro'
import React, {
	Dispatch,
	SetStateAction,
	useEffect,
	useRef,
	useState,
} from 'react'
import 'react-checkbox-tree/lib/react-checkbox-tree.css'
import appsIcon from '../../assets/varys-applist-icon-2.svg'
import filterRemoveIcon from '../../assets/varys-filter-remove.svg'
import mediaPlanIcon from '../../assets/varys-media-plan-icon.svg'
import { convertPathName } from '../../utils/helpers/helperFuncs'
import theme from '../../utils/theme2024'
import moment from 'moment'
import {
	SimpleActionsButton,
	StatusButton,
	SubmitButton,
	UnborderedButton,
} from './Buttons'
import {
	DateFilterSearchComponent,
	FilterSearchComponent,
} from './SelectableInputs'
import { StaticChipInputList } from './StaticChipInput'
import HandshakeIcon from '@mui/icons-material/Handshake'

export const TableComponent = (props: {
	style?: any
	setLoading: any
	columns: GridColDef[]
	rows: any[]
	setPageSize: any
	pageSize: any
	rowsPerPageOptions: any
	onRowClick?: any
	experimentalFeatures?: any
	onRowEditStop?: any
	onRowEditCommit?: any
	commitCellChange?: any
	filterStatus?: any
	setFilterStatus?: any
	setSearch?: any
	search?: any
	handlePopUp?: any
	downloadButtonOptions?: any
	uploadButtonOptions?: any
	handlePasswordVisibility?: any
	mediaPlanHandler?: any
	handshakeHandler?: any
	onCellClick?: any
	sortModel?: any
	rowHeight?: any
	onSortModelChange?: any
	filterHandler?: any
	setFilterHander?: any
	customFilter?: any
	setCustomFilter?: any
	currentFilters?: any
	applyFilter?: any
	filterLabel?: any
	optionsFilters?: any
	mainFilterOptions?: any
	rowModesModel?: any
	handleRowModesModelChange?: any
	selector?: any
	isAutoSwitch?: any
	appIdList?: any[]
	appId?: any
	setAppId?: any
	selectorProp?: any
	processRowUpdate?: any
	handleRowEditStop?: any
	handleDoubleCellClick?: any
	handleCellKeyDown?: any
	handleCellFocusOut?: any
	handleCellOnFocus?: any
	monthFilterArray?: any
	setMonthFilterArray?: any
	yearFilterArray?: any
	setYearFilterArray?: any
	downloadTemplate?: any
	fileInputClickHandler?: any
	treeData?: boolean
	getTreeDataPath?: any
	groupingColDef?: any
	sendEmail?: any
	financeLastSend?: any
	financeFinalSent?: boolean
	getRowClassName?: any
	customSx?: any
	customMessage?: any
	columnVisibility?: any
	autoswitch?: any
	pinnedBottomRow?: any
	apiRef?: any
	searchWithTimer?: boolean
	checkboxButtonValue?: boolean
	setCheckboxButtonValue?: Dispatch<SetStateAction<boolean>>
	checkboxButtonLabel?: string
	downloadButtonFolderOptions?: any
	infoOpened?: boolean
	setInfoOpened?: Dispatch<SetStateAction<boolean>>
	triggerExtraBolFunction?: Function
	triggerExtraBolFunctionValue?: boolean
	extraBolFunctionLabel?: any
	presetSearch?: string
	noToolbar?: boolean
	loading?: boolean
	dateFilter?: any
	setDateFilter?: any
	dateFilterHandler?: any
	setDateFilterHandler?: any
}) => {
	// useEffect(() => {
	// 	props.setLoading(false)
	// })

	const [filterButtonEl, setFilterButtonEl] =
		React.useState<HTMLButtonElement | null>(null)
	const [filterSearch, setFilterSearch] = useState([])

	const [showClearAllFiltersButton, setShowClearAllFiltersButton] =
		useState(false)
	const filterRef = useRef(null)
	const [position, setPosition] = useState({ x: 0, y: 0 })
	const [hoverRow, setHoverRow] = useState(false)
	const [currentHoveredRow, setCurrentHoveredRow] = useState<any>({})
	const pageSizeOptions = [30, 100, 200, props.rows.length]

	let handlePopUp = props.handlePopUp
	let handlePopUpDisabled = false
	if (props.handlePopUp) {
		if (typeof props.handlePopUp.onClick === 'function') {
			handlePopUp = props.handlePopUp.onClick
		}
		if (typeof props.handlePopUp.disabled === 'boolean') {
			handlePopUpDisabled = props.handlePopUp.disabled
		}
	}

	// const applyFilter = () => {
	// 	if (filterSearch.length === 0) {
	// 		props.customFilter[
	// 			props.currentFilters as keyof typeof props.customFilter
	// 		] = filterSearch
	// 	}
	// 	props.applyFilter(props.rows)
	// }
	const clearFilter = () => {
		if (props.customFilter) {
			props.customFilter[
				props.currentFilters as keyof typeof props.customFilter
			] = []
		}

		setFilterSearch([])
		props.applyFilter()
	}
	const clearAllFilters = () => {
		if (props.customFilter) {
			for (const key in props.customFilter) {
				props.customFilter[key as keyof typeof props.customFilter] = []
			}
		}
		setFilterSearch([])
		props.applyFilter()
	}

	const handleClickOutside = (event: any) => {
		if (
			filterRef.current &&
			!(filterRef.current as any).contains(event.target) &&
			event.target.tagName.toLowerCase() !== 'li' &&
			event.target.role !== 'option'
		) {
			if (props.setFilterHander) props.setFilterHander(false)
			if (props.setDateFilterHandler) {
				let clickIsOnDateComponent = false
				for (const className of event.srcElement.classList) {
					if (
						className === 'MuiPickersDay-root' ||
						className === 'MuiSvgIcon-root'
					) {
						clickIsOnDateComponent = true
						break
					}
				}
				if (clickIsOnDateComponent === false) {
					props.setDateFilterHandler(false)
				}
			}
		}
	}

	const calculateLeftPosition = () => {
		let result = 0
		for (let i = 0; i < props.columns.length; i++) {
			if (props.columns[i].field === props.currentFilters) {
				result = i
				break
			}
		}
		return (result * 90) / props.columns.length + '%'
	}
	useEffect(() => {
		if (props.customFilter) {
			for (const key of Object.keys(props.customFilter)) {
				if (props.customFilter[key].length > 0) {
					setShowClearAllFiltersButton(true)
					break
				}
			}
		}
	}, [props.customFilter])
	useEffect(() => {
		if (props.customFilter) {
			if (filterSearch && filterSearch.length !== 0) {
				props.customFilter[props.currentFilters] = filterSearch
				setShowClearAllFiltersButton(true)
			} else {
				props.customFilter[props.currentFilters] = []
				props.applyFilter()
				let changed = false
				for (const key of Object.keys(props.customFilter)) {
					if (
						props.customFilter[key].length > 0 &&
						key !== props.currentFilters
					) {
						setShowClearAllFiltersButton(true)
						changed = true
						break
					}
				}
				// setFilterSearch([])
				changed || setShowClearAllFiltersButton(false)
			}
		}

		// console.log(filterSearch, props.customFilter)
	}, [filterSearch])

	useEffect(() => {
		document.addEventListener('click', handleClickOutside)
		return () => {
			document.removeEventListener('click', handleClickOutside)
		}
	}, [])
	useEffect(() => {
		if (props.customMessage) {
			const handleMouseMove = (e: any) => {
				//if there's ul with role listbox it means the filter is open and we don't want to have hover reactions otherwise it will
				//re-render
				if (!document.querySelector("ul[role='listbox']")) {
					// Subtract part of the div position in order to be closer to the cursor
					setPosition({
						x: e.clientX - 20,
						y: e.clientY - 170,
					})
				}
			}
			document.addEventListener('mousemove', handleMouseMove)
			return () => {
				document.removeEventListener('mousemove', handleMouseMove)
			}
		}
	}, [])
	const renderCustomMessage = () => {
		if (props.customMessage) {
			return props.customMessage(currentHoveredRow, position)
		} else {
			return <></>
		}
	}

	const filterSearchComponentArray = (filterKeysList: any) => {
		let wholeList: any = {}
		// console.log(props.currentFilters)

		for (let e of filterKeysList)
			wholeList[e] = (
				<FilterSearchComponent
					style={{
						width: '95%',
						boxShadow: 'none',
						border: '1px solid rgba(0,0,0,0.12)',
						borderRadius: '3px',
						marginTop: '9px',
						height: 'inherit',
					}}
					key={'FilterSearchComponent___' + e}
					setSearch={setFilterSearch}
					search={filterSearch}
					applyFilter={props.applyFilter}
					filterLabel={props.filterLabel}
					currentFilters={props.currentFilters}
					clearFilter={clearFilter}
					optionsFilters={props.optionsFilters}
					setFilterHander={props.setFilterHander}
					filtersKey={e}
					customFilter={props.customFilter}
				/>
			)
		// console.log(wholeList)

		return wholeList[props.currentFilters]
	}
	const dateFilterSearchComponentArray = (filterKeysList: any) => {
		let wholeList: any = {}
		// console.log(props.currentFilters, filterSearch, props.customFilter)

		for (let e of filterKeysList)
			wholeList[e] = (
				<DateFilterSearchComponent
					style={{
						width: '95%',
						boxShadow: 'none',
						border: '1px solid rgba(0,0,0,0.12)',
						borderRadius: '3px',
						marginTop: '9px',
						height: 'inherit',
					}}
					key={'FilterSearchComponent___' + e}
					setSearch={setFilterSearch}
					search={filterSearch}
					applyFilter={props.applyFilter}
					filterLabel={props.filterLabel}
					currentFilters={props.currentFilters}
					clearFilter={clearFilter}
					setFilterHander={props.setFilterHander}
					filtersKey={e}
					customFilter={props.customFilter}
				/>
			)
		// console.log(wholeList)

		return wholeList[props.currentFilters]
	}
	let filterKeysList: any = []
	let datefilterKeysList: any = []
	if (props.customFilter) {
		filterKeysList = Object.keys(props.customFilter)
	}
	if (props.dateFilter) {
		datefilterKeysList = Object.keys(props.dateFilter)
	}

	return (
		<>
			<DataGridPro
				initialState={{
					sorting: {
						sortModel: [
							props.sortModel
								? props.sortModel
								: { field: 'createdAt', sort: 'desc' },
						],
					},
					pagination: { paginationModel: { pageSize: 30 } },
					columns: { columnVisibilityModel: props.columnVisibility },
				}}
				rows={props.rows}
				columns={props.columns}
				pagination
				pageSizeOptions={pageSizeOptions.map((option) =>
					option === props.rows.length
						? { value: option, label: 'All' }
						: option,
				)}
				onCellEditStop={props.onRowEditStop}
				disableColumnMenu
				rowHeight={props.rowHeight ? props.rowHeight : 55}
				// headerHeight={50}
				loading={props.loading}
				sortModel={props.sortModel}
				slots={{
					toolbar: TableToolbar,
				}}
				editMode={'row'}
				rowModesModel={props.rowModesModel}
				onRowModesModelChange={props.handleRowModesModelChange}
				slotProps={{
					panel: {
						anchorEl: filterButtonEl,
					},
					toolbar: {
						setFilterButtonEl,
						filterStatus: props.filterStatus,
						setFilterStatus: props.setFilterStatus,
						setSearch: props.setSearch,
						search: props.search,
						handlePopUp: handlePopUp,
						handlePopUpDisabled: handlePopUpDisabled,
						downloadButtonOptions: props.downloadButtonOptions,
						uploadButtonOptions: props.uploadButtonOptions,
						handlePasswordVisibility: props.handlePasswordVisibility,
						mediaPlanHandler: props.mediaPlanHandler,
						handshakeHandler: props.handshakeHandler,
						filterHandler: props.filterHandler,
						customFilter: props.customFilter,
						clearAllFilters: clearAllFilters,
						showClearAllFiltersButton: showClearAllFiltersButton,
						setShowClearAllFiltersButton: setShowClearAllFiltersButton,
						mainFilterOptions: props.mainFilterOptions,
						setAppId: props.setAppId,
						appId: props.appId,
						selector: props.selector,
						selectorProp: props.selectorProp,
						monthFilterArray: props.monthFilterArray,
						setMonthFilterArray: props.setMonthFilterArray,
						yearFilterArray: props.yearFilterArray,
						setYearFilterArray: props.setYearFilterArray,
						downloadTemplate: props.downloadTemplate,
						fileInputClickHandler: props.fileInputClickHandler,
						sendEmail: props.sendEmail,
						financeLastSend: props.financeLastSend,
						financeFinalSent: props.financeFinalSent,
						applyFilter: props.applyFilter,
						autoswitch: props.autoswitch,
						searchWithTimer: props.searchWithTimer,
						checkboxButtonValue: props.checkboxButtonValue,
						setCheckboxButtonValue: props.setCheckboxButtonValue,
						checkboxButtonLabel: props.checkboxButtonLabel,
						downloadButtonFolderOptions: props.downloadButtonFolderOptions,
						infoOpened: props.infoOpened,
						setInfoOpened: props.setInfoOpened,
						triggerExtraBolFunction: props.triggerExtraBolFunction,
						triggerExtraBolFunctionValue: props.triggerExtraBolFunctionValue,
						extraBolFunctionLabel: props.extraBolFunctionLabel,
						presetSearch: props.presetSearch,
						dateFilterHandler: props.dateFilterHandler,
					},
					cell: {
						onBlur: props.handleCellFocusOut,
						onFocus: props.handleCellOnFocus,
					},
					row: {
						onMouseEnter: (event) => {
							if (
								event.currentTarget.dataset.id &&
								!document.querySelector("ul[role='listbox']")
							) {
								const id = parseInt(event.currentTarget.dataset.id)
								const row = props.rows.find((el: any) => el.id === id)
								setCurrentHoveredRow(row)
								setHoverRow(true)
							}
						},
						onMouseLeave: () => {
							setHoverRow(false)
						},
					},
				}}
				getRowHeight={(v) => {
					return props.triggerExtraBolFunctionValue ? 80 : props.rowHeight
				}}
				onCellClick={props.onCellClick}
				onSortModelChange={props.onSortModelChange}
				onCellDoubleClick={props.handleDoubleCellClick}
				onCellKeyDown={props.handleCellKeyDown}
				treeData={props.treeData}
				getTreeDataPath={props.getTreeDataPath}
				groupingColDef={props.groupingColDef}
				getRowClassName={(params) => {
					if (props.getRowClassName) {
						return props.getRowClassName(params)
					} else {
						return props.treeData &&
							params.row.hierarchy &&
							params.row.hierarchy.length > 1
							? 'treed-row'
							: ''
					}
				}}
				columnHeaderHeight={40}
				sx={{
					'& .MuiDataGrid-withBorderColor': {
						borderBottom: `1px solid ${theme.colors.base.grey2}`,
					},
					'& .MuiDataGrid-columnHeaders': {
						borderBottom: `1px solid ${theme.colors.base.grey3} !important`,
						borderTop: `1px solid ${theme.colors.base.grey3} !important`,
					},
					'& .MuiDataGrid-columnHeaders:focus': {
						outline: 'none',
					},
					'& .MuiDataGrid-cell:focus': {
						outline: 'none',
					},
					'& .MuiDataGrid-toolbarContainer': {
						padding: props.noToolbar ? '0px !important' : 'inherit',
						margin: props.noToolbar ? '0px !important' : 'inherit',
					},
					...props.customSx,
				}}
				processRowUpdate={props.processRowUpdate}
				pinnedRows={{ bottom: props.pinnedBottomRow }}
				style={{
					border: 'none',
					...props.style,
				}}
				apiRef={props.apiRef}

				// {...props}
			/>
			<div ref={filterRef}>
				{props.filterHandler && (
					<div
						style={{
							background: theme.colors.base.grey50,
							width: '20%',
							padding: '0px 18px 0px 18px',
							minWidth: '400px',
							height: 'auto',
							display: 'flex',
							position: 'absolute',
							top: '11%',
							left: calculateLeftPosition(),
							boxShadow: '2px 2px 6px 1px rgba(0,0,0,0.15)',
							borderRadius: '6px',
							alignItems: 'center',
						}}
					>
						{filterSearchComponentArray(filterKeysList)}
					</div>
				)}
				{props.dateFilterHandler && (
					<div
						style={{
							background: theme.colors.base.grey50,
							width: '20%',
							padding: '0px 18px 0px 18px',
							minWidth: '400px',
							height: 'auto',
							display: 'flex',
							position: 'absolute',
							top: '11%',
							left: calculateLeftPosition(),
							boxShadow: '2px 2px 6px 1px rgba(0,0,0,0.15)',
							borderRadius: '6px',
							alignItems: 'center',
						}}
					>
						{dateFilterSearchComponentArray(datefilterKeysList)}
					</div>
				)}
				{hoverRow &&
					currentHoveredRow &&
					!document.querySelector("ul[role='listbox']") &&
					renderCustomMessage()}
			</div>
		</>
	)
}

export const TableToolbar: React.FunctionComponent<{
	setFilterButtonEl: React.Dispatch<
		React.SetStateAction<HTMLButtonElement | null>
	>
	filterStatus?: any
	setFilterStatus?: any
	setSearch: any
	search: any
	handlePopUp: any
	handlePopUpDisabled: boolean
	downloadButtonOptions: any
	uploadButtonOptions: any
	handlePasswordVisibility: any
	mediaPlanHandler?: any
	handshakeHandler?: any
	filterHandler?: any
	customFilter?: any
	clearAllFilters?: any
	showClearAllFiltersButton?: boolean
	setShowClearAllFiltersButton?: any
	mainFilterOptions: any
	isAutoSwitch?: any
	appIdList?: any[]
	appId?: any
	setAppId?: any
	selector?: any
	selectorProp?: any
	monthFilterArray?: any
	setMonthFilterArray?: any
	yearFilterArray?: any
	setYearFilterArray?: any
	downloadTemplate?: any
	fileInputClickHandler?: any
	sendEmail?: any
	financeLastSend?: any
	financeFinalSent?: boolean
	hideBlockedUsersButton?: boolean
	applyFilter: any
	clearFilter: any
	autoswitch?: any
	searchWithTimer?: boolean
	checkboxButtonValue?: boolean
	setCheckboxButtonValue?: Dispatch<SetStateAction<boolean>>
	checkboxButtonLabel?: string
	downloadButtonFolderOptions?: any
	infoOpened?: boolean
	setInfoOpened?: Dispatch<SetStateAction<boolean>>
	triggerExtraBolFunction?: Function
	triggerExtraBolFunctionValue?: boolean
	extraBolFunctionLabel?: any
	presetSearch?: string
	dateFilterHandler?: any
}> = ({
	setFilterButtonEl,
	filterStatus,
	setFilterStatus,
	setSearch,
	search,
	handlePopUp,
	handlePopUpDisabled,
	downloadButtonOptions,
	uploadButtonOptions,
	handlePasswordVisibility,
	mediaPlanHandler,
	handshakeHandler,
	isAutoSwitch,
	appIdList,
	appId,
	setAppId,
	selector,
	selectorProp,
	filterHandler,
	customFilter,
	clearAllFilters,
	showClearAllFiltersButton,
	setShowClearAllFiltersButton,
	mainFilterOptions,
	monthFilterArray,
	setMonthFilterArray,
	yearFilterArray,
	setYearFilterArray,
	downloadTemplate,
	fileInputClickHandler,
	sendEmail,
	financeLastSend,
	financeFinalSent,
	hideBlockedUsersButton,
	applyFilter,
	clearFilter,
	autoswitch,
	searchWithTimer,
	checkboxButtonValue,
	setCheckboxButtonValue,
	checkboxButtonLabel,
	downloadButtonFolderOptions,
	infoOpened,
	setInfoOpened,
	triggerExtraBolFunction,
	triggerExtraBolFunctionValue,
	extraBolFunctionLabel,
	presetSearch,
	dateFilterHandler,
}) => {
	const possibleCampaignFilter = mainFilterOptions
		? mainFilterOptions
		: ['Both', 'Active', 'Disabled']
	// const [filterTitleColor, setFilterTitleColor] = useState(theme.colors.black)
	const activeFilterHandler = (
		possibleCampaign: any,
		filterCurrent: any,
		setFilter: any,
	) => {
		let maxNumber = possibleCampaign.length
		const number = filterCurrent + 1
		if (number === maxNumber) setFilter(0)
		else setFilter(number)
	}

	const handleClearAllFilters = () => {
		clearAllFilters()
		if (setMonthFilterArray) {
			setMonthFilterArray([])
		}
		if (setYearFilterArray) {
			setYearFilterArray([])
		}
		applyFilter()
		setShowClearAllFiltersButton(false)
	}
	const [downloadFocus, setDownloadFocus] = useState(false)
	const [uploadFocus, setUploadFocus] = useState(false)
	const [sendFocus, setSendFocus] = useState(false)
	const [changeErrorButtonColor, setChangeErrorButtonColor] = useState(false)
	const [yearOptions, setYearOptions] = useState<string[]>([])

	useEffect(() => {
		if (yearOptions.length === 0) {
			let startingYear = 2023
			const currentYear = moment().year()
			const arr: (string | number)[] = [startingYear]
			while (startingYear !== currentYear) {
				startingYear++
				arr.push(startingYear)
			}
			for (const indx in arr) {
				if (currentYear === arr[indx]) {
					arr[indx] = 'This Year'
				} else {
					arr[indx] = arr[indx].toString()
				}
			}
			setYearOptions(arr.reverse() as string[])
		}
	}, [])

	return (
		<GridToolbarContainer
			style={{
				gap: '12px',
				display: 'flex',
				justifyContent: 'flex-start',
				marginBottom: 18,
			}}
		>
			{/* <GridToolbarFilterButton
				ref={setFilterButtonEl}
				style={{ color: filterTitleColor }}
				nonce={undefined}
				onResize={undefined}
				onResizeCapture={undefined}
				onMouseEnter={() => handleFilterColor(true)}
				onMouseLeave={() => handleFilterColor(false)}
			/> */}
			{filterStatus !== undefined && (
				<StatusButton
					activeFilterHandler={activeFilterHandler}
					possibleCampaignFilter={possibleCampaignFilter}
					filterStatus={filterStatus}
					setFilterStatus={setFilterStatus}
				/>
			)}
			{/* {search !== undefined && (
				<TableSearchComponent
					style={{
						width: '16vw',
						boxShadow: 'none',
						border: '1px solid rgba(0,0,0,0.12)',
						borderRadius: '3px',
						marginTop: '9px',
					}}
					setSearch={setSearch}
					search={search}
					filterHandler={filterHandler}
					searchWithTimer={searchWithTimer}
					presetSearch={presetSearch}
				/>
			)} */}
			{typeof handlePopUp !== 'undefined' && autoswitch === undefined && (
				<UnborderedButton
					onClick={(e: any) => handlePopUp(e)}
					label={'Add' + convertPathName(window.location.pathname)}
					icon={<AddIcon />}
					style={{
						color: theme.colors.text.titles,
						fontSize: theme.font.size.body,
					}}
					// iconStyle={{ height: '16px' }}
				/>
			)}
			{selector && selectorProp && (
				// <AddButton
				// 	onClick={() => selector(selectorProp)}
				// 	style={{
				// 		// boxShadow: 'none',
				// 		width: '80px',
				// 		height: '30px',
				// 		marginTop: '9px',
				// 		fontSize: '12px',
				// 	}}
				// >
				// 	Apps
				// </AddButton>
				<UnborderedButton
					onClick={() => selector(selectorProp)}
					label={'Apps'}
					icon={<img src={appsIcon} alt='mediaplanicon' />}
					style={{ color: theme.colors.text.titles }}
				/>
			)}
			{mediaPlanHandler !== undefined && (
				<UnborderedButton
					onClick={mediaPlanHandler}
					label={'Media Plan'}
					icon={<img src={mediaPlanIcon} alt='mediaplanicon' />}
					style={{ color: theme.colors.text.titles }}
				/>
			)}
			{handshakeHandler !== undefined && (
				<UnborderedButton
					onClick={handshakeHandler}
					label={'Handshake'}
					icon={<HandshakeIcon style={{ width: '20px' }} />}
					style={{ color: theme.colors.text.titles }}
				/>
			)}

			{triggerExtraBolFunction !== undefined && (
				<UnborderedButton
					onClick={() => triggerExtraBolFunction()}
					style={{
						color: theme.colors.text.titles,
						fontSize: theme.font.size.body,
					}}
					icon={
						triggerExtraBolFunctionValue
							? extraBolFunctionLabel['iconTrue']
							: extraBolFunctionLabel['iconFalse']
					}
				>
					{
						extraBolFunctionLabel[
							triggerExtraBolFunctionValue as unknown as keyof typeof extraBolFunctionLabel
						]
					}
				</UnborderedButton>
			)}
			{setCheckboxButtonValue !== undefined && (
				<>
					<UnborderedButton
						onClick={() => setCheckboxButtonValue(!checkboxButtonValue)}
						style={{
							// boxShadow: 'none',
							width: checkboxButtonValue === undefined ? 80 : 120,
							display: 'flex',
							alignItems: 'center',
							height: 20,
							fontSize: theme.font.size.body,
							color: theme.colors.text.titles,
						}}
						onMouseEnter={() => setChangeErrorButtonColor(true)}
						onMouseLeave={() => setChangeErrorButtonColor(false)}
						isActive={checkboxButtonValue}
						icon={
							<Checkbox
								checked={checkboxButtonValue}
								onChange={() => {}}
								disabled
								icon={
									<CheckBoxOutlineBlankIcon
										style={{
											scale: '0.7',
											color: theme.colors.text.titles,
										}}
									/>
								}
								style={{ padding: '0px' }}
								checkedIcon={
									<CheckBoxIcon
										style={{
											scale: '0.7',
											color: theme.colors.text.titles,
										}}
									/>
								}
							/>
						}
					>
						{checkboxButtonLabel}
					</UnborderedButton>
				</>
			)}
			{hideBlockedUsersButton && (
				<SubmitButton
					onClick={handlePopUp}
					style={{
						// boxShadow: 'none',
						width: '80px',
						height: '30px',
						marginTop: '9px',
						fontSize: '12px',
					}}
				>
					Blocked
				</SubmitButton>
			)}
			<div
				style={{
					marginLeft: 'auto',
					display: 'flex',
					flexDirection: 'row',
					alignItems: 'center',
					marginRight: 40,
				}}
			>
				{customFilter !== undefined && (
					<UnborderedButton
						onClick={handleClearAllFilters}
						icon={
							<img
								src={filterRemoveIcon}
								alt='filterRemoveIcon'
								style={{ width: '16px', marginRight: 10 }}
							/>
						}
						style={{
							fontSize: theme.font.size.body,
							color: theme.colors.text.titles,
							width: 130,
							height: 20,
							// marginLeft: 'auto',
						}}
					>
						Clear Filters
					</UnborderedButton>
				)}
				{downloadButtonOptions !== undefined && (
					<SimpleActionsButton
						label={'Download'}
						options={downloadButtonOptions}
						icon={<DownloadIcon style={{ width: '14px' }} />}
						style={{
							color: theme.colors.text.titles,
							fontSize: theme.font.size.body,
						}}
					/>
				)}
				{uploadButtonOptions !== undefined && (
					<SimpleActionsButton
						label={'Upload'}
						options={uploadButtonOptions}
						icon={<UploadIcon style={{ width: '14px' }} />}
						style={{
							color: theme.colors.text.titles,
							fontSize: theme.font.size.body,
						}}
					/>
				)}
				{handlePasswordVisibility !== undefined && (
					<UnborderedButton
						// fullWidth
						style={{
							// width: '130px',
							height: '30px',
							// marginTop: '9px',
							fontSize: '12px',
							color: theme.colors.text.titles,
							alignItems: 'center',
							display: 'flex',
							justifyContent: 'center',
						}}
						onClick={() => {
							handlePasswordVisibility()
						}}
						icon={<VisibilityIcon />}
					>
						View Password
					</UnborderedButton>
				)}
				{setInfoOpened && (
					<UnborderedButton
						onClick={() => setInfoOpened(!infoOpened)}
						icon={<InfoOutlinedIcon></InfoOutlinedIcon>}
						style={{
							fontSize: theme.font.size.body,
							color: theme.colors.text.titles,
							height: 20,
						}}
					>
						Info
					</UnborderedButton>
				)}
				{yearFilterArray && (
					<div
						style={{
							display: 'flex',
							maxWidth: '420px',
							// overflow: 'auto',
						}}
					>
						<StaticChipInputList
							onChange={setYearFilterArray}
							options={yearOptions}
							value={yearFilterArray}
							openWithClick={true}
							style={
								{
									height: 'inherit',
									display: 'flex',
									alignItems: 'center',
									justifyContent: 'center',
									margin: '8px',
									width: 'auto',
									minWidth: '100px',
									// marginTop: '25px',
								} as any
							}
							onlyOptionsAllowed
							placeholder={'Year'}
						/>
					</div>
				)}
				{monthFilterArray && (
					<div
						style={{
							display: 'flex',
							maxWidth: '420px',
						}}
					>
						<StaticChipInputList
							onChange={setMonthFilterArray}
							options={[
								'January',
								'February',
								'March',
								'April',
								'May',
								'June',
								'July',
								'August',
								'September',
								'October',
								'November',
								'December',
							]}
							value={monthFilterArray}
							openWithClick={true}
							style={
								{
									height: 'inherit',
									display: 'flex',
									alignItems: 'center',
									// justifyContent: 'center',
									margin: '8px',
									width: 'auto',
									minWidth: '140px',
									// marginTop: '25px',
								} as any
							}
							onlyOptionsAllowed
							placeholder={'Month'}
						/>
					</div>
				)}
				{downloadTemplate && (
					<UnborderedButton
						onClick={downloadTemplate}
						style={{
							fontSize: theme.font.size.body,
							color: theme.colors.text.titles,
							height: 20,
							display: 'flex',
							// marginTop: 29,
						}}
						icon={<DownloadIcon style={{ width: '14px' }} />}
					>
						Download
					</UnborderedButton>
				)}
				{downloadButtonFolderOptions && (
					<SimpleActionsButton
						style={{
							fontSize: theme.font.size.body,
							color: theme.colors.text.titles,
							height: 20,
							display: 'flex',
						}}
						options={downloadButtonFolderOptions}
						label={'Download'}
						icon={<DownloadIcon style={{ width: '14px' }} />}
					></SimpleActionsButton>
				)}
				{fileInputClickHandler && (
					<UnborderedButton
						onClick={fileInputClickHandler}
						style={{
							fontSize: theme.font.size.body,
							color: theme.colors.text.titles,
							height: 20,
							display: 'flex',
						}}
						icon={<UploadIcon style={{ width: '14px' }} />}
					>
						Upload
					</UnborderedButton>
				)}
				{sendEmail && (
					<div style={{ display: 'flex', flexDirection: 'column' }}>
						<SimpleActionsButton
							// onClick={sendEmail}
							style={{
								display: 'flex',
								flexDirection: 'row',
								fontSize: theme.font.size.body,
								height: 20,
								color: theme.colors.text.titles,
							}}
							icon={<SendIcon style={{ scale: '1' }} />}
							label='Send'
							options={sendEmail}
						></SimpleActionsButton>
						{financeLastSend && (
							<div
								style={{
									color: 'black',
									position: 'relative',
									// left: '-10px',
									fontSize: theme.font.size.body,
									width: '100px',
									marginTop: '4px',
								}}
							>
								{financeFinalSent
									? 'Final Numbers sent: '
									: 'Last Status sent: '}
								<span style={{ fontWeight: theme.font.weight.bold }}>
									{financeLastSend}
								</span>
							</div>
						)}
					</div>
				)}
			</div>
		</GridToolbarContainer>
	)
}
