import { FormGroup } from '@mui/material'
import { useEffect, useState } from 'react'
import { useTypedSelector } from '../../hooks/useTypedSelector'
import { AppInterface, NetworksInterface } from '../../models/model.interface'
// import { countryList } from '../components/countryList'
import { NetworkFieldsInterface } from './FiltersPage'
import { ChipInputList } from '../components/ChipInput'
import { CountryListSelector } from '../components/SelectableInputs'
// import { StyledCheckboxFormControl } from './FiltersStyled'
// import DeleteForeverIcon from '@mui/icons-material/DeleteForever'

export const NetworksComponentList = (props: {
	setAppIds: any
	setAgency: any
	setAppName: any
	setPid: any
	setPlatform: any
	setUsername: any
	setPmdAfPrt: any
	dataObj: NetworkFieldsInterface
	setDataObj: any
}) => {
	const [appIdsChecked, setAppIdsChecked] = useState<boolean>(false)
	const [appNameChecked, setAppNameChecked] = useState<boolean>(false)
	const [agencyChecked, setAgencyChecked] = useState<boolean>(false)
	const [usernameChecked, setUsernameChecked] = useState<boolean>(false)
	const [pidChecked, setPidChecked] = useState<boolean>(false)
	const [platformChecked, setPlatformChecked] = useState<boolean>(false)
	// const [addFilterIsOpen, setAddFilterIsOpen] = useState<boolean>(false)
	// const [newFilters, setNewFilters] = useState<any[]>([])

	const { networks, users, app, publisher } = useTypedSelector((state) => state)

	const makeList = (field: keyof NetworksInterface) => {
		const unfiltered = networks.networks.map((e: NetworksInterface) => e[field])
		return [...new Set(unfiltered)]
	}
	const appIdList = app.app.map((e: AppInterface) => e.app_id)

	// const platformList = makeList('platform')
	// const advertiserList = advertiser.advertiser.map(
	// 	(e: AppInterface) => e.advertiser_name,
	// )
	const pidListRaw: string[] = publisher.publisher
		.map((e: any) => e.media_source_pid)
		.flat()
		.filter((e: string) => e !== 'All' && e !== 'all')
	let pidList: string[] = []
	pidListRaw.forEach((pid) => {
		if (!pidList.includes(pid)) {
			pidList.push(pid)
		}
	})
	const agencyList = makeList('agency/pmd_(af_prt)')

	// const ref = useRef(null)
	// function useOutsideAlerter(ref: any) {
	// 	useEffect(() => {
	// 		function handleClickOutside(event: any) {
	// 			if (ref.current && !ref.current.contains(event.target)) {
	// 				if (!event.target.className.includes('addFilterButton')) {
	// 					setAddFilterIsOpen(false)
	// 				}
	// 			}
	// 		}
	// 		document.addEventListener('mousedown', handleClickOutside)
	// 		return () => {
	// 			document.removeEventListener('mousedown', handleClickOutside)
	// 		}
	// 	}, [ref])
	// }
	// useOutsideAlerter(ref)
	// const checkIfFilterAlreadyAdded = (label: string) => {
	// 	for (const filter of newFilters) {
	// 		if (label === filter.id) {
	// 			return true
	// 		}
	// 	}
	// 	return false
	// }
	// const handleNewFilter = (label: string) => {
	// 	const appIdFilter = {
	// 		onChange: setAppIdsChecked,
	// 		checked: true,
	// 		label: 'App Id',
	// 		id: 'app_id',
	// 		arrayOnChange: props.setAppIds,
	// 		array: appIdList,
	// 		disabled: props.dataObj.filters.app_id === undefined,
	// 		value: props.dataObj.filters.app_id,
	// 	}
	// 	const pidFilter = {
	// 		onChange: setAppIdsChecked,
	// 		checked: true,
	// 		label: 'PID',
	// 		id: 'media_source_pid',
	// 		arrayOnChange: props.setPid,
	// 		array: pidList,
	// 		disabled: props.dataObj.filters.media_source_pid === undefined,
	// 		value: props.dataObj.filters.media_source_pid,
	// 	}

	// 	const possibleFilters = [
	// 		appIdFilter,
	// 		pidFilter,
	// 	]

	// 	for (const filter of possibleFilters) {
	// 		if (label === filter.id) {
	// 			setNewFilters((prev: any) =>
	// 				prev.length > 0 ? [...prev, filter] : [filter],
	// 			)
	// 			setAddFilterIsOpen(false)
	// 			break
	// 		}
	// 	}
	// }
	// const removeFilter = (label: string) => {
	// 	const arr = newFilters.filter((el) => el.id !== label)
	// 	setNewFilters(arr)
	// }

	return (
		<div
			style={{
				width: 'inherit',
				display: 'flex',
				flexDirection: 'column',
			}}
		>
			{/* <span
				style={{
					height: '50px',
					display: 'inherit',
					justifyContent: 'flex-end',
				}}
			>
				{newFilters.length < 2 && (
					<AddButton
						style={{ width: '100px', fontSize: '12px', alignSelf: 'flex-end' }}
						onClick={() => setAddFilterIsOpen(!addFilterIsOpen)}
						className={'addFilterButton'}
					>
						Add filter
					</AddButton>
				)}
			</span> */}
			<FormGroup
				key={'form-group-2'}
				style={{
					width: '260px',
					marginTop: '0px',
					display: 'flex',
					gap: '18px',
				}}
			>
				<CheckedFilter
					{...props}
					onChange={setPlatformChecked}
					checked={true}
					label={'Platform'}
					id={'platform'}
					arrayOnChange={props.setPlatform}
					array={['ios', 'android']}
					disabled={props.dataObj.filters.platform === undefined}
					value={props.dataObj.filters.platform}
				/>
				{/* <CheckedFilter
					{...props}
					onChange={setAppNameChecked}
					checked={true}
					label={'App Name'}
					id={'app_name'}
					arrayOnChange={props.setAppName}
					array={advertiserList.sort()}
					disabled={props.dataObj.filters.app_name === undefined}
					value={props.dataObj.filters.app_name}
				/> */}
				<CheckedFilter
					{...props}
					onChange={setAgencyChecked}
					checked={true}
					label={'Agency'}
					id={'agency/pmd_(af_prt)'}
					arrayOnChange={props.setAgency}
					array={agencyList}
					disabled={props.dataObj.filters.agency === undefined}
					value={props.dataObj.filters.agency}
				/>
				<CheckedFilter
					{...props}
					onChange={setAppIdsChecked}
					checked={true}
					label={'App Id'}
					id={'app_id'}
					arrayOnChange={props.setAppIds}
					array={appIdList}
					disabled={props.dataObj.filters.app_id === undefined}
					value={props.dataObj.filters.app_id}
				/>
				<CheckedFilter
					{...props}
					onChange={setAppIdsChecked}
					checked={true}
					label={'PID'}
					id={'media_source_pid'}
					arrayOnChange={props.setPid}
					array={pidList}
					disabled={props.dataObj.filters.media_source_pid === undefined}
					value={props.dataObj.filters.media_source_pid}
				/>
				<CheckedFilter
					{...props}
					onChange={setUsernameChecked}
					checked={true}
					label={'Username'}
					id={'username'}
					arrayOnChange={props.setUsername}
					array={login_array.map((e) => e.username)}
					disabled={props.dataObj.filters.username === undefined}
					value={props.dataObj.filters.username}
				/>
				{/* {newFilters.map((el) => {
					return (
						<CheckedFilter
						{...props}
							key={el.id}
							onChange={el.onChange}
							checked={el.checked}
							label={el.label}
							id={el.id}
							arrayOnChange={el.arrayOnChange}
							array={el.array}
							disabled={el.disabled}
							value={el.value}
							remove={removeFilter}
						/>
					)
				})} */}
			</FormGroup>
			{/* {addFilterIsOpen && (
				<Box
					style={{
						width: '200px',
						height: `${
							newFilters.length > 0 ? 100 / newFilters.length : 100
						}px`,
						background: 'white',
						display: 'flex',
						position: 'absolute',
						top: '5%',
						right: '25%',
						borderRadius: '8px',
						flexDirection: 'column',
						justifyContent: 'space-evenly',
						alignItems: 'center',
						boxShadow: '0 14px 15px rgb(0 0 0 / 10%)'
					}}
					ref={ref}
				>
					{!checkIfFilterAlreadyAdded('app_id') && (
						<AddButton
							style={{ width: '130px', height: '26px', fontSize: '12px' }}
							onClick={() => handleNewFilter('app_id')}
						>
							App ID
						</AddButton>
					)}
					{!checkIfFilterAlreadyAdded('media_source_pid') && (
						<AddButton
							style={{ width: '130px', height: '26px', fontSize: '12px' }}
							onClick={() => handleNewFilter('media_source_pid')}
						>
							PID
						</AddButton>
					)}
				</Box>
			)} */}
		</div>
	)
}

const CheckedFilter = (props: {
	id: any
	onChange: any
	checked: boolean
	label: string
	arrayOnChange: any
	array: any[]
	value?: any[]
	disabled?: boolean
	setDataObj?: any
	dataObj?: any
	// remove?: any
}) => {
	const [extraFilter, setExtraFilter] = useState(false)
	useEffect(() => {
		const possibleFilters = [
			'app_id',
			'media_source_pid',
			'campaign_name',
			'campaign_id',
			'country',
		]
		if (possibleFilters.includes(props.id)) {
			setExtraFilter(true)
		}
	}, [])

	// const handleChange = () => {
	// 	props.onChange(!props.checked)
	// 	props.setDataObj({
	// 		from_date: props.dataObj.from_date,
	// 		to_date: props.dataObj.to_date,
	// 		breakdowns: props.dataObj.breakdowns,
	// 		statistics: props.dataObj.statistics,
	// 		filters: getFilters(),
	// 	})
	// 	if (props.checked === true) {
	// 		props.arrayOnChange([])
	// 		handleChangeField(props.id, [])
	// 	}
	// }
	// const getFilters = () => {
	// 	var filters: any = {
	// 		platform: props.dataObj.filters.platform,
	// 		app_id: props.dataObj.filters.app_id,
	// 		media_source_pid: props.dataObj.filters.media_source_pid,
	// 		app_name: props.dataObj.filters.app_name,
	// 		agency: props.dataObj.filters.agency,
	// 		pmd_af_prt: props.dataObj.filters.pmd_af_prt,
	// 	}
	// 	return filters
	// }
	// const handleChangeField = (id: keyof NetworkFieldsInterface, value: any) => {
	// 	Object.defineProperty(props.dataObj.filters, id!, value)
	// }

	return (
		<div style={{ width: '260px' }}>
			{props.checked ? (
				props.label !== 'Country' ? (
					<div key={props.id} style={{ display: 'flex' }}>
						<ChipInputList
							{...props}
							onChange={props.arrayOnChange}
							options={props.array}
							value={
								extraFilter ? props.dataObj.filters[props.id] : props.value
							}
							openWithClick={true}
							style={{ width: '100%' }}
						/>
						{/* {props.remove ? <button
							style={{
								background: 'none',
								border: 'none',
								cursor: 'pointer',
								height: '30px',
								alignSelf: 'center',
								marginTop: '24px',
								marginLeft: '-10px',
								width: '50px'
							}}
							onClick={() => props.remove(props.id)}
						>
							<DeleteForeverIcon />
						</button> : <span style={{height: '30px', width: '40px'}}></span>} */}
					</div>
				) : (
					<div key={props.id} style={{ display: 'flex' }}>
						<CountryListSelector
							{...props}
							onChange={props.arrayOnChange}
							options={props.array}
							value={props.dataObj.filters.country}
							style={{ width: '100%' }}
						/>
						{/* {props.remove ? <button
							style={{
								background: 'none',
								border: 'none',
								cursor: 'pointer',
								height: '30px',
								alignSelf: 'center',
								marginTop: '24px',
								marginLeft: '-10px',
								width: '50px'
							}}
							onClick={() => props.remove(props.id)}
						>
							<DeleteForeverIcon />
						</button> : <span style={{height: '30px', width: '50px'}}></span>} */}
					</div>
				)
			) : (
				<></>
			)}
		</div>
	)
}

const login_array = [
	{
		username: 'idan@thingortwo.agency',
		password: '!@Yxbdfnx625!@',
		googletoken: '',
		googleaccesstoken: '',
		'keep-user-logged-in': false,
		'2fa': '',
	},
	{
		username: 'shaked@tatanka.agency',
		password: 'XU!bqSdnqui5!@C5',
		googletoken: '',
		googleaccesstoken: '',
		'keep-user-logged-in': false,
		'2fa': '',
	},
	{
		username: 'adops@thingortwo.es',
		password: '3R!Yn^axS.n7kfH3!',
		googletoken: '',
		googleaccesstoken: '',
		'keep-user-logged-in': false,
		'2fa': '',
	},
	{
		username: 'adops@smartass.media',
		password: '$SFDbse2cw87i0',
		googletoken: '',
		googleaccesstoken: '',
		'keep-user-logged-in': false,
		'2fa': '',
	},
	{
		username: 'updates@thing02.com',
		password: 'vbN!SD#e5!dv99',
		googletoken: '',
		googleaccesstoken: '',
		'keep-user-logged-in': false,
		'2fa': '',
	},
	{
		username: 'support@pinkads.agency',
		password: 'yn2NQUV4@K4Dric6',
		googletoken: '',
		googleaccesstoken: '',
		'keep-user-logged-in': false,
		'2fa': '',
	},
	{
		username: 'support@admint.media',
		password: 'S!F4p8Ldmdur#knA-g',
		googletoken: '',
		googleaccesstoken: '',
		'keep-user-logged-in': false,
		'2fa': '',
	},
]
