import {
	Autocomplete,
	Chip,
	InputBase,
	InputLabel,
	TextField,
	TextFieldProps,
	styled,
} from '@mui/material'
import { StyledInputLabel } from '../campaigns/CampaignStyled'
import { Circle, RadioButtonUnchecked } from '@mui/icons-material'
import theme from '../../utils/theme2024'
import { useState } from 'react'
import { IconButton, InputAdornment } from '@mui/material'
import {
	default as Visibility,
	default as VisibilityIcon,
} from '@mui/icons-material/Visibility'
import VisibilityOff from '@mui/icons-material/VisibilityOff'
import LinkIcon from '@mui/icons-material/Link'
import { ErrorTag, InfoTag, StyledLabel } from './Informative'
import { useTypedSelector } from '../../hooks/useTypedSelector'
import { UserInterface } from '../../models/model.interface'
import { ChipInputList } from './reusableComponents'

export const RadioChipInput = (props: {
	options: string[]
	label: string
	value: string
	onChange: any
	disabled?: boolean
	style?: React.CSSProperties
	chipStyle?: React.CSSProperties
}) => {
	const { options, label, value, style, chipStyle, disabled, onChange } = props
	return (
		<>
			<StyledInputLabel
				style={{
					// marginBottom: '0.625rem',
					fontWeight: theme.font.weight.normal,
					// marginTop: '0.2rem',
					fontSize: theme.font.size.caption,
				}}
			>
				{label}
			</StyledInputLabel>
			<div
				style={{
					width: '25rem',
					display: 'flex',
					flexWrap: 'wrap',
					justifyContent: 'start',
					rowGap: '1.563rem',
					columnGap: '1.25rem',
					...style,
				}}
			>
				{options.map((option: string) => (
					<RadioChip
						option={option}
						value={value}
						onChange={onChange}
						disabled={disabled}
						chipStyle={chipStyle}
					/>
				))}
			</div>
		</>
	)
}

export const RadioChip = (props: {
	option: string
	value?: string
	onChange?: any
	disabled?: boolean
	chipStyle?: React.CSSProperties
}) => {
	const { option, value, disabled, chipStyle, onChange } = props
	return (
		<Chip
			disabled={disabled}
			onClick={() => props.onChange(option)}
			key={option}
			sx={{
				display: 'flex',
				alignItems: 'center',
				justifyContent: 'start',
				minWidth: '5.625rem',
				maxWidth: '8.125rem',
				paddingBlock: '0.25rem',
				paddingInline: '0.5rem',
				color:
					option === value
						? theme.colors.base.grey900
						: theme.colors.base.grey600,
				fontSize: theme.font.size.body,
				cursor: 'pointer',
				borderColor:
					option === value
						? theme.colors.base.green200
						: theme.colors.base.grey300,
				transition: 'all .3s ease-in-out',
				...chipStyle,
				'&.MuiChip-root&.MuiChip-outlined&:hover': {
					color: theme.colors.base.grey900,
					borderColor: theme.colors.base.green200,
				},
			}}
			variant='outlined'
			icon={
				<>
					<RadioButtonUnchecked
						style={{
							display: option !== value ? 'block' : 'none',
							fontSize: theme.font.size.body,
							color: theme.colors.base.grey300,
							marginInlineEnd: '-0.875rem',
						}}
					/>
					<div
						style={{
							borderRadius: '50%',
							background: theme.colors.base.green200,
							width: '0.813rem',
							height: '0.813rem',
							display: 'flex',
							alignItems: 'center',
							justifyContent: 'center',
							marginInlineEnd: '-0.313rem',
							transform: option !== value ? 'scale(0)' : 'scale(1)',
							transition: 'transform 0.3s ease-in-out',
						}}
					>
						<Circle
							style={{
								fontSize: '0.5rem',
								color: theme.colors.base.grey900,
								transform: option !== value ? 'scale(0)' : 'scale(1)',
								transition: 'transform 0.3s ease-in-out',
							}}
						/>
					</div>
				</>
			}
			label={option}
		/>
	)
}

export const InputFieldText = (props: {
	label: string | undefined
	type: string
	value: string | number | string[] | null | undefined
	onChange?: any
	onBlur?: any
	onFocus?: any
	disabled?: boolean
	keyname?: string
	hint?: any
	float?: 'right' | 'left'
	errormessage?: any
	warning?: any
	checked?: boolean
	multiline?: boolean
	style?: any
	customInputLabel?: string
	color?:
		| 'primary'
		| 'secondary'
		| 'error'
		| 'info'
		| 'success'
		| 'warning'
		| undefined
	showUrlIcon?: boolean
	inputProps?: any
	placeholder?: string
	noLabelSpace?: boolean
	sx?: any
	fullWidth?: boolean
	viewPasswordIcon?: boolean
	onKeyDown?: any
	mandatory?: boolean
}) => {
	const [focused, setFocused] = useState(false)
	const [firstFocus, setFirstFocus] = useState(false)
	const [hovered, setHovered] = useState(false)
	const [showPassword, setShowPassword] = useState(false)
	const [isFocused, setIsFocused] = useState(false)
	const [interacted, setInteracted] = useState(false) // Add this line

	const handleClickShowPassword = () => setShowPassword(!showPassword)
	const handleMouseDownPassword = () => setShowPassword(!showPassword)

	const handleEndAdornment = (type: string) => {
		if (type === 'password' && props.viewPasswordIcon) {
			return (
				<InputAdornment position='end'>
					<IconButton
						aria-label='toggle password visibility'
						onClick={handleClickShowPassword}
						onMouseDown={handleMouseDownPassword}
					>
						{showPassword ? <Visibility /> : <VisibilityOff />}
					</IconButton>
				</InputAdornment>
			)
		}
		if (type === 'custom') {
			return (
				<InputAdornment position='start'>
					{props.customInputLabel}
				</InputAdornment>
			)
		} else {
			return <></>
		}
	}

	const responsiveStyle = () => {
		const style: any = {
			border: '1px solid ' + theme.colors.base.grey300,
			borderRight: '6px solid ' + theme.colors.base.grey300,
		}
		if (props.disabled) {
			style.background = theme.colors.base.grey50
			return style
		}
		if (focused) {
			style.borderRight = '6px solid ' + theme.colors.base.green200
		}
		if (!props.value && hovered) {
			style.borderRight = '6px solid ' + theme.colors.base.green200
		}
		if (props.value && !props.errormessage) {
			style.borderRight = '6px solid ' + theme.colors.base.green200
		}
		if (props.errormessage && firstFocus) {
			style.borderRight = '6px solid ' + theme.colors.base.red300
		}
		return style
	}

	const { showUrlIcon, label, ...otherProps } = props
	return (
		<div
			style={{
				display: 'flex',
				position: 'relative',
				width: '100%',
				flexDirection: 'column',
			}}
		>
			{!props.noLabelSpace && (
				<div style={{ display: 'flex', gap: '20px' }}>
					<StyledLabel
						isFocused={focused}
						hasError={props.errormessage ? true : false}
					>
						{props.label}
						{props.mandatory && !interacted && (
							<span
								style={{
									color: `${theme.colors.base.red300}`,
									fontSize: '1rem',
									fontWeight: 'bold',
								}}
							>
								*
							</span>
						)}
					</StyledLabel>
					{props.showUrlIcon && props.value && !props.errormessage && (
						<button
							onClick={() => {
								const newTab: any = window.open(props.value as string, '_blank')
								newTab.focus()
							}}
							style={{
								position: 'absolute',
								right: '2%',
								top: '46%',
								border: 'none',
								cursor: 'pointer',
								zIndex: '2',
							}}
						>
							<LinkIcon></LinkIcon>
						</button>
					)}
					{props.hint ? (
						<div
							style={{
								position: 'absolute',
								right: '10%',
								top: '56%',
								border: 'none',
								cursor: 'pointer',
								zIndex: '2',
							}}
						>
							<InfoTag title={props.hint} />
						</div>
					) : null}
					{firstFocus && props.errormessage ? (
						<div
							style={{
								position: 'absolute',
								right: 24,
								top: 32,
								border: 'none',
								cursor: 'pointer',
								zIndex: '2',
							}}
						>
							<ErrorTag title={props.errormessage} />
						</div>
					) : null}
					{props.warning ? (
						<div
							style={{
								position: 'absolute',
								right: '10%',
								top: '56%',
								border: 'none',
								cursor: 'pointer',
								zIndex: '2',
							}}
						>
							<ErrorTag title={props.warning} />
						</div>
					) : null}
				</div>
			)}
			<StyledTextInputField
				multiline={props.multiline}
				focused
				{...otherProps}
				key={props.keyname ? props.keyname : 'InputName' + props.label}
				margin='dense'
				type={props.type === 'password' && showPassword ? 'text' : props.type}
				value={props.value}
				fullWidth
				onFocus={() => {
					props.onFocus && props.onFocus()
					setFocused(true)
					setFirstFocus(true)
					setIsFocused(true)
				}}
				onBlur={() => {
					props.onBlur && props.onBlur()
					setFocused(false)
				}}
				style={{ ...props.style, ...responsiveStyle() }}
				onMouseEnter={() => {
					setHovered(true)
				}}
				onMouseLeave={() => {
					setHovered(false)
				}}
				onChange={(e: any) => {
					setIsFocused(true)
					setInteracted(true) // Add this line
					if (props.value === 'All') {
						const newState = e.target.value.slice(4)
						props.onChange(newState)
					} else {
						props.onChange(e.target.value)
					}
				}}
				onKeyDown={props.onKeyDown}
				variant='outlined'
				placeholder={props.placeholder}
				InputProps={{
					...props.inputProps,
					endAdornment: props.customInputLabel
						? handleEndAdornment('custom')
						: handleEndAdornment(props.type),
				}}
				showurlicon={
					props.showUrlIcon ? props.showUrlIcon.toString() : undefined
				}
				showErrorTag={props.errormessage ? true : false}
				sx={props.sx}
			/>
		</div>
	)
}

export const StyledTextInputField = styled(TextField)<TextFieldProps | any>(
	({ showurlicon, value, showErrorTag }) => {
		const showIcon = showErrorTag || showurlicon === 'true'
		return {
			// backgroundColor: theme.colors.base.white,
			height: 'auto',
			fontWeight: theme.font.weight.normal,
			borderRadius: theme.dimentions.smallerRadius,
			color: theme.colors.text.general,
			margin: '0px',
			'& .MuiOutlinedInput-input': {
				backgroundColor: theme.colors.base.white,
				background: theme.colors.base.white + ' !important',
				borderRadius: theme.dimentions.smallerRadius,
				height: 'auto',
				fontSize: theme.font.size.caption,
				minHeight: '34px',
				fontWeight: theme.font.weight.normal,
				width: showIcon ? '80%' : '100%',
				transition: 'all 0.2s ease-in-out',
				color: 'inherit',
				margin: '0px',
			},
			'& .MuiOutlinedInput-root': {
				backgroundColor: theme.colors.base.white,
				background: theme.colors.base.white + ' !important',
				borderRadius: theme.dimentions.smallerRadius,
				height: 'auto',
				minHeight: '34px',
				paddingLeft: '8px',
				color: 'inherit',
				margin: '0px',
			},
		}
	},
)

export const StyledAutocomplete = styled(Autocomplete)(() => ({
	paddingTop: '0px',
	paddingLeft: '0px',

	'& .MuiOutlinedInput-root': {
		backgroundColor: theme.colors.base.white,
		borderRadius: theme.dimentions.smallerRadius,

		padding: '0px',
		'& .MuiAutocomplete-input': {
			padding: '0px',
			// width: '800px',
		},
	},
	'& .MuiOutlinedInput-input': {
		backgroundColor: theme.colors.base.white,
		//borderRadius: theme.dimentions.smallerRadius,
		'& .MuiAutocomplete-popper': {
			width: '500px',
		},
		padding: '0px',
	},
}))

export const StyledTextAreaField = (props: {
	label: string
	value: string | undefined
	onChange: any
}) => {
	const [focused, setFocused] = useState(false)
	const [hovered, setHovered] = useState(false)

	const responsiveStyle = () => {
		const style: any = {
			border: '1px solid ' + theme.colors.base.grey300,
			borderRight: '6px solid ' + theme.colors.base.grey300,
		}
		if (focused) {
			style.borderRight = '6px solid ' + theme.colors.base.green200
		}
		if (!props.value && hovered) {
			style.borderRight = '6px solid ' + theme.colors.base.green200
		}
		if (props.value) {
			style.borderRight = '6px solid ' + theme.colors.base.green200
		}
		return style
	}

	return (
		<div
			style={{
				display: 'flex',
				position: 'relative',
				flexDirection: 'column',
				width: 'inherit',
			}}
		>
			<StyledLabel isFocused={focused}>{props.label}</StyledLabel>
			<TextField
				// label={props.label}
				value={props.value}
				key={props.label + 'textarea'}
				onChange={(e) => props.onChange(e.target.value)}
				fullWidth
				variant='outlined'
				multiline
				focused
				rows={6}
				inputProps={{
					style: {
						padding: '12px',
						fontSize: theme.font.size.caption,
					},
				}}
				// color={'primary'}
				style={{
					borderRadius: '8px',
					backgroundColor: theme.colors.base.white,
					fontSize: theme.font.size.caption,
					...responsiveStyle(),
				}}
				onFocus={() => {
					setFocused(true)
				}}
				onBlur={() => {
					setFocused(false)
				}}
				onMouseEnter={() => {
					setHovered(true)
				}}
				onMouseLeave={() => {
					setHovered(false)
				}}
			/>
		</div>
	)
}

export const StyledInputBase = styled(InputBase)(({ disabled }) => ({
	'& .MuiInputBase-input': {
		padding: theme.spacing(0.8, 1, 1, 1),
		paddingLeft: `calc(1em + ${theme.spacing(4)})`,
		width: 'inherit',
		height: '100% !important',
		backgroundColor: 'rgba(242, 242, 242, 1)',
		cursor: disabled ? 'not-allowed' : 'text',
	},
}))
