import type {} from '@mui/lab/themeAugmentation'
import {
	createTheme,
	responsiveFontSizes,
	Theme,
	Theme2024,
	ThemeOptions2024,
} from '@mui/material'
declare module '@mui/material/styles' {
	//theme is temporally added here
	export interface Theme {
		colors: {
			white: string
			black: string
			gradient: string
			buttonGradient: string
			lightGradient: string
			activeGradient: string
			disabledGradient: string
			titleGradient: string
			green: string
			gray: string
			lightGray: string
			purple: string
			yellow: string
			red: string
			blue: string
		}
		animatedItem: any
		shadow: {
			boxShadow: string
			lightShadow: string
		}
		dimentions: {
			drawerWidth: number
			smallerRadius: string
			biggerRadius: string
		}
	}

	export interface ThemeOptions {
		colors?: {
			white?: string
			black?: string
			gradient?: string
			buttonGradient?: string
			lightGradient?: string
			activeGradient?: string
			disabledGradient?: string
			titleGradient?: string
			green?: string
			gray?: string
			lightGray?: string
			purple?: string
			yellow?: string
			red?: string
			blue?: string
		}
		animatedItem?: any
		shadow?: {
			boxShadow?: string
			lightShadow?: string
		}
		dimentions?: {
			drawerWidth?: number
			smallerRadius?: string
			biggerRadius?: string
		}
	}
	interface Theme {
		font: any
		customComponents: any
	}
	export interface Theme2024 extends Theme {
		colors: any
		font: any
		customComponents: any
		shadow: {
			boxShadow: string
			lightShadow: string
		}
		dimentions: {
			drawerWidth: number
			smallerRadius: string
			biggerRadius: string
		}
	}

	export interface ThemeOptions2024 {
		font?: any
		colors?: any
		shadow?: {
			boxShadow?: string
			lightShadow?: string
		}
		dimentions?: {
			drawerWidth?: number
			smallerRadius?: string
			biggerRadius?: string
		}
	}
}
const smallerRadius = '6px'
const biggerRadius = '15px'
const fontFamily = 'Lato, Arial, sans-serif'
const font = {
	fontFamily: fontFamily,
	size: {
		titles: '22px',
		buttons: '0.9rem',
		body: '14px',
		caption: '12px',
		small: '10px',
	},
	weight: {
		skinny: '300',
		normal: '400',
		bold: '500',
		mediumBold: '600',
		bolder: '700',
	},
}
const baseColors = {
	base: {
		white: 'rgba(255,255,255,1)',
		lightGreen: 'rgba(202, 240, 78, 1)',
		grey: 'rgba(251, 251, 251, 1)',
		lightGrey: 'rgba(242, 242, 242, 1)',
		grey2: 'rgba(233, 236, 239, 1)',
		grey3: 'rgba(138, 146, 166, 1)',

		// greys
		grey50: 'rgba(251, 251, 251, 1)', // originally grey
		grey80: 'rgba(241, 241, 241, 1)',
		grey100: 'rgba(242, 242, 242, 1)', // originally lightGrey
		grey200: 'rgba(233, 236, 239, 1)', // originally grey2
		grey300: 'rgba(217, 217, 217, 1)',
		grey500: 'rgba(138, 146, 166, 1)', // originally grey3
		grey600: 'rgba(146, 146, 157, 1)',
		grey700: 'rgba(92, 92, 92, 1)',
		grey900: 'rgba(36, 45, 50, 1)',

		// greens
		green20: 'rgba(235, 255, 211, 1)',
		green50: 'rgba(228, 253, 197, 1)',
		green100: 'rgba(202, 240, 78, 1)', // originally lightGreen
		green200: 'rgba(181, 250, 97, 1)',
		green300: 'rgba(186, 220, 72, 1)',
		green400: 'rgba(163, 225, 87, 1)',
		green500: 'rgba(18, 174, 39, 1)',

		//yellows:
		yellow300: 'rgba(218, 174, 31, 1)', //error
		yellow400: 'rgba(248, 208, 79, 1)', //error
		//reds:
		red300: 'rgba(236, 60, 40, 1)', //error
		red400: 'red',
		//blues:
		blue300: 'rgba(72, 76, 255, 1)', //error
		blue400: 'rgba(66, 114, 246, 1)', //error
	},
	status: {
		green: 'rgba(113, 220, 54, 1)', //active
		grey: 'rgba(214, 214, 214, 1)', //inactive
		red: 'rgba(192, 50, 33, 1)', //error
	},
	text: {
		grey: 'rgba(146, 146, 157, 1)',
		inactive: 'rgba(173, 185, 189, 1)', //grey darker
		titles: 'rgba(0, 0, 0, 1)', //black
		general: 'rgba(36, 45, 50, 1)', //black
		colored: 'rgba(98, 219, 123, 1)', //green
	},
}

const customComponents = {
	button: {
		green: 'rgba(181, 250, 97, 1)',
		iconHeight: '20px',
		iconMargin: '1px 6px 0px 0px',
	},
	input: {
		borderRadius: '4px',
		border: `1px solid ${baseColors.base.grey2}`,
		backgroundColor: baseColors.base.lightGrey,
	},
}

//green - yellow gradient
const gradientColors = {
	primary: 'rgba(98, 239, 141, 1)', //green
	secondary: 'rgba(233, 219, 45, 1)', //yellow
	primarySecondaryMix: 'rgba(182, 227, 82, 1)', //mix light
	primarySecondaryMixDarker: 'rgba(152, 231, 105, 1)', //mix darker
}

export const theme: Theme2024 = responsiveFontSizes(
	createTheme({
		colors: {
			...baseColors,
			gradientMenu: `conic-gradient(from 170deg, ${gradientColors.primary} 23%, ${gradientColors.secondary} 28%, ${gradientColors.primarySecondaryMix} 30%,${gradientColors.primarySecondaryMixDarker} 74%, ${gradientColors.primary} 80%)`,
			gradientLoginPage: `conic-gradient(from 175deg, ${gradientColors.primary} 0%, ${gradientColors.secondary} 22%,  ${gradientColors.primary}100%)`,
		},
		font: { ...font },
		customComponents: { ...customComponents },
		shadow: {
			boxShadow: '-8px 8px 17px 0px rgb(0 0 0 / 21%)',
			lightShadow: '-8px 8px 17px 0px rgb(0 0 0 / 10%)',
		},
		dimentions: {
			drawerWidth: 40,
			smallerRadius: smallerRadius,
			biggerRadius: biggerRadius,
		},
		components: {
			MuiCheckbox: {
				styleOverrides: {
					colorPrimary: {
						color: 'inherit',
					},
					colorSecondary: {
						color: 'inherit',
					},

					root: {
						'&.Mui-checked': {
							color: 'inherit',
							backgroundColor: 'inherit',
						},
						'&.Mui-checked:hover': {
							color: 'inherit',
							backgroundColor: 'inherit',
						},
						'&.Mui-checked:active': {
							color: 'inherit',
							backgroundColor: 'inherit',
						},
						'&.Mui-checked:focus': {
							color: 'inherit',
							backgroundColor: 'inherit',
						},
					},
					// disabled: { color: 'gray', backgroundColor: 'gray' },
				},
			},
			MuiOutlinedInput: {
				styleOverrides: {
					root: {
						borderRadius: smallerRadius,
						padding: '0px',
						'& .MuiOutlinedInput-notchedOutline': {
							border: 'none',
							maxHeight: '14px',
						},
						'& .MuiOutlinedInput-input': {
							padding: '0px',
						},
					},
				},
			},

			// MuiFormLabel: {
			// 	styleOverrides: {
			// 		asterisk: {
			// 			color: '#ee1d4a',
			// 		},
			// 		root: {
			// 			color: '#808080',
			// 			fontWeight: 'bolder',
			// 			top: '25%',
			// 			'&.Mui-focused': {
			// 				color: '#808080',
			// 				fontWeight: 'bolder',
			// 				top: '0%',
			// 			},
			// 		},
			// 	},
			// },
			MuiSwitch: {
				styleOverrides: {
					root: {
						width: 46,
						height: 26,
						padding: 0,
						'& .MuiSwitch-switchBase': {
							padding: 0,
							margin: 2,
							transitionDuration: '200ms',
							'&.Mui-checked': {
								transform: 'translateX(20px)',

								'& + .MuiSwitch-track': {
									opacity: 1,
									border: 0,
								},
								'&.Mui-disabled + .MuiSwitch-track': {
									opacity: 0.5,
								},
							},
							'&.Mui-focusVisible .MuiSwitch-thumb': {
								color: baseColors.base.green200,
								border: '6px solid #fff',
							},
							'&.Mui-disabled .MuiSwitch-thumb': {
								color: baseColors.base.grey200,
							},
							'&.Mui-disabled + .MuiSwitch-track': {
								opacity: 0.7,
							},
						},
						'& .MuiSwitch-thumb': {
							boxSizing: 'border-box',
							width: 22,
							height: 22,
							marginLeft: '2px',
							backgroundColor: baseColors.base.white,
						},
						'& .MuiSwitch-track': {
							borderRadius: 13,
							backgroundColor: baseColors.base.grey600,
							opacity: 1,
						},
					},
					switchBase: {
						'&.Mui-checked': {
							color: '#d0d0d0',
						},
						'&.Mui-checked+.MuiSwitch-track': {
							background: baseColors.base.green200,
							opacity: '1',
						},
					},
				},
			},
			// MuiGrid: {
			// 	styleOverrides: {
			// 		item: {
			// 			padding: '0px',
			// 		},
			// 		root: {
			// 			'&.MuiGrid-item': {
			// 				paddingTop: '0px',
			// 				paddingLeft: '0px',
			// 			},
			// 			'&&': {
			// 				paddingLeft: '0px',
			// 			},
			// 		},
			// 	},
			// },
			// MuiFormControl: {
			// 	styleOverrides: {
			// 		root: {
			// 			margin: '0px',
			// 			padding: '0px',
			// 		},
			// 	},
			// },
			// MuiFormControlLabel: {
			// 	styleOverrides: {
			// 		root: {
			// 			color: '#ffffff',
			// 			'&.MuiDisabled': {
			// 				color: '#aaaaaa',
			// 			},
			// 		},
			// 	},
			// },
			MuiListItem: {
				styleOverrides: {
					root: {
						backgroundColor: '#fafbfc',
						fontWeight: 600,
					},
				},
			},

			MuiButton: {
				styleOverrides: {
					startIcon: {
						color: '#ee1d4a',
					},
					endIcon: {
						color: '#ee1d4a',
					},
					root: {
						'&:hover': {
							background: 'transparent',
							color: baseColors.base.green300,
							// border: '0px !important'
							borderColor: 'transparent',
						},
					},
				},
			},
			MuiIconButton: {
				styleOverrides: {
					root: {
						'&:hover': {
							background: 'inherit',
							// color: baseColors.base.green300,
							// border: '0px !important'
							borderColor: 'transparent',
						},
					},
				},
			},
			MuiTouchRipple: {
				styleOverrides: {
					root: {
						'&:active': {
							background: 'inherit',
							// color: baseColors.base.green300,
							// border: '0px !important'
							backgroundColor: 'inherit',
						},
						'&:focus': {
							background: 'inherit',
							// color: baseColors.base.green300,
							// border: '0px !important'
							backgroundColor: 'inherit',
						},
					},
				},
			},
			MuiPaper: {
				styleOverrides: {
					root: {
						borderRadius: smallerRadius,
						boxShadow: 'none',
					},
				},
			},

			MuiMenuItem: {
				styleOverrides: {
					root: {
						backgroundColor: baseColors.base.white,
						'&:hover': {
							background: 'transparent',
							color: baseColors.base.green300,
							borderRadius: smallerRadius,
						},
					},
				},
			},
			MuiListItemButton: {
				styleOverrides: {
					root: {
						stroke: 'black',
						'&:hover': {
							background: 'transparent',
							color: baseColors.base.green300,
							stroke: 'white',
						},
					},
				},
			},
			MuiLinearProgress: {
				styleOverrides: {
					bar: {
						// color: '#ee1d4a!important',
						// background: 'rgba(238,29,66,1)',
					},

					barColorPrimary: {
						color: baseColors.base.green200 + '!important',
						background: `linear-gradient(90deg, ${baseColors.base.green200} 0%, ${baseColors.base.green400} 100%)`,
					},
					barColorSecondary: {
						color: baseColors.base.green400 + '!important',
						background: `linear-gradient(90deg, ${baseColors.base.green200} 0%, ${baseColors.base.green400} 100%)`,
					},
				},
			},
			MuiPickersDay: {
				styleOverrides: {
					root: {
						'&[aria-selected=true]': {
							backgroundColor: baseColors.base.green200,
						},
						'&[aria-selected=true]:hover': {
							backgroundColor: baseColors.base.green200,
						},
						'&[aria-selected=true]:focus': {
							backgroundColor: baseColors.base.green200,
						},
					},
				},
			},
			MuiDateRangePickerInput: {
				styleOverrides: {
					root: {
						width: '100%',
						'& .MuiFormControl-root.MuiTextField-root': {
							width: '100%',
						},
					},
				},
			},
			MuiDataGrid: {
				styleOverrides: {
					root: {
						'& .MuiDataGrid-row.Mui-selected': {
							backgroundColor: baseColors.base.grey50,
						},
						'& .MuiDataGrid-row:hover': {
							backgroundColor: baseColors.base.grey50,
						},
						'& .MuiDataGrid-row.Mui-selected:hover': {
							backgroundColor: baseColors.base.grey50,
						},
						'& .MuiDataGrid-row.Mui-selected:focus-visible': {
							backgroundColor: baseColors.base.grey50,
						},
						'& .MuiDataGrid-columnHeader:focus-within': {
							outline: 'none',
						},
						'& .MuiDataGrid-cell:focus-within': {
							outline: 'none',
						},
						'& .MuiDataGrid-iconButtonContainer .MuiBadge-badge': {
							fontSize: '0px', // Hides the number in the badge
						},
						'& [data-field="__tree_data_group__"].MuiDataGrid-cell--withRenderer svg':
							{
								color: baseColors.base.green400 + ' !important',
							},
						'& [data-field="__tree_data_group__"].MuiDataGrid-cell--withRenderer span':
							{
								fontWeight: font.weight.normal + '!important',
							},
					},
				},
			},
			MuiAutocomplete: {
				styleOverrides: {
					input: {
						fontSize: '14px',
						fontWeight: 500,
					},
					option: {
						background: baseColors.base.white + ' !important',
						backgroundColor: 'none !important',
						fontSize: font.size.body,
						'&.Mui-focused': {
							background: baseColors.base.white,
							backgroundColor: baseColors.base.white,
							color: baseColors.base.green300,
						},
						'&.Mui-focusVisible': {
							background: baseColors.base.white,
							backgroundColor: baseColors.base.white,
							color: baseColors.base.green300,
						},
						'&[aria-selected=true]': {
							background: baseColors.base.white + ' !important',
							backgroundColor: baseColors.base.white + ' !important',
							color: baseColors.base.green300,
						},
						'&[aria-selected=true].Mui-focused': {
							background: baseColors.base.white,
							backgroundColor: baseColors.base.white,
							color: baseColors.base.green300,
						},
						'&[aria-selected=true] .MuiMenuItem-root': {
							background: baseColors.base.white + ' !important',
							backgroundColor: baseColors.base.white + ' !important',
							fontWeight: font.weight.normal + ' !important',
						},
					},

					popper: {
						width: 'fit-content!important',
					},
					root: {
						padding: '0px',
						'& .MuiOutlinedInput-root': {
							padding: '0px 65px 0px 8px',
							minHeight: '40px',
							fontSize: '14px',
						},
					},
				},
			},
			MuiTextField: {
				styleOverrides: {
					root: {
						'& .MuiOutlinedInput-notchedOutline legend': { display: 'none' },
						'& .MuiOutlinedInput-notchedOutline': {
							top: '0px',
						},
					},
				},
			},
			// MuiListItemText: {
			// 	styleOverrides: {
			// 		root: {
			// 			color: 'inherit',
			// 		},
			// 	},
			// },
			MuiSvgIcon: {
				styleOverrides: {
					colorDisabled: {
						color: baseColors.base.grey300,
					},
					colorPrimary: {
						color: baseColors.text.titles,
					},
					colorSecondary: {
						color: 'white',
					},
				},
			},
			MuiSelect: {
				styleOverrides: {
					icon: { color: '#000000' },
					nativeInput: {
						padding: '0px',
					},
				},
			},
			MuiDialog: {
				styleOverrides: {
					paper: {
						borderRadius: biggerRadius,
						width: '500px',
					},
				},
			},
		},
	} as ThemeOptions2024),
)

export default theme
