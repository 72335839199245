import { ActionType } from '../action-types/index'

interface GetSalesPlanAppsReducer {
	type: ActionType.GET_SALES_PLAN_APPS
	payload: any
}
interface LoginInterfacrReducer {
	type: ActionType.LOGIN
	payload: any
}

interface GetUsersInterfaceReducer {
	type: ActionType.GET_USERS
	payload: any
}

interface InsertUserInterfaceReducer {
	type: ActionType.INSERT_USER
	payload: any
}

interface DeleteUserInterfaceReducer {
	type: ActionType.DELETE_USER
	payload: any
}

interface ResetPasswordInterfaceReducer {
	type: ActionType.RESET_PASSWORD
	payload: any
}
interface NewsReducerInterface {
	type: ActionType.GET_NEWS
	payload: any
}

interface PostNewsReducerInterface {
	type: ActionType.POST_NEWS
	payload: any
}

interface PostNewsImageReducerInterface {
	type: ActionType.POST_IMAGE
	payload: any
}

interface GetAdvertiserInterfaceReducer {
	type: ActionType.GET_ADVERTISER
	payload: any
}
interface GetAdvertiserReportInterfaceReducer {
	type: ActionType.GET_ADVERTISER_REPORT
	payload: any
}
interface GetAdvertiserContactsInterfaceReducer {
	type: ActionType.GET_ADVERTISER_CONTACTS
	payload: any
}

interface InsertAdvertiserInterfaceReducer {
	type: ActionType.INSERT_ADVERTISER
	payload: any
}
interface DeleteAdvertiserInterfaceReducer {
	type: ActionType.DELETE_ADVERTISER
	payload: any
}

interface DeleteAdvertiserIconAction {
	type: ActionType.DELETE_ADVERTISER_ICON
	payload: {
		name: string
		iconUrl: string | null
	}
}

interface UpdateAdvertiserIconAction {
	type: ActionType.UPDATE_ADVERTISER_ICON
	payload: { name: string; iconUrl: string }
}

interface UpdatePublisherIconAction {
	type: ActionType.UPDATE_PUBLISHER_ICON
	payload: { name: string; iconUrl: string }
}

interface DeletePublisherIconAction {
	type: ActionType.DELETE_PUBLISHER_ICON
	payload: {
		name: string
		iconUrl: string | null
	}
}

interface UpdateCreatorIconAction {
	type: ActionType.UPDATE_CREATOR_ICON
	payload: { name: string; iconUrl: string }
}

interface DeleteCreatorIconAction {
	type: ActionType.DELETE_CREATOR_ICON
	payload: {
		name: string
		iconUrl: string | null
	}
}

interface LogoutInterfaceReducer {
	type: ActionType.LOGOUT
	payload: any
}

interface GetPublisherInterfaceReducer {
	type: ActionType.GET_PUBLISER
	payload: any
}

interface GetPublisherReportInterfaceReducer {
	type: ActionType.GET_PUBLISHER_REPORT
	payload: any
}
interface GetPublisherContactsInterfaceReducer {
	type: ActionType.GET_PUBLISHER_CONTACTS
	payload: any
}

interface InsertPublisherInterfaceReducer {
	type: ActionType.INSERT_PUBLIHSER
	payload: any
}

interface GetCampaignInterfaceReducer {
	type: ActionType.GET_CAMPAIGN
	payload: any
}

interface GetFiltersInterfaceReducer {
	type: ActionType.SEND_APPSFLYER
	payload: any
}

interface InsertCampaignInterfaceReducer {
	type: ActionType.INSERT_CAMPAIGN
	payload: any
}
interface DeleteCampaignInterfaceReducer {
	type: ActionType.DELETE_CAMPAIGN
	payload: any
}
interface GetAppInterfaceReducer {
	type: ActionType.GET_APP
	payload: any
}
interface InsertP360RuleInterfaceReducer {
	type: ActionType.INSERT_P360_RULE
	payload: any
}
interface RemoveP360RuleInterfaceReducer {
	type: ActionType.REMOVE_P360_RULE
	payload: any
}
interface UpdateAppP360nterfaceReducer {
	type: ActionType.UPDATE_APP_P360
	payload: any
}
interface RemoveAppP360nterfaceReducer {
	type: ActionType.REMOVE_APP_P360
	payload: any
}
interface GetIconsInterfaceReducer {
	type: ActionType.GET_ICONS
	payload: any
}
interface UpdateAppReducerInterface {
	type: ActionType.UPDATE_APP
	payload: any
}
interface GetAppsflyerfaceReducer {
	type: ActionType.GET_APPSFLYER
	payload: any
}
interface AddAppReducer {
	type: ActionType.ADD_APP
	payload: any
}
interface UpdateAppInfoReducer {
	type: ActionType.UPDATE_APP_INFO
	payload: any
}

interface SaveAppsflyerReducer {
	type: ActionType.SAVE_APPSFLYER
	payload: any
}

interface NavigationReducer {
	type: ActionType.NAVIGATION
	payload: any
}

interface ReevaluateReducer {
	type: ActionType.REEVALUATE
	payload: any
}

interface PresearchReducer {
	type: ActionType.PRESEARCH
	payload: any
}
interface InsertLogReducer {
	type: ActionType.INSERT_LOG
	payload: any
}
interface GetLogsReducer {
	type: ActionType.GET_LOGS
	payload: any
}
interface GetSettingsReducer {
	type: ActionType.GET_SETTINGS
	payload: any
}
interface UpdateSettingsReducer {
	type: ActionType.UPDATE_SETTINGS
	payload: any
}

interface PresetReducer {
	type: ActionType.PRESET
	payload: any
}

interface GetNetworkReducer {
	type: ActionType.GET_NETWORK
	payload: any
}
interface PostNetworkReducer {
	type: ActionType.POST_NETWORK
	payload: any
}
interface UpdateNetworkReducer {
	type: ActionType.UPDATE_NETWORK
	payload: any
}
interface RefreshAppsflyerReducer {
	type: ActionType.UPDATE_APPSFLYER
	payload: any
}
interface PostRawReducer {
	type: ActionType.POST_RAWDATA
	payload: any
}
interface GetRawReducer {
	type: ActionType.GET_RAWDATA
	payload: any
}
interface UpdatesReducer {
	type: ActionType.UPDATE
	payload: any
}
interface GetPasswordsReducer {
	type: ActionType.GETPASSWORDS
	payload: any
}
interface GetAppsflyerAccountsReducer {
	type: ActionType.GETACCOUNTS
	payload: any
}
interface GetLearningReducer {
	type: ActionType.GET_LEARNING
	payload: any
}
interface AddLearningReducer {
	type: ActionType.ADD_LEARNING
	payload: any
}
interface RemoveLearningReducer {
	type: ActionType.REMOVE_LEARNING
	payload: any
}
interface GetDashboardReducer {
	type: ActionType.GETDASHBOARD
	payload: any
}
interface GetCampiagnHistoryReducer {
	type: ActionType.GET_CAMPAIGN_HISTORY
	payload: any
}
interface GetFinanceReducer {
	index?: number
	payload: any
	type: ActionType.GET_FINANCE
}
interface EmptyFinanceReducer {
	payload: any
	type: ActionType.EMPTY_FINANCE
}
interface UploadFinanceReducer {
	payload: any
	type: ActionType.UPLOAD_FINANCE
}
interface DeleteFinanceReducer {
	payload: any
	type: ActionType.DELETE_FINANCE
}
interface UploadFinanceRollOverReducer {
	payload: any
	type: ActionType.UPLOAD_FINANCE_ROLLOVER
}
interface UploadFinanceDemandReducer {
	payload: any
	type: ActionType.UPLOAD_FINANCE_DEMAND
}
interface UploadFinanceBalanceInReducer {
	payload: any
	type: ActionType.UPLOAD_FINANCE_BALANCEIN
}
interface UploadFinanceBalanceOutReducer {
	payload: any
	type: ActionType.UPLOAD_FINANCE_BALANCEOUT
}
interface UploadInstructionsFinanceReducer {
	payload: any
	type: ActionType.UPLOAD_INSTRUCTIONS_FINANCE
}
interface UploadProofFinanceReducer {
	payload: any
	type: ActionType.UPLOAD_PROOF_FINANCE
}
interface DeleteProofFinanceReducer {
	payload: any
	type: ActionType.DELETE_PROOF_FINANCE
}
interface SendEmailFinanceReducer {
	payload: any
	type: ActionType.SEND_EMAIL_FINANCE
}
interface SendEmailAdvertiserFinanceReducer {
	payload: any
	type: ActionType.SEND_EMAIL_FINANCE_ADVERTISER
}
interface CreateCreatorCampaignFinanceReducer {
	payload: any
	type: ActionType.CREATE_CREATOR_CAMPAIGN
}
interface GetAutoSwitch {
	type: ActionType.GETAUTOSWITCH
	payload: any
}
interface PutAutoSwitch {
	type: ActionType.PUTAUTOSWITCH
	payload: any
}

interface DeleteAutoSwitch {
	type: ActionType.DELETEAUTOSWITCH
	payload: any
}

interface AvatarsReducer {
	type: ActionType.AVATAR
	payload: any
}

interface VersionReducer {
	type: ActionType.GETVERSION
	payload: any
}

interface P360Reducer {
	type: ActionType.P360AGGREGATED
	payload: any
}

interface GetPublisherWishListReducer {
	type: ActionType.GETWISHLIST
	payload: any
}
interface AddPublisherWishListReducer {
	type: ActionType.ADDWISHLIST
	payload: any
}
interface UpdatePublisherWishListReducer {
	type: ActionType.UPDATEWISHLIST
	payload: any
}
interface DeletePublisherWishListReducer {
	type: ActionType.DELETEWISHLIST
	payload: any
}
interface GetCreatortReducer {
	type: ActionType.GET_CREATOR
	payload: any
}
interface GetPidHandshakeReducer {
	type: ActionType.GETHANDSHAKE
	payload: any
}
interface InsertCreatorReducer {
	type: ActionType.INSERT_CREATOR | ActionType.INSERT_CREATORS
	payload: any
}

export type Action =
	| LoginInterfacrReducer
	| GetUsersInterfaceReducer
	| GetAdvertiserInterfaceReducer
	| GetAdvertiserReportInterfaceReducer
	| GetAdvertiserContactsInterfaceReducer
	| UpdateAdvertiserIconAction
	| DeleteAdvertiserIconAction
	| LogoutInterfaceReducer
	| GetPublisherInterfaceReducer
	| InsertAdvertiserInterfaceReducer
	| DeleteAdvertiserInterfaceReducer
	| InsertPublisherInterfaceReducer
	| UpdatePublisherIconAction
	| DeletePublisherIconAction
	| GetCampaignInterfaceReducer
	| InsertCampaignInterfaceReducer
	| DeleteCampaignInterfaceReducer
	| GetAppsflyerfaceReducer
	| InsertUserInterfaceReducer
	| GetAppInterfaceReducer
	| GetIconsInterfaceReducer
	| ResetPasswordInterfaceReducer
	| GetFiltersInterfaceReducer
	| UpdateAppReducerInterface
	| InsertP360RuleInterfaceReducer
	| RemoveP360RuleInterfaceReducer
	| UpdateAppP360nterfaceReducer
	| RemoveAppP360nterfaceReducer
	| DeleteUserInterfaceReducer
	| NewsReducerInterface
	| AddAppReducer
	| UpdateAppInfoReducer
	| SaveAppsflyerReducer
	| NavigationReducer
	| ReevaluateReducer
	| PresearchReducer
	| InsertLogReducer
	| GetLogsReducer
	| GetSettingsReducer
	| UpdateSettingsReducer
	| PresetReducer
	| PostNewsReducerInterface
	| PostNewsImageReducerInterface
	| GetNetworkReducer
	| PostNetworkReducer
	| RefreshAppsflyerReducer
	| UpdateNetworkReducer
	| PostRawReducer
	| GetRawReducer
	| UpdatesReducer
	| GetPublisherReportInterfaceReducer
	| GetPublisherContactsInterfaceReducer
	| GetPasswordsReducer
	| GetLearningReducer
	| AddLearningReducer
	| RemoveLearningReducer
	| GetDashboardReducer
	| GetAutoSwitch
	| PutAutoSwitch
	| DeleteAutoSwitch
	| GetAppsflyerAccountsReducer
	| GetCampiagnHistoryReducer
	| GetFinanceReducer
	| EmptyFinanceReducer
	| AvatarsReducer
	| UploadFinanceReducer
	| DeleteFinanceReducer
	| UploadFinanceRollOverReducer
	| UploadFinanceDemandReducer
	| UploadFinanceBalanceInReducer
	| UploadFinanceBalanceOutReducer
	| SendEmailFinanceReducer
	| SendEmailAdvertiserFinanceReducer
	| UploadInstructionsFinanceReducer
	| UploadProofFinanceReducer
	| DeleteProofFinanceReducer
	| CreateCreatorCampaignFinanceReducer
	| VersionReducer
	| P360Reducer
	| GetSalesPlanAppsReducer
	| GetPublisherWishListReducer
	| UpdatePublisherWishListReducer
	| AddPublisherWishListReducer
	| DeletePublisherWishListReducer
	| GetPidHandshakeReducer
	| GetCreatortReducer
	| InsertCreatorReducer
	| UpdateCreatorIconAction
	| DeleteCreatorIconAction

export const emptyFinance = () => {
	return {
		type: ActionType.EMPTY_FINANCE,
		payload: {},
	}
}
