import { ThemeProvider } from '@emotion/react'
import '@inovua/reactdatagrid-community/index.css'
import { Grid, MenuItem } from '@mui/material'
import { GridColDef } from '@mui/x-data-grid-pro'
import saveAs from 'file-saver'
import { useEffect, useState } from 'react'
import { useActions } from '../../hooks/useActions'
import { useTypedSelector } from '../../hooks/useTypedSelector'
import { fetchData } from '../../utils/helpers/navigationHelper'
import { generateRowsWithIds, searchFor } from '../../utils/helpers/tableHelper'
import { WarningPrompt } from '../../utils/notifications/WarningPrompt'
import theme from '../../utils/theme2024'
import { PageWrapper, TableWrapper } from '../components/reusableComponents'
import AccountsEdit from './AppsflyerAccountsAdd'
import { TableComponent } from '../components/TableComponents'
import { SimpleActionsButton } from '../components/Buttons'

const AppsflyerAccountsTable = (props: {
	setLoading: any
	errorMessage: any
	setErrorMessage: any
	isErrorPromptOpened: any
	setIsErrorPromptOpened: any
	isSuccessPromptOpened: any
	setIsSuccessPromptOpened: any
	isEditorOpened?: any
	isAddEditorOpened?: any
	presetSearch: any
	setPresetSearch: any
	PopUpVisible: any
	setPopUpVisible: any
}) => {
	const rowCount = 7
	const { login, presearch, accounts } = useTypedSelector((state) => state)
	const { presearchAction, getAccountsAction, deleteAccountsAction } =
		useActions()
	const [pageSize, setPageSize] = useState(15)
	const [PopUpVisible, setPopUpVisible] = useState(props.isEditorOpened)
	const [AddVisible, setAddVisible] = useState(false)
	const [row, setRow] = useState<any>(undefined)
	const [search, setSearch] = useState('')
	const [found, setFound] = useState<any[]>(
		// searchFor(search, accounts.accounts, columns),
		[],
	)
	const [blockPasswords, setBlockPasswords] = useState(true)
	const [codeHash, setCodeHash] = useState('')
	const [update, setUpdate] = useState(false)
	const [isWarningPromptOpened, setIsWarningPromptOpened] = useState(false)
	const [rowWidth, setRowWidth] = useState(
		Math.floor(window.innerWidth - theme.dimentions.drawerWidth) / rowCount,
	)
	const [isAdding, setIsAdding] = useState(false)
	const [sortModel, setSortModel] = useState([{ field: 'name', sort: 'asc' }])
	const [pwdChange, setPwdChange] = useState('')
	const [fileDownload, setFileDownload] = useState()
	const headers = {
		Authorization: `Token ${login.user.token}`,
	}
	const actionOptions = (row: any) => {
		return (
			<div>
				<MenuItem onClick={() => handleEdit(row)}>Edit</MenuItem>
				{login.user.role.toLowerCase() === 'super' && (
					<MenuItem
						onClick={() => {
							setRow(row.row)
							setIsWarningPromptOpened(true)
						}}
					>
						Delete
					</MenuItem>
				)}
			</div>
		)
	}

	const columns: GridColDef[] = [
		{
			field: 'user_name',

			headerName: 'User Name',
			width: rowWidth,
		},
		{
			field: 'password',

			headerName: 'Password',
			width: rowWidth,

			renderCell: ({ value, row }: { value?: any; row?: any }) => {
				return login.user.role.toLowerCase() === 'super' && value !== undefined
					? value
					: '******'
			},
		},
		{
			field: 'name',
			headerName: 'Name',
			width: rowWidth,
		},
		{
			field: 'identifier',
			headerName: 'Identifier, DO NOT CHANGE',
			width: rowWidth,
		},
		{
			field: 'token',
			headerName: 'Token, DO NOT CHANGE',
			width: rowWidth,
			renderCell({ value }) {
				if (value) {
					return <span>***************</span>
				} else return <span></span>
			},
		},
		{
			field: 'mmp',
			headerName: 'MMP',
			width: rowWidth,
		},
		{
			field: 'created_by',
			headerName: 'Created by',
			width: rowWidth,
		},
		{
			field: 'actions',

			headerName: 'Actions',

			// width: rowWidth,
			sortable: false,
			renderCell: (row: any) => (
				<SimpleActionsButton
					// width={rowWidth}
					// row={row}
					options={actionOptions(row)}
					label={'Actions'}
					// style={{ width: '40px', height: '22px', fontSize: '30px' }}
					// arrowColor={'rgba(0,0,0,0.75)'}
				/>
			),
		},
	]

	const handlePopUp = () => {
		if (!props.PopUpVisible && !AddVisible) {
			fetchData(login, getAccountsAction, accounts.accounts)
			//setRow(undefined)
		}
		if (!AddVisible) {
			props.setPopUpVisible(!props.PopUpVisible)
		}
		if (!props.PopUpVisible) {
			setAddVisible(!AddVisible)
		}
	}
	const showPopUps = () => {
		if (AddVisible) {
			return (
				<AccountsEdit
					advertisers={undefined}
					PopUpVisible={AddVisible}
					setPopUpVisible={handlePopUp}
					row={row}
					setLoading={props.setLoading}
					blockPasswords={blockPasswords}
					isAdding={isAdding}
					setIsAdding={setIsAdding}
					setRow={setRow}
					errorMessage={props.errorMessage}
					setErrorMessage={props.setErrorMessage}
					isErrorPromptOpened={props.isErrorPromptOpened}
					setIsErrorPromptOpened={props.setIsErrorPromptOpened}
					isSuccessPromptOpened={props.isSuccessPromptOpened}
					setIsSuccessPromptOpened={props.setIsSuccessPromptOpened}
				/>
			)
		}
		return null
	}
	const popUp = showPopUps()
	const handleEdit = (row: any) => {
		setRow(row.row)
		setIsAdding(false)
		setAddVisible(!AddVisible)
	}
	const createDeletingPayload = () => {
		if (row) {
			return {
				uuid: row.uuid,
				user_name: row.user_name,
				password: row.password,
				user_login: login.user.email,
				created_by: row.created_by,
			}
		}
		return {}
	}
	const handleDelete = async (row: any) => {
		// createDeletingPayload()
		await deleteAccountsAction(headers, { dto: createDeletingPayload() })
		setIsWarningPromptOpened(false)
		//setAddVisible(!AddVisible)
	}
	//MISSING USEEFFECT LOGIC FOR NULIFY FIELDS OF ADDCAMPAIGN
	useEffect(() => {
		if (search.length === 0 && presearch !== null) {
			setSearch(presearch.toString())
			props.setPresetSearch(null)
		}
		if (search.length > 0) {
			setFound(searchFor(search, accounts.accounts, columns))
		}
	}, [search, props, accounts.accounts, presearch])

	useEffect(() => {
		if (search.length === 0) {
			if (presearch !== null) {
				presearchAction(null)
			}
		}
	}, [search, presearchAction, presearch])

	useEffect(() => {
		if (fileDownload) {
			saveAs(fileDownload, '*.csv')
		}
	}, [fileDownload])
	useEffect(() => {
		const fetchUpdate = async () => {
			setRow(undefined)
			await fetchData(login, getAccountsAction, accounts.accounts)
			setUpdate(!update)
		}
		if (!props.PopUpVisible) {
			fetchUpdate()
		}
	}, [props.PopUpVisible])

	// const handlePasswordVisibility = () => {
	// showPasswordAction(headers, login.user.email, setCodeHash)
	// 	props.setPopUpVisible(!props.PopUpVisible)
	// }
	const handleNewPassword = () => {
		setIsAdding(true)
		setAddVisible(true)
	}

	return popUp === null ? (
		<ThemeProvider theme={theme}>
			<PageWrapper>
				<Grid
					container
					spacing={0}
					direction={'row'}
					alignItems={'center'}
					width={'100%'}
				></Grid>
				<TableWrapper
					theme={theme}
					rowCount={2}
					setRowWidth={() => 10}
					style={{ paddingBottom: '60px', width: '90%' }}
				>
					<TableComponent
						columns={columns}
						rows={generateRowsWithIds(
							search.length > 0 ? found : accounts.accounts,
						)}
						setPageSize={setPageSize}
						pageSize={pageSize}
						rowsPerPageOptions={[15, 25, 50]}
						setLoading={props.setLoading}
						experimentalFeatures={{ newEditingApi: true }}
						setSearch={setSearch}
						search={search}
						handlePopUp={handleNewPassword}
						// handlePasswordVisibility={handlePasswordVisibility}
						sortModel={sortModel}
						onSortModelChange={(model: any) => setSortModel(model)}
						// downloadButtonOptions={downloadButtonoptions}

						// onRowEditStop={(params: any, event: any) =>
						// 	updatePasswordsAction(headers, { dto: createPayload(params.row) })
						// }
					></TableComponent>
				</TableWrapper>
				<WarningPrompt
					message={'Deleting the row now?'}
					PopUpVisible={isWarningPromptOpened}
					setPopUpVisible={setIsWarningPromptOpened}
					onAgree={() => handleDelete(row)}
					onCancel={() => props.setLoading(false)}
					obj={row}
				/>
			</PageWrapper>
		</ThemeProvider>
	) : (
		popUp
	)
}

export default AppsflyerAccountsTable
