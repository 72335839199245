import { Checkbox, Grid, ToggleButton, Typography } from '@mui/material'
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup'
import { subDays } from 'date-fns'
import saveAs from 'file-saver'
import { useEffect, useMemo, useState } from 'react'
import { useActions } from '../../hooks/useActions'
import { useTypedSelector } from '../../hooks/useTypedSelector'
import { getApplicableRules } from '../../utils/helpers/helperFuncs'
import {
	capitalizeFirstLetter,
	formatDate,
} from '../../utils/helpers/tableHelper'
import theme from '../../utils/theme2024'
import {
	ReportDashItem,
	ReportItemHeading,
	StyledCheckboxFormControl,
	StyledDashBox,
} from '../dashboard/ReportsStyled'
import {
	DashboardReportFieldsInterface,
	FiltersComponentList,
} from './DashboardFiltersList'
import DashboardTable from './DashboardTable'

export const downloadEverything = async () => {
	try {
		const downloadObject = async (url: string) => {
			try {
				const fetchResponse = await fetch(url)
				return await fetchResponse.json()
			} catch (err) {
				console.error('Error - ', err)
			}
		}
		console.log('AOSINFPASIFAPOSIFHO{AISF{OIASHF{OIASF')
		const ad = await downloadObject(
			'https://varys-dashboard-reports.s3.eu-central-1.amazonaws.com/report_advertiser.txt',
		)
		const ad_i = await downloadObject(
			'https://varys-dashboard-reports.s3.eu-central-1.amazonaws.com/report_invalid_advertiser.txt',
		)
		const pu = await downloadObject(
			'https://varys-dashboard-reports.s3.eu-central-1.amazonaws.com/report_publisher.txt',
		)
		const pu_i = await downloadObject(
			'https://varys-dashboard-reports.s3.eu-central-1.amazonaws.com/report_invalid_publisher.txt',
		)
		const co = await downloadObject(
			'https://varys-dashboard-reports.s3.eu-central-1.amazonaws.com/report_countries.txt',
		)
		const co_i = await downloadObject(
			'https://varys-dashboard-reports.s3.eu-central-1.amazonaws.com/report_invalid_countries.txt',
		)
		const da = await downloadObject(
			'https://varys-dashboard-reports.s3.eu-central-1.amazonaws.com/report_daily.txt',
		)
		const da_i = await downloadObject(
			'https://varys-dashboard-reports.s3.eu-central-1.amazonaws.com/report_invalid_daily.txt',
		)
		const ca = await downloadObject(
			'https://varys-dashboard-reports.s3.eu-central-1.amazonaws.com/report_campaign.txt',
		)
		const am = await downloadObject(
			'https://varys-dashboard-reports.s3.eu-central-1.amazonaws.com/report_account_manager.txt',
		)
		const pm = await downloadObject(
			'https://varys-dashboard-reports.s3.eu-central-1.amazonaws.com/report_publisher_manager.txt',
		)
		return {
			advertiser: { regular: ad, invalid: ad_i },
			publisher: { regular: pu, invalid: pu_i },
			country: { regular: co, invalid: co_i },
			daily: { regular: da, invalid: da_i },
			campaign: { regular: ca },
			account_man: { regular: am },
			publish_man: { regular: pm },
		}
	} catch (err) {
		console.error('Error - ', err)
	}
}
export const Dashboard = (props: {
	setLoading: any
	setErrorMessage: any
	setIsWarningPromptOpened: any
}) => {
	const [report, setReport] = useState<Object | null>(null)
	const [tableType, handleTableType] = useState<string | null>('daily')
	const [viewDataFor, setViewDataFor] = useState<string>('Last 7 Days')
	const [startDay, setStartDay] = useState<any>(
		new Date(formatDate(subDays(new Date(), 7))),
	)
	const [endDay, setEndDay] = useState<any>(
		new Date(formatDate(subDays(new Date(), 1))),
	)
	const [breakdownFields, setBreakdownFields] = useState<string[]>([])
	const [statisticsFields, setStatisticsFields] = useState<string[]>([])
	const [appIds, setAppIds] = useState<string[]>([])
	const [advertisers, setAdvertisers] = useState<string[]>([])
	const [user, setUser] = useState<string[]>([])
	const [pid, setPid] = useState<string[]>([])
	const [publishers, setPublishers] = useState<string[]>([])
	const [campaignNames, setCampaignNames] = useState<string[]>([])
	const [filtersList, setFiltersList] = useState<string[]>([])
	const [campaignIds, setCampaignIds] = useState<string[]>([])
	const [countries, setCountries] = useState<string[]>([])
	const { login, dashboard, users, settings } = useTypedSelector(
		(state) => state,
	)
	const { rules } = getApplicableRules(users, login, settings, 'dashboard')
	const { getFiltersAction } = useActions()
	const [lastUpdatedAt, setLastUpdatedAt] = useState(undefined)
	const [isReportOpen, setIsReportOpen] = useState<boolean>(true)
	const [invalidReport, setInvalidReport] = useState<Object | null>(null)
	const [fileDownload, setFileDownload] = useState<any>()
	const [presetVisible, setPresetVisible] = useState(false)
	const [isSaving, setIsSaving] = useState(false)
	const [accountManager, setAccountManager] = useState<any>([])
	const [team, setTeam] = useState<string[]>([])
	const [publisherManager, setPublisherManager] = useState<string[]>([])
	const [advertiserManager, setAdvertiserManager] = useState<string[]>([])
	const [salesRep, setSalesRep] = useState<string[]>([])
	const [rowCount, setRowCount] = useState(12)
	const [spreadByDate, setSpreadByDate] = useState(false)
	const [date, setDate] = useState<any>({
		from_date: subDays(new Date(), 7),
		to_date: subDays(new Date(), 1),
	})
	const [datesArray, setDatesArray] = useState<any[]>([])
	const [graphs, setGraphs] = useState([
		{
			metric: 'Media source',
			value: 'Installs',
			type: 'pie',
			depth: 5,
			title: 'Title',
		},
		{
			metric: 'App ID',
			value: 'Installs',
			type: 'line',
			depth: 5,
			title: 'Title',
		},
	])
	const [reportFields, setReportFields] =
		useState<DashboardReportFieldsInterface>({
			from_date: startDay,
			to_date: endDay,
			breakdowns: [],
			statistics: [],
			filters: {
				app_id: [],
				country: [],
				advertiser_name: [],
				publisher: [],
				account_manager: [],
				publisher_manager: [],
				advertiser_manager: [],
				sales_rep: [],
				campaign_name: [],
				team: [],
			},
		})

	const headers = useMemo(() => {
		return {
			Authorization: `Token ${login.user.token}`,
		}
	}, [login.user.token])
	const {
		saveAppsflyerAction,
		getRefreshAction,
		getUpdatedAtAction,
		getInvalidAction,
	} = useActions()
	const getLastRefreshed = () => {
		getUpdatedAtAction(headers, 'aggregated', setLastUpdatedAt)
	}

	const goToBottom = () => {
		window.scrollTo({
			top: 700,
			behavior: 'smooth',
		})
	}

	const tryDownload = async () => {
		await saveAppsflyerAction(
			setFileDownload,
			headers,
			report,
			login.user.email,
		)
	}

	const tryGetRealtime = async () => {
		props.setLoading(true)
		props.setIsWarningPromptOpened(true)
		await getRefreshAction(headers, props.setErrorMessage)
		props.setLoading(false)
	}
	const fillReport = () => {
		if (reportFields) {
			setBreakdownFields(
				reportFields.breakdowns.map((a: string) =>
					a
						.split('_')
						.map((word) => capitalizeFirstLetter(word))
						.join(' '),
				),
			)
			setStatisticsFields(
				reportFields.statistics.map((a: string) =>
					a
						.split('_')
						.map((word) => capitalizeFirstLetter(word))
						.join(' '),
				),
			)

			// setStartDay(reportFields.from_date)
			// setEndDay(reportFields.to_date)
			setAppIds(reportFields.filters.app_id)
			setAdvertisers(reportFields.filters.advertiser_name)
			setPublishers(reportFields.filters.publisher)
			setCampaignNames(reportFields.filters.campaign_name)
			setCountries(reportFields.filters.country)
			setAccountManager(reportFields.filters.account_manager)
			setTeam(reportFields.filters.team)
			setPublisherManager(reportFields.filters.publisher_manager)
			setAdvertiserManager(reportFields.filters.advertiser_manager)
			setSalesRep(reportFields.filters.sales_rep)
		}
	}
	const trySubmit = async () => {
		props.setLoading(true)
		const singleUserReport = {
			from_date: startDay,
			to_date: endDay,
			breakdowns: breakdownFields,
			statistics: statisticsFields,
			filters: {
				app_id: appIds,
				country: countries,
				advertiser_name: advertisers,
				publisher: publishers,
				email: accountManager || publisherManager || salesRep,
				campaign_name: campaignNames,
				campaign_id: [],
				media_source_pid: [],
			},
		}
		const singleUserInvalidReport = {
			from_date: startDay,
			to_date: endDay,
			breakdowns: breakdownFields,
			statistics: ['impressions', 'clicks', 'installs', 'total_revenue'],
			filters: {
				app_id: appIds,
				country: countries,
				advertiser_name: advertisers,
				publisher: publishers,
				email: accountManager || publisherManager || salesRep,
				campaign_name: campaignNames,
				campaign_id: [],
				media_source_pid: [],
				agencyAccounts: [],
			},
		}

		setIsReportOpen(false)

		await getInvalidAction(
			headers,
			singleUserInvalidReport,
			setInvalidReport,
			props.setErrorMessage,
			props.setLoading,
			setIsReportOpen,
		)
		setIsReportOpen(report !== null)
		props.setLoading(false)
	}

	useEffect(() => {
		props.setLoading(false)
	}, [props])
	useEffect(() => {
		if (fileDownload) {
			saveAs(fileDownload, '*.csv')
		}
	}, [fileDownload])
	useEffect(() => {
		if (report !== null) {
			setIsReportOpen(true)
			goToBottom()
		}
		//fillReport()
	}, [report])

	const handleTypeChoice = (
		event: React.MouseEvent<HTMLElement>,
		newType: string | null,
	) => {
		handleTableType(newType)
	}
	useEffect(() => {
		const tablesWith12Fields = [
			'daily',
			'advertiser',
			'campaign',
			'publisher',
			'country',
		]
		const tablesWith9Fields = [
			'accountManager',
			'publisherManager',
			'salesManager',
		]
		if (tableType && tablesWith12Fields.includes(tableType)) {
			setRowCount(12)
		}
		if (tableType && tablesWith9Fields.includes(tableType)) {
			setRowCount(9)
		}
	}, [tableType])

	useEffect(() => {
		if (dashboard.dashboard) {
			setReport(dashboard.dashboard.daily.regular.report)
			setInvalidReport(dashboard.dashboard.daily.invalid)
		}
	}, [])

	return (
		<>
			<ToggleButtonGroup
				value={tableType}
				exclusive
				fullWidth
				onChange={handleTypeChoice}
				sx={{
					'& .Mui-selected': {
						flow: 'right',
						position: 'relative',
						margin: '0px',
						width: '100%',
						background: theme.colors.base.white,
						borderRadius: '6px',
						// boxShadow: theme.colors.base.grey300,
						color: 'white',
					},
					'& .MuiToggleButton-root.Mui-selected': {
						color: 'white',
						borderTopRightRadius: '6px',
						borderBottomRightRadius: '6px',
						borderTopLeftRadius: '6px',
						borderBottomLeftRadius: '6px',
					},

					'& .MuiToggleButtonGroup-grouped': {
						margin: '1vw',
						border: 0,
						borderRadius: '6px',
					},
				}}
				aria-label='table type'
				style={{
					marginTop: '0vh',
					width: '95vw',
					height: '7vh',
					marginLeft: '-1vw',
				}}
			>
				<ToggleButton
					value='daily'
					aria-label='daily'
					onClick={() => {
						setReport(dashboard.dashboard.daily.regular.report)
						setInvalidReport(dashboard.dashboard.daily.invalid)
						setBreakdownFields(['date'])
						setStatisticsFields([
							'impressions',
							'clicks',
							'installs',
							'revenue',
							'cost',
							'profit',
							'cr',
						])
						// console.log(datesArray)
					}}
				>
					Daily
				</ToggleButton>
				<ToggleButton
					value='advertiser'
					aria-label='advertiser'
					onClick={() => {
						setReport(dashboard.dashboard.advertiser.regular.report)
						setInvalidReport(dashboard.dashboard.advertiser.invalid)
						setBreakdownFields(['advertiser_name'])
						setStatisticsFields([
							'impressions',
							'clicks',
							'installs',
							'revenue',
							'cost',
							'profit',
							'cr',
						])
						// console.log(datesArray)
					}}
				>
					Advertisers
				</ToggleButton>
				<ToggleButton
					value='campaign'
					aria-label='campaigns'
					onClick={() => {
						setReport(dashboard.dashboard.campaign.regular.report)
						setBreakdownFields(['campaign_name'])
						setStatisticsFields([
							'impressions',
							'clicks',
							'installs',
							'level_2_event',
							'level_3_event',
							'level_4_event',
							'level_5_event',
							'revenue',
							'cost',
							'profit',
							'cr',
						])
					}}
				>
					Campaigns
				</ToggleButton>
				<ToggleButton
					value='publisher'
					aria-label='publishers'
					onClick={() => {
						setReport(dashboard.dashboard.publisher.regular.report)
						setInvalidReport(dashboard.dashboard.publisher.invalid)
						setBreakdownFields(['publisher_name'])
						setStatisticsFields([
							'Impressions',
							'Clicks',
							'Installs',
							'level_2_event',
							'level_3_event',
							'level_4_event',
							'level_5_event',
							'Revenue',
							'Cost',
							'Profit',
							'CR',
						])
					}}
				>
					Publishers
				</ToggleButton>
				<ToggleButton
					value='country'
					aria-label='country'
					onClick={() => {
						setReport(dashboard.dashboard.country.regular.report)
						setInvalidReport(dashboard.dashboard.country.invalid)
						setBreakdownFields(['country'])
						setStatisticsFields([
							'impressions',
							'clicks',
							'installs',
							'revenue',
							'cost',
							'profit',
							'cr',
						])
					}}
				>
					Country
				</ToggleButton>
				<ToggleButton
					value='accountManager'
					aria-label='advertiser managers'
					onClick={() => {
						setReport(dashboard.dashboard.account_man.regular.report)
					}}
				>
					Advertiser Managers
				</ToggleButton>
				<ToggleButton
					value='publisherManager'
					aria-label='publisher managers'
					onClick={() => {
						setReport(dashboard.dashboard.publish_man.regular.report)
					}}
				>
					Publisher Managers
				</ToggleButton>
			</ToggleButtonGroup>
			<DashFiltersComponent
				setAppIds={setAppIds}
				setAdvertisers={setAdvertisers}
				setUser={setUser}
				setPid={setPid}
				setPublishers={setPublishers}
				setCampaignNames={setCampaignNames}
				setCampaignIds={setCampaignIds}
				setCountries={setCountries}
				dataObj={reportFields}
				setDataObj={setReportFields}
				tryDownload={tryDownload}
				isReportOpen={!isReportOpen}
				user={user}
				presetVisible={presetVisible}
				setPresetVisible={setPresetVisible}
				setIsSaving={setIsSaving}
				tryGetRealtime={tryGetRealtime}
				setIsWarningPromptOpened={props.setIsWarningPromptOpened}
				lastUpdatedAt={lastUpdatedAt}
				row={true}
				setAccountManager={setAccountManager}
				setTeam={setTeam}
				setPublisherManager={setPublisherManager}
				setAdvertiserManager={setAdvertiserManager}
				setSalesRep={setSalesRep}
				date={{ startDay, endDay }}
				setDate={{ setStartDay, setEndDay }}
				viewDataFor={viewDataFor}
				setViewDataFor={setViewDataFor}
				spreadByDate={spreadByDate}
				setSpreadByDate={setSpreadByDate}
				tableType={tableType}
				setFiltersList={setFiltersList}
				setDatesArray={setDatesArray}
			/>
			{report && (
				<DashboardTable
					report={report}
					reportType={tableType || 'advertiser'}
					setLoading={props.setLoading}
					dates={{ start: startDay, end: endDay }}
					rowCount={rowCount}
					invalidReport={invalidReport}
					setReport={setReport}
					spreadByDate={spreadByDate}
					setSpreadByDate={setSpreadByDate}
					filters={filtersList}
					dataObj={reportFields}
					datesArray={datesArray}
					rules={rules}
					graphs={graphs}
					breakdownFields={breakdownFields}
					statisticsFields={statisticsFields}
				></DashboardTable>
			)}

			{/* {report ? (
					<ReportsTable
						report={report}
						setIsReportOpen={setIsReportOpen}
						reportFields={reportFields}
						isReportOpen={isReportOpen}
						customReportFields={undefined}
					/>
				) : (
					<></>
				)} */}
		</>
	)
}

export const DashFiltersComponent = (props: {
	setAppIds: any
	setAdvertisers: any
	setUser: any
	setPid: any
	setPublishers: any
	setCampaignNames: any
	setCampaignIds: any
	setCountries: any
	presetVisible: boolean
	dataObj: DashboardReportFieldsInterface
	user: any
	tryDownload: any
	isReportOpen: any
	setDataObj: any
	setPresetVisible: any
	setIsSaving: any
	tryGetRealtime: any
	setIsWarningPromptOpened: any
	lastUpdatedAt: string | undefined
	setAccountManager: any
	setTeam: any
	setPublisherManager: any
	setAdvertiserManager: any
	setSalesRep: any
	setDate: any
	spreadByDate: any
	setSpreadByDate: any
	row?: any
	date?: any
	viewDataFor?: any
	setViewDataFor?: any
	tableType: any
	setFiltersList: any
	setDatesArray: any
}) => {
	const { login } = useTypedSelector((state) => state)
	const headers = useMemo(() => {
		return {
			Authorization: `Token ${login.user.token}`,
		}
	}, [login.user.token])
	// const dateArr = props.lastUpdatedAt?.split('T')
	// let timeString = ''
	// if (dateArr !== undefined && dateArr[1] !== undefined) {
	// 	const timeStringArr = dateArr[1].split(':')
	// 	timeString = timeStringArr[0] + ':' + timeStringArr[1]
	// }
	return (
		<ReportDashItem>
			<ReportItemHeading
				style={{ transform: 'TranslateY(4vh) TranslateX(1vw)' }}
			>
				Filters
			</ReportItemHeading>
			<StyledDashBox
				style={{
					transform: 'TranslateY(4vh) TranslateX(1vw)',
					paddingInline: '3vw',
				}}
			>
				<FiltersComponentList {...props} setDataObj={props.setDataObj} />
			</StyledDashBox>
			<Grid
				container
				direction={'row'}
				spacing={2}
				style={{
					position: 'absolute',
					right: '1%',
					flexDirection: 'row-reverse',
					width: '100vw',
					overflow: 'hidden',
					marginTop: '20px',
					paddingBottom: '60px',
				}}
			>
				<Grid item xs={2} order={3}>
					<StyledCheckboxFormControl
						key={'timeframeControl'}
						control={
							<Checkbox
								onChange={() => props.setSpreadByDate(!props.spreadByDate)}
								checked={props.spreadByDate}
								key={'timeframe'}
								inputProps={{
									'aria-label': 'controlled',
								}}
								style={{ marginLeft: '25px', color: '#ee1d4a' }}
								disabled={props.isReportOpen}
							/>
						}
						label={
							<Typography fontSize='13px' fontWeight={500} color={'secondary'}>
								Spread by Date
							</Typography>
						}
					/>
				</Grid>
			</Grid>
		</ReportDashItem>
	)
}
