import { ThemeProvider } from '@emotion/react'
import '@inovua/reactdatagrid-community/index.css'
import { Apps, PropaneSharp } from '@mui/icons-material'
import {
	Box,
	DialogActions,
	FormControl,
	Grid,
	InputLabel,
	MenuItem,
	Select,
	Typography,
} from '@mui/material'
import { DataGridProProps, GridColDef } from '@mui/x-data-grid-pro'
import { format } from 'date-fns'
import { useCallback, useEffect, useMemo, useState } from 'react'
import { useNavigate, useParams } from 'react-router'
import { useActions } from '../../hooks/useActions'
import { useTypedSelector } from '../../hooks/useTypedSelector'
import { AppInterface } from '../../models/model.interface'
import {
	deleteAutoSwitchRuleAction,
	getAppsIcons,
} from '../../state/action-creators'
import {
	getApplicableRules,
	makeDictionaryByKey,
} from '../../utils/helpers/helperFuncs'
import { fetchData } from '../../utils/helpers/navigationHelper'
import { generateRowsWithIds, searchFor } from '../../utils/helpers/tableHelper'
import { DeletionWarningPrompt } from '../../utils/notifications/WarningPrompt'
import theme from '../../utils/theme2024'
import { PageWrapper, TableWrapper } from '../components/reusableComponents'
import { AutoSwitchPopUp } from './AutoSwitchPopup'
import AxiosCustom from '../../utils/Axios'
import { RulesCards } from '../campaigns/CampaignStyled'
import { CustomGridTreeDataGroupingCell } from '../finance/GlobalTable'
import { DropList } from '../components/SelectableInputs'
import {
	SimpleActionsButton,
	SubmitButton,
	UnborderedButton,
} from '../components/Buttons'
import { TableComponent } from '../components/TableComponents'

const AutoSwitchPage = (props: {
	children?: any
	setLoading?: any
	errorMessage: null | string
	setErrorMessage: any
	isErrorPromptOpened: boolean
	setIsErrorPromptOpened: any
	isSuccessPromptOpened: any
	setIsSuccessPromptOpened: any
	viewRecord: any
	setViewRecord: any
	setUniversalEdit: any
	PopUpVisible: boolean
	setPopUpVisible: any
	setOnWarningAgree: any
}) => {
	const navigate = useNavigate()
	const params = useParams()

	const { advertiser, publisher, autoswitch, login, users, settings } =
		useTypedSelector((state) => state)
	const ruleSet = getApplicableRules(users, login, settings, 'autoswitch')
	const [rows, setRows] = useState<Record<string, any[]>>(
		makeDictionaryByKey(autoswitch.rules, 'app_id'),
	)
	const [newPopUpVisible, setNewPopUpVisible] = useState(false)
	const rowCount = 6
	const [warning, setWarning] = useState<any>()
	const [warningObj, setWarningObj] = useState<any>()
	const [popUpNewRule, setPopUpNewRule] = useState(false)
	const [search, setSearch] = useState('')
	const [found, setFound] = useState<any[]>([])
	const [activeCount, setActiveCount] = useState(0)
	const [rowWidth, setRowWidth] = useState(
		Math.floor((window.innerWidth - theme.dimentions.drawerWidth) / rowCount),
	)
	const [isWarningPromptOpened, setIsWarningPromptOpened] = useState(false)
	const [sortModel, setSortModel] = useState([{ field: 'app_id', sort: 'asc' }])
	const [row, setRow] = useState<any>()
	const [update, setUpdate] = useState(false)
	// const [PopUpVisible, setPopUpVisible] = useState(false)
	const { app } = useTypedSelector((state) => state)
	const { updateAppsAction, getAppsAction, getAutoSwitchAction } = useActions()
	const [pageSize, setPageSize] = useState(15)
	const [appId, setAppId] = useState('')
	const [advertiserSelected, setAdvertiserSelected] = useState('')
	const [appListArray, setAppListArray] = useState([''])
	const [list, setList] = useState<any[]>([])
	const [warningPopUp, setWarningPopUp] = useState(<></>)
	const [makeingNew, setMakeingNew] = useState(false)
	const headers = useMemo(() => {
		return {
			Authorization: `Token ${login.user.token}`,
		}
	}, [login.user.token])
	let advListArray: any[] = []
	useEffect(() => {
		let tem: string[] = []
		let appsflyerapps = filterAppsflyerApps()
		if (appsflyerapps && appsflyerapps.length > 0) {
			for (let rule of appsflyerapps) {
				if (!tem.includes(rule.app_id)) {
					if (rule.advertiser_name == advertiserSelected) {
						tem.push(rule.app_id)
					}
				}
			}
		}
		console.log('appsflyerapps : ', appsflyerapps)
		setAppListArray(tem)
	}, [advertiserSelected])
	const lastUpdateFormat = (value: string) => {
		const time = value.split(':')
		const comp = time[0] + ':' + time[1]
		const new_value = time[2].split('to')
		return [comp, new_value[1]]
	}
	for (let adv of advertiser.advertiser) {
		advListArray.push(adv.name)
	}

	const columns: GridColDef[] = [
		{
			field: 'advertiser_name',
			headerName: 'Advertiser Name',

			width: rowWidth * 0.8,
			renderCell: ({ value }) => (
				<Typography sx={{ fontWeight: 600, fontSize: 14 }}>{value}</Typography>
			),
		},
		{
			field: 'app_id',
			headerName: 'App Id',
			width: rowWidth / 2,
			renderCell: ({ value }) => (
				<Typography sx={{ fontWeight: 600, fontSize: 14 }}>{value}</Typography>
			),
		},
		{
			field: 'created_by',
			headerName: 'Rule Creator',
			align: 'center',
			headerAlign: 'center',
			width: rowWidth,
			renderCell: ({ value }) => (
				<Typography sx={{ fontWeight: 600, fontSize: 14 }}>{value}</Typography>
			),
		},
		{
			field: 'rules',
			headerName: 'Rules',
			headerAlign: 'center',
			align: 'center',
			width: rowWidth / 3,
			renderCell: (row) => (
				<Typography sx={{ fontWeight: 600, fontSize: 14 }}>
					{row.row.hierarchy.length > 1
						? '1'
						: rows[row.row.app_id] && rows[row.row.app_id].length > 0
						? rows[row.row.app_id].length
						: null}
				</Typography>
			),
		},
		{
			field: 'last_update',
			headerName: 'Last Sync',
			headerAlign: 'center',
			align: 'center',
			width: rowWidth,
			renderCell: ({ value }) =>
				value.toString().includes('True') ||
				value.toString().includes('False') ? (
					<div>
						<Typography sx={{ fontWeight: 300, fontSize: 14 }}>
							{lastUpdateFormat(value.toString())[0]}
						</Typography>
						<Typography sx={{ fontWeight: 900, fontSize: 14 }}>
							{lastUpdateFormat(value.toString())[1]}
						</Typography>
					</div>
				) : (
					value
				),
		},
		{
			field: 'actions',
			headerName: 'Actions',
			resizable: false,
			headerAlign: 'center',
			align: 'center',
			// width: rowWidth,
			sortable: false,
			renderCell: (row) => {
				return (
					<SimpleActionsButton
						// width={rowWidth}
						// row={row.row}
						label={'Actions'}
						options={actionOptions(row.row)}
						// style={{ width: '40px', height: '22px', fontSize: '30px' }}
						// arrowColor={'rgba(0,0,0,0.75)'}
					/>
				)
			},
		},
	]
	const actionOptions = (row: any) => {
		return (
			<div>
				<MenuItem onClick={() => handleEdit(row)}>Edit</MenuItem>
			</div>
		)
	}
	const handleEdit = (app: any) => {
		console.log('EDIT : ', app)
		setRow(app)
		navigate('/autoswitch/' + app.app_id)
		props.setPopUpVisible(true)
	}

	const updateAppsHandler = async (row: AppInterface) => {
		await updateAppsAction(
			headers,
			{
				app_id: row.app_id,
				status: !row.status,
				email: login.user.email,
				created_by: row.created_by,
			},
			row,
			props.setErrorMessage,
		)
		await fetchData(login, getAppsAction, app.app)
		setUpdate(!update)
		props.setLoading(false)
	}

	const handlePopUp = async (row: any) => {
		props.setPopUpVisible(!props.PopUpVisible)
		setMakeingNew(true)
		setRows(makeDictionaryByKey([row], 'app_id'))
		navigate('/autoswitch/' + appId)
		if (props.PopUpVisible) {
			setUpdate(!update)
			setRow(undefined)
		}
	}

	const filterAppsflyerApps = () => {
		let result = []
		for (let sa of app.appIcons) {
			const temp_result = app.app.filter(
				(data: any) => data.app_id === sa.app_id,
			)
			if (temp_result && temp_result.length > 0) {
				result.push(temp_result[0])
			}
		}
		return result
	}

	useEffect(() => {
		console.log('setting up data')
		fetchFinishLoad()
	}, [props.PopUpVisible, newPopUpVisible])

	useEffect(() => {
		if (search.length > 0) {
			setFound(searchFor(search, list, columns))
		}
	}, [search, app.app])

	useEffect(() => {
		if (params.id != undefined) {
			const id: string = params.id
			const data = makeDictionaryByKey(autoswitch.rules, 'app_id')
			if (Object.keys(data).includes(id)) {
				setRow(data[id][0])
				setRows(data)
				handleEdit(data[id][0])
			} else {
				if (!makeingNew) {
					props.setPopUpVisible(false)
					navigate('/autoswitch')
				}
				// data[id] = [
				// 	{
				// 		app_id: appId,
				// 		media_source_pid: [''],
				// 		last_update: undefined,
				// 		created_by: login.user.name,
				// 		calendar: undefined,
				// 		uuid: undefined,
				// 		advertiser_name: advertiserSelected,
				// 	},
				// ]
				// setRows(data)
				// handleEdit(data[id][0])
			}
		}
	}, [autoswitch.rules, newPopUpVisible])

	const addHierarchy = (list: any[], dict: any) => {
		let result: any[] = [...list]
		for (let elm of list) {
			elm['hierarchy'] = [elm['app_id']]
			for (let rur of dict[elm.app_id]) {
				let withhierarchy = { ...rur }
				withhierarchy['hierarchy'] = [
					withhierarchy['app_id'],
					withhierarchy['media_source_pid'].join(', ') +
						' (' +
						withhierarchy['agency_accounts'] +
						')',
				]
				result.push(withhierarchy)
			}
		}
		return result
	}

	useEffect(() => {
		const dict = makeDictionaryByKey(autoswitch.rules, 'app_id')
		setRows(dict)
		const loadedRules = aggregateByAppId(dict)
		setList(addHierarchy(loadedRules, dict))
	}, [autoswitch.rules])

	useEffect(() => {
		const countActiveApps = () => {
			let count = 0
			app.app.forEach((a: AppInterface) => {
				if (a.status) {
					count++
				}
			})
			setActiveCount(count)
		}
		countActiveApps()
		props.setLoading(false)
	}, [update, app.app])

	const aggregateByAppId = (dict: Object) => {
		let results: any[] = []
		for (let r of Object.values(dict)) {
			results.push(r[0])
		}
		return results
	}

	function omit(key: string, obj: any) {
		const { [key]: omitted, ...rest } = obj
		return rest
	}

	const fetchFinishLoad = async () => {
		try {
			console.log('FETCHING')
			await fetchData(login, getAutoSwitchAction, autoswitch.rules)
			setUpdate(!update)
			return props.setLoading(false)
		} catch (c) {
			alert(c)
		}
	}

	const AppIdSelect = (props: { onChange: any; value: any }) => {
		const usersList: JSX.Element[] = appListArray.map(
			(app: string, key: number) => (
				<MenuItem key={key} value={app}>
					{app}
				</MenuItem>
			),
		)
		return (
			<FormControl
				style={{ width: '50%' }}
				variant='standard'
				disabled={advertiserSelected == ''}
			>
				<InputLabel required id='demo-simple-select-label'>
					App Id to add
				</InputLabel>
				<Select
					labelId='demo-simple-select-label'
					id='demo-simple-select'
					label='App Id to add'
					onChange={(e: any) => props.onChange(e.target.value)}
					defaultValue={''}
					value={props.value}
				>
					{usersList}
				</Select>
			</FormControl>
		)
	}
	const AdvertiserSelect = (props: { onChange: any; value: any }) => {
		const { advertiser } = useTypedSelector((state) => state)
		const usersList: JSX.Element[] = advertiser.advertiser.map(
			(adv: AppInterface, key: number) => (
				<MenuItem key={key} value={adv.advertiser_name}>
					{adv.advertiser_name}
				</MenuItem>
			),
		)
		return (
			<FormControl style={{ width: '50%' }} variant='standard'>
				<InputLabel required id='demo-simple-select-label'>
					Advertiser name
				</InputLabel>
				<Select
					style={{ transform: 'translateY(10vh)' }}
					labelId='demo-simple-select-label'
					id='demo-simple-select'
					label='Advertiser name'
					onChange={(e: any) => props.onChange(e.target.value)}
					defaultValue={''}
					value={props.value}
				>
					{usersList}
				</Select>
			</FormControl>
		)
	}
	const rowHelper = (advertiserSelected: string, appId: string) => {
		const newRow = {
			app_id: appId,
			media_source_pid: [''],
			calendar: undefined,
			uuid: undefined,
			created_by: login.user.name,
			last_update: new Date(),
			advertiser_name: advertiserSelected,
		}
		if (rows[appId]) {
			rows[appId] = [...rows[appId], newRow]
		} else {
			rows[appId] = [newRow]
		}
		setRows((rows) => rows)
		setRow(newRow)
		return newRow
	}
	const tryRemoveRule = async (payload: any) => {
		try {
			const data = makeDictionaryByKey(autoswitch.rules, 'app_id')
			let last = false
			if (Object.keys(data).includes(payload.app_id)) {
				if (data[payload.app_id].length == 1) {
					last = true
				}
			}
			const headers = {
				Authorization: `Token ${login.user.token}`,
			}
			setWarningObj(undefined)
			setIsWarningPromptOpened(false)
			props.setLoading(true)
			let response: any = ''

			response = await deleteAutoSwitchRuleAction(payload, headers)

			if (response.data.successful) {
				if (newPopUpVisible) {
					setNewPopUpVisible(false)
				} else {
					props.setPopUpVisible(false)
					await fetchFinishLoad()
					navigate('/autoswitch')
					console.log(
						'here stops the lingering depression and pain of existanse',
					)
				}

				props.setIsSuccessPromptOpened(true)
			} else {
				if (response.statusText == 'Created') {
					setNewPopUpVisible(false)
					props.setIsSuccessPromptOpened(true)
				} else {
					setNewPopUpVisible(false)
					props.setErrorMessage(response.data.payload)
					props.setIsErrorPromptOpened(true)
				}
			}

			if (last === true) {
				setMakeingNew(false)
			}

			await fetchFinishLoad()
		} catch (err) {
			console.error()
		}
	}

	const NewRulePopUp = () => {
		const [disabled, setDisabled] = useState(false)
		useEffect(() => {
			if (appId != '') {
				setDisabled(true)
			}
		}, [appId])

		const cancel = async () => {
			try {
				setDisabled(false)
				setAdvertiserSelected('')
				setAppId('')
				setNewPopUpVisible(false)
				navigate('/autoswitch')
			} catch {
				console.error()
			}
		}
		return (
			<RulesCards>
				<Grid container>
					<Grid
						item
						xs={12}
						style={{ display: 'flex', flexDirection: 'row', gap: '18px' }}
					>
						<DropList
							label='Advertiser'
							options={advertiser.advertiser.map(
								(adv: any) => adv.advertiser_name,
							)}
							disabled={disabled}
							float='right'
							onChange={(e: any) => setAdvertiserSelected(e)}
							value={advertiserSelected}
						/>
						<DropList
							label='Apps'
							disabled={advertiserSelected === '' || disabled}
							options={appListArray}
							float='right'
							onChange={(e: any) => setAppId(e)}
							value={appId}
						/>
					</Grid>
					<Grid item xs={12}>
						{appId ? (
							<AutoSwitchPopUp
								appObject={{
									app_id: appId,
									media_source_pid: [''],
									last_update: undefined,
									created_by: login.user.name,
									calendar: undefined,
									uuid: undefined,
									advertiser_name: advertiserSelected,
									agency_accounts: [''],
								}}
								setLoading={props.setLoading}
								setPopUpVisible={props.setPopUpVisible}
								setIsWarningPromptOpened={setIsWarningPromptOpened}
								isWarningPromptOpened={isWarningPromptOpened}
								setIsSuccessPromptOpened={props.setIsSuccessPromptOpened}
								setIsErrorPromptOpened={props.setIsErrorPromptOpened}
								setErrorMessage={props.setErrorMessage}
								publisher={publisher}
								rows={rows}
								setOnWarningAgree={props.setOnWarningAgree}
								setRows={setRows}
								setRow={setRow}
								setWarning={setWarning}
								key={'EA3HFJFYj6yf263gsy' + appId + 'new'}
								reload={fetchFinishLoad}
								row={row}
								setWarningObj={setWarningObj}
								newRule={false}
								cancelFunc={cancel}
								rules={ruleSet}
							/>
						) : (
							<DialogActions
								style={{ width: '30%', marginTop: '5vh', marginLeft: '35%' }}
							>
								<UnborderedButton
									style={{ margin: 'auto' }}
									onClick={() => {
										cancel()
									}}
									label={'Cancel'}
								></UnborderedButton>
							</DialogActions>
						)}
					</Grid>
				</Grid>
			</RulesCards>
		)
	}

	useEffect(() => {
		setWarningPopUp(
			<DeletionWarningPrompt
				message={'Are you sure you want to delete this AutoSwitch rule?'}
				PopUpVisible={isWarningPromptOpened}
				setPopUpVisible={setIsWarningPromptOpened}
				onAgree={tryRemoveRule}
				setObj={setWarningObj}
				obj={warningObj}
			/>,
		)
	}, [warningObj])

	const rulesRender = (fromDict: any[]) =>
		fromDict &&
		fromDict.map((r: any, i: number) => (
			<AutoSwitchPopUp
				appObject={r}
				setIsWarningPromptOpened={setIsWarningPromptOpened}
				isWarningPromptOpened={isWarningPromptOpened}
				setLoading={props.setLoading}
				setPopUpVisible={props.setPopUpVisible}
				publisher={publisher}
				setIsSuccessPromptOpened={props.setIsSuccessPromptOpened}
				setIsErrorPromptOpened={props.setIsErrorPromptOpened}
				setErrorMessage={props.setErrorMessage}
				rows={rows}
				setRows={setRows}
				setOnWarningAgree={props.setOnWarningAgree}
				setRow={setRow}
				key={'EA3HFJFYj6yf263gsy' + r.app_id + i}
				reload={fetchFinishLoad}
				row={row}
				newRule={true}
				setWarning={setWarning}
				setWarningObj={setWarningObj}
				rules={ruleSet}
			/>
		))

	const getTreeDataPath: DataGridProProps['getTreeDataPath'] = (row) =>
		row.hierarchy
	const groupingColDef: DataGridProProps['groupingColDef'] = {
		headerName: 'Rule',
		width: rowWidth * 1.7,
	}

	return !props.PopUpVisible ? (
		!newPopUpVisible ? (
			<ThemeProvider theme={theme}>
				<PageWrapper>
					{ruleSet.post && (
						<SubmitButton
							onClick={() => setNewPopUpVisible(!newPopUpVisible)}
							disabled={false}
							style={{
								// boxShadow: 'none',
								width: '80px',
								height: '30px',
								marginTop: '9px',
								fontSize: '12px',
							}}
						>
							+ Add
						</SubmitButton>
					)}
					<TableWrapper
						theme={theme}
						rowCount={rowCount}
						setRowWidth={setRowWidth}
						style={{ paddingBottom: '60px', width: '90%' }}
					>
						<TableComponent
							columns={columns}
							rows={
								ruleSet.get
									? generateRowsWithIds(search.length > 0 ? found : list)
									: []
							}
							setPageSize={setPageSize}
							pageSize={pageSize}
							rowsPerPageOptions={[15, 25, 50]}
							setLoading={props.setLoading}
							setSearch={setSearch}
							search={search}
							handlePopUp={handleEdit}
							sortModel={sortModel}
							onSortModelChange={(model: any) => setSortModel(model)}
							selector={AppIdSelect}
							setAppId={setAppId}
							appId={appId}
							autoswitch={true}
							treeData={true}
							getTreeDataPath={getTreeDataPath}
							groupingColDef={groupingColDef}
						></TableComponent>
					</TableWrapper>
				</PageWrapper>
			</ThemeProvider>
		) : (
			<NewRulePopUp />
		)
	) : (
		<div>
			{warningObj && warningPopUp}
			{row && rulesRender(rows[row.app_id])}
		</div>
	)
}

export default AutoSwitchPage
