import { CheckBox, Public } from '@mui/icons-material'
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown'
import ArrowRightIcon from '@mui/icons-material/ArrowRight'
import CheckBoxIcon from '@mui/icons-material/CheckBox'
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank'
import ClearIcon from '@mui/icons-material/Clear'
import DownloadIcon from '@mui/icons-material/Download'
import ErrorIcon from '@mui/icons-material/Error'
import FilterAltOffIcon from '@mui/icons-material/FilterAltOff'
import FilterListIcon from '@mui/icons-material/FilterList'
import HelpIcon from '@mui/icons-material/Help'
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown'
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight'
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp'
import LinkIcon from '@mui/icons-material/Link'
import SearchIcon from '@mui/icons-material/Search'
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined'
import AddIcon from '@mui/icons-material/Add'
import {
	default as Visibility,
	default as VisibilityIcon,
} from '@mui/icons-material/Visibility'
import VisibilityOff from '@mui/icons-material/VisibilityOff'
import {
	Autocomplete,
	Avatar,
	Badge,
	Box,
	Button,
	Card,
	Checkbox,
	Chip,
	Dialog,
	FormControl,
	FormControlProps,
	IconButton,
	InputAdornment,
	InputBase,
	InputLabel,
	Menu,
	MenuItem,
	Popper,
	Select,
	styled,
	TextField,
	TextFieldProps,
	Theme,
	Tooltip,
	Typography,
	TypographyProps,
} from '@mui/material'
import { GridColDef, GridToolbarContainer } from '@mui/x-data-grid'
import { DataGridPro, useGridApiRef } from '@mui/x-data-grid-pro'
import { debounce } from 'lodash'
import React, {
	CSSProperties,
	Dispatch,
	SetStateAction,
	useEffect,
	useRef,
	useState,
} from 'react'
import 'react-checkbox-tree/lib/react-checkbox-tree.css'
import ReactCountryFlag from 'react-country-flag'
import downloadFolderIconWhite from '../../assets/download-white.png'
import downloadFolderIcon from '../../assets/download.png'
import sendWhiteIcon from '../../assets/send-white.png'
import sendIcon from '../../assets/send.png'
import search from '../../assets/search-varys.svg'
import appsIcon from '../../assets/varys-applist-icon-2.svg'
import filterRemoveIcon from '../../assets/varys-filter-remove.svg'
import mediaPlanIcon from '../../assets/varys-media-plan-icon.svg'
import uploadFolderIconWhite from '../../assets/upload-white.png'
import uploadFolderIcon from '../../assets/upload.png'
import { useTypedSelector } from '../../hooks/useTypedSelector'
import { UserInterface } from '../../models/model.interface'
import { convertPathName, stringAvatar } from '../../utils/helpers/helperFuncs'
import theme from '../../utils/theme2024'
import { PublishersList } from '../campaigns/CampaignComponents'
import { StyledCheckboxFormControl } from '../reports/FiltersStyled'
import moment from 'moment'
import { InfoField } from '../advertisers/AdvertiserPreviewPage'
import { StatusButton, SubmitButton, UnborderedButton } from './Buttons'
import { StyledMenuItem } from './Informative'
import { StyledInputLabel } from '../campaigns/CampaignStyled'

export const TableWrapper = (props: {
	style?: any
	children: any
	theme: Theme
	setRowWidth: any
	rowCount: number
	height?: string
}) => {
	const [tableWidth, setTableWidth] = useState(
		window.innerWidth - 20 - props.theme.dimentions.drawerWidth,
	)
	useEffect(() => {
		const handleResize = debounce((e: UIEvent) => {
			const target = e.target as Window
			const width = target.innerWidth - props.theme.dimentions.drawerWidth
			setTableWidth(width)
			props.setRowWidth(width / props.rowCount)
		}, 100)

		window.addEventListener('resize', handleResize, true)
		return () => {
			window.removeEventListener('resize', handleResize, true)
		}
	}, [props])

	return (
		<div style={props.style}>
			<div
				style={{
					position: 'absolute',
					fontSize: '150px',
					//align: 'center',
					marginLeft: '-60px',
					height: props.height ? props.height : '90vh',
					width: tableWidth * 1.01,
				}}
			>
				{props.children}
			</div>
		</div>
	)
}

// export const CustomToolbar: React.FunctionComponent<{
// 	setFilterButtonEl: React.Dispatch<
// 		React.SetStateAction<HTMLButtonElement | null>
// 	>
// 	filterStatus?: any
// 	setFilterStatus?: any
// 	setSearch: any
// 	search: any
// 	handlePopUp: any
// 	handlePopUpDisabled: boolean
// 	downloadButtonOptions: any
// 	handlePasswordVisibility: any
// 	mediaPlanHandler?: any
// 	filterHandler?: any
// 	customFilter?: any
// 	clearAllFilters?: any
// 	showClearAllFiltersButton?: boolean
// 	setShowClearAllFiltersButton?: any
// 	mainFilterOptions: any
// 	isAutoSwitch?: any
// 	appIdList?: any[]
// 	appId?: any
// 	setAppId?: any
// 	selector?: any
// 	selectorProp?: any
// 	monthFilterArray?: any
// 	setMonthFilterArray?: any
// 	yearFilterArray?: any
// 	setYearFilterArray?: any
// 	downloadTemplate?: any
// 	fileInputClickHandler?: any
// 	sendEmail?: any
// 	financeLastSend?: any
// 	financeFinalSent?: boolean
// 	hideBlockedUsersButton?: boolean
// 	applyFilter: any
// 	clearFilter: any
// 	autoswitch?: any
// 	searchWithTimer?: boolean
// 	checkboxButtonValue?: boolean
// 	setCheckboxButtonValue?: Dispatch<SetStateAction<boolean>>
// 	checkboxButtonLabel?: string
// 	downloadButtonFolderOptions?: any
// 	infoOpened?: boolean
// 	setInfoOpened?: Dispatch<SetStateAction<boolean>>
// 	triggerExtraBolFunction?: Function
// 	triggerExtraBolFunctionValue?: boolean
// 	extraBolFunctionLabel?: any
// 	presetSearch?: string
// }> = ({
// 	setFilterButtonEl,
// 	filterStatus,
// 	setFilterStatus,
// 	setSearch,
// 	search,
// 	handlePopUp,
// 	handlePopUpDisabled,
// 	downloadButtonOptions,
// 	handlePasswordVisibility,
// 	mediaPlanHandler,
// 	isAutoSwitch,
// 	appIdList,
// 	appId,
// 	setAppId,
// 	selector,
// 	selectorProp,
// 	filterHandler,
// 	customFilter,
// 	clearAllFilters,
// 	showClearAllFiltersButton,
// 	setShowClearAllFiltersButton,
// 	mainFilterOptions,
// 	monthFilterArray,
// 	setMonthFilterArray,
// 	yearFilterArray,
// 	setYearFilterArray,
// 	downloadTemplate,
// 	fileInputClickHandler,
// 	sendEmail,
// 	financeLastSend,
// 	financeFinalSent,
// 	hideBlockedUsersButton,
// 	applyFilter,
// 	clearFilter,
// 	autoswitch,
// 	searchWithTimer,
// 	checkboxButtonValue,
// 	setCheckboxButtonValue,
// 	checkboxButtonLabel,
// 	downloadButtonFolderOptions,
// 	infoOpened,
// 	setInfoOpened,
// 	triggerExtraBolFunction,
// 	triggerExtraBolFunctionValue,
// 	extraBolFunctionLabel,
// 	presetSearch,
// }) => {
// 	const possibleCampaignFilter = mainFilterOptions
// 		? mainFilterOptions
// 		: ['Both', 'Active', 'Disabled']
// 	// const [filterTitleColor, setFilterTitleColor] = useState(theme.colors.black)
// 	const activeFilterHandler = (
// 		possibleCampaign: any,
// 		filterCurrent: any,
// 		setFilter: any,
// 	) => {
// 		let maxNumber = possibleCampaign.length
// 		const number = filterCurrent + 1
// 		if (number === maxNumber) setFilter(0)
// 		else setFilter(number)
// 	}

// 	const handleClearAllFilters = () => {
// 		clearAllFilters()
// 		applyFilter()
// 		setShowClearAllFiltersButton(false)
// 	}
// 	const [downloadFocus, setDownloadFocus] = useState(false)
// 	const [uploadFocus, setUploadFocus] = useState(false)
// 	const [sendFocus, setSendFocus] = useState(false)
// 	const [changeErrorButtonColor, setChangeErrorButtonColor] = useState(false)
// 	const [yearOptions, setYearOptions] = useState<string[]>([])

// 	useEffect(() => {
// 		if (yearOptions.length === 0) {
// 			let startingYear = 2023
// 			const currentYear = moment().year()
// 			const arr: (string | number)[] = [startingYear]
// 			while (startingYear !== currentYear) {
// 				startingYear++
// 				arr.push(startingYear)
// 			}
// 			for (const indx in arr) {
// 				if (currentYear === arr[indx]) {
// 					arr[indx] = 'This Year'
// 				} else {
// 					arr[indx] = arr[indx].toString()
// 				}
// 			}
// 			setYearOptions(arr.reverse() as string[])
// 		}
// 	}, [])

// 	return (
// 		<GridToolbarContainer
// 			style={{
// 				gap: '12px',
// 				display: 'flex',
// 				justifyContent: 'flex-start',
// 				marginBottom: '8px',
// 			}}
// 		>
// 			{/* <GridToolbarFilterButton
// 				ref={setFilterButtonEl}
// 				style={{ color: filterTitleColor }}
// 				nonce={undefined}
// 				onResize={undefined}
// 				onResizeCapture={undefined}
// 				onMouseEnter={() => handleFilterColor(true)}
// 				onMouseLeave={() => handleFilterColor(false)}
// 			/> */}
// 			{filterStatus !== undefined && (
// 				<StatusButton
// 					activeFilterHandler={activeFilterHandler}
// 					possibleCampaignFilter={possibleCampaignFilter}
// 					filterStatus={filterStatus}
// 					setFilterStatus={setFilterStatus}
// 				/>
// 			)}
// 			{search !== undefined && (
// 				<TableSearchComponent
// 					style={{
// 						width: '16vw',
// 						boxShadow: 'none',
// 						border: '1px solid rgba(0,0,0,0.12)',
// 						borderRadius: '3px',
// 						marginTop: '9px',
// 					}}
// 					setSearch={setSearch}
// 					search={search}
// 					filterHandler={filterHandler}
// 					searchWithTimer={searchWithTimer}
// 					presetSearch={presetSearch}
// 				/>
// 			)}
// 			{typeof handlePopUp !== 'undefined' && autoswitch === undefined && (
// 				<UnborderedButton
// 					onClick={(e: any) => handlePopUp(e)}
// 					label={'Add' + convertPathName(window.location.pathname)}
// 					icon={<AddIcon />}
// 					style={{ color: theme.colors.text.titles }}
// 					// iconStyle={{ height: '16px' }}
// 				/>
// 			)}
// 			{selector && selectorProp && (
// 				// <AddButton
// 				// 	onClick={() => selector(selectorProp)}
// 				// 	style={{
// 				// 		// boxShadow: 'none',
// 				// 		width: '80px',
// 				// 		height: '30px',
// 				// 		marginTop: '9px',
// 				// 		fontSize: '12px',
// 				// 	}}
// 				// >
// 				// 	Apps
// 				// </AddButton>
// 				<UnborderedButton
// 					onClick={() => selector(selectorProp)}
// 					label={'Apps'}
// 					icon={<img src={appsIcon} alt='mediaplanicon' />}
// 					style={{ color: theme.colors.text.titles }}
// 				/>
// 			)}
// 			{mediaPlanHandler !== undefined && (
// 				// <AddButton
// 				// 	onClick={mediaPlanHandler}
// 				// 	style={{
// 				// 		// boxShadow: 'none',
// 				// 		width: '100px',
// 				// 		height: '30px',
// 				// 		marginTop: '9px',
// 				// 		fontSize: '12px',
// 				// 	}}
// 				// >
// 				// 	Media Plan
// 				// </AddButton>
// 				<UnborderedButton
// 					onClick={mediaPlanHandler}
// 					label={'Media Plan'}
// 					icon={<img src={mediaPlanIcon} alt='mediaplanicon' />}
// 					style={{ color: theme.colors.text.titles }}
// 				/>
// 			)}

// 			{triggerExtraBolFunction !== undefined && (
// 				<SubmitButton
// 					onClick={() => triggerExtraBolFunction()}
// 					style={{
// 						// boxShadow: 'none',
// 						width: '120px',
// 						height: '30px',
// 						marginTop: '9px',
// 						fontSize: '12px',
// 					}}
// 				>
// 					{
// 						extraBolFunctionLabel[
// 							triggerExtraBolFunctionValue as unknown as keyof typeof extraBolFunctionLabel
// 						]
// 					}
// 				</SubmitButton>
// 			)}
// 			{setCheckboxButtonValue !== undefined && (
// 				<>
// 					<SubmitButton
// 						onClick={() => setCheckboxButtonValue(!checkboxButtonValue)}
// 						style={{
// 							// boxShadow: 'none',
// 							width: checkboxButtonValue === undefined ? '90px' : '120px',
// 							height: '30px',
// 							marginTop: '9px',
// 							fontSize: '12px',
// 						}}
// 						onMouseEnter={() => setChangeErrorButtonColor(true)}
// 						onMouseLeave={() => setChangeErrorButtonColor(false)}
// 						isActive={checkboxButtonValue}
// 					>
// 						<Checkbox
// 							checked={checkboxButtonValue}
// 							onChange={() => {}}
// 							disabled
// 							icon={
// 								<CheckBoxOutlineBlankIcon
// 									style={{
// 										scale: '0.7',
// 										color: changeErrorButtonColor
// 											? 'white'
// 											: themeOld.colors.black,
// 									}}
// 								/>
// 							}
// 							style={{ padding: '0px 4px 0px 0px' }}
// 							checkedIcon={
// 								<CheckBoxIcon
// 									style={{
// 										scale: '0.7',
// 										color: changeErrorButtonColor
// 											? 'white'
// 											: themeOld.colors.black,
// 									}}
// 								/>
// 							}
// 						/>
// 						<span>{checkboxButtonLabel}</span>
// 					</SubmitButton>
// 				</>
// 			)}
// 			{hideBlockedUsersButton && (
// 				<SubmitButton
// 					onClick={handlePopUp}
// 					style={{
// 						// boxShadow: 'none',
// 						width: '80px',
// 						height: '30px',
// 						marginTop: '9px',
// 						fontSize: '12px',
// 					}}
// 				>
// 					Blocked
// 				</SubmitButton>
// 			)}
// 			<div
// 				style={{
// 					display: 'flex',
// 					marginLeft: 'auto',
// 					marginRight: '3%',
// 					gap: '12px',
// 				}}
// 			>
// 				{customFilter !== undefined && (
// 					<UnborderedButton
// 						onClick={handleClearAllFilters}
// 						label={'Clear Filters'}
// 						icon={<img src={filterRemoveIcon} alt='filterRemoveIcon' />}
// 						style={{ color: theme.colors.text.titles }}
// 					/>
// 					// <AddButton
// 					// 	onClick={handleClearAllFilters}
// 					// 	style={{
// 					// 		// boxShadow: 'none',
// 					// 		width: '120px',
// 					// 		height: '30px',
// 					// 		marginTop: '9px',
// 					// 		fontSize: '12px',
// 					// 	}}
// 					// 	disabled={!showClearAllFiltersButton}
// 					// >
// 					// 	Clear All Filters
// 					// </AddButton>
// 				)}
// 				{downloadButtonOptions !== undefined && (
// 					<SimpleActionsButton
// 						width={0}
// 						row={undefined}
// 						style={{
// 							// background: theme.colors.buttonGradient,
// 							// borderRadius: '10px',
// 							// color: theme.colors.black,
// 							height: '30px',
// 							width: '80px',
// 							marginTop: '-1px',
// 						}}
// 						label={'download'}
// 						options={downloadButtonOptions}
// 						id={'downloadButton'}
// 						noArrow
// 					>
// 						<DownloadIcon style={{ width: '20px' }} />
// 					</SimpleActionsButton>
// 				)}
// 				{handlePasswordVisibility !== undefined && (
// 					<SubmitButton
// 						fullWidth
// 						style={{
// 							width: '80px',
// 							height: '30px',
// 							marginTop: '9px',
// 							fontSize: '12px',
// 						}}
// 						onClick={() => {
// 							handlePasswordVisibility()
// 						}}
// 					>
// 						<VisibilityIcon />
// 					</SubmitButton>
// 				)}
// 				{setInfoOpened && (
// 					<button
// 						style={{
// 							display: 'flex',
// 							marginRight: '3%',
// 							maxWidth: '420px',
// 							height: 'auto',
// 							marginLeft: 'auto',
// 							marginTop: 'auto',
// 							marginBottom: 'auto',
// 							border: 'none',
// 							fontSize: '14px',
// 							textDecoration: 'underline',
// 							cursor: 'pointer',
// 							gap: '4px',
// 							color: '',
// 						}}
// 						onClick={() => setInfoOpened(!infoOpened)}
// 					>
// 						<InfoOutlinedIcon></InfoOutlinedIcon> Info
// 					</button>
// 				)}
// 				{yearFilterArray && (
// 					<div
// 						style={{
// 							display: 'flex',
// 							marginRight: '3%',
// 							maxWidth: '420px',
// 							// overflow: 'auto',
// 							marginLeft: 'auto',
// 						}}
// 					>
// 						<StaticChipInputList
// 							onChange={setYearFilterArray}
// 							options={yearOptions}
// 							value={yearFilterArray}
// 							openWithClick={true}
// 							style={
// 								{
// 									height: 'inherit',
// 									display: 'flex',
// 									alignItems: 'center',
// 									justifyContent: 'center',
// 									margin: '8px',
// 									width: 'auto',
// 									minWidth: '100px',
// 									// marginTop: '25px',
// 								} as any
// 							}
// 							onlyOptionsAllowed
// 							placeholder={'Year'}
// 						/>
// 					</div>
// 				)}
// 				{monthFilterArray && (
// 					<div
// 						style={{
// 							display: 'flex',
// 							marginRight: '30px',
// 							maxWidth: '420px',
// 						}}
// 					>
// 						<StaticChipInputList
// 							onChange={setMonthFilterArray}
// 							options={[
// 								'January',
// 								'February',
// 								'March',
// 								'April',
// 								'May',
// 								'June',
// 								'July',
// 								'August',
// 								'September',
// 								'October',
// 								'November',
// 								'December',
// 							]}
// 							value={monthFilterArray}
// 							openWithClick={true}
// 							style={
// 								{
// 									height: 'inherit',
// 									display: 'flex',
// 									alignItems: 'center',
// 									// justifyContent: 'center',
// 									margin: '8px',
// 									width: 'auto',
// 									minWidth: '140px',
// 									// marginTop: '25px',
// 								} as any
// 							}
// 							onlyOptionsAllowed
// 							placeholder={'Month'}
// 						/>
// 					</div>
// 				)}
// 				{downloadTemplate && (
// 					<SubmitButton
// 						onClick={downloadTemplate}
// 						style={{
// 							display: 'flex',
// 							flexDirection: 'column',
// 							fontSize: '28px',
// 							minWidth: '78px',
// 							height: '58px',
// 							padding: '8px 16px 8px 16px',
// 						}}
// 						onMouseEnter={() => setDownloadFocus(true)}
// 						onMouseLeave={() => setDownloadFocus(false)}
// 					>
// 						<img
// 							src={downloadFocus ? downloadFolderIconWhite : downloadFolderIcon}
// 							style={{ height: '32px' }}
// 							alt='download-icon'
// 						></img>
// 						<span style={{ fontSize: '12px' }}>Download</span>
// 					</SubmitButton>
// 				)}
// 				{downloadButtonFolderOptions && (
// 					<SimpleActionsButton
// 						style={{
// 							display: 'flex',
// 							flexDirection: 'column',
// 							fontSize: '28px',
// 							width: '74px',
// 							height: '58px',
// 							paddingTop: '8px',
// 							background: 'none',
// 							boxShadow: '1px 1px 1px 1px rgba(0,0,0,0.2)',
// 							border: '1px solid rgba(0,0,0,0.2)',
// 							marginTop: '10px',
// 							borderRadius: '10px',
// 						}}
// 						onMouseEnter={() => setDownloadFocus(true)}
// 						onMouseLeave={() => setDownloadFocus(false)}
// 						width={0}
// 						row={undefined}
// 						type={'folder-icon'}
// 						noArrow
// 						options={downloadButtonFolderOptions}
// 					>
// 						<img
// 							src={downloadFocus ? downloadFolderIconWhite : downloadFolderIcon}
// 							style={{ height: '32px' }}
// 							alt='download-icon'
// 						></img>
// 						<span style={{ fontSize: '12px' }}>Download</span>
// 					</SimpleActionsButton>
// 				)}
// 				{fileInputClickHandler && (
// 					<SubmitButton
// 						onClick={fileInputClickHandler}
// 						style={{
// 							display: 'flex',
// 							flexDirection: 'column',
// 							fontSize: '28px',
// 							width: '80px',
// 							height: '58px',
// 							paddingTop: '8px',
// 						}}
// 						onMouseEnter={() => setUploadFocus(true)}
// 						onMouseLeave={() => setUploadFocus(false)}
// 					>
// 						<img
// 							src={uploadFocus ? uploadFolderIconWhite : uploadFolderIcon}
// 							style={{ height: '32px' }}
// 							alt='upload-icon'
// 						></img>
// 						<span style={{ fontSize: '12px' }}>Upload</span>
// 					</SubmitButton>
// 				)}
// 				{sendEmail && (
// 					<div style={{ display: 'flex', flexDirection: 'column' }}>
// 						<SimpleActionsButton
// 							// onClick={sendEmail}
// 							style={{
// 								display: 'flex',
// 								flexDirection: 'column',
// 								fontSize: '28px',
// 								width: '80px',
// 								height: '58px',
// 								paddingTop: '8px',
// 								marginTop: '10px',
// 								border: '1px solid rgba(0,0,0,0.2)',
// 								borderRadius: '10px',
// 							}}
// 							onMouseEnter={() => setSendFocus(true)}
// 							onMouseLeave={() => setSendFocus(false)}
// 							noArrow
// 							width={0}
// 							row={undefined}
// 							label={'Send Email'}
// 							options={sendEmail}
// 							id={'downloadButton'}
// 						>
// 							<img
// 								src={sendFocus ? sendWhiteIcon : sendIcon}
// 								style={{ height: '32px' }}
// 								alt='upload-icon'
// 							></img>
// 							<span style={{ fontSize: '12px' }}>Send</span>
// 						</SimpleActionsButton>
// 						{financeLastSend && (
// 							<div
// 								style={{
// 									color: 'black',
// 									position: 'relative',
// 									// left: '-10px',
// 									fontSize: '11px',
// 									width: '100px',
// 									marginTop: '4px',
// 								}}
// 							>
// 								{financeFinalSent
// 									? 'Final Numbers sent: '
// 									: 'Last Status sent: '}
// 								<span style={{ fontWeight: '600' }}>{financeLastSend}</span>
// 							</div>
// 						)}
// 					</div>
// 				)}
// 			</div>
// 		</GridToolbarContainer>
// 	)
// }

// export const TableComponent = (props: {
// 	style?: any
// 	setLoading: any
// 	columns: GridColDef[]
// 	rows: any[]
// 	setPageSize: any
// 	pageSize: any
// 	rowsPerPageOptions: any
// 	onRowClick?: any
// 	experimentalFeatures?: any
// 	onRowEditStop?: any
// 	onRowEditCommit?: any
// 	commitCellChange?: any
// 	filterStatus?: any
// 	setFilterStatus?: any
// 	setSearch?: any
// 	search?: any
// 	handlePopUp?: any
// 	downloadButtonOptions?: any
// 	handlePasswordVisibility?: any
// 	mediaPlanHandler?: any
// 	onCellClick?: any
// 	sortModel?: any
// 	rowHeight?: any
// 	onSortModelChange?: any
// 	filterHandler?: any
// 	setFilterHander?: any
// 	customFilter?: any
// 	setCustomFilter?: any
// 	currentFilters?: any
// 	applyFilter?: any
// 	filterLabel?: any
// 	optionsFilters?: any
// 	mainFilterOptions?: any
// 	rowModesModel?: any
// 	handleRowModesModelChange?: any
// 	selector?: any
// 	isAutoSwitch?: any
// 	appIdList?: any[]
// 	appId?: any
// 	setAppId?: any
// 	selectorProp?: any
// 	processRowUpdate?: any
// 	handleRowEditStop?: any
// 	handleDoubleCellClick?: any
// 	handleCellKeyDown?: any
// 	handleCellFocusOut?: any
// 	monthFilterArray?: any
// 	setMonthFilterArray?: any
// 	yearFilterArray?: any
// 	setYearFilterArray?: any
// 	downloadTemplate?: any
// 	fileInputClickHandler?: any
// 	treeData?: boolean
// 	getTreeDataPath?: any
// 	groupingColDef?: any
// 	sendEmail?: any
// 	financeLastSend?: any
// 	financeFinalSent?: boolean
// 	getRowClassName?: any
// 	customSx?: any
// 	customMessage?: any
// 	columnVisibility?: any
// 	autoswitch?: any
// 	pinnedBottomRow?: any
// 	apiRef?: any
// 	searchWithTimer?: boolean
// 	checkboxButtonValue?: boolean
// 	setCheckboxButtonValue?: Dispatch<SetStateAction<boolean>>
// 	checkboxButtonLabel?: string
// 	downloadButtonFolderOptions?: any
// 	infoOpened?: boolean
// 	setInfoOpened?: Dispatch<SetStateAction<boolean>>
// 	triggerExtraBolFunction?: Function
// 	triggerExtraBolFunctionValue?: boolean
// 	extraBolFunctionLabel?: any
// 	presetSearch?: string
// }) => {
// 	// useEffect(() => {
// 	// 	props.setLoading(false)
// 	// })

// 	const [filterButtonEl, setFilterButtonEl] =
// 		React.useState<HTMLButtonElement | null>(null)
// 	const [filterSearch, setFilterSearch] = useState([])

// 	const [showClearAllFiltersButton, setShowClearAllFiltersButton] =
// 		useState(false)
// 	const filterRef = useRef(null)
// 	const [position, setPosition] = useState({ x: 0, y: 0 })
// 	const [hoverRow, setHoverRow] = useState(false)
// 	const [currentHoveredRow, setCurrentHoveredRow] = useState<any>({})

// 	let handlePopUp = props.handlePopUp
// 	let handlePopUpDisabled = false
// 	if (props.handlePopUp) {
// 		if (typeof props.handlePopUp.onClick === 'function') {
// 			handlePopUp = props.handlePopUp.onClick
// 		}
// 		if (typeof props.handlePopUp.disabled === 'boolean') {
// 			handlePopUpDisabled = props.handlePopUp.disabled
// 		}
// 	}

// 	// const applyFilter = () => {
// 	// 	if (filterSearch.length === 0) {
// 	// 		props.customFilter[
// 	// 			props.currentFilters as keyof typeof props.customFilter
// 	// 		] = filterSearch
// 	// 	}
// 	// 	props.applyFilter(props.rows)
// 	// }
// 	const clearFilter = () => {
// 		if (props.customFilter) {
// 			props.customFilter[
// 				props.currentFilters as keyof typeof props.customFilter
// 			] = []
// 		}

// 		setFilterSearch([])
// 		props.applyFilter()
// 	}
// 	const clearAllFilters = () => {
// 		if (props.customFilter) {
// 			for (const key in props.customFilter) {
// 				props.customFilter[key as keyof typeof props.customFilter] = []
// 			}
// 		}

// 		setFilterSearch([])
// 		props.applyFilter()
// 	}

// 	const handleClickOutside = (event: any) => {
// 		if (
// 			filterRef.current &&
// 			!(filterRef.current as any).contains(event.target) &&
// 			event.target.tagName.toLowerCase() !== 'li' &&
// 			event.target.role !== 'option'
// 		) {
// 			if (props.setFilterHander) props.setFilterHander(false)
// 		}
// 	}

// 	const calculateLeftPosition = () => {
// 		let result = 0
// 		for (let i = 0; i < props.columns.length; i++) {
// 			if (props.columns[i].field === props.currentFilters) {
// 				result = i
// 				break
// 			}
// 		}
// 		return (result * 90) / props.columns.length + '%'
// 	}
// 	useEffect(() => {
// 		if (props.customFilter) {
// 			for (const key of Object.keys(props.customFilter)) {
// 				if (props.customFilter[key].length > 0) {
// 					setShowClearAllFiltersButton(true)
// 					break
// 				}
// 			}
// 		}
// 	}, [props.customFilter])
// 	useEffect(() => {
// 		if (props.customFilter) {
// 			if (filterSearch.length !== 0) {
// 				props.customFilter[props.currentFilters] = filterSearch
// 				setShowClearAllFiltersButton(true)
// 			} else {
// 				props.customFilter[props.currentFilters] = []
// 				props.applyFilter()
// 				let changed = false
// 				for (const key of Object.keys(props.customFilter)) {
// 					if (
// 						props.customFilter[key].length > 0 &&
// 						key !== props.currentFilters
// 					) {
// 						setShowClearAllFiltersButton(true)
// 						changed = true
// 						break
// 					}
// 				}
// 				// setFilterSearch([])
// 				changed || setShowClearAllFiltersButton(false)
// 			}
// 		}

// 		// console.log(filterSearch, props.customFilter)
// 	}, [filterSearch])

// 	useEffect(() => {
// 		document.addEventListener('click', handleClickOutside)
// 		return () => {
// 			document.removeEventListener('click', handleClickOutside)
// 		}
// 	}, [])

// 	useEffect(() => {
// 		if (props.customMessage) {
// 			const handleMouseMove = (e: any) => {
// 				// Subtract part of the div position in order to be closer to the cursor
// 				setPosition({
// 					x: e.clientX,
// 					y: e.clientY - 90,
// 				})
// 			}
// 			document.addEventListener('mousemove', handleMouseMove)
// 			return () => {
// 				document.removeEventListener('mousemove', handleMouseMove)
// 			}
// 		}
// 	}, [])
// 	const renderCustomMessage = () => {
// 		if (props.customMessage) {
// 			return props.customMessage(currentHoveredRow, position)
// 		} else {
// 			return <></>
// 		}
// 	}

// 	const filterSearchComponentArray = (filterKeysList: any) => {
// 		let wholeList: any = {}
// 		// console.log(props.currentFilters)

// 		for (let e of filterKeysList)
// 			wholeList[e] = (
// 				<FilterSearchComponent
// 					style={{
// 						width: '95%',
// 						boxShadow: 'none',
// 						border: '1px solid rgba(0,0,0,0.12)',
// 						borderRadius: '3px',
// 						marginTop: '9px',
// 						height: 'inherit',
// 					}}
// 					key={'FilterSearchComponent___' + e}
// 					setSearch={setFilterSearch}
// 					search={filterSearch}
// 					applyFilter={props.applyFilter}
// 					filterLabel={props.filterLabel}
// 					currentFilters={props.currentFilters}
// 					clearFilter={clearFilter}
// 					optionsFilters={props.optionsFilters}
// 					setFilterHander={props.setFilterHander}
// 					filtersKey={e}
// 					customFilter={props.customFilter}
// 				/>
// 			)
// 		// console.log(wholeList)

// 		return wholeList[props.currentFilters]
// 	}
// 	let filterKeysList: any = []
// 	if (props.customFilter) {
// 		filterKeysList = Object.keys(props.customFilter)
// 	}

// 	return (
// 		<>
// 			<DataGridPro
// 				initialState={{
// 					sorting: {
// 						sortModel: [
// 							props.sortModel
// 								? props.sortModel
// 								: { field: 'createdAt', sort: 'desc' },
// 						],
// 					},
// 					columns: { columnVisibilityModel: props.columnVisibility },
// 				}}
// 				rows={props.rows}
// 				columns={props.columns}
// 				// onPageSizeChange={(newPageSize) => props.setPageSize(newPageSize)}
// 				onCellEditStop={props.onRowEditStop}
// 				disableColumnMenu
// 				rowHeight={props.rowHeight ? props.rowHeight : 80}
// 				// headerHeight={50}
// 				sortModel={props.sortModel}
// 				slots={{
// 					toolbar: CustomToolbar,
// 				}}
// 				editMode={'row'}
// 				rowModesModel={props.rowModesModel}
// 				onRowModesModelChange={props.handleRowModesModelChange}
// 				slotProps={{
// 					panel: {
// 						anchorEl: filterButtonEl,
// 					},
// 					toolbar: {
// 						setFilterButtonEl,
// 						filterStatus: props.filterStatus,
// 						setFilterStatus: props.setFilterStatus,
// 						setSearch: props.setSearch,
// 						search: props.search,
// 						handlePopUp: handlePopUp,
// 						handlePopUpDisabled: handlePopUpDisabled,
// 						downloadButtonOptions: props.downloadButtonOptions,
// 						handlePasswordVisibility: props.handlePasswordVisibility,
// 						mediaPlanHandler: props.mediaPlanHandler,
// 						filterHandler: props.filterHandler,
// 						customFilter: props.customFilter,
// 						clearAllFilters: clearAllFilters,
// 						showClearAllFiltersButton: showClearAllFiltersButton,
// 						setShowClearAllFiltersButton: setShowClearAllFiltersButton,
// 						mainFilterOptions: props.mainFilterOptions,
// 						setAppId: props.setAppId,
// 						appId: props.appId,
// 						selector: props.selector,
// 						selectorProp: props.selectorProp,
// 						monthFilterArray: props.monthFilterArray,
// 						setMonthFilterArray: props.setMonthFilterArray,
// 						yearFilterArray: props.yearFilterArray,
// 						setYearFilterArray: props.setYearFilterArray,
// 						downloadTemplate: props.downloadTemplate,
// 						fileInputClickHandler: props.fileInputClickHandler,
// 						sendEmail: props.sendEmail,
// 						financeLastSend: props.financeLastSend,
// 						financeFinalSent: props.financeFinalSent,
// 						applyFilter: props.applyFilter,
// 						autoswitch: props.autoswitch,
// 						searchWithTimer: props.searchWithTimer,
// 						checkboxButtonValue: props.checkboxButtonValue,
// 						setCheckboxButtonValue: props.setCheckboxButtonValue,
// 						checkboxButtonLabel: props.checkboxButtonLabel,
// 						downloadButtonFolderOptions: props.downloadButtonFolderOptions,
// 						infoOpened: props.infoOpened,
// 						setInfoOpened: props.setInfoOpened,
// 						triggerExtraBolFunction: props.triggerExtraBolFunction,
// 						triggerExtraBolFunctionValue: props.triggerExtraBolFunctionValue,
// 						extraBolFunctionLabel: props.extraBolFunctionLabel,
// 						presetSearch: props.presetSearch,
// 					},
// 					cell: { onBlur: props.handleCellFocusOut },
// 					row: {
// 						onMouseEnter: (event) => {
// 							if (event.currentTarget.dataset.id) {
// 								const id = parseInt(event.currentTarget.dataset.id)
// 								const row = props.rows.find((el: any) => el.id === id)
// 								setCurrentHoveredRow(row)
// 								setHoverRow(true)
// 							}
// 						},
// 						onMouseLeave: () => {
// 							setHoverRow(false)
// 						},
// 					},
// 				}}
// 				getRowHeight={(v) => {
// 					return props.triggerExtraBolFunctionValue ? 80 : props.rowHeight
// 				}}
// 				onCellClick={props.onCellClick}
// 				onSortModelChange={props.onSortModelChange}
// 				onCellDoubleClick={props.handleDoubleCellClick}
// 				onCellKeyDown={props.handleCellKeyDown}
// 				treeData={props.treeData}
// 				getTreeDataPath={props.getTreeDataPath}
// 				groupingColDef={props.groupingColDef}
// 				getRowClassName={(params) => {
// 					if (props.getRowClassName) {
// 						return props.getRowClassName(params)
// 					} else {
// 						return props.treeData &&
// 							params.row.hierarchy &&
// 							params.row.hierarchy.length > 1
// 							? 'treed-row'
// 							: ''
// 					}
// 				}}
// 				sx={{
// 					'& .MuiDataGrid-withBorderColor': {
// 						borderBottom: `1px solid ${theme.colors.base.grey2}`,
// 					},
// 					'& .MuiDataGrid-columnHeaders': {
// 						borderBottom: `1px solid ${theme.colors.base.grey3} !important`,
// 					},
// 					...props.customSx,
// 				}}
// 				processRowUpdate={props.processRowUpdate}
// 				pinnedRows={{ bottom: props.pinnedBottomRow }}
// 				style={{
// 					border: 'none',
// 					...props.style,
// 				}}
// 				apiRef={props.apiRef}

// 				// {...props}
// 			/>
// 			<div ref={filterRef}>
// 				{props.filterHandler && (
// 					<div
// 						style={{
// 							background: themeOld.colors.gray,
// 							width: '40%',
// 							padding: '0px 18px 0px 18px',
// 							minWidth: '400px',
// 							height: 'auto',
// 							display: 'flex',
// 							position: 'absolute',
// 							top: '11%',
// 							left: calculateLeftPosition(),
// 							boxShadow: '2px 2px 6px 1px rgba(0,0,0,0.4)',
// 							borderRadius: '12px',
// 							alignItems: 'center',
// 						}}
// 					>
// 						{filterSearchComponentArray(filterKeysList)}
// 					</div>
// 				)}
// 				{hoverRow && currentHoveredRow && renderCustomMessage()}
// 			</div>
// 		</>
// 	)
// }

export const StyledAutocomplete = styled(Autocomplete)(({ theme }) => ({
	paddingTop: '0px',
	paddingLeft: '0px',

	'& .MuiOutlinedInput-root': {
		backgroundColor: 'transparent',
		borderRadius: theme.dimentions.smallerRadius,

		padding: '0px',
		'& .MuiAutocomplete-input': {
			padding: '0px',
			// width: '800px',
		},
	},
	'& .MuiOutlinedInput-input': {
		backgroundColor: 'transparent',
		//borderRadius: theme.dimentions.smallerRadius,
		'& .MuiAutocomplete-popper': {
			width: '500px',
		},
		padding: '0px',
	},
}))

export const InfoTag = (props: { title: string }) => {
	return (
		<div
			style={{
				position: 'relative',
				width: '20%',
			}}
		>
			<Tooltip title={props.title}>
				<HelpIcon
					style={{
						fill: theme.colors.base.red300,
						top: '-1.3vh',
						display: 'flex',
						position: 'absolute',
						zIndex: 2,
						paddingTop: '7px',
					}}
				/>
			</Tooltip>
		</div>
	)
}
export const ErrorTag = (props: { title: string; color?: string }) => {
	return (
		<div
			style={{
				position: 'relative',
				width: '20%',
			}}
		>
			<Tooltip title={props.title}>
				<ErrorIcon
					style={{
						fill: theme.colors.base.red300,
						top: '-1.3vh',
						display: 'flex',
						position: 'absolute',
						zIndex: 2,
						paddingTop: '7px',
					}}
				/>
			</Tooltip>
		</div>
	)
}
export const CancelIconButton = styled(IconButton)(({ theme }) => ({
	flow: 'left',
	position: 'absolute',
	zIndex: 4,
	marginLeft: '140px',
	marginTop: '-20px',
	padding: 'unset',

	color: theme.colors.red,
	'&.Mui-disabled': {
		background: theme.colors.lightGray,
		boxShadow: 'none',
	},
}))
export const UserNameWithAvatar = (props: {
	avatars: any
	value: string
	withoutName?: boolean
	position?: string
	country?: string
	bigPicture?: boolean
	onClick?: any
	selected?: boolean
}) => {
	return (
		<div
			style={{
				display: 'flex',
				alignItems: 'center',
				// marginBlock: '25px',
				width: '20vw',
				gap: '0.5vw',
				justifyContent: 'inherit',
			}}
			data-tag={'_navbarButton'}
		>
			<button
				style={{
					display: 'flex',
					position: 'relative',
					border: 'none',
					cursor: props.onClick ? 'pointer' : 'inherit',
				}}
				onClick={props.onClick ? props.onClick : () => {}}
				data-tag={'_navbarButton'}
			>
				<Avatar
					data-tag={'_navbarButton'}
					src={props.avatars[props.value] || 'na '}
					{...stringAvatar(props.value)}
					style={{
						height: props.bigPicture ? '130px' : 'inherited',
						width: props.bigPicture ? '130px' : 'inherited',
						// border: '2px solid ' + theme.colors.base.white,
						boxShadow: `0px 0px 0px 2px ${
							props.selected ? theme.colors.base.white : 'rgba(0,0,0,0)'
						}`,
					}}
				/>
				{props.country && (
					<div
						style={{
							height: '1rem',
							width: '1rem',
							display: 'flex',
							position: 'absolute',
							bottom: -4,
							left: 2,
							borderRadius: '100px',
							overflow: 'hidden',
							// border: '1px solid blue',
						}}
					>
						<ReactCountryFlag
							style={{
								width: '150%',
								// height: '2rem',
								scale: '1.3',
								left: '0%',
								bottom: '-9%',
								position: 'relative',
							}}
							key={'flags' + props.country}
							className='roundedFlag'
							countryCode={props.country === 'UK' ? 'GB' : props.country}
							svg
						/>
					</div>
				)}

				{/* style={{
						height: '1rem',
						width: '1rem',
						display: 'flex',
						position: 'absolute',
						bottom: -4,
						left: 0,
						border: '1px solid black',
					}} */}
			</button>
			<div
				style={{
					display: 'flex',
					justifyContent: 'center',
					flexDirection: 'column',
					gap: '4px',
					color: theme.colors.text.titles,
				}}
			>
				{!props.withoutName && (
					<span
						style={{
							fontWeight: theme.font.weight.bolder,
							fontSize: theme.font.size.buttons,
						}}
					>
						{props.value}
					</span>
				)}
				{props.position && (
					<span
						style={{
							fontWeight: theme.font.weight.skinny,
							fontSize: theme.font.size.body,
						}}
					>
						{props.position}
					</span>
				)}
			</div>
		</div>
	)
}
export const CancelButton = styled(Button)(({ theme }) => ({
	flow: 'left',
	position: 'relative',
	margin: '10px',
	width: '15%',
	background: theme.palette.primary.main,
	borderRadius: '10px',
	borderColor: theme.colors.lightGray,
	border: '1px solid rgba(0,0,0,0.2)',
	boxShadow: '1px 1px 0px 1px rgba(0,0,0,0.2)',
	color: theme.palette.primary.dark,
	'&.Mui-disabled': {
		background: theme.colors.lightGray,
		boxShadow: 'none',
	},
}))
export const AddButton = styled(Button, {
	shouldForwardProp: (prop) => prop !== 'isActive',
})<{ isActive?: boolean }>(({ theme, isActive }) => ({
	flow: 'right',
	position: 'relative',
	margin: '10px',
	width: '15%',
	background: theme.colors.buttonGradient,
	borderRadius: '10px',
	// boxShadow: theme.shadow.boxShadow,
	color: theme.colors.black,
	border: '1px solid rgba(0,0,0,0.2)',
	boxShadow: '1px 1px 1px 1px rgba(0,0,0,0.2)',
	'&.Mui-disabled': {
		background: theme.colors.lightGray,
		boxShadow: 'none',
	},
	// ...(isActive && {
	// 	boxShadow: 'none',
	// 	translate: '2px,2px',
	// }),
}))

export const StyledButtonComp = styled(Button)(({ theme }) => ({
	flow: 'right',
	position: 'relative',

	width: '15%',
	background: theme.colors.gradient,
	borderRadius: '11px',
	// boxShadow: theme.shadow.boxShadow,
	color: theme.colors.black,
	// border: '1px solid rgba(0,0,0,0.2)',
	// boxShadow: '1px 1px 1px 1px rgba(0,0,0,0.2)',
	'&.Mui-disabled': {
		background: theme.colors.lightGray,
		boxShadow: 'none',
	},
}))
export const StyledBoxComp = styled(Box)(({ theme }) => ({
	flow: 'right',
	position: 'relative',

	width: '15%',
	background: theme.colors.gradient,
	borderRadius: '11px',
	// boxShadow: theme.shadow.boxShadow,
	color: theme.colors.black,
	// border: '1px solid rgba(0,0,0,0.2)',
	// boxShadow: '1px 1px 1px 1px rgba(0,0,0,0.2)',
	'&.Mui-disabled': {
		background: theme.colors.lightGray,
		boxShadow: 'none',
	},
}))
export const BorderGradientButton = (props: {
	tooltip?: string
	onClick: any
	children?: any
	style?: any
}) => {
	return (
		<StyledBoxComp
			style={{
				...props.style,
				margin: '0px 4px',
				width: props.style ? props.style.width ?? '200px' : '200px',
				padding: '1px',
				boxShadow: 'none',
				marginTop: props.style ? props.style.marginTop ?? '20px' : '20px',
			}}
		>
			{props.tooltip ? (
				<Tooltip
					title={props.tooltip}
					// style={{ marginLeft: '35px' }}
					placement='top-end'
				>
					<AddButton
						style={{
							margin: '0px',
							width: '100%',
							padding: '10px',
							paddingInline: '20px',
							boxShadow: 'none',
							border: 'none',
						}}
						onClick={props.onClick}
						children={props.children}
					></AddButton>
				</Tooltip>
			) : (
				<AddButton
					style={{
						margin: '0px',
						width: '100%',
						padding: '10px',
						paddingInline: '20px',
						boxShadow: 'none',
						border: 'none',
					}}
					onClick={props.onClick}
					children={props.children}
				></AddButton>
			)}
		</StyledBoxComp>
	)
}
export const PageWrapper = styled('div')(() => ({
	// marginInline: '2rem',
	// marginTop: '6rem',
	marginLeft: 100,
	marginTop: 34,
}))

export const Logo = styled(Box)(() => ({
	position: 'absolute',
	width: '120px',
	bottom: '3%',
	zIndex: 12,
}))

export const GreetingsComponent = styled(Typography)(({ theme }) => ({
	...theme.typography,
	color: theme.palette.common.black,
	fontSize: '20px',
	fontWeight: '600',
	marginLeft: '5px',
	marginBottom: '30px',
	// border: '1px solid rgba(0,0,0,0.1)',
	// 						borderRadius: '20px',
	// 						boxShadow: '-2px 2px 0px 1px rgba(0,0,0,0.4)',
}))
type MyT = React.ComponentType<TypographyProps<'span', { component?: 'span' }>>

// export const TitleComponent: MyT = styled(Typography)(({ theme }) => ({
// 	...theme.typography,
// 	color: theme.palette.common.black,
// 	fontSize: '30px',
// 	fontWeight: 'bold',
// 	// marginLeft: '15px',
// }))
export const GradientTitleComponent: MyT = styled(Typography)(({ theme }) => ({
	...theme.typography,
	color: theme.palette.common.black,
	fontSize: '30px',
	fontWeight: 'bold',
	marginLeft: '15px',
	background: '-webkit-linear-gradient(360deg, #ee1d4a 0%, #401381 100%)',
	WebkitBackgroundClip: 'text',
	WebkitTextFillColor: 'transparent',
	width: 'fit-content',
}))

export const ItemTitle = styled('span')(({ theme }) => ({
	...theme.typography,
	fontWeight: '400',
}))

export const Search = styled('div')(({ theme }) => ({
	position: 'relative',
	width: '20%',
	borderRadius: theme.dimentions.biggerRadius,
	boxShadow: theme.shadow.boxShadow,
	backgroundColor: theme.palette.common.white,
	fontFamily: theme.typography.fontFamily,
	'&:hover': {
		backgroundColor: theme.palette.common.white,
	},

	[theme.breakpoints.up('sm')]: {
		marginLeft: theme.spacing(1),
		left: '60%',
	},
}))

// export const SearchIconWrapper = styled('div')(() => ({
// 	padding: theme.spacing(0, 2),
// 	height: '100%',
// 	position: 'absolute',
// 	pointerEvents: 'none',
// 	display: 'flex',
// 	alignItems: 'center',
// 	justifyContent: 'center',
// 	zIndex: '20',
// 	'& .MuiSvgIcon-root': {
// 		fill: theme.colors.text.grey,
// 		color: theme.colors.text.grey,
// 	},
// }))

export const StyledInputBase = styled(InputBase)(({ theme, disabled }) => ({
	'& .MuiInputBase-input': {
		padding: theme.spacing(0.8, 1, 1, 1),
		paddingLeft: `calc(1em + ${theme.spacing(4)})`,
		width: 'inherit',
		height: '100% !important',
		backgroundColor: 'rgba(242, 242, 242, 1)',
		cursor: disabled ? 'not-allowed' : 'text',
	},
}))
// export const SimpleActionsButton = (props: {
// 	children?: any
// 	label?: string
// 	options?: any
// 	style?: any
// 	onClick?: any
// 	// arrowColor?: string
// 	// id?: string
// 	// noArrow?: boolean
// 	// onMouseEnter?: any
// 	// onMouseLeave?: any
// 	// type?: string
// }) => {
// 	const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null)
// 	// const [arrowColor, setArrowColor] = useState(props.arrowColor)
// 	const open = Boolean(anchorEl)

// 	const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
// 		setAnchorEl(event.currentTarget)
// 	}
// 	const handleClose = () => {
// 		setAnchorEl(null)
// 	}
// 	const MenuOptions = () => {
// 		if (props.options) {
// 			return props.options
// 		}
// 		return (
// 			<div>
// 				<MenuItem key={'menuption_edit'} onClick={handleClose}>
// 					Edit
// 				</MenuItem>
// 				<MenuItem key={'menuption_delete'} onClick={handleClose}>
// 					Delete
// 				</MenuItem>
// 			</div>
// 		)
// 	}
// 	// const handleHoverArrowColor = (hovered: boolean) => {
// 	// 	if (hovered) {
// 	// 		setArrowColor('inherit')
// 	// 	} else {
// 	// 		setArrowColor(props.arrowColor)
// 	// 	}
// 	// }
// 	// let style = {}
// 	// if (props.type === 'folder-icon') {
// 	// 	style = { ...props.style }
// 	// } else {
// 	// 	style = {
// 	// 		...props.style,
// 	// 		zIndex: 9,
// 	// 		background:
// 	// 			props.label === 'Selector' || props.label === 'settings'
// 	// 				? 'none'
// 	// 				: 'rgba(0, 0, 0, 0.02)',
// 	// 		boxShadow:
// 	// 			props.label === 'Selector' || props.label === 'settings'
// 	// 				? 'none'
// 	// 				: '1px 1px 1px 1px rgba(0,0,0,0.2)',
// 	// 		justifyContent: props.label === 'Selector' ? 'flex-start' : 'center',
// 	// 	}
// 	// }
// 	return (
// 		<div style={{ display: 'inherit' }}>
// 			<UnborderedButton
// 				onClick={(e: any) => {
// 					handleClick(e)
// 					//props.onClick()
// 				}}
// 				// style={style}
// 			>
// 				{props.label}
// 			</UnborderedButton>
// 			<StyledMenuItem
// 				id='demo-positioned-menu'
// 				aria-labelledby='demo-positioned-button'
// 				anchorEl={anchorEl}
// 				open={open}
// 				onClose={handleClose}
// 				onClick={handleClose}
// 				anchorOrigin={{
// 					vertical: 'top',
// 					horizontal: props.label === 'Selector' ? 'right' : 'left',
// 				}}
// 				transformOrigin={{
// 					vertical: 'top',
// 					horizontal: 'left',
// 				}}
// 			>
// 				<MenuOptions />
// 			</StyledMenuItem>
// 		</div>
// 	)
// }
export const ActionsButton = (props: {
	children?: any
	width: number
	row: any
	label?: string
	options?: any
	style?: any
	onClick?: any
	arrowColor?: string
	id?: string
	noArrow?: boolean
}) => {
	const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null)
	const [arrowColor, setArrowColor] = useState(props.arrowColor)
	const open = Boolean(anchorEl)

	const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
		setAnchorEl(event.currentTarget)
	}
	const handleClose = () => {
		setAnchorEl(null)
	}

	const MenuOptions = () => {
		if (props.options) {
			return props.options
		}
		return (
			<div>
				{/* <MenuItem onClick={handleClose}>Preview</MenuItem> */}
				<MenuItem key={'menuption_edit1'} onClick={handleClose}>
					Edit
				</MenuItem>
				<MenuItem key={'menuption_delete1'} onClick={handleClose}>
					Delete
				</MenuItem>
			</div>
		)
	}
	const handleHoverArrowColor = (hovered: boolean) => {
		if (hovered) {
			setArrowColor('inherit')
		} else {
			setArrowColor(props.arrowColor)
		}
	}
	return (
		<div style={{ display: 'inherit' }} id={props.id}>
			<StyledButton
				id='basic-button'
				aria-controls={open ? 'basic-menu' : undefined}
				aria-haspopup='true'
				aria-expanded={open ? 'true' : undefined}
				onClick={(e) => {
					handleClick(e)
					//props.onClick()
				}}
				onMouseEnter={() => handleHoverArrowColor(true)}
				onMouseLeave={() => handleHoverArrowColor(false)}
				style={{
					...props.style,
					zIndex: 9,
					background:
						props.label === 'Selector' ||
						props.label === 'settings' ||
						props.label === 'Finance' ||
						props.label === 'Dashboard' ||
						props.label === 'Tools' ||
						props.label === 'Autoswitch' ||
						props.label === 'Standard' ||
						props.label === 'Advanced'
							? 'none'
							: 'rgba(0, 0, 0, 0.02)',
					boxShadow:
						props.label === 'Selector' ||
						props.label === 'settings' ||
						props.label === 'Finance' ||
						props.label === 'Dashboard' ||
						props.label === 'Tools' ||
						props.label === 'Autoswitch' ||
						props.label === 'Standard' ||
						props.label === 'Advanced'
							? 'none'
							: '1px 1px 0px 1px rgba(0,0,0,0.1)',
					justifyContent:
						props.label === 'Selector' || 'Tools' ? 'flex-start' : 'center',
				}}
			>
				{props.children || (props.width > 150 ? props.label : '')}
				{!props.noArrow && (
					<KeyboardArrowDownIcon
						style={{
							fontSize: '26px',
							color: props.arrowColor ? arrowColor : 'inherit',
						}}
					/>
				)}
			</StyledButton>
			<StyledMenuItem
				id='demo-positioned-menu'
				aria-labelledby='demo-positioned-button'
				anchorEl={anchorEl}
				open={open}
				onClose={handleClose}
				anchorOrigin={{
					vertical:
						props.label === 'Tools' ||
						props.label === 'Selector' ||
						props.label === 'Finance' ||
						props.label === 'Advanced' ||
						props.label === 'Advertisers'
							? 'bottom'
							: 'top',
					horizontal: props.label === 'Standard' ? 'right' : 'left',
				}}
				transformOrigin={{
					vertical: 'top',
					horizontal: 'left',
				}}
			>
				<MenuOptions />
			</StyledMenuItem>
		</div>
	)
}

// export const StyledMenuItem = styled(Menu)(({ theme }) => ({
// 	paddingTop: '0px',

// 	'& 	.MuiMenu-list': {
// 		minWidth: '185px',

// 		padding: '0px',
// 	},
// }))

export const InputFormField = (props: {
	label: string
	type: string
	onChange?: any
	required?: boolean
	value?: any
	color?:
		| 'primary'
		| 'secondary'
		| 'error'
		| 'info'
		| 'success'
		| 'warning'
		| undefined
}) => {
	return (
		<TextField
			required={props.required}
			label={props.label}
			type={props.type}
			margin='dense'
			fullWidth
			value={props.value}
			onChange={props.onChange}
			variant='standard'
			color={props.color}
		/>
	)
}

export const StatusSelect = (props: { onChange: any }) => {
	return (
		<FormControl fullWidth variant='standard'>
			<InputLabel required id='demo-simple-select-label'>
				Status
			</InputLabel>
			<Select
				labelId='demo-simple-select-label'
				id='demo-simple-select'
				label='Status'
				onChange={props.onChange}
				defaultValue={''}
			>
				<MenuItem value={'active'}>Active</MenuItem>
				<MenuItem value={'disabled'}>Disabled</MenuItem>
			</Select>
		</FormControl>
	)
}
export const PlusButton = styled(Button)(({ theme }) => ({
	background: theme.colors.activeGradient,
	borderRadius: theme.dimentions.biggerRadius,
	position: 'relative',
	height: '20px',
	width: '20px',
	minWidth: '10px',
	top: '300px',
}))
const StyledButton = styled(Button)(({ theme }) => ({
	flow: 'right',
	position: 'relative',
	margin: '0px',
	width: '100%',
	background: theme.palette.primary.main,
	borderRadius: '6px',
	boxShadow: theme.shadow.lightShadow,
	color: theme.palette.secondary.light,
}))

export const StyledDialog = styled(Dialog)(({ theme }) => ({
	borderRadius: theme.dimentions.smallerRadius,
}))

export const PresetDialog = styled(Dialog)(({ theme }) => ({
	borderRadius: theme.dimentions.smallerRadius,

	'& .MuiDialog-paper': {
		width: '800px',
	},
}))

export const StyledTextField = styled(TextField)<TextFieldProps | any>(
	({ theme, showurlicon }) => {
		const showUrlIconBoolean = showurlicon === 'true'
		return {
			backgroundColor: 'rgba(238, 238, 238, 0.6)',
			height: 'auto',
			fontWeight: '500',
			fontSize: '14px',
			borderRadius: theme.dimentions.smallerRadius,
			minHeight: '40px',
			'& .MuiOutlinedInput-input': {
				backgroundColor: 'transparent',
				borderRadius: theme.dimentions.smallerRadius,
				height: 'auto',
				fontSize: '14px',
				minHeight: '40px',
				fontWeight: '500',
				width: showUrlIconBoolean ? '80%' : '100%',
				transition: 'all 0.2s ease-in-out',
			},
			'& .MuiOutlinedInput-root': {
				backgroundColor: 'transparent',
				borderRadius: theme.dimentions.smallerRadius,
				height: 'auto',
				minHeight: '40px',
				paddingLeft: '8px',
			},
		}
	},
)

export const PopperMy = (props: any) => {
	return <Popper {...props} style={{ width: '400px' }} placement='top-start' />
}

export const EventNameAutocomplete = (props: {
	onChange: any
	onBlur?: any
	label?: string
	options: string[]
	disabled?: boolean
	value?: string[] | string
	float?: 'left' | 'right'
	fullWidth?: boolean
	name?: string
	ref?: any
	id?: string
}) => {
	return (
		<StyledAutocomplete
			freeSolo
			disableCloseOnSelect
			disabled={props.disabled}
			options={props.options}
			id={props.id ? props.id : 'tags-filled'}
			value={props.value}
			PopperComponent={PopperMy}
			ListboxProps={{
				style: {
					fontSize: '11px',
				},
			}}
			onChange={(a: any, n: any) => props.onChange(n)}
			onBlur={props.onBlur}
			renderInput={(params: any) => (
				<StyledTextField
					{...params}
					focused
					name={props.name}
					fullWidth
					ref={props.ref}
					variant='outlined'
					style={{
						float: props.float,
						fontSize: '11px',
						maxHeight: '40px',
						marginTop: '2px',
						background: props.value
							? theme.colors.base.white
							: theme.colors.base.grey100,
						opacity: props.value ? 1 : 0.4,
						color: theme.colors.base.grey300,
					}}
					onKeyDown={(e: any) => {
						props.onChange(e.target.value)
					}}
					onKeyUp={(e: any) => {
						props.onChange(e.target.value)
					}}
					InputProps={{
						...params.InputProps,
						style: {
							fontSize: '11px',
							paddingLeft: '10px',
						},
					}}
				/>
			)}
		/>
	)
}

// export const PidInputList = (props: {
// 	onChange?: any
// 	label?: string
// 	options: any[]
// 	hint?: string
// 	hintmargin?: string
// 	disabled?: boolean
// 	publishers?: any
// 	value?: string[] | string
// 	float?: 'left' | 'right'
// 	fullWidth?: boolean
// 	multiple?: boolean
// 	singular?: boolean
// 	checked?: boolean
// 	changePublisher?: any
// 	style?: React.CSSProperties
// }) => {
// 	return (
// 		<StyledAutocomplete
// 			multiple={props.multiple ? false : true}
// 			freeSolo={!props.singular}
// 			disabled={props.disabled}
// 			options={props.options}
// 			style={props.style}
// 			id='tags-filled'
// 			value={props.value}
// 			onChange={(a: any, n: any) => {
// 				props.onChange(n)
// 			}}
// 			renderTags={(value: any[], getTagProps: any) => {
// 				const chips = value.map((option: any, index: number) => {
// 					return (
// 						<Chip
// 							icon={
// 								props.checked ? (
// 									<StyledCheckboxFormControl
// 										label={option}
// 										control={
// 											<Checkbox
// 												key={'timeframe'}
// 												inputProps={{
// 													'aria-label': 'controlled',
// 												}}
// 												style={{ marginLeft: '0px', color: '#ee1d4a' }}
// 											/>
// 										}
// 									/>
// 								) : (
// 									'unset'
// 								)
// 							}
// 							variant='outlined'
// 							label={
// 								<PublishersList value={props.publishers} disabled={false} />
// 							}
// 							deleteIcon={<ClearIcon />}
// 							{...getTagProps({ index })}
// 						/>
// 					)
// 				})

// 				return chips
// 			}}
// 			renderInput={(params: any) => (
// 				<>
// 					{props.hint ? <InfoTag title={props.hint} /> : null}
// 					<StyledTextField
// 						{...params}
// 						focused
// 						label={props.label}
// 						margin='dense'
// 						fullWidth
// 						variant='outlined'
// 						style={{
// 							float: props.float,
// 						}}
// 						InputProps={{
// 							...params.InputProps,
// 							style: {
// 								paddingLeft: '10px',
// 								height: '100px',
// 							},
// 						}}
// 						InputLabelProps={{
// 							style: { top: '-20%' },
// 						}}
// 					/>
// 				</>
// 			)}
// 		/>
// 	)
// }
export const CountryListSelector = (props: {
	onChange?: any
	label?: string
	options?: any[]
	disabled?: boolean
	value?: string[]
	fullWidth?: boolean
	multiple?: boolean
	singular?: boolean
	float?: 'left' | 'right'
	style?: React.CSSProperties
	onBlur?: any
	loginRole?: string
	chipStyle?: React.CSSProperties
}) => {
	const [open, setOpen] = useState(false)
	const StyleForSelectedOption = (value: string, list: string[]) => {
		if (
			(list.length === 0 && value === 'GLOBAL') ||
			list.indexOf(value.substring(0, 2)) !== -1 ||
			list.indexOf(value.substring(0, 2).toLowerCase()) !== -1
		) {
			return {
				fontWeight: '900',
				backgroundColor: 'rgba(238,238,238,0.7)',
				width: '100%',
			}
		}
	}
	const selectedOptions: string[] = []
	const notSelectedOptions: string[] = []
	props.options!.forEach((el) => {
		if (
			props.value?.includes(el.substring(0, 2)) ||
			props.value?.includes(el.substring(0, 2).toLowerCase())
		) {
			selectedOptions.push(el)
		} else {
			notSelectedOptions.push(el)
		}
	})
	const orderedOptions = [...selectedOptions, 'GLOBAL', ...notSelectedOptions]

	const renderValueIfEmpty = (values: string[]) => {
		if (values?.length === 0 || (values?.length === 1 && values[0] === '')) {
			return ['GLOBAL']
		}
		return values.map((el: string) => el.toUpperCase())
	}
	const optionsCovertor = (options: string) => {
		let option
		if (options === 'GLOBAL') option = options
		else option = options.length > 2 ? options.substring(0, 2) : options
		const checkedOptions = option === 'UK' ? 'GB' : option
		return checkedOptions
	}
	const renderOptionsWhenOpening = (propsd: any, options: any) => {
		const checkedOption = optionsCovertor(options)
		return checkedOption !== 'GLOBAL' ? (
			<li {...propsd} key={propsd.id}>
				<ReactCountryFlag
					style={{
						width: '2em',
						height: '2em',
						borderRadius: '30px',
						display: 'block',
					}}
					key={options + '_flags'}
					countryCode={checkedOption}
					svg
				/>
				<MenuItem
					key={options}
					value={options}
					style={StyleForSelectedOption(options, props.value || [])}
				>
					{options}
				</MenuItem>
			</li>
		) : (
			<></>
		)
	}
	const renderSelectedOptions = (value: any[], getTagProps: any) => {
		const chips: any = value.map((options: any, index: number) => {
			const checkedOption = optionsCovertor(options)
			if (!open) {
				if (index <= 5) {
					return (
						<React.Fragment key={index + '-fragment-option'}>
							{index !== 5 ? (
								<Chip
									style={props.chipStyle}
									icon={
										checkedOption !== 'GLOBAL' ? (
											<ReactCountryFlag
												style={{
													width: '2em',
													height: '2em',
													borderRadius: '30px',
												}}
												countryCode={checkedOption}
												svg
											/>
										) : (
											<Public />
										)
									}
									variant='outlined'
									label={options}
									deleteIcon={
										// checkedOption !== 'GLOBAL' ? <ClearIcon /> : <></>
										<ClearIcon />
									}
									{...getTagProps({ index })}
								/>
							) : (
								<Chip
									style={{ marginLeft: '18px' }}
									onClick={() => {
										setOpen(!open)
									}}
									variant='outlined'
									label={` + ${(value?.length || 5) - 5}`}
								/>
							)}
						</React.Fragment>
					)
				}
			} else {
				return (
					<Chip
						icon={
							checkedOption !== 'GLOBAL' ? (
								<ReactCountryFlag
									style={{
										width: '2em',
										height: '2em',
										borderRadius: '30px',
									}}
									countryCode={checkedOption}
									svg
								/>
							) : (
								<Public />
							)
						}
						variant='outlined'
						label={options}
						// deleteIcon={checkedOption !== 'GLOBAL' ? <ClearIcon /> : <></>}
						deleteIcon={<ClearIcon />}
						{...getTagProps({ index })}
					/>
				)
			}
		})
		return chips
	}
	const renderInputField = (params: any) => (
		<>
			<StyledInputLabel>{props.label}</StyledInputLabel>
			<StyledTextField
				{...params}
				focused
				label={undefined}
				margin='dense'
				fullWidth
				key={params + 'key-input'}
				variant='outlined'
				style={{
					float: props.float,
				}}
				InputProps={{
					...params.InputProps,
					style: {
						paddingLeft: '10px',
					},
				}}
				onClick={() => !props.disabled && setOpen(!open)}
			/>
		</>
	)
	const customFilter = (options: any, state: any) => {
		const geoCodeResult =
			state.inputValue.length <= 2
				? options.filter((el: any) => {
						return el
							.substring(0, 2)
							.toLowerCase()
							.includes(state.inputValue.toLowerCase())
				  })
				: []
		const countryResult = options.filter((el: any) => {
			return (
				el.toLowerCase().includes(state.inputValue.toLowerCase()) &&
				el.substring(0, 2).toLowerCase() !== state.inputValue.toLowerCase()
			)
		})
		return [...geoCodeResult, ...countryResult]
	}

	return (
		<StyledAutocomplete
			multiple={props.multiple ? false : true}
			freeSolo={!props.singular}
			disabled={props.disabled}
			open={open}
			options={orderedOptions || []}
			style={props.style}
			id='tags-filled'
			value={renderValueIfEmpty(props.value as string[])}
			onChange={(a: any, n: any) => {
				if (n[0] === 'GLOBAL') n.shift()
				if (n.includes('GLOBAL')) props.onChange([])
				else {
					const result = n.map((el: string) => {
						return el.substring(0, 2) === 'GB' ? 'UK' : el.substring(0, 2)
					})
					props.onChange(result)
				}
			}}
			isOptionEqualToValue={(option: any, value: any) => {
				if (value === 'GLOBAL') return option === value
				return option.substring(0, 2) === value.substring(0, 2)
			}}
			onBlur={() => {
				setOpen(false)
				return props.onBlur
			}}
			filterOptions={customFilter}
			renderOption={renderOptionsWhenOpening}
			renderTags={renderSelectedOptions}
			renderInput={renderInputField}
		/>
	)
}

export const StaticChipInputList = (props: {
	onChange?: any
	label?: string
	options?: any[]
	hint?: string
	hintmargin?: string
	disabled?: boolean
	value?: string[]
	float?: 'left' | 'right'
	fullWidth?: boolean
	multiple?: boolean
	singular?: boolean
	style?: React.CSSProperties
	onBlur?: any
	seeAll?: boolean
	loginRole?: string
	openWithClick?: boolean
	setMainHeightChange?: any
	onlyOneOptionAllowed?: boolean
	ShowSelected?: boolean
	onlyOptionsAllowed?: boolean
	placeholder?: string
}) => {
	const ref = useRef()
	const [open, setOpen] = useState(false)

	let gotCurrentHeight = false
	const getCurrentHeight = () => {
		const { current }: any = ref
		if (current?.clientHeight) {
			if (!gotCurrentHeight) {
				requestAnimationFrame(getCurrentHeight)
			}
			gotCurrentHeight = true
			//2.0 is hardcoded. It's the number of max px/ this element px that looks optimal to messure if the main buttons need to move.
			if (2.0 > window.innerHeight / current.clientHeight) {
				props.setMainHeightChange !== undefined &&
					props.setMainHeightChange(true)
			}
		}
	}
	getCurrentHeight()
	const StyleForSelectedOption = (value: string, list: string[]) => {
		if (list.indexOf(value) !== -1) {
			return {
				fontWeight: '900',
				backgroundColor: 'rgba(238,238,238,0.7)',
				width: '100%',
			}
		}
	}
	const selectedOptions: string[] = []
	const notSelectedOptions: string[] = []
	props.options!.forEach((el) => {
		if (props.value?.includes(el)) {
			selectedOptions.push(el)
		} else {
			notSelectedOptions.push(el)
		}
	})
	const orderedOptions = [...selectedOptions, ...notSelectedOptions]
	props.seeAll && orderedOptions.push('All')

	const renderOptionsWhenOpening = (propsd: any, option: any) => {
		// if (props.seeAll && option === 'All') {
		// 	return null
		// }
		return (
			<div {...propsd} key={propsd.id}>
				<MenuItem
					key={option}
					value={option}
					style={StyleForSelectedOption(option, props.value ? props.value : [])}
				>
					{option}
				</MenuItem>
			</div>
		)
	}
	const renderInputField = (params: any) => {
		return (
			<>
				<StyledTextField
					{...params}
					focused
					label={props.label}
					margin='dense'
					fullWidth
					key={params + 'key-input'}
					variant='outlined'
					style={{
						border: 0,
						clip: 'rect(0 0 0 0)',
						height: '1px',
						margin: '-1px',
						overflow: 'hidden',
						padding: 0,
						position: 'absolute',
						width: '130px',
						whiteSpace: 'nowrap',
						float: props.float,
					}}
					InputProps={{
						...params.InputProps,
						style: {
							paddingLeft: '10px',
						},
					}}
					InputLabelProps={{
						style: { top: '-10px' },
					}}
					onClick={() => {
						if (props.openWithClick && !props.disabled) {
							setOpen(!open)
						}
					}}
					placeholder={props.placeholder}
				/>
				<AddButton
					style={{ minWidth: '100px' }}
					onClick={() => {
						if (props.openWithClick && !props.disabled) {
							setOpen(!open)
						}
					}}
				>
					<span>{props.placeholder}</span>{' '}
					{open ? (
						<KeyboardArrowUpIcon style={{ scale: '1', color: 'inherit' }} />
					) : (
						<KeyboardArrowDownIcon style={{ scale: '1', color: 'inherit' }} />
					)}
				</AddButton>
				<button
					style={{
						border: 'none',
						borderRadius: '60px',
						height: '24px',
						width: '24px',
						fontSize: '12px',
						display: 'flex',
						alignItems: 'center',
						background: theme.colors.base.grey300,
						cursor: props.value?.length === 0 ? 'not-allowed' : 'pointer',
					}}
					onClick={() => {
						props.onChange([])
					}}
					disabled={props.value && props.value.length === 0}
				>
					<FilterAltOffIcon fontSize={'inherit'} />
				</button>
			</>
		)
	}
	function compareArrays(array1: string[], array2: string[]) {
		for (let i = 0; i < array1.length; i++) {
			if (!array2.includes(array1[i])) {
				return false
			}
		}
		return true
	}

	return (
		<StyledAutocomplete
			ref={props.setMainHeightChange !== undefined ? ref : undefined}
			multiple={props.multiple ? false : true}
			freeSolo={!props.singular}
			disabled={props.disabled}
			open={
				props.seeAll && props.value!.length === props.options!.length
					? false
					: open
			}
			options={orderedOptions}
			style={props.style}
			id='tags-filled'
			value={
				props.seeAll
					? props.value &&
					  props.value.length > 0 &&
					  props.value!.length === props.options!.length
						? ['All']
						: props.value || []
					: props.value && props.value.length === 1 && props.value[0] === ''
					? []
					: props.value
			}
			onChange={(a: any, n: any) => {
				if (props.onlyOneOptionAllowed) {
					n.reverse()
					n[0] ? props.onChange([n[0]]) : props.onChange([])
				}
				if (props.onlyOptionsAllowed) {
					if (compareArrays(n, props.options as string[])) {
						props.onChange(n)
					} else return null
				} else props.onChange(n)
			}}
			isOptionEqualToValue={(option: any, value: any) => {
				return option === value ? true : false
			}}
			onBlur={() => {
				setOpen(false)
				props.setMainHeightChange !== undefined &&
					props.setMainHeightChange(false)
				return props.onBlur
			}}
			renderOption={renderOptionsWhenOpening}
			renderTags={() => <></>}
			renderInput={renderInputField}
		/>
	)
}

export const ChipInputList = (props: {
	onChange?: any
	label?: string
	options?: any[]
	hint?: string
	disabled?: boolean
	value?: string[]
	float?: 'left' | 'right'
	multiple?: boolean
	singular?: boolean
	style?: React.CSSProperties
	onBlur?: any
	seeAll?: boolean
	loginRole?: string
	openWithClick?: boolean
	setMainHeightChange?: any
	onlyOneOptionAllowed?: boolean
	ShowSelected?: boolean
	onlyOptionsAllowed?: boolean
	placeholder?: string
	clickableFunction?: (option: any, ev: React.MouseEvent) => void
	errored?: boolean
	errormessage?: any
	inputStyle?: any
	anchorId?: string
	chipStyle?: React.CSSProperties & { hover?: React.CSSProperties }
}) => {
	const ref = useRef()
	const [open, setOpen] = useState(false)
	const [anchorEl, setAnchorEl] = useState<any>('')

	useEffect(() => {
		if (props.anchorId) {
			setAnchorEl(document.getElementById(props.anchorId))
		}
	}, [])

	let gotCurrentHeight = false
	const getCurrentHeight = () => {
		const { current }: any = ref
		if (current?.clientHeight) {
			if (!gotCurrentHeight) {
				requestAnimationFrame(getCurrentHeight)
			}
			gotCurrentHeight = true
			//2.0 is hardcoded. It's the number of max px/ this element px that looks optimal to messure if the main buttons need to move.
			if (2.0 > window.innerHeight / current.clientHeight) {
				props.setMainHeightChange !== undefined &&
					props.setMainHeightChange(true)
			}
		}
	}
	getCurrentHeight()
	const StyleForSelectedOption = (value: string, list: string[]): any => {
		if (list.indexOf(value) !== -1) {
			return {
				fontWeight: '900',
				backgroundColor: 'rgba(238,238,238,0.7)',
				width: '100%',
				position: 'relative',
			}
		}
	}

	const errorStyling = () => {
		if (props.errormessage) {
			return {
				boxShadow: 'red 0px 0px 7px',
			}
		}
	}
	const selectedOptions: string[] = []
	const notSelectedOptions: string[] = []
	props.options!.forEach((el) => {
		if (props.value?.includes(el)) {
			selectedOptions.push(el)
		} else {
			notSelectedOptions.push(el)
		}
	})
	const orderedOptions = [...selectedOptions, ...notSelectedOptions]
	props.seeAll && orderedOptions.push('All')

	const renderOptionsWhenOpening = (propsd: any, option: any) => {
		return (
			<div {...propsd} key={propsd.id}>
				<MenuItem
					key={option}
					value={option}
					style={StyleForSelectedOption(option, props.value ? props.value : [])}
				>
					{option}
				</MenuItem>
			</div>
		)
	}

	const renderSelectedOptions = (value: any[], getTagProps: any) => {
		const chips: any = value.map((option: any, index: number) => {
			const CustomLabel = (props: any) => {
				const { children } = props
				return (
					<div
						style={{
							whiteSpace: 'normal',
							overflow: 'auto',
							textOverflow: 'ellipsis',
							paddingLeft: '2px',
							paddingRight: '2px',
						}}
					>
						{children}
					</div>
				)
			}

			return (
				<Chip
					variant='outlined'
					onClick={(ev: React.MouseEvent) => {
						if (props.clickableFunction) {
							props.clickableFunction(option, ev)
						}
					}}
					label={
						props.ShowSelected ? <CustomLabel>{option}</CustomLabel> : option
					}
					deleteIcon={<ClearIcon />}
					style={{
						width: 'auto',
						minWidth: props.ShowSelected ? '100px' : '0px',
						height: 'auto',
						...props.chipStyle,
						transition: 'background-color 0.3s ease',
					}}
					{...getTagProps({ index })}
					onMouseEnter={(e: any) => {
						if (props.chipStyle?.hover) {
							e.currentTarget.style.backgroundColor =
								props.chipStyle.hover.backgroundColor
						}
					}}
					onMouseLeave={(e: any) => {
						e.currentTarget.style.backgroundColor =
							props.chipStyle?.background || '#fff'
					}}
				/>
			)
		})

		return chips
	}

	const CustomPopper = function (props: any) {
		return (
			<Popper
				{...props}
				disablePortal
				anchorEl={anchorEl ? anchorEl : props.anchorEl}
				style={{ width: 'auto', backgroundColor: '#fff' }}
				placement='bottom-start'
			/>
		)
	}
	const renderInputField = (params: any) => (
		<>
			<div
				style={{
					display: 'flex',
					flexDirection: 'row',
					gap: '20px',
				}}
			>
				<StyledInputLabel>{props.label}</StyledInputLabel>
				{props.hint ? <InfoTag title={props.hint} /> : null}
			</div>
			<StyledTextField
				{...params}
				focused
				label={undefined}
				margin='dense'
				key={params + 'key-input'}
				variant='outlined'
				style={{
					float: props.float,
					margin: '0px',
					// backgroundColor: props.errored ? '#eeafaf' : 'rgba(238,238,238,0.6)',
					boxShadow: props.errored ? 'red 0px 0px 7px' : 'none',
					...errorStyling(),
					...props.inputStyle,
				}}
				InputProps={{
					...params.InputProps,
					style: {
						...props.inputStyle,
						paddingLeft: '10px',
						backgroundColor: '#fff',
					},
				}}
				// InputLabelProps={{
				// 	style: { top: '-10px' },
				// }}
				onClick={() => {
					if (props.openWithClick && !props.disabled) {
						setOpen(!open)
					}
				}}
				placeholder={props.placeholder}
			/>
			{props.errormessage ? <ErrorTag title={props.errormessage} /> : null}
		</>
	)
	function compareArrays(array1: string[], array2: string[]) {
		for (let i = 0; i < array1.length; i++) {
			if (!array2.includes(array1[i])) {
				return false
			}
		}
		return true
	}

	return (
		<StyledAutocomplete
			ref={props.setMainHeightChange !== undefined ? ref : undefined}
			multiple={props.multiple ? false : true}
			freeSolo={!props.singular}
			disabled={props.disabled}
			open={
				props.seeAll &&
				props.value &&
				props.options &&
				props.value.length === props.options.length
					? false
					: open
			}
			options={orderedOptions}
			style={props.style}
			id={'tags-filled'}
			value={
				props.seeAll
					? props.value &&
					  props.value.length > 0 &&
					  props.value.length === (props.options?.length || 0)
						? ['All']
						: props.value || []
					: props.value && props.value.length === 1 && props.value[0] === ''
					? []
					: props.value
			}
			onChange={(a: any, n: any) => {
				if (props.onlyOneOptionAllowed) {
					n.reverse()
					n[0] ? props.onChange([n[0]]) : props.onChange([])
				}
				if (props.onlyOptionsAllowed) {
					if (compareArrays(n, props.options as string[])) {
						props.onChange(n)
					} else return null
				} else props.onChange(n)
			}}
			isOptionEqualToValue={(option: any, value: any) => {
				return option === value ? true : false
			}}
			onBlur={() => {
				setOpen(false)
				props.setMainHeightChange !== undefined &&
					props.setMainHeightChange(false)
				return props.onBlur
			}}
			renderOption={renderOptionsWhenOpening}
			renderTags={renderSelectedOptions}
			renderInput={renderInputField}
			PopperComponent={CustomPopper}
		/>
	)
}

const StyledChipTextField = styled(TextField)`
	.MuiOutlinedInput-root {
		background-color: #fff;
		display: flex;
		align-items: center;
		height: 50px;
		padding: 0.2rem;
	}
`

const StyledChipInputLabel = styled(InputLabel)`
	font-weight: 500;
	font-size: ${theme.font.size.caption};
	margin-left: 0.5rem;
	margin-bottom: 0.3rem;
	background-color: #fff;
`

export const ChipInputField = ({
	label,
	value,
	onChange,
	options,
	style,
	inputProps,
	placeholder,
	chipStyle,
}: {
	label: string
	value: string[]
	onChange: (value: string[]) => void
	options: any[]
	style?: React.CSSProperties
	inputProps?: any
	placeholder?: string
	chipStyle?: React.CSSProperties & { hover?: React.CSSProperties }
}) => {
	const [inputValue, setInputValue] = useState('')
	const [focused, setFocused] = useState(false)
	const [hovered, setHovered] = useState(false)

	const responsiveStyle = () => {
		const style: any = {
			border: '1px solid ' + theme.colors.base.grey300,
			borderRight: '6px solid ' + theme.colors.base.grey300,
			borderRadius: '5px',
		}
		if (focused) {
			style.borderRight = '6px solid ' + theme.colors.base.grey300
		}
		if (hovered) {
			style.borderRight = '6px solid ' + theme.colors.base.green200
		}

		return style
	}

	const handleKeyDown = (event: React.KeyboardEvent<HTMLDivElement>) => {
		if (event.key === 'Enter' && inputValue.trim()) {
			onChange([...value, inputValue.trim()])
			setInputValue('')
			event.preventDefault()
		}
	}

	return (
		<div
			style={{
				display: 'flex',
				flexDirection: 'column',
				width: '100%',
				...style,
			}}
		>
			<StyledChipInputLabel>{label}</StyledChipInputLabel>
			<StyledChipTextField
				variant='outlined'
				fullWidth
				value={inputValue}
				onChange={(e) => setInputValue(e.target.value)}
				onKeyDown={handleKeyDown}
				onFocus={() => setFocused(true)}
				onBlur={() => setFocused(false)}
				onMouseEnter={() => setHovered(true)}
				onMouseLeave={() => setHovered(false)}
				InputProps={{
					...inputProps,
					startAdornment: (
						<InputAdornment position='start'>
							<div
								style={{
									display: 'flex',
									alignItems: 'center',
									flexWrap: 'nowrap',
									gap: '4px',
									overflowX: 'auto',
									whiteSpace: 'nowrap',
								}}
							>
								{value.map((chip, index) => (
									<Chip
										key={index}
										label={chip}
										onDelete={() => onChange(value.filter((v) => v !== chip))}
										style={{
											...chipStyle,
											display: 'inline-flex',
											transition: 'background-color 0.3s ease',
										}}
										onMouseEnter={(e: any) => {
											if (chipStyle?.hover) {
												e.currentTarget.style.backgroundColor =
													chipStyle.hover.backgroundColor
											}
										}}
										onMouseLeave={(e: any) => {
											e.currentTarget.style.backgroundColor =
												chipStyle?.backgroundColor || '#fff'
										}}
									/>
								))}
							</div>
						</InputAdornment>
					),
				}}
				placeholder={placeholder}
				style={{ ...responsiveStyle() }}
			/>
		</div>
	)
}

export const StyledFormControl = (
	props: FormControlProps & { children: any; fullWidth?: boolean },
) => {
	const StyledForm = styled(FormControl)(({ theme }) => ({
		width: props.fullWidth ? '100%' : '45%',
		marginTop: '20px',
	}))
	return (
		<StyledForm focused {...props}>
			{props.children}
		</StyledForm>
	)
}

export const OwnerSelect = (props: {
	onChange: any
	disabled?: boolean
	float?: 'right' | 'left'
	label: string
	value: any
	fontSize?: string
	multiple?: boolean
}) => {
	const { users } = useTypedSelector((state) => state)
	const usersList: JSX.Element[] = users.users.map(
		(user: UserInterface) => user.name,
	)
	const getNameFromUserEmail = (email: string) => {
		const user = users.users.filter((el: UserInterface) => el.email === email)
		if (user[0]) {
			return user[0].name
		}
	}
	const handleChange = (event: any, value: any) => {
		const user = users.users.filter((el: UserInterface) => el.name === value)
		props.onChange(user[0].email)
	}

	return (
		<div style={{ float: props.float, marginTop: '0px' }}>
			<StyledInputLabel>{props.label}</StyledInputLabel>
			<Autocomplete
				style={{
					minHeight: '40px',
					width: '100%',
					background: 'rgb(243,243,243)',
					borderRadius: '6px',
				}}
				sx={{
					'& .MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline': {
						border: 'none',
						padding: '0px',
					},
					'& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline': {
						border: 'none',
						padding: '0px',
					},
					'& .MuiOutlinedInput-root .MuiAutocomplete-input': {
						minHeight: '40px',
						padding: '0px',
					},
				}}
				options={usersList}
				renderInput={(params) => (
					<TextField
						{...params}
						style={{ height: 'inherit' }}
						placeholder={props.label}
					/>
				)}
				onChange={handleChange}
				value={getNameFromUserEmail(props.value)}
			/>
		</div>
	)
}

export const StyledTextAreaField = (props: {
	label: string
	value: string
	onChange: any
}) => {
	return (
		<TextField
			label={props.label}
			value={props.value}
			key={props.label + 'textarea'}
			onChange={(e) => props.onChange(e.target.value)}
			fullWidth
			variant='outlined'
			multiline
			focused
			rows={6}
			InputLabelProps={{ shrink: true, style: { marginTop: '-12px' } }}
			inputProps={{
				style: {
					padding: '20px',
				},
			}}
			color={'primary'}
			style={{
				marginTop: '12px',
				borderRadius: '8px',
				backgroundColor: 'rgb(243,243,243)',
			}}
		/>
	)
}
export const StyledNameField = (props: {
	label: string
	type: string
	value: string | number | null | undefined
	onChange?: any
	onBlur?: any
	onFocus?: any
	disabled?: boolean
	keyname?: string
	hint?: any
	float?: 'right' | 'left'
	errormessage?: any
	warning?: any
	checked?: boolean
	multiline?: boolean
	style?: any
	customInputLabel?: string
	color?:
		| 'primary'
		| 'secondary'
		| 'error'
		| 'info'
		| 'success'
		| 'warning'
		| undefined
	showUrlIcon?: boolean
	inputProps?: any
	placeholder?: string
	noLabelSpace?: boolean
	sx?: any
	fullWidth?: boolean
}) => {
	const [showPassword, setShowPassword] = useState(false)
	const handleClickShowPassword = () => setShowPassword(!showPassword)
	const handleMouseDownPassword = () => setShowPassword(!showPassword)
	const handleEndAdornment = (type: string) => {
		if (type === 'password') {
			return (
				<InputAdornment position='end'>
					<IconButton
						aria-label='toggle password visibility'
						onClick={handleClickShowPassword}
						onMouseDown={handleMouseDownPassword}
					>
						{showPassword ? <Visibility /> : <VisibilityOff />}
					</IconButton>
				</InputAdornment>
			)
		}
		if (type === 'custom') {
			return (
				<InputAdornment position='start'>
					{props.customInputLabel}
				</InputAdornment>
			)
		} else {
			return <></>
		}
	}

	const errorStyling = () => {
		if (props.errormessage) {
			return {
				backgroundColor: '#ffcdd2',
				boxShadow: '0px 0px 0px 1px #d32f2f',
				color: '#d32f2f',
			}
		}
	}
	const { showUrlIcon, label, ...otherProps } = props
	return (
		<div
			style={{
				display: 'flex',
				position: 'relative',
				width: props.fullWidth ? '100%' : '',
				flexDirection: 'column',
			}}
		>
			{!props.noLabelSpace && (
				<div style={{ display: 'flex', gap: '20px' }}>
					<StyledInputLabel>{props.label}</StyledInputLabel>
					{props.showUrlIcon && props.value && !props.errormessage && (
						<button
							onClick={() => {
								const newTab: any = window.open(props.value as string, '_blank')
								newTab.focus()
							}}
							style={{
								position: 'absolute',
								right: '4%',
								top: '43%',
								border: 'none',
								cursor: 'pointer',
								zIndex: '2',
							}}
						>
							<LinkIcon></LinkIcon>
						</button>
					)}
					{props.hint ? (
						<div
							style={{
								position: 'relative',
								width: '20%',
							}}
						>
							<InfoTag title={props.hint} />
						</div>
					) : null}
					{props.errormessage ? <ErrorTag title={props.errormessage} /> : null}
					{props.warning ? (
						<ErrorTag title={props.warning} color={'rgb(237,108,4)'} />
					) : null}
				</div>
			)}
			<StyledTextField
				multiline={props.multiline}
				focused
				{...otherProps}
				key={props.keyname ? props.keyname : 'InputName' + props.label}
				margin='dense'
				type={props.type === 'password' && showPassword ? 'text' : props.type}
				value={props.value}
				fullWidth
				onFocus={props.onFocus}
				onBlur={props.onBlur}
				style={{
					...props.style,
					...errorStyling(),
					//  marginTop: '22px'
				}}
				onChange={(e: any) => {
					if (props.value === 'All') {
						const newState = e.target.value.slice(4)
						props.onChange(newState)
					} else {
						props.onChange(e.target.value)
					}
				}}
				variant='outlined'
				color={
					props.color ? props.color : props.checked ? 'success' : 'primary'
				}
				placeholder={props.placeholder}
				InputProps={{
					// <-- This is where the toggle button is added.
					...props.inputProps,
					endAdornment: props.customInputLabel
						? handleEndAdornment('custom')
						: handleEndAdornment(props.type),
				}}
				showurlicon={
					props.showUrlIcon ? props.showUrlIcon.toString() : undefined
				}
				sx={props.sx}
			/>
		</div>
	)
}

// export const TableSearchComponent = (props: {
// 	setSearch: any
// 	search: string
// 	style?: any
// 	filterHandler?: any
// 	searchWithTimer?: boolean
// 	presetSearch?: string
// 	placeholder?: string
// }) => {
// 	const [value, setValue] = useState('')
// 	// const [value, setValue] = useState(props.search)
// 	const timer = useRef<any>(null)

// 	const handleSearch = (e: any) => {
// 		setValue(e.target.value)
// 	}

// 	useEffect(() => {
// 		setValue(props.search)
// 	}, [props.search])

// 	useEffect(() => {
// 		// Clear any existing timer each time 'value' changes
// 		if (timer.current) {
// 			clearTimeout(timer.current)
// 		}

// 		// Set a new timer
// 		timer.current = setTimeout(() => {
// 			// if (!props.presetSearch) {
// 			props.setSearch(value)
// 			// }
// 		}, 200)

// 		// Clear the timer if the component is unmounted
// 		return () => {
// 			if (timer.current) {
// 				clearTimeout(timer.current)
// 			}
// 		}
// 	}, [value])

// 	//TODO: try with autofocus true
// 	return (
// 		<TableSearch style={{ ...props.style }}>
// 			<SearchIconWrapper>
// 				{/* <SearchIcon /> */}
// 				<img
// 					src={search}
// 					alt={'search-icon'}
// 					style={{
// 						zIndex: '10',
// 						width: '1rem',
// 						height: '1rem',
// 					}}
// 				/>
// 			</SearchIconWrapper>
// 			<StyledInputBase
// 				placeholder={
// 					props.placeholder ? `Search in ${props.placeholder}...` : 'Search...'
// 				}
// 				inputProps={{
// 					'aria-label': 'search',
// 				}}
// 				autoFocus={!props.filterHandler}
// 				onChange={(e: any) => {
// 					handleSearch(e)
// 					// props.searchWithTimer
// 					// 	? handleSearch(e)
// 					// 	: props.setSearch(e.target.value)
// 				}}
// 				// value={props.searchWithTimer ? value : props.search}
// 				value={value}
// 				style={{ width: '100%' }}
// 				disabled={!props.placeholder}
// 			/>
// 		</TableSearch>
// 	)
// }
export const FilterSearchComponent = (props: {
	setSearch: any
	search: string[]
	style?: any
	applyFilter?: any
	filterLabel?: any
	currentFilters?: any
	clearFilter?: any
	optionsFilters?: any
	setFilterHander?: any
	filtersKey?: any
	customFilter?: any
}) => {
	let options: any = []
	for (const key in props.optionsFilters) {
		if (key === props.currentFilters) {
			if (typeof [...props.optionsFilters[key]][0] === 'string') {
				options = [...props.optionsFilters[key]].sort()
			} else if (
				typeof [...props.optionsFilters[key]][0] === 'object' &&
				!Array.isArray([...props.optionsFilters[key]][0])
			) {
				for (const element of props.optionsFilters[key]) {
					if (key === 'email' || key === 'publisher_manager') {
						options.push(element.name)
					} else {
						options.push(element[key])
					}
				}
				options.sort()
				// console.log(props.optionsFilters[key])
			}
			// if (options)
			break
		}
	}
	// const StyleForSelectedOption = (value: string, list: string[]) => {
	// 	if (list.indexOf(value) !== -1) {
	// 		return { fontWeight: '900', backgroundColor: 'rgba(238,238,238,0.7)' }
	// 	}
	// }
	// const renderedOptions = (opt: any) => {
	// 	return (
	// 		<MenuItem
	// 			key={opt.id}
	// 			value={opt.key}
	// 			onClick={() => {
	// 				props.setSearch((prev: any) =>
	// 					prev ? [...prev, opt.key] : [opt.key],
	// 				)
	// 			}}
	// 			style={StyleForSelectedOption(opt.key, props.search)}
	// 		>
	// 			{opt.key}
	// 		</MenuItem>
	// 	)
	// }

	useEffect(() => {
		if (props.customFilter) {
			props.setSearch(props.customFilter[props.filtersKey])
		}
	}, [props.currentFilters])
	useEffect(() => {
		if (props.customFilter) {
			props.setSearch(props.customFilter[props.filtersKey])
		} else {
			props.setSearch([])
		}
	}, [])

	return (
		<>
			<div
				style={{
					display: 'flex',
					alignItems: 'center',
					justifyContent: 'center',
					height: 'inherit',
					width: '100%',
				}}
			>
				{options.length === 0 ? (
					<StyledInputBase
						placeholder={'Filter for ' + props.filterLabel}
						inputProps={{
							'aria-label': 'search',
						}}
						autoFocus
						onChange={(e: any) => {
							props.setSearch(e.target.value)
						}}
						value={props.search}
						onKeyDown={(e) => {
							if (e.key === 'Enter') {
								props.applyFilter()
								props.setFilterHander(false)
							}
						}}
					/>
				) : (
					<ChipInputList
						onChange={props.setSearch}
						options={options}
						value={props.search}
						openWithClick={true}
						style={
							{
								height: 'inherit',
								display: 'flex',
								alignItems: 'center',
								justifyContent: 'center',
								margin: '8px',
								width: 'inherit',
								// marginTop: '-25px',
							} as any
						}
						onlyOptionsAllowed
					/>
					// <Autocomplete
					// 	style={{
					// 		height: '50px',
					// 	}}
					// 	sx={{
					// 		'& .MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline':
					// 			{
					// 				border: 'none',
					// 			},
					// 		'& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline': {
					// 			border: 'none',
					// 		},
					// 	}}
					// 	options={options}
					// 	renderOption={(option: any) => {
					// 		return renderedOptions(option)
					// 	}}
					// 	renderInput={(params) => (
					// 		<TextField
					// 			{...params}
					// 			style={{ height: 'inherit' }}
					// 			placeholder={props.filterLabel}
					// 		/>
					// 	)}
					// 	onChange={(_, value) => {
					// 		if (!value) {
					// 			props.setSearch([])
					// 		} else {
					// 			props.setSearch((prev: any) =>
					// 				prev ? [...prev, value] : [value],
					// 			)
					// 		}
					// 	}}
					// 	onKeyDown={(e) => {
					// 		if (e.key === 'Enter') {
					// 			props.applyFilter()
					// 			props.setFilterHander(false)
					// 		}
					// 	}}
					// 	getOptionLabel={(option: any) => {
					// 		return option.toString()
					// 	}}
					// 	value={props.search}
					// />
				)}
			</div>
			<AddButton
				style={{ fontSize: '12px' }}
				onClick={() => props.applyFilter()}
			>
				Apply
			</AddButton>
			<button
				style={{
					fontSize: '12px',
					border: 'none',
					boxShadow: 'none',
					textDecoration: 'solid underline 2px',
					textUnderlineOffset: '6px',
					marginLeft: '4px',
					paddingLeft: '0px',
					cursor: 'pointer',
				}}
				onClick={props.clearFilter}
				disabled={props.search.length === 0}
			>
				Clear
			</button>
		</>
	)
}

export const GradientedText = styled(Typography)(({ theme }) => ({
	background: '-webkit-linear-gradient(360deg, #ee1d4a 0%, #401381 100%)',
	WebkitBackgroundClip: 'text',
	WebkitTextFillColor: 'transparent',
	fontSize: '20px',
	fontWeight: 'bold',
	width: 'fit-content',
}))

export const StyledHeader = styled(Typography)(({ theme }) => ({
	marginInlineStart: '60px',
	color: 'black',
	fontSize: '30px',
	fontWeight: 'bold',
}))

export const StyledBadge = styled(Badge)(() => ({
	'& .MuiBadge-badge': {
		right: 2,
		top: 18,
		background: theme.colors.base.green200,
	},
}))
