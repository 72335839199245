import { Button, styled, Tab, Tabs } from '@mui/material'
import theme from '../../utils/theme2024'

export const StyledTabs = styled(Tabs)(() => ({
	border: '1px solid rgba(0,0,0,0.2)',
	width: 'auto',
	borderRadius: '56px',
	height: '28px',
	minHeight: '0px',
	'& .MuiTabs-indicator': {
		backgroundColor: theme.customComponents.button.green,
		transition: 'none',
	},
	// '& .MuiTabs-indicatorSpan': {
	// 	background: theme.colors.gradient,
	// },
}))

export const StyledTab = styled(Tab)(() => ({
	height: '28px',
	minHeight: '0px',
	borderLeft: '1px solid rgba(0,0,0,0.2)',
	minWidth: '12vw',
	'&.Mui-selected': {
		color: theme.colors.text.general,
		backgroundColor: theme.customComponents.button.green,
	},
}))
