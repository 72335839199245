import { ThemeProvider } from '@emotion/react'
import {
	DataGridProProps,
	GridColDef,
	GridEventListener,
	GridRenderCellParams,
	GridRowEditStopReasons,
	GridRowModes,
	GridRowModesModel,
	gridFilteredDescendantCountLookupSelector,
	useGridApiContext,
	useGridSelector,
} from '@mui/x-data-grid-pro'
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown'
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight'
import React, {
	Dispatch,
	SetStateAction,
	useEffect,
	useRef,
	useState,
} from 'react'
import { useActions } from '../../hooks/useActions'
import { useTypedSelector } from '../../hooks/useTypedSelector'
import { generateRowsWithIds, searchFor } from '../../utils/helpers/tableHelper'
import theme from '../../utils/theme2024'
import './finance.css'
import { PageWrapper, TableWrapper } from '../components/reusableComponents'

import { ButtonProps, Typography } from '@mui/material'
import saveAs from 'file-saver'
import { useNavigate } from 'react-router'
import {
	extractPaymentLimit,
	formatDateToMonth,
	formatFinancePaymentDate,
	getMonthAbbreviation,
	getYear,
	returnAbbreviatedMonth,
	sortDates,
	sortHardcodedDates,
} from '../../utils/helpers/helperFuncs'
import moment from 'moment'
import { TableComponent } from '../components/TableComponents'
import { useDispatch } from 'react-redux'
import { emptyFinance } from '../../state/actions'
import { HeaderFilterComponent } from '../components/TableHelperComponents'

const BalanceInPage = (props: {
	setLoading: any
	errorMessage: any
	setErrorMessage: any
	isErrorPromptOpened: any
	setIsErrorPromptOpened: any
	isSuccessPromptOpened: any
	setIsSuccessPromptOpened: any
	isEditorOpened?: any
	isAddEditorOpened?: any
	PopUpVisible: any
	setPopUpVisible: any
	search: string
	setSearch: Dispatch<SetStateAction<string>>
	presetSearch: string
	setPresetSearch: Dispatch<SetStateAction<string>>
}) => {
	const { login, finance, advertiser, users, settings } = useTypedSelector(
		(state) => state,
	)
	const headers: any = {
		Authorization: `Token ${login.user.token}`,
	}
	const { getFinanceData, uploadBalanceIn, downloadFinance } = useActions()
	const sessionKey = 'filters-finance-balance_in'
	const sessionSerializedFilters = sessionStorage.getItem(sessionKey)
	let sessionFilters: any = {}
	if (sessionSerializedFilters && sessionSerializedFilters.length > 4) {
		sessionFilters = JSON.parse(sessionSerializedFilters)
	}
	const [pageSize, setPageSize] = useState(15)
	const [filteredFinance, setFilteredFinance] = useState<any>([])
	const rowCount = 16
	const [rowWidth, setRowWidth] = useState(
		Math.floor((window.innerWidth - theme.dimentions.drawerWidth) / rowCount),
	)
	const [fileDownload, setFileDownload] = useState()

	const [tableRows, setTableRows] = useState<any>([])
	const [openFilter, setOpenFilter] = useState(false)
	const [financeFinalConfirmationFilter, setFinanceFinalConfirmationFilter] =
		useState(
			sessionFilters.financeFinalConfirmationFilter
				? sessionFilters.financeFinalConfirmationFilter
				: 0,
		)
	const [customFilter, setCustomFilter] = useState<any>(
		sessionFilters.customFilter
			? sessionFilters.customFilter
			: {
					advertiser: [],
					account_manager: [],
					branch: [],
			  },
	)
	const [applyFilter, setApplyFilter] = useState(false)
	const [currentFilters, setCurrentFilters] = useState(
		sessionSerializedFilters && sessionSerializedFilters.length > 4
			? 'advertiser'
			: '',
	)
	const [filterLabel, setFilterLabel] = useState('')
	const [optionsFilters, setOptionsFilter] = useState<any>({
		advertiser: [],
		account_manager: [],
		branch: [],
	})
	const [sortModel, setSortModel] = useState([{ field: 'month', sort: 'desc' }])
	const [rowModesModel, setRowModesModel] = React.useState<GridRowModesModel>(
		{},
	)
	const [monthFilterArray, setMonthFilterArray] = useState<any>(
		sessionFilters.monthFilterArray ? sessionFilters.monthFilterArray : [],
	)
	const [yearFilterArray, setYearFilterArray] = useState<any>(
		sessionFilters.yearFilterArray ? sessionFilters.yearFilterArray : [],
	)
	const [pinnedBottomRow, setPinnedBottomRow] = useState<any>([])
	const [dataFetched, setDataFetched] = useState(false)
	const dispatch = useDispatch()
	const hasFetched = useRef(false)

	const fetchData = async () => {
		let i = 0
		let result
		do {
			result = await getFinanceData(
				headers,
				'balancein',
				props.errorMessage,
				login.user.email,
				1000,
				i,
			)
			i++
			setDataFetched(true)
			//we need to check the pathname in order to stop fetching data if we move to a different route
		} while (
			result &&
			result.length > 0 &&
			window.location.pathname.endsWith('balancein')
		)
	}

	useEffect(() => {
		dispatch(emptyFinance())
		//this needs to be added for the StrictMode. Otherwise data is duplicated.
		if (hasFetched.current) {
			return
		}
		hasFetched.current = true
		fetchData()
		const sessionSerializedFilters = sessionStorage.getItem(sessionKey)
		if (sessionSerializedFilters && sessionSerializedFilters.length > 4) {
			const sessionFilters = JSON.parse(sessionSerializedFilters)
			setCurrentFilters('advertiser')
			sessionFilters.customFilter &&
				setCustomFilter(sessionFilters.customFilter)
			sessionFilters.monthFilterArray &&
				setMonthFilterArray(sessionFilters.monthFilterArray)
			sessionFilters.yearFilterArray &&
				setYearFilterArray(sessionFilters.yearFilterArray)
			sessionFilters.financeFinalConfirmationFilter &&
				setFinanceFinalConfirmationFilter(
					sessionFilters.financeFinalConfirmationFilter,
				)
		}
	}, [])
	useEffect(() => {
		if (dataFetched) {
			setOptionsFilter({
				advertiser: new Set(
					tableRows
						.map((element: any) => element.advertiser)
						.filter((el: any) => el.length > 0),
				),
				account_manager: new Set(
					tableRows
						.map((element: any) => element.account_manager)
						.filter((el: any) => el && el.length > 0),
				),
				branch: new Set(
					tableRows
						.map((element: any) => element.branch)
						.filter((el: any) => el && el.length > 0),
				),
			})
		}
	}, [tableRows])
	useEffect(() => {
		if (filterLabel) {
			const filters = {
				customFilter,
				monthFilterArray,
				yearFilterArray,
				financeFinalConfirmationFilter,
			}
			const serializedFilters = JSON.stringify(filters)
			sessionStorage.setItem(sessionKey, serializedFilters)
		}
	}, [
		applyFilter,
		monthFilterArray,
		yearFilterArray,
		financeFinalConfirmationFilter,
	])

	useEffect(() => {
		//we do this check because if we move from one page to another, even if finance.finance has data only for 0.1ms then the
		//gridtable will fail trying to find hierarchy.
		if (finance.finance.length > 0 && finance.finance[0].hierarchy) {
			setFilteredFinance(Array.isArray(finance.finance) ? finance.finance : [])
		}
	}, [finance.finance])
	// useEffect(() => {
	// 	setFilteredFinance(Array.isArray(finance.finance) ? finance.finance : [])
	// 	const arr = generateRowsWithIds(
	// 		generateRowsByAdvertiser(finance.finance, advertiser.advertiser),
	// 	)
	// 	setTableRows(arr)
	// 	setPinnedBottomRow(calculatePinnedTotalRow(arr))
	// }, [finance.finance])

	const possibleFinanceFilter = [
		'All',
		'Missing',
		'Confirmed',
		'Paid',
		'Unpaid',
	]

	useEffect(() => {
		if (dataFetched) {
			props.setLoading(true)
		}
		let filtered: any
		if (props.search.length > 0) {
			let searched = searchFor(props.search, filteredFinance, [
				...columns,
				{ field: 'advertiser' },
			])
			if (
				possibleFinanceFilter[financeFinalConfirmationFilter].toLowerCase() ===
				'all'
			) {
				filtered = searched
			} else if (
				possibleFinanceFilter[financeFinalConfirmationFilter].toLowerCase() ===
				'missing'
			) {
				filtered = searched.filter(
					(el: any) => !el.final_confirmation_advertiser,
				)
			} else if (
				possibleFinanceFilter[financeFinalConfirmationFilter].toLowerCase() ===
				'confirmed'
			) {
				filtered = searched.filter(
					(el: any) => el.final_confirmation_advertiser,
				)
			} else if (
				possibleFinanceFilter[financeFinalConfirmationFilter].toLowerCase() ===
				'paid'
			) {
				filtered = searched.filter((el: any) => el.payment_advertiser)
			} else if (
				possibleFinanceFilter[financeFinalConfirmationFilter].toLowerCase() ===
				'unpaid'
			) {
				filtered = searched.filter((el: any) => !el.payment_advertiser)
			}
		} else {
			if (
				possibleFinanceFilter[financeFinalConfirmationFilter].toLowerCase() ===
				'all'
			) {
				filtered = filteredFinance
			} else if (
				possibleFinanceFilter[financeFinalConfirmationFilter].toLowerCase() ===
				'missing'
			) {
				filtered = filteredFinance.filter(
					(el: any) => !el.final_confirmation_advertiser,
				)
			} else if (
				possibleFinanceFilter[financeFinalConfirmationFilter].toLowerCase() ===
				'confirmed'
			) {
				filtered = filteredFinance.filter(
					(el: any) => el.final_confirmation_advertiser,
				)
			} else if (
				possibleFinanceFilter[financeFinalConfirmationFilter].toLowerCase() ===
				'paid'
			) {
				filtered = filteredFinance.filter((el: any) => el.payment_advertiser)
			} else if (
				possibleFinanceFilter[financeFinalConfirmationFilter].toLowerCase() ===
				'unpaid'
			) {
				filtered = filteredFinance.filter((el: any) => !el.payment_advertiser)
			}
		}
		//if possible due date is "All" then we do nothing at all because we won't need to filter.
		let arr = []
		let anyfilters = false
		let additionalFiltersApplied = []
		//this is used to filter by the buttons of the columns headers.
		for (let [index, data] of filtered.entries()) {
			// let found = false
			let foundWrong = false

			for (const key in customFilter) {
				// let wrongKey = false
				let foundByKey = false

				if (customFilter[key].length > 0) {
					anyfilters = true
					if (key === 'branch') {
						foundByKey = customFilter[key].includes(
							data['internal_legal_entity'],
						)
					} else {
						foundByKey = customFilter[key].includes(data[key])
					}
					if (key === 'publisher_manager' || key === 'account_manager') {
						const values = customFilter[key].map((el: string) =>
							el.toLowerCase(),
						)
						const userFound = users.users.find((e: any) => {
							return (
								values.includes(e.name.toLowerCase()) &&
								data[key] &&
								data[key].toLowerCase() === e.name.toLowerCase()
							)
						})
						foundByKey = !!userFound
					}
					if (!foundByKey) {
						foundWrong = true
						break
					}
				}
			}
			if (!foundWrong) {
				additionalFiltersApplied.push(data)
				continue
			}
		}
		if (!anyfilters) {
			arr = filtered
		} else if (additionalFiltersApplied.length > 0 && anyfilters) {
			arr = additionalFiltersApplied
		} else {
			arr = []
		}
		//this handles the year
		if (yearFilterArray.length !== 0) {
			const filteredData = arr.filter((item: any) => {
				const year = getYear(item.month)
				if (
					yearFilterArray.includes('This Year') &&
					year === moment().year().toString()
				) {
					return true
				}
				return yearFilterArray.includes(year)
			})
			arr = filteredData
		}
		//this handles the months
		if (monthFilterArray.length !== 0) {
			const arrToFilter = arr
			const monthAbbrevatedFilteredArray = monthFilterArray.map((el: string) =>
				returnAbbreviatedMonth(el),
			)
			const filteredData = arrToFilter.filter((item: any) => {
				const monthAbbreviation = getMonthAbbreviation(item.month)
				return monthAbbrevatedFilteredArray.includes(monthAbbreviation)
			})
			arr = filteredData
		}
		const rowsByAdvertisers = generateRowsByAdvertiser(
			arr,
			advertiser.advertiser,
		)
		arr = generateRowsWithIds(rowsByAdvertisers)
		setTableRows(arr)
		setPinnedBottomRow(calculatePinnedTotalRow(arr))
		if (dataFetched) {
			props.setLoading(false)
		}
	}, [
		props.search,
		monthFilterArray,
		yearFilterArray,
		applyFilter,
		financeFinalConfirmationFilter,
		finance.finance,
	])

	const handleApplyFilter = () => {
		setApplyFilter(!applyFilter)
	}

	useEffect(() => {
		if (fileDownload) {
			saveAs(fileDownload, '*.csv')
		}
	}, [fileDownload])

	// const handleEditClick = (id: any) => () => {
	// 	setRowModesModel({ ...rowModesModel, [id]: { mode: GridRowModes.Edit } })
	// }
	// const handleSaveClick = (id: any) => () => {
	// 	setRowModesModel({ ...rowModesModel, [id]: { mode: GridRowModes.View } })
	// }
	// const handleCancelClick = (id: any) => () => {
	// 	setRowModesModel({
	// 		...rowModesModel,
	// 		[id]: { mode: GridRowModes.View, ignoreModifications: true },
	// 	})
	// }
	const handleDownloadFinance = () => {
		const dataToDownload = tableRows.filter(
			(el: any) => el.hierarchy.length === 1,
		)
		downloadFinance(
			headers,
			dataToDownload,
			'balance-in',
			setFileDownload,
			props.setErrorMessage,
		)
	}

	const handleRowEditStop: GridEventListener<'rowEditStop'> = (
		params,
		event,
	) => {
		if (params.reason === GridRowEditStopReasons.rowFocusOut) {
			event.defaultMuiPrevented = true
		}
	}
	const columns: GridColDef[] = [
		// {
		// 	field: 'advertiser',
		// 	headerName: 'Advertiser',
		// 	width: rowWidth,
		// 	type: 'singleSelect',
		// 	renderHeader: () => {
		// 		return (
		// 			<HeaderFilterComponent
		// 				label={'Advertiser'}
		// 				width={rowWidth * 1.2}
		// 				openFilter={openFilter}
		// 				setOpenFilter={setOpenFilter}
		// 				field={'advertiser'}
		// 				customFilter={customFilter}
		// 				currentFilters={currentFilters}
		// 				setCurrentFilters={setCurrentFilters}
		// 				setFilterLabel={setFilterLabel}
		// 				optionsFilters={optionsFilters}
		// 			/>
		// 		)
		// 	},
		// 	renderCell: (row) => {
		// 		return (
		// 			<Typography sx={{ fontSize: 14, fontWeight: '600' }}>
		// 				{row.value}
		// 			</Typography>
		// 		)
		// 	},
		// 	cellClassName: (params) => {
		// 		if (params.colDef.editable === false) {
		// 			return 'is-not-editable'
		// 		}
		// 		return 'is-editable'
		// 	},
		// },
		{
			field: 'branch',
			headerName: 'Legal Entity',
			width: rowWidth * 1.6,
			align: 'center',
			renderHeader: () => {
				return (
					<HeaderFilterComponent
						label={'Legal Entity'}
						width={rowWidth * 1.4}
						openFilter={openFilter}
						setOpenFilter={setOpenFilter}
						field={'branch'}
						customFilter={customFilter}
						currentFilters={currentFilters}
						setCurrentFilters={setCurrentFilters}
						setFilterLabel={setFilterLabel}
						optionsFilters={optionsFilters}
					/>
				)
			},
			cellClassName: (params) => {
				if (params.colDef.editable === false) {
					return 'is-not-editable'
				}
				return 'is-editable'
			},
		},
		{
			field: 'account_manager',
			headerName: 'Account Manager',
			width: rowWidth * 1.3,
			renderHeader: () => {
				return (
					<HeaderFilterComponent
						label={'Account Manager'}
						width={rowWidth * 1.4}
						openFilter={openFilter}
						setOpenFilter={setOpenFilter}
						field={'account_manager'}
						customFilter={customFilter}
						currentFilters={currentFilters}
						setCurrentFilters={setCurrentFilters}
						setFilterLabel={setFilterLabel}
						optionsFilters={optionsFilters}
					/>
				)
			},
			cellClassName: (params) => {
				if (params.colDef.editable === false) {
					return 'is-not-editable'
				}
				return 'is-editable'
			},
		},
		{
			field: 'month',
			headerName: 'Period',
			headerAlign: 'center',
			align: 'center',
			width: rowWidth * 0.8,
			renderCell: ({ value }) => {
				const date = formatDateToMonth(value)
				return <Typography sx={{ fontSize: 14 }}>{date}</Typography>
			},
			sortComparator: (str1, str2) => {
				return sortHardcodedDates(str1, str2)
			},
			cellClassName: (params) => {
				if (params.colDef.editable === false) {
					return 'is-not-editable'
				}
				return 'is-editable'
			},
		},
		{
			field: 'revenue',
			headerName: 'Revenue',
			headerAlign: 'center',
			width: rowWidth * 0.8,
			type: 'number',
			align: 'center',
			renderCell: ({ value }) => {
				return (
					<span>
						{Number(value).toLocaleString('en-US', {
							minimumFractionDigits: 2,
							maximumFractionDigits: 2,
						})}
					</span>
				)
			},
			cellClassName: (params) => {
				if (params.colDef.editable === false) {
					return 'is-not-editable'
				}
				return 'is-editable'
			},
		},
		{
			field: 'cost',
			headerName: 'Cost',
			headerAlign: 'center',
			width: rowWidth * 0.8,
			type: 'number',
			align: 'center',
			renderCell: ({ value }) => {
				if (value) {
					return (
						<span>
							{Number(value).toLocaleString('en-US', {
								minimumFractionDigits: 2,
								maximumFractionDigits: 2,
							})}
						</span>
					)
				} else {
					return <></>
				}
			},
			cellClassName: (params) => {
				if (params.colDef.editable === false) {
					return 'is-not-editable'
				}
				return 'is-editable'
			},
		},
		{
			field: 'profit',
			headerName: 'Profit',
			headerAlign: 'center',
			width: rowWidth * 1.2,
			type: 'number',
			align: 'center',
			headerClassName: 'finance-final-header',
			renderCell: ({ value }) => {
				if (value) {
					return (
						<span>
							{Number(value).toLocaleString('en-US', {
								minimumFractionDigits: 2,
								maximumFractionDigits: 2,
							})}
						</span>
					)
				} else {
					return <></>
				}
			},
			cellClassName: (params) => {
				if (params.colDef.editable === false) {
					if (params && params.row && params.row.profit < 0) {
						return 'finance-final-cell no-profit is-not-editable'
					}
					return 'finance-final-cell is-not-editable'
				}
				return 'finance-final-cell is-editable'
			},
		},
		{
			field: 'margin_value',
			headerName: 'Margin',
			headerAlign: 'center',
			width: rowWidth * 0.8,
			type: 'number',
			align: 'center',
			renderCell: ({ value }) => {
				if (!isNaN(value)) {
					return (
						<span>
							{Number(value).toLocaleString('en-US', {
								minimumFractionDigits: 2,
								maximumFractionDigits: 2,
							})}{' '}
							%
						</span>
					)
				} else {
					return <></>
				}
			},
			cellClassName: (params) => {
				if (params.colDef.editable === false) {
					return 'is-not-editable'
				}
				return 'is-editable'
			},
		},
		{
			field: 'revenue_with_vat',
			headerName: 'Amount inc. VAT',
			headerAlign: 'center',
			width: rowWidth * 0.8,
			type: 'number',
			align: 'center',
			renderCell: ({ value }) => {
				return (
					<span>
						{Number(value).toLocaleString('en-US', {
							minimumFractionDigits: 2,
							maximumFractionDigits: 2,
						})}
					</span>
				)
			},
			cellClassName: (params) => {
				if (params.colDef.editable === false) {
					return 'is-not-editable'
				}
				return 'is-editable'
			},
		},
		{
			field: 'invoice_number_advertiser',
			headerName: 'Invoice Number',
			width: rowWidth,
			align: 'left',
			headerAlign: 'left',
			cellClassName: (params) => {
				if (params.colDef.editable === false) {
					return 'is-not-editable'
				}
				return 'is-editable'
			},
		},
		{
			field: 'invoice_number_advertiser_added_time',
			headerName: 'Overdue Date',
			width: rowWidth,
			type: 'number',
			align: 'left',
			headerAlign: 'left',
			renderCell: (row) => {
				const value = row.row.invoice_number_advertiser_added_time
				let currentDate
				if (row.row.payment_advertiser) {
					currentDate = moment(row.row.payment_advertiser).startOf('day')
				} else {
					currentDate = moment().startOf('day')
				}
				const valueDate = moment(value).startOf('day')
				const difference_days = currentDate.diff(valueDate, 'days')
				const outOfPaymentLimit = row.row.payment_limit < difference_days

				return (
					<Typography
						sx={{ fontSize: 14, color: outOfPaymentLimit ? 'red' : 'inherit' }}
					>
						{value ? difference_days : ''}
					</Typography>
				)
			},
			cellClassName: (params) => {
				if (params.colDef.editable === false) {
					return 'is-not-editable'
				}
				return 'is-editable'
			},
		},
		{
			field: 'payment_advertiser',
			headerName: 'Payment Date',
			width: rowWidth,
			align: 'left',
			headerAlign: 'left',
			type: 'date',
			valueGetter: ({ value }) => new Date(value),
			renderCell: ({ value }) => {
				if (value) {
					const date = formatFinancePaymentDate(value)
					return <Typography sx={{ fontSize: 14 }}>{date}</Typography>
				} else {
					return <></>
				}
			},
			cellClassName: (params) => {
				if (params.colDef.editable === false) {
					return 'is-not-editable'
				}
				return 'is-editable'
			},
		},
		{
			field: 'received_amount_advertiser_converted',
			headerName: 'Received Amount',
			width: rowWidth,
			align: 'left',
			headerAlign: 'left',
			// editable: true,
			type: 'number',
			cellClassName: (params) => {
				if (params.colDef.editable === false) {
					return 'is-not-editable'
				}
				return 'is-editable'
			},
			renderCell: ({ value }) => {
				return (
					<span>
						{Number(value || 0).toLocaleString('en-US', {
							minimumFractionDigits: 2,
							maximumFractionDigits: 2,
						})}
					</span>
				)
			},
		},
		{
			field: 'bank_fees_advertiser',
			headerName: 'Bank Fees',
			width: rowWidth,
			align: 'left',
			headerAlign: 'left',
			// editable: true,
			type: 'number',
			cellClassName: (params) => {
				if (params.colDef.editable === false) {
					return 'is-not-editable'
				}
				return 'is-editable'
			},
			renderCell: ({ value }) => {
				if (!value && value !== 0) {
					return <></>
				}
				return (
					<span>
						{Number(value).toLocaleString('en-US', {
							minimumFractionDigits: 2,
							maximumFractionDigits: 2,
						})}
					</span>
				)
			},
		},
		{
			field: 'payment_method_advertiser',
			headerName: 'Payment Method',
			width: rowWidth,
			align: 'left',
			headerAlign: 'left',
			type: 'singleSelect',
			// editable: true,
			valueOptions: settings.settings.paymentMethod,
			cellClassName: (params) => {
				if (params.colDef.editable === false) {
					return 'is-not-editable'
				}
				return 'is-editable'
			},
		},
	]

	const getTreeDataPath: DataGridProProps['getTreeDataPath'] = (row) =>
		row.hierarchy
	//here we define the column that is grouping.
	const groupingColDef: DataGridProProps['groupingColDef'] = {
		headerName: 'Advertiser',
		width: rowWidth * 1.2,
		// cellClassName: (params: any) =>
		// 	params.row.hierarchy && params.row.hierarchy.length > 1
		// 		? 'finance-campaign-title'
		// 		: 'finance-campaign-title',
		renderCell: (params) => {
			if (params.rowNode.type === 'group') {
				return <CustomGridTreeDataGroupingCell {...params} />
			} else {
				if (params.row.hierarchy && params.row.hierarchy.length > 1) {
					return <span></span>
				}
				return <span>{params.row.advertiser}</span>
			}
		},
	}

	const handleRowStyle = (params: any) => {
		const row = params.row
		const isInEditMode = rowModesModel[row.id]?.mode === GridRowModes.Edit
		if (isInEditMode) {
			return 'edit-mode-on'
		}
		//9999999999 this id is the hardcoded id we use for the bottom pinned row.
		if (row.id === 9999999999) {
			return 'pinned-row'
		}
		return ''
	}
	const handleRowModesModelChange = (newRowModesModel: GridRowModesModel) => {
		setRowModesModel(newRowModesModel)
	}
	const processRowUpdate = async (newRow: any) => {
		const updatedRow = { ...newRow, isNew: false }

		const failed = await uploadBalanceIn(
			headers,
			newRow,
			login.user.email,
			props.setErrorMessage,
		)

		if (!failed) {
			const arr = tableRows.map((row: any) =>
				row.uuid === newRow.uuid ? updatedRow : row,
			)
			setTableRows(arr)
			setPinnedBottomRow(calculatePinnedTotalRow(arr))
		} else {
			const notUpdatedData = [...tableRows]
			setTableRows(notUpdatedData)
			setPinnedBottomRow(calculatePinnedTotalRow(notUpdatedData))
		}
		return updatedRow
	}
	const handleDoubleCellClick = React.useCallback((params, event) => {
		event.defaultMuiPrevented = true
	}, [])

	// Prevent from rolling back on escape
	const handleCellKeyDown = React.useCallback((params, event) => {
		if (
			['Escape', 'Delete', 'Backspace', 'Enter'].includes(
				(event as React.KeyboardEvent).key,
			)
		) {
			event.defaultMuiPrevented = true
		}
	}, [])

	// Prevent from committing on focus out
	const handleCellFocusOut = React.useCallback((params, event) => {
		if (params.cellMode === 'edit' && event) {
			event.defaultMuiPrevented = true
		}
	}, [])
	return (
		<ThemeProvider theme={theme}>
			<PageWrapper>
				<TableWrapper
					theme={theme}
					rowCount={rowCount}
					setRowWidth={setRowWidth}
					style={{ paddingBottom: '60px', width: '90%' }}
				>
					<TableComponent
						columns={columns}
						rows={tableRows}
						setPageSize={setPageSize}
						pageSize={pageSize}
						rowsPerPageOptions={[15, 25, 50]}
						setLoading={props.setLoading}
						filterStatus={financeFinalConfirmationFilter}
						setFilterStatus={setFinanceFinalConfirmationFilter}
						rowHeight={'auto'}
						sortModel={sortModel}
						onSortModelChange={(model: any) => setSortModel(model)}
						filterHandler={openFilter}
						setFilterHander={setOpenFilter}
						customFilter={customFilter}
						setCustomFilter={setCustomFilter}
						currentFilters={currentFilters}
						applyFilter={handleApplyFilter}
						filterLabel={filterLabel}
						optionsFilters={optionsFilters}
						mainFilterOptions={possibleFinanceFilter}
						rowModesModel={rowModesModel}
						handleRowModesModelChange={handleRowModesModelChange}
						processRowUpdate={processRowUpdate}
						handleRowEditStop={handleRowEditStop}
						handleDoubleCellClick={handleDoubleCellClick}
						handleCellKeyDown={handleCellKeyDown}
						handleCellFocusOut={handleCellFocusOut}
						monthFilterArray={monthFilterArray}
						setMonthFilterArray={setMonthFilterArray}
						yearFilterArray={yearFilterArray}
						setYearFilterArray={setYearFilterArray}
						treeData={true}
						getTreeDataPath={getTreeDataPath}
						groupingColDef={groupingColDef}
						downloadTemplate={handleDownloadFinance}
						customSx={{
							'& .MuiDataGrid-columnHeaderTitle': {
								lineHeight: '18px',
								whiteSpace: 'pre-line',
							},
							'& .MuiDataGrid-editInputCell': {
								fontSize: '16px',
								marginTop: '3px',
							},
							'& .MuiSelect-select': {
								fontSize: '16px',
							},
							'& ::-webkit-scrollbar': {
								height: '7px',
								width: '10px',
							},
						}}
						getRowClassName={handleRowStyle}
						pinnedBottomRow={pinnedBottomRow}
					></TableComponent>
				</TableWrapper>
			</PageWrapper>
		</ThemeProvider>
	)
}

const generateRowsByAdvertiser = (inputRows: any, advertiserList: any) => {
	// console.log(inputRows)
	// console.log(advertiserList)
	return inputRows
	// const rows = [...inputRows]
	// const result: any = []
	// const alreadyIn: string[] = []
	// for (const row of rows) {
	// 	let newRow: any = {}
	// 	if (!alreadyIn.includes(row.advertiser + row.month.toString())) {
	// 		const currentAdvertiser = advertiserList.filter(
	// 			(adv: any) =>
	// 				adv.advertiser_name.toLowerCase().trim() ===
	// 				row.advertiser.toLowerCase().trim(),
	// 		)
	// 		newRow.advertiser = row.advertiser
	// 		newRow.bank_fees_advertiser = row.bank_fees_advertiser
	// 		newRow.received_amount_advertiser_converted =
	// 			row.received_amount_advertiser_converted
	// 		newRow.payment_method_advertiser = row.payment_method_advertiser
	// 		newRow.account_manager = row.account_manager
	// 		newRow.branch = row.internal_legal_entity
	// 		newRow.payment_limit = currentAdvertiser[0]
	// 			? extractPaymentLimit(currentAdvertiser[0].payment_terms)
	// 			: ''
	// 		newRow.uuid = row.uuid + '-demand-' + row.month
	// 		newRow.month = row.month
	// 		newRow.invoice_instructions = row.invoice_instructions
	// 		newRow.proof = row.proof
	// 		newRow.currency = row.currency
	// 		newRow.extra_invoices = row.extra_invoices
	// 		newRow.advertiser_vat = row.advertiser_vat
	// 		newRow.revenue = row.revenue_converted ? row.revenue_converted : 0
	// 		newRow.revenue_with_vat =
	// 			Number(row.revenue_converted) +
	// 			Number(row.revenue_converted) * (Number(row.advertiser_vat) / 100)
	// 		newRow.cost = 0
	// 		if (row.status.toLowerCase() === 'yes') {
	// 			newRow.cost = row.final_amount
	// 		}
	// 		newRow.profit = newRow.revenue - newRow.cost
	// 		if (row.bank_fees_advertiser) {
	// 			newRow.profit = newRow.profit - Number(row.bank_fees_advertiser)
	// 		}
	// 		newRow.margin_value = 0
	// 		if (newRow.profit && newRow.revenue) {
	// 			newRow.margin_value =
	// 				(Number(newRow.profit) / Number(newRow.revenue)) * 100
	// 		}
	// 		newRow.final_confirmation_advertiser_sent =
	// 			row.final_confirmation_advertiser_sent
	// 		newRow.invoice_number_advertiser = row.invoice_number_advertiser
	// 		newRow.invoice_number_advertiser_added_time =
	// 			row.invoice_number_advertiser_added_time
	// 		if (row.payment_advertiser) {
	// 			newRow.payment_advertiser = new Date(row.payment_advertiser)
	// 		} else {
	// 			newRow.payment_advertiser = ''
	// 		}
	// 		alreadyIn.push(row.advertiser + row.month.toString())
	// 		result.push(newRow)
	// 	} else {
	// 		const rowToEdit = result.findIndex(
	// 			(el: any) => el.advertiser === row.advertiser && el.month === row.month,
	// 		)
	// 		result[rowToEdit].revenue =
	// 			Number(result[rowToEdit].revenue) + row.revenue_converted
	// 				? Number(row.revenue_converted)
	// 				: 0
	// 		result[rowToEdit].revenue_with_vat =
	// 			Number(result[rowToEdit].revenue) +
	// 			Number(result[rowToEdit].revenue) * (Number(row.advertiser_vat) / 100)
	// 		if (row.status.toLowerCase() === 'yes') {
	// 			result[rowToEdit].cost =
	// 				Number(result[rowToEdit].cost) + Number(row.final_amount)
	// 		}
	// 		result[rowToEdit].profit =
	// 			Number(result[rowToEdit].revenue) - Number(result[rowToEdit].cost)
	// 		if (result[rowToEdit].bank_fees_advertiser) {
	// 			result[rowToEdit].profit =
	// 				result[rowToEdit].profit -
	// 				Number(result[rowToEdit].bank_fees_advertiser)
	// 		}
	// 		if (result[rowToEdit].profit && result[rowToEdit].revenue) {
	// 			result[rowToEdit].margin_value =
	// 				(Number(result[rowToEdit].profit) /
	// 					Number(result[rowToEdit].revenue)) *
	// 				100
	// 		}
	// 	}
	// }
	// return createHierarchy(result, 'advertiser', 'month')
	// console.log(result)
}
const calculatePinnedTotalRow = (rows: any) => {
	const pinnedRow = {
		advertiser: 'TOTAL',
		branch: '',
		account_manager: '',
		month: '',
		revenue: 0,
		cost: 0,
		profit: 0,
		invoice_number_advertiser: '',
		invoice_number_advertiser_added_time: '',
		payment_advertiser: '',
		bank_fees_advertiser: 0,
		received_amount_advertiser_converted: 0,
		payment_method_advertiser: '',
		revenue_with_vat: 0,
		margin_value: 0,
		id: 9999999999,
	}
	for (const row of rows) {
		if (row.cost && row.hierarchy.length < 2) {
			pinnedRow.cost = Number(pinnedRow.cost) + Number(row.cost)
		}
		if (row.revenue && row.hierarchy.length < 2) {
			pinnedRow.revenue = Number(pinnedRow.revenue) + Number(row.revenue)
		}
		if (row.bank_fees_advertiser && row.hierarchy.length < 2) {
			pinnedRow.bank_fees_advertiser =
				Number(pinnedRow.bank_fees_advertiser) +
				Number(row.bank_fees_advertiser)
		}
		if (row.received_amount_advertiser_converted && row.hierarchy.length < 2) {
			pinnedRow.received_amount_advertiser_converted =
				Number(pinnedRow.received_amount_advertiser_converted) +
				Number(row.received_amount_advertiser_converted)
		}
		if (row.revenue_with_vat && row.hierarchy.length < 2) {
			pinnedRow.revenue_with_vat =
				Number(pinnedRow.revenue_with_vat) + Number(row.revenue_with_vat)
		}
	}
	pinnedRow.revenue = Number(pinnedRow.revenue).toFixed(2) as any
	pinnedRow.bank_fees_advertiser = Number(
		pinnedRow.bank_fees_advertiser,
	).toFixed(2) as any
	pinnedRow.received_amount_advertiser_converted = Number(
		pinnedRow.received_amount_advertiser_converted,
	).toFixed(2) as any
	pinnedRow.revenue_with_vat = Number(pinnedRow.revenue_with_vat).toFixed(
		2,
	) as any
	pinnedRow.cost = Number(pinnedRow.cost).toFixed(2) as any
	pinnedRow.profit =
		Number(pinnedRow.revenue) -
		Number(pinnedRow.cost) -
		Number(pinnedRow.bank_fees_advertiser)
	pinnedRow.margin_value =
		(Number(pinnedRow.profit) / Number(pinnedRow.revenue)) * 100
	pinnedRow.profit = Number(pinnedRow.profit).toFixed(2) as any
	return [pinnedRow]
}

const createHierarchy = (
	array: any,
	key: string = 'advertiser',
	key2: string | undefined = undefined,
) => {
	return array
	// console.log(array)
	// const hierarchyResult = []
	// for (const element of array) {
	// 	let hierarchyId = element[key]
	// 	if (key2) {
	// 		hierarchyId += element[key2]
	// 	}
	// 	let row = element
	// 	let mainRow: any = {}
	// 	mainRow.revenue = row.revenue
	// 	mainRow.hierarchy = [hierarchyId]
	// 	mainRow.invoice_number_advertiser = row.invoice_number_advertiser
	// 	mainRow.invoice_number_advertiser_added_time =
	// 		row.invoice_number_advertiser_added_time
	// 	mainRow.payment_advertiser = row.payment_advertiser
	// 	mainRow.received_amount_advertiser_converted =
	// 		row.received_amount_advertiser_converted
	// 	mainRow.bank_fees_advertiser = row.bank_fees_advertiser
	// 	mainRow.payment_method_advertiser = row.payment_method_advertiser
	// 	mainRow.cost = row.cost
	// 	mainRow.profit = row.profit
	// 	mainRow.revenue_with_vat = row.revenue_with_vat
	// 	mainRow.margin_value = row.margin_value
	// 	mainRow.advertiser = element.advertiser
	// 	mainRow.advertiser_vat = element.advertiser_vat
	// 	mainRow.account_manager = element.account_manager
	// 	mainRow.branch = element.branch
	// 	mainRow.month = element.month
	// 	mainRow.uuid = row.uuid

	// 	if (row.extra_invoices) {
	// 		let firstInvoice: any = {}
	// 		firstInvoice.revenue = row.revenue
	// 		firstInvoice.invoice_number_advertiser = row.invoice_number_advertiser
	// 		firstInvoice.invoice_number_advertiser_added_time =
	// 			row.invoice_number_advertiser_added_time
	// 		firstInvoice.payment_advertiser = row.payment_advertiser
	// 		firstInvoice.received_amount_advertiser_converted =
	// 			row.received_amount_advertiser_converted
	// 		firstInvoice.bank_fees_advertiser = row.bank_fees_advertiser
	// 		firstInvoice.revenue_with_vat = row.revenue_with_vat
	// 		firstInvoice.payment_method_advertiser = row.payment_method_advertiser
	// 		firstInvoice.advertiser = element.advertiser
	// 		firstInvoice.uuid = row.uuid
	// 		firstInvoice.hierarchy = [hierarchyId, row.uuid]

	// 		delete mainRow.invoice_number_advertiser
	// 		delete mainRow.invoice_number_advertiser_added_time
	// 		delete mainRow.payment_advertiser
	// 		delete mainRow.payment_method_advertiser
	// 		mainRow.bank_fees_advertiser = 0
	// 		mainRow.received_amount_advertiser_converted = 0
	// 		mainRow.revenue_with_vat = 0

	// 		// mainRow.revenue = Number(row.revenue) + Number(firstInvoice.revenue)
	// 		if (firstInvoice.bank_fees_advertiser) {
	// 			mainRow.bank_fees_advertiser =
	// 				Number(mainRow.bank_fees_advertiser) +
	// 				Number(firstInvoice.bank_fees_advertiser)
	// 		}
	// 		if (firstInvoice.revenue_with_vat) {
	// 			mainRow.revenue_with_vat =
	// 				Number(mainRow.revenue_with_vat) +
	// 				Number(firstInvoice.revenue_with_vat)
	// 		}
	// 		if (firstInvoice.received_amount_advertiser_converted) {
	// 			mainRow.received_amount_advertiser_converted =
	// 				Number(mainRow.received_amount_advertiser_converted) +
	// 				Number(firstInvoice.received_amount_advertiser_converted)
	// 		}
	// 		hierarchyResult.push(firstInvoice)

	// 		for (const invoice of row.extra_invoices) {
	// 			let invoiceRow: any = {}
	// 			invoiceRow.revenue = invoice.revenue_converted
	// 			invoiceRow.invoice_number_advertiser = invoice.invoice_number_advertiser
	// 			invoiceRow.invoice_number_advertiser_added_time =
	// 				invoice.invoice_number_advertiser_added_time
	// 			invoiceRow.payment_advertiser = invoice.payment_advertiser
	// 			if (invoiceRow.payment_advertiser) {
	// 				invoiceRow.payment_advertiser = new Date(
	// 					invoiceRow.payment_advertiser,
	// 				)
	// 			}
	// 			invoiceRow.received_amount_advertiser_converted =
	// 				invoice.received_amount_advertiser_converted
	// 			invoiceRow.bank_fees_advertiser = invoice.bank_fees_advertiser
	// 			invoiceRow.revenue_with_vat =
	// 				Number(invoiceRow.revenue) +
	// 				Number(invoiceRow.revenue) * (Number(element.advertiser_vat) / 100)
	// 			invoiceRow.payment_method_advertiser = invoice.payment_method_advertiser
	// 			invoiceRow.advertiser = element.advertiser
	// 			invoiceRow.uuid = invoice.uuid
	// 			invoiceRow.hierarchy = [hierarchyId, invoice.uuid]
	// 			mainRow.revenue = Number(mainRow.revenue) + Number(invoiceRow.revenue)
	// 			if (invoiceRow.bank_fees_advertiser) {
	// 				mainRow.bank_fees_advertiser =
	// 					Number(mainRow.bank_fees_advertiser) +
	// 					Number(invoiceRow.bank_fees_advertiser)
	// 			}
	// 			if (invoiceRow.revenue_with_vat) {
	// 				mainRow.revenue_with_vat =
	// 					Number(mainRow.revenue) +
	// 					(Number(invoiceRow.revenue) +
	// 						Number(invoiceRow.revenue) *
	// 							(Number(element.advertiser_vat) / 100))
	// 			}
	// 			if (invoiceRow.received_amount_advertiser_converted) {
	// 				mainRow.received_amount_advertiser_converted =
	// 					Number(mainRow.received_amount_advertiser_converted) +
	// 					Number(invoiceRow.received_amount_advertiser_converted)
	// 			}

	// 			hierarchyResult.push(invoiceRow)
	// 		}
	// 		mainRow.profit =
	// 			Number(mainRow.revenue) -
	// 			Number(mainRow.cost) -
	// 			Number(mainRow.bank_fees_advertiser)

	// 		mainRow.margin_value =
	// 			(Number(mainRow.profit) / Number(mainRow.revenue)) * 100
	// 	}

	// 	hierarchyResult.push(mainRow)
	// }
	// if (hierarchyResult[0] && !hierarchyResult[0].advertiser) {
	// 	hierarchyResult.shift()
	// }
	// return hierarchyResult
}

function CustomGridTreeDataGroupingCell(props: GridRenderCellParams) {
	const { id, field, rowNode } = props
	const apiRef = useGridApiContext()
	const [expanded, setExpanded] = useState(false)
	const filteredDescendantCountLookup = useGridSelector(
		apiRef,
		gridFilteredDescendantCountLookupSelector,
	)
	const filteredDescendantCount = filteredDescendantCountLookup[rowNode.id] ?? 0

	const handleClick: ButtonProps['onClick'] = (event) => {
		if (rowNode.type !== 'group') {
			return
		}
		apiRef.current.setRowChildrenExpansion(id, !rowNode.childrenExpanded)
		apiRef.current.setCellFocus(id, field)
		event.stopPropagation()
	}
	useEffect(() => {
		const currentRowStatus = apiRef.current.getRowNode(id)
		setExpanded((currentRowStatus as any).childrenExpanded)
	}, [apiRef.current.getRowNode(id)])

	return (
		<div>
			<div>
				{filteredDescendantCount > 0 ? (
					<button
						onClick={handleClick}
						tabIndex={-1}
						style={{
							border: 'none',
							display: 'flex',
							alignItems: 'flex-start',
							marginLeft: '-12px',
							cursor: 'pointer',
						}}
					>
						{expanded ? (
							<KeyboardArrowDownIcon
								style={{
									color: theme.colors.base.red300,
									fontSize: '20px',
									marginTop: '2px',
								}}
							/>
						) : (
							<KeyboardArrowRightIcon
								style={{
									color: theme.colors.base.red300,
									fontSize: '20px',
									marginTop: '2px',
								}}
							/>
						)}
						<span
							style={{
								fontWeight: '600',
								fontSize: '14px',
								display: 'flex',
								justifyContent: 'flex-start',
								textAlign: 'start',
								marginLeft: '2px',
							}}
						>
							{props.row.advertiser}
						</span>
					</button>
				) : (
					<span />
				)}
			</div>
		</div>
	)
}

export default BalanceInPage
