import { LicenseInfo } from '@mui/x-license-pro'
import React, { useEffect, useRef, useState } from 'react'
import { Route, Routes, useLocation } from 'react-router-dom'
import './App.css'
import { Loading } from './assets/svg/loading'
import { useActions } from './hooks/useActions'
import { useTypedSelector } from './hooks/useTypedSelector'
import ResponsiveDrawer from './navigation/NavBar'
import AdjustReportsPage from './pages/adjust/FiltersPage'
import AdvertiserPreviewPage from './pages/advertisers/AdvertiserPreviewPage'
import AdvertiserPage from './pages/advertisers/AdvertiserTable'
import AppsPage from './pages/apps/AppsTable'
import P360Page from './pages/apps/P360Table'
import AppsflyerAccountsTable from './pages/appsflyer/AppsflyerAccountsTable'
import CampaignPage from './pages/campaigns/CampaignTable'
import { Dashboard } from './pages/dashboard/Dashboard'
import LearningComponent from './pages/documents/LearningComponent'
import BalanceInPage from './pages/finance/BalanceInTable'
import BalanceOutPage from './pages/finance/BalanceOutTable'
import ControllerPage from './pages/finance/ControllerTable'
import DemandPage from './pages/finance/DemandTable'
import GlobalTable from './pages/finance/GlobalTable'
import MediaBuyingPlatform from './pages/finance/MediaBuyingTable'
import PersonalPage from './pages/finance/PersonalTable'
import SupplyPreview from './pages/finance/SupplyPreview'
import SupplyPage from './pages/finance/SupplyTable'
import InvalidReportsPage from './pages/invalid/FiltersPage'
import Login from './pages/login/loginPage'
import LogsPage from './pages/logs/logsPage'
import MediaPlanPage from './pages/mediaplan/MediaPlanPage'
import NetworkPage from './pages/network/FiltersPage'
import NewsPage from './pages/news/newsPage'
import NotFound from './pages/notfound/NotFound'
import AutoSwitchPage from './pages/optimization/AutoSwitch'
import P360Dashboard from './pages/p360dashboard/p360dashboard'

import { AdsPlacementPage } from './pages/adsplacement/AdsPlacementPage'
import PasswordsPage from './pages/passwords/PasswordsTable'
import PublisherPreviewPage from './pages/publishers/PublisherPreviewPage'
import PublisherPage from './pages/publishers/PublisherTable'
import RawReportsPage from './pages/rawdata/FiltersPage'
import ReportsPage from './pages/reports/FiltersPage'
import PersonalSettingsPage from './pages/settings/personalPage'
import SettingsPage from './pages/settings/settingsPage'
import UserPage from './pages/users/UserTable'
import { getApplicableRules } from './utils/helpers/helperFuncs'
import NotLogged from './utils/NotLogged'
import ProtectedRoutes from './utils/routes'
import DemandInvoiceTable from './pages/finance/DemandInvoiceTable'
import CampaignPreviewPage from './pages/campaigns/CampaignPreviewPage'
import WishListTable from './pages/publisher-wishlist/WishListTable'
import CreatorPreviewPage from './pages/publishers/CreatorPreviewPage'
import ResetPasswordPage from './pages/login/ResetPasswordPage'
import CreatorFinancePage from './pages/finance/creators/CreatorTable'
import CreatorPersonalPage from './pages/finance/creators/CreatorPersonalTable'
import CreatorSupplyPage from './pages/finance/creators/CreatorSupply'
import HandshakePage from './pages/handshake/HandshakePage'

LicenseInfo.setLicenseKey(
	'8810c5e70b27cca67fb391d0652406c4Tz04MjMxMixFPTE3MzcwNTEzNzEwMDAsUz1wcm8sTE09c3Vic2NyaXB0aW9uLEtWPTI=',
)
const App: React.FC = () => {
	const {
		users,
		login,
		advertiser,
		publisher,
		campaign,
		app,
		dashboard,
		logs,
		networks,
		passwords,
		campaign_history,
		autoswitch,
		avatars,
		news,
		settings,
	} = useTypedSelector((state) => state)
	const [errorMessage, setErrorMessage] = useState(null)
	const [userMessage, setUserMessage] = useState(null)
	const [isErrorPromptOpened, setIsErrorPromptOpened] = useState(false)
	const [isSuccessPromptOpened, setIsSuccessPromptOpened] = useState(false)
	const [isWarningPromptOpened, setIsWarningPromptOpened] = useState(false)
	const [isUserMessagePromptOpened, setIsUserMessagePromptOpened] =
		useState(false)
	const [onWarningAgree, setOnWarningAgree] = useState<any>()
	const [loading, setLoading] = useState(false)
	const [firstLoading, setFirstLoading] = useState(false)
	// const [presetSearch, setPresetSearch] = useState<string>('')
	const [viewRecord, setViewRecord] = useState<any>(null)
	const [isPreviewOpened, setIsPreviewOpened] = useState(false)
	const [universalEdit, setUniversalEdit] = useState<any>(null)
	const [universalDelete, setUniversalDelete] = useState<any>(null)
	const [PopUpVisible, setPopUpVisible] = useState(false)
	const [forcedFilterStatus, setForcedFilterStatus] = useState(1)
	const [firstLoadingDone, setFirstLoadingDone] = useState(false)

	const sessionKey = 'search-bar'
	const sessionSerializedFilters = sessionStorage.getItem(sessionKey)
	let sessionFilters: any = {}
	if (sessionSerializedFilters && sessionSerializedFilters.length > 4) {
		sessionFilters = JSON.parse(sessionSerializedFilters)
	}
	const [search, setSearch] = useState(
		sessionFilters.search ? sessionFilters.search : '',
	)
	const [presetSearch, setPresetSearch] = useState(
		sessionFilters.search ? sessionFilters.search : '',
	)
	// const {
	// 	usersAction,
	// 	getAdvertiserAction,
	// 	getPublisherAction,
	// 	getCampaignAction,
	// 	getAppsAction,
	// 	navigationAction,
	// 	logOutAction,
	// 	getNetworkAction,
	// 	getSettingsAction,
	// 	getPasswordsAction,
	// 	getDashboardAction,
	// 	getCampaignHistoryAction,
	// 	getAvatars,
	// 	getNewsAction,
	// } = useActions()
	// const headers = {
	// 	Authorization: login.user ? `Token ${login.user.token}` : '',
	// }
	useEffect(() => {
		if (presetSearch !== '') {
			setSearch(presetSearch)
			setPresetSearch('')
		}
	}, [search])

	useEffect(() => {
		if (errorMessage !== null) {
			setIsErrorPromptOpened(true)
		}
	}, [errorMessage])

	useEffect(() => {
		if (viewRecord !== null) {
			setIsPreviewOpened(true)
		}
	}, [viewRecord])

	useEffect(() => {
		return () => {
			if (isPreviewOpened) {
				setUniversalDelete(null)
				setUniversalEdit(null)
			}
		}
	}, [isPreviewOpened])
	const checkAmountOfSlashes = (string: string) => {
		let result = 0
		for (const letter of string) {
			if (letter === '/') {
				result++
			}
		}
		return result
	}
	useEffect(() => {
		const handleBackButton = () => {
			//we check the amount of / because if we have more than 1 it means we are going to a page that needs popUpVisible.
			if (checkAmountOfSlashes(window.location.pathname) >= 2) {
				setPopUpVisible(true)
			} else {
				setPopUpVisible(false)
			}
		}

		window.addEventListener('popstate', handleBackButton)

		return () => {
			window.removeEventListener('popstate', handleBackButton)
		}
	}, [])

	const endpointsMap = {
		users: ['/users'],
		news: ['/news'],
		logs: ['/logs'],
		settings: ['/settings'],
		advertiser: ['/advertisers'],
		publisher: ['/publishers'],
		campaign: ['/campaigns'],
		app: ['/apps'],
		appsflyer: ['/aggregated'],
		network: ['/network'],
		rawdata: ['/rawdata'],
		invalid: ['/invalid'],
		passwords: ['/passwords'],
		learning: ['/documents'],
		dashboard: ['/dashboard'],
		autoswitch: ['/autoswitch'],
		'ads placement': ['/adsplacement'],
		image: ['/personalSettings'],
		finance: [
			'/finance/personal',
			'/finance/control',
			'/finance/mediabuying',
			'/finance/creator',
			'/finance/creatorsupply',
			'/finance/global',
			'/finance/demand',
			'/finance/supply',
			'/finance/balancein',
			'/finance/balanceout',
		],
		app_p360: ['/p360apps'],
		appsflyer_accounts: ['/appsflyeraccounts'],
		publisher_wish_list: ['/publisherwishlist'],
	}
	const financeRulesMap = {
		'View Personal': '/finance/personal',
		'View Control': '/finance/control',
		'View Balance In': '/finance/balancein',
		'View Balance Out': '/finance/balanceout',
		'View Media Buying': '/finance/mediabuying',
		'View Influencers': '/finance/creator',
		'View Influencers Supply': '/finance/creatorsupply',
		'View Demand': '/finance/demand',
		'View Supply': '/finance/supply',
		'View Global': '/finance/global',
	}
	const isAuthorized = (endpoint: string) => {
		// console.log('checking for', endpoint, users, login, settings)
		if (!login.user.email) {
			return false
		}
		//if endpoint is finance we need to check the inside rules object
		if (endpoint.startsWith('/finance')) {
			let insideRule = ''
			for (const key in financeRulesMap) {
				if (financeRulesMap[key as keyof typeof financeRulesMap] === endpoint) {
					insideRule = key
					break
				}
			}
			const ruleSet = getApplicableRules(users, login, settings, 'finance')
			if (ruleSet.get) {
				return ruleSet.rules[insideRule]
			}
			return false
		}
		if (endpoint.startsWith('/personalSettings')) {
			const ruleSet = getApplicableRules(users, login, settings, 'settings')
			return ruleSet.rules['view personal settings page']
		}
		// if the owner of the advertiser can edit his own, but can't edit others:

		if (endpoint.includes('advertisers/')) {
			if (endpoint.endsWith('edit')) {
				const path = window.location.pathname.split('/')
				const advertiserName = path[path.length - 1]
					.toLowerCase()
					.replace('%20', ' ')
				const curAdvertiser = advertiser.advertiser.filter(
					(c: any) => c.advertiser_name.toLowerCase() === advertiserName,
				)
				const ruleSet = getApplicableRules(users, login, settings, 'advertiser')
				if (!ruleSet.post) {
					if (!curAdvertiser[0]) return
					const loggedUserEmail = login.user.email

					return curAdvertiser[0].email === loggedUserEmail
				}
			}
		}
		if (endpoint === '/apps') {
			const ruleSet = getApplicableRules(users, login, settings, 'advertiser')
			return ruleSet.rules['access to ‘Apps’']
		}

		const entities = ['advertiser', 'publisher', 'campaign']
		for (const entity of entities) {
			if (endpoint.includes(entity)) {
				if (endpoint.endsWith('edit')) {
					const ruleSet = getApplicableRules(users, login, settings, entity)
					return ruleSet.post
				} else if (endpoint.endsWith('preview')) {
					const ruleSet = getApplicableRules(users, login, settings, entity)
					return ruleSet.rules.preview
				}
			}
		}

		//everything else will check only the get (view) outside object
		let mainRuleToCheck = ''
		for (const key in endpointsMap) {
			if (endpointsMap[key as keyof typeof endpointsMap].includes(endpoint)) {
				mainRuleToCheck = key
				break
			}
		}
		if (!mainRuleToCheck) {
			return true
		}
		const ruleSet = getApplicableRules(users, login, settings, mainRuleToCheck)
		if (!ruleSet) {
			return false
		}
		if (ruleSet.get) {
			return true
		}
	}

	return (
		<Routes>
			<Route
				path='/'
				element={<Login setFirstLoadingDone={setFirstLoadingDone} />}
			/>
			<Route path='/notlogged' element={<NotLogged />} />
			<Route path='/resetpassword' element={<ResetPasswordPage />} />
			<Route path='*' element={<NotFound />} />
			<Route element={<ProtectedRoutes />}>
				<Route
					element={
						<ResponsiveDrawer
							isErrorPromptOpened={isErrorPromptOpened}
							setIsErrorPromptOpened={setIsErrorPromptOpened}
							isSuccessPromptOpened={isSuccessPromptOpened}
							setIsSuccessPromptOpened={setIsSuccessPromptOpened}
							isWarningPromptOpened={isWarningPromptOpened}
							setIsWarningPromptOpened={setIsWarningPromptOpened}
							isUserMessagePromptOpened={isUserMessagePromptOpened}
							setIsUserMessagePromptOpened={setIsUserMessagePromptOpened}
							setErrorMessage={setErrorMessage}
							setUserMessage={setUserMessage}
							errorMessage={errorMessage}
							userMessage={userMessage}
							PopUpVisible={PopUpVisible}
							setPopUpVisible={setPopUpVisible}
							forcedFilterStatus={forcedFilterStatus}
							setForcedFilterStatus={setForcedFilterStatus}
							isAuthorized={isAuthorized}
							setFirstLoadingDone={setFirstLoadingDone}
							search={search}
							setSearch={setSearch}
							presetSearch={presetSearch}
							setPresetSearch={setPresetSearch}
							setLoading={setLoading}
						/>
					}
				>
					{isAuthorized('/news') && (
						<Route
							path={'/news'}
							element={
								<div>
									{/* <FirstLoading loading={firstLoading} report={false} /> */}

									<NewsPage
										setLoading={setFirstLoading}
										errorMessage={errorMessage}
										setErrorMessage={setErrorMessage}
										isErrorPromptOpened={isErrorPromptOpened}
										setIsErrorPromptOpened={setIsErrorPromptOpened}
									/>
								</div>
							}
						/>
					)}
					{login.user && isAuthorized('/users') && (
						<Route
							path='/users'
							element={
								<div>
									<Loading loading={loading} report={false} />
									<UserPage
										setLoading={setLoading}
										errorMessage={errorMessage}
										setErrorMessage={setErrorMessage}
										isErrorPromptOpened={isErrorPromptOpened}
										setIsErrorPromptOpened={setIsErrorPromptOpened}
										PopUpVisible={PopUpVisible}
										setPopUpVisible={setPopUpVisible}
										search={search}
										setSearch={setSearch}
										presetSearch={presetSearch}
										setPresetSearch={setPresetSearch}
									/>
								</div>
							}
						/>
					)}
					{isAuthorized('/advertisers') && (
						<Route
							path='/advertisers'
							element={
								<div>
									<Loading loading={loading} report={false} />
									<AdvertiserPage
										setLoading={setLoading}
										errorMessage={errorMessage}
										setErrorMessage={setErrorMessage}
										isErrorPromptOpened={isErrorPromptOpened}
										setIsErrorPromptOpened={setIsErrorPromptOpened}
										isSuccessPromptOpened={isSuccessPromptOpened}
										setIsSuccessPromptOpened={setIsSuccessPromptOpened}
										viewRecord={viewRecord}
										setViewRecord={setViewRecord}
										setUniversalEdit={setUniversalEdit}
										popUpClear={false}
										PopUpVisible={PopUpVisible}
										setPopUpVisible={setPopUpVisible}
										setForcedFilterStatus={setForcedFilterStatus}
										search={search}
										setSearch={setSearch}
										presetSearch={presetSearch}
										setPresetSearch={setPresetSearch}
									/>
								</div>
							}
						>
							{isAuthorized('/advertisers/edit') && (
								<Route
									path='/advertisers/:id'
									element={
										<div>
											<Loading loading={loading} report={false} />
											<AdvertiserPage
												setLoading={setLoading}
												errorMessage={errorMessage}
												setErrorMessage={setErrorMessage}
												isErrorPromptOpened={isErrorPromptOpened}
												setIsErrorPromptOpened={setIsErrorPromptOpened}
												isSuccessPromptOpened={isSuccessPromptOpened}
												setIsSuccessPromptOpened={setIsSuccessPromptOpened}
												viewRecord={viewRecord}
												setViewRecord={setViewRecord}
												setUniversalEdit={setUniversalEdit}
												popUpClear={false}
												PopUpVisible={PopUpVisible}
												setPopUpVisible={setPopUpVisible}
												setForcedFilterStatus={setForcedFilterStatus}
												search={search}
												setSearch={setSearch}
												presetSearch={presetSearch}
												setPresetSearch={setPresetSearch}
											/>
										</div>
									}
								/>
							)}
						</Route>
					)}
					{isAuthorized('/advertisers/preview') && (
						<Route
							path='/advertisers/:id/preview'
							element={
								<div>
									<Loading loading={loading} report={false} />
									<AdvertiserPreviewPage
										setLoading={setLoading}
										errorMessage={errorMessage}
										setErrorMessage={setErrorMessage}
										isErrorPromptOpened={isErrorPromptOpened}
										setIsErrorPromptOpened={setIsErrorPromptOpened}
										isSuccessPromptOpened={isSuccessPromptOpened}
										setIsSuccessPromptOpened={setIsSuccessPromptOpened}
										setUniversalEdit={setUniversalEdit}
										viewRecord={viewRecord}
										setViewRecord={setViewRecord}
										setUniversalDelete={setUniversalDelete}
										PopUpVisible={PopUpVisible}
										setPopUpVisible={setPopUpVisible}
									/>
								</div>
							}
						/>
					)}

					{isAuthorized('/apps') && (
						<Route
							path='/apps'
							element={
								<div>
									<Loading loading={loading} report={false} />
									<AppsPage
										setLoading={setLoading}
										errorMessage={errorMessage}
										setErrorMessage={setErrorMessage}
										isErrorPromptOpened={isErrorPromptOpened}
										setIsErrorPromptOpened={setIsErrorPromptOpened}
										isSuccessPromptOpened={isSuccessPromptOpened}
										setIsSuccessPromptOpened={setIsSuccessPromptOpened}
										setUniversalEdit={setUniversalEdit}
										viewRecord={viewRecord}
										setViewRecord={setViewRecord}
										PopUpVisible={PopUpVisible}
										setPopUpVisible={setPopUpVisible}
										search={search}
										setSearch={setSearch}
										presetSearch={presetSearch}
										setPresetSearch={setPresetSearch}
									/>
								</div>
							}
						></Route>
					)}

					{isAuthorized('/p360apps') && (
						<Route
							path='/p360apps'
							element={
								<div>
									<Loading loading={loading} report={false} />
									<P360Page
										setLoading={setLoading}
										errorMessage={errorMessage}
										setErrorMessage={setErrorMessage}
										isErrorPromptOpened={isErrorPromptOpened}
										setIsErrorPromptOpened={setIsErrorPromptOpened}
										isSuccessPromptOpened={isSuccessPromptOpened}
										setIsSuccessPromptOpened={setIsSuccessPromptOpened}
										setUniversalEdit={setUniversalEdit}
										viewRecord={viewRecord}
										setViewRecord={setViewRecord}
										PopUpVisible={PopUpVisible}
										setPopUpVisible={setPopUpVisible}
										search={search}
										setSearch={setSearch}
										presetSearch={presetSearch}
										setPresetSearch={setPresetSearch}
									/>
								</div>
							}
						/>
					)}
					{isAuthorized('/p360apps') && (
						<Route
							path='/p360apps/:id'
							element={
								<div>
									<Loading loading={loading} report={false} />
									<P360Page
										setLoading={setLoading}
										errorMessage={errorMessage}
										setErrorMessage={setErrorMessage}
										isErrorPromptOpened={isErrorPromptOpened}
										setIsErrorPromptOpened={setIsErrorPromptOpened}
										isSuccessPromptOpened={isSuccessPromptOpened}
										setIsSuccessPromptOpened={setIsSuccessPromptOpened}
										setUniversalEdit={setUniversalEdit}
										viewRecord={viewRecord}
										setViewRecord={setViewRecord}
										PopUpVisible={PopUpVisible}
										setPopUpVisible={setPopUpVisible}
										search={search}
										setSearch={setSearch}
										presetSearch={presetSearch}
										setPresetSearch={setPresetSearch}
									/>
								</div>
							}
						/>
					)}
					{isAuthorized('/p360apps') && (
						<Route
							path='/p360apps/:id/:uuid'
							element={
								<div>
									<Loading loading={loading} report={false} />
									<P360Page
										setLoading={setLoading}
										errorMessage={errorMessage}
										setErrorMessage={setErrorMessage}
										isErrorPromptOpened={isErrorPromptOpened}
										setIsErrorPromptOpened={setIsErrorPromptOpened}
										isSuccessPromptOpened={isSuccessPromptOpened}
										setIsSuccessPromptOpened={setIsSuccessPromptOpened}
										setUniversalEdit={setUniversalEdit}
										viewRecord={viewRecord}
										setViewRecord={setViewRecord}
										PopUpVisible={PopUpVisible}
										setPopUpVisible={setPopUpVisible}
										search={search}
										setSearch={setSearch}
										presetSearch={presetSearch}
										setPresetSearch={setPresetSearch}
									/>
								</div>
							}
						/>
					)}
					{isAuthorized('/mediaplan') && (
						<Route
							path='/mediaplan'
							element={
								<div>
									<Loading loading={loading} report={false} />
									<MediaPlanPage />
								</div>
							}
						/>
					)}
					{isAuthorized('/mediaplan') && (
						<Route
							path='/pidhandshake'
							element={
								<div>
									<Loading loading={loading} report={false} />
									<HandshakePage
										setLoading={setLoading}
										errorMessage={errorMessage}
										setErrorMessage={setErrorMessage}
										isErrorPromptOpened={isErrorPromptOpened}
										setIsErrorPromptOpened={setIsErrorPromptOpened}
										isSuccessPromptOpened={isSuccessPromptOpened}
										setIsSuccessPromptOpened={setIsSuccessPromptOpened}
										PopUpVisible={PopUpVisible}
										setPopUpVisible={setPopUpVisible}
										search={search}
										setSearch={setSearch}
										presetSearch={presetSearch}
										setPresetSearch={setPresetSearch}
									/>
								</div>
							}
						/>
					)}
					{isAuthorized('/adsplacement') && (
						<Route
							path='/adsplacement'
							element={
								<div>
									<Loading loading={loading} report={false} />
									<AdsPlacementPage
										setLoading={setLoading}
										setErrorMessage={setErrorMessage}
										setUserMessage={setUserMessage}
										setIsUserMessagePromptOpened={setIsUserMessagePromptOpened}
									/>
								</div>
							}
						/>
					)}
					{isAuthorized('/publishers') && (
						<Route
							path='/publishers'
							element={
								<div>
									<Loading loading={loading} report={false} />
									<PublisherPage
										setLoading={setLoading}
										errorMessage={errorMessage}
										setErrorMessage={setErrorMessage}
										isErrorPromptOpened={isErrorPromptOpened}
										setIsErrorPromptOpened={setIsErrorPromptOpened}
										isSuccessPromptOpened={isSuccessPromptOpened}
										setIsSuccessPromptOpened={setIsSuccessPromptOpened}
										setUniversalEdit={setUniversalEdit}
										viewRecord={viewRecord}
										setViewRecord={setViewRecord}
										setUniversalDelete={setUniversalDelete}
										PopUpVisible={PopUpVisible}
										setPopUpVisible={setPopUpVisible}
										search={search}
										setSearch={setSearch}
										presetSearch={presetSearch}
										setPresetSearch={setPresetSearch}
									/>
								</div>
							}
						>
							{isAuthorized('/publishers/edit') && (
								<Route
									path='/publishers/:id'
									element={
										<div>
											<Loading loading={loading} report={false} />
											<PublisherPage
												setLoading={setLoading}
												errorMessage={errorMessage}
												setErrorMessage={setErrorMessage}
												isErrorPromptOpened={isErrorPromptOpened}
												setIsErrorPromptOpened={setIsErrorPromptOpened}
												isSuccessPromptOpened={isSuccessPromptOpened}
												setIsSuccessPromptOpened={setIsSuccessPromptOpened}
												setUniversalEdit={setUniversalEdit}
												viewRecord={viewRecord}
												setViewRecord={setViewRecord}
												setUniversalDelete={setUniversalDelete}
												PopUpVisible={PopUpVisible}
												setPopUpVisible={setPopUpVisible}
												search={search}
												setSearch={setSearch}
												presetSearch={presetSearch}
												setPresetSearch={setPresetSearch}
											/>
										</div>
									}
								/>
							)}
						</Route>
					)}
					{isAuthorized('/publishers/preview') && (
						<Route
							path='/publishers/:id/preview'
							element={
								<div>
									<Loading loading={loading} report={false} />
									<PublisherPreviewPage
										setLoading={setLoading}
										errorMessage={errorMessage}
										setErrorMessage={setErrorMessage}
										isErrorPromptOpened={isErrorPromptOpened}
										setIsErrorPromptOpened={setIsErrorPromptOpened}
										isSuccessPromptOpened={isSuccessPromptOpened}
										setIsSuccessPromptOpened={setIsSuccessPromptOpened}
										setUniversalEdit={setUniversalEdit}
										viewRecord={viewRecord}
										setViewRecord={setViewRecord}
										setUniversalDelete={setUniversalDelete}
										PopUpVisible={PopUpVisible}
										setPopUpVisible={setPopUpVisible}
									/>
								</div>
							}
						/>
					)}
					{isAuthorized('/publishers/edit') && (
						<Route
							path='/publisherwishlist'
							element={
								<div>
									<Loading loading={loading} report={false} />
									<WishListTable
										setLoading={setLoading}
										errorMessage={errorMessage}
										setErrorMessage={setErrorMessage}
										isErrorPromptOpened={isErrorPromptOpened}
										setIsErrorPromptOpened={setIsErrorPromptOpened}
										isSuccessPromptOpened={isSuccessPromptOpened}
										setIsSuccessPromptOpened={setIsSuccessPromptOpened}
										presetSearch={presetSearch}
										setPresetSearch={setPresetSearch}
										PopUpVisible={PopUpVisible}
										setPopUpVisible={setPopUpVisible}
									/>
								</div>
							}
						/>
					)}
					{isAuthorized('/publishers') && (
						<Route
							path='/creators/'
							element={
								<div>
									<Loading loading={loading} report={false} />
									<PublisherPage
										setLoading={setLoading}
										errorMessage={errorMessage}
										setErrorMessage={setErrorMessage}
										isErrorPromptOpened={isErrorPromptOpened}
										setIsErrorPromptOpened={setIsErrorPromptOpened}
										isSuccessPromptOpened={isSuccessPromptOpened}
										setIsSuccessPromptOpened={setIsSuccessPromptOpened}
										setUniversalEdit={setUniversalEdit}
										viewRecord={viewRecord}
										setViewRecord={setViewRecord}
										setUniversalDelete={setUniversalDelete}
										PopUpVisible={PopUpVisible}
										setPopUpVisible={setPopUpVisible}
										search={search}
										setSearch={setSearch}
										presetSearch={presetSearch}
										setPresetSearch={setPresetSearch}
									/>
								</div>
							}
						/>
					)}
					{isAuthorized('/publishers/edit') && (
						<Route
							path='/creators/:id'
							element={
								<div>
									<Loading loading={loading} report={false} />
									<PublisherPage
										setLoading={setLoading}
										errorMessage={errorMessage}
										setErrorMessage={setErrorMessage}
										isErrorPromptOpened={isErrorPromptOpened}
										setIsErrorPromptOpened={setIsErrorPromptOpened}
										isSuccessPromptOpened={isSuccessPromptOpened}
										setIsSuccessPromptOpened={setIsSuccessPromptOpened}
										setUniversalEdit={setUniversalEdit}
										viewRecord={viewRecord}
										setViewRecord={setViewRecord}
										setUniversalDelete={setUniversalDelete}
										PopUpVisible={PopUpVisible}
										setPopUpVisible={setPopUpVisible}
										search={search}
										setSearch={setSearch}
										presetSearch={presetSearch}
										setPresetSearch={setPresetSearch}
									/>
								</div>
							}
						/>
					)}
					{isAuthorized('/publishers/preview') && (
						<Route
							path='/creators/:id/preview'
							element={
								<div>
									<Loading loading={loading} report={false} />
									<CreatorPreviewPage
										setLoading={setLoading}
										errorMessage={errorMessage}
										setErrorMessage={setErrorMessage}
										isErrorPromptOpened={isErrorPromptOpened}
										setIsErrorPromptOpened={setIsErrorPromptOpened}
										isSuccessPromptOpened={isSuccessPromptOpened}
										setIsSuccessPromptOpened={setIsSuccessPromptOpened}
										setUniversalEdit={setUniversalEdit}
										viewRecord={viewRecord}
										setViewRecord={setViewRecord}
										setUniversalDelete={setUniversalDelete}
										PopUpVisible={PopUpVisible}
										setPopUpVisible={setPopUpVisible}
									/>
								</div>
							}
						/>
					)}
					{isAuthorized('/campaigns') && (
						<Route
							path='/campaigns'
							element={
								<div>
									<Loading loading={loading} report={false} />
									<CampaignPage
										setLoading={setLoading}
										errorMessage={errorMessage}
										setErrorMessage={setErrorMessage}
										isErrorPromptOpened={isErrorPromptOpened}
										setIsErrorPromptOpened={setIsErrorPromptOpened}
										isSuccessPromptOpened={isSuccessPromptOpened}
										setIsSuccessPromptOpened={setIsSuccessPromptOpened}
										isEditorOpened={false}
										PopUpVisible={PopUpVisible}
										setPopUpVisible={setPopUpVisible}
										forcedFilterStatus={forcedFilterStatus}
										search={search}
										setSearch={setSearch}
										presetSearch={presetSearch}
										setPresetSearch={setPresetSearch}
									/>
								</div>
							}
						>
							{isAuthorized('/campaigns/edit') && (
								<Route
									path='/campaigns/:id'
									element={
										<div>
											<Loading loading={loading} report={false} />
											<CampaignPage
												setLoading={setLoading}
												errorMessage={errorMessage}
												setErrorMessage={setErrorMessage}
												isErrorPromptOpened={isErrorPromptOpened}
												setIsErrorPromptOpened={setIsErrorPromptOpened}
												isSuccessPromptOpened={isSuccessPromptOpened}
												setIsSuccessPromptOpened={setIsSuccessPromptOpened}
												isEditorOpened={false}
												PopUpVisible={PopUpVisible}
												setPopUpVisible={setPopUpVisible}
												forcedFilterStatus={forcedFilterStatus}
												search={search}
												setSearch={setSearch}
												presetSearch={presetSearch}
												setPresetSearch={setPresetSearch}
											/>
										</div>
									}
								/>
							)}
						</Route>
					)}
					{isAuthorized('/campaigns/preview') && (
						<Route
							path='/campaigns/:id/preview'
							element={
								<div>
									<Loading loading={loading} report={false} />
									<CampaignPreviewPage
										setLoading={setLoading}
										errorMessage={errorMessage}
										setErrorMessage={setErrorMessage}
										isErrorPromptOpened={isErrorPromptOpened}
										setIsErrorPromptOpened={setIsErrorPromptOpened}
										isSuccessPromptOpened={isSuccessPromptOpened}
										setIsSuccessPromptOpened={setIsSuccessPromptOpened}
										setUniversalEdit={setUniversalEdit}
										viewRecord={viewRecord}
										setViewRecord={setViewRecord}
										setUniversalDelete={setUniversalDelete}
										PopUpVisible={PopUpVisible}
										setPopUpVisible={setPopUpVisible}
									/>
								</div>
							}
						></Route>
					)}
					{isAuthorized('/aggregated') && (
						<Route
							path='/aggregated'
							element={
								<div>
									<Loading loading={loading} report={true} />
									<ReportsPage
										setLoading={setLoading}
										errorMessage={errorMessage}
										viewRecord={viewRecord}
										setViewRecord={setViewRecord}
										setErrorMessage={setErrorMessage}
										isErrorPromptOpened={isErrorPromptOpened}
										setIsErrorPromptOpened={setIsErrorPromptOpened}
										setIsWarningPromptOpened={setIsWarningPromptOpened}
									/>
								</div>
							}
						/>
					)}
					{isAuthorized('/adjust') && (
						<Route
							path='/adjust'
							element={
								<div>
									<Loading loading={loading} report={true} />
									<AdjustReportsPage
										setLoading={setLoading}
										errorMessage={errorMessage}
										viewRecord={viewRecord}
										setViewRecord={setViewRecord}
										setErrorMessage={setErrorMessage}
										isErrorPromptOpened={isErrorPromptOpened}
										setIsErrorPromptOpened={setIsErrorPromptOpened}
										setIsWarningPromptOpened={setIsWarningPromptOpened}
									/>
								</div>
							}
						/>
					)}
					{isAuthorized('/dashboard') && (
						<Route
							path='/dashboard'
							element={
								<div>
									<Loading loading={loading} report={true} />
									<Dashboard
										setErrorMessage={setErrorMessage}
										setLoading={setLoading}
										setIsWarningPromptOpened={setIsWarningPromptOpened}
									/>
								</div>
							}
						/>
					)}
					{isAuthorized('/autoswitch') && (
						<Route
							path='/autoswitch'
							element={
								<div>
									<Loading loading={loading} report={false} />
									<AutoSwitchPage
										setLoading={setLoading}
										errorMessage={errorMessage}
										setErrorMessage={setErrorMessage}
										isErrorPromptOpened={isErrorPromptOpened}
										setIsErrorPromptOpened={setIsErrorPromptOpened}
										isSuccessPromptOpened={isSuccessPromptOpened}
										setIsSuccessPromptOpened={setIsSuccessPromptOpened}
										setUniversalEdit={setUniversalEdit}
										viewRecord={viewRecord}
										setViewRecord={setViewRecord}
										PopUpVisible={PopUpVisible}
										setPopUpVisible={setPopUpVisible}
										setOnWarningAgree={setOnWarningAgree}
									/>
								</div>
							}
						>
							<Route
								path='/autoswitch/:id'
								element={
									<div>
										<Loading loading={loading} report={false} />
										<AutoSwitchPage
											setLoading={setLoading}
											errorMessage={errorMessage}
											setErrorMessage={setErrorMessage}
											isErrorPromptOpened={isErrorPromptOpened}
											setIsErrorPromptOpened={setIsErrorPromptOpened}
											isSuccessPromptOpened={isSuccessPromptOpened}
											setIsSuccessPromptOpened={setIsSuccessPromptOpened}
											setUniversalEdit={setUniversalEdit}
											viewRecord={viewRecord}
											setViewRecord={setViewRecord}
											PopUpVisible={PopUpVisible}
											setPopUpVisible={setPopUpVisible}
											setOnWarningAgree={setOnWarningAgree}
										/>
									</div>
								}
							/>
						</Route>
					)}
					{isAuthorized('/network') && (
						<Route
							path='/network'
							element={
								<div>
									<Loading loading={loading} report={false} />
									<NetworkPage
										setLoading={setLoading}
										errorMessage={errorMessage}
										setErrorMessage={setErrorMessage}
										isErrorPromptOpened={isErrorPromptOpened}
										setViewRecord={setViewRecord}
										setIsErrorPromptOpened={setIsErrorPromptOpened}
										setIsWarningPromptOpened={setIsWarningPromptOpened}
									/>
								</div>
							}
						/>
					)}
					{isAuthorized('/rawdata') && (
						<Route
							path='/rawdata'
							element={
								<div>
									<Loading loading={loading} report={false} />
									<RawReportsPage
										setLoading={setLoading}
										errorMessage={errorMessage}
										setErrorMessage={setErrorMessage}
										setViewRecord={setViewRecord}
										isErrorPromptOpened={isErrorPromptOpened}
										setIsErrorPromptOpened={setIsErrorPromptOpened}
										setIsWarningPromptOpened={setIsWarningPromptOpened}
									/>
								</div>
							}
						/>
					)}
					{isAuthorized('/invalid') && (
						<Route
							path='/invalid'
							element={
								<div>
									<Loading loading={loading} report={false} />
									<InvalidReportsPage
										setLoading={setLoading}
										errorMessage={errorMessage}
										setErrorMessage={setErrorMessage}
										isErrorPromptOpened={isErrorPromptOpened}
										setIsErrorPromptOpened={setIsErrorPromptOpened}
										setIsWarningPromptOpened={setIsWarningPromptOpened}
										viewRecord={undefined}
										setViewRecord={undefined}
									/>
								</div>
							}
						/>
					)}
					{isAuthorized('/documents') && (
						<Route
							path='/documents'
							element={
								<div>
									<Loading loading={loading} report={false} />
									<LearningComponent
										setLoading={setLoading}
										errorMessage={errorMessage}
										setErrorMessage={setErrorMessage}
										isErrorPromptOpened={isErrorPromptOpened}
										setIsErrorPromptOpened={setIsErrorPromptOpened}
										setIsWarningPromptOpened={setIsWarningPromptOpened}
										PopUpVisible={PopUpVisible}
										setPopUpVisible={setPopUpVisible}
										setIsSuccessPromptOpened={setIsSuccessPromptOpened}
										isSuccessPromptOpened={isSuccessPromptOpened}
										search={search}
										setSearch={setSearch}
										presetSearch={presetSearch}
										setPresetSearch={setPresetSearch}
									/>
								</div>
							}
						/>
					)}
					{isAuthorized('/documents') && (
						<Route
							path='/documents/:id'
							element={
								<div>
									<Loading loading={loading} report={false} />
									<LearningComponent
										setLoading={setLoading}
										errorMessage={errorMessage}
										setErrorMessage={setErrorMessage}
										isErrorPromptOpened={isErrorPromptOpened}
										setIsErrorPromptOpened={setIsErrorPromptOpened}
										setIsWarningPromptOpened={setIsWarningPromptOpened}
										PopUpVisible={PopUpVisible}
										setPopUpVisible={setPopUpVisible}
										setIsSuccessPromptOpened={setIsSuccessPromptOpened}
										isSuccessPromptOpened={isSuccessPromptOpened}
										search={search}
										setSearch={setSearch}
										presetSearch={presetSearch}
										setPresetSearch={setPresetSearch}
									/>
								</div>
							}
						/>
					)}
					{isAuthorized('/settings') && (
						<Route
							path='/settings'
							element={
								<div>
									<Loading loading={loading} report={false} />
									<SettingsPage
										setLoading={setLoading}
										errorMessage={errorMessage}
										setErrorMessage={setErrorMessage}
										isErrorPromptOpened={isErrorPromptOpened}
										setIsErrorPromptOpened={setIsErrorPromptOpened}
										setIsWarningPromptOpened={setIsWarningPromptOpened}
										setIsSuccessPromptOpened={setIsSuccessPromptOpened}
									/>
								</div>
							}
						/>
					)}
					{isAuthorized('/logs') && (
						<Route
							path='/logs'
							element={
								<LogsPage
									setLoading={setLoading}
									PopUpVisible={PopUpVisible}
									setPopUpVisible={setPopUpVisible}
									search={search}
									setSearch={setSearch}
									presetSearch={presetSearch}
									setPresetSearch={setPresetSearch}
								/>
							}
						/>
					)}
					{isAuthorized('/p360dashboard') && (
						<Route
							path='/p360dashboard'
							element={
								<div>
									<Loading loading={loading} report={false} />
									<P360Dashboard />
								</div>
							}
						/>
					)}
					<Route
						path='/permissions'
						element={
							<div>
								<Loading loading={loading} report={false} />
							</div>
						}
					/>
					{isAuthorized('/passwords') && (
						<Route
							path='/passwords'
							element={
								<div>
									<Loading loading={loading} report={false} />
									<PasswordsPage
										setLoading={setLoading}
										errorMessage={errorMessage}
										setErrorMessage={setErrorMessage}
										isErrorPromptOpened={isErrorPromptOpened}
										setIsErrorPromptOpened={setIsErrorPromptOpened}
										isSuccessPromptOpened={isSuccessPromptOpened}
										setIsSuccessPromptOpened={setIsSuccessPromptOpened}
										PopUpVisible={PopUpVisible}
										setPopUpVisible={setPopUpVisible}
										search={search}
										setSearch={setSearch}
										presetSearch={presetSearch}
										setPresetSearch={setPresetSearch}
									/>
								</div>
							}
						/>
					)}
					{isAuthorized('/personalSettings') && (
						<Route
							path='/personalSettings'
							element={
								<div>
									<Loading loading={loading} report={false} />
									<PersonalSettingsPage
										setLoading={setLoading}
										errorMessage={errorMessage}
										setErrorMessage={setErrorMessage}
										isErrorPromptOpened={isErrorPromptOpened}
										setIsErrorPromptOpened={setIsErrorPromptOpened}
										setIsWarningPromptOpened={setIsWarningPromptOpened}
									/>
								</div>
							}
						/>
					)}
					{isAuthorized('appsflyeraccounts') && (
						<Route
							path='/appsflyeraccounts'
							element={
								<div>
									<Loading loading={loading} report={false} />
									<AppsflyerAccountsTable
										setLoading={setLoading}
										errorMessage={errorMessage}
										setErrorMessage={setErrorMessage}
										isErrorPromptOpened={isErrorPromptOpened}
										setIsErrorPromptOpened={setIsErrorPromptOpened}
										isSuccessPromptOpened={isSuccessPromptOpened}
										setIsSuccessPromptOpened={setIsSuccessPromptOpened}
										presetSearch={presetSearch}
										setPresetSearch={setPresetSearch}
										PopUpVisible={PopUpVisible}
										setPopUpVisible={setPopUpVisible}
									/>
								</div>
							}
						/>
					)}
					{isAuthorized('/finance/personal') && (
						<Route
							path='/finance/personal'
							element={
								<div>
									<Loading loading={loading} report={false} />
									<PersonalPage
										setLoading={setLoading}
										errorMessage={errorMessage}
										setErrorMessage={setErrorMessage}
										isErrorPromptOpened={isErrorPromptOpened}
										setIsErrorPromptOpened={setIsErrorPromptOpened}
										isSuccessPromptOpened={isSuccessPromptOpened}
										setIsSuccessPromptOpened={setIsSuccessPromptOpened}
										PopUpVisible={PopUpVisible}
										setPopUpVisible={setPopUpVisible}
										search={search}
										setSearch={setSearch}
										presetSearch={presetSearch}
										setPresetSearch={setPresetSearch}
									/>
								</div>
							}
						/>
					)}
					{isAuthorized('/finance/control') && (
						<Route
							path='/finance/control'
							element={
								<div>
									<Loading loading={loading} report={false} />
									<ControllerPage
										setLoading={setLoading}
										errorMessage={errorMessage}
										setErrorMessage={setErrorMessage}
										isErrorPromptOpened={isErrorPromptOpened}
										setIsErrorPromptOpened={setIsErrorPromptOpened}
										isSuccessPromptOpened={isSuccessPromptOpened}
										setIsSuccessPromptOpened={setIsSuccessPromptOpened}
										PopUpVisible={PopUpVisible}
										setPopUpVisible={setPopUpVisible}
										search={search}
										setSearch={setSearch}
										presetSearch={presetSearch}
										setPresetSearch={setPresetSearch}
									/>
								</div>
							}
						/>
					)}
					{isAuthorized('/finance/mediabuying') && (
						<Route
							path='/finance/mediabuying'
							element={
								<div>
									<Loading loading={loading} report={false} />
									<MediaBuyingPlatform
										setLoading={setLoading}
										errorMessage={errorMessage}
										setErrorMessage={setErrorMessage}
										isErrorPromptOpened={isErrorPromptOpened}
										setIsErrorPromptOpened={setIsErrorPromptOpened}
										isSuccessPromptOpened={isSuccessPromptOpened}
										setIsSuccessPromptOpened={setIsSuccessPromptOpened}
										PopUpVisible={PopUpVisible}
										setPopUpVisible={setPopUpVisible}
										search={search}
										setSearch={setSearch}
										presetSearch={presetSearch}
										setPresetSearch={setPresetSearch}
									/>
								</div>
							}
						/>
					)}
					{isAuthorized('/finance/creator') && (
						<Route
							path='/finance/creator'
							element={
								<div>
									<Loading loading={loading} report={false} />
									<CreatorFinancePage
										setLoading={setLoading}
										errorMessage={errorMessage}
										setErrorMessage={setErrorMessage}
										isErrorPromptOpened={isErrorPromptOpened}
										setIsErrorPromptOpened={setIsErrorPromptOpened}
										isSuccessPromptOpened={isSuccessPromptOpened}
										setIsSuccessPromptOpened={setIsSuccessPromptOpened}
										PopUpVisible={PopUpVisible}
										setPopUpVisible={setPopUpVisible}
										search={search}
										setSearch={setSearch}
										presetSearch={presetSearch}
										setPresetSearch={setPresetSearch}
									/>
								</div>
							}
						/>
					)}
					{isAuthorized('/finance/creator') && (
						<Route
							path='/finance/creator/:id'
							element={
								<div>
									<Loading loading={loading} report={false} />
									<CreatorPersonalPage
										setLoading={setLoading}
										errorMessage={errorMessage}
										setErrorMessage={setErrorMessage}
										isErrorPromptOpened={isErrorPromptOpened}
										setIsErrorPromptOpened={setIsErrorPromptOpened}
										isSuccessPromptOpened={isSuccessPromptOpened}
										setIsSuccessPromptOpened={setIsSuccessPromptOpened}
										PopUpVisible={PopUpVisible}
										setPopUpVisible={setPopUpVisible}
										search={search}
										setSearch={setSearch}
										presetSearch={presetSearch}
										setPresetSearch={setPresetSearch}
									/>
								</div>
							}
						/>
					)}
					{isAuthorized('/finance/creator') && (
						<Route
							path='/finance/creatorsupply/'
							element={
								<div>
									<Loading loading={loading} report={false} />
									<CreatorSupplyPage
										setLoading={setLoading}
										errorMessage={errorMessage}
										setErrorMessage={setErrorMessage}
										isErrorPromptOpened={isErrorPromptOpened}
										setIsErrorPromptOpened={setIsErrorPromptOpened}
										isSuccessPromptOpened={isSuccessPromptOpened}
										setIsSuccessPromptOpened={setIsSuccessPromptOpened}
										PopUpVisible={PopUpVisible}
										setPopUpVisible={setPopUpVisible}
										search={search}
										setSearch={setSearch}
										presetSearch={presetSearch}
										setPresetSearch={setPresetSearch}
									/>
								</div>
							}
						/>
					)}
					{isAuthorized('/finance/global') && (
						<Route
							path='/finance/global'
							element={
								<div>
									<Loading loading={loading} report={false} />
									<GlobalTable
										setLoading={setLoading}
										errorMessage={errorMessage}
										setErrorMessage={setErrorMessage}
										isErrorPromptOpened={isErrorPromptOpened}
										setIsErrorPromptOpened={setIsErrorPromptOpened}
										isSuccessPromptOpened={isSuccessPromptOpened}
										setIsSuccessPromptOpened={setIsSuccessPromptOpened}
										PopUpVisible={PopUpVisible}
										setPopUpVisible={setPopUpVisible}
										search={search}
										setSearch={setSearch}
										presetSearch={presetSearch}
										setPresetSearch={setPresetSearch}
									/>
								</div>
							}
						/>
					)}
					{isAuthorized('/finance/demand') && (
						<Route
							path='/finance/demand'
							element={
								<div>
									<Loading loading={loading} report={false} />
									<DemandPage
										setLoading={setLoading}
										errorMessage={errorMessage}
										setErrorMessage={setErrorMessage}
										isErrorPromptOpened={isErrorPromptOpened}
										setIsErrorPromptOpened={setIsErrorPromptOpened}
										isSuccessPromptOpened={isSuccessPromptOpened}
										setIsSuccessPromptOpened={setIsSuccessPromptOpened}
										PopUpVisible={PopUpVisible}
										setPopUpVisible={setPopUpVisible}
										search={search}
										setSearch={setSearch}
										presetSearch={presetSearch}
										setPresetSearch={setPresetSearch}
									/>
								</div>
							}
						/>
					)}
					{isAuthorized('/finance/demand') && (
						<Route
							path='/finance/demand/:id'
							element={
								<div>
									<Loading loading={loading} report={false} />
									<DemandInvoiceTable
										setLoading={setLoading}
										errorMessage={errorMessage}
										setErrorMessage={setErrorMessage}
										isErrorPromptOpened={isErrorPromptOpened}
										setIsErrorPromptOpened={setIsErrorPromptOpened}
										isSuccessPromptOpened={isSuccessPromptOpened}
										setIsSuccessPromptOpened={setIsSuccessPromptOpened}
										presetSearch={presetSearch}
										setPresetSearch={setPresetSearch}
										PopUpVisible={PopUpVisible}
										setPopUpVisible={setPopUpVisible}
									/>
								</div>
							}
						/>
					)}
					{isAuthorized('/finance/balancein') && (
						<Route
							path='/finance/balancein'
							element={
								<div>
									<Loading loading={loading} report={false} />
									<BalanceInPage
										setLoading={setLoading}
										errorMessage={errorMessage}
										setErrorMessage={setErrorMessage}
										isErrorPromptOpened={isErrorPromptOpened}
										setIsErrorPromptOpened={setIsErrorPromptOpened}
										isSuccessPromptOpened={isSuccessPromptOpened}
										setIsSuccessPromptOpened={setIsSuccessPromptOpened}
										PopUpVisible={PopUpVisible}
										setPopUpVisible={setPopUpVisible}
										search={search}
										setSearch={setSearch}
										presetSearch={presetSearch}
										setPresetSearch={setPresetSearch}
									/>
								</div>
							}
						/>
					)}
					{isAuthorized('/finance/balanceout') && (
						<Route
							path='/finance/balanceout'
							element={
								<div>
									<Loading loading={loading} report={false} />
									<BalanceOutPage
										setLoading={setLoading}
										errorMessage={errorMessage}
										setErrorMessage={setErrorMessage}
										isErrorPromptOpened={isErrorPromptOpened}
										setIsErrorPromptOpened={setIsErrorPromptOpened}
										isSuccessPromptOpened={isSuccessPromptOpened}
										setIsSuccessPromptOpened={setIsSuccessPromptOpened}
										PopUpVisible={PopUpVisible}
										setPopUpVisible={setPopUpVisible}
										search={search}
										setSearch={setSearch}
										presetSearch={presetSearch}
										setPresetSearch={setPresetSearch}
									/>
								</div>
							}
						/>
					)}
					{isAuthorized('/finance/supply') && (
						<Route
							path='/finance/supply'
							element={
								<div>
									<Loading loading={loading} report={false} />
									<SupplyPage
										setLoading={setLoading}
										errorMessage={errorMessage}
										setErrorMessage={setErrorMessage}
										isErrorPromptOpened={isErrorPromptOpened}
										setIsErrorPromptOpened={setIsErrorPromptOpened}
										isSuccessPromptOpened={isSuccessPromptOpened}
										setIsSuccessPromptOpened={setIsSuccessPromptOpened}
										PopUpVisible={PopUpVisible}
										setPopUpVisible={setPopUpVisible}
										search={search}
										setSearch={setSearch}
										presetSearch={presetSearch}
										setPresetSearch={setPresetSearch}
									/>
								</div>
							}
						/>
					)}
					{isAuthorized('/finance/supply') && (
						<Route
							path='/finance/supply/:id'
							element={
								<div>
									<Loading loading={loading} report={false} />
									<SupplyPreview
										setLoading={setLoading}
										errorMessage={errorMessage}
										setErrorMessage={setErrorMessage}
										isErrorPromptOpened={isErrorPromptOpened}
										setIsErrorPromptOpened={setIsErrorPromptOpened}
										isSuccessPromptOpened={isSuccessPromptOpened}
										setIsSuccessPromptOpened={setIsSuccessPromptOpened}
										PopUpVisible={PopUpVisible}
										setPopUpVisible={setPopUpVisible}
										search={search}
										setSearch={setSearch}
										presetSearch={presetSearch}
										setPresetSearch={setPresetSearch}
									/>
								</div>
							}
						/>
					)}

					{isAuthorized('/appsflyeraccounts') && (
						<Route
							path='/appsflyeraccounts'
							element={
								<div>
									<Loading loading={loading} report={false} />
									<AppsflyerAccountsTable
										setLoading={setLoading}
										errorMessage={errorMessage}
										setErrorMessage={setErrorMessage}
										isErrorPromptOpened={isErrorPromptOpened}
										setIsErrorPromptOpened={setIsErrorPromptOpened}
										isSuccessPromptOpened={isSuccessPromptOpened}
										setIsSuccessPromptOpened={setIsSuccessPromptOpened}
										presetSearch={presetSearch}
										setPresetSearch={setPresetSearch}
										PopUpVisible={PopUpVisible}
										setPopUpVisible={setPopUpVisible}
									/>
								</div>
							}
						/>
					)}
				</Route>
			</Route>
		</Routes>
	)
}

export default App
