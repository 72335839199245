import React, {
	Dispatch,
	SetStateAction,
	useEffect,
	useRef,
	useState,
} from 'react'
import theme from '../../utils/theme2024'
import { PageWrapper, TableWrapper } from '../components/reusableComponents'
import { ThemeProvider } from '@emotion/react'
import { useActions } from '../../hooks/useActions'
import { useTypedSelector } from '../../hooks/useTypedSelector'
import { generateRowsWithIds, searchFor } from '../../utils/helpers/tableHelper'
import {
	GridColDef,
	GridRowModesModel,
	GridRowModes,
	GridActionsCellItem,
	GridEventListener,
	GridRowEditStopReasons,
	GridEditSingleSelectCell,
	useGridApiRef,
} from '@mui/x-data-grid-pro'
import { Typography } from '@mui/material'
import './finance.css'
import { ReactComponent as EditIcon } from '../../assets/svg/edit-icon.svg'

import { ReactComponent as DeleteIcon } from '../../assets/svg/delete-icon.svg'
import {
	findAdvertiserByOwner,
	findPublisherFromPid,
	formatDateToMonth,
	getApplicableRules,
	getMonthAbbreviation,
	getYear,
	handleFinanceStatusColor,
	returnAbbreviatedMonth,
	sortDates,
	sortHardcodedDates,
} from '../../utils/helpers/helperFuncs'
import CheckIcon from '@mui/icons-material/Check'
import ClearIcon from '@mui/icons-material/Clear'
import moment from 'moment'
import Guidelines from './Guidelines'
import { TableComponent } from '../components/TableComponents'
import { DropList } from '../components/SelectableInputs'
import { useDispatch } from 'react-redux'
import { emptyFinance } from '../../state/actions'
import { SmallLoading } from '../../assets/svg/loading'
import { HeaderFilterComponent } from '../components/TableHelperComponents'

const MediaBuyingPlatform = (props: {
	setLoading: any
	errorMessage: any
	setErrorMessage: any
	isErrorPromptOpened: any
	setIsErrorPromptOpened: any
	isSuccessPromptOpened: any
	setIsSuccessPromptOpened: any
	isEditorOpened?: any
	isAddEditorOpened?: any
	PopUpVisible: any
	setPopUpVisible: any
	search: string
	setSearch: Dispatch<SetStateAction<string>>
	presetSearch: string
	setPresetSearch: Dispatch<SetStateAction<string>>
}) => {
	const { login, finance, publisher, advertiser, users, settings } =
		useTypedSelector((state) => state)
	const ruleSet = getApplicableRules(users, login, settings, 'finance')
	const headers: any = {
		Authorization: `Token ${login.user.token}`,
		// Accept: 'application/json',
		// "content-type": "multipart/form-data"
	}
	const {
		downloadFinanceMBTemplateAction,
		uploadFinanceMBTemplate,
		getFinanceData,
		uploadMBFinanceRow,
		deleteFinanceRow,
	} = useActions()
	const sessionKey = 'filters-finance-media_buying'
	const sessionSerializedFilters = sessionStorage.getItem(sessionKey)
	let sessionFilters: any = {}
	if (sessionSerializedFilters && sessionSerializedFilters.length > 4) {
		sessionFilters = JSON.parse(sessionSerializedFilters)
	}
	const [file, setFile] = useState(null)
	const fileInputRef = useRef()
	const [pageSize, setPageSize] = useState(15)
	const [update, setUpdate] = useState(false)
	const [filteredFinance, setFilteredFinance] = useState<any>([])
	const rowCount = 10
	const [rowWidth, setRowWidth] = useState(
		Math.floor((window.innerWidth - theme.dimentions.drawerWidth) / rowCount),
	)
	const [tableRows, setTableRows] = useState(
		generateRowsWithIds(filteredFinance),
	)
	const [openFilter, setOpenFilter] = useState(false)
	const [errorFilter, setErrorFilter] = useState<boolean>(
		sessionFilters.errorFilter ? sessionFilters.errorFilter : false,
	)
	const [financeFilterStatus, setFinanceFilterStatus] = useState(
		sessionFilters.financeFilterStatus ? sessionFilters.financeFilterStatus : 0,
	)
	const [unfiltered, setUnfiltered] = useState<any>([])
	const [customFilter, setCustomFilter] = useState<any>(
		sessionFilters.customFilter
			? sessionFilters.customFilter
			: {
					campaign: [],
					platform: [],
					advertiser: [],
					pid: [],
					publisher: [],
			  },
	)
	const apiRef = useGridApiRef()
	const [applyFilter, setApplyFilter] = useState(false)
	const [infoOpened, setInfoOpened] = useState(false)
	const [currentFilters, setCurrentFilters] = useState(
		sessionSerializedFilters && sessionSerializedFilters.length > 4
			? 'advertiser'
			: '',
	)
	const [filterLabel, setFilterLabel] = useState('')
	const [optionsFilters, setOptionsFilter] = useState<any>({
		campaign: [],
		platform: [],
		advertiser: [],
		pid: [],
		publisher: [],
	})
	const [sortModel, setSortModel] = useState([{ field: 'month', sort: 'desc' }])
	const [rowModesModel, setRowModesModel] = React.useState<GridRowModesModel>(
		{},
	)
	const [monthFilterArray, setMonthFilterArray] = useState<any>(
		sessionFilters.monthFilterArray ? sessionFilters.monthFilterArray : [],
	)
	const [yearFilterArray, setYearFilterArray] = useState<any>(
		sessionFilters.yearFilterArray ? sessionFilters.yearFilterArray : [],
	)
	const [pinnedBottomRow, setPinnedBottomRow] = useState<any>([])
	const [smallLoading, setSmallLoading] = useState(true)
	const advertiserList = advertiser.advertiser.map(
		(el: any) => el.advertiser_name,
	)
	//this is the function we use to download the template. We open a blank page in order to not update the current page.
	const downloadTemplate = async () => {
		const url: any = await downloadFinanceMBTemplateAction(headers)
		window.open(url, '_blank')
	}
	const fileSelectedHandler = (event: any) => {
		setFile(event.target.files[0])
	}
	const fileInputClickHandler = () => {
		setFile(null)
		;(fileInputRef.current as any).value = ''
		;(fileInputRef.current as any).click()
	}
	const [dataFetched, setDataFetched] = useState(false)
	const dispatch = useDispatch()
	const hasFetched = useRef(false)
	const fetchData = async () => {
		let i = 0
		let result
		do {
			result = await getFinanceData(
				headers,
				'mediabuying',
				props.errorMessage,
				login.user.email,
				500,
				i,
			)
			i++
			setDataFetched(true)
			if (result.length === 0) {
				setSmallLoading(false)
			}
		} while (result && result.length > 0)
	}
	//function to upload data with the excel file.
	const uploadData = async () => {
		if (!file) {
			return
		}
		props.setLoading(true)
		const formData = new FormData()
		formData.append('finance', file)
		formData.append('email', login.user.email)
		for (let pair of formData.entries()) {
			console.log(pair[0] + ': ' + pair[1])
		}
		const failed = await uploadFinanceMBTemplate(
			headers,
			formData,
			props.setErrorMessage,
		)
		if (!failed) {
			props.setIsSuccessPromptOpened(true)
			fetchData()
			setFile(null)
			;(fileInputRef.current as any).value = ''
		}
		props.setLoading(false)
	}

	useEffect(() => {
		if (file) {
			uploadData()
		}
	}, [file])

	useEffect(() => {
		dispatch(emptyFinance())
		//this needs to be added for the StrictMode. Otherwise data is duplicated.
		if (hasFetched.current) {
			return
		}
		hasFetched.current = true
		fetchData()
		const sessionSerializedFilters = sessionStorage.getItem(sessionKey)
		if (sessionSerializedFilters && sessionSerializedFilters.length > 4) {
			const sessionFilters = JSON.parse(sessionSerializedFilters)
			setCurrentFilters('advertiser')
			sessionFilters.customFilter &&
				setCustomFilter(sessionFilters.customFilter)
			sessionFilters.monthFilterArray &&
				setMonthFilterArray(sessionFilters.monthFilterArray)
			sessionFilters.yearFilterArray &&
				setYearFilterArray(sessionFilters.yearFilterArray)
			sessionFilters.errorFilter && setErrorFilter(sessionFilters.errorFilter)
			sessionFilters.financeFilterStatus &&
				setFinanceFilterStatus(sessionFilters.financeFilterStatus)
		}
	}, [])
	useEffect(() => {
		if (filterLabel) {
			const filters = {
				customFilter,
				monthFilterArray,
				yearFilterArray,
				errorFilter,
				financeFilterStatus,
			}
			const serializedFilters = JSON.stringify(filters)
			sessionStorage.setItem(sessionKey, serializedFilters)
		}
	}, [
		applyFilter,
		monthFilterArray,
		yearFilterArray,
		errorFilter,
		financeFilterStatus,
	])

	useEffect(() => {
		setFilteredFinance(
			Array.isArray(finance.finance)
				? finance.finance.filter(
						(el: any) => el.media_buying_owner === login.user.email,
				  )
				: [],
		)
	}, [finance.finance])

	useEffect(() => {
		if (dataFetched) {
			setOptionsFilter({
				campaign: new Set(
					tableRows
						.map((element: any) => element.campaign)
						.filter((el) => el.length > 0),
				),
				platform: new Set(
					tableRows
						.map((element: any) => element.platform)
						.filter((el) => el.length > 0),
				),
				advertiser: new Set(
					tableRows
						.map((element: any) => element.advertiser)
						.filter((el) => el.length > 0),
				),
				pid: new Set(
					tableRows
						.map((element: any) => element.pid)
						.filter((el) => el.length > 0),
				),
				publisher: new Set(
					tableRows
						.map((element: any) => element.publisher)
						.filter((el) => el.length > 0),
				),
			})
		}
	}, [tableRows])

	//same filter that we always use. In this case, instead of 3 status we have 4. The logic behind relies the same.
	const possibleFinanceFilter = ['All', 'Yes', 'No', 'On Hold', 'NA']
	useEffect(() => {
		if (dataFetched) {
			props.setLoading(true)
		}
		let filtered: any
		if (props.search.length > 0) {
			let searched = searchFor(props.search, filteredFinance, columns)
			if (possibleFinanceFilter[financeFilterStatus].toLowerCase() === 'all') {
				filtered = searched
			} else if (
				possibleFinanceFilter[financeFilterStatus].toLowerCase() === 'yes'
			) {
				filtered = searched.filter(
					(el: any) => el.status.toLowerCase() === 'yes',
				)
			} else if (
				possibleFinanceFilter[financeFilterStatus].toLowerCase() === 'no'
			) {
				filtered = searched.filter(
					(el: any) => el.status.toLowerCase() === 'no',
				)
			} else if (
				possibleFinanceFilter[financeFilterStatus].toLowerCase() === 'on hold'
			) {
				filtered = searched.filter(
					(el: any) => el.status.toLowerCase() === 'on hold',
				)
			} else if (
				possibleFinanceFilter[financeFilterStatus].toLowerCase() === 'na'
			) {
				filtered = searched.filter(
					(el: any) => el.status.toLowerCase() === 'na',
				)
			}
		} else {
			if (possibleFinanceFilter[financeFilterStatus].toLowerCase() === 'all') {
				filtered = filteredFinance
			} else if (
				possibleFinanceFilter[financeFilterStatus].toLowerCase() === 'yes'
			) {
				filtered = filteredFinance.filter(
					(el: any) => el.status.toLowerCase() === 'yes',
				)
			} else if (
				possibleFinanceFilter[financeFilterStatus].toLowerCase() === 'no'
			) {
				filtered = filteredFinance.filter(
					(el: any) => el.status.toLowerCase() === 'no',
				)
			} else if (
				possibleFinanceFilter[financeFilterStatus].toLowerCase() === 'on hold'
			) {
				filtered = filteredFinance.filter(
					(el: any) => el.status.toLowerCase() === 'on hold',
				)
			} else if (
				possibleFinanceFilter[financeFilterStatus].toLowerCase() === 'na'
			) {
				filtered = filteredFinance.filter(
					(el: any) => el.status.toLowerCase() === 'na',
				)
			}
		}
		let arr = []
		let anyfilters = false
		let additionalFiltersApplied = []
		//this is used to filter by the buttons of the columns headers.
		for (let [index, data] of filtered.entries()) {
			// let found = false
			let foundWrong = false

			for (const key in customFilter) {
				// let wrongKey = false
				let foundByKey = false

				if (customFilter[key].length > 0) {
					anyfilters = true
					foundByKey = customFilter[key].includes(data[key])
					if (!foundByKey) {
						foundWrong = true
						break
					}
				}
			}
			if (!foundWrong) {
				additionalFiltersApplied.push(data)

				continue
			}
		}
		if (!anyfilters) {
			arr = filtered
		} else if (additionalFiltersApplied.length > 0 && anyfilters) {
			arr = additionalFiltersApplied
		} else {
			arr = []
		}
		//this handles the year
		if (yearFilterArray.length === 0) {
			arr = generateRowsWithIds(arr)
		} else {
			const filteredData = arr.filter((item: any) => {
				const year = getYear(item.month)
				if (
					yearFilterArray.includes('This Year') &&
					year === moment().year().toString()
				) {
					return true
				}
				return yearFilterArray.includes(year)
			})
			arr = generateRowsWithIds(filteredData)
		}
		//this handles the months
		if (monthFilterArray.length === 0) {
			arr = generateRowsWithIds(arr)
		} else {
			const arrToFilter = arr
			const monthAbbrevatedFilteredArray = monthFilterArray.map((el: string) =>
				returnAbbreviatedMonth(el),
			)
			const filteredData = arrToFilter.filter((item: any) => {
				const monthAbbreviation = getMonthAbbreviation(item.month)
				return monthAbbrevatedFilteredArray.includes(monthAbbreviation)
			})
			arr = generateRowsWithIds(filteredData)
		}
		if (errorFilter) {
			const errorRows = arr
				.map((el: any) => {
					if (
						!el.publisher ||
						!advertiserList.includes(el.advertiser) ||
						(!el.revenue && el.revenue !== '0')
					) {
						return el
					}
					return undefined
				})
				.filter((el: any) => el !== undefined)
			// setTableRows(errorRows)
			arr = errorRows
		}
		setTableRows(arr)
		setUnfiltered(arr)
		setPinnedBottomRow(calculatePinnedTotalRow(arr))
		if (dataFetched) {
			props.setLoading(false)
		}
	}, [
		financeFilterStatus,
		props.search,
		monthFilterArray,
		yearFilterArray,
		applyFilter,
		filteredFinance,
		errorFilter,
	])

	const handleApplyFilter = () => {
		setApplyFilter(!applyFilter)
	}
	//these next functions are used to track the edit, remove, save and cancel of an specific row.
	const handleEditClick = (id: any) => () => {
		setRowModesModel({ ...rowModesModel, [id]: { mode: GridRowModes.Edit } })
	}
	const handleSaveClick = (id: any) => () => {
		setRowModesModel({ ...rowModesModel, [id]: { mode: GridRowModes.View } })
	}
	const handleCancelClick = (id: any) => () => {
		setRowModesModel({
			...rowModesModel,
			[id]: { mode: GridRowModes.View, ignoreModifications: true },
		})
	}
	const handleDeleteClick = (id: any) => async () => {
		const deleted = window.confirm(
			'Deleting this will remove the entire row from the database. Are you sure?',
		)
		if (deleted) {
			let rowToRemove
			const arr = tableRows.filter((element: any) => {
				if (element.id !== id) {
					return true
				} else {
					rowToRemove = element
					return false
				}
			})
			setTableRows(arr)
			setPinnedBottomRow(calculatePinnedTotalRow(arr))
			await deleteFinanceRow(
				headers,
				rowToRemove,
				login.user.email,
				props.setErrorMessage,
			)
		}
	}
	const handleRowEditStop: GridEventListener<'rowEditStop'> = (
		params,
		event,
	) => {
		if (params.reason === GridRowEditStopReasons.rowFocusOut) {
			event.defaultMuiPrevented = true
		}
	}
	const columns: GridColDef[] = [
		{
			field: 'campaign',
			headerName: 'Campaign',
			width: rowWidth * 1.4,
			editable: true,
			renderHeader: () => {
				return (
					<HeaderFilterComponent
						label={'Campaign'}
						width={rowWidth * 1.2}
						openFilter={openFilter}
						setOpenFilter={setOpenFilter}
						field={'campaign'}
						customFilter={customFilter}
						currentFilters={currentFilters}
						setCurrentFilters={setCurrentFilters}
						setFilterLabel={setFilterLabel}
						optionsFilters={optionsFilters}
					/>
				)
			},
			renderCell: (row) => {
				if (row.row.id === 9999999999) {
					return (
						<span
							style={{
								fontWeight: theme.font.weight.normal,
								fontSize: theme.font.size.body,
								justifyContent: row.id === 9999999999 ? 'center' : 'left',
								display: 'flex',
								width: '100%',
							}}
						>
							{row.value}
							{smallLoading && (
								<div style={{ display: 'relative' }}>
									<SmallLoading />
								</div>
							)}
						</span>
					)
				}
				return (
					<Typography
						sx={{
							fontWeight: 600,
							fontSize: 14,
							justifyContent: row.id === 9999999999 ? 'center' : 'left',
							display: 'flex',
							width: '100%',
						}}
					>
						{row.value}
					</Typography>
				)
			},
		},
		{
			field: 'agency_account',
			headerName: 'Agency Account',
			width: rowWidth * 0.7,
			align: 'center',
			renderCell: (row) => {
				if (row.row.agency_account === 'Other') {
					return <span>{row.row.agency_account_specification}</span>
				} else {
					return <span>{row.row.agency_account}</span>
				}
			},
		},
		{
			field: 'platform',
			headerName: 'Platform',
			width: rowWidth * 0.8,
			editable: true,
			type: 'singleSelect',
			align: 'center',
			valueOptions: ['Affise', 'MB'],
			renderHeader: () => {
				return (
					<HeaderFilterComponent
						label={'Platform'}
						width={rowWidth * 1.2}
						openFilter={openFilter}
						setOpenFilter={setOpenFilter}
						field={'platform'}
						customFilter={customFilter}
						currentFilters={currentFilters}
						setCurrentFilters={setCurrentFilters}
						setFilterLabel={setFilterLabel}
						optionsFilters={optionsFilters}
					/>
				)
			},
			cellClassName: (params) => {
				if (params.colDef.editable === false) {
					return 'is-not-editable'
				}
				return 'is-editable'
			},
		},
		{
			field: 'advertiser',
			headerName: 'Advertiser',
			width: rowWidth,
			editable: true,
			type: 'singleSelect',
			renderHeader: () => {
				return (
					<HeaderFilterComponent
						label={'Advertiser'}
						width={rowWidth * 1.2}
						openFilter={openFilter}
						setOpenFilter={setOpenFilter}
						field={'advertiser'}
						customFilter={customFilter}
						currentFilters={currentFilters}
						setCurrentFilters={setCurrentFilters}
						setFilterLabel={setFilterLabel}
						optionsFilters={optionsFilters}
					/>
				)
			},
			renderEditCell: (params) => {
				//if the advertiser exists in the database it will have account manager saved so the user shouldn't change it to another advertiser.
				if (params.row.account_manager) {
					return <span>{params.row.advertiser}</span>
				}
				let options = []
				if (params && params.row && advertiser.advertiser) {
					options = findAdvertiserByOwner(
						params.row.owner,
						advertiser.advertiser,
					)
					if (!options.includes(params.row.advertiser)) {
						options.unshift(params.row.advertiser)
					}
				}
				return (
					<DropList
						onChange={(e: string) => {
							apiRef.current.setEditCellValue({
								id: params.row.id,
								field: 'advertiser',
								value: e,
							})
						}}
						options={options}
						label={''}
						value={params.value}
						noBackground
						autoCompleteStyle={{
							border: 'none',
							borderRight: 'none',
						}}
					/>
				)
			},
		},
		{
			field: 'pid',
			headerName: 'Pid',
			width: rowWidth,
			editable: true,
			renderHeader: () => {
				return (
					<HeaderFilterComponent
						label={'Pid'}
						width={rowWidth * 1.2}
						openFilter={openFilter}
						setOpenFilter={setOpenFilter}
						field={'pid'}
						customFilter={customFilter}
						currentFilters={currentFilters}
						setCurrentFilters={setCurrentFilters}
						setFilterLabel={setFilterLabel}
						optionsFilters={optionsFilters}
					/>
				)
			},
			cellClassName: (params) => {
				if (params.colDef.editable === false) {
					return 'is-not-editable'
				}
				return 'is-editable'
			},
		},
		{
			field: 'publisher',
			headerName: 'Publisher',
			width: rowWidth * 0.9,
			editable: true,
			type: 'singleSelect',
			renderHeader: () => {
				return (
					<HeaderFilterComponent
						label={'Publisher'}
						width={rowWidth * 1.2}
						openFilter={openFilter}
						setOpenFilter={setOpenFilter}
						field={'publisher'}
						customFilter={customFilter}
						currentFilters={currentFilters}
						setCurrentFilters={setCurrentFilters}
						setFilterLabel={setFilterLabel}
						optionsFilters={optionsFilters}
					/>
				)
			},
			renderEditCell: (params) => {
				let options = []
				if (params && params.row && publisher.publisher) {
					options = findPublisherFromPid(params.row.pid, publisher.publisher)
				}

				return (
					<DropList
						onChange={(e: string) => {
							apiRef.current.setEditCellValue({
								id: params.row.id,
								field: 'publisher',
								value: e,
							})
						}}
						options={options}
						label={''}
						value={params.value}
						noBackground
						autoCompleteStyle={{
							border: 'none',
							borderRight: 'none',
						}}
					/>
				)
			},
			cellClassName: (params) => {
				if (params.colDef.editable === false) {
					return 'is-not-editable'
				}
				return 'is-editable'
			},
		},
		{
			field: 'cost',
			headerName: 'Cost',
			width: rowWidth * 0.6,
			type: 'number',
			align: 'left',
			headerAlign: 'left',
			editable: true,
			renderCell: (row) => {
				const value = row.value
				if (row.id === 9999999999) {
					return (
						<span>
							{Number(value).toLocaleString('en-US', {
								minimumFractionDigits: 2,
								maximumFractionDigits: 2,
							})}
						</span>
					)
				}
				return <span>{Number(value).toFixed(2)}</span>
			},
		},
		{
			field: 'approved_conversions',
			headerName: 'Conversion',
			width: rowWidth * 0.8,
			type: 'number',
			align: 'left',
			headerAlign: 'left',
			editable: true,
			renderCell: (row) => {
				const value = row.value
				if (row.id === 9999999999) {
					return <span>{Number(value).toLocaleString('en-US')}</span>
				}
				return <span>{Number(value)}</span>
			},
		},
		{
			field: 'month',
			headerName: 'Period',
			headerAlign: 'center',
			align: 'center',
			width: rowWidth * 0.6,
			renderCell: ({ value }) => {
				const date = formatDateToMonth(value)
				return <Typography sx={{ fontSize: 14 }}>{date}</Typography>
			},
			sortComparator: (str1, str2) => {
				return sortHardcodedDates(str1, str2)
			},
		},
		{
			field: 'status',
			headerName: 'Status',
			headerAlign: 'center',
			align: 'center',
			width: rowWidth * 0.8,
			type: 'singleSelect',
			renderCell: ({ value }) => {
				if (value === '') {
					return <></>
				}
				return (
					<div
						style={{
							borderRadius: '20px',
							color: handleFinanceStatusColor(
								value === 'On Hold' ? 'on hold yellow' : value,
							),
							width: '70px',
							height: '30px',
							display: 'flex',
							alignItems: 'center',
							justifyContent: 'center',
							textTransform: 'uppercase',
							fontWeight: theme.font.weight.bolder,
							fontSize: theme.font.size.caption,
						}}
					>
						{value}
					</div>
				)
			},
		},
		(ruleSet.post || ruleSet.delete) && {
			field: 'actions',
			type: 'actions',
			headerName: 'Actions',
			width: 100,
			cellClassName: 'actions',
			getActions: ({ id }) => {
				const isInEditMode = rowModesModel[id]?.mode === GridRowModes.Edit
				//9999999999 this id is the hardcoded id we use for the bottom pinned row.
				if (id === 9999999999) {
					return []
				}
				//based on if the edit mode is triggered we will have the options to save, cancel or edit, remove
				if (isInEditMode) {
					return [
						<GridActionsCellItem
							icon={
								<button
									style={{
										display: 'flex',
										alignItems: 'center',
										border: 'none',

										height: '28px',
										width: '28px',
										fontSize: '16px',
									}}
									className={'action-button'}
								>
									<CheckIcon fontSize={'inherit'} />
								</button>
							}
							label='Save'
							sx={{
								color: 'primary.main',
							}}
							onClick={handleSaveClick(id)}
						/>,
						<GridActionsCellItem
							icon={
								<button
									style={{
										display: 'flex',
										alignItems: 'center',
										border: 'none',

										height: '28px',
										width: '28px',
										fontSize: '16px',
									}}
									className={'action-button'}
								>
									<ClearIcon fontSize={'inherit'} />
								</button>
							}
							label='Cancel'
							className='textPrimary'
							onClick={handleCancelClick(id)}
							color='inherit'
						/>,
					]
				}

				return [
					ruleSet.post ? (
						<GridActionsCellItem
							icon={
								<button
									style={{
										display: 'flex',
										alignItems: 'center',
										border: 'none',

										height: '28px',
										width: '28px',
										cursor: 'pointer',
										fontSize: '16px',
									}}
									className={'action-button'}
								>
									<EditIcon
										style={{ color: 'inherit', scale: '0.8' }}
										className={'action-button'}
									/>
								</button>
							}
							label='Edit'
							className='textPrimary'
							onClick={handleEditClick(id)}
							color='inherit'
						/>
					) : (
						<></>
					),
					ruleSet.delete ? (
						<GridActionsCellItem
							icon={
								<button
									style={{
										display: 'flex',
										alignItems: 'center',
										border: 'none',
										height: '28px',
										width: '28px',
										fontSize: '16px',
									}}
									className={'action-button'}
								>
									<DeleteIcon
										style={{ color: 'inherit', scale: '0.8' }}
										className={'action-button'}
									/>
								</button>
							}
							label='Edit'
							className='textPrimary'
							onClick={handleDeleteClick(id)}
							color='inherit'
						/>
					) : (
						<></>
					),
				]
			},
		},
	]
	//this is the logic we use to update the rows everytime a change is done.
	//for every time the user presses "save" it will trigger the update.
	const handleRowModesModelChange = (newRowModesModel: GridRowModesModel) => {
		setRowModesModel(newRowModesModel)
	}
	const processRowUpdate = async (newRow: any) => {
		const updatedRow = { ...newRow, isNew: false }
		const oldRow = tableRows.find((el: any) => el.uuid === newRow.uuid)
		// console.log(oldRow)
		const data = { old_row: oldRow, new_row: newRow }
		const failed = await uploadMBFinanceRow(
			headers,
			data,
			login.user.email,
			props.setErrorMessage,
		)
		const selectedAdvertiser = advertiser.advertiser.filter(
			(el: any) =>
				el.advertiser_name.toLowerCase() ===
				updatedRow.advertiser.toLowerCase(),
		)
		if (selectedAdvertiser.length > 0) {
			updatedRow.account_manager = selectedAdvertiser[0].email
		}
		updatedRow.final_amount = updatedRow.cost - updatedRow.deduction_amount
		if (!failed) {
			const arr = tableRows.map((row: any) =>
				row.uuid === newRow.uuid ? updatedRow : row,
			)
			setTableRows(arr)
			setPinnedBottomRow(calculatePinnedTotalRow(arr))
		} else {
			const notUpdatedData = [...tableRows]
			setTableRows(notUpdatedData)
			setPinnedBottomRow(calculatePinnedTotalRow(notUpdatedData))
		}
		return updatedRow
	}
	//we do this in order to prevent the double click to allow to enter the edit mode.
	const handleDoubleCellClick = React.useCallback((params, event) => {
		event.defaultMuiPrevented = true
	}, [])

	// Prevent from rolling back on escape
	const handleCellKeyDown = React.useCallback((params, event) => {
		if (
			['Escape', 'Delete', 'Backspace', 'Enter'].includes(
				(event as React.KeyboardEvent).key,
			)
		) {
			event.defaultMuiPrevented = true
		}
	}, [])

	// Prevent from committing on focus out
	const handleCellFocusOut = React.useCallback((params, event) => {
		if (params.cellMode === 'edit' && event) {
			event.defaultMuiPrevented = true
		}
	}, [])
	//basic function for style
	const handleRowStyle = (params: any) => {
		const row = params.row
		const isInEditMode = rowModesModel[row.id]?.mode === GridRowModes.Edit
		const advertiserRegistered = advertiserList.includes(row.advertiser)
		//9999999999 this id is the hardcoded id we use for the bottom pinned row.
		if (row.id === 9999999999) {
			return 'pinned-row'
		}
		if ((!advertiserRegistered || row.publisher === '') && !isInEditMode) {
			return 'personal-row-no-data'
		}
		if (!row.revenue && row.revenue !== '0' && !isInEditMode) {
			return 'warning'
		}
		return ''
	}

	const renderCustomMessage = (hoveredRow: any, position: any) => {
		let advertiserIsRegistered = true
		if (advertiserList) {
			advertiserIsRegistered = advertiserList.includes(hoveredRow.advertiser)
		}
		let possiblePublishers = []
		if (publisher.publisher) {
			possiblePublishers = findPublisherFromPid(
				hoveredRow.pid,
				publisher.publisher,
			)
		}
		let isWarning = false
		if (
			advertiserIsRegistered &&
			hoveredRow.publisher &&
			!hoveredRow.revenue &&
			hoveredRow.revenue !== '0'
		) {
			isWarning = true
		}
		return (
			(!advertiserIsRegistered ||
				!hoveredRow.publisher ||
				(!hoveredRow.revenue && hoveredRow.revenue !== '0')) && (
				<div
					style={{
						position: 'absolute',
						top: position.y,
						left: position.x,
						width: 'auto',
						height: 'auto',
						backgroundColor: theme.colors.base.white,
						border: isWarning
							? '1px solid rgba(218, 174, 31, 1)'
							: '1px solid rgb(196,91,80)',
						display: 'flex',
						borderRadius: '4px',
					}}
				>
					<span
						style={{
							color: isWarning ? 'rgba(218, 174, 31, 1)' : 'rgb(196,91,80)',
							fontSize: '14px',
							backgroundColor: isWarning
								? 'rgba(248, 208, 79, 0.6)'
								: 'rgba(240,215,217,1)',
							padding: '8px 30px',
							display: 'flex',
							flexDirection: 'column',
						}}
					>
						{!advertiserIsRegistered && (
							<span>
								The advertiser{' '}
								<span style={{ fontWeight: '600' }}>
									{hoveredRow.advertiser}
								</span>{' '}
								is not registered in Varys.
							</span>
						)}
						{!hoveredRow.publisher && possiblePublishers.length > 20 && (
							<span>
								The row is missing a{' '}
								<span style={{ fontWeight: '600' }}>publisher.</span> The PID
								was not found.
							</span>
						)}
						{!hoveredRow.publisher && possiblePublishers.length <= 20 && (
							<span>
								This PID is connected to{' '}
								<span style={{ fontWeight: '600' }}>more than one</span>{' '}
								publishers.
							</span>
						)}
						{!hoveredRow.revenue && hoveredRow.revenue !== '0' && (
							<span>
								The row is missing{' '}
								<span style={{ fontWeight: '600' }}>the revenue</span> from the
								Account Manager.
							</span>
						)}
					</span>
				</div>
			)
		)
	}

	const guidelinesIntro = [
		{
			explanation:
				'Upload the number that you see on every media buying platform, then the AM will add the revenue from their Personal area. The deadline for adding your numbers is the 10th of each month.',
		},
	]

	const guidelines = [
		{
			title: 'Campaign',
			explanation:
				'Name of the Campaign. \nExample: William Hill - iOS - UK - CPA.',
		},
		{
			title: 'Agency Account',
			explanation:
				"Specify the agency account, choosing between: \nThing Or Two, Tatanka, smartassmeil951, and weknowil870. If 'Other' is chosen, provide details in the 'Agency Account Specification' column.",
		},
		{
			title: 'Advertiser',
			explanation:
				'Name of your advertiser written in the same way that is written in Varys - Demand - Advertiser.',
		},
		{ title: 'Pid', explanation: 'PID name. \nExample: siftco_int .' },
		{
			title: 'Publisher',
			explanation:
				'Publisher name. It is not necessary to add the publisher name, it will come automatically from the system when you write the pid.',
		},
		{
			title: 'Cost',
			explanation:
				'(Before deduction) Amount payable to the publisher before deductions for the total conversions.',
		},
		{
			title: 'Conversion',
			explanation:
				'(After deduction) Approved number of conversions per publisher per PID after deductions.',
		},
		{
			title: 'Period',
			explanation:
				'Activity Month + Year. \nFor Example, Jan/2024, Feb/2024, Mar/2024, Apr/2024, May/2024, Jun/2024, Jul/2024, Aug/2024.',
		},
		{
			title: 'Status',
			explanation: `3 different statuses: \n   * On Hold: you add the numbers but they are yet to be confirmed by the advertiser. \n   * Yes: The line is approved and we will pay our source that specific amount indicated in the total amount approved. \n   * No: unconfirmed or fully deducted.`,
		},
	]

	return (
		<ThemeProvider theme={theme}>
			{infoOpened && (
				<div
					style={{
						background: 'rgba(0,0,0,0.4)',
						width: '100%',
						height: '100%',
						zIndex: '20',
						display: 'flex',
						position: 'absolute',
						top: '0',
						left: '0',
					}}
				></div>
			)}
			<PageWrapper>
				<input
					type='file'
					onChange={fileSelectedHandler}
					ref={fileInputRef as any}
					style={{ display: 'none' }}
				/>
				{infoOpened && (
					<Guidelines
						data={guidelines}
						closeFunc={() => setInfoOpened(false)}
						intro={guidelinesIntro}
					></Guidelines>
				)}
				<TableWrapper
					theme={theme}
					rowCount={rowCount}
					setRowWidth={setRowWidth}
					style={{ paddingBottom: '60px', width: '90%' }}
				>
					<TableComponent
						columns={columns}
						rows={tableRows}
						setPageSize={setPageSize}
						pageSize={pageSize}
						rowsPerPageOptions={[15, 25, 50]}
						setLoading={props.setLoading}
						filterStatus={financeFilterStatus}
						setFilterStatus={setFinanceFilterStatus}
						rowHeight={'auto'}
						sortModel={sortModel}
						onSortModelChange={(model: any) => setSortModel(model)}
						filterHandler={openFilter}
						setFilterHander={setOpenFilter}
						customFilter={customFilter}
						setCustomFilter={setCustomFilter}
						currentFilters={currentFilters}
						applyFilter={handleApplyFilter}
						filterLabel={filterLabel}
						optionsFilters={optionsFilters}
						mainFilterOptions={possibleFinanceFilter}
						rowModesModel={rowModesModel}
						handleRowModesModelChange={handleRowModesModelChange}
						processRowUpdate={processRowUpdate}
						handleRowEditStop={handleRowEditStop}
						handleDoubleCellClick={handleDoubleCellClick}
						handleCellKeyDown={handleCellKeyDown}
						handleCellFocusOut={handleCellFocusOut}
						monthFilterArray={monthFilterArray}
						setMonthFilterArray={setMonthFilterArray}
						yearFilterArray={yearFilterArray}
						setYearFilterArray={setYearFilterArray}
						downloadTemplate={downloadTemplate}
						fileInputClickHandler={
							ruleSet.post ? fileInputClickHandler : undefined
						}
						getRowClassName={handleRowStyle}
						customSx={{
							'& .MuiDataGrid-columnHeaderTitle': {
								lineHeight: '18px',
								whiteSpace: 'pre-line',
							},
							'& .personal-row-no-data.MuiDataGrid-row:hover': {
								backgroundColor: 'rgba(252, 234, 235)',
							},
							'& ::-webkit-scrollbar': {
								height: '7px',
								width: '10px',
							},
						}}
						customMessage={renderCustomMessage}
						pinnedBottomRow={pinnedBottomRow}
						apiRef={apiRef}
						searchWithTimer={true}
						checkboxButtonValue={errorFilter}
						setCheckboxButtonValue={setErrorFilter}
						checkboxButtonLabel={'Only Errors'}
						infoOpened={infoOpened}
						setInfoOpened={setInfoOpened}
					></TableComponent>
				</TableWrapper>
			</PageWrapper>
		</ThemeProvider>
	)
}

export default MediaBuyingPlatform

const calculatePinnedTotalRow = (rows: any) => {
	const pinnedRow = {
		campaign: 'TOTAL',
		advertiser: '',
		pid: '',
		cost: 0,
		approved_conversions: 0,
		period: '',
		status: '',
		publisher: '',
		id: 9999999999,
	}
	for (const row of rows) {
		if (row.cost) {
			pinnedRow.cost = Number(pinnedRow.cost) + Number(row.cost)
		}
		if (row.approved_conversions) {
			pinnedRow.approved_conversions =
				Number(pinnedRow.approved_conversions) +
				Number(row.approved_conversions)
		}
	}
	pinnedRow.cost = Number(pinnedRow.cost).toFixed(2) as any
	return [pinnedRow]
}
