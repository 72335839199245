import React, { memo } from 'react'
import theme from '../../utils/theme2024'
import { PlayCircleOutline } from '@mui/icons-material'

// sdk
const SDKSportsBannerTemplate = (props: { template: any }) => {
	const { smallBannerPreview } = props.template
	return (
		<>
			{
				<div
					style={{
						position: 'absolute',
						width: 185,
						height: 30,
						top: '44.6%',
						right: '20.5%',
					}}
				>
					{smallBannerPreview && (
						<img
							src={smallBannerPreview}
							style={{ width: '100%', height: '100%' }}
						/>
					)}
				</div>
			}
		</>
	)
}

const SDKShoppingBannerTemplate = (props: { template: any }) => {
	const { smallBannerPreview } = props.template
	return (
		<>
			{
				<div
					style={{
						position: 'absolute',
						width: 185,
						height: 30,
						top: '82%',
						right: '20.5%',
					}}
				>
					{smallBannerPreview && (
						<img
							src={smallBannerPreview}
							style={{ width: '100%', height: '100%' }}
						/>
					)}
				</div>
			}
		</>
	)
}

const SDKGamingBannerTemplate = (props: { template: any }) => {
	const { smallBannerPreview } = props.template
	return (
		<>
			{
				<div
					style={{
						position: 'absolute',
						width: 191,
						height: 30,
						top: '12%',
						right: '19.5%',
					}}
				>
					{smallBannerPreview && (
						<img
							src={smallBannerPreview}
							style={{ width: '100%', height: '100%' }}
						/>
					)}
				</div>
			}
		</>
	)
}

const SDKVideoTemplate = (props: { template: any }) => {
	const { videoPreview, horizontal } = props.template

	return (
		<>
			<div
				id='play'
				style={{
					position: 'absolute',
					top: '40%',
					left: '40%',
					transform:
						'translate(-50%, -50%)' + horizontal ? 'rotate(270deg)' : '',
					zIndex: 0,
					color: '#e0e0e0',
				}}
			>
				<PlayCircleOutline sx={{ width: '75px', height: '75px' }} />
			</div>
			{videoPreview ? (
				<div
					style={{
						position: 'absolute',
						width: '420px',
						height: '220px',
						top: '30%',
						right: '-19%',
						transform: horizontal ? 'rotate(270deg)' : '',
						borderRadius: '4px',
						backgroundColor: 'black',
					}}
				>
					<video
						src={videoPreview}
						style={{ width: '100%', height: '100%' }}
						autoPlay
						loop
						muted
					/>
				</div>
			) : (
				<div
					style={{
						backgroundColor: 'black',
						position: 'absolute',
						width: 420,
						height: 220,
						top: '30%',
						right: '-19%',
						transform: horizontal ? 'rotate(270deg)' : '',
					}}
				></div>
			)}
		</>
	)
}

const SDKInterstitialTemplate = (props: {
	template: any
	headline: string | null
	cta: string | null
}) => {
	const { interstitialPreview } = props.template
	return (
		<>
			<div
				style={{
					backgroundColor: 'black',
					width: '220px',
					height: '465px',
					borderRadius: '30px',
					position: 'absolute',
					top: '6.9%',
					right: '14.4%',
				}}
			>
				<div
					style={{
						position: 'absolute',
						top: '1%',
						right: '1%',
						color: '#FFF',
						borderRadius: '50%',
						fontSize: '15px',
						padding: '15px',
					}}
				>
					X
				</div>
				<div
					style={{
						position: 'absolute',
						top: '83%',
						right: '13%',
						color: '#FFF',
						borderRadius: '50%',
						fontSize: '14px',
						padding: '15px',
						display: 'flex',
						flexDirection: 'column',
						alignItems: 'center',
						gap: '4px',
					}}
				>
					<span style={{ fontSize: '12px' }}>{props.headline}</span>
					<span
						style={{
							background: theme.colors.base.green300,
							color: theme.colors.base.white,
							width: '128px',
							borderRadius: '25px',
							textAlign: 'center',
							paddingBlock: '3px',
							fontWeight: 600,
						}}
					>
						{props.cta}
					</span>
				</div>
				<div
					style={{
						position: 'absolute',
						width: 218,
						height: 330,
						top: '14%',
						right: '0.5%',
						zIndex: 3,
						borderRadius: '4px',
					}}
				>
					{interstitialPreview && (
						<img
							src={interstitialPreview}
							style={{
								borderRadius: '4px',
								objectFit: 'contain',
								width: '100%',
								height: '100%',
							}}
						/>
					)}
				</div>
			</div>
		</>
	)
}

// oem
const OEMSearchPageTemplate = (props: {
	template: any
	headline: string | null
}) => {
	const { iconPreview } = props.template
	return (
		<>
			{iconPreview ? (
				<div
					style={{
						position: 'absolute',
						width: '22px',
						height: '22px',
						top: '38%',
						left: '17%',
						borderRadius: '4px',
						display: 'flex',
						flexDirection: 'column',
						alignItems: 'center',
						gap: '3px',
					}}
				>
					<img
						src={iconPreview}
						style={{ borderRadius: '4px', width: '100%', height: '100%' }}
					/>
					<span
						style={{
							whiteSpace: 'nowrap',
							fontSize: '7px',
							marginInlineStart: '2px',
						}}
					>
						{props.headline}
					</span>
				</div>
			) : (
				<div
					style={{
						backgroundColor: 'black',
						position: 'absolute',
						width: '25px',
						height: '25px',
						top: '38%',
						left: '17%',
						borderRadius: '4px',
					}}
				></div>
			)}
		</>
	)
}

const OEMHomePageTemplate = (props: {
	template: any
	headline: string | null
}) => {
	const { iconPreview } = props.template
	return (
		<>
			{iconPreview ? (
				<div
					style={{
						position: 'absolute',
						width: '32px',
						height: '32px',
						top: '27%',
						left: '66%',
						borderRadius: '4px',
						display: 'flex',
						flexDirection: 'column',
						alignItems: 'center',
						gap: '4px',
					}}
				>
					<img
						src={iconPreview}
						style={{ borderRadius: '4px', width: '100%', height: '100%' }}
					/>
					<div
						style={{
							display: 'flex',
							alignItems: 'center',
						}}
					>
						<div
							style={{
								backgroundColor: 'rgb(18 176 228)',
								width: '3px',
								height: '3px',
								borderRadius: '50%',
							}}
						></div>
						<span
							style={{
								whiteSpace: 'nowrap',
								color: 'white',
								fontSize: '7px',
								marginInlineStart: '2px',
							}}
						>
							{props.headline}
						</span>
					</div>
				</div>
			) : (
				<div
					style={{
						backgroundColor: 'black',
						position: 'absolute',
						width: '33px',
						height: '33px',
						top: '27%',
						left: '66%',
						borderRadius: '4px',
					}}
				></div>
			)}
		</>
	)
}

const OEMBannerTemplate = (props: {
	template: any
	text: string | null
	cta: string | null
}) => {
	const { bigBannerPreview, iconPreview } = props.template
	return (
		<>
			{bigBannerPreview ? (
				<div
					style={{
						display: 'flex',
						flexDirection: 'column',
						position: 'absolute',
						top: '34%',
						left: '14%',
						gap: '0px',
					}}
				>
					<div
						style={{
							display: 'flex',
							justifyContent: 'space-between',
							alignItems: 'center',
							background: 'white',
						}}
					>
						<div
							style={{
								display: 'flex',
								// flexDirection: 'column',
								alignItems: 'center',
								gap: '4px',
								justifyContent: 'center',
							}}
						>
							{iconPreview && (
								<img
									src={iconPreview}
									style={{
										width: '15px',
										height: '15px',
										top: '27%',
										left: '66%',
										borderRadius: '4px',
									}}
								/>
							)}
							<span
								style={{
									fontSize: '7px',
									color: '#686868',
									textOverflow: 'ellipsis',
									height: '12px',
									width: '130px',
									// whiteSpace: 'nowrap',
									overflow: 'hidden',
								}}
							>
								{props.text}
							</span>
						</div>
						<div
							style={{
								width: '60px',
								paddingBlock: '4px',
								fontSize: '8px',
								color: 'white',
								backgroundColor: '#c4c4c4',
								textAlign: 'center',
								borderRadius: '2px',
								marginInlineStart: '-21px',
								marginBlockStart: '-3px',
								marginBlockEnd: '3px',
							}}
						>
							{props.cta}
						</div>
					</div>
					<div
						style={{
							width: '210px',
							height: '150px',
							borderRadius: '8px',
							display: 'flex',
						}}
					>
						<img
							src={bigBannerPreview}
							style={{
								width: '100%',
								height: '100%',
								borderRadius: '4px',
								objectFit: 'cover',
							}}
						/>
					</div>
				</div>
			) : (
				<div
					style={{
						backgroundColor: 'black',
						position: 'absolute',
						width: '210px',
						height: '130px',
						top: '35%',
						left: '14%',
						borderRadius: '4px',
					}}
				></div>
			)}
		</>
	)
}

//social
const SocialInfluencerTemplate = (props: {
	template: any
	headline: string | null
	text: string | null
	hashtags: string[]
}) => {
	const { iconPreview } = props.template
	return (
		<>
			<div
				style={{
					position: 'absolute',
					width: 25,
					height: 25,
					top: '44%',
					right: '17.5%',
				}}
			>
				{iconPreview && (
					<img
						src={iconPreview}
						style={{ borderRadius: '50%', width: '100%', height: '100%' }}
					/>
				)}
			</div>

			<div
				style={{
					position: 'absolute',
					width: 25,
					height: 25,
					top: '77%',
					right: '17.5%',
				}}
			>
				{iconPreview && (
					<img
						src={iconPreview}
						style={{ borderRadius: '50%', width: '100%', height: '100%' }}
					/>
				)}
			</div>

			<div
				style={{
					display: 'flex',
					flexDirection: 'column',
					position: 'absolute',
					width: 210,
					height: 55,
					top: '74%',
					right: '16%',
					color: 'white',
				}}
			>
				<span style={{ fontSize: '8px', fontWeight: 400 }}>
					{props.headline} • <span style={{ color: '#838383' }}>8-8-2023</span>
				</span>
				{props.text && props.text.length <= 80 && (
					<span
						style={{
							fontSize: '7px',
							color: '#e6e6e6',
							width: '170px',
							fontWeight: 300,
							overflow: 'hidden',
							// textOverflow: 'ellipsis',
							// whiteSpace: 'normal',
							// display: '-webkit-box',
							// WebkitLineClamp: 2,
							// WebkitBoxOrient: 'vertical',
						}}
					>
						{props.text}{' '}
					</span>
				)}
				{props.text && props.text.length > 80 && (
					<span
						style={{
							fontSize: '7px',
							color: '#e6e6e6',
							width: '170px',
							fontWeight: 300,
						}}
					>
						{props.text.slice(0, 80)}
						<span style={{ fontSize: '7px', fontWeight: 500, color: 'white' }}>
							...more
						</span>
					</span>
				)}
				<span style={{ fontSize: '7px', fontWeight: 500, color: 'white' }}>
					{props.hashtags.map((hashtag: string) => '#' + hashtag + ' ')}
				</span>
			</div>
		</>
	)
}

const SocialSponsoredTemplate = (props: {
	template: any
	headline: string | null
	text: string | null
	hashtags: string[]
	cta: string | null
}) => {
	const { iconPreview } = props.template
	return (
		<>
			<div
				style={{
					position: 'absolute',
					width: '152px',
					// height: '20px',
					paddingBlock: '5px',
					top: '78%',
					right: '33.5%',
					backgroundColor: 'rgb(0, 136, 234)',
					borderRadius: '4px',
					color: '#e1e1e1',
					fontSize: '7.5px',
					textAlign: 'center',
					letterSpacing: '.3px',
				}}
			>
				{props.cta}
			</div>
			<div
				style={{
					position: 'absolute',
					width: 25,
					height: 25,
					top: '50%',
					right: '17.5%',
				}}
			>
				{iconPreview && (
					<img
						src={iconPreview}
						style={{ borderRadius: '50%', width: '100%', height: '100%' }}
					/>
				)}
			</div>

			<div
				style={{
					position: 'absolute',
					width: 12,
					height: 12,
					top: '53.4%',
					right: '19.7%',
					borderRadius: '50%',
					backgroundColor: 'rgb(255, 0, 74)',
					textAlign: 'center',
				}}
			>
				<span
					style={{
						position: 'absolute',
						top: '-10%',
						right: '20%',
						color: '#fff',
						fontSize: '10px',
						fontWeight: 300,
					}}
				>
					+
				</span>
			</div>

			<div
				style={{
					position: 'absolute',
					width: 25,
					height: 25,
					top: '77%',
					right: '17.5%',
				}}
			>
				{iconPreview && (
					<img
						src={iconPreview}
						style={{ borderRadius: '50%', width: '100%', height: '100%' }}
					/>
				)}
			</div>

			<div
				style={{
					display: 'flex',
					flexDirection: 'column',
					position: 'absolute',
					width: 210,
					height: 55,
					top: '68%',
					right: '13.5%',
					color: 'white',
					gap: '4px',
				}}
			>
				<span style={{ fontSize: '8px', fontWeight: 400 }}>
					{props.headline}
				</span>
				{props.text && props.text.length <= 80 && (
					<span
						style={{
							fontSize: '7px',
							color: '#e6e6e6',
							width: '170px',
							fontWeight: 300,
							overflow: 'hidden',
							// textOverflow: 'ellipsis',
							// whiteSpace: 'normal',
							// display: '-webkit-box',
							// WebkitLineClamp: 2,
							// WebkitBoxOrient: 'vertical',
						}}
					>
						{props.text}{' '}
					</span>
				)}
				{props.text && props.text.length > 80 && (
					<span
						style={{
							fontSize: '7px',
							color: '#e6e6e6',
							width: '170px',
							fontWeight: 300,
						}}
					>
						{props.text.slice(0, 80)}
						<span style={{ fontSize: '7px', fontWeight: 500, color: 'white' }}>
							...more
						</span>
					</span>
				)}
			</div>
		</>
	)
}

const SocialGamingTemplate = (props: {
	template: any
	headline: string | null
	text: string | null
	cta: string | null
}) => {
	const { videoPreview, iconPreview } = props.template
	return (
		<>
			<div
				id='play'
				style={{
					position: 'absolute',
					top: '50%',
					left: '50%',
					transform: 'translate(-50%, -50%)',
					zIndex: 0,
					color: '#e0e0e0',
				}}
			>
				<PlayCircleOutline sx={{ width: '75px', height: '75px' }} />
			</div>
			{props.cta ? (
				<div
					style={{
						position: 'absolute',
						width: '36px',
						paddingBlock: '3px',
						borderRadius: '2rem',
						top: '68.5%',
						textAlign: 'center',
						right: '19%',
						fontSize: '6px',
						fontWeight: 600,
						fontFamily: 'verdana',
						backgroundColor: '#ededed',
					}}
				>
					{props.cta}
				</div>
			) : (
				<></>
			)}
			<div
				style={{
					position: 'absolute',
					width: '195px',
					height: '56px',
					top: '23%',
					right: '18.5%',
					fontSize: '9px',
					fontWeight: 600,
					fontFamily: 'verdana',
					backgroundColor: '#fff',
					overflow: 'hidden',
				}}
			>
				<span>{props.text}</span>
			</div>
			{iconPreview ? (
				<div
					style={{
						position: 'absolute',
						width: '14px',
						height: '14px',
						borderRadius: '50%',
						top: '20%',
						right: '79%',
					}}
				>
					<img
						src={iconPreview}
						style={{ width: '100%', height: '100%', borderRadius: '50%' }}
					/>
				</div>
			) : (
				<div
					style={{
						position: 'absolute',
						width: '14px',
						height: '14px',
						borderRadius: '50%',
						top: '20.5%',
						right: '79%',
						backgroundColor: 'black',
					}}
				></div>
			)}
			<div
				style={{
					position: 'absolute',
					width: '150px',
					top: '21%',
					right: '28.8%',
					fontSize: '7px',
					fontWeight: 500,
					fontFamily: 'verdana',
					backgroundColor: '#fff',
				}}
			>
				<span>
					{props.headline}{' '}
					<span
						style={{ color: '#919191', fontSize: '6.5px', fontWeight: 300 }}
					>
						• Promoted
					</span>
				</span>
			</div>
			<div
				style={{
					position: 'absolute',
					width: '192px',
					height: '190px',
					top: '33.5%',
					right: '19.5%',
					overflow: 'hidden',
					background: 'black',
					borderRadius: '8px',
				}}
			>
				{videoPreview && (
					<video
						src={videoPreview}
						style={{
							width: '100%',
							height: '100%',
						}}
						muted
						autoPlay
						loop
						// width={'200px'}
						// height={'200px'}
					/>
				)}
			</div>
		</>
	)
}

const SocialShoppingTemplate = (props: {
	template: any
	headline: string | null
	text: string | null
	cta: string | null
}) => {
	const { videoPreview, iconPreview } = props.template
	return (
		<>
			<div
				id='play'
				style={{
					position: 'absolute',
					top: '45%',
					left: '50%',
					transform: 'translate(-50%, -50%)',
					zIndex: 0,
					color: '#e0e0e0',
				}}
			>
				<PlayCircleOutline sx={{ width: '75px', height: '75px' }} />
			</div>
			<div
				style={{
					position: 'absolute',
					width: '100px',
					paddingBlock: '3px',
					borderRadius: '2rem',
					top: '63.5%',
					right: '51%',
					fontSize: '6.5px',
					fontWeight: 500,
					fontFamily: 'verdana',
					backgroundColor: '#fff',
				}}
			>
				apps.apple.com
			</div>
			{props.cta ? (
				<div
					style={{
						position: 'absolute',
						width: '45px',
						// height: '15px',
						paddingBlock: '3px',
						borderRadius: '2rem',
						top: '63.5%',
						textAlign: 'center',
						right: '19%',
						fontSize: '6px',
						fontWeight: 600,
						fontFamily: 'verdana',
						backgroundColor: '#ededed',
					}}
				>
					{props.cta}
				</div>
			) : (
				<></>
			)}
			<div
				style={{
					position: 'absolute',
					width: '195px',
					height: '42px',
					top: '19.8%',
					right: '19.5%',
					fontSize: '9px',
					fontWeight: 600,
					fontFamily: 'verdana',
					backgroundColor: '#fff',
					overflow: 'hidden',
				}}
			>
				<span>{props.text}</span>
			</div>
			{iconPreview ? (
				<div
					style={{
						position: 'absolute',
						width: '14px',
						height: '14px',
						borderRadius: '50%',
						top: '16.8%',
						right: '79.8%',
					}}
				>
					<img
						src={iconPreview}
						style={{ width: '100%', height: '100%', borderRadius: '50%' }}
					/>
				</div>
			) : (
				<div
					style={{
						position: 'absolute',
						width: '14px',
						height: '14px',
						borderRadius: '50%',
						top: '17.3%',
						right: '79.8%',
						backgroundColor: 'black',
					}}
				></div>
			)}
			<div
				style={{
					position: 'absolute',
					width: '150px',
					top: '17.7%',
					right: '29.1%',
					fontSize: '7px',
					fontWeight: 500,
					fontFamily: 'verdana',
					backgroundColor: '#fff',
				}}
			>
				<span>
					{props.headline}{' '}
					<span
						style={{ color: '#919191', fontSize: '6.5px', fontWeight: 300 }}
					>
						• Promoted
					</span>
				</span>
			</div>
			<div
				style={{
					position: 'absolute',
					width: '202px',
					height: '196px',
					top: '27.4%',
					right: '17.5%',
					overflow: 'hidden',
					background: 'black',
					borderRadius: '8px',
				}}
			>
				{videoPreview && (
					<video
						src={videoPreview}
						style={{
							width: '100%',
							height: '100%',
						}}
						muted
						autoPlay
						loop
					/>
				)}
			</div>
		</>
	)
}

const SocialSportsTemplate = (props: {
	template: any
	headline: string | null
	text: string | null
	cta: string | null
}) => {
	const { videoPreview, iconPreview } = props.template
	return (
		<>
			<div
				id='play'
				style={{
					position: 'absolute',
					top: '55%',
					left: '50%',
					transform: 'translate(-50%, -50%)',
					zIndex: 0,
					color: '#e0e0e0',
				}}
			>
				<PlayCircleOutline sx={{ width: '75px', height: '75px' }} />
			</div>
			<div
				style={{
					position: 'absolute',
					width: '45px',
					paddingBlock: '3px',
					borderRadius: '2rem',
					top: '74.2%',
					textAlign: 'center',
					right: '69.4%',
					fontSize: '6.5px',
					fontWeight: 500,
					fontFamily: 'verdana',
					backgroundColor: '#fff',
				}}
			>
				apps.apple.com
			</div>
			{props.cta ? (
				<div
					style={{
						position: 'absolute',
						width: '45px',
						paddingBlock: '3px',
						borderRadius: '2rem',
						top: '74.2%',
						textAlign: 'center',
						right: '18.5%',
						fontSize: '6px',
						fontWeight: 600,
						fontFamily: 'verdana',
						backgroundColor: '#ededed',
					}}
				>
					{props.cta}
				</div>
			) : (
				<></>
			)}
			<div
				style={{
					position: 'absolute',
					width: '195px',
					height: '65px',
					top: '26%',
					right: '19.5%',
					fontSize: '9px',
					fontWeight: 600,
					fontFamily: 'verdana',
					backgroundColor: '#fff',
					overflow: 'hidden',
				}}
			>
				<span>{props.text}</span>
			</div>
			{iconPreview ? (
				<div
					style={{
						position: 'absolute',
						width: '14px',
						height: '14px',
						borderRadius: '50%',
						top: '23%',
						right: '79.8%',
					}}
				>
					<img
						src={iconPreview}
						style={{ width: '100%', height: '100%', borderRadius: '50%' }}
					/>
				</div>
			) : (
				<div
					style={{
						position: 'absolute',
						width: '14px',
						height: '14px',
						borderRadius: '50%',
						top: '23.5%',
						right: '79.8%',
						backgroundColor: 'black',
					}}
				></div>
			)}
			<div
				style={{
					position: 'absolute',
					width: '150px',
					top: '24%',
					right: '29.1%',
					fontSize: '7px',
					fontWeight: 500,
					fontFamily: 'verdana',
					backgroundColor: '#fff',
				}}
			>
				<span>
					{props.headline}{' '}
					<span
						style={{ color: '#919191', fontSize: '6.5px', fontWeight: 300 }}
					>
						• Promoted
					</span>
				</span>
			</div>
			<div
				style={{
					position: 'absolute',
					width: '202px',
					height: '196px',
					top: '38%',
					right: '17.5%',
					overflow: 'hidden',
					background: 'black',
					borderRadius: '8px',
				}}
			>
				{videoPreview && (
					<video
						src={videoPreview}
						style={{
							width: '100%',
							height: '100%',
						}}
						muted
						autoPlay
						loop
					/>
				)}
			</div>
		</>
	)
}

const AdTemplate = memo(
	(props: {
		template: any
		headline: string | null
		text: string | null
		hashtags: string[]
		cta: string | null
	}) => {
		const { id, type, templateUrl, vertical, category, horizontal } =
			props.template
		return (
			<div
				id={id}
				style={{
					width: '300px',
					position: 'relative',
					transform: horizontal ? 'rotate(90deg)' : '',
				}}
			>
				{type === 'Banner' &&
					vertical === 'Sports' &&
					category === 'DSP & SDK' && (
						<SDKSportsBannerTemplate template={props.template} />
					)}
				{type === 'Banner' &&
					vertical === 'Shopping' &&
					category === 'DSP & SDK' && (
						<SDKShoppingBannerTemplate template={props.template} />
					)}
				{type === 'Banner' &&
					vertical === 'Gaming' &&
					category === 'DSP & SDK' && (
						<SDKGamingBannerTemplate template={props.template} />
					)}
				{type === 'Video' && category === 'DSP & SDK' && (
					<SDKVideoTemplate template={props.template} />
				)}
				{type === 'Interstitial' && category === 'DSP & SDK' && (
					<SDKInterstitialTemplate
						template={props.template}
						headline={props.headline}
						cta={props.cta}
					/>
				)}
				{type === 'Search Page' && category === 'OEM' && (
					<OEMSearchPageTemplate
						template={props.template}
						headline={props.headline}
					/>
				)}
				{type === 'Home Page' && category === 'OEM' && (
					<OEMHomePageTemplate
						template={props.template}
						headline={props.headline}
					/>
				)}
				{type === 'Banner' && category === 'OEM' && (
					<OEMBannerTemplate
						template={props.template}
						text={props.text}
						cta={props.cta}
					/>
				)}
				{type === 'Influencer' && (
					<SocialInfluencerTemplate
						template={props.template}
						headline={props.headline}
						text={props.text}
						hashtags={props.hashtags}
					/>
				)}
				{type === 'Sponsored' && (
					<SocialSponsoredTemplate
						template={props.template}
						headline={props.headline}
						text={props.text}
						hashtags={props.hashtags}
						cta={props.cta}
					/>
				)}
				{type === 'Video' && category === 'Social' && vertical === 'Gaming' && (
					<SocialGamingTemplate
						template={props.template}
						headline={props.headline}
						text={props.text}
						cta={props.cta}
					/>
				)}
				{type === 'Video' &&
					category === 'Social' &&
					vertical === 'Shopping' && (
						<SocialShoppingTemplate
							template={props.template}
							headline={props.headline}
							text={props.text}
							cta={props.cta}
						/>
					)}
				{type === 'Video' && category === 'Social' && vertical === 'Sports' && (
					<SocialSportsTemplate
						template={props.template}
						headline={props.headline}
						text={props.text}
						cta={props.cta}
					/>
				)}
				<img style={{ width: '100%' }} src={templateUrl} alt='' />
			</div>
		)
	},
)

export default AdTemplate
