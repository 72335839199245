import { Grid, Paper, TextField, Typography } from '@mui/material'
import { styled } from '@mui/material/styles'
import { DesktopDatePicker } from '@mui/x-date-pickers'
import theme from '../../utils/theme2024'

export const ReportItem = styled(Paper)(() => ({
	backgroundColor: theme.colors.base.white,
	border: 'none',
	boxShadow: 'none',
	textAlign: 'left',
	height: '600px',
}))
export const StyledBox = styled(Paper)(() => ({
	overflow: 'auto',
	backgroundColor: theme.colors.base.white,
	border: '10px',
	borderRadius: theme.dimentions.smallerRadius,
	textAlign: 'left',
	height: '600px',
	padding: '10px',
}))

export const ReportGrid = styled(Grid)(({ theme }) => ({
	textAlign: 'left',
	minHeight: '100px',
	padding: '20px',
}))

export const DateComponent = styled(DesktopDatePicker)(({ theme }) => ({
	minHeight: '100px',
	width: '80%',
	marginRight: '0px',
}))

export const ReportItemHeading = styled(Typography)(() => ({
	fontWeight: 500,
	color: theme.colors.base.grey500,
}))

export const StyledTextField = styled(TextField)(({ theme }) => ({
	backgroundColor: 'rgba(238, 238, 238, 0.6)',
	height: '40px',
	fontWeight: 'bold',

	borderRadius: theme.dimentions.smallerRadius,
	marginTop: '40px',
	'& .MuiOutlinedInput-input': {
		backgroundColor: 'transparent',
		borderRadius: theme.dimentions.smallerRadius,
		height: '40px',
		fontSize: '13px',
		fontWeight: 'bold',
		paddingTop: '1px',
		paddingBottom: '1px',
		paddingRight: '6px',
	},
}))
