import React, { useState, useEffect, useRef } from 'react'
import theme from '../../utils/theme2024'
import { capitalizeFirstLetter } from '../../utils/helpers/tableHelper'

const FilterTabs = (props: {
	filterKey: string
	items: any[]
	setFilteredItems: (items: any[]) => void
}) => {
	const { filterKey, items, setFilteredItems } = props

	const getFilterOptions = () => {
		const optionsSet = new Set<string>() // Using a Set to ensure uniqueness
		items.forEach((item: any) => {
			const normalizedCategory = item[filterKey]?.trim().toLowerCase()

			if (normalizedCategory) {
				const capitalizedCategory = capitalizeFirstLetter(normalizedCategory)
				optionsSet.add(capitalizedCategory) // Add capitalized value to the set
			}
		})

		const options = Array.from(optionsSet) // Convert Set back to array

		return options
	}

	const [options, setOptions] = useState<string[]>(getFilterOptions())
	const [selectedFilter, setSelectedFilter] = useState<string>('')
	const [hoveredOption, setHoveredOption] = useState<string>('')
	const [backgroundPosition, setBackgroundPosition] = useState<number>(-200)
	const optionRef: any = useRef<HTMLDivElement[]>([])

	useEffect(() => {
		setOptions(getFilterOptions())
	}, [items, filterKey])

	useEffect(() => {
		if (selectedFilter === '') {
			setFilteredItems(items)
			return
		}
		const filteredItems = items.filter(
			(item) => item[filterKey] === selectedFilter,
		)
		setFilteredItems(filteredItems)
	}, [selectedFilter, items, filterKey])

	useEffect(() => {
		const selectedIndex = options.indexOf(selectedFilter)
		if (optionRef.current[selectedIndex]) {
			setBackgroundPosition(optionRef.current[selectedIndex].offsetLeft)
		} else {
			setBackgroundPosition(-200)
		}
	}, [selectedFilter, options])

	const handleOptionClick = (option: string) => {
		setSelectedFilter((curFilter) => {
			if (curFilter === option) {
				return ''
			} else {
				return option
			}
		})
	}

	const handleTextColor = (option: string) => {
		if (selectedFilter === option) {
			return theme.colors.base.grey900
		}
		if (hoveredOption === option) {
			return theme.colors.base.green200
		}
		return theme.colors.base.grey600
	}

	return (
		<div
			style={{
				display: 'flex',
				justifyContent: 'center',
				height: 30,
			}}
		>
			<div
				style={{
					border: '1px solid ' + theme.colors.base.grey600,
					borderRadius: '2rem',
					fontSize: theme.font.size.body,
					display: 'flex',
					justifyContent: 'center',
					maxWidth: '70%',
					position: 'relative',
					overflow: 'hidden',
					// padding: '0px 20px',
				}}
			>
				<div
					style={{
						display: selectedFilter ? 'block' : 'none',
						position: 'absolute',
						height: '100%',
						width: '9.5rem',
						background: theme.colors.base.green200,
						borderRadius: '2rem',
						transition: 'left 0.5s',
						left: `${backgroundPosition}px`,
					}}
				/>
				{options.map((option, index) => (
					<div
						key={option}
						ref={(el) => (optionRef.current[index] = el)}
						onClick={() => handleOptionClick(option)}
						onMouseEnter={() => setHoveredOption(option)}
						onMouseLeave={() => setHoveredOption('')}
						style={{
							cursor: 'pointer',
							width: '9.5rem',
							paddingBlock: 4,
							borderRadius: '2rem',
							textAlign: 'center',
							color: handleTextColor(option),
							background: 'transparent',
							position: 'relative',
							zIndex: 1,
						}}
					>
						{option}
					</div>
				))}
			</div>
		</div>
	)
}

export default FilterTabs
