import { Dispatch, SetStateAction, useEffect, useState } from 'react'
import { checkInputStartsWithHttp } from '../../utils/helpers/tableHelper'
import { countryList } from '../components/countryList'
import {
	getApplicableRules,
	stringIsValidName,
} from '../../utils/helpers/helperFuncs'
import moment from 'moment'
import { useTypedSelector } from '../../hooks/useTypedSelector'
import { InputFieldText } from '../components/Inputs'
import {
	CountryListSelector,
	DropList,
	OwnerSelect,
} from '../components/SelectableInputs'
import { ChipInputList } from '../components/ChipInput'
import { Grid } from '@mui/material'

//this component will render and allow to edit / save a new publisher. It's the main file for all the inputs.
export const PublisherCompanyInfoComponent = (props: {
	name: string | null
	setName: any
	legalName: string
	setLegalName: any
	address: string
	setAddress: any
	geo: string[]
	setGeo: any
	linkToIO: string
	setLinkToIO: any
	setOwnerEmail: any
	ownerEmail: string | null
	setSalesRep: any
	salesRep: string
	setStatus: any
	status: boolean
	setPIDs: any
	pids: string[]
	notes: string
	setNotes: any
	skypeGroup: string
	setSkypeGroup: any
	freeText: any
	setFreeText: any
	linkToPubFile: any
	setLinkToPubFile: any
	isValid: boolean
	settings?: any
	loginRole: string
	externalId: any
	setExternalId: any
	externalIdInUse: boolean
	setMainHeightChange?: any
	linkToLinkedin: any
	setLinkToLinkedin: any
	linkToWebsite: any
	setLinkToWebsite: any
	publicSkype: boolean
	setPublicSkype: any
	pastOwnerFinance: any
	setPastOwnerFinance: any
	users: any
	mainCategory: string
	setMainCategory: Dispatch<SetStateAction<string>>
	pauseReason: string
	setPauseReason: Dispatch<SetStateAction<string>>
	pauseExplanation: string
	setPauseExplanation: Dispatch<SetStateAction<string>>
	setHandShakeEditorOpen: Dispatch<SetStateAction<boolean>>
	setEditablePid: Dispatch<SetStateAction<string>>
	mandatoryFields?: any
	fieldValidations?: any
}) => {
	const { users, login, settings } = useTypedSelector((state) => state)

	const ruleSet = getApplicableRules(users, login, settings, 'publisher')
	const [filteredPIDs, setFilteredPIDs] = useState<any>([])
	const [dummiesPids, setDummiesPids] = useState<any>([])
	const [nameTouched, setNameTouched] = useState(false)

	//we want to hide the dummiesPIDs from the user, that's why we filter them.
	useEffect(() => {
		if (props.externalId && props.externalId !== '') {
			const arr = []
			const dummiesArr = []
			for (const pid of props.pids) {
				if (!pid.endsWith(props.externalId.toString())) {
					arr.push(pid)
				} else {
					dummiesArr.push(pid)
				}
			}
			setFilteredPIDs(arr)
			setDummiesPids(dummiesArr)
		}
	}, [props.externalId])
	useEffect(() => {
		if (props.externalId) {
			const newArr = [...filteredPIDs, ...dummiesPids]
			props.setPIDs(newArr)
		}
	}, [filteredPIDs])

	const handlePastOwnerFinance = (newOwner: string) => {
		if (newOwner !== props.ownerEmail) {
			const currentFinanceDate = moment()
			currentFinanceDate.add(5, 'days')
			const currentFinancePeriod = currentFinanceDate.format('01/MM/YYYY')
			// console.log(currentFinancePeriod)
			const userName = props.users.users.filter(
				(el: any) =>
					el.email.toLowerCase() === (props.ownerEmail as string).toLowerCase(),
			)[0]['name']
			const financePastOwner = {
				owner: props.ownerEmail,
				ownerName: userName,
				financePeriod: currentFinancePeriod,
			}
			const financeRuleSet = getApplicableRules(
				users,
				{ user: { email: props.ownerEmail } },
				settings,
				'finance',
			)

			const viewPersonal = financeRuleSet?.rules?.['View Personal'] || false
			const viewMediaBuying =
				financeRuleSet?.rules?.['View Media Buying'] || false

			if (!financeRuleSet || !financeRuleSet.rules) {
				console.error('Finance rule set is undefined or invalid.')
				return
			}

			if (viewPersonal || viewMediaBuying) {
				const updatedArr = []
				if (props.pastOwnerFinance.length > 0) {
					for (const oldOwner of props.pastOwnerFinance) {
						if (oldOwner.financePeriod === currentFinancePeriod) {
							oldOwner.owner = props.ownerEmail
							oldOwner.ownerName = userName
						}
						updatedArr.push(oldOwner)
					}
				} else {
					updatedArr.push(financePastOwner)
				}
				props.setPastOwnerFinance(updatedArr)
			}
		} else {
			console.warn('No permission to view personal or media buying')
		}
	}

	const pidUpdateDeal = (e: any) => {
		setFilteredPIDs(e)
		props.setPIDs(e)
	}
	//we use this in order to work with the style of the pids.
	const handlePIDWidth = (pidLength: number) => {
		if (pidLength > 10) {
			return 12
		} else if (pidLength > 6) {
			return 9
		} else if (pidLength > 2) {
			return 6
		} else return 3
	}
	return (
		<Grid
			container
			columnSpacing={6}
			rowSpacing={4}
			direction='row'
			style={{
				display: 'flex',
				width: '98%',
			}}
			columns={12}
		>
			<Grid item xs={3}>
				<InputFieldText
					label='Company Name'
					type='text'
					value={props.name}
					color={
						nameTouched && props.name && !stringIsValidName(props.name)
							? 'error'
							: 'primary'
					}
					onChange={(e: any) => {
						setNameTouched(true)
						props.setName(e)
					}}
					disabled={!ruleSet.post}
					errormessage={
						nameTouched && props.name && !stringIsValidName(props.name)
							? "Company name must not start or end with whitespace and must not contain '/', '%', ',' or '.'"
							: undefined
					}
					mandatory={!props.fieldValidations.name}
				/>
			</Grid>
			<Grid item xs={3}>
				<InputFieldText
					label='Legal Name'
					type='text'
					value={props.legalName}
					onChange={props.setLegalName}
					disabled={!ruleSet.post}
					mandatory={!props.fieldValidations.legalName}
				/>
			</Grid>
			<Grid item xs={3}>
				<InputFieldText
					label='Address'
					type='text'
					value={props.address}
					onChange={props.setAddress}
					disabled={!ruleSet.post}
				/>
			</Grid>
			<Grid item xs={3}>
				<CountryListSelector
					label='Address Geo'
					options={countryList}
					value={props.geo}
					singular
					onChange={(e: any) => props.setGeo(e)}
					disabled={!ruleSet.post}
				/>
			</Grid>
			{ruleSet.rules['view links'] && (
				<Grid item xs={3}>
					<InputFieldText
						label='Link to IO'
						type='text'
						value={props.linkToIO}
						onChange={props.setLinkToIO}
						color={
							props.linkToIO &&
							!checkInputStartsWithHttp(props.linkToIO) &&
							props.isValid === false
								? 'error'
								: 'primary'
						}
						disabled={!ruleSet.post}
						errormessage={
							props.linkToIO
								? !checkInputStartsWithHttp(props.linkToIO)
									? "Links must start with 'http://' or 'https://'"
									: undefined
								: undefined
						}
						showUrlIcon={true}
					/>
				</Grid>
			)}
			<Grid item xs={3}>
				<OwnerSelect
					onChange={(e: any) => {
						handlePastOwnerFinance(e)
						props.setOwnerEmail(e)
					}}
					label={'Owner'}
					value={props.ownerEmail}
					disabled={!ruleSet.post}
				/>
			</Grid>
			<Grid item xs={3}>
				<DropList
					onChange={(e: any) => props.setSalesRep(e)}
					options={props.settings.settings.salesRepresentative}
					label={'Sales Representative'}
					value={props.salesRep}
					disabled={!ruleSet.post}
				/>
			</Grid>
			<Grid item xs={3}>
				<DropList
					onChange={(e: any) => props.setStatus(e === 'Active' ? true : false)}
					options={['Active', 'Disabled']}
					label={'Status'}
					value={props.status === true ? 'Active' : 'Disabled'}
					disabled={!ruleSet.post}
				/>
			</Grid>
			{ruleSet.rules['view links'] && (
				<Grid item xs={3}>
					<InputFieldText
						label='Link to Publisher File'
						type='text'
						value={props.linkToPubFile}
						onChange={props.setLinkToPubFile}
						color={
							props.linkToPubFile &&
							!checkInputStartsWithHttp(props.linkToPubFile) &&
							props.isValid === false
								? 'error'
								: 'primary'
						}
						disabled={!ruleSet.post}
						errormessage={
							props.linkToPubFile
								? !checkInputStartsWithHttp(props.linkToPubFile)
									? "Links must start with 'http://' or 'https://'"
									: undefined
								: undefined
						}
						showUrlIcon={true}
					/>
				</Grid>
			)}
			{ruleSet.rules['view links'] && (
				<Grid item xs={3}>
					<InputFieldText
						label='Publisher website'
						type='text'
						value={props.linkToWebsite}
						onChange={props.setLinkToWebsite}
						color={
							props.linkToWebsite &&
							!checkInputStartsWithHttp(props.linkToWebsite) &&
							props.isValid === false
								? 'error'
								: 'primary'
						}
						disabled={!ruleSet.post}
						errormessage={
							props.linkToWebsite
								? !checkInputStartsWithHttp(props.linkToWebsite)
									? "Links must start with 'http://' or 'https://'"
									: undefined
								: undefined
						}
						showUrlIcon={true}
					/>
				</Grid>
			)}
			{ruleSet.rules['view links'] && (
				<Grid item xs={3}>
					<InputFieldText
						label='Link to LinkedIn'
						type='text'
						value={props.linkToLinkedin}
						onChange={props.setLinkToLinkedin}
						color={
							props.linkToLinkedin &&
							!checkInputStartsWithHttp(props.linkToLinkedin) &&
							props.isValid === false
								? 'error'
								: 'primary'
						}
						disabled={!ruleSet.post}
						errormessage={
							props.linkToLinkedin
								? !checkInputStartsWithHttp(props.linkToLinkedin)
									? "Links must start with 'http://' or 'https://'"
									: undefined
								: undefined
						}
						showUrlIcon={true}
					/>
				</Grid>
			)}
			<Grid item xs={3}>
				<InputFieldText
					disabled={!ruleSet.post}
					label='External ID'
					type='number'
					value={props.externalId}
					onChange={props.setExternalId}
					errormessage={
						props.externalIdInUse ? 'This id is already in use' : undefined
					}
				/>
			</Grid>
			<Grid item xs={3}>
				<DropList
					onChange={(e: any) => props.setMainCategory(e)}
					options={[
						'Direct App/Website',
						'Social Platform',
						'DSP',
						'In App Self-Serve',
						'Incent Self-Serve',
						'Incent Offerwall',
						'Native Ads Platform',
						'Phone Operator',
						'Ad Network',
						'External Media Buying',
					]}
					label={'Main Category'}
					value={props.mainCategory}
					disabled={!ruleSet.post}
				/>
			</Grid>
			{ruleSet.rules['view links'] && (
				<Grid item xs={6}>
					<InputFieldText
						label='Skype Group Link'
						type='text'
						value={
							props.publicSkype
								? props.skypeGroup
								: ruleSet.rules['edit skype']
								? props.skypeGroup
								: '******'
						}
						onChange={props.setSkypeGroup}
						disabled={
							!ruleSet.post || props.publicSkype
								? false
								: ruleSet.rules['edit skype']
								? false
								: true
						}
					/>
				</Grid>
			)}
			<Grid item xs={3}>
				<DropList
					onChange={(e: any) =>
						props.setPublicSkype(e === 'Open' ? true : false)
					}
					options={['Open', 'Private']}
					label={'Skype Group'}
					value={props.publicSkype === true ? 'Open' : 'Private'}
					disabled={
						!ruleSet.post || (ruleSet.post && !ruleSet.rules['edit skype'])
					}
				/>
			</Grid>
			{props.status === false && (
				<>
					<Grid item xs={3}>
						<DropList
							onChange={props.setPauseReason}
							options={
								props.settings.settings.platforms
									? props.settings.settings.pauseReason.sort()
									: []
							}
							label={'Pause Reason'}
							value={props.pauseReason}
							multiple={false}
							disabled={!ruleSet.post}
							noNull={true}
						/>
					</Grid>
					<Grid item xs={3}>
						<InputFieldText
							label='Explanation'
							type='text'
							value={props.pauseExplanation}
							onChange={props.setPauseExplanation}
							disabled={!ruleSet.post}
						/>
					</Grid>
				</>
			)}
			<Grid item xs={12}>
				<ChipInputList
					onChange={(e: any) => pidUpdateDeal(e)}
					label={'PIDs'}
					options={[]}
					value={filteredPIDs.length > 0 ? filteredPIDs : props.pids}
					disabled={!ruleSet.post}
					setMainHeightChange={props.setMainHeightChange}
					clickableFunction={(option) => {
						props.setHandShakeEditorOpen(true)
						props.setEditablePid(option.toLowerCase().trim())
					}}
				/>
			</Grid>
		</Grid>
	)
}
