import ListItemText from '@mui/material/ListItemText'
import { useState } from 'react'
import theme from '../../utils/theme2024'
import { StyledListItemButton } from '../reports/FiltersStyled'

const TransferListElement = (props: {
	value: any
	dataObj: any
	handleClick: any
	// checkDisabled: any
	checkedArr: any
	listName: string
	key: string
}) => {
	const [checked, setChecked] = useState(
		Object.keys(props.checkedArr).includes(props.value) &&
			props.checkedArr[props.value] === true,
	)

	// useEffect(() => {console.log(checked)}, [checked])
	return (
		<StyledListItemButton
			divider
			button
			key={props.value + checked}
			value={props.value}
			dataObj={props.dataObj}
			selected={checked}
			onClick={(e: any) => {
				setChecked(!checked)
				props.handleClick(props.value)
			}}
			// disabled={props.checkDisabled(props.value, props.dataObj)}
		>
			<ListItemText
				id={`transfer-list-all-item-${props.value}-label`}
				primary={props.value}
				primaryTypographyProps={{
					fontWeight: 500,
					color: checked ? theme.colors.base.green300 : 'inherit',

					fontSize: '13px',
				}}
			/>
		</StyledListItemButton>
	)
}

export default TransferListElement
