import React, { useEffect, useState } from 'react'
import { AppFilterContainer } from './AppFilterContainer'
import { useTypedSelector } from '../../hooks/useTypedSelector'
import { BrowserUpdatedOutlined } from '@mui/icons-material'
import theme from '../../utils/theme2024'
import { AppTable } from './AppTable'
import saveAs from 'file-saver'
import { useActions } from '../../hooks/useActions'
import { SubmitButton } from '../components/Buttons'
import { StyledHeader } from '../components/reusableComponents'

interface Props {
	setErrorMessage?: any
	setLoading: any
}

export const SalesPlan: React.FC<Props> = (props) => {
	const { downloadAppListAction } = useActions()
	const { login } = useTypedSelector((state) => state)
	const [previewApps, setPreviewApps] = useState<any>([])
	const [fileDownload, setFileDownload] = useState()
	const [isPreview, setIsPreview] = useState<boolean>(false)

	const headers = {
		Authorization: `Token ${login.user.token}`,
	}

	const previewAppsHandler = (filteredApps: any) => {
		setPreviewApps(filteredApps)
	}

	useEffect(() => {
		props.setLoading(true)
		// Simulate data fetching
		setTimeout(() => {
			// Mock data fetch
			setPreviewApps([]) // Replace with actual data fetching logic
			props.setLoading(false)
		}, 2000) // Replace with actual data fetching logic
	}, [])

	useEffect(() => {
		if (fileDownload) {
			saveAs(fileDownload, '*.csv')
		}
	}, [fileDownload])

	const styles = {
		container: {
			display: 'flex',
			flexDirection: 'column' as 'column',
			alignItems: 'center',
			justifyContent: 'center',
			width: '100%',
		},
		innerContainer: {
			width: '1350px',
			display: 'flex',
			justifyContent: 'center',
			flexDirection: 'column' as 'column',
		},
		innerContainerHidden: {
			width: '1350px',
			display: 'none',
			justifyContent: 'center',
			flexDirection: 'column' as 'column',
		},
		submitButton: {
			display: 'flex',
			alignItems: 'center',
			flexDirection: 'column' as 'column',
		},
		button: (isDisabled: boolean) => ({
			background: isDisabled ? 'white' : theme.colors.base.green200,
			color: isDisabled ? '' : theme.colors.white,
			width: '142px',
			marginBlockStart: '-40px',
			cursor: isDisabled ? 'not-allowed' : 'pointer',
		}),
		downloadButton: {
			width: '140px',
			display: 'flex',
			justifyContent: 'space-evenly',
			boxShadow: 'none',
			border: `1px solid ${theme.colors.activeGradient}`,
		},
	}

	return (
		<div style={styles.container}>
			<div
				style={isPreview ? styles.innerContainerHidden : styles.innerContainer}
			>
				<StyledHeader>Sales Plan</StyledHeader>
				<AppFilterContainer previewApps={previewAppsHandler} />
			</div>
			<div style={styles.submitButton}>
				<SubmitButton
					disabled={previewApps.length === 0}
					onClick={() => setIsPreview(!isPreview)}
					style={styles.button(previewApps.length === 0)}
				>
					{isPreview ? 'Filter' : 'Results'}
				</SubmitButton>
				{isPreview && (
					<SubmitButton
						onClick={() =>
							downloadAppListAction(
								setFileDownload,
								previewApps,
								headers,
								props.setErrorMessage,
							)
						}
						style={styles.downloadButton}
					>
						Download <BrowserUpdatedOutlined />
					</SubmitButton>
				)}
			</div>
			{isPreview && (
				<div>
					<AppTable apps={previewApps} setLoading={props.setLoading} />
				</div>
			)}
		</div>
	)
}
