export const CLIENT_VERSION = '1.0.1' //THE HARDCODED VERSION NUMBER NEEDS TO BE CHECKED WITH SERVER EVERY PUSH

const API = 'api/'

export const devUrl = `http://localhost:4000/${API}`

export interface routeItem {
	route: PAGE_NAVIGATION | null
	displayString: string
	icon?: string
}
export enum PAGE_NAVIGATION {
	NEWS = 'News',
	ADVERTISERS = 'Advertisers',
	APPS = 'Apps',
	PUBLISHERS = 'Publishers',
	CAMPAIGN = 'Campaigns',
	AGGREGATED = 'Aggregated',
	DATACENTER = 'Data Center',
	USERS = 'Users',
	APPSFLYER = 'Appsflyer',
	LOGS = 'Logs',
	NETWORK = 'Network',
	RAWDATA = 'RawData',
	SETTINGS = 'Settings',
	PASSWORDS = 'Passwords',
	DOCUMENTS = 'Documents',
	//DASHBOARD = 'Dashboard',
	AUTOSWITCH = 'Autoswitch',
	P360DASHBOARD = 'P360DASHBOARD',
}

export const routes: routeItem[] = [
	{
		route: PAGE_NAVIGATION.NEWS,
		displayString: 'News',
		icon: '<path d="M10 20v-6h4v6h5v-8h3L12 3 2 12h3v8z" />',
	},
	{
		route: null,
		displayString: 'Dashboard',
		icon: `
	  <path d="M10 20v-6h4v6h5v-8h3L12 3 2 12h3v8z" />
	`,
	},
	{
		route: PAGE_NAVIGATION.ADVERTISERS,
		displayString: 'Advertisers',
		icon: `
      <path d="M10 20v-6h4v6h5v-8h3L12 3 2 12h3v8z" />
    `,
	},
	{
		route: PAGE_NAVIGATION.PUBLISHERS,
		displayString: 'Publishers',
		icon: `
      <path d="M10 20v-6h4v6h5v-8h3L12 3 2 12h3v8z" />
    `,
	},
	{
		route: PAGE_NAVIGATION.APPS,
		displayString: 'Apps',
		icon: `
      <path d="M10 20v-6h4v6h5v-8h3L12 3 2 12h3v8z" />
    `,
	},
	{
		route: PAGE_NAVIGATION.CAMPAIGN,
		displayString: 'Campaigns',
		icon: `
      <path d="M10 20v-6h4v6h5v-8h3L12 3 2 12h3v8z" />
    `,
	},
	{
		route: null,
		displayString: 'Selector',
		icon: `
      <path d="M10 20v-6h4v6h5v-8h3L12 3 2 12h3v8z" />
    `,
	},
	{
		route: null,
		displayString: 'Finance',
		icon: `
      <path d="M10 20v-6h4v6h5v-8h3L12 3 2 12h3v8z" />
    `,
	},
	{
		route: null,
		displayString: 'Tools',
		icon: `
      <path d="M10 20v-6h4v6h5v-8h3L12 3 2 12h3v8z" />
    `,
	},
	{
		route: PAGE_NAVIGATION.USERS,
		displayString: 'Users',
		icon: `
      <path d="M10 20v-6h4v6h5v-8h3L12 3 2 12h3v8z" />
    `,
	},
	{
		route: PAGE_NAVIGATION.PASSWORDS,
		displayString: 'Passwords',
		icon: `
      <path d="M10 20v-6h4v6h5v-8h3L12 3 2 12h3v8z" />
    `,
	},
	{
		route: PAGE_NAVIGATION.DOCUMENTS,
		displayString: 'Documents',
		icon: `
      <path d="M10 20v-6h4v6h5v-8h3L12 3 2 12h3v8z" />
    `,
	},
	{
		route: PAGE_NAVIGATION.AGGREGATED,
		displayString: 'Aggregated',
		icon: `
      <path d="M10 20v-6h4v6h5v-8h3L12 3 2 12h3v8z" />
    `,
	},

	{
		route: PAGE_NAVIGATION.NETWORK,
		displayString: 'Network',
		icon: `
      <path d="M10 20v-6h4v6h5v-8h3L12 3 2 12h3v8z" />
    `,
	},
	{
		route: PAGE_NAVIGATION.RAWDATA,
		displayString: 'RawData',
		icon: `
      <path d="M10 20v-6h4v6h5v-8h3L12 3 2 12h3v8z" />
    `,
	},
	{
		route: PAGE_NAVIGATION.AUTOSWITCH,
		displayString: 'Autoswitch',
		icon: '<path d="M10 20v-6h4v6h5v-8h3L12 3 2 12h3v8z" />',
	},
	// {
	// 	route: PAGE_NAVIGATION.P360DASHBOARD,
	// 	displayString: 'P360Dashboard',
	// 	icon: '<path d="M10 20v-6h4v6h5v-8h3L12 3 2 12h3v8z" />',
	// },
]

export enum endpoints {
	USER = 'user',
	REGISTER = 'register',
	SIGNUP = 'signup',
	RESET_PASSWORD = 'reset_password',
	LOGIN = 'login',
	ADVERTISER = 'advertiser',
	CAMPAIGN = 'campaign',
	PUBLISHER = 'publisher',
	PUBLISHER_WISHLIST = 'publisherwishlist',
	PUBLISHER_WISHLIST_DELETE = 'publisherwishlistdelete',
	NEWS = 'news',
	NEWS_I = 'news/image',
	LOGS = 'logs',
	SETTINGS = 'settings',
	REPORTS = 'reports',
	APPSFLYER = 'appsflyer',
	SAVE = 'appsflyer/save',
	APP = 'app',
	P360APP = 'app/p360',
	P360APPADD = 'app/p360/add',
	P360APPREMOVE = 'app/p360/removerule',
	REMOVEP360APP = 'app/p360/remove',
	ICONS = 'icons',
	APPUPDATE = 'app/update',
	PASSWORD = 'password',
	PRESET = 'preset',
	RAWDATA = 'rawdata',
	NETWORK = 'network',
	NETWORK_U = 'network/update',
	APPSFLYER_U = 'appsflyer/update',
	STORAGE = 'storage',
	UPDATE = 'update',
	FORGOTPASSWORD = 'forgotpwd',
	UNIQUE_CARD = 'unique_card',
	PASSWORDS = 'passwords',
	CHANGE_PASSWORD_WITH_TOKEN = 'changepasswordwithtoken',
	SHOW_PASSWORD = 'showpasswords',
	INVALID = 'invalid',
	DOCUMENTS = 'documents',
	DASHBOARD = 'dashboard',
	APPSFLYER_ACCOUNTS = 'appsflyeraccounts',
	CAMPAIGNHISTORY = 'campaignhistory',
	AVATAR = 'avatar',
	FINANCE = 'finance',
	AUTOSWITCH = 'autoswitch',
	ADJUST = 'adjust',
	VERSION = 'version',
	P360DASHBOARD = 'p360dashboard',
	p360aggregated = 'p360aggregated',
	SALES_PLAN = 'salesplan',
	ADS_PLACEMENT = 'adsplacement',
	CREATOR = 'creator',
	CREATORS = 'creators',
	HANDSHAKE = 'handshake',
}

export const entityEndpoints: Record<
	'advertiser' | 'publisher' | 'creator',
	keyof typeof endpoints
> = {
	advertiser: 'ADVERTISER',
	publisher: 'PUBLISHER',
	creator: 'CREATOR',
}

export const ACCEPTED_FILES = {
	DEFAULT: '*', // Accepts all file types
	IMAGES_ONLY: '.jpg, .jpeg, .png, .svg', // Specific types for the advertiser
	VIDEO: 'video/*',
}
