import AdapterDateFns from '@date-io/date-fns'
import { HideImage } from '@mui/icons-material'
import ArrowBackIcon from '@mui/icons-material/ArrowBack'
import VisibilityIcon from '@mui/icons-material/Visibility'
import AddIcon from '@mui/icons-material/Add'
import { ReactComponent as DeleteIcon } from '../../assets/svg/delete-icon.svg'
import { ReactComponent as DuplicateIcon } from '../../assets/svg/duplicate-icon.svg'
import defaultIcon from '../../assets/default-icon.webp'

import {
	Box,
	Card,
	Checkbox,
	Dialog,
	DialogActions,
	DialogContent,
	DialogContentText,
	Grid,
	MenuItem,
	Paper,
	Switch,
	ThemeProvider,
	Tooltip,
	Typography,
} from '@mui/material'
import { DataGrid } from '@mui/x-data-grid'
import { LocalizationProvider } from '@mui/x-date-pickers'
import _ from 'lodash'
import React, { useEffect, useMemo, useRef, useState } from 'react'
import { useNavigate, useParams } from 'react-router'
import { useActions } from '../../hooks/useActions'
import { useTypedSelector } from '../../hooks/useTypedSelector'
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos'
import {
	CampaignInterface,
	PublisherInterface,
	TrackedEvent,
} from '../../models/model.interface'
import {
	getAccountsAction,
	saveCampaignHistoryAction,
} from '../../state/action-creators'
import {
	generateNodesFromArray,
	getApplicableRules,
	handleIconUrl,
	stringIsValidName,
} from '../../utils/helpers/helperFuncs'
import { fetchData } from '../../utils/helpers/navigationHelper'
import {
	capitalizeFirstLetter,
	checkInputStartsWithHttp,
	formatDate,
	shallowEqual,
} from '../../utils/helpers/tableHelper'
import theme from '../../utils/theme2024'
import { StyledTab, StyledTabs } from '../advertisers/AdvertiserStyled'
import { countryList } from '../components/countryList'
import { PageWrapper } from '../components/reusableComponents'
import { StyledCheckboxFormControl } from '../reports/FiltersStyled'
import { DateComponent } from '../reports/ReportsStyled'
import {
	AdvertiserList,
	AppIdList,
	CostOptions,
	EventForm,
	isUuid,
	TabPanel,
} from './CampaignComponents'
import {
	ButtonBar,
	PlusTab,
	StyledInputLabel,
	StyledSelect,
} from './CampaignStyled'
import { TabContainer } from '../components/TabComponent'
import { SubmitButton, UnborderedButton } from '../components/Buttons'
import {
	InputFieldText,
	RadioChipInput,
	StyledTextAreaField,
	StyledTextInputField,
} from '../components/Inputs'
import { CountryListSelector, DropList } from '../components/SelectableInputs'
import { ChipInputList } from '../components/ChipInput'
import { DatePicker } from '../components/DateInputs'
import { SwitchComponent } from '../components/ToggleComponents'
import { GridContainer, UniqueCards } from '../components/CustomContainers'
import CompanyInformationHeader from '../components/CompanyInformationHeader'

const CampaignAdd = (props: {
	PopUpVisible: boolean
	setPopUpVisible: any
	setRow: any
	row?: CampaignInterface
	setLoading: any
	errorMessage: null | string
	setErrorMessage: any
	isErrorPromptOpened: boolean
	setIsErrorPromptOpened: any
	setIsSuccessPromptOpened: any
	isSuccessPromptOpened: any
	setDuplicating: any
	duplicating: any
	uuidOfDuped: any
	isAdding?: any
	fetchData?: any
	campaignsGroup?: any
	setCampaignsGroup?: any
	handleDelete?: any
}): JSX.Element => {
	const {
		getEventsByAppsAction,
		getCampaignAction,
		getCampaignPids,
		getAccountsAction,
		deleteCampaign,
		insertLog,
		getCampaignHistoryByMainDtoAction,
		insertCampaignAction,
		duplicateCampaignAction,
	} = useActions()

	const {
		login,
		campaign,
		publisher,
		advertiser,
		app,
		campaign_history,
		settings,
		accounts,
		users,
	} = useTypedSelector((state) => state)
	const navigate = useNavigate()
	const params = useParams()
	const [tab, setTab] = useState<number>(0)
	const [campaignName, setCampaignName] = useState<string | null>(null)
	const [previewUrl, setPreviewUrl] = useState<string | undefined | null>(null)
	const [advertiserName, setAdvertiserName] = useState<string>('')
	const [role, setRole] = useState<string>('M')
	const [cParam, setCParam] = useState<string>('All')
	const [status, setStatus] = useState<boolean>(false)
	const [appId, setAppId] = useState<string>('')
	const [trackedEvent, setTrackedEvent] = useState<TrackedEvent[]>()
	const [cap, setCap] = useState<string | undefined>('')
	const [isValid, setIsValid] = useState<boolean>(false)
	const [datePicked, setDatePicked] = useState<any>(undefined)
	const [available, setAvailable] = useState<boolean>(false)
	const [eventList, setEventList] = useState<string[]>([])
	const [cParamValid, setCParamValid] = useState<boolean>(true)
	// const [uniqueIsEnabled, setUniqueIsEnabled] = useState<boolean>(true)
	const [trackedEventChanged, setTrackedEventChanged] = useState<any[]>([])
	const [prevState, setPrevState] = useState<any>(undefined)
	const [uniqueTabState, setUniqueTabState] = useState<any[]>([])
	const [pointer, setPointer] = useState<number>(0)
	const [alignment, setAlignment] = useState<number>(0)
	const [popUpVisible, setPopUpVisible] = useState<boolean>(false)
	const [isRevenueShare, setIsRevenueShare] = useState<boolean>(false)
	const [createdBy, setCreatedBy] = useState<string>('')
	const [fixPrice, setFixPrice] = useState<string>('')
	const [iconUrl, setIconUrl] = useState<string>('')
	const [fixPriceSelected, setFixPriceSelected] = useState<boolean>(false)
	const [kpi, setKpi] = useState<string>('')
	const [softKpi, setSoftKpi] = useState<string>('')
	const [hardKpi, setHardKpi] = useState<string>('')
	const [traffic, setTraffic] = useState<any>([])
	const [usedExistingChannels, setUsedExistingChannels] = useState<any>([])
	const [eventsFlow, setEventsFlow] = useState<string>('')
	const [targetAudience, setTargetAudience] = useState<string>('')
	const [targetAudienceGender, setTargetAudienceGender] = useState<any>([])
	const [targetAudienceInterest, setTargetAudienceInterest] = useState<any>([])
	const [targetAudienceOthers, setTargetAudienceOthers] = useState<any>([])
	const [targetAudienceAgeFrom, setTargetAudienceAgeFrom] = useState<any>('')
	const [targetAudienceAgeTo, setTargetAudienceAgeTo] = useState<any>('')
	const [campaignGoals, setCampaignGoals] = useState<any>([])
	const [goal, setGoal] = useState<string>('')
	const [note, setNote] = useState<string>('')
	const [vtaAllowed, setVtaAllowed] = useState<boolean>(false)
	const [retargeting, setRetargeting] = useState<boolean>(false)
	const [probVta, setProbVta] = useState<boolean>(false)
	const [revenueNeeded, setRevenueNeeded] = useState<boolean>(false)
	const [organicNeeded, setOrganicNeeded] = useState<boolean>(false)
	const [advfileLink, setAdvfileLink] = useState<string>('')
	const [appName, setAppName] = useState<string>('')
	const [additionalTag, setAdditionalTag] = useState<string>('')
	const [creativesLink, setCreativesLink] = useState<string>('')
	const [deleteUnique, setDeleteUnique] = useState<any>([])
	const [budgetAmount, setBudgetAmount] = useState<string>('')
	const [timeFrame, setTimeFrame] = useState<any>('Monthly')
	const [endDate, setEndDate] = useState<Date>(new Date())
	const [kpiFocus, setKpiFocus] = useState<boolean>(true)
	const [trafficFocus, setTrafficFocus] = useState<boolean>(true)
	const [targetFocus, setTargetFocus] = useState<boolean>(true)
	const [goalFocus, setGoalFocus] = useState<boolean>(true)
	const [notesFocus, setNotesFocus] = useState<boolean>(true)
	const [rawdata, setRawdata] = useState<any>(false)
	const [pidList, setPidList] = useState<any>({})
	const [datePickerOpened, setDatePickerOpened] = useState(false)
	const [dateMoverOpened, setDateMoverOpened] = useState(false)
	const [uniques, setUniques] = useState<any>({})
	const [campaignFlow, setCampaignFlow] = useState('')
	const [creativesFocus, setCreativesFocus] = useState(true)
	const [pidConflictResolver, setPidConflictResolver] = useState<any>([])
	const [advFileFocus, setAdvFileFocus] = useState(true)
	const [eventNamesChosen, setEventNamesChosen] = useState<any[]>()
	const urlSplit = window.location.href.split('/')
	const [historyIsLoading, setHistoryIsLoading] = useState(true)
	const [conflicts, setConflicts] = useState<JSX.Element[]>([])
	const [privacyLevel, setPrivacyLevel] = useState('Open')
	const [campaignOwner, setCampaignOwner] = useState('')
	const [newUuid, setNewUuid] = useState('')
	const [fraudTools, setFraudTools] = useState<any>('')
	const [geoArray, setGeoArray] = useState<any>([])
	const [isEditingHistory, setIsEditingHistory] = useState(false)
	const [dateAdded, setDateAdded] = useState(undefined)
	const [historyUuid, setHistoryUuid] = useState(undefined)
	const [chosenHistoryEntry, setChosenHistoryEntry] = useState(0)
	const [networkAccount, setNetworkAccount] = useState([])
	const [gender, setGender] = useState('Both')
	const [incentive, setIncentive] = useState(false)
	const [campaignRules, setCampaignRules] = useState<any>({})
	const [countryExcluded, setCountryExcluded] = useState<boolean>(false)
	const [appList, setAppList] = useState<string[]>([])
	const [dummyPids, setDummyPids] = useState<boolean>(false)
	const [appNameTouched, setAppNameTouched] = useState(false)
	const [campaignAdded, setCampaignAdded] = useState(false)

	const mspidList = useMemo(
		() =>
			publisher.publisher
				.map((p: PublisherInterface) => p.media_source_pid)
				.flat()
				.filter((e: string) =>
					isNaN(parseInt(e.split('_')[e.split('_').length - 1])),
				),
		[],
	)

	const listOfHooks: any = []

	const advertiserList: any = advertiser.advertiser
		.sort((adv1: any, adv2: any) =>
			adv1.advertiser_name.localeCompare(adv2.advertiser_name),
		)
		.map((adv: any, key: number) => adv.advertiser_name)

	const preparePrevState = (prevState: any) => {
		try {
			const { id, count, createdAt, status, platform, ...result } = prevState
			return result
		} catch (error) {
			return null
		}
	}
	const prepareNewState = (prevState: any) => {
		const { id, user_login, ...result } = prevState
		return result
	}

	const uuidInUrl = props.duplicating
		? props.uuidOfDuped
		: urlSplit[urlSplit.length - 1]

	const headers = {
		Authorization: `Token ${login.user.token}`,
	}

	//finding a campaign record for opened page
	const foundCampaign: any = campaign.campaign.find(
		(e: any) => e.uuid === uuidInUrl && e.role === 'M',
	)
	let filteredHistory =
		campaign_history.campaign_history?.filter(
			(e: any) => e.uuid === uuidInUrl && e.role === 'M',
		) || []
	filteredHistory.push(foundCampaign)

	filteredHistory.sort(
		(a: any, b: any) =>
			new Date(a.date_added).getTime() - new Date(b.date_added).getTime(),
	)

	// helper function to find a appropriate width for the history cells
	let widthArray: number[] = []
	if (filteredHistory[0]) {
		// console.log(filteredHistory)
		let offset = new Date(filteredHistory[0].date_added).getTime()
		let elapsedTimeArray = []
		let timeelapsedSum =
			new Date().getTime() - new Date(filteredHistory[0].date_added).getTime()

		for (let entity of filteredHistory) {
			elapsedTimeArray.push(new Date(entity.date_added).getTime() - offset)

			offset = new Date(entity.date_added).getTime()
		}

		elapsedTimeArray.push()
		let percent = Math.floor(timeelapsedSum / 100)

		let min = 100
		let max = 0
		let sum = 0
		for (let time of elapsedTimeArray) {
			let calculatedWidth = Math.floor(time / percent)

			widthArray.push(calculatedWidth === 0 ? 1 : calculatedWidth)
			if (max < calculatedWidth) {
				max = calculatedWidth
			}
			if (min > calculatedWidth) {
				min = calculatedWidth
			}
			sum += calculatedWidth
		}

		// const median = Math.floor(min + max / 2)
		const median = Math.floor(sum / elapsedTimeArray.length)

		for (let [i, width] of Object.entries(widthArray)) {
			widthArray[parseInt(i)] = Math.floor(median + 0.5 * (width - median))
		}
	}

	// creating all necessary components for history selector
	const historyArray = filteredHistory
		.map((e: any, i: number) => {
			if (e) {
				let res = [
					<Grid
						item
						style={{
							marginRight: '10px',
							width: '10px',
							textAlign: 'center',
							borderRadius: '10px',
							padding: 0,
							zIndex: 1,
						}}
					>
						<Tooltip title={formatDate(new Date(e.date_added))} placement='top'>
							<SubmitButton
								onClick={() => {
									setDateAdded(e.date_added)
									setDateMoverOpened(true)
								}}
								style={{
									background: '#808080',
									width: '10px',
									height: '50px',
									margin: 0,
									padding: 0,
									minWidth: 0,
									borderRadius: '30px',
									clipPath:
										'polygon(25% 0%, 75% 0%, 100% 50%, 75% 100%, 25% 100%, 0% 50%)',
									position: 'relative',
									border: 'none',
								}}
							></SubmitButton>
						</Tooltip>
					</Grid>,
					<Grid
						item
						xs={widthArray[i + 1]}
						style={{
							// background: 'grey',
							// alignContent: 'center',
							marginRight: '10px',
							borderRadius: '30px',
							textAlign: 'center',
							// width: widthArray[i] + 'px',
						}}
					>
						<SubmitButton
							onClick={() => {
								fillCampaignStatesFromHistory(e)
								setChosenHistoryEntry(filteredHistory.length - 1 - i)
							}}
							style={{
								borderRadius: '30px',
								width: '100%',
								// right: '10%',
								height: '25px',
								borderBlockColor: 'transparent',

								background:
									chosenHistoryEntry === filteredHistory.length - 1 - i
										? theme.colors.base.green200
										: i % 2 === 0
										? '#eeeeee'
										: '#dddddd',
								margin: 0,
								position: 'relative',
								minWidth: 0,
								zIndex: 1,
								border: 'none',
							}}
						></SubmitButton>
					</Grid>,
				]
				if (i === filteredHistory.length - 1) {
					res = [
						<Grid
							item
							xs={widthArray[i + 1]}
							style={{
								// background: 'black',
								marginRight: '10px',
								width: '10px',
								textAlign: 'center',
								borderRadius: '30px',
								padding: 0,
							}}
						>
							<Tooltip
								title={formatDate(new Date(e.date_added))}
								placement='top'
							>
								<SubmitButton
									onClick={() => {
										setDateAdded(e.date_added)
										setDateMoverOpened(true)
									}}
									style={{
										background: '#808080',
										width: '10px',
										height: '50px',
										margin: 0,
										padding: 0,
										minWidth: 0,
										borderRadius: '30px',
										clipPath:
											'polygon(25% 0%, 75% 0%, 100% 50%, 75% 100%, 25% 100%, 0% 50%)',
										position: 'relative',
										marginRight: '10px',
										border: 'none',
									}}
								></SubmitButton>
							</Tooltip>
						</Grid>,
						<Grid
							item
							style={{
								// background: 'red',
								//

								borderRadius: '30px',
								textAlign: 'center',
							}}
						>
							<SubmitButton
								onClick={() => {
									fillCampaignStates()
									setChosenHistoryEntry(filteredHistory.length - 1 - i)
								}}
								style={{
									borderRadius: '30px',
									width: '100%',
									// right: '10%',
									height: '25px',
									borderBlockColor: 'transparent',
									boxShadow: 'none',
									background:
										chosenHistoryEntry === filteredHistory.length - 1 - i
											? theme.colors.base.green100
											: 'grey',
									margin: 0,
									position: 'relative',
									minWidth: 0,
									clipPath:
										'polygon(75% 0%, 100% 50%, 75% 100%, 0% 100%, 25% 50%, 0% 0%)',
									border: 'none',
								}}
							></SubmitButton>
						</Grid>,
					]
				}
				return res
			} else return undefined
		})
		.flat()

	// forms the log object for storing and sends it to the server
	const handleLog = async (headers: any, payload: any) => {
		// console.log('save logs with old', prevState)
		const shallow = shallowEqual(prevState, prepareNewState(payload), true)
		if (shallow.length > 0) {
			const log = {
				email: login.user.email,
				object: campaignName! + ' in Campaigns',
				change: JSON.stringify(shallow),
			}
			await insertLog(headers, log)
		}
	}

	// forms campaign present uniques data into a dictionary
	const formUniqueDictionary = () => {
		const foundCampaign: any = campaign.campaign.find(
			(e: any) => e.uuid === uuidInUrl && e.role === 'M',
		)
		const foundCampaignsArray: any = campaign.campaign.filter(
			(e: any) => e.campaign_name === foundCampaign.campaign_name,
		)

		let result: any = {}
		for (let e of foundCampaignsArray) {
			Object.assign(result, {
				[e.role]: {
					media_source_pid: e.media_source_pid,
					country: e.country,
					tracked_event: e.tracked_event,
					is_revenue_share: e.is_revenue_share,
					uuid: props.duplicating ? undefined : e.uuid,
					cost_from_data: e.cost_from_data,
				},
			})
		}
		if (Object.keys(result).length === 0) {
			Object.assign(result, {
				M: {
					media_source_pid: [],
					country: [''],
					tracked_event: [
						{
							order: 1,
							eventName: 'installs',
							defaultCost: 0,
							defaultRevenue: 0,
						},
					],
					uuid: undefined,
				},
			})
		}
		return result
	}
	// forms campaign history uniques data into a dictionary for a record
	const formUniqueDictionaryFromHistory = (
		main_name: string,
		date_added: any,
	) => {
		// console.log('formUniqueDictionary', props.duplicating)

		const foundCampaignsArray = campaign_history.campaign_history.filter(
			(e: any) => e.date_added === date_added,
		)
		let result: any = {}
		for (let e of foundCampaignsArray) {
			Object.assign(result, {
				[e.role]: {
					media_source_pid: e.media_source_pid,
					country: e.country,
					tracked_event: e.tracked_event,
					is_revenue_share: e.is_revenue_share,
					uuid: props.duplicating ? undefined : e.uuid,
				},
			})
		}
		if (Object.keys(result).length === 0) {
			Object.assign(result, {
				M: {
					media_source_pid: [],
					country: [''],
					tracked_event: [
						{
							order: 1,
							eventName: 'installs',
							defaultCost: 0,
							defaultRevenue: 0,
						},
					],
					uuid: undefined,
				},
			})
		}
		return result
	}

	// use found data to fill the campaign states
	const fillCampaignStates = () => {
		if (foundCampaign !== undefined) {
			if (foundCampaign.icon) {
				setIconUrl(foundCampaign.icon)
			}
			setCampaignOwner(foundCampaign.email)
			setPreviewUrl(foundCampaign.preview_url)
			setCap(foundCampaign.cap)
			setStatus(foundCampaign.status)
			setCampaignName(foundCampaign.campaign_name)
			setCParam(foundCampaign.c_parameter)
			// setTrackedEvent(orderedTrackedEvent)
			setIsRevenueShare(foundCampaign.is_revenue_share)
			setGoal(foundCampaign.goal)
			setTargetAudience(foundCampaign.target_audience)
			setKpi(foundCampaign.kpi)
			setNote(foundCampaign.note)
			setVtaAllowed(foundCampaign.vta_allowed)
			setRetargeting(foundCampaign.retargeting)
			setTraffic(foundCampaign.traffic || [])
			setProbVta(foundCampaign.prob_vta)
			setRevenueNeeded(foundCampaign.revenue_needed)
			setOrganicNeeded(foundCampaign.organic_needed)
			setAdvfileLink(foundCampaign.adv_file_link)
			setCreativesLink(foundCampaign.creatives)
			setTimeFrame(foundCampaign.timeframe)
			setEndDate(foundCampaign.end_date)
			setRawdata(foundCampaign.rawdata)
			setBudgetAmount(foundCampaign.budget_amount)
			setFixPrice(foundCampaign.fix_price)
			setCreatedBy(foundCampaign.created_by)
			setFixPriceSelected(foundCampaign.fix_price)
			setPidConflictResolver(foundCampaign.pidConflicts)
			setPrivacyLevel(foundCampaign.privacy_level)
			setAdvertiserName(foundCampaign.advertiser_name)
			setAppId(foundCampaign.app_id)
			setIncentive(foundCampaign.incentive)
			setAppName(foundCampaign.campaign_name.split(' - ')[0])
			let tag = ''
			if (foundCampaign.campaign_name) {
				const splitResult = foundCampaign.campaign_name.split(' - ')

				tag = ['CPI', 'CPA', 'CPC', 'CPM'].includes(
					splitResult[splitResult.length - 1]?.trim(),
				)
					? ''
					: splitResult[splitResult.length - 1]?.trim()
				setCampaignFlow(
					['CPI', 'CPA', 'CPC', 'CPM'].includes(
						splitResult[splitResult.length - 1]?.trim(),
					)
						? splitResult[splitResult.length - 1]?.trim()
						: splitResult[splitResult.length - 2]?.trim(),
				)
				setAdditionalTag(tag)
			}
			setNetworkAccount(foundCampaign.network_account)
			setFraudTools(foundCampaign.fraud_tool)
			const dict = formUniqueDictionary()
			setUniques(dict)
			if (dict && dict['M']) {
				setEventNamesChosen(
					Object.values(dict['M']).map((event: any) => event?.name),
				)
			}

			setHistoryUuid(undefined)
			setDateAdded(undefined)
			setIsEditingHistory(false)
			setGender(foundCampaign.gender)
			setTargetAudienceAgeFrom(foundCampaign.age_from)
			setTargetAudienceAgeTo(foundCampaign.age_to)
			setUsedExistingChannels(foundCampaign.channels || [])
			setNetworkAccount(foundCampaign.network_account || [])
			setTargetAudienceInterest(foundCampaign.interests || [])
			setCampaignGoals(foundCampaign.campaign_goals || [])
			setTargetAudienceOthers(foundCampaign.other_caricaturists || [])
			setSoftKpi(foundCampaign.soft_kpi)
			setHardKpi(foundCampaign.hard_kpi)
			setEventsFlow(foundCampaign.campaign_flow)
			setCampaignRules(
				foundCampaign.campaign_rules
					? JSON.parse(foundCampaign.campaign_rules)
					: {},
			)
			setCountryExcluded(foundCampaign.country_excluded)
			setDummyPids(foundCampaign.dummy_pids)
		}
	}

	// if the campaign is of advertiser that is not in apps flyer
	// we remove the agency account from the campaign (it is not needed)
	const checkAdvertiserPlatform = () => {
		const curAdvertiser = advertiser.advertiser.find(
			(a: any) => a.advertiser_name === campaignObject.advertiser_name,
		)
		if (curAdvertiser.platform.includes('Appsflyer')) {
			return
		} else {
			let emptyArr: string[] = []
			campaignObject.network_account = emptyArr
		}
	}

	// use found historical data to fill the campaign states
	const fillCampaignStatesFromHistory = (historyRecord: any) => {
		if (historyRecord !== undefined) {
			// const orderedTrackedEvent =
			//  foundCampaign !== undefined && foundCampaign.tracked_event !== undefined
			//      ? foundCampaign.tracked_event.sort(
			//              (a: any, b: any) => a.order! - b.order!,
			//        )
			//      : []
			const campaignNameSplit = historyRecord.campaign_name.split('-')
			if (foundCampaign.icon) {
				setIconUrl(foundCampaign.icon)
			}
			setCampaignOwner(historyRecord.email)
			setPreviewUrl(historyRecord.preview_url)
			setCap(historyRecord.cap)
			setStatus(historyRecord.status)
			setCampaignName(historyRecord.campaign_name)
			setCParam(historyRecord.c_parameter)
			// setTrackedEvent(orderedTrackedEvent)
			setIsRevenueShare(historyRecord.is_revenue_share)
			setGoal(historyRecord.goal)
			setTargetAudience(historyRecord.target_audience)
			setKpi(historyRecord.kpi)
			setNote(historyRecord.note)
			setVtaAllowed(historyRecord.vta_allowed)
			setRetargeting(historyRecord.retargeting)
			setTraffic(historyRecord.traffic || [])
			setProbVta(historyRecord.prob_vta)
			setRevenueNeeded(historyRecord.revenue_needed)
			setOrganicNeeded(historyRecord.organic_needed)
			setAdvfileLink(historyRecord.adv_file_link)
			setCreativesLink(historyRecord.creatives)
			setTimeFrame(historyRecord.timeframe)
			setEndDate(historyRecord.end_date)
			setRawdata(historyRecord.rawdata)
			setBudgetAmount(historyRecord.budget_amount)
			setFixPrice(historyRecord.fix_price)
			setCreatedBy(historyRecord.created_by)
			setFixPriceSelected(historyRecord.fix_price)
			setPidConflictResolver(historyRecord.pidConflicts)
			setPrivacyLevel(historyRecord.privacy_level)
			setAdvertiserName(historyRecord.advertiser_name)
			setAppId(historyRecord.app_id)
			const dict = formUniqueDictionaryFromHistory(
				historyRecord.campaign_name,
				historyRecord.date_added,
			)
			setUniques(dict)
			if (dict && dict['M']) {
				setEventNamesChosen(
					Object.values(dict['M']).map((event: any) => event?.name),
				)
			}
			setDateAdded(historyRecord.date_added)
			setIsEditingHistory(true)
			setHistoryUuid(historyRecord.history_uuid)
			setGender(historyRecord.gender)
			setTargetAudienceAgeFrom(historyRecord.age_from)
			setTargetAudienceAgeTo(historyRecord.age_to)
			setUsedExistingChannels(historyRecord.channels || [])
			setNetworkAccount(historyRecord.network_account || [])
			setTargetAudienceInterest(historyRecord.interests || [])
			setCampaignGoals(historyRecord.campaign_goals || [])
			setTargetAudienceOthers(historyRecord.other_caricaturists || [])
			setIncentive(historyRecord.incentive)
			setSoftKpi(historyRecord.soft_kpi)
			setHardKpi(historyRecord.hard_kpi)
			setEventsFlow(historyRecord.campaign_flow)
			setCampaignRules(
				historyRecord.campaign_rules
					? JSON.parse(historyRecord.campaign_rules)
					: {},
			)
			setDummyPids(historyRecord.dummy_pids)
			setFraudTools(historyRecord.fraud_tool)
			if (campaignNameSplit.length > 0) {
				setAppName(campaignNameSplit[0])

				let tag = ['CPI', 'CPA', 'CPC', 'CPM'].includes(
					campaignNameSplit[campaignNameSplit.length - 1]?.trim(),
				)
					? ''
					: campaignNameSplit[campaignNameSplit.length - 1]?.trim()
				// console.log(
				//  ['CPI', 'CPA', 'CPC', 'CPM'].includes(
				//      campaignNameSplit[campaignNameSplit.length - 1]?.trim(),
				//  )
				//      ? campaignNameSplit[campaignNameSplit.length - 1]?.trim()
				//      : campaignNameSplit[campaignNameSplit.length - 2]?.trim(),
				// )
				setCampaignFlow(
					['CPI', 'CPA', 'CPC', 'CPM'].includes(
						campaignNameSplit[campaignNameSplit.length - 1]?.trim(),
					)
						? campaignNameSplit[campaignNameSplit.length - 1]?.trim()
						: campaignNameSplit[campaignNameSplit.length - 2]?.trim(),
				)
				setAdditionalTag(tag)
				setCountryExcluded(historyRecord.country_excluded)
			}
		}
	}
	const externalFunction = (gotRole: string) => {}

	// forms an object to send to the backend from all hooks on the page
	const campaignObject: any = {
		uuid:
			uuidInUrl !== 'campaigns'
				? props.duplicating
					? undefined
					: uuidInUrl
				: undefined,
		app_id: appId,
		campaign_name: campaignName,
		email:
			advertiser.advertiser.find(
				(e: any) => e.advertiser_name === advertiserName,
			)?.email || '',
		c_parameter: cParam,
		advertiser_name: advertiserName,
		status: status,
		role: role,
		is_revenue_share: isRevenueShare,
		cap: cap,
		preview_url: previewUrl,
		goal: goal,
		target_audience: targetAudience,
		kpi: kpi,
		note: note,
		vta_allowed: vtaAllowed,
		retargeting: retargeting,
		traffic: traffic,
		prob_vta: probVta,
		revenue_needed: revenueNeeded,
		organic_needed: organicNeeded,
		adv_file_link: advfileLink,
		creatives: creativesLink,
		timeframe: timeFrame,
		end_date: endDate,
		budget_amount: budgetAmount,
		rawdata: rawdata,
		fix_price: fixPrice,
		created_by: createdBy,
		pidConflicts: conflicts,
		privacy_level: privacyLevel,
		priority: 'media_source_pid',
		uniques: uniques,
		campaign_goals: campaignGoals,
		gender: gender,
		age_from: targetAudienceAgeFrom,
		age_to: targetAudienceAgeTo,
		interests: targetAudienceInterest,
		other_caricaturists: targetAudienceOthers,
		channels: usedExistingChannels,
		incentive: incentive,
		network_account: networkAccount,
		hard_kpi: hardKpi,
		soft_kpi: softKpi,
		campaign_flow: eventsFlow,
		fraud_tool: fraudTools,
		campaign_rules: campaignRules,
		country_excluded: countryExcluded,
		dummy_pids: dummyPids,
	}

	// clones object for comparison and forming a log record
	let deepCloneCampaignObject = _.cloneDeep(campaignObject)

	const [mandatoryFields, setMandatoryFields] = useState({
		appId: true,
		advertiser: true,
		campaignFlow: true,
		uniqueCampaigns: true,
	})

	const [fieldValidations, setFieldValidations] = useState({
		appId: false,
		advertiser: false,
		campaignFlow: false,
		uniqueCampaigns: false,
	})

	if (!foundCampaign && !prevState) {
		const prev = preparePrevState({ ...deepCloneCampaignObject })
		setPrevState({ ...prev })
	}

	if (!prevState && campaignObject && campaignObject.campaign_name) {
		const prev = preparePrevState({ ...deepCloneCampaignObject })
		setPrevState({ ...prev })
	}
	const handlePidResolver = (key: string, value: string) => {
		pidConflictResolver[key] = value
		const new_enry = {}
		Object.assign(new_enry, pidConflictResolver)
		setPidConflictResolver(new_enry)
	}
	useEffect(() => {
		setConflicts(dublicatedPidComponentsList())
	}, [pidConflictResolver, pidList])

	// defaults all the hooks to their initial state
	const nullifyCompaignStates = () => {
		setCampaignName('')

		setAdvertiserName('')
		setCParam('All')
		setStatus(false)
		setAppId('')
		setTrackedEvent([])
		setIsValid(false)
		setIsRevenueShare(false)
		setUniqueTabState([])
		setGoal('')
		setTargetAudience('')
		setKpi('')
		setSoftKpi('')
		setHardKpi('')
		setNote('')
		setVtaAllowed(false)
		setRetargeting(false)
		setTraffic([])
		setUsedExistingChannels([])
		setEventsFlow('')
		setProbVta(false)
		setRevenueNeeded(false)
		setOrganicNeeded(false)
		setAdvfileLink('')
		setCreativesLink('')
		setBudgetAmount('')
		setEndDate(new Date())
		setTimeFrame('')
		setPreviewUrl('')
		setCap('')
		setDeleteUnique([])
		setFixPrice('')
		setCreatedBy('')
		setFixPriceSelected(false)
		setPrivacyLevel('Open')
		setUniques({})
		setDatePicked(undefined)
		setGender('')
		setTargetAudienceAgeFrom('')
		setTargetAudienceAgeTo('')
		setNetworkAccount([])
		setTargetAudienceInterest('')
		setCampaignGoals('')
		setTargetAudienceOthers('')
		setIncentive(false)
		setAdditionalTag('')
		setFraudTools('')
		setCampaignRules({})
		setCountryExcluded(false)
		setDummyPids(false)
	}

	// form and send everything to backend
	const trySubmit = async () => {
		props.setLoading(true)
		let payloadArray = []
		if (Object.keys(campaignObject.uniques).length > 0) {
			payloadArray = Object.keys(campaignObject.uniques).map((e: any) => {
				delete campaignObject.uuid
				if (e === 'M' && isUuid(uuidInUrl)) {
					return {
						...campaignObject,
						...campaignObject.uniques[e],
						role: e,
						main_uuid: uuidInUrl,
						uuid: uuidInUrl,
						date_toSaveFrom: datePicked,
						interests: targetAudienceInterest
							? targetAudienceInterest.map((e: any) => e?.label ?? '')
							: [],
						other_caricaturists: targetAudienceOthers
							? targetAudienceOthers.map((e: any) => e?.label ?? '')
							: [],
						campaign_goals: campaignGoals
							? campaignGoals?.map((e: any) => e?.label ?? '')
							: [],
						country_excluded: countryExcluded,
					}
				} else if (isUuid(uuidInUrl)) {
					return {
						...campaignObject,
						...campaignObject.uniques[e],
						role: e,
						main_uuid: uuidInUrl,
						date_toSaveFrom: datePicked,
						interests: targetAudienceInterest
							? targetAudienceInterest.map((e: any) => e?.label ?? '')
							: [],
						other_caricaturists: targetAudienceOthers
							? targetAudienceOthers.map((e: any) => e?.label ?? '')
							: [],
						campaign_goals: campaignGoals
							? campaignGoals?.map((e: any) => e?.label ?? '')
							: [],
						tracked_event: campaignObject.uniques[e].tracked_event.map(
							(elem: any, i: number) => {
								return {
									...elem,
									eventName:
										campaignObject.uniques['M'].tracked_event[i]?.eventName,
								}
							},
						),
						country_excluded: countryExcluded,
					}
				}
				return {
					...campaignObject,
					...campaignObject.uniques[e],
					role: e,
					date_toSaveFrom: datePicked,
					interests: targetAudienceInterest
						? targetAudienceInterest.map((e: any) => e?.label ?? '')
						: [],
					other_caricaturists: targetAudienceOthers
						? targetAudienceOthers.map((e: any) => e?.label ?? '')
						: [],
					campaign_goals: campaignGoals
						? campaignGoals?.map((e: any) => e?.label ?? '')
						: [],
					tracked_event: campaignObject.uniques[e].tracked_event.map(
						(elem: any, i: number) => {
							return {
								...elem,
								eventName:
									campaignObject.uniques['M'].tracked_event[i]?.eventName,
							}
						},
					),
				}
			})
		}
		// if duplicating a campaign
		if (payloadArray.length === 0) {
			payloadArray.push(campaignObject)
		}
		let payload: any = ''
		if (props.duplicating) {
			try {
				for (let campaign of payloadArray) {
					delete campaign.uuid
					delete campaign.main_uuid
				}

				payload = await duplicateCampaignAction(
					headers,
					payloadArray,
					ruleSet,
					props.setErrorMessage,
					setNewUuid,
				)
			} catch (err) {
				console.error(err)
			}
			// if creating ot editing a campaign
		} else {
			try {
				campaignObject.campaign_rules = JSON.stringify(
					campaignObject.campaign_rules,
				)
				checkAdvertiserPlatform()
				console.log('campaignObject 2 ', campaignObject)
				payload = await insertCampaignAction(
					headers,
					payloadArray,
					ruleSet,
					props.setErrorMessage,
					setNewUuid,
					campaignObject,
				)
			} catch (err) {
				console.error(err)
			}
		}

		// if deleing a campaign
		if (deleteUnique && !props.duplicating) {
			deleteUnique.forEach(async (unique: any) => {
				if (unique !== undefined) {
					const obj = {
						uuid: unique,
						user_login: login.user.email!,
						rule_set: ruleSet,
					}
					await deleteCampaign(headers, obj, props.setErrorMessage)
					setDeleteUnique([])
				}
			})
		}
		if (typeof payload !== 'string') {
			await handleLog(headers, campaignObject)
			props.setIsSuccessPromptOpened(true)
			await fetchData(login, getCampaignAction, campaign.campaign)
			getCampaignHistoryByMainDtoAction(
				headers,
				{ main_uuid: uuidInUrl },
				setHistoryIsLoading,
			)
		}
		props.setLoading(false)
	}
	// form and send everything to backend (historu)
	const trySaveHistoricalEnrty = async () => {
		props.setLoading(true)
		let payloadArray = []
		if (Object.keys(campaignObject.uniques).length > 0) {
			payloadArray = Object.keys(campaignObject.uniques).map((e: any) => {
				delete campaignObject.uuid
				const historyEntry = filteredHistory.find(
					(el: any) => el.date_added === dateAdded,
				)

				if (e === 'M' && isUuid(uuidInUrl)) {
					return {
						...campaignObject,
						...campaignObject.uniques[e],
						role: e,
						uuid: uuidInUrl,

						main_uuid: uuidInUrl,
						date_added: historyEntry.date_added,
						date_ended: historyEntry.date_ended,
						history_uuid: historyEntry.history_uuid,
						date_toSaveFrom: datePicked,
						interests: targetAudienceInterest
							? targetAudienceInterest.map((e: any) => e?.label ?? '')
							: [],
						other_caricaturists: targetAudienceOthers
							? targetAudienceOthers.map((e: any) => e?.label ?? '')
							: [],
						campaign_goals: campaignGoals
							? campaignGoals?.map((e: any) => e?.label ?? '')
							: [],
					}
				}
				return {
					...campaignObject,
					...campaignObject.uniques[e],
					role: e,
					main_uuid: uuidInUrl,

					date_added: historyEntry.date_added,
					date_ended: historyEntry.date_ended,
					history_uuid: historyEntry.history_uuid,
					date_toSaveFrom: datePicked,
					interests: targetAudienceInterest
						? targetAudienceInterest.map((e: any) => e?.label ?? '')
						: [],
					other_caricaturists: targetAudienceOthers
						? targetAudienceOthers.map((e: any) => e?.label ?? '')
						: [],
					campaign_goals: campaignGoals
						? campaignGoals?.map((e: any) => e?.label ?? '')
						: [],
					tracked_event: campaignObject.uniques[e].tracked_event.map(
						(elem: any, i: number) => {
							return {
								...elem,
								eventName:
									campaignObject.uniques['M'].tracked_event[i]?.eventName,
							}
						},
					),
				}
			})
		}

		if (payloadArray.length === 0) {
			payloadArray.push(campaignObject)
		}
		let payload: any = ''

		try {
			payload = await saveCampaignHistoryAction(
				headers,
				payloadArray,
				props.setErrorMessage,
				setNewUuid,
				campaignObject,
			)
		} catch (err) {
			console.error(err)
		}

		if (typeof payload !== 'string') {
			// console.log('before saving', prevState.uniques, campaignObject.uniques)
			await handleLog(headers, campaignObject)
			props.setIsSuccessPromptOpened(true)
			await fetchData(login, getCampaignAction, campaign.campaign)
			getCampaignHistoryByMainDtoAction(
				headers,
				{ main_uuid: uuidInUrl },
				setHistoryIsLoading,
			)
		}
		props.setLoading(false)
	}
	useEffect(() => {
		fetchData(login, getAccountsAction, accounts.accounts)
	}, [])

	const handleGetEventList = () => {
		if (appId || campaignObject.app_id) {
			getEventsByAppsAction(
				headers,
				appId ?? campaignObject.app_id,
				setEventList,
			)
		}
	}

	const dublicatedPidComponentsList = () => {
		return Object.keys(pidList)
			.map((item, index) => {
				const publishers: string[] = pidList[item]

				const menuItems = publishers.map((pub: string) => (
					<MenuItem
						onClick={() => handlePidResolver(item, pub)}
						value={pub}
						key={pub}
					>
						{pub}
					</MenuItem>
				))
				return [
					<Grid item xs={12}>
						<Grid
							container
							style={{
								width: '100%',
								display: 'flex',
								marginBottom: '-1px',
								alignItems: 'flex-start',
							}}
						>
							<Grid item xs={5}>
								<InputFieldText
									label=''
									type={''}
									value={item}
								></InputFieldText>
							</Grid>
							<Grid item xs={6} style={{ marginTop: '20px' }}>
								<StyledSelect value={pidConflictResolver[item] || ''}>
									{menuItems}
								</StyledSelect>
							</Grid>
						</Grid>
					</Grid>,
				]
			})
			.flat()
	}

	function a11yProps(index: number) {
		return {
			id: `simple-tab-${index}`,
			'aria-controls': `simple-tabpanel-${index}`,
		}
	}

	const handleChangeTab = (event: React.SyntheticEvent, newValue: number) => {
		setTab(newValue)
	}
	useEffect(() => {
		const advert = advertiser.advertiser.filter(
			(el: any) => el.advertiser_name === advertiserName,
		)
		if (advert[0]) {
			// setCurrentAdvertiser(advert[0])
			setCampaignOwner(advert[0].email)
			if (
				!advfileLink ||
				!foundCampaign ||
				(foundCampaign && !foundCampaign.adv_file_link)
			) {
				setAdvfileLink(advert[0].link_to_io)
			}
			if (
				!creativesLink ||
				!foundCampaign ||
				(foundCampaign && !foundCampaign.creatives)
			) {
				setCreativesLink(advert[0].link_to_creative)
			}
		}
	}, [advertiserName])

	useEffect(() => {
		if (newUuid !== '') {
			const main = (newUuid as any).find((el: any) => el.role === 'M')
			props.setDuplicating(false)

			navigate('/campaigns/' + main.uuid)
			for (const key in campaignObject.uniques) {
				for (const element of newUuid as any) {
					if (element.role === key) {
						campaignObject.uniques[key].uuid = element.uuid
					}
				}
			}
		}
	}, [newUuid])

	useEffect(() => {
		if (!!appId && !!advertiserName) {
			const app = advertiser.advertiser.filter(
				(el: any) => el.advertiser_name === advertiserName,
			)
			setAvailable(true)
			handleGetEventList()
			// setPreviewUrl(app[0].link_to_adv_file)
		} else {
			setAvailable(false)
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [advertiserName, appId])
	useEffect(() => {
		if (isUuid(uuidInUrl)) {
			fillCampaignStates()

			handleGetEventList()
			getCampaignHistoryByMainDtoAction(
				headers,
				{ main_uuid: uuidInUrl },
				setHistoryIsLoading,
			)
		} else {
			nullifyCompaignStates()
		}
		if (props.duplicating) {
			setCampaignName((campaignName) => campaignName + ' (Duplicated)')
		}

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [])

	useEffect(() => {
		const iconsFound = app.appIcons.filter(
			(a: any) => a.app_id === appId && a.identifier === networkAccount,
		)

		if (iconsFound && iconsFound.length > 0) {
			foundCampaign['icon'] = iconsFound.icon_url
			setIconUrl(iconsFound.icon_url)
		}
	}, [app, appId, networkAccount])

	useEffect(() => {
		if (campaignObject.uniques && appId && appName) {
			let GeoArray = []
			// console.log(geoArray)

			const campaignNamgeGenerated =
				appName +
				' - ' +
				(geoArray.flat().length > 6
					? 'MultiGeo'
					: geoArray.toString().toUpperCase()) +
				' - ' +
				(appId?.startsWith('id') ? 'iOS' : 'Android') +
				' - ' +
				campaignFlow +
				(additionalTag !== '' ? ' - ' + additionalTag : '')
			// console.log(campaignNamgeGenerated)

			setCampaignName(campaignNamgeGenerated)
			campaignObject.campaign_name = campaignNamgeGenerated
		}
	}, [appName, geoArray, campaignFlow, appId, additionalTag])

	useEffect(() => {
		fillUniqueStates()
	}, [eventNamesChosen])

	const closeDetailedView = () => {
		props.setDuplicating(false)

		navigate('/campaigns')
		props.setPopUpVisible(false)
		nullifyCompaignStates()
	}
	const duplicateUniqueTab = (tab: any) => {
		const newRole =
			Object.keys(campaignObject.uniques).length > 0
				? 'Q' + Object.keys(campaignObject.uniques).length
				: 'M'
		// const newEntry = {
		//  media_source_pid: campaignObject.uniques[tab].media_source_pid,
		//  country: campaignObject.uniques[tab].country,
		//  tracked_event: campaignObject.uniques[tab].tracked_event,
		// }
		// console.log('before', JSON.stringify(campaignObject.uniques))
		const newEntry = _.cloneDeep(uniques[tab])
		delete newEntry.uuid
		uniques[newRole] = newEntry
		const newValue = Object.assign({}, uniques)
		setUniques(newValue)
	}

	const generateNodesFromArrayParameter = (parameter: any[]) => {
		try {
			return generateNodesFromArray(parameter, {
				value: '',
				label: '',
			})
		} catch (error) {
			console.error(error)
			return generateNodesFromArray([], { value: '', label: '' })
		}
	}

	const [update, setUpdate] = useState(false)
	const [uniquesComponents, setUniquesComponents] = useState<any[]>([])

	let ruleSet = getApplicableRules(users, login, settings, 'campaign')

	// console.log(
	// 	tab !== 3,
	// 	ruleSet.rules['opening history'],
	// 	historyArray,
	// 	historyIsLoading,
	// )

	useEffect(() => {
		setUniquesComponents(
			Object.keys(uniques)
				.sort((a: any, b: any) => {
					//thanks chatGPT <3
					if (a === 'M') {
						return -1 // 'M' should be placed before any other value
					} else if (b === 'M') {
						return 1 // 'M' should be placed before any other value
					} else {
						const numA = parseInt(a.slice(1)) // Extract the numeric part of a
						const numB = parseInt(b.slice(1)) // Extract the numeric part of b
						return numA - numB // Sort by numeric order
					}
				})
				.map((e: any) => {
					return (
						<CampaignUnit
							role={e}
							pointer={0}
							key={e}
							// uniqueIsEnabled={uniqueIsEnabled}
							// createUnique={createUnique}
							trackedEventChanged={trackedEventChanged}
							setTrackedEventChanged={setTrackedEventChanged}
							// handleEventAddition={handleEventAddition}
							eventList={eventList}
							uniqueTabState={uniqueTabState}
							// innerGroup={innerGroup}
							setUniqueTabState={setUniqueTabState}
							setDeleteUnique={setDeleteUnique}
							// handleChangeField={handleChangeField}
							mspidList={mspidList}
							setTrackedEvent={setTrackedEvent}
							trackedEvent={trackedEvent}
							setUniques={setUniques}
							campaignObject={campaignObject}
							eventNamesChosen={eventNamesChosen}
							setEventNamesChosen={setEventNamesChosen}
							duplicateUniqueTab={duplicateUniqueTab}
							uniques={uniques}
							externalFunction={externalFunction}
							listOfHooks={listOfHooks}
							setGeoArray={setGeoArray}
							setCountryExcluded={setCountryExcluded}
						/>
					)
				}),
		)
	}, [uniques, eventList, eventNamesChosen])

	useEffect(() => {
		const apps = app.app
			.filter((el: any) => el.advertiser_name === advertiserName)
			.map((el: any) => el.app_id)
		setAppList(apps)
	}, [advertiserName])

	const makeUniqueTab = () => {
		const lastKey = Object.keys(campaignObject.uniques)[
			Object.keys(campaignObject.uniques).length - 1
		]
		let newKey = 'Q1'
		if (!lastKey) {
			newKey = 'M'
		} else if (lastKey.startsWith('Q')) {
			const newDigit = Number(lastKey.slice(1)) + 1
			newKey = 'Q' + newDigit
		}
		const newRole = newKey
		const newEntry = {
			media_source_pid: ['all'],
			country: [''],
			tracked_event: [
				{
					order: 1,
					eventName: 'installs',
					defaultCost: 0,
					defaultRevenue: 0,
				},
			],
		}
		campaignObject.uniques[newRole] = newEntry
		const newValue = Object.assign({}, campaignObject.uniques)
		setUniques(newValue)
		setCampaignAdded(true)
	}

	const fillUniqueStates = () => {
		const newValue = Object.assign({}, campaignObject.uniques)
	}

	function openADatePicker() {
		setDatePickerOpened(true)
	}

	const updateCampaignRules = (reportType: string, rules: any) => {
		let campRules = { rules, reportType }
		setCampaignRules(campRules)
		const campRuleStringified = JSON.stringify(campRules)
		campaignObject.campaign_rules = campRuleStringified
	}
	const openPreview = () => {
		if (params.id) {
			navigate('/campaigns/' + foundCampaign.uuid + '/preview')
		}
	}

	const tabOptions = [
		{ label: 'General Info', tabIndex: 0 },
		{ label: 'Guidelines', tabIndex: 1 },
		{ label: 'Targeting & Affiliates', tabIndex: 2 },
	]

	return (
		<ThemeProvider theme={theme}>
			<div
				style={{
					marginTop: 40,
					paddingInline: '5rem',
					display: 'flex',
					flexDirection: 'row',
					justifyContent: 'space-between',
					alignItems: 'center',
				}}
			>
				<UnborderedButton
					onClick={closeDetailedView}
					icon={
						<ArrowBackIosIcon style={{ height: '16px', marginLeft: '1rem' }} />
					}
					label={'Back'}
					style={{
						marginRight: '0.5rem',
					}}
				/>

				{/* <Box
                // style={{
                //  width: '30%',
                // }}
                > */}
				<TabContainer
					tab={tab}
					handleChangeTab={handleChangeTab}
					tabProps={a11yProps}
					options={tabOptions}
				/>
				{/* </Box> */}
				{foundCampaign && ruleSet.rules.preview && params.id ? (
					<UnborderedButton
						style={{ marginRight: 100 }}
						onClick={openPreview}
						icon={<VisibilityIcon />}
						label={'Preview'}
					/>
				) : (
					<div style={{ width: '80px' }}></div>
				)}
			</div>
			<PageWrapper
				style={{
					maxHeight: '200%',
					overflow: 'visible',
					height: 'fit-content',
				}}
			>
				<TabPanel value={tab} index={0}>
					<CompanyInformationHeader
						name={campaignName}
						iconUrl={handleIconUrl(iconUrl, defaultIcon)}
						style={{ marginLeft: '-0.1rem', marginBottom: '2rem' }}
					/>
					<Grid
						container
						columnSpacing={6}
						columns={12}
						rowSpacing={3}
						direction='row'
						style={{
							display: 'flex',
							width: '92%',
						}}
					>
						<Grid item xs={3}>
							<InputFieldText
								label='App Name'
								disabled={
									!ruleSet.rules[
										'editing main parameters  (advertiser, app_id, c parameter, media source, geos, payouts)'
									]
								}
								type='text'
								value={appName}
								onChange={(e: string) => {
									setAppNameTouched(true)
									setAppName(e)
								}}
								errormessage={
									appNameTouched && !stringIsValidName(appName)
										? "App name is mandatory. App name must not start or end with whitespace and must not contain '/', '%', ',' or '.'"
										: undefined
								}
							/>
						</Grid>
						<Grid item xs={3}>
							<InputFieldText
								label='Additional Tag'
								type='text'
								disabled={!ruleSet.rules['editing other parameters']}
								value={additionalTag}
								onChange={setAdditionalTag}
							/>
						</Grid>
						<Grid item xs={3}>
							<DropList
								label='Advertiser'
								options={advertiserList || []}
								disabled={
									!ruleSet.rules[
										'editing main parameters  (advertiser, app_id, c parameter, media source, geos, payouts)'
									]
								}
								value={advertiserName}
								onChange={(value: any) => {
									setAdvertiserName(value)
									setAppId('')
								}}
								mandatory={mandatoryFields.advertiser}
							/>
						</Grid>

						<Grid item xs={3}>
							<DropList
								label='App ID'
								options={appList}
								disabled={
									!ruleSet.rules[
										'editing main parameters  (advertiser, app_id, c parameter, media source, geos, payouts)'
									]
								}
								value={appId}
								onChange={(value: any) => {
									setAppId(value)
								}}
								mandatory={mandatoryFields.appId}
							/>
						</Grid>

						<Grid item xs={3}>
							<InputFieldText
								label='Appsflyer C Parameter'
								type='text'
								value={capitalizeFirstLetter(cParam)}
								onChange={(e: any) => {
									setCParam(e)
								}}
								onFocus={() => setCParamValid(false)}
								onBlur={() =>
									cParam === '' ? setCParamValid(false) : setCParamValid(true)
								}
								hint={
									cParam
										? undefined
										: 'C Parameter is what comes AFTER an equality ( = ) sign'
								}
								checked={cParamValid}
								disabled={
									!ruleSet.rules[
										'editing main parameters  (advertiser, app_id, c parameter, media source, geos, payouts)'
									]
								}
							/>
						</Grid>

						<Grid item xs={3}>
							<DropList
								label='Privacy Level'
								options={['Private', 'Premoderation', 'Open']}
								disabled={!ruleSet.rules['editing other parameters']}
								value={privacyLevel}
								onChange={(e: any) => {
									setPrivacyLevel(e)
								}}
							/>
						</Grid>

						<Grid item xs={3}>
							<DropList
								disabled={!ruleSet.rules['editing other parameters']}
								label='Status'
								options={['Active', 'Disabled']}
								value={status ? 'Active' : 'Disabled'}
								onChange={(e: any) => {
									setStatus(e === 'Active')
								}}
							/>
						</Grid>

						{/* <Grid
                            item
                            xs={3}
                            flexDirection='column'
                            style={{ display: 'flex', flexDirection: 'row', gap: '12px' }}
                        > */}
						<Grid item xs={3}>
							<DropList
								disabled={!ruleSet.rules['editing other parameters']}
								label='Fraud Tools'
								options={settings.settings.fraudTools}
								value={fraudTools}
								onChange={(e: any) => {
									setFraudTools(e)
								}}
							/>
						</Grid>
						<Grid item xs={3}>
							<DropList
								disabled={!ruleSet.rules['editing other parameters']}
								label='Campaign Flow'
								options={['CPI', 'CPA', 'CPC', 'CPM']}
								value={campaignFlow}
								onChange={(e: any) => {
									setCampaignFlow(e)
								}}
								errored={!campaignFlow}
								mandatory={mandatoryFields.campaignFlow}
							/>
						</Grid>
						{/* </Grid> */}

						<Grid item xs={3}>
							<ChipInputList
								disabled={!ruleSet.rules['editing other parameters']}
								label='Agency Account'
								openWithClick
								options={
									advertiserName
										? [
												...accounts.accounts.map((e: any) => e.identifier),
										  ]?.filter((e) => e != '') || ['thingortwo']
										: ['thingortwo']
								}
								value={networkAccount}
								onChange={(e: any) => {
									setNetworkAccount(e)
								}}
								onlyOptionsAllowed
							/>
						</Grid>
						<GridContainer item xs={3}>
							<SwitchComponent
								onChange={setIncentive}
								value={incentive}
								label={'Incent Allow'}
								disabled={!ruleSet.rules['editing other parameters']}
							/>
						</GridContainer>
						<GridContainer item xs={3}>
							<SwitchComponent
								onChange={setDummyPids}
								value={dummyPids}
								label={'Dummy PIDs'}
								disabled={!ruleSet.rules['editing other parameters']}
							/>
						</GridContainer>
						<Grid item xs={6}>
							<InputFieldText
								label='Preview Url'
								type='text'
								value={previewUrl ?? ''}
								onChange={(e: any) => {
									setPreviewUrl(e)
								}}
								disabled={!ruleSet.rules['editing other parameters']}
								showUrlIcon
							/>
						</Grid>
						<Grid item xs={6}>
							<InputFieldText
								type='text'
								onChange={setAdvfileLink}
								value={advfileLink}
								label='Link to Google Drive'
								color={
									creativesLink &&
									!checkInputStartsWithHttp(creativesLink) &&
									isValid === false
										? 'error'
										: 'primary'
								}
								disabled={!ruleSet.rules['editing other parameters']}
								showUrlIcon
							/>
						</Grid>
					</Grid>
				</TabPanel>
				<TabPanel value={tab} index={1}>
					<div
						style={{
							color: theme.colors.base.grey900,
							fontSize: '1rem',
							fontWeight: theme.font.weight.bold,
							marginTop: '2rem',
							display: 'flex',
							alignItems: 'center',
							gap: '.7rem',
							marginBottom: 12,
						}}
					>
						{iconUrl?.length > 0 ? (
							<img
								alt='icon'
								src={iconUrl}
								style={{
									height: '45px',
									width: '45px',
									borderRadius: '8px',
								}}
							></img>
						) : (
							<HideImage
								style={{
									color: 'black',
									height: '45px',
									width: '45px',
								}}
							></HideImage>
						)}
						{campaignName}
					</div>
					<Grid container direction={'column'} style={{ width: '92%' }}>
						<Box
							style={{
								textOverflow: 'ellipsis',
								// padding: '15px',
								maxHeight: '200vh',
								overflow: 'hidden',
							}}
						>
							<Grid
								container
								style={{ marginLeft: '3px!important', marginTop: 12 }}
								marginLeft={'3px'}
							>
								<Grid item xs={2.3} style={{ display: 'flex' }}>
									<SwitchComponent
										style={{ alignSelf: 'start' }}
										onChange={setVtaAllowed}
										value={vtaAllowed}
										label={'VTA allowed'}
										disabled={!ruleSet.rules['editing other parameters']}
									/>
									{/* <StyledCheckboxFormControl
                                        key={'vtaAllowed'}
                                        control={
                                            <Checkbox
                                                disabled={!ruleSet.rules['editing other parameters']}
                                                onChange={(e) => {
                                                    setVtaAllowed(!vtaAllowed)
                                                }}
                                                checked={vtaAllowed}
                                                key={'vtaAllowed_checkbox'}
                                                style={{ marginLeft: '0px', color: '#ee1d4a' }}
                                                // disabled={
                                                //  login.user.role.toLowerCase() === 'view' ||
                                                //  login.user.role.toLowerCase() === 'designer'
                                                // }
                                            />
                                        }
                                        label={
                                            <Typography
                                                fontSize='13px'
                                                fontWeight={500}
                                                color={'secondary'}
                                            >
                                                {'VTA allowed'}
                                            </Typography>
                                        }
                                    /> */}
								</Grid>
								<Grid item xs={2.3} style={{ display: 'flex' }}>
									<SwitchComponent
										style={{ alignSelf: 'start' }}
										onChange={setProbVta}
										value={probVta}
										label={'Probabilistic VTA'}
										disabled={!ruleSet.rules['editing other parameters']}
									/>
									{/* <StyledCheckboxFormControl
                                        key={'probabilisticVTA'}
                                        control={
                                            <SwitchToggle
                                                onChange={(e: any) => {
                                                    setProbVta(!probVta)
                                                }}
                                                checked={probVta}
                                                key={'probabilisticVTA_checkbox'}
                                                style={{ marginLeft: '0px', color: '#ee1d4a' }}
                                                disabled={!ruleSet.rules['editing other parameters']}
                                                // disabled={
                                                //  login.user.role.toLowerCase() === 'view' ||
                                                //  login.user.role.toLowerCase() === 'designer'
                                                // }
                                            />
                                        }
                                        label={
                                            <Typography
                                                fontSize='13px'
                                                fontWeight={500}
                                                color={'secondary'}
                                            >
                                                {'Probabilistic VTA'}
                                            </Typography>
                                        }
                                    /> */}
								</Grid>
								<Grid item xs={2.3} style={{ display: 'flex' }}>
									<SwitchComponent
										style={{ alignSelf: 'start' }}
										onChange={setRevenueNeeded}
										value={revenueNeeded}
										label={'Revenue'}
										disabled={!ruleSet.rules['editing other parameters']}
									/>
									{/* <StyledCheckboxFormControl
                                        key={'revenueData'}
                                        control={
                                            <Checkbox
                                                onChange={(e) => {
                                                    setRevenueNeeded(!revenueNeeded)
                                                }}
                                                checked={revenueNeeded}
                                                key={'revenueData_checkbox'}
                                                style={{ marginLeft: '0px', color: '#ee1d4a' }}
                                                disabled={!ruleSet.rules['editing other parameters']}
                                                // disabled={
                                                //  login.user.role.toLowerCase() === 'view' ||
                                                //  login.user.role.toLowerCase() === 'designer'
                                                // }
                                            />
                                        }
                                        label={
                                            <Typography
                                                fontSize='13px'
                                                fontWeight={500}
                                                color={'secondary'}
                                            >
                                                {'Revenue'}
                                            </Typography>
                                        }
                                    /> */}
								</Grid>
								<Grid item xs={2.3} style={{ display: 'flex' }}>
									<SwitchComponent
										style={{ alignSelf: 'start' }}
										onChange={setOrganicNeeded}
										value={organicNeeded}
										label={'Organic Data'}
										disabled={!ruleSet.rules['editing other parameters']}
									/>
									{/* <StyledCheckboxFormControl
                                        key={'organicData'}
                                        control={
                                            <Checkbox
                                                onChange={(e) => {
                                                    setOrganicNeeded(!organicNeeded)
                                                }}
                                                checked={organicNeeded}
                                                key={'organicData_checkbox'}
                                                style={{ marginLeft: '0px', color: '#ee1d4a' }}
                                                disabled={!ruleSet.rules['editing other parameters']}
                                                // disabled={
                                                //  login.user.role.toLowerCase() === 'view' ||
                                                //  login.user.role.toLowerCase() === 'designer'
                                                // }
                                            />
                                        }
                                        label={
                                            <Typography
                                                fontSize='13px'
                                                fontWeight={500}
                                                color={'secondary'}
                                            >
                                                {'Organic Data'}
                                            </Typography>
                                        }
                                    /> */}
								</Grid>
								<Grid item xs={2.3} style={{ display: 'flex' }}>
									<SwitchComponent
										style={{ alignSelf: 'start' }}
										onChange={setRetargeting}
										value={retargeting}
										label={'Retargeting'}
										disabled={!ruleSet.rules['editing other parameters']}
									/>
									{/* <StyledCheckboxFormControl
                                        key={'retargeting'}
                                        control={
                                            <Checkbox
                                                onChange={(e) => {
                                                    setRetargeting(!retargeting)
                                                    // handleChangeField(!retargeting, 0, 'retargeting')
                                                }}
                                                checked={retargeting}
                                                key={'retargeting_checkbox'}
                                                inputProps={{
                                                    'aria-label': 'controlled',
                                                }}
                                                style={{ marginLeft: '0px', color: '#ee1d4a' }}
                                                disabled={!ruleSet.rules['editing other parameters']}
                                                // disabled={
                                                //  login.user.role.toLowerCase() === 'view' ||
                                                //  login.user.role.toLowerCase() === 'designer'
                                                // }
                                            />
                                        }
                                        label={
                                            <Typography
                                                fontSize='13px'
                                                fontWeight={500}
                                                color={'secondary'}
                                            >
                                                {'Retargeting'}
                                            </Typography>
                                        }
                                    /> */}
								</Grid>
							</Grid>
							<Grid
								container
								style={{ marginTop: '32px', width: '100%' }}
								columnSpacing={4}
								rowSpacing={4}
							>
								<Grid item xs={6} style={{ width: '100%' }}>
									<StyledTextAreaField
										key='hardKpiInput'
										onChange={setHardKpi}
										label='Hard KPI'
										// disabled={!ruleSet.rules['editing other parameters']}
										value={hardKpi}
									/>
								</Grid>
								<Grid item xs={6} style={{ width: '100%' }}>
									<StyledTextAreaField
										key='softKpiInput'
										onChange={setSoftKpi}
										label='Soft KPI'
										// disabled={!ruleSet.rules['editing other parameters']}
										value={softKpi}
									/>
								</Grid>
								<Grid item xs={6}>
									<StyledTextAreaField
										key='flowInput'
										label='Complete Flow'
										onChange={setEventsFlow}
										value={eventsFlow}

										// disabled={!ruleSet.rules['editing other parameters']}
									/>
								</Grid>
								<Grid item xs={6}>
									<StyledTextAreaField
										key='notesInput'
										label='Special Notes'
										onChange={setNote}
										value={note}
										// disabled={!ruleSet.rules['editing other parameters']}
									/>
								</Grid>
								{advertiserName !== '' && (
									<Grid item xs={6}>
										<ChipInputList
											options={settings.settings.trafficRestrictions || []}
											label={'Traffic Restrictions'}
											value={traffic}
											onChange={(e: any) => {
												setTraffic(e)
											}}
											openWithClick
											disabled={!ruleSet.rules['editing other parameters']}
										></ChipInputList>
									</Grid>
								)}
								{advertiserName !== '' && (
									<Grid item xs={6}>
										<ChipInputList
											options={settings.settings.existingChannels || []}
											label={'Existing Channels Client is Using'}
											value={usedExistingChannels}
											onChange={(e: any) => {
												setUsedExistingChannels(e)
											}}
											openWithClick
											disabled={!ruleSet.rules['editing other parameters']}
										></ChipInputList>
									</Grid>
								)}

								{advertiserName !== '' && (
									<Grid item xs={12}>
										<div
											style={{
												color: theme.colors.base.grey900,
												fontSize: '1rem',
												fontWeight: theme.font.weight.bold,
												marginLeft: '5px',
												marginTop: '22px',
											}}
										>
											Target Audience
										</div>
									</Grid>
								)}
								{advertiserName !== '' && (
									<Grid item xs={6}>
										<RadioChipInput
											disabled={!ruleSet.rules['editing other parameters']}
											label='Gender'
											options={['Male', 'Female', 'Both']}
											value={gender}
											onChange={(e: any) => {
												setGender(e)
											}}
										/>
									</Grid>
								)}
								{advertiserName !== '' && (
									<Grid item xs={6}>
										<div style={{ width: '50%', position: 'relative' }}>
											<div
												style={{
													display: 'flex',
													flexDirection: 'row',
													gap: '18px',
												}}
											>
												<InputFieldText
													label='Age'
													type='number'
													value={targetAudienceAgeFrom}
													onChange={(e: any) => setTargetAudienceAgeFrom(e)}
													float='left'
													placeholder={'from'}
													disabled={!ruleSet.rules['editing other parameters']}
												/>

												<InputFieldText
													label={''}
													type='number'
													value={targetAudienceAgeTo}
													onChange={(e: any) => setTargetAudienceAgeTo(e)}
													float='right'
													placeholder={'to'}
													disabled={!ruleSet.rules['editing other parameters']}
												/>
											</div>
										</div>
									</Grid>
								)}
							</Grid>
						</Box>
					</Grid>
				</TabPanel>
				<TabPanel value={tab} index={2} key={'panel_123'}>
					<div
						style={{
							color: theme.colors.base.grey900,
							fontSize: '1rem',
							fontWeight: theme.font.weight.bold,
							marginTop: '2rem',
							display: 'flex',
							alignItems: 'center',
							gap: '.7rem',
						}}
					>
						{iconUrl?.length > 0 ? (
							<img
								alt='icon'
								src={iconUrl}
								style={{
									height: '45px',
									width: '45px',
									borderRadius: '8px',
								}}
							></img>
						) : (
							<HideImage
								style={{
									color: 'black',
									height: '45px',
									width: '45px',
								}}
							></HideImage>
						)}
						{campaignName}
					</div>
					<Grid
						container
						columns={12}
						columnSpacing={6}
						rowSpacing={0}
						direction='row'
						style={{
							display: 'flex',
							rowGap: '1.2rem',
							marginTop: 24,
							marginBottom: 24,
							width: '92%',
						}}
					>
						<Grid item xs={3}>
							<DatePicker
								label={'Campaign End Date'}
								onChange={setEndDate}
								value={endDate}
								disabled={!ruleSet.rules['editing other parameters']}
								format='dd/MM/yyyy'
							/>
						</Grid>
						<Grid item xs={3}>
							<DropList
								label='Time Frame'
								options={['Monthly', 'Total']}
								// float='right'
								disabled={!ruleSet.rules['editing other parameters']}
								value={timeFrame}
								onChange={(value: any) => {
									setAppId(value)
								}}
							/>
						</Grid>
						<Grid item xs={3}>
							<InputFieldText
								key='amountInput'
								onChange={(e: any) => {
									setBudgetAmount(e)
								}}
								value={budgetAmount}
								label='Budget'
								type={'text'}
								disabled={!ruleSet.rules['editing other parameters']}
							/>
						</Grid>
						<Grid item xs={3}>
							<InputFieldText
								key='Capnput'
								onChange={(e: any) => {
									setCap(e)
								}}
								value={cap}
								label='Cap'
								type={'text'}
								disabled={!ruleSet.rules['editing other parameters']}
							/>
						</Grid>
						<Grid
							item
							xs
							style={{
								display: 'flex',
								alignItems: 'center',
								justifyContent: 'center',
							}}
						>
							{conflicts.length > 0 && (
								<Card
									style={{
										marginTop: '23px',
										border: '1px solid rgba(0, 0, 0, 0.3)',
										width: '98%',
										borderRadius: '17px',

										display: 'flex',
									}}
								>
									<Typography
										style={{
											fontWeight: '500',
											fontSize: '14px',
											marginLeft: '20px',
											marginTop: '10px',
										}}
									>
										Media Source PID conflicts
									</Typography>
									<Grid container>{conflicts}</Grid>
								</Card>
							)}
						</Grid>
					</Grid>

					{uniquesComponents}

					{uniqueTabState}

					{ruleSet.rules['adding unique payouts'] && (
						<div
							style={{
								display: 'flex',
								width: '100%',
								marginTop: '6vh',
							}}
						>
							<UnborderedButton
								onClick={() => makeUniqueTab()}
								label={'Add Campaign'}
								icon={
									<AddIcon
										style={{
											border: '1px solid ' + theme.colors.text.titles,
											borderRadius: '100px',
											width: '14px',
											height: '14px',
										}}
									/>
								}
								style={{ color: theme.colors.text.titles }}
								mandatory={!campaignAdded && mandatoryFields.uniqueCampaigns}
								interacted={fieldValidations.uniqueCampaigns}
							/>
						</div>
						// <PlusTab
						//  onClick={() => {
						//      makeUniqueTab()
						//  }}
						//  value={pointer}
						//  // disabled={uniqueIsEnabled}
						//  // disabled={!uniqueIsEnabled}
						//  style={{
						//      position: 'relative',
						//      display: 'flex',

						//      top: '30px',
						//      width: '17%',
						//      boxShadow:
						//          uniquesComponents.length === 0 ? 'red 0px 0px 7px' : 'none',
						//      alignSelf: 'flex-end',
						//      justifySelf: 'center',
						//      alignItems: 'center',
						//  }}
						// >
						//  + Unique
						// </PlusTab>
					)}
				</TabPanel>
				<TabPanel value={tab} index={3} key={'panel_665'}>
					<Grid
						container
						direction={'column'}
						marginTop={'0px'}
						style={{
							alignItems: 'center',
							justifyContent: 'center',
							width: '80vw',
						}}
					>
						<Box
							style={{
								textOverflow: 'ellipsis',
								padding: '15px',
								paddingInline: '100px',
								display: 'flex',
								flexDirection: 'column',
								alignItems: 'center',
								justifyContent: 'center',
								width: '100%',
								overflow: 'auto',
							}}
						>
							{/* {campaignRules.rules && ( */}
							{/* <CampaignRules
                                submit={() => trySubmit()}
                                updateRules={(reportType: string, rules: any) =>
                                    updateCampaignRules(reportType, rules)
                                }
                                campaignRules={campaignRules}
                                setErrorMessage={(str: string) => props.setErrorMessage(str)}
                            /> */}
							{/* )} */}
						</Box>
					</Grid>
				</TabPanel>
			</PageWrapper>
			{tab !== 3 && (
				<ButtonBar style={{ marginLeft: '30px', marginBottom: '35px' }}>
					{/* {ruleSet.rules['editing other parameters'] ||
                        (ruleSet.rules[
                            'editing main parameters  (advertiser, app_id, c parameter, media source, geos, payouts)'
                        ] && ( */}
					<UnborderedButton
						onClick={() => {
							nullifyCompaignStates()
							props.setRow(undefined)
							navigate('/campaigns')
							props.setPopUpVisible(false)
						}}
						style={{ color: theme.colors.text.titles }}
						label={'Cancel'}
					/>
					{/* ))} */}
					{((ruleSet.rules['editing other parameters'] &&
						ruleSet.rules[
							'editing main parameters  (advertiser, app_id, c parameter, media source, geos, payouts)'
						]) ||
						(ruleSet.post && campaignOwner === login.user.email)) && (
						<SubmitButton
							// disabled={!isValid || pointer !== 0}
							disabled={
								!(
									appId &&
									advertiser &&
									campaignFlow &&
									uniquesComponents.length > 0
								)
							}
							onClick={() => {
								isEditingHistory ? trySaveHistoricalEnrty() : trySubmit()
								getCampaignHistoryByMainDtoAction(
									headers,
									{ main_uuid: uuidInUrl },
									setHistoryIsLoading,
								)
							}}
							// style={{ width: 100, height: 30 }}
						>
							{props.duplicating === true
								? 'Duplicate'
								: foundCampaign
								? isEditingHistory
									? 'Save History'
									: 'Update'
								: 'Save'}
						</SubmitButton>
					)}
					{ruleSet.rules['opening history'] ? (
						<SubmitButton
							// disabled={!isValid || pointer !== 0}
							disabled={props.duplicating === true || !foundCampaign}
							onClick={() => {
								openADatePicker()
							}}
							// style={{ width: '12%' }}
						>
							{'Update From Date'}
						</SubmitButton>
					) : (
						<></>
					)}
				</ButtonBar>
			)}
			{tab !== 3 && ruleSet.rules['opening history'] ? (
				<DatePickerComponent
					setDatePicked={setDatePicked}
					datePicked={datePicked}
					setDatePickerOpened={setDatePickerOpened}
					datePickerOpened={datePickerOpened}
					trySubmit={trySubmit}
				></DatePickerComponent>
			) : (
				<></>
			)}
			{tab !== 3 && ruleSet.rules['opening history'] ? (
				<DatePickerComponent
					setDatePicked={setDatePicked}
					datePicked={datePicked}
					setDatePickerOpened={setDateMoverOpened}
					datePickerOpened={dateMoverOpened}
					trySubmit={trySaveHistoricalEnrty}
				></DatePickerComponent>
			) : (
				<></>
			)}
			{/* temporally hiding history array in order to deploy */}
			{tab !== 3 &&
			ruleSet.rules['opening history'] &&
			historyArray &&
			!historyIsLoading ? (
				<Paper
					style={{
						borderRadius: '30px',
						padding: '20px',
						paddingInline: '30px',
						zIndex: '15',
						gridAutoFlow: 'column',
						width: 'fit-content',
						marginInline: 'auto',
						marginBottom: '30px',
					}}
				>
					{' '}
					<div
						style={{
							color: '#f2f2f2',
							marginBottom: '20px',
							marginInline: '30px',
							fontSize: '20px',
							fontWeight: 'bold',
						}}
					>
						<div
							style={{
								color: 'black',
							}}
						>
							History
						</div>
					</div>
					<Grid
						container
						columns={110}
						direction={'row'}
						alignItems={'center'}
						style={{
							background: 'transparent',
							// height: '70%',

							bottom: '200px',
							borderRadius: '30px',
							textAlign: 'center',
							width: 1000,
							gridAutoFlow: 'column',
							alignItems: 'center',
							// width: widthArray[i] + 'px',
						}}
					>
						{historyArray}
					</Grid>
					{/* <Grid
                            container
                            columns={100}
                            height={'60px'}
                            paddingBottom={'30px'}
                            style={{
                                background: 'transparent',
                                // height: '70%',
                                // marginRight: '10px',
                                bottom: '200px',
                                borderRadius: '30px',
                                textAlign: 'center',
                                width: 1000,
                                overflow: 'clip',
                                // width: widthArray[i] + 'px',
                            }}
                        >
                            {historyLining}
                        </Grid> */}
				</Paper>
			) : (
				<></>
			)}
		</ThemeProvider>
	)
}
export default CampaignAdd
const DatePickerComponent = (props: {
	setDatePicked: any
	setDatePickerOpened: any
	datePicked: any
	datePickerOpened: any
	trySubmit: any
}) => {
	return (
		<Dialog open={props.datePickerOpened}>
			<DialogContent
				style={{
					display: 'flex',
					flexDirection: 'column',
					justifyContent: 'center',
					alignItems: 'center',
				}}
			>
				<DialogContentText>
					{'Choose the date to save the campaign from'}
				</DialogContentText>
				<LocalizationProvider dateAdapter={AdapterDateFns}>
					<DateComponent
						onChange={(e: any) => props.setDatePicked(formatDate(e))}
						value={props.datePicked}
						inputFormat='dd/MM/yyyy'
						OpenPickerButtonProps={{
							style: { paddingRight: '20px', paddingLeft: '0px' },
						}}
						renderInput={(params: any) => (
							<StyledTextInputField
								{...params}
								focused
								fullWidth
								InputLabelProps={{
									style: { top: '-20%' },
								}}
							/>
						)}
					></DateComponent>
				</LocalizationProvider>
			</DialogContent>
			<DialogActions>
				<SubmitButton
					onClick={() => {
						props.setDatePicked(null)
						props.setDatePickerOpened(false)
					}}
				>
					Close
				</SubmitButton>
				<SubmitButton
					onClick={() => {
						props.trySubmit()
					}}
				>
					Update
				</SubmitButton>
			</DialogActions>
		</Dialog>
	)
}
const CampaignUnit = (props: {
	// uniqueIsEnabled: any
	trackedEventChanged: string[]
	setTrackedEventChanged: any
	eventList: string[]
	role: any
	pointer: any
	uniqueTabState: any
	setUniques: any
	setUniqueTabState: any
	setDeleteUnique: any
	// setInnerGroup: any
	// handleChangeField: any
	mspidList: any
	// setIsEditing: any
	setTrackedEvent: any
	trackedEvent: any
	campaignObject: any
	// setUniques: any
	eventNamesChosen: any
	setEventNamesChosen: any
	externalFunction: any
	listOfHooks: any
	setGeoArray: any
	duplicateUniqueTab: any
	uniques: any
	setCountryExcluded: any
}) => {
	const { login, users, settings } = useTypedSelector((state) => state)
	const [fixPrice, setFixPrice] = useState('')
	const [mediaSourcePid, setMediaSourcePid] = useState(['all'])

	const [uniqueState, setUniqueState] = useState(
		props.campaignObject.uniques[props.role],
	)

	const [isExcludedCountry, setIsExcludedCountry] = useState<boolean>(
		props.campaignObject.country_excluded,
	)

	const [country, setCountry] = useState<any[]>([])
	const [isRevenueShare, setIsRevenueShare] = useState(false)
	const [costFromDate, setCostFromDate] = useState(false)
	const [eventFormState, setEventFormState] = useState<any>(null)

	const [fixPriceSelected, setFixPriceSelected] = useState(false)
	const [trackedEvent, setTrackedEvent] = useState<any>([])

	const countryCodes = countryList.map((item) => item.slice(0, 2).toLowerCase())

	const getDisplayCountries = () => {
		let displayCountries = []
		const includedCountries =
			props.campaignObject['uniques'][props.role]['country']

		if (isExcludedCountry) {
			const excludedCountries = countryCodes.filter(
				(country: string) => !includedCountries.includes(country),
			)
			displayCountries = excludedCountries
		} else {
			displayCountries = includedCountries
		}
		return displayCountries
	}
	const [displayCountries, setDisplayCountries] = useState<string[]>([])

	useEffect(() => {
		const geosForDisplay = getDisplayCountries()
		setDisplayCountries(geosForDisplay)
	}, [])

	const fillUniqueStates = () => {
		let statesObj = {}
		// setMediaSourcePid(props.campaignObject.uniques[props.role].media_source_pid)
		// setCountry(props.campaignObject.uniques[props.role].country)
		// setTrackedEvent(props.campaignObject.uniques[props.role].tracked_event)
		// setIsRevenueShare(props.campaignObject.uniques[props.role].is_revenue_share)
		// setCostFromDate(props.campaignObject.uniques[props.role].cost_from_data)
		// console.log(
		//  'props used',
		//  props.campaignObject,
		//  props.role,
		//  props.campaignObject.uniques[props.role],
		// )
		if (props.campaignObject.uniques[props.role]) {
			if (!props.campaignObject.uniques[props.role].tracked_event) {
				helperMakeFirstEvent(statesObj)
			}
			setUniqueState(
				Object.assign(
					{ ...statesObj },
					props.campaignObject.uniques[props.role],
				),
			)
		}
	}

	const helperMakeFirstEvent = (obj: any) => {
		const new_event = {
			eventName: 'installs',
			defaultRevenue: 0,
			defaultCost: 0,
			order: 1,
		}
		// setTrackedEvent([new_event])
		obj.tracked_event = [new_event]
		return obj
	}

	const removeUniqueTab = () => {
		if (Object.keys(props.campaignObject.uniques).length === 1) {
			return
		}
		props.setDeleteUnique((prev: any) => {
			return prev
				? [...prev, props.campaignObject.uniques[props.role].uuid]
				: [props.campaignObject.uniques[props.role].uuid]
		})
		delete props.campaignObject.uniques[props.role]
		if (props.role === 'M') {
			const roles = Object.keys(props.campaignObject.uniques)
			for (let i = 0; i < roles.length; i++) {
				let newRole = 'M'
				if (i > 0) {
					newRole = `Q${i}`
				}
				const currentRole = roles[i]
				props.campaignObject.uniques[newRole] =
					props.campaignObject.uniques[currentRole]
				delete props.campaignObject.uniques[currentRole]
			}
		}
		const newUniques = Object.assign({}, props.campaignObject.uniques)
		// console.log('new uniques', newUniques)
		// console.log('props uniques', props.campaignObject)
		fillUniqueStates()
		props.setUniques(newUniques)
	}

	const handleEventAddition = () => {
		const new_event = {
			eventName: '',
			defaultRevenue: 0,
			defaultCost: 0,
			order: props.campaignObject.uniques[props.role].tracked_event.length + 1,
		}
		Object.values(props.campaignObject.uniques[props.role].tracked_event).map(
			(e: any, i, a) => [...a, new_event],
		)
		// for (let role of Object.keys(props.campaignObject.uniques)) {
		props.campaignObject.uniques[props.role].tracked_event = [
			...props.campaignObject.uniques[props.role].tracked_event,
			new_event,
		]
		fillUniqueStates()
		// }
	}

	// useEffect(() => {
	// 	console.log('is excluded: ', isExcludedCountry)
	// 	console.log('countries to display: ', getDisplayCountries())
	// }, [])

	const handleGeoChange = (e: any) => {
		setDisplayCountries(e)
		const normalizedData =
			e.length === 0 ? [''] : e.filter((el: string) => el !== 'GLOBAL')
		console.log('data recieved: ', normalizedData)
		let updatedData: any = []
		if (isExcludedCountry) {
			updatedData = countryCodes.filter(
				(country) => !normalizedData.includes(country),
			)
		} else {
			updatedData = normalizedData
		}
		props.campaignObject['uniques'][props.role]['country'] = updatedData
	}

	const handlePidChange = (e: any) => {
		let normalizedData = ['all']
		if (e.length === 1) {
			normalizedData = e
		} else if (e.length > 1) {
			normalizedData = e.filter((el: string) => el !== 'all')
		}
		props.campaignObject['uniques'][props.role]['media_source_pid'] =
			normalizedData
		fillUniqueStates()
	}

	useEffect(() => {
		// handleGeoChange(props.campaignObject['uniques'][props.role]['country'])
		handlePidChange(
			props.campaignObject['uniques'][props.role]['media_source_pid'],
		)
	}, [])
	useEffect(() => {
		fillUniqueStates()
	}, [props.eventNamesChosen])

	useEffect(() => {
		let GeoArray: any = []
		for (let role of Object.keys(props.campaignObject.uniques)) {
			GeoArray.push(props.campaignObject.uniques[role].country)
		}
		const geoSet = [
			...new Set(GeoArray.flat().map((e: string) => e.toUpperCase())),
		]

		if (geoSet.includes('')) {
			// console.log('GLOBAL')
			props.setGeoArray(['GLOBAL'])
		} else {
			// console.log('NOT GLOBAL')
			props.setGeoArray(geoSet.filter((e) => e !== '' && e !== 'GLOBAL').sort())
		}
		fillUniqueStates()
	}, [country, props.uniques])

	useEffect(() => {
		setEventFormState(
			<EventForm
				value={uniqueState.tracked_event}
				options={props.eventList}
				onChange={fillUniqueStates}
				pointer={props.pointer}
				isRevenueShare={isRevenueShare}
				handleEventAddition={handleEventAddition}
				setTrackedEventChanged={props.setTrackedEventChanged}
				trackedEventChanged={props.trackedEventChanged}
				available={true}
				// innerGroup={props.innerGroup}
				key={'evenet' + props.role}
				// role={props.role}
				loginRole={login.user.role}
				campaignObject={props.campaignObject}
				role={props.role}
				eventNamesChosen={props.eventNamesChosen}
				setEventNamesChosen={props.setEventNamesChosen}
				externalFunction={props.externalFunction}
				listOfHooks={props.listOfHooks}
				setUniques={props.setUniques}
				isCostFromDate={costFromDate}
			/>,
		)
	}, [uniqueState])
	const ruleSet = getApplicableRules(users, login, settings, 'campaign')

	const [componentInitialRender, setComponentInitialRender] =
		useState<boolean>(false)
	useEffect(() => {
		if (componentInitialRender) {
			let updatedData = props.campaignObject['uniques'][props.role]['country']
			if (isExcludedCountry) {
				updatedData = countryCodes.filter(
					(country) =>
						!props.campaignObject['uniques'][props.role]['country'].includes(
							country,
						),
				)
			} else {
				updatedData = countryCodes.filter(
					(country) => !updatedData.includes(country),
				)
			}
			props.campaignObject['uniques'][props.role]['country'] = updatedData
		} else {
			setComponentInitialRender(true)
			return
		}
	}, [isExcludedCountry])

	const handleCountryExcluded = () => {
		props.campaignObject.country_excluded = !isExcludedCountry
		props.setCountryExcluded(!isExcludedCountry)
		setIsExcludedCountry(!isExcludedCountry)
	}

	return (
		<>
			<UniqueCards
				style={{
					// width: '73vw',
					width: '92%',
					// border: '1px solid rgba(0, 0, 0, 0.3)',
					flexWrap: 'nowrap',
					flexDirection: 'column',
					display: 'flex',
				}}
			>
				<hr
					style={{
						width: '102%',
						background: theme.colors.base.grey300,
						height: '1px',
						border: 'none',
						marginLeft: '-3%',
					}}
				></hr>
				{ruleSet.rules[
					'editing main parameters  (advertiser, app_id, c parameter, media source, geos, payouts)'
				] && (
					<div
						style={{
							zIndex: '15',
							width: '100%',
							height: 'auto',
							display: 'flex',
							position: 'relative',
							marginTop: 42,
						}}
					>
						<UnborderedButton
							style={{
								// transform: 'translateX(34vw) translateY(-1vh)',
								position: 'absolute',
								top: 0,
								right: '2%',
								color: theme.colors.text.titles,
							}}
							icon={
								<DeleteIcon
									// style={{
									// 	border: '1px solid ' + theme.colors.text.titles,
									// 	borderRadius: '100px',
									// 	width: '14px',
									// 	height: '14px',
									// }}
									style={{ color: theme.colors.text.titles, scale: '0.8' }}
								/>
							}
							// disabled={props.role === 'M'}
							onClick={() => {
								removeUniqueTab()
							}}
							label={'Delete'}
						></UnborderedButton>
						<UnborderedButton
							onClick={() => {
								props.duplicateUniqueTab(props.role)
							}}
							style={{
								// transform: 'translateX(28vw) translateY(-1vh)',
								top: 0,
								right: '10%',
								position: 'absolute',
								color: theme.colors.text.titles,
							}}
							icon={
								<DuplicateIcon
									// style={{
									// 	border: '1px solid ' + theme.colors.text.titles,
									// 	borderRadius: '100px',
									// 	width: '14px',
									// 	height: '14px',
									// }}
									style={{ color: theme.colors.text.titles, scale: '0.8' }}
								/>
							}
						>
							Duplicate
						</UnborderedButton>
					</div>
				)}
				<Grid
					container
					columns={12}
					direction={'row'}
					columnSpacing={8}
					rowSpacing={4}
				>
					<Grid item xs={12} style={{ marginBottom: 16 }}>
						<span style={{ color: theme.colors.base.grey900 }}>
							Sub-Campaign
						</span>
					</Grid>
					<Grid item xs={5}>
						<ChipInputList
							label='Media Source PID (Unique)'
							options={props.mspidList}
							value={uniqueState.media_source_pid}
							onChange={(e: any) => {
								handlePidChange(e)
							}}
							hint='press ENTER after each PID'
							openWithClick
							disabled={
								!ruleSet.rules[
									'editing main parameters  (advertiser, app_id, c parameter, media source, geos, payouts)'
								]
							}
						/>
					</Grid>
					<Grid item xs={5}>
						<CountryListSelector
							label={isExcludedCountry ? 'Excluded Country' : 'Country'}
							options={countryList}
							value={displayCountries}
							onChange={(e: any) => {
								handleGeoChange(e)
							}}
							chipStyle={{ opacity: isExcludedCountry ? 0.38 : 1 }}
							disabled={
								!ruleSet.rules[
									'editing main parameters  (advertiser, app_id, c parameter, media source, geos, payouts)'
								]
							}
						/>
					</Grid>
					<Grid item xs={2} style={{ marginTop: 6 }}>
						<SwitchComponent
							onChange={handleCountryExcluded}
							value={isExcludedCountry}
							label={'Excluded Country'}
							disabled={
								!ruleSet.rules[
									'editing main parameters  (advertiser, app_id, c parameter, media source, geos, payouts)'
								]
							}
						/>
					</Grid>
					<Grid
						item
						xs={12}
						container
						columns={12}
						direction={'row'}
						columnSpacing={0}
						rowSpacing={2}
					>
						<Grid item xs={2}>
							{ruleSet.rules[
								'editing main parameters  (advertiser, app_id, c parameter, media source, geos, payouts)'
							] && (
								<SwitchComponent
									onChange={(e) => {
										props.campaignObject['uniques'][
											props.role
										].is_revenue_share =
											!props.campaignObject['uniques'][props.role]
												.is_revenue_share
										setIsRevenueShare(!isRevenueShare)
									}}
									value={
										props.campaignObject['uniques'][props.role].is_revenue_share
									}
									label={'Revenue Share'}
									disabled={
										!ruleSet.rules[
											'editing main parameters  (advertiser, app_id, c parameter, media source, geos, payouts)'
										]
									}
								/>
							)}
						</Grid>
						<Grid item xs={2}>
							{ruleSet.rules[
								'editing main parameters  (advertiser, app_id, c parameter, media source, geos, payouts)'
							] && (
								<SwitchComponent
									onChange={(e) => {
										props.campaignObject['uniques'][props.role].cost_from_data =
											!props.campaignObject['uniques'][props.role]
												.cost_from_data
										setCostFromDate(!costFromDate)
									}}
									value={
										props.campaignObject['uniques'][props.role].cost_from_data
									}
									label={'Cost From Appsflyer'}
									disabled={
										!ruleSet.rules[
											'editing main parameters  (advertiser, app_id, c parameter, media source, geos, payouts)'
										]
									}
								/>
							)}
						</Grid>
					</Grid>
				</Grid>

				<Grid
					item
					xs={4}
					style={{ height: '10%', width: '95%', marginTop: 34 }}
				>
					{eventFormState}
				</Grid>
			</UniqueCards>

			<>
				{
					<div
						style={{
							width: '95%',
							justifyContent: 'center',
						}}
					></div>
				}
			</>
		</>
	)
}

const TimelineDataGrid = (props: { filteredHistory: any }) => {
	let offset = new Date(props.filteredHistory[0].date_added).getTime()
	let elapsedTimeArray = []
	for (let entity of props.filteredHistory) {
		elapsedTimeArray.push(new Date(entity.date_added).getTime() - offset)
	}

	let percent = Math.floor(elapsedTimeArray[elapsedTimeArray.length - 1] / 100)

	let widthArray: number[] = []
	for (let time of elapsedTimeArray) {
		widthArray.push((100 - Math.floor(time / percent)) * 7)
	}

	const columnas = props.filteredHistory.map((e: any, i: number) => {
		return {
			field: formatDate(new Date(e.date_added)),
			resizable: true,
			headerName: formatDate(new Date(e.date_added)),
			width: widthArray[i],
		}
	})
	let row: any = { id: 1 }
	columnas.forEach((e: any) => {
		row[e.field] = 1
	})
	const rows = [row]

	return (
		<DataGrid
			style={{ height: '100px' }}
			columns={columnas}
			rows={rows}
			disableColumnFilter
			disableColumnMenu
		></DataGrid>
	)
}
