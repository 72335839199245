import {
	Dialog,
	DialogActions,
	DialogContent,
	DialogContentText,
	DialogTitle,
} from '@mui/material'
import { SubmitButton } from '../../pages/components/Buttons'

export const UserMessagePrompt = ({
	PopUpVisible,
	setPopUpVisible,
	userMessage,
	setUserMessage,
}: {
	PopUpVisible: boolean
	setPopUpVisible: any
	userMessage: any
	setUserMessage: any
}) => {
	return (
		<Dialog open={PopUpVisible}>
			<DialogTitle>{userMessage?.title}</DialogTitle>
			<DialogContent>
				<DialogContentText style={{ whiteSpace: 'pre-line' }}>
					{userMessage?.text || ''}
				</DialogContentText>
			</DialogContent>
			<DialogActions>
				<SubmitButton
					style={{ margin: 'auto' }}
					onClick={() => {
						setUserMessage(null)
						setPopUpVisible(false)
					}}
				>
					Close
				</SubmitButton>
			</DialogActions>
		</Dialog>
	)
}
