import { styled } from 'styled-components'
import theme from '../../utils/theme2024'
import { FormControlLabel, Switch } from '@mui/material'
import { Dispatch, SetStateAction } from 'react'
import { StyledLabel } from './Informative'

export const StyledCheckboxFormControl = styled(FormControlLabel)(() => ({
	fontSize: '13px',
	maxWidth: '280px',
	height: '200px',
	fontWeight: 600,
}))

export const SwitchComponent = (props: {
	onChange: Dispatch<SetStateAction<boolean>>
	value: boolean
	label: string
	disabled: boolean
	style?: React.CSSProperties
}) => {
	const { onChange, value, label, disabled } = props
	return (
		<div
			style={{
				display: 'flex',
				flexDirection: 'column',
				alignItems: 'flex-start',
				...props.style,
			}}
		>
			<StyledLabel style={{ left: '2px' }}>{label}</StyledLabel>

			<Switch
				onChange={(e: any) => {
					onChange(!value)
				}}
				checked={value}
				key={'checkbox' + label}
				style={{
					marginLeft: '0px',
					height: 'auto',
				}}
				disabled={disabled}
			/>
		</div>
	)
}
