import React, {
	ChangeEvent,
	Dispatch,
	SetStateAction,
	useEffect,
	useState,
} from 'react'
import DocViewer, { DocViewerRenderers } from '@cyntler/react-doc-viewer'
import CloseIcon from '@mui/icons-material/Close'
import theme from '../../utils/theme2024'
import { PageWrapper, TableWrapper } from '../components/reusableComponents'
import { ThemeProvider } from '@emotion/react'
import { generateRowsWithIds, searchFor } from '../../utils/helpers/tableHelper'
import { GridColDef } from '@mui/x-data-grid-pro'
import { MenuItem, Typography } from '@mui/material'
import { LearningAdd } from './LearningAdd'
import { useActions } from '../../hooks/useActions'
import { useTypedSelector } from '../../hooks/useTypedSelector'
import { useNavigate, useParams } from 'react-router-dom'
import { getApplicableRules } from '../../utils/helpers/helperFuncs'
import FilterTabs from '../components/Filters'
import {
	SubmitButton,
	ActionsButton,
	UnborderedButton,
} from '../components/Buttons'
import { TableComponent } from '../components/TableComponents'
import { ReactComponent as EditIcon } from '../../assets/svg/edit-icon.svg'
import { ReactComponent as DeleteIcon } from '../../assets/svg/delete-icon.svg'
import { ReactComponent as DownloadIcon } from '../../assets/svg/download-icon.svg'
import { ReactComponent as PreviewIcon } from '../../assets/svg/preview-icon.svg'

const LearningComponent = (props: {
	setLoading: any
	errorMessage: any
	setErrorMessage: any
	isErrorPromptOpened: any
	setIsErrorPromptOpened: any
	setIsWarningPromptOpened: any
	PopUpVisible: boolean
	setPopUpVisible: any
	setIsSuccessPromptOpened: any
	isSuccessPromptOpened: any
	search: string
	setSearch: Dispatch<SetStateAction<string>>
	presetSearch: string
	setPresetSearch: Dispatch<SetStateAction<string>>
}) => {
	const rowCount = 5
	const params = useParams()
	const navigate = useNavigate()
	const { getLearningAction, removeRow } = useActions()
	const { learning, login, users, settings } = useTypedSelector(
		(state) => state,
	)
	const [pageSize, setPageSize] = useState(15)
	const [found, setFound] = useState<any[]>([])
	const [row, setRow] = useState()
	const [doc, setDoc] = useState<any>([])
	const [sortModel, setSortModel] = useState([
		{ field: 'file_title', sort: 'asc' },
	])
	const [rowWidth, setRowWidth] = useState(
		Math.floor((window.innerWidth - theme.dimentions.drawerWidth) / rowCount),
	)

	const docCategories = [
		'Company Details',
		'Company Licenses',
		'HR',
		'Legal',
		'Operations',
		'Sales',
		'Varys',
		'Other',
	]

	const getDocsWithCategories = () => {
		let learnings = [...learning.learning]
		const learningsWithCategories = learnings.map((doc: any) => {
			const randomIndex = Math.floor(Math.random() * docCategories.length)
			doc.category = doc.category || docCategories[randomIndex]
			return doc
		})
		return learningsWithCategories
	}
	const [learnings, setItems] = useState(getDocsWithCategories())
	const [filteredLearning, setFilteredLearnings] = useState(
		getDocsWithCategories(),
	)
	const [filterKey, setFilterKey] = useState<string>('category')

	const headers = {
		Authorization: `Token ${login.user.token}`,
	}

	const ruleSet = getApplicableRules(users, login, settings, 'learning')

	useEffect(() => {
		loadCurrentData()
		props.setLoading(false)
	}, [])
	useEffect(() => {
		if (learning.learning.length > 0) {
			setFilteredLearnings(getDocsWithCategories())
			setItems(getDocsWithCategories())
		}
	}, [learning.learning])
	useEffect(() => {
		if (!props.PopUpVisible && params.id && learning.learning.length > 0) {
			const rowToCheck = learning.learning.filter((el: any) => {
				return Number(el.id) === Number(params.id)
			})
			handleEdit(rowToCheck[0])
		}
	}, [filteredLearning])

	const columns: GridColDef[] = [
		{
			field: 'file_title',
			headerName: 'Name',
			width: rowWidth * 1,
			renderCell: ({ value }) => (
				<Typography sx={{ fontWeight: 600, fontSize: 14 }}>{value}</Typography>
			),
		},
		// {
		// 	field: 'file_open_link',
		// 	headerName: 'Open',
		// 	width: rowWidth * 0.4,
		// 	renderCell: ({ value }) => {
		// 		return (
		// 			value && (
		// 				<SubmitButton
		// 					onClick={() => {
		// 						setDoc([{ uri: value }])
		// 					}}
		// 					style={{ width: '60px', height: '26px' }}
		// 				>
		// 					<FileOpenIcon fontSize={'small'}></FileOpenIcon>
		// 				</SubmitButton>
		// 			)
		// 		)
		// 	},
		// },
		// {
		// 	field: 'file_link',
		// 	headerName: 'Drive link',
		// 	width: rowWidth * 0.5,
		// 	renderCell: ({ value }) => {
		// 		return (
		// 			<Typography sx={{ fontWeight: 600, fontSize: 14 }}>
		// 				<a href={value} rel='noreferrer noopener' target='_blank'>
		// 					Link
		// 				</a>
		// 			</Typography>
		// 		)
		// 	},
		// },
		{
			field: 'file_name',
			headerName: 'File name',
			width: rowWidth * 1.8,
			renderCell: (row) => {
				const value = row.value
				const openLink = row.row.file_open_link
				return (
					<>
						<Typography sx={{ fontWeight: 600, fontSize: 14 }}>
							{value || 'No file'}
						</Typography>
						{openLink && (
							<UnborderedButton
								onClick={() => {
									setDoc([{ uri: openLink }])
								}}
								style={{ width: '60px', height: '26px' }}
								icon={
									<PreviewIcon
										style={{ color: theme.colors.text.titles, scale: '0.8' }}
										className={'action-button'}
									/>
								}
							></UnborderedButton>
						)}
					</>
				)
			},
		},
		{
			field: 'category',
			headerName: 'Category',
			width: rowWidth * 1,
			renderCell: ({ value }) => (
				<Typography sx={{ fontWeight: 600, fontSize: 14 }}>
					{value || 'No category'}
				</Typography>
			),
		},
		ruleSet.get && {
			field: 'actions',
			headerName: 'Actions',
			resizable: false,
			headerAlign: 'center',
			align: 'center',
			// width: rowWidth,
			sortable: false,
			renderCell: (row) => {
				return (
					<ActionsButton
						// label={'Actions'}
						// width={rowWidth}
						// row={row.row}
						options={actionOptions(row.row)}
						// style={{ width: '40px', height: '22px', fontSize: '30px' }}
						// arrowColor={'rgba(0,0,0,0.75)'}
					/>
				)
			},
		},
	]
	const actionOptions = (row: any) => {
		return [
			{
				label: '',
				icon: (
					<DownloadIcon
						style={{ color: theme.colors.text.titles, scale: '6.4' }}
						className={'action-button'}
					/>
				),
				onClick: () => {
					handleDownloadFile(row)
				},
				key: 'download',
				rule: ruleSet.get,
			},
			{
				label: '',
				icon: (
					<EditIcon
						style={{ color: theme.colors.text.titles, scale: '6.4' }}
						className={'action-button'}
					/>
				),
				onClick: () => {
					handleEdit(row)
				},
				key: 'edit',
				rule: ruleSet.post,
			},
			{
				label: '',
				icon: (
					<DeleteIcon
						style={{ color: theme.colors.text.titles, scale: '6.4' }}
						className={'action-button'}
					/>
				),
				onClick: () => {
					handleRemove(row)
				},
				key: 'delete',
				rule: ruleSet.delete,
			},
		]
	}
	const handleDownloadFile = (rowToDownload: any) => {
		window.open(rowToDownload.file_open_link)
	}
	const handleEdit = (rowToEdit: any) => {
		setRow(rowToEdit)
		navigate('/documents/' + rowToEdit.id)
		props.setPopUpVisible(true)
	}
	const handleRemove = (rowToRemove: any) => {
		removeRow(
			headers,
			rowToRemove,
			props.setErrorMessage,
			props.setIsSuccessPromptOpened,
		)
	}
	const loadCurrentData = async () => {
		getLearningAction(headers)
	}
	useEffect(() => {
		if (props.search.length > 0) {
			setFound(searchFor(props.search, filteredLearning, columns))
		}
	}, [props.search, filteredLearning])

	return !props.PopUpVisible ? (
		<ThemeProvider theme={theme}>
			{ruleSet.get && (
				<PageWrapper>
					{doc.length > 0 && (
						<div
							key={'testdocviewer'}
							style={{
								display: 'flex',
								position: 'absolute',
								zIndex: '20',
								top: '10%',
								left: '2%',
								width: '95%',
								height: '80%',
								border: `2px solid ${theme.colors.base.grey300}`,
							}}
						>
							<SubmitButton
								style={{
									display: 'flex',
									position: 'absolute',
									zIndex: '10',
									right: '0%',
									top: '0%',
									width: '20px',
									height: '24px',
								}}
								onClick={() => setDoc([])}
							>
								<CloseIcon fontSize={'small'}></CloseIcon>
							</SubmitButton>
							<DocViewer
								documents={doc}
								pluginRenderers={DocViewerRenderers}
							></DocViewer>
						</div>
					)}
					<TableWrapper
						theme={theme}
						rowCount={rowCount}
						setRowWidth={setRowWidth}
						style={{ paddingBottom: '60px', width: '90%' }}
					>
						<FilterTabs
							filterKey={filterKey}
							items={learnings}
							setFilteredItems={setFilteredLearnings}
						/>
						{
							<TableComponent
								columns={columns}
								rows={generateRowsWithIds(
									props.search.length > 0 ? found : filteredLearning,
								)}
								setPageSize={setPageSize}
								pageSize={pageSize}
								rowsPerPageOptions={[15, 25, 50]}
								setLoading={props.setLoading}
								handlePopUp={
									ruleSet.post
										? () => {
												setRow(undefined)
												props.setPopUpVisible(true)
										  }
										: undefined
								}
								sortModel={sortModel}
								onSortModelChange={(model: any) => setSortModel(model)}
								// downloadButtonOptions={downloadButtonoptions}
							></TableComponent>
						}
					</TableWrapper>
				</PageWrapper>
			)}
		</ThemeProvider>
	) : (
		<LearningAdd
			row={row}
			PopUpVisible={props.PopUpVisible}
			setPopUpVisible={props.setPopUpVisible}
			setLoading={props.setLoading}
			errorMessage={props.errorMessage}
			setErrorMessage={props.setErrorMessage}
			isErrorPromptOpened={props.isErrorPromptOpened}
			setIsErrorPromptOpened={props.setIsErrorPromptOpened}
			setIsSuccessPromptOpened={props.setIsSuccessPromptOpened}
			isSuccessPromptOpened={props.isSuccessPromptOpened}
			categoryOptions={docCategories}
		></LearningAdd>
	)
}

export default LearningComponent
