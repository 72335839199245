import { ThemeProvider } from '@emotion/react'
import '@inovua/reactdatagrid-community/index.css'
import ReportIcon from '@mui/icons-material/Report'
import { Tooltip, Typography } from '@mui/material'
import { DataGridPro, GridColDef } from '@mui/x-data-grid-pro'
import { useEffect, useState } from 'react'
import { useTypedSelector } from '../../hooks/useTypedSelector'
import {
	capitalizeFirstLetter,
	generateRowsWithIds,
	sortFieldNames,
} from '../../utils/helpers/tableHelper'
import theme from '../../utils/theme2024'
import { PageWrapper, TableWrapper } from '../components/reusableComponents'
export const AdjustReportsTable = (props: {
	report: any
	setIsReportOpen: any
	reportFields: any
	isReportOpen: boolean
	customReportFields: any
}) => {
	let fieldNames: any[] = []
	const { users } = useTypedSelector((state) => state)
	if (props.report !== null) {
		fieldNames = props.report.length === 0 ? [] : Object.keys(props.report[0])
		if (fieldNames.length > 0) {
			props.reportFields.statistics.forEach((v: string) => {
				if (
					!fieldNames.includes(v)
					// !(
					// 	(props.reportFields.filters.campaign_id &&
					// 		props.reportFields.filters.campaign_id.length === 1) ||
					// 	(props.reportFields.filters.campaign_field &&
					// 		props.reportFields.filters.campaign_field.length === 1)
					// )
				) {
					fieldNames.push(v)
				}
			})
		}
	}

	const sortedfieldNames: string[] = sortFieldNames(fieldNames)
	const [pinnedRow, setPinnedRow] = useState({})

	const clearFieldNamesForTable = (allFieldNamesFromDB: string[]) => {
		let result: any = []
		// if (
		// 	props.customReportFields.statistics.length !==
		// 		props.reportFields.statistics.length ||
		// 	props.customReportFields.breakdowns.length !==
		// 		props.reportFields.breakdowns.length
		// ) {
		// 	if (!props.reportFields.breakdowns.includes('date')) {
		// 		result = allFieldNamesFromDB.filter((el) => el !== 'date')
		// 	}
		// 	if (!props.reportFields.statistics.includes('revenue')) {
		// 		result =
		// 			result.length === 0
		// 				? allFieldNamesFromDB.filter((el) => el !== 'revenue')
		// 				: result.filter((el: any) => el !== 'revenue')
		// 	}
		// 	if (!props.reportFields.statistics.includes('cost')) {
		// 		result =
		// 			result.length === 0
		// 				? allFieldNamesFromDB.filter((el) => el !== 'profit')
		// 				: result.filter((el: any) => el !== 'profit')
		// 	}
		// 	if (!props.reportFields.statistics.includes('profit')) {
		// 		result =
		// 			result.length === 0
		// 				? allFieldNamesFromDB.filter((el) => el !== 'cost')
		// 				: result.filter((el: any) => el !== 'cost')
		// 	}
		// } else {
		// 	result = allFieldNamesFromDB
		// }
		return allFieldNamesFromDB
	}
	// const confirmedSortedfieldNames = clearFieldNamesForTable(sortedfieldNames)

	const rowCount = sortedfieldNames.length

	const [rowWidth, setRowWidth] = useState(
		Math.floor((window.innerWidth - theme.dimentions.drawerWidth) / rowCount),
	)
	const columns: GridColDef[] = sortedfieldNames.map(
		(e: any, index: number): any => {
			let name = e
				.split('_')
				.map((a: string) => capitalizeFirstLetter(a))
				.join(' ')
			if (name.length < 4) {
				name = name.toUpperCase()
			} else {
				name = capitalizeFirstLetter(name)
			}
			if (name.toLowerCase() === 'cr') {
				return {
					field: e,
					headerName: name,
					width: rowWidth,

					renderCell: ({ value }: { value: any }) =>
						value === undefined ? (
							<>{<ReportIcon style={{ fill: 'red' }} />}</>
						) : (
							Number(value * 100).toLocaleString() + ' %'
						),
				}
			}
			if (
				name.toLowerCase() === 'impressions' ||
				name.toLowerCase() === 'installs' ||
				name.toLowerCase() === 'clicks'
			) {
				return {
					field: e,
					headerName: name,
					width: rowWidth,
					align: 'left',
					headerAlign: 'left',
					type: 'number',
					valueFormatter: ({ value }: { value: any }) =>
						new Intl.NumberFormat().format(value),
				}
			}

			return {
				field: e,
				headerName: name,
				width: rowWidth,

				renderCell: ({ value }: { value: any }) =>
					!isNaN(value) ? (
						<Tooltip title={value}>
							<Typography fontSize={12}>
								{Number(value).toLocaleString()}
							</Typography>
						</Tooltip>
					) : (
						<Tooltip title={value}>
							<Typography fontSize={12}>{value}</Typography>
						</Tooltip>
					),
			}
		},
	)

	const pageSize = 20

	useEffect(() => {
		setRowWidth(
			Math.floor((window.innerWidth - theme.dimentions.drawerWidth) / rowCount),
		)
	}, [rowCount])

	useEffect(() => {
		if (props.report) {
			const sumRow = props.report.slice(props.report.length - 1)
			sumRow[0].id = props.report.length - 1
			const pinnedSumRow = { bottom: sumRow }
			setPinnedRow(pinnedSumRow)
		}
	}, [props.report])

	return (
		<ThemeProvider theme={theme}>
			<PageWrapper style={{ zIndex: 0 }}>
				<TableWrapper
					style={{
						margin: '-1rem',
						marginTop: '60px',
						width: '90%',
						paddingBottom: '60px',
					}}
					theme={theme}
					rowCount={rowCount}
					setRowWidth={setRowWidth}
				>
					<DataGridPro
						style={{
							fontSize: '0.75rem',
						}}
						columns={columns}
						rowHeight={23}
						rows={generateRowsWithIds(props.report)}
						pageSizeOptions={[15, 25, 50]}
						showColumnVerticalBorder={true}
						pinnedRows={pinnedRow}
					></DataGridPro>
				</TableWrapper>
			</PageWrapper>
		</ThemeProvider>
	)
}
