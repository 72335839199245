import React, { CSSProperties, useState } from 'react'
import theme from '../../utils/theme2024'
import { Grid, InputLabel, Menu, Tooltip, styled } from '@mui/material'
import ErrorOutlineOutlinedIcon from '@mui/icons-material/ErrorOutlineOutlined'
import { SubmitButton, UnborderedButton } from './Buttons'
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown'
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp'
import ReactCountryFlag from 'react-country-flag'
import { Public } from '@mui/icons-material'

export const StatusTag = ({
	label,
	style,
}: {
	label: string
	style?: React.CSSProperties
}) => {
	return (
		<span
			style={{
				color: theme.colors.text.grey,
				fontSize: theme.font.size.buttons,
				fontWeight: theme.font.weight.skinny,
				...style,
			}}
		>
			{label}
		</span>
	)
}
export const InfoTag = (props: { title: string }) => {
	return (
		<div
			style={{
				position: 'relative',
				width: '20%',
			}}
		>
			<Tooltip title={props.title}>
				<span
					style={{
						color: theme.colors.base.grey900,
						top: '-0.2vh',
						display: 'flex',
						position: 'absolute',
						zIndex: 2,
						height: '15px',
						width: '15px',
						border: '2px solid ' + theme.colors.base.grey900,
						borderRadius: '500px',
						alignItems: 'center',
						textAlign: 'center',
						justifyContent: 'center',
						padding: '0px',
						fontSize: '15px',
						fontWeight: '500',
					}}
				>
					i
				</span>
			</Tooltip>
		</div>
	)
}
export const ErrorTag = (props: { title: string; color?: string }) => {
	return (
		<div
			style={{
				position: 'relative',
				width: '20%',
			}}
		>
			<Tooltip title={props.title}>
				{/* <ErrorOutlineOutlinedIcon
					style={{
						color: theme.colors.base.red300,
						top: '-1.1vh',
						display: 'flex',
						position: 'absolute',
						zIndex: 2,
						paddingTop: '7px',
						scale: '0.8',
					}}
				/> */}
				<span
					style={{
						color: theme.colors.base.red300,
						top: '-0.2vh',
						display: 'flex',
						position: 'absolute',
						zIndex: 2,
						height: '12px',
						width: '12px',
						border: '2px solid ' + theme.colors.base.red300,
						borderRadius: '500px',
						alignItems: 'center',
						textAlign: 'center',
						justifyContent: 'center',
						padding: '0px',
						fontSize: '12px',
						fontWeight: '500',
					}}
				>
					!
				</span>
			</Tooltip>
		</div>
	)
}

export const StyledLabel = styled(InputLabel)<any>(
	({ isFocused, hasError, style }) => {
		return {
			color:
				isFocused && !hasError
					? theme.colors.base.green400
					: theme.colors.base.grey600,
			fontWeight: theme.font.weight.normal,
			left: '0.7rem',
			top: '-0.2rem',
			marginTop: '0.2rem',
			fontSize: theme.font.size.caption,
			width: 'auto',
			minHeight: '18px',
			...style,
		}
	},
)
export const TitleComponent = styled('span')<any>(({ style }) => {
	return {
		color: theme.colors.base.grey600,
		fontSize: theme.font.size.titles,
		fontWeight: theme.font.weight.bold,
		...style,
	}
})

export const TitleTag = (props: {
	children: React.ReactNode
	style?: CSSProperties
}) => {
	return (
		<div
			style={{
				position: 'relative',
				margin: '10px',
				// width: '20%',
				// background: theme.colors.base.green200,
				// border: '2px solid ' + theme.colors.base.green200,
				borderRadius: '18px',
				textTransform: 'none',
				fontSize: theme.font.size.titles,
				color: theme.colors.text.titles,
				padding: '6px 0px 0px 0px',
				fontWeight: theme.font.weight.mediumBold,
				...props.style,
			}}
		>
			<span>{props.children}</span>
		</div>
	)
}
export const StatusTitleTag = (props: {
	status: boolean
	style?: CSSProperties
}) => {
	return (
		<div
			style={{
				position: 'relative',
				margin: '10px',
				// width: '20%',
				background: theme.colors.base.white,
				// border: '2px solid ' + theme.colors.base.green200,
				borderRadius: '18px',
				textTransform: 'none',
				width: '7rem',
				fontSize: theme.font.size.buttons,
				color: theme.colors.text.titles,
				padding: '14px 20px 14px 20px',
				textAlign: 'center',
				...props.style,
			}}
		>
			<span>
				Status:{' '}
				<span
					style={{
						marginLeft: '4px',
						color: props.status
							? theme.colors.status.green
							: theme.colors.status.grey,
					}}
				>
					{props.status ? 'Active' : 'Disabled'}
				</span>
			</span>
		</div>
	)
}

export const StyledMenuItem = styled(Menu)(() => ({
	paddingTop: '0px',
	'& 	.MuiMenu-list': {
		minWidth: '185px',
		padding: '0px',
		border: '1px solid ' + theme.colors.base.grey300,
	},
}))

export const ExpandableInfoField = (props: {
	label: string
	value: string | string[]
}) => {
	const [expanded, setExpanded] = useState(false)
	return (
		<Grid
			item
			xs={11}
			container
			columns={12}
			direction={'row'}
			columnSpacing={0}
			rowSpacing={6}
			style={{ marginLeft: 8, gap: 20 }}
		>
			<Grid item xs={12}>
				<SubmitButton
					style={{
						display: 'flex',
						justifyContent: 'center',
						alignItems: 'center',
						// width: 240,
						marginLeft: -8,
					}}
					onClick={() => setExpanded(!expanded)}
				>
					{props.label}
					{expanded ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
				</SubmitButton>
			</Grid>
			{expanded && props.value.length > 0 && (
				<Grid
					item
					xs={11.9}
					container
					columns={12}
					columnSpacing={0.5}
					rowSpacing={0.5}
					style={{
						border: '1px solid ' + theme.colors.base.grey300,
						borderRadius: '20px',
						padding: '20px',
					}}
				>
					{(props.value as string[]).map((element: string) => {
						return (
							<Grid
								item
								xs={element.length < 16 ? 1 : 2}
								style={{ display: 'flex' }}
							>
								<span
									style={{
										padding: '6px',
										border: '2px solid ' + theme.colors.base.green200,
										// color: theme.colors.base.white,
										borderRadius: '10px',
										fontSize: theme.font.size.body,
										width: '100%',
										// gridColumnStart: element.length < 16 ? 'span 1' : 'span 2',
										whiteSpace: 'nowrap',
										textAlign: 'center',
										fontWeight: theme.font.weight.skinny,
									}}
								>
									{element}
								</span>
							</Grid>
						)
					})}
				</Grid>
			)}
		</Grid>
	)
}

export const ColoredTag = (props: { value: string; checked: boolean }) => {
	const { value, checked } = props
	return (
		<span
			style={{
				color: checked ? theme.colors.base.white : theme.colors.base.grey900,
				background: checked
					? theme.colors.status.green
					: theme.colors.base.grey200,
				padding: '6px 12px',
				borderRadius: '10px',
				whiteSpace: 'nowrap',
				textAlign: 'center',
				fontSize: theme.font.size.caption,
			}}
			key={value}
		>
			{value}
		</span>
	)
}

export const ContactField = (props: { contact: any }) => {
	return (
		<div
			style={{
				display: 'flex',
				flexDirection: 'row',
				fontWeight: theme.font.weight.bold,
				padding: '4px 8px 4px 50px',
				fontSize: theme.font.size.caption,
				justifyContent: 'flex-start',
				borderRadius: '12px',
				background: theme.colors.base.grey100,
			}}
		>
			<div
				style={{
					whiteSpace: 'nowrap',
					display: 'inherit',
					gap: '6px',
					width: '14%',
					fontSize: theme.font.size.caption,
				}}
			>
				<span style={{ color: 'rgba(128,128,128, 0.8)' }}>Name: </span>
				<span>{props.contact.name}</span>
			</div>
			<div
				style={{
					whiteSpace: 'nowrap',
					display: 'inherit',
					gap: '6px',
					width: '25%',
				}}
			>
				<span style={{ color: 'rgba(128,128,128, 0.8)' }}>Email: </span>
				<span>{props.contact.email}</span>
			</div>
			<div
				style={{
					whiteSpace: 'nowrap',
					display: 'inherit',
					gap: '6px',
					width: '18%',
				}}
			>
				<span style={{ color: 'rgba(128,128,128, 0.8)' }}>Role: </span>
				<span>{props.contact.role}</span>
			</div>
			<div
				style={{
					whiteSpace: 'nowrap',
					display: 'inherit',
					gap: '6px',
					width: '25%',
				}}
			>
				<span style={{ color: 'rgba(128,128,128, 0.8)' }}>Skype: </span>
				<span>{props.contact.skype}</span>
			</div>
			<div
				style={{
					display: 'flex',
					fontWeight: '500',
					whiteSpace: 'nowrap',
					alignItems: 'center',
					justifyContent: 'flex-end',
					width: '18%',
				}}
			>
				<div
					style={{
						display: 'inherit',
						alignItems: 'flex-end',
						width: '80%',
						gap: '6px',
					}}
				>
					<span style={{ color: 'rgba(128,128,128, 0.8)' }}>Geo: </span>
					<span
						style={{
							display: 'flex',
							gap: '6px',
							fontWeight: '500',
							whiteSpace: 'nowrap',
							alignItems: 'center',
						}}
					>
						{props?.contact?.geo.length > 0 ? (
							<ReactCountryFlag
								style={{
									height: '10px',
									width: '14px',
									borderRadius: '100px',
									border: `1px solid ${theme.colors.black}`,
								}}
								key={'flags'}
								className='roundedFlag'
								countryCode={
									props.contact?.geo[0] === 'UK'
										? 'GB'
										: (props.contact?.geo[0] as string)
								}
								svg
							/>
						) : (
							<Public style={{ width: '16px', height: '14px' }} />
						)}
						{props.contact?.geo.length > 0 ? props.contact?.geo[0] : 'GLOBAL'}{' '}
					</span>
				</div>
			</div>
		</div>
	)
}
