import { ThemeProvider } from '@emotion/react'
import '@inovua/reactdatagrid-community/index.css'
import VisibilityIcon from '@mui/icons-material/Visibility'
import { Grid, MenuItem, Typography } from '@mui/material'
import { GridColDef } from '@mui/x-data-grid-pro'
import saveAs from 'file-saver'
import React, { Dispatch, SetStateAction, useEffect, useState } from 'react'
import { useActions } from '../../hooks/useActions'
import { useTypedSelector } from '../../hooks/useTypedSelector'
import { GetPasswordsInterface } from '../../models/model.interface'
import { fetchData } from '../../utils/helpers/navigationHelper'
import { generateRowsWithIds, searchFor } from '../../utils/helpers/tableHelper'
import { WarningPrompt } from '../../utils/notifications/WarningPrompt'
import LinkIcon from '@mui/icons-material/Link'
import theme from '../../utils/theme2024'
import { PageWrapper, TableWrapper } from '../components/reusableComponents'
import PasswordsEdit from './PasswordsAdd'
import PasswordsPopUp from './PasswordsShow'
import { getApplicableRules } from '../../utils/helpers/helperFuncs'
import { TableComponent } from '../components/TableComponents'
import { SimpleActionsButton, ActionsButton } from '../components/Buttons'
import { ReactComponent as EditIcon } from '../../assets/svg/edit-icon.svg'
import { ReactComponent as DeleteIcon } from '../../assets/svg/delete-icon.svg'

const PasswordsPage = (props: {
	children?: any
	setLoading?: any
	errorMessage: null | string
	setErrorMessage: any
	isErrorPromptOpened: boolean
	setIsErrorPromptOpened: any
	isSuccessPromptOpened: any
	setIsSuccessPromptOpened: any
	isEditorOpened?: any
	isAddEditorOpened?: any
	PopUpVisible: boolean
	setPopUpVisible: any
	search: string
	setSearch: Dispatch<SetStateAction<string>>
	presetSearch: string
	setPresetSearch: Dispatch<SetStateAction<string>>
}) => {
	const rowCount = 10
	const { login, presearch, passwords, users, settings } = useTypedSelector(
		(state) => state,
	)
	const ruleSet = getApplicableRules(users, login, settings, 'passwords')
	const {
		presearchAction,
		getPasswordsAction,
		showPasswordAction,
		deletePasswordsAction,
	} = useActions()
	const sessionKey = 'filters--passwords-table'
	const sessionSerializedFilters = sessionStorage.getItem(sessionKey)
	let sessionFilters: any = {}
	if (sessionSerializedFilters && sessionSerializedFilters.length > 4) {
		sessionFilters = JSON.parse(sessionSerializedFilters)
	}
	const [pageSize, setPageSize] = useState(15)
	// const [PopUpVisible, setPopUpVisible] = useState(props.isEditorOpened)
	const [AddVisible, setAddVisible] = useState(props.isAddEditorOpened)
	const [row, setRow] = useState<GetPasswordsInterface | undefined>(undefined)
	const [found, setFound] = useState<any[]>(
		// searchFor(props.search, passwords.passwords),
		[],
	)
	const [blockPasswords, setBlockPasswords] = useState(
		typeof sessionFilters.blockPasswords === 'boolean'
			? sessionFilters.blockPasswords
			: true,
	)
	const [codeHash, setCodeHash] = useState('')
	const [update, setUpdate] = useState(false)
	const [isWarningPromptOpened, setIsWarningPromptOpened] = useState(false)
	const [rowWidth, setRowWidth] = useState(
		Math.floor(window.innerWidth - theme.dimentions.drawerWidth) / rowCount,
	)
	const [isAdding, setIsAdding] = useState(false)
	const [sortModel, setSortModel] = useState([
		{ field: 'type', sort: 'asc' },
		{ field: 'name', sort: 'asc' },
	])

	const hasPermission = (permissionType: string, ownerEmail: string) => {
		const isOwner = login.user.email === ownerEmail
		switch (permissionType) {
			case 'edit':
				return ruleSet.post || isOwner
			case 'delete':
				return ruleSet.delete || isOwner
			default:
				return ruleSet.post || ruleSet.delete || isOwner
		}
	}

	const [fileDownload, setFileDownload] = useState()
	const headers = {
		Authorization: `Token ${login.user.token}`,
	}
	const [pwdChange, setPwdChange] = useState('')
	const actionOptions = (row: any) => {
		const canEdit = hasPermission('edit', row.row.created_by)
		const canDelete = hasPermission('delete', row.row.created_by)
		return [
			{
				label: '',
				icon: (
					<EditIcon
						style={{ color: theme.colors.text.titles, scale: '6.4' }}
						className={'action-button'}
					/>
				),
				onClick: () => canEdit && handleEdit(row),
				rule: ruleSet.post,
				key: 'edit',
			},
			{
				label: '',
				icon: (
					<DeleteIcon
						style={{ color: theme.colors.text.titles, scale: '6.4' }}
						className={'action-button'}
					/>
				),
				onClick: () => {
					setIsWarningPromptOpened(true)
					canDelete && setRow(row.row)
				},
				rule: ruleSet.delete,
				key: 'delete',
			},
		]
	}
	const columnsNew: GridColDef[] = [
		{
			field: 'name',
			headerName: 'Name',

			renderCell: ({ value }: { value?: any }) => (
				<Typography sx={{ fontWeight: 500, fontSize: 14 }}>{value}</Typography>
			),
			width: rowWidth * 1.4,
		},
		{
			field: 'internal_poc',
			renderCell: ({ value }: { value?: any }) => (
				<Typography sx={{ fontSize: 14 }}>
					{value.split('@thing02.com')}
				</Typography>
			),
			headerName: 'Owner',
			width: rowWidth * 0.8,
		},
		{
			field: 'url',
			renderCell: ({ value }: { value?: any }) => (
				<div
					style={{
						display: 'flex',
						flexDirection: 'row',
						justifyContent: 'center',
						alignItems: 'center',
					}}
				>
					<button
						style={{
							border: 'none',
							cursor: 'pointer',
							display: 'flex',
						}}
						onClick={() => {
							const newTab: any = window.open(value, '_blank')
							newTab.focus()
						}}
					>
						<LinkIcon></LinkIcon>
					</button>
					<Typography sx={{ fontSize: 14 }}>{value}</Typography>
				</div>
			),
			headerName: 'Url',
			width: rowWidth * 1.7,
		},
		{
			field: 'user_name',

			headerName: 'User Name',
			width: rowWidth,
		},
		{
			field: 'password',

			headerName: 'Password',
			width: rowWidth * 1.5,

			renderCell: ({ value, row }: { value?: any; row?: any }) => {
				return renderPassword(blockPasswords, login, value, row)
			},
		},
		{
			field: 'access',

			headerName: 'Access',
			width: rowWidth * 0.7,
		},
		{
			field: 'type',

			headerName: 'Type',
			width: rowWidth * 0.6,
		},
		{
			field: 'notes',
			headerName: 'Notes',

			width: rowWidth * 0.6,
		},
		{
			field: 'actions',

			headerName: 'Actions',

			// width: rowWidth,
			sortable: false,
			renderCell: (row: any) => (
				<ActionsButton
					// width={rowWidth}
					// row={row}
					// label={'Actions'}
					options={actionOptions(row)}
					// style={{ width: '40px', height: '22px', fontSize: '30px' }}
					// arrowColor={'rgba(0,0,0,0.75)'}
				/>
			),
		},
	]

	const handlePopUp = () => {
		if (!props.PopUpVisible && !AddVisible) {
			fetchData(login, getPasswordsAction, passwords.passwords)
			//setRow(undefined)
		}
		if (!AddVisible) {
			props.setPopUpVisible(!props.PopUpVisible)
		}
		if (!props.PopUpVisible) {
			setAddVisible(!AddVisible)
		}
	}
	const showPopUps = () => {
		if (props.PopUpVisible) {
			return (
				<PasswordsPopUp
					advertisers={undefined}
					PopUpVisible={props.PopUpVisible}
					setPopUpVisible={handlePopUp}
					row={row}
					codeHash={codeHash}
					setLoading={props.setLoading}
					setBlockPasswords={setBlockPasswords}
					errorMessage={props.errorMessage}
					setErrorMessage={props.setErrorMessage}
					isErrorPromptOpened={props.isErrorPromptOpened}
					setIsErrorPromptOpened={props.setIsErrorPromptOpened}
					isSuccessPromptOpened={props.isSuccessPromptOpened}
					setIsSuccessPromptOpened={props.setIsSuccessPromptOpened}
					sessionKey={sessionKey}
					search={props.search}
				/>
			)
		}
		if (AddVisible) {
			return (
				<PasswordsEdit
					advertisers={undefined}
					PopUpVisible={AddVisible}
					setPopUpVisible={handlePopUp}
					row={row}
					setLoading={props.setLoading}
					blockPasswords={blockPasswords}
					isAdding={isAdding}
					setIsAdding={setIsAdding}
					setRow={setRow}
					errorMessage={props.errorMessage}
					setErrorMessage={props.setErrorMessage}
					isErrorPromptOpened={props.isErrorPromptOpened}
					setIsErrorPromptOpened={props.setIsErrorPromptOpened}
					isSuccessPromptOpened={props.isSuccessPromptOpened}
					setIsSuccessPromptOpened={props.setIsSuccessPromptOpened}
					ruleSet={ruleSet}
				/>
			)
		}
		return null
	}
	const popUp = showPopUps()
	const handleEdit = (row: any) => {
		setRow(row.row)
		setIsAdding(false)
		setAddVisible(!AddVisible)
	}
	const createPayload = (input?: any) => {
		if (row) {
			return {
				id: row.id,
				field: row.name,
				internal_poc: row.internal_poc,
				url: row.url,
				user_field: row.user_name,
				password: row.password,
				access: row.access,
				type: row.type,
				contact_person: row.contact_person,
				contact_email: row.contact_email,
				contact_skype: row.contact_skype,
				notes: row.notes,
				user_login: login.user.email,
			}
		}
		if (input) {
			return {
				field: input.name,
				internal_poc: input.internal_poc,
				url: input.url,
				user_field: input.user_name,
				password: pwdChange,
				access: input.access,
				type: input.type,
				contact_person: input.contact_person,
				contact_email: input.contact_email,
				contact_skype: input.contact_skype,
				notes: input.notes,
				user_login: login.user.email,
			}
		}
		return {}
	}
	const handleDelete = async (row: any) => {
		await deletePasswordsAction(headers, { dto: createPayload() })
		setIsWarningPromptOpened(false)
		//setAddVisible(!AddVisible)
	}
	useEffect(() => {
		const sessionSerializedFilters = sessionStorage.getItem(sessionKey)
		if (sessionSerializedFilters && sessionSerializedFilters.length > 4) {
			const sessionFilters = JSON.parse(sessionSerializedFilters)

			sessionFilters.blockPasswords &&
				setBlockPasswords(sessionFilters.blockPasswords)
		}
		props.setLoading(false)
	}, [])
	useEffect(() => {
		if (props.search.length === 0 && presearch !== null) {
			props.setSearch(presearch.toString())
			props.setPresetSearch('')
		}
		if (props.search.length > 0) {
			setFound(searchFor(props.search, passwords.passwords, columnsNew))
		}
	}, [props.search, props, passwords.passwords, presearch])

	useEffect(() => {
		const filters = {
			blockPasswords,
		}
		const serializedFilters = JSON.stringify(filters)
		sessionStorage.setItem(sessionKey, serializedFilters)
	}, [blockPasswords])

	useEffect(() => {
		if (props.search.length === 0) {
			if (presearch !== null) {
				presearchAction(null)
			}
		}
	}, [props.search, presearchAction, presearch])

	useEffect(() => {
		if (fileDownload) {
			saveAs(fileDownload, '*.csv')
		}
	}, [fileDownload])
	useEffect(() => {
		const fetchUpdate = async () => {
			setRow(undefined)
			await fetchData(login, getPasswordsAction, passwords.passwords)
			setUpdate(!update)
		}
		if (!props.PopUpVisible) {
			fetchUpdate()
		}
	}, [props.PopUpVisible])

	const handlePasswordVisibility = () => {
		showPasswordAction(headers, login.user.email, setCodeHash)
		props.setPopUpVisible(!props.PopUpVisible)
	}
	const handleNewPassword = () => {
		setIsAdding(true)
		setAddVisible(true)
	}

	return popUp === null ? (
		<ThemeProvider theme={theme}>
			<PageWrapper>
				<TableWrapper
					theme={theme}
					rowCount={rowCount}
					setRowWidth={setRowWidth}
					style={{ paddingBottom: '60px', width: '90%' }}
				>
					<TableComponent
						columns={columnsNew}
						rows={generateRowsWithIds(
							props.search.length > 0 ? found : passwords.passwords,
						)}
						setPageSize={setPageSize}
						pageSize={pageSize}
						rowsPerPageOptions={[15, 25, 50]}
						setLoading={props.setLoading}
						experimentalFeatures={{ newEditingApi: true }}
						handlePopUp={ruleSet.post ? handleNewPassword : undefined}
						handlePasswordVisibility={handlePasswordVisibility}
						sortModel={sortModel}
						onSortModelChange={(model: any) => setSortModel(model)}
					></TableComponent>
				</TableWrapper>
			</PageWrapper>
			<WarningPrompt
				message={'Deleting the row now?'}
				PopUpVisible={isWarningPromptOpened}
				setPopUpVisible={setIsWarningPromptOpened}
				onAgree={() => handleDelete(row)}
				onCancel={() => props.setLoading(false)}
				obj={row}
			/>
		</ThemeProvider>
	) : (
		popUp
	)
}

export default PasswordsPage

const renderPassword = (
	blockPasswords: boolean,
	login: any,
	password: string | undefined,
	row: any,
) => {
	let result = '******'
	if (blockPasswords) {
		return result
	}
	if (row.whitelist.includes(login.user.name) || row.access === 'Open') {
		result = password as string
	}
	return result
}
