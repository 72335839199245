import {
	Tab,
	Tabs,
	ToggleButton,
	ToggleButtonGroup,
	styled,
} from '@mui/material'
import React, { CSSProperties } from 'react'
import theme from '../../utils/theme2024'

export const TabContainer = ({
	tab,
	handleChangeTab,
	tabProps,
	options,
	tabStyle,
	mainStyle,
}: {
	tab: number
	handleChangeTab: any
	tabProps: any
	options: any
	tabStyle?: CSSProperties
	mainStyle?: CSSProperties
}) => {
	return (
		<div style={{ width: 'auto', display: 'flex', ...mainStyle }}>
			<StyledTabs value={tab} aria-label='basic tabs example'>
				{options.map((el: any) => {
					return (
						<SingleTab
							tabProps={tabProps}
							tabPropsValue={el.tabIndex}
							label={el.label}
							onClick={handleChangeTab}
							tab={tab}
							style={{ ...tabStyle }}
							key={el.tabIndex}
						/>
					)
				})}
			</StyledTabs>
		</div>
	)
}

const SingleTab = ({
	tab,
	tabProps,
	label,
	style,
	tabPropsValue,
	onClick,
}: {
	tab: number
	tabProps: any
	label: string
	style?: any
	tabPropsValue: number
	onClick: any
}) => {
	return (
		<StyledTab
			style={{
				...style,
				borderLeft: tabPropsValue !== 0 ? '1px solid rgba(0,0,0,0.2)' : 'none',
			}}
			label={label}
			{...tabProps} // Spread the tabProps object here directly
			onClick={(e) => onClick(e, tabPropsValue)}
			selected={tab === tabPropsValue}
		/>
	)
}

const StyledTabs = styled(Tabs)(() => ({
	border: '1px solid rgba(0,0,0,0.2)',
	width: 'auto',
	borderRadius: '56px',
	height: '28px',
	color: theme.colors.text.general,
	display: 'flex',
	minHeight: '0px',
	'& .MuiTabs-indicator': {
		backgroundColor: theme.colors.base.green200,
		transition: 'none',
	},
}))
const StyledTab = styled(Tab)(() => ({
	height: '28px',
	minHeight: '0px',
	// borderLeft: '1px solid rgba(0,0,0,0.2)',
	textTransform: 'none',
	minWidth: '10vw',
	display: 'flex',
	'&.Mui-selected': {
		color: theme.colors.text.titles,
		backgroundColor: theme.colors.base.green200,
	},
}))
