import { Grid } from '@mui/material'
import {
	checkValidBudgets,
	generateNodesFromArray,
} from '../../utils/helpers/helperFuncs'
import theme from '../../utils/theme2024'
import { TreeCheckboxContainer } from '../components/TreeCheckBox'
import { InputFieldText } from '../components/Inputs'
import { TitleComponent } from '../components/Informative'

//this would be the tab 2 of editing advertiser.
export const TrafficBudgetComponent = (props: {
	loginRole: string
	settings: any
	trafficRestrictions: string[]
	setTrafficRestrictions: any
	existingChannels: string[]
	setExistingChannels: any
	dailyBudgetLimit: any
	monthlyBudgetLimit: any
	totalBudgetLimit: any
	setDailyBudgetLimit: any
	setMonthlyBudgetLimit: any
	setTotalBudgetLimit: any
}) => {
	//based on the values in settings we create the nodes for traffic and channel. These nodes will be used for the treecheckbox.
	const generateNodesTrafficRestriction = () => {
		return generateNodesFromArray(props.settings.settings.trafficRestrictions, {
			value: '',
			label: '',
		})
	}
	const generateNodesExistingChannels = () => {
		return generateNodesFromArray(props.settings.settings.existingChannels, {
			value: '',
			label: '',
		})
	}
	return (
		<>
			<Grid
				container
				columnSpacing={0}
				columns={12}
				style={{
					// marginTop: '30px',
					minHeight: '50vh',
					justifyContent: 'center',
					width: '96%',
					// border: '1px solid red',
				}}
			>
				<Grid item xs={4}>
					<TreeCheckboxContainer
						nodes={generateNodesTrafficRestriction()}
						selectedElements={props.trafficRestrictions}
						setSelectedElements={props.setTrafficRestrictions}
						label={'Traffic Restrictions'}
						expandFilter={true}
					/>
				</Grid>
				<Grid item xs={4}>
					<TreeCheckboxContainer
						nodes={generateNodesExistingChannels()}
						selectedElements={props.existingChannels}
						setSelectedElements={props.setExistingChannels}
						label={'Existing Channels'}
						expandFilter={true}
					/>
				</Grid>
				<Grid item xs={4}>
					<TitleComponent
						style={{
							fontSize: theme.font.size.buttons,
							// color: 'rgba(128,128,128, 0.8)',
							color: theme.colors.text.titles,
							fontWeight: 'bold',
							marginLeft: '14px',
							marginBottom: '8px',
							marginTop: 10,
						}}
					>
						Budget (USD):
					</TitleComponent>
					<div
						style={{
							display: 'flex',
							flexDirection: 'column',
							// width: '65%',
							justifyContent: 'flex-start',
							border: '1px solid' + theme.colors.lightGray,
							padding: '4px 14px 8px 14px',
							fontSize: '14px',
							borderRadius: '8px',
							gap: '12px',
						}}
					>
						<div>
							<InputFieldText
								label='Daily Budget'
								type='number'
								value={props.dailyBudgetLimit}
								onChange={props.setDailyBudgetLimit}
								disabled={props.loginRole === 'view'}
								errormessage={
									!!checkValidBudgets(
										parseInt(props.dailyBudgetLimit),
										parseInt(props.monthlyBudgetLimit),
									)
										? "Daily budget can't be bigger than monthly budget."
										: undefined
								}
							/>
						</div>
						<div>
							<InputFieldText
								label='Monthly Budget'
								type='number'
								value={props.monthlyBudgetLimit}
								onChange={props.setMonthlyBudgetLimit}
								disabled={props.loginRole === 'view'}
								errormessage={
									!!checkValidBudgets(
										parseInt(props.dailyBudgetLimit),
										parseInt(props.monthlyBudgetLimit),
									)
										? "Monthly budget can't be smaller than daily budget."
										: undefined
								}
							/>
						</div>
						<InputFieldText
							label='Total Budget'
							type='number'
							value={props.totalBudgetLimit}
							onChange={props.setTotalBudgetLimit}
							disabled={props.loginRole === 'view'}
						/>
					</div>
				</Grid>
			</Grid>
		</>
	)
}
