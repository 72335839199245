import { ThemeProvider } from '@mui/material'
import { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { useActions } from '../../hooks/useActions'
import { useTypedSelector } from '../../hooks/useTypedSelector'
import { loginParams } from '../../state/action-creators'
import { theme } from '../../utils/theme2024'
import jwt_decode from 'jwt-decode'
import hero from '../../assets/login-hero.png'
import logoGreen from '../../assets/varys-logo-colored.png'
import Login from './Login'
import SignUp from './SignUp'
import tiktok from '../../assets/tiktok.svg'
import facebook from '../../assets/facebook.svg'
import instagram from '../../assets/instagram.svg'
import linkedin from '../../assets/linkedin.svg'
import ResetPassword from './ResetPassword'

const RepositoriesList = (props: { setFirstLoadingDone: any }) => {
	const [currentView, setCurrentView] = useState('login')
	const [hoveredOption, setHoveredOption] = useState('')
	const [opacity, setOpacity] = useState(1)
	const [isVisible, setIsVisible] = useState(false)
	const [errorMessage, setErrorMessage] = useState('')
	const [isEmailSubmitted, setIsEmailSubmitted] = useState(false)
	const { loginAction, insertLog, signUpAction, resetPasswordAction } =
		useActions()
	const navigate = useNavigate()
	const { login } = useTypedSelector((state) => state)
	const tryLogin = async (email: string, password: string) => {
		setErrorMessage('')
		const user: loginParams = {
			email: email,
			password: password,
		}

		const loginRes: any = await loginAction(user, setErrorMessage)
		if (!loginRes) {
			navigate('/news')
			const headers = {
				Authorization: `Token ${login.user.token}`,
			}
			await insertLog(headers, {
				email: user.email,
				change: 'Login',
				object: {},
			})
		}
	}

	const trySignUp = async (newUser: any) => {
		setErrorMessage('')
		const user = {
			email: newUser.email,
			password: newUser.password,
			name: newUser.name,
			type: newUser.clientType,
		}
		const res = await signUpAction(user, setErrorMessage)
	}
	useEffect(() => {
		if (errorMessage) {
			setIsVisible(false)
		}
	}, [errorMessage])

	const tryResetPassword = async (email: string) => {
		setErrorMessage('')
		const res = await resetPasswordAction(email, setErrorMessage)
		if (res === undefined) {
			setIsEmailSubmitted(true)
		} else {
			setIsEmailSubmitted(false)
		}
	}

	useEffect(() => {
		if (login.user.token && !login.user.blocked) {
			const decodedToken = jwt_decode(login.user.token) as any

			const currentTime = Date.now() / 1000

			if (decodedToken.exp > currentTime) {
				navigate('/news')
			}
		}
	}, [])

	const socialMedia = [
		{
			id: '1',
			title: 'TikTok',
			src: tiktok,
			link: 'https://www.tiktok.com/@thingortwo_agency',
		},
		{
			id: '2',
			title: 'Facebook',
			src: facebook,
			link: 'https://www.tiktok.com/@thingortwo_agency',
		},
		{
			id: '3',
			title: 'Instagram',
			src: instagram,
			link: 'https://www.instagram.com/thing.or.two/',
		},
		{
			id: '4',
			title: 'Linkedin',
			src: linkedin,
			link: 'https://www.linkedin.com/company/thingortwo',
		},
	]

	const loginSignUpDisplayHandler = (view: string) => {
		setOpacity(0)
		setCurrentView(view)
		setTimeout(() => {
			setOpacity(1)
		}, 250)
	}

	const renderForm = () => {
		switch (currentView) {
			case 'login':
				return (
					<Login
						errorMsg={errorMessage}
						onLogin={tryLogin}
						setCurrentView={setCurrentView}
					/>
				)
			case 'signup':
				return (
					<SignUp
						setCurrentView={setCurrentView}
						errorMsg={errorMessage}
						onSignUp={trySignUp}
					/>
				)
			case 'resetPassword':
				return (
					<ResetPassword
						isSubmit={isEmailSubmitted}
						errorMsg={errorMessage}
						onResetPassword={tryResetPassword}
						setCurrentView={setCurrentView}
					/>
				)
			default:
				return (
					<Login
						errorMsg={errorMessage}
						onLogin={tryLogin}
						setCurrentView={setCurrentView}
					/>
				)
		}
	}

	const handleTextColor = (option: string) => {
		if (currentView === option) {
			return theme.colors.base.grey900
		}
		if (hoveredOption === option) {
			return theme.colors.base.green200
		}
		return theme.colors.text.inactive
	}

	return (
		<ThemeProvider theme={theme}>
			<div
				style={{
					width: '100%',
					height: '100vh',
					display: 'flex',
					alignItems: 'center',
					justifyContent: 'center',
					color: theme.colors.base.grey900,
					overflow: 'hidden',
				}}
			>
				<div
					style={{
						display: 'flex',
						alignItems: 'center',
						position: 'absolute',
						top: '5%',
						left: !(currentView === 'signup') ? '3%' : '88%',
						transition: 'left 0.5s ease-in-out',
					}}
				>
					<div style={{ width: '3.75rem' }}>
						<img src={logoGreen} alt='logo' style={{ width: '100%' }} />
					</div>
					<span style={{ fontSize: '1.75rem' }}>Varys</span>
				</div>
				<div
					style={{
						width: '50%',
						display: 'flex',
						alignItems: 'center',
						justifyContent: 'center',
						flexDirection: 'column',
						position: 'absolute',
						top: 0,
						height: '100vh',
						transform: `translateX(${
							!(currentView === 'signup') ? '-50%' : '50%'
						})`,
						opacity: opacity,
						transition: 'transform 0.5s ease-in-out',
					}}
				>
					<div
						style={{
							width: '50%',
							height: '25vh',
							display: 'flex',
							flexDirection: 'column',
							justifyContent: 'center',
							alignItems: 'center',
							gap: '1.875rem',
						}}
					>
						{(currentView === 'login' || currentView === 'signup') && (
							<div
								style={{
									marginBlockEnd: '2.813rem',
									position: 'relative',
									display: 'flex',
									width: '20rem',
									alignItems: 'center',
								}}
							>
								<div
									style={{
										position: 'absolute',
										width: '5.313rem',
										height: '0.563rem',
										borderTopLeftRadius: '1rem',
										borderTopRightRadius: '1rem',
										top: '110%',
										left: !(currentView === 'signup') ? '8%' : '65%',
										background: theme.colors.base.green200,
									}}
								></div>
								<button
									onMouseEnter={() => setHoveredOption('login')}
									onMouseLeave={() => setHoveredOption('')}
									onClick={() => loginSignUpDisplayHandler('login')}
									style={{
										padding: '0',
										textAlign: 'center',
										width: '28.125rem',
										height: '2.5rem',
										fontSize: '2rem',
										fontWeight: theme.font.weight.bold,
										marginRight: '3.125rem',
										color: handleTextColor('login'),
										transition: 'color .2s ease-in-out',
										cursor: !(currentView === 'signup') ? '' : 'pointer',
										border: '0',
									}}
								>
									Log In
								</button>
								<button
									onMouseEnter={() => setHoveredOption('signup')}
									onMouseLeave={() => setHoveredOption('')}
									onClick={() => loginSignUpDisplayHandler('signup')}
									style={{
										padding: '0',
										border: '0',
										textAlign: 'center',
										width: '28.125rem',
										height: '2.5rem',
										fontSize: '2rem',
										fontWeight: theme.font.weight.bold,
										color: handleTextColor('signup'),
										transition: 'color .2s ease-in-out',
										cursor: currentView === 'signup' ? '' : 'pointer',
									}}
								>
									Sign Up
								</button>
							</div>
						)}
						{renderForm()}
						{/* {errorMessage && <span>{errorMessage}</span>} */}
						{(currentView === 'login' || currentView === 'signup') && (
							<div
								style={{
									display: 'flex',
									flexDirection: 'column',
									justifyContent: 'center',
									gap: '1.25rem',
								}}
							>
								<span style={{ textAlign: 'center' }}>Follow us!</span>
								<div
									style={{
										display: 'flex',
										alignItems: 'center',
										gap: '0.938rem',
									}}
								>
									{socialMedia.map((s: any) => {
										return (
											<div
												onClick={() => window.open(s.link, '_blank')}
												key={s.id}
												style={{
													width: '2.25rem',
													cursor: 'pointer',
												}}
											>
												<img
													src={s.src}
													alt={s.title}
													style={{
														width: '100%',
														objectFit: 'contain',
													}}
												/>
											</div>
										)
									})}
								</div>
							</div>
						)}
					</div>
				</div>
				<div
					style={{
						width: '50%',
						height: '100vh',
						backgroundImage: theme.colors.gradientLoginPage,
						color: theme.colors.base.white,
						position: 'absolute',
						top: 0,
						// transition: 'left 0.5s ease-in-out',
						// left: position === 'left' ? '50%' : '0%',
						display: 'flex',
						flexDirection: 'column',
						alignItems: 'center',
						justifyContent: 'center',
						gap: '2.5rem',
						transform: `translateX(${
							!(currentView === 'signup') ? '50%' : '-50%'
						})`,
						transition: 'transform 0.5s ease-in-out',
					}}
				>
					<span
						style={{
							textAlign: 'center',
							width: '28.125rem',
							height: '6.25rem',
							fontSize: '2.6rem',
							fontWeight: theme.font.weight.bold,
						}}
					>
						Unlock Your Data, Unleash Your Potential
					</span>
					<div style={{ width: '24.375rem' }}>
						<img src={hero} alt='hero' style={{ width: '100%' }} />
					</div>
				</div>
			</div>
		</ThemeProvider>
	)
}

export default RepositoriesList
