import {
	DesktopDatePicker,
	LocalizationProvider,
} from '@mui/x-date-pickers-pro'
import {
	DateRangePicker,
	DateRange,
} from '@mui/x-date-pickers-pro/DateRangePicker'
import AdapterDateFns from '@date-io/date-fns'
import { StyledLabel } from './Informative'
import { StyledTextInputField } from './Inputs'
import {
	Dispatch,
	SetStateAction,
	useState,
	CSSProperties,
	useRef,
} from 'react'
import { TextField, Button, Box, ClickAwayListener } from '@mui/material'
import { styled } from 'styled-components'
import theme from '../../utils/theme2024'
import { format } from 'date-fns'
import CalendarTodayIcon from '@mui/icons-material/CalendarToday'
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown'

export const DatePicker = (props: {
	onChange: Dispatch<SetStateAction<Date>>
	value: Date
	disabled: boolean
	label: string
	format: string
}) => {
	const { onChange, value, disabled, label, format } = props
	const [focused, setFocused] = useState(false)
	const [hovered, setHovered] = useState(false)

	const responsiveStyle = () => {
		const style: any = {
			border: '1px solid ' + theme.colors.base.grey300,
			borderRight: '6px solid ' + theme.colors.base.grey300,
		}
		if (props.disabled) {
			style.background = theme.colors.base.grey50
			return style
		}
		if (focused) {
			style.borderRight = '6px solid ' + theme.colors.base.green200
		}
		if (!props.value && hovered) {
			style.borderRight = '6px solid ' + theme.colors.base.green200
		}
		if (props.value) {
			style.borderRight = '6px solid ' + theme.colors.base.green200
		}

		return style
	}
	return (
		<LocalizationProvider dateAdapter={AdapterDateFns}>
			<div
				style={{
					display: 'flex',
					width: '100%',

					flexDirection: 'column',
				}}
			>
				<StyledLabel>{label}</StyledLabel>
				<DateComponent
					onChange={(e: any) => {
						onChange(e)
					}}
					value={value}
					inputFormat={format}
					disabled={disabled}
					OpenPickerButtonProps={{
						style: {
							paddingRight: '20px',
							paddingLeft: '0px',
						},
					}}
					renderInput={(params: any) => (
						<StyledTextInputField
							{...params}
							focused
							style={{ marginTop: '0px', ...responsiveStyle() }}
							disabled={disabled}
						/>
					)}
				/>
			</div>
		</LocalizationProvider>
	)
}

export const DateComponent = styled(DesktopDatePicker)(() => ({
	// minHeight: '100px',
	width: '100%',
	marginRight: '0px',
}))

export const DatePickerComponent: React.FC = () => {
	const [value, setValue] = useState<DateRange<Date>>([null, null])
	const [range, setRange] = useState<string>('Custom')
	const [open, setOpen] = useState<boolean>(false)
	const containerRef = useRef<HTMLDivElement | null>(null)

	const handleRangeChange = (range: string) => {
		setRange(range)
		const now = new Date()
		switch (range) {
			case '24 Hours':
				setValue([now, new Date(now.getTime() + 24 * 60 * 60 * 1000)])
				break
			case '7 Days':
				setValue([now, new Date(now.getTime() + 7 * 24 * 60 * 60 * 1000)])
				break
			case '30 Days':
				setValue([now, new Date(now.getTime() + 30 * 24 * 60 * 60 * 1000)])
				break
			default:
				setValue([null, null])
		}
	}

	const handleClickAway = () => {
		setOpen(false)
	}

	const handleButtonClick = () => {
		setOpen((prevOpen) => !prevOpen)
	}

	const formatDate = (date: Date | null): string => {
		return date ? format(date, 'dd/MM/yyyy') : ''
	}

	const containerStyle: CSSProperties = {
		position: 'relative',
		display: 'inline-block',
	}

	const buttonStyle: CSSProperties = {
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'space-between',
		width: '308px',
		padding: '8px 12px',
		border: '1px solid #ccc',
		borderRadius: '5px',
		backgroundColor: '#fff',
		cursor: 'pointer',
		color: 'gray',
	}

	const dropdownStyle: CSSProperties = {
		position: 'absolute',
		top: '40px', // adjust as needed
		left: '0',
		zIndex: 1000,
		backgroundColor: '#fff',
		padding: '8px',
		border: '1px solid #ccc',
		borderRadius: '8px',
		boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
		marginTop: '10px',
	}

	const rangeSelectorStyle: CSSProperties = {
		display: 'flex',
		justifyContent: 'space-between',
		marginBottom: '20px',
		border: '1px solid #ccc',
	}

	const rangeButtonStyle: CSSProperties = {
		flex: 1,
		padding: '4px 10px',
		borderRadius: '0px',
		textTransform: 'none',
		fontWeight: 'normal',
		backgroundColor: 'lightgray',
		border: 'none',
		fontSize: '12px',
		color: 'black',
	}

	const activeRangeButtonStyle: CSSProperties = {
		...rangeButtonStyle,
		borderBottom: '1px solid',
	}

	const datePickerWrapperStyle: CSSProperties = {
		display: 'flex',
		flexDirection: 'column',
		marginBottom: '10px',
	}

	return (
		<LocalizationProvider dateAdapter={AdapterDateFns}>
			<ClickAwayListener onClickAway={handleClickAway}>
				<Box style={containerStyle} ref={containerRef}>
					<Box style={buttonStyle} onClick={handleButtonClick}>
						<CalendarTodayIcon style={{ marginRight: '8px' }} />
						{value[0] && value[1] ? (
							<span>{`${formatDate(value[0])} - ${formatDate(value[1])}`}</span>
						) : (
							<span>View from</span>
						)}
						<ArrowDropDownIcon />
					</Box>
					{open && (
						<Box style={dropdownStyle}>
							<Box style={rangeSelectorStyle}>
								{['24 Hours', '7 Days', '30 Days', 'Custom'].map((option) => (
									<Button
										key={option}
										onClick={() => handleRangeChange(option)}
										variant={range === option ? 'contained' : 'outlined'}
										style={
											range === option
												? activeRangeButtonStyle
												: rangeButtonStyle
										}
									>
										{option}
									</Button>
								))}
							</Box>
							<Box style={datePickerWrapperStyle}>
								<CustomDateRangePicker
									startText='Start Date'
									endText='End Date'
									value={value}
									onChange={(newValue: DateRange<any>) =>
										setValue(newValue as any)
									}
									renderInput={(startProps, endProps) => (
										<>
											<TextField {...startProps} />
											<Box sx={{ mx: 2 }}> to </Box>
											<TextField {...endProps} />
										</>
									)}
									calendars={2}
									open={true}
									onOpen={() => {}}
									onClose={() => {}}
									inputFormat='dd/MM/yyyy'
								/>
							</Box>
						</Box>
					)}
				</Box>
			</ClickAwayListener>
		</LocalizationProvider>
	)
}

const CustomDateRangePicker = styled(DateRangePicker)(({ theme }) => ({
	'& .MuiPickersDay-root': {
		borderRadius: 0,
		fontSize: '0.8rem',
		width: '22px',
		height: '22px',
		'&:hover': {
			backgroundColor: 'black !important',
			color: 'white !important',
		},
	},
	'& .Mui-selected': {
		backgroundColor: 'black !important',
		color: 'white !important',
	},
	'& .MuiPickersDay-dayInRangeInterval': {
		backgroundColor: 'lightgray !important',
		color: 'black !important',
	},
	'& .MuiPickersDay-dayStart': {
		backgroundColor: 'black !important',
		color: 'white !important',
		borderRadius: '0 !important',
	},
	'& .MuiPickersDay-dayEnd': {
		backgroundColor: 'black !important',
		color: 'white !important',
		borderRadius: '0 !important',
	},
	'& .MuiPickersDay-dayOutsideMonth': {
		color: 'black !important',
	},
}))
