import ArrowCircleRightIcon from '@mui/icons-material/ArrowCircleRight'
import {
	Button,
	Checkbox,
	Grid,
	Paper,
	ThemeProvider,
	Typography,
} from '@mui/material'
import { DataGridPro, GridColDef } from '@mui/x-data-grid-pro'
import { useEffect, useState } from 'react'
import { useTypedSelector } from '../../hooks/useTypedSelector'
import { ActionType } from '../../state/action-types'
import {
	capitalizeFirstLetter,
	generateRows,
} from '../../utils/helpers/tableHelper'
import theme from '../../utils/theme2024'
import { StyledCheckboxFormControl } from '../reports/FiltersStyled'

export const PermissionsPage = (props: {
	selectedName: any
	groups: any
	setPermGroup: any
}) => {
	const { preset, login, users, p360aggregated, publisher, advertiser } =
		useTypedSelector((state) => state)
	const [first, setfirst] = useState<string[]>(['first'])
	const [refresh, setRefresh] = useState(false)
	const [tableData, setTableData] = useState<any>([])
	const [electedDetailsData, setSelectedDetailsData] = useState<any>([])
	const endpointList = Object.values(ActionType)
	const [selectedDetails, setSelectedDetails] = useState<any>(undefined)
	useEffect(() => {
		// let columnGroups: any = {}
		// let rowsGen: any = []
		// endpointList.forEach((e: string) => {
		// 	if (
		// 		e.includes('_') &&
		// 		['get', 'update', 'delete'].includes(e.slice(0, e.indexOf('_')))
		// 	) {
		// 		if (columnGroups[e.slice(e.indexOf('_') + 1, e.length)] === undefined) {
		// 			columnGroups[e.slice(e.indexOf('_') + 1, e.length)] = {}
		// 		}
		// 		columnGroups[e.slice(e.indexOf('_') + 1, e.length)][
		// 			e.slice(0, e.indexOf('_'))
		// 		] = true
		// 	}
		// })
		// Object.keys(columnGroups).forEach((e: any, index: number) =>
		// 	rowsGen.push({
		// 		id: rowsGen.length,
		// 		endpoint: Object.keys(columnGroups)[index],
		// 		get: columnGroups[e]['get'],
		// 		post: columnGroups[e]['update'],
		// 		delete: columnGroups[e]['delete'],
		// 		rules: permLookup[index]['rules'],
		// 	}),
		// )
		const foundInGroupsList = props.groups[props.selectedName]

		setTableData(foundInGroupsList)
	}, [props.selectedName])

	useEffect(() => {
		if (selectedDetails !== undefined && tableData[selectedDetails].rules) {
			const rule_crid = Object.keys(tableData[selectedDetails].rules).map(
				(e: any, index: number) => (
					<Grid
						item
						style={{
							width: '100%',
						}}
					>
						<Paper
							style={{
								height: '100px',
								display: 'flex',
								justifyContent: 'center',
								alignContent: 'center',
								backgroundColor: index % 2 === 0 ? 'white' : '#f2f2f2',
							}}
						>
							<StyledCheckboxFormControl
								key={'retargeting'}
								control={
									<Checkbox
										onChange={(e: any) => {
											tableData[selectedDetails]['rules'][
												Object.keys(tableData[selectedDetails]['rules'])[index]
											] =
												!tableData[selectedDetails]['rules'][
													Object.keys(tableData[selectedDetails]['rules'])[
														index
													]
												]
											setTableData(Object.assign([], tableData))

											// props.groups[
											// 	props.groups.findIndex(
											// 		(e: any) => Object.keys(e)[0] === props.selectedName,
											// 	)
											// ][props.selectedName] = tableData
											// props.setPermGroup(props.groups)
											props.groups[props.selectedName] = tableData
											props.setPermGroup(props.groups)
										}}
										checked={
											tableData[selectedDetails]['rules'][
												Object.keys(tableData[selectedDetails]['rules'])[index]
											]
										}
										key={'retargeting_checkbox'}
										inputProps={{
											'aria-label': 'controlled',
										}}
										style={{ marginLeft: '0px', color: '#ee1d4a' }}
										disabled={false}
									/>
								}
								label={
									<Typography
										fontSize='17px'
										fontWeight={500}
										color={'secondary'}
										children={capitalizeFirstLetter(e)}
									/>
								}
							/>
						</Paper>
					</Grid>
				),
			)

			setSelectedDetailsData(rule_crid)
		} else {
			setSelectedDetailsData(undefined)
		}
	}, [selectedDetails, tableData])

	const columns: GridColDef[] = [
		{
			field: 'endpoint',
			headerName: 'Endpoint',
			width: 200,
			// type: 'number',
			renderCell(params) {
				return (
					<Typography fontSize='17px' fontWeight={500} color={'secondary'}>
						{capitalizeFirstLetter(params.value)}
					</Typography>
				)
			},
		},
		{
			field: 'get',
			headerName: 'View',
			// width: rowWidth,
			// type: 'number',
			width: 100,
			renderCell(params) {
				if (params.value === undefined) {
					return undefined
				}
				return (
					<StyledCheckboxFormControl
						key={'get'}
						control={
							<Checkbox
								onChange={(e: any) => {
									tableData[params.id as number][params.field] =
										!tableData[params.id as number][params.field]
									if (
										tableData[params.id as number]['post'] !== undefined &&
										tableData[params.id as number][params.field] === false
									) {
										tableData[params.id as number]['post'] = false
									}
									if (
										tableData[params.id as number]['delete'] !== undefined &&
										tableData[params.id as number][params.field] === false
									) {
										tableData[params.id as number]['delete'] = false
									}

									setTableData(Object.assign([], tableData))
									// props.groups[
									// 	props.groups.findIndex(
									// 		(e: any) => Object.keys(e)[0] === props.selectedName,
									// 	)
									// ][props.selectedName] = tableData
									props.groups[props.selectedName] = tableData
									props.setPermGroup(props.groups)
								}}
								checked={params.value}
								key={'retargeting_checkbox'}
								inputProps={{
									'aria-label': 'controlled',
								}}
								style={{
									marginLeft: '0px',
									color: '#ee1d4a',
								}}
								disabled={params.value === undefined}
							/>
						}
						label={
							<Typography
								fontSize='13px'
								fontWeight={500}
								color={'secondary'}
							></Typography>
						}
					/>
				)
			},
		},
		{
			field: 'post',
			headerName: 'Add/Edit',
			// width: rowWidth,
			// type: 'number',
			width: 100,
			renderCell(params) {
				if (params.value === undefined) {
					return undefined
				}

				return (
					<StyledCheckboxFormControl
						key={'post'}
						control={
							<Checkbox
								onChange={(e: any) => {
									tableData[params.id as number][params.field] =
										!tableData[params.id as number][params.field]
									setTableData(Object.assign([], tableData))

									props.groups[props.selectedName] = tableData
									// props.groups[
									// 	props.groups.findIndex(
									// 		(e: any) => Object.keys(e)[0] === props.selectedName,
									// 	)
									// ][props.selectedName] = tableData
									props.setPermGroup(props.groups)
								}}
								checked={params.value}
								key={'retargeting_checkbox'}
								inputProps={{
									'aria-label': 'controlled',
								}}
								style={{ marginLeft: '0px', color: '#ee1d4a' }}
								disabled={params.value === undefined}
							/>
						}
						label={
							<Typography
								fontSize='13px'
								fontWeight={500}
								color={'secondary'}
							></Typography>
						}
					/>
				)
			},
		},
		{
			field: 'delete',
			headerName: 'Delete',
			// width: rowWidth,
			// type: 'number',
			width: 100,
			renderCell(params) {
				if (params.value === undefined) {
					return undefined
				}
				return (
					<StyledCheckboxFormControl
						key={'delete'}
						control={
							<Checkbox
								onChange={(e: any) => {
									tableData[params.id as number][params.field] =
										!tableData[params.id as number][params.field]

									setTableData(Object.assign([], tableData))

									// props.groups[
									// 	props.groups.findIndex(
									// 		(e: any) => Object.keys(e)[0] === props.selectedName,
									// 	)
									// ][props.selectedName] = tableData
									// props.setPermGroup(props.groups)
									props.groups[props.selectedName] = tableData
									props.setPermGroup(props.groups)
								}}
								checked={params.value}
								key={'retargeting_checkbox'}
								inputProps={{
									'aria-label': 'controlled',
								}}
								style={{ marginLeft: '0px', color: '#ee1d4a' }}
								disabled={params.value === undefined}
							/>
						}
						label={
							<Typography
								fontSize='13px'
								fontWeight={500}
								color={'secondary'}
							></Typography>
						}
					/>
				)
			},
		},
		{
			field: 'use',
			headerName: 'Actions',
			// width: rowWidth,
			// type: 'number',
			width: 100,
			renderCell(params) {
				return permLookup[params.id]?.rules ? (
					<Button
						children={<ArrowCircleRightIcon color='warning' />}
						onClick={() => {
							setSelectedDetails(params.id as number)
						}}
					/>
				) : undefined
			},
		},
	]

	return (
		<>
			<ThemeProvider theme={theme}>
				<Typography color='black'>{props.selectedName}</Typography>
				<div style={{ display: 'flex' }}>
					<DataGridPro
						columns={columns}
						rows={generateRows(tableData)}
						style={{
							maxWidth: '35vw',
							marginInline: '8vw',
						}}
						onRowClick={(props: any) => setSelectedDetails(props.id)}
					></DataGridPro>
					<Paper key={'345235435345'} style={{ width: '35vw' }}>
						<Grid
							container
							// rowSpacing={5}
							direction={'column'}
							style={{
								width: '35vw',
								height: 'auto',
								justifyContent: 'center',
								alignContent: 'center',
								flexWrap: 'nowrap',
							}}
						>
							{electedDetailsData}
						</Grid>
					</Paper>
				</div>
			</ThemeProvider>
		</>
	)
}

const PermissionCell = () => {}

export const permLookup: any = [
	{
		id: 0,
		endpoint: 'users',
		get: true,
		post: true,
		rules: {
			'changing users password': true,
			'blocking users': true,
		},
	},
	{
		id: 1,
		endpoint: 'news',
		get: true,
		post: true,
		rules: {
			'See New Advertisers': true,
			'See New Publishers': true,
		},
	},
	{
		id: 2,
		endpoint: 'logs',
		get: true,
		rules: {
			'view log-ins': true,
			'view super logs': true,
			'view admin logs': true,
			'view designer logs': true,
			'view user logs': true,
			'view detailed logs': true,
		},
	},
	{
		id: 3,
		endpoint: 'settings',
		get: true,
		post: true,
		rules: {
			'view personal settings page': true,
		},
	},
	{
		id: 4,
		endpoint: 'advertiser',
		get: true,
		post: true,
		delete: true,
		rules: {
			'allow only owner to edit': true,
			'see all advertisers': true,
			preview: true,
			'access to ‘Apps’': true,
			'download advertisers list': true,
			'download contact list': true,
			'view advertiser contacts': true,
		},
	},
	{
		id: 5,
		endpoint: 'publisher',
		get: true,
		post: true,
		delete: true,
		rules: {
			'adding new publishers': true,
			preview: true,
			'access to ‘Media Plan’': true,
			'download publishers list': true,
			'view links': true,
			'view publisher contacts': true,
		},
	},
	{
		id: 6,
		endpoint: 'campaign',
		get: true,
		post: true,
		delete: true,
		rules: {
			'adding new campaigns': true,
			'viewing info': true,
			'editing main parameters  (advertiser, app_id, c parameter, media source, geos, payouts)':
				true,
			'editing other parameters': true,
			'opening history': true,
			'adding unique payouts': true,
			'download a campaign report (and which types of report)': true,
			preview: true,
		},
	},
	{
		id: 7,
		endpoint: 'appsflyer',
		get: true,
		post: true,
		rules: {
			'-visible report types': true,
			'-viewing revenue': true,
			'-viewing cost,': true,
			'-viewing profit': true,
			'-available filters (app_ids, publishers, etc) (will also limit which info a user can see)':
				true,
			'-optional additional statistics fields limitations': true,
		},
	},
	{
		id: 8,
		endpoint: 'app_p360',
		get: true,
		post: true,
		delete: true,
	},
	{
		id: 9,
		endpoint: 'network',
		get: true,
		post: true,
	},
	{
		id: 10,
		endpoint: 'rawdata',
		get: true,
		post: true,
	},
	{
		id: 11,
		endpoint: 'passwords',
		get: true,
		post: true,
		delete: true,
	},
	{
		id: 12,
		endpoint: 'learning',
		get: true,
		post: true,
		delete: true,
	},
	{
		id: 13,
		endpoint: 'finance',
		get: true,
		post: true,
		delete: true,
		rules: {
			'View Control': true,
			'View Personal': true,
			'View Media Buying': true,
			'View Global': true,
			'View Demand': true,
			'View Supply': true,
			'View Balance In': true,
			'View Balance Out': true,
			'Send Emails': true,
			'Edit Demand': true,
			'Edit Demand Invoice': true,
			'Edit Supply Invoice': true,
			// 'Update data (We have the ability to update by pages. For example: User could be allowed to update on Personal but not on Supply)':
			// 	true,
			// 'We could have the ability to show the data filtered by Advertiser or Publisher if needed':
			// 	true,
		},
	},
	{
		id: 14,
		endpoint: 'dashboard',
		get: true,
		rules: {
			'Permission to see aggregated p360 report': true,
			'Permissionto see aggregated + network report': true,
		},
	},
	{
		id: 15,
		endpoint: 'autoswitch',
		get: true,
		post: true,
		delete: true,
	},
	{
		id: 16,
		endpoint: 'ads placement',
		get: true,
	},
	{
		id: 17,
		endpoint: 'invalid',
		get: true,
	},
]
