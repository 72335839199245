import { AddAPhoto } from '@mui/icons-material'
import { Container, Typography } from '@mui/material'
import React, { useEffect, useState } from 'react'
import theme from '../../utils/theme2024'
import { PlusButton } from '../campaigns/CampaignStyled'
import ArrowBackIcon from '@mui/icons-material/ArrowBack'
import ArrowForwardIcon from '@mui/icons-material/ArrowForward'
import './newsletter-style.css'

const NewsletterElement = (props: {
	title: string
	value: string
	image: any
	imageSrc: any
	id: string
	index: number
	elements: any[]
	mainObj: any
	editMode: boolean
	setElements: any
	handleDeleteElement: any
	setErrorMessage: any
	orderEditor: any
	length: number
}) => {
	const [isEditing, setIsEditing] = useState(false)
	const [hoveredAddImage, setHoveredAddImage] = useState(false)
	const [isTitleEditing, setTitleIsEditing] = useState(false)
	const [title, setTitle] = useState(props.title)
	const [value, setValue] = useState(props.value)
	const [image, setImage] = useState<any>(null)

	useEffect(() => {
		if (props.image === 'uploaded' && props.imageSrc) {
			const imageSrcTo64 = arrayBufferToBase64(props.imageSrc.data)
			const src = `data:image/jpeg;base64,${imageSrcTo64}`
			setImage(src)
		}
	}, [])

	const arrayBufferToBase64 = (buffer: any) => {
		var binary = ''
		var bytes = [].slice.call(new Uint8Array(buffer))
		bytes.forEach((b) => (binary += String.fromCharCode(b)))
		return window.btoa(binary)
	}

	const handleInputChange = (e: any) => {
		const updatedObj = {
			title: title === props.title ? props.title : title,
			value: value === props.value ? props.value : value,
			image:
				props.image === 'uploaded'
					? props.image
					: image === props.image
					? props.image
					: image,
			type: 'image',
			id: props.id,
		}
		if (isEditing) {
			updatedObj.value = e.target.value
			setValue(e.target.value)
		} else {
			updatedObj.title = e.target.value
			setTitle(e.target.value)
		}

		props.elements[props.index] = updatedObj
		props.mainObj.elements.forEach((el: any) => {
			if (el.id === props.id) {
				el.title = updatedObj.title
				el.value = updatedObj.value
			}
		})
	}
	const handleEnter = (e: any) => {
		if (e.key === 'Enter') {
			setIsEditing(false)
			setTitleIsEditing(false)
		}
	}
	const openFile = () => {
		document.getElementById('hiddenFileInput' + props.id)!.click()
	}
	const handleNewImage = (e: any) => {
		const imageStr = URL.createObjectURL(e.target.files[0])
		const image = new Image()
		image.src = imageStr
		image.onload = () => {
			if (image.height <= 210 || image.width <= 210) {
				setImage(imageStr)
				props.mainObj.elements.forEach((el: any) => {
					if (el.id === props.id) {
						el.image = e.target.files[0]
					}
				})
			} else {
				props.setErrorMessage('the size of the image must be 210x210 at max.')
			}
		}
	}

	return (
		<Container
			style={{
				display: 'flex',
				flexDirection: 'column',
				alignItems: 'flex-start',
				marginLeft: '34px',
				width: props.editMode ? '200px' : '400px',
				gap: '8px',
				paddingLeft: '16px',
				alignSelf: 'flex-start',
				border: props.editMode ? '1px dashed rgba(234,29,67,0.4)' : 'none',
			}}
			key={'element' + props.id}
		>
			<input
				type='file'
				style={{ visibility: 'hidden' }}
				id={'hiddenFileInput' + props.id}
				onChange={handleNewImage}
			></input>
			{image ? (
				<div style={{ position: 'relative' }}>
					<img
						src={image}
						style={{
							height: '100px',
							// border: '1px solid ' + theme.colors.base.green50,
							// boxShadow: 'inset 0px 0px 0px 2px ' + theme.colors.base.green200,
							borderRadius: '30px',
							// backgroundColor: theme.colors.base.green200,
						}}
						alt='user-profile'
					></img>
					<div
						style={{
							width: '100%',
							height: '98%',
							// background: 'red',
							boxShadow: 'inset 0px 0px 0px 3px ' + theme.colors.base.green200,
							borderRadius: '30px',
							position: 'absolute',
							top: 0,
						}}
					></div>
				</div>
			) : props.editMode ? (
				<button
					onClick={openFile}
					style={{
						background: hoveredAddImage ? 'rgb(241,241,242)' : 'none',
						cursor: 'pointer',
						border: '1px solid rgba(0,0,0,0.4)',
						borderRadius: '12px',
						width: '100px',
						height: '100px',
					}}
					onMouseEnter={() => {
						setHoveredAddImage(true)
					}}
					onMouseLeave={() => {
						setHoveredAddImage(false)
					}}
				>
					<AddAPhoto style={{ scale: '1.6' }} />
				</button>
			) : (
				<></>
			)}
			{props.editMode && (
				<>
					<PlusButton
						style={{
							background: theme.colors.base.red300,
							margin: '0px',
							left: '99%',
							top: '-128px',
							marginTop: '-6px',
							width: '20px',
							height: '20px',
							paddingTop: '4px',
						}}
						onClick={(e: any) => props.handleDeleteElement(props.id)}
					>
						x
					</PlusButton>
				</>
			)}
			{isEditing ? (
				<input
					type='text'
					onChange={handleInputChange}
					onKeyDown={handleEnter}
					defaultValue={
						value
							? value !== ''
								? value
								: 'Empty'
							: props.value !== ''
							? props.value
							: 'Empty'
					}
				/>
			) : props.editMode ? (
				<Typography
					component={'span'}
					style={{ fontSize: '14px', marginLeft: '12px' }}
					onDoubleClick={() => setIsEditing(true)}
				>
					{value
						? value !== ''
							? value
							: 'Empty'
						: props.value !== ''
						? props.value
						: 'Empty'}
				</Typography>
			) : (
				<Typography
					component={'span'}
					style={{ fontSize: '14px', marginLeft: '12px' }}
				>
					{props.value}
				</Typography>
			)}
			{isTitleEditing ? (
				<input
					type='text'
					onChange={handleInputChange}
					onKeyDown={handleEnter}
					defaultValue={
						title
							? title !== ''
								? title
								: 'Empty'
							: props.title !== ''
							? props.title
							: 'Empty'
					}
				/>
			) : props.editMode ? (
				<Typography
					component={'span'}
					style={{
						fontSize: '14px',
						fontWeight: '600',
						marginTop: '-6px',
						marginLeft: '12px',
					}}
					onDoubleClick={() => setTitleIsEditing(true)}
				>
					{title
						? title !== ''
							? title
							: 'Empty'
						: props.title !== ''
						? props.title
						: 'Empty'}
				</Typography>
			) : (
				<Typography
					component={'span'}
					style={{
						fontSize: '14px',
						fontWeight: '600',
						marginTop: '-6px',
						marginLeft: '12px',
					}}
				>
					{props.title}
				</Typography>
			)}

			{props.editMode && (
				<div
					style={{
						display: 'flex',
						width: 'inherit',
						justifyContent: 'center',
						marginLeft: '-16px',
					}}
				>
					{props.index !== 0 ? (
						<button
							style={{ display: 'flex', alignItems: 'center' }}
							onClick={() => props.orderEditor(props.id, props.index - 1)}
						>
							<ArrowBackIcon></ArrowBackIcon>
						</button>
					) : (
						<div style={{ width: '40px' }}></div>
					)}
					{props.length !== props.index + 1 ? (
						<button
							style={{ display: 'flex', alignItems: 'center' }}
							onClick={() => props.orderEditor(props.id, props.index + 1)}
						>
							<ArrowForwardIcon></ArrowForwardIcon>
						</button>
					) : (
						<div style={{ width: '40px' }}></div>
					)}
				</div>
			)}
		</Container>
	)
}

export default NewsletterElement
