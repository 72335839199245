import React from 'react'
import theme from '../../utils/theme2024'
import { formatDateToDdMmYyyy } from '../../utils/helpers/helperFuncs'
import { SubmitButton } from '../components/Buttons'

//this component will render the email history for the last sent.
const EmailHistoryView = (props: {
	historyList: string[]
	finalWasSent: boolean
	setViewHistoryEmail: any
	setIsEmailPreviewOpen: any
}) => {
	return (
		<div
			style={{
				width: '30%',
				height: '50%',
				background: theme.colors.base.white,
				position: 'absolute',
				top: '-2%',
				left: '28%',
				zIndex: '20',
				// border: '1px solid rgba(0,0,0,0.2)',
				borderRadius: '12px',
				fontSize: '16px',
				color: 'black',
				padding: '40px',
				display: 'flex',
				overflowY: 'auto',
				boxShadow: '2px 2px 2px 3px ' + theme.colors.base.grey300,
				flexDirection: 'column',
				justifyContent: 'space-between',
				alignItems: 'center',
			}}
		>
			<ul
				style={{
					display: 'flex',
					justifyContent: 'space-around',
					flexDirection: 'column',
					marginLeft: '-22px',
					fontSize: '18px',
					height: '80%',
				}}
			>
				{props.historyList.map((el: string, index: number) => {
					if (props.finalWasSent && index === props.historyList.length - 1) {
						return (
							<li style={{ listStyle: 'none', fontWeight: '500' }}>
								Final Numbers were sent:{' '}
								<span style={{ textDecoration: 'underline' }}>
									{formatDateToDdMmYyyy(el)}
								</span>
							</li>
						)
					} else {
						return (
							<li style={{ listStyle: 'none' }}>
								Status {index + 1} was sent: {formatDateToDdMmYyyy(el)}
							</li>
						)
					}
				})}
			</ul>
			<SubmitButton
				onClick={() => {
					props.setViewHistoryEmail(false)
					props.setIsEmailPreviewOpen(false)
				}}
			>
				Close
			</SubmitButton>
		</div>
	)
}

export default EmailHistoryView
